<ion-content class="page">

    <div class="background">
        <!-- Fabs buttons -->
        <ion-fab *ngIf="module?.options  && module.options?.allowSessionsDownload" class="fabs-buttons"
            vertical="bottom" horizontal="end" slot="fixed">
            <ion-fab-button class="basic-fab-button" [ngClass]="{'fab-mobile': isMobile, 'fab-desktop': !isMobile}"
                (click)="openDownloadScheduleModal()">
                <ion-icon src="assets/images/calendar_colloqium.svg"></ion-icon>
            </ion-fab-button>
        </ion-fab>
        <div *ngIf="event && module" class="f-col-fs-fs full-h">
            <!-- <div class="filtering-header" #header [style.display]="'block'" [style.padding]="isMobile ? '0' : '0 15%'">
                <ion-toolbar class="date-toolbar" [style.border-color]="event.styling.btnBgColor + '1A'">
                    <ion-buttons slot="start" class="hide-in-small-screen">
                        <ion-button class="swiper-prev" (click)="previousDatesSlide()">
                            <ion-icon src="assets/icon/ArrowLeft.svg" style="color: #CCCCCC;"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                    <swiper-container #datesSwiper [modules]="swiperModules" [effect]="swiperOptions.effect"
                        [slidesPerView]="swiperOptions.slidesPerView" [slidesPerGroup]="swiperOptions.slidesPerGroup"
                        [updateOnWindowResize]="swiperOptions.updateOnWindowResize"
                        [centerInsufficientSlides]="swiperOptions.centerInsufficientSlides">
                        <swiper-slide>
                            <span class="{{ selectedDate === 'all' ? 'date-circle-selected' : 'date-circle' }}"
                                (click)="selectedDate = 'all'; filterByDate()"
                                [style.background]="selectedDate === 'all' ? event.styling.btnBgColor : event.styling.btnTextColor"
                                [style.color]="selectedDate === 'all' ? event.styling.btnTextColor : event.styling.btnBgColor"
                                [style.border-color]="selectedDate === 'all' ? 'none' : event.styling.btnBgColor + '26'">
                                {{ "texts.all" | translate }}
                            </span>
                        </swiper-slide>
                        <swiper-slide *ngFor="let date of dates; let i = index">
                            <span *ngIf="date"
                                class="{{selectedDate === date ? 'date-circle-selected' : 'date-circle'}}"
                                (click)="selectedDate = date; filterByDate()"
                                [style.background]="selectedDate === date ? event.styling.btnBgColor : event.styling.btnTextColor"
                                [style.color]="selectedDate === date ? event.styling.btnTextColor : event.styling.btnBgColor"
                                [style.border-color]="selectedDate === date ? 'none' : event.styling.btnBgColor + '26'">
                                <span style="font-size: 1.5rem;">{{ getPartOfDate(date, 'day') }}</span>
                                <span>{{ getPartOfDate(date, 'month') }}</span>
                            </span>
                        </swiper-slide>
                    </swiper-container>

                    <ion-buttons slot="end" class="hide-in-small-screen">
                        <ion-button class="swiper-next" (click)="nextDatesSlide()">
                            <ion-icon src="assets/icon/ArrowRight.svg" style="color: #CCCCCC;"></ion-icon>
                        </ion-button>
                    </ion-buttons>
                </ion-toolbar>
            </div> -->

            <div class="wrapper" [style.padding-top.px]="''" [style.--padding-start]="isMobile ? '0px' : '15%'"
                [style.--padding-end]="isMobile ? '0px' : '15%'">
                <ng-container *ngIf="sessionsFiltered.length > 0; else emptyContent">
                    <div class="viewHour" [style.padding]="'0px'">
                        <ion-list *ngFor="let date of getPaginatedDates()" style="padding: 0;">
                            <ion-list-header>
                                <ion-label [style.color]="event.styling.titleColor" class="header-date">
                                    {{ SUtility.getPartOfDate(event, eventUser, date, "full") | titlecase }}
                                </ion-label>
                            </ion-list-header>
                            <div id="{{ session.uid }}" *ngFor="let session of getPaginatedSessions(); let i = index"
                                class="session-container">
                                <div class="session-item" *ngIf="checkSessionDate(session, date)" [style.background]="getFirstTrackForSession(session)
                                    ? (getFirstTrackForSession(session).textColor.includes('#fff') ? getFirstTrackForSession(session).backgroundColor : getFirstTrackForSession(session).textColor)
                                    : event.styling.titleColor" [style.margin-top]="i === 0 && 0"
                                    (click)="openSession(session)"
                                    [style.width]="checkSessionDate(session, date) && '100%' ">
                                    <div class="label-bg"
                                        [style.border]="session.tracks.length === 0 ? '1px solid ' + event.styling.titleColor + '33' : 'none'">
                                        <div [style.background]="getFirstTrackForSession(session)
                                            ? (getFirstTrackForSession(session).textColor.includes('#fff') ? getFirstTrackForSession(session).backgroundColor + '1A' : getFirstTrackForSession(session).textColor + '1A' )
                                            : '#fff'" class="label">
                                            <div>
                                                <small class="track-name" *ngFor="let trackId of session.tracks"
                                                    [style.color]="getSpecificTrack(trackId) ? getSpecificTrack(trackId).textColor : ''"
                                                    [style.background]="getSpecificTrack(trackId) ? getSpecificTrack(trackId).backgroundColor : ''">
                                                    {{ getSpecificTrack(trackId) ?
                                                    getSpecificTrack(trackId).name[currentLanguage] : "" }}
                                                </small>
                                            </div>
                                            <div [style.color]="event.styling.titleColor" class="session-name">
                                                {{ session.name[currentLanguage] }}</div>
                                            <div *ngIf="!session.hideSchedule || (checkSessionDate(session, date) && module.options?.allowSessionsDownload)"
                                                [style.color]="event.styling.contentTextColor" class="session-time">
                                                <span *ngIf="!session.hideSchedule">{{ SUtility.getPartOfDate(event,
                                                    eventUser, session.startDate, "time") |
                                                    uppercase}}</span>
                                                <span *ngIf="session.endDate && !session.hideSchedule">
                                                    -
                                                    {{
                                                    SUtility.getPartOfDate(event, eventUser, session.endDate, "time") |
                                                    uppercase
                                                    }} </span>
                                                <ion-icon
                                                    *ngIf="checkSessionDate(session, date) && module.options?.allowSessionsDownload"
                                                    style="float: right; position: relative; right: 10px; width: 25px; height: 25px;"
                                                    fill="{{event.styling.menuColor}}"
                                                    (click)="openDownloadModal(session, $event)"
                                                    class="material-icons ics-download"
                                                    src="assets/images/calendar_colloqium.svg"
                                                    alt="download"></ion-icon>
                                            </div>
                                            <br>
                                            <div>

                                                <small
                                                    style="display:inline-flex; align-items: center;margin-right: 5px;"
                                                    *ngFor="let locationId of session.locations"
                                                    [style.color]="event.styling.btnBgColor">
                                                    <ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
                                                    <span>{{ getSpecificLocation(locationId).name }}</span>
                                                </small>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ion-list>
                        <ion-infinite-scroll threshold="100px" (ionInfinite)="moreSessions($event, 'normal')">
                            <ion-infinite-scroll-content loadingSpinner="dots">
                            </ion-infinite-scroll-content>
                        </ion-infinite-scroll>
                    </div>
                </ng-container>
                <ng-template #emptyContent>
                    <div class="container">
                        <span class="wait-content"
                            [style.color]="event.styling.titleColor">{{"texts.waiting_for_content" |
                            translate}}</span>
                        <ion-icon src="assets/images/emptycontent/AGENDA.svg"
                            [style.color]="event.styling.menuColor.includes('linear') ? event.styling.menuColor.split(',')[1] : event.styling.menuColor">
                        </ion-icon>
                        <div class="text-empty-content" [style.color]="event.styling.contentTextColor">
                            <span class="title"> {{"schedule.empty_content_title" | translate}}</span>
                            <span>{{"schedule.empty_content_text" | translate}}</span>
                        </div>
                    </div>
                </ng-template>
            </div>

        </div>
        <div *ngIf="!event || !module">
            <ion-item-divider>
                <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
            </ion-item-divider>
            <ion-item lines="none" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                <ion-avatar slot="start">
                    <ion-skeleton-text style="width: 10%"></ion-skeleton-text>
                    <ion-skeleton-text style="width: 10%"></ion-skeleton-text>
                </ion-avatar>
                <ion-label>
                    <h3>
                        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                    </h3>
                    <h3>
                        <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
                    </h3>
                    <h3>
                        <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                    </h3>
                </ion-label>
            </ion-item>
        </div>
    </div>
</ion-content>