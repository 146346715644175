import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { QuerySnapshot, where } from "firebase/firestore";
import { firstValueFrom, Subscription, take } from "rxjs";
import { GetRegisterForms } from "../actions/register-form.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { checkSameEvent } from "../selectors/register-form.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { PathComponents } from "../paths/path-components";
import { IRegisterForm } from "../interfaces/register-form.interfaces";
import { IEvent, IEventUser } from "../interfaces";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { ModalController, Platform } from "@ionic/angular";
import { TypeModule } from "../enums/type-module";

@Injectable({
	providedIn: "root"
})
export class RegisterFormService {
	formsSub: Subscription;
	isMobile: boolean;

	constructor(
		private SFirestore: FirestoreService,
		private store: Store,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private platform: Platform,
		private modalCtrl: ModalController
	) {
		this.isMobile = this.platform.is("mobile") && window.innerWidth < 768 ? true : false;
	}

	unsubscribeAll() {
		[this.formsSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
	}

	/**
	 * getAllEventRegisterForms
	 * @param eventId
	 */
	getAllEventRegisterForms(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.formsSub && !this.formsSub.closed) {
					return;
				} else if (!sameEvent && this.formsSub && !this.formsSub.closed) {
					this.formsSub.unsubscribe();
				}

				this.formsSub = this.SFirestore.collectionGroupValueChangesDocuments("register-forms", [
					where("eventId", "==", eventId)
				]).subscribe((forms) => {
					this.store.dispatch(GetRegisterForms({ payload: forms, eventId: eventId }));
					this.store
						.select(getInitSpecificEventDatasPart("initRegisterForms"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initRegisterForms", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 * getRegisterFormOfModule
	 * @param eventId
	 * @param moduleId
	 * @returns
	 */
	async getRegisterFormOfModule(eventId: string, moduleId: string) {
		try {
			const snapshop = await this.SFirestore.getDocumentsCollectionGroup("register-forms", [
				where("eventId", "==", eventId),
				where("moduleId", "==", moduleId)
			]);

			return !snapshop.empty ? (snapshop.docs[0].data() as IRegisterForm) : [];
		} catch (error) {}
	}

	/**
	 * getRegisterForm
	 * @param eventId
	 * @param moduleId
	 * @param formId
	 * @returns
	 */
	getRegisterForm(eventId: string, moduleId: string, formId: string) {
		return this.SFirestore.getDocument(`events/${eventId}/modules/${moduleId}/register-forms/${formId}`);
	}

	/**
	 * Open openAccompanyingForm
	 */
	async openAccompanyingForm(event: IEvent, eventUser: IEventUser, registerForm: IRegisterForm) {
		try {
			const [totalEventUsers, totalModuleEventUsers, totalEventUsersAccompanying] = await Promise.all([
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs("event-users", "group", [where("eventId", "==", event.uid)])
				),
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users`,
						"default",
						[]
					)
				),
				firstValueFrom(
					this.SFirestore.getCountOfQueryObs(
						`events/${event.uid}/modules/${eventUser.moduleId}/event-users`,
						"default",
						[where("isAccompanying", "==", true), where("registeredBy", "==", eventUser.uid)]
					)
				)
			]);

			if (!registerForm) {
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			if (
				totalEventUsers >= event?.limitations?.usersLimit ||
				(registerForm &&
					registerForm.formSettings.enableRegisterLimit &&
					totalModuleEventUsers >= registerForm.formSettings.registerLimit) ||
				(registerForm &&
					registerForm.formSettings.enableAccompanyingUsers &&
					eventUser &&
					totalEventUsersAccompanying >= registerForm.formSettings.accompanyingUsersLimit)
			) {
				this.snackbar.open(this.STranslate.instant("register.accompanying_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			const modal = await this.modalCtrl.create({
				id: "accompanying-form-modal",
				component: PathComponents.registerForm,
				componentProps: {
					eventId: event.uid,
					moduleId: eventUser.moduleId,
					eventUserProfileId: eventUser.uid,
					mode: "accompanying-form-modal"
				},
				cssClass: "full-sized-modal"
			});

			return modal.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
