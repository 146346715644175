import { createAction, props } from "@ngrx/store";
import {
	IAnalyticsAttendees,
	IAnalyticsChats,
	IAnalyticsDocuments,
	IAnalyticsFeeds,
	IAnalyticsGalleries,
	IAnalyticsGeneral,
	IAnalyticsSchedules,
	IAnalyticsSpeakers,
	IAnalyticsTracking,
	IAnalyticsUsers,
	IAnalyticsVisios
} from "../interfaces/analytics.interfaces";

export enum AnalyticsActionsTypes {
	// General analytics
	UpdateGeneralAnalytics = "[Analytics Service] Update general analytics",
	UpdateTotalAccess = "[Analytics Service] Update total access analytics",
	UpdateTotalProfilEdition = "[Analytics Service] Update total profil edition analytics",
	UpdateBestConsultedModules = "[Analytics Service] Update best consulted modules analytics",

	// Attendees analytics
	UpdateAttendeesAnalytics = "[Analytics Service] Update attendees analytics",
	UpdateTotalAttendeesModulesAnalytics = "[Analytics Service] Update total attendees modules analytics",
	UpdateTotalAttendeesAnalytics = "[Analytics Service] Update total attendees analytics",

	// Chats analytics
	UpdateChatsAnalytics = "[Analytics Service] Update chats analytics",
	UpdateTotalChats = "[Analytics Service] Update total chats analytics",
	UpdateTotalChatsUsers = "[Analytics Service] Update total chats users analytics",
	UpdateTotalMessagesChats = "[Analytics Service] Update total messages chats analytics",
	UpdateTotalDiscussionGroups = "[Analytics Service] Update total discussion groups analytics",
	UpdateTotalDiscussionGroupsUsers = "[Analytics Service] Update total discussion groups users analytics",
	UpdateTotalMessagesDiscussionGroups = "[Analytics Service] Update total messages discussion groups analytics",

	// Documents analytics
	UpdateDocumentsAnalytics = "[Analytics Service] Update documents analytics",
	UpdateBestDocuments = "[Analytics Service] Update best documents analytics",
	UpdateTotalDocuments = "[Analytics Service] Update total documents analytics",

	// Feed analytics
	UpdateFeedsAnalytics = "[Analytics Service] Update feeds analytics",
	UpdateTotalFeedsPosts = "[Analytics Service] Update total feeds posts analytics",
	UpdateTotalFeedsLikes = "[Analytics Service] Update total feeds likes analytics",
	UpdateTotalFeedsComments = "[Analytics Service] Update total feeds comments analytics",
	UpdateBestLikedFeeds = "[Analytics Service] Update best liked feeds analytics",
	UpdateBestCommentedFeeds = "[Analytics Service] Update best commented feeds analytics",

	// Galleries analytics
	UpdateGalleriesAnalytics = "[Analytics Service] Update galleries analytics",
	UpdateBestImages = "[Analytics Service] Update best images analytics",
	UpdateTotalImages = "[Analytics Service] Update total images analytics",

	// Users analytics
	UpdateUsersAnalytics = "[Analytics Service] Update users analytics",
	UpdateTotalUniqueUsers = "[Analytics Service] Update total unique users analytics",
	UpdateCurrentConnectedAttendees = "[Analytics Service] Update total of current connected attendees analytics",
	UpdateCurrentConnectedSpeakers = "[Analytics Service] Update total of current connected speakers analytics",
	UpdateTotalUsersByModule = "[Analytics Service] Update total users by module analytics",
	UpdateTotalAttendees = "[Analytics Service] Update total attendees analytics",
	UpdateTotalSpeakers = "[Analytics Service] Update total speakers analytics",

	// Schedules analytics
	UpdateSchedulesAnalytics = "[Analytics Service] Update schedules analytics",
	UpdateTotalSchedulesModulesAnalytics = "[Analytics Service] Update total schedules modules analytics",
	UpdateTotalSchedulesSessionsAnalytics = "[Analytics Service] Update total schedules sessions analytics",

	// Speakers analytics
	UpdateSpeakersAnalytics = "[Analytics Service] Update Speakers analytics",
	UpdateTotalSpeakersModulesAnalytics = "[Analytics Service] Update total Speakers modules analytics",
	UpdateTotalSpeakersAnalytics = "[Analytics Service] Update total Speakers analytics",

	// Tracking analytics
	UpdateTrackingAnalytics = "[Analytics Service] Update tracking analytics",
	UpdateUserRoadTrackingAnalytics = "[Analytics Service] Update user tracking analytics",

	// Visios analytics
	UpdateVisiosAnalytics = "[Analytics Service] Update visios analytics",
	UpdateTotalPIM = "[Analytics Service] Update total pim visios analytics",
	UpdateTotalRoomsForEvent = "[Analytics Service] Update total rooms for event visios analytics",
	UpdateTotalRoomsBetween2 = "[Analytics Service] Update total rooms for 2 visios analytics"
}

/******************************************/
/*********   General analytics   **********/
/******************************************/
export const UpdateGeneralAnalytics = createAction(
	AnalyticsActionsTypes.UpdateGeneralAnalytics,
	props<{ payload: IAnalyticsGeneral }>()
);
export const UpdateTotalAccess = createAction(AnalyticsActionsTypes.UpdateTotalAccess, props<{ payload: number }>());
export const UpdateTotalProfilEdition = createAction(
	AnalyticsActionsTypes.UpdateTotalProfilEdition,
	props<{ payload: number }>()
);
export const UpdateBestConsultedModules = createAction(
	AnalyticsActionsTypes.UpdateBestConsultedModules,
	props<{ payload: any[] }>()
);

/******************************************/
/**********  Attendees analytics  *********/
/******************************************/

export const UpdateAttendeesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateAttendeesAnalytics,
	props<{ payload: IAnalyticsAttendees }>()
);
export const UpdateTotalAttendeesModulesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTotalAttendeesModulesAnalytics,
	props<{ payload: number }>()
);
export const UpdateTotalAttendeesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTotalAttendeesAnalytics,
	props<{ payload: number }>()
);

/******************************************/
/***********  Chats analytics  ************/
/******************************************/
export const UpdateChatsAnalytics = createAction(
	AnalyticsActionsTypes.UpdateChatsAnalytics,
	props<{ payload: IAnalyticsChats }>()
);
export const UpdateTotalChats = createAction(AnalyticsActionsTypes.UpdateTotalChats, props<{ payload: number }>());
export const UpdateTotalChatsUsers = createAction(
	AnalyticsActionsTypes.UpdateTotalChatsUsers,
	props<{ payload: number }>()
);
export const UpdateTotalMessagesChats = createAction(
	AnalyticsActionsTypes.UpdateTotalMessagesChats,
	props<{ payload: number }>()
);
export const UpdateTotalDiscussionGroups = createAction(
	AnalyticsActionsTypes.UpdateTotalDiscussionGroups,
	props<{ payload: number }>()
);
export const UpdateTotalDiscussionGroupsUsers = createAction(
	AnalyticsActionsTypes.UpdateTotalDiscussionGroupsUsers,
	props<{ payload: number }>()
);
export const UpdateTotalMessagesDiscussionGroups = createAction(
	AnalyticsActionsTypes.UpdateTotalMessagesDiscussionGroups,
	props<{ payload: number }>()
);

/******************************************/
/*********  Documents analytics  **********/
/******************************************/
export const UpdateDocumentsAnalytics = createAction(
	AnalyticsActionsTypes.UpdateDocumentsAnalytics,
	props<{ payload: IAnalyticsDocuments }>()
);
export const UpdateBestDocuments = createAction(AnalyticsActionsTypes.UpdateBestDocuments, props<{ payload: any[] }>());
export const UpdateTotalDocuments = createAction(
	AnalyticsActionsTypes.UpdateTotalDocuments,
	props<{ payload: number }>()
);

/******************************************/
/*********    Feeds analytics    **********/
/******************************************/
export const UpdateFeedsAnalytics = createAction(
	AnalyticsActionsTypes.UpdateFeedsAnalytics,
	props<{ payload: IAnalyticsFeeds }>()
);
export const UpdateTotalFeedsPosts = createAction(
	AnalyticsActionsTypes.UpdateTotalFeedsPosts,
	props<{ payload: number }>()
);
export const UpdateTotalFeedsLikes = createAction(
	AnalyticsActionsTypes.UpdateTotalFeedsLikes,
	props<{ payload: number }>()
);
export const UpdateTotalFeedsComments = createAction(
	AnalyticsActionsTypes.UpdateTotalFeedsComments,
	props<{ payload: number }>()
);
export const UpdateBestLikedFeeds = createAction(
	AnalyticsActionsTypes.UpdateBestLikedFeeds,
	props<{ payload: any[] }>()
);
export const UpdateBestCommentedFeeds = createAction(
	AnalyticsActionsTypes.UpdateBestCommentedFeeds,
	props<{ payload: any[] }>()
);

/******************************************/
/*********  Galleries analytics  **********/
/******************************************/
export const UpdateGalleriesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateGalleriesAnalytics,
	props<{ payload: IAnalyticsGalleries }>()
);
export const UpdateBestImages = createAction(AnalyticsActionsTypes.UpdateBestImages, props<{ payload: any[] }>());
export const UpdateTotalImages = createAction(AnalyticsActionsTypes.UpdateTotalImages, props<{ payload: number }>());

/******************************************/
/**********  Schedules analytics  *********/
/******************************************/
export const UpdateSchedulesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateSchedulesAnalytics,
	props<{ payload: IAnalyticsSchedules }>()
);
export const UpdateTotalSchedulesModulesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTotalSchedulesModulesAnalytics,
	props<{ payload: number }>()
);
export const UpdateTotalSchedulesSessionsAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTotalSchedulesSessionsAnalytics,
	props<{ payload: number }>()
);

/******************************************/
/**********  Speakers analytics  *********/
/******************************************/
export const UpdateSpeakersAnalytics = createAction(
	AnalyticsActionsTypes.UpdateSpeakersAnalytics,
	props<{ payload: IAnalyticsSpeakers }>()
);
export const UpdateTotalSpeakersModulesAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTotalSpeakersModulesAnalytics,
	props<{ payload: number }>()
);
export const UpdateTotalSpeakersAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTotalSpeakersAnalytics,
	props<{ payload: number }>()
);

/******************************************/
/**********  Tracking analytics  **********/
/******************************************/
export const UpdateTrackingAnalytics = createAction(
	AnalyticsActionsTypes.UpdateTrackingAnalytics,
	props<{ payload: IAnalyticsTracking }>()
);
export const UpdateUserRoadTrackingAnalytics = createAction(
	AnalyticsActionsTypes.UpdateUserRoadTrackingAnalytics,
	props<{ payload: any[] }>()
);

/******************************************/
/*********    Users analytics    **********/
/******************************************/
export const UpdateUsersAnalytics = createAction(
	AnalyticsActionsTypes.UpdateUsersAnalytics,
	props<{ payload: IAnalyticsUsers }>()
);
export const UpdateTotalUniqueUsers = createAction(
	AnalyticsActionsTypes.UpdateTotalUniqueUsers,
	props<{ payload: number }>()
);
export const UpdateCurrentConnectedAttendees = createAction(
	AnalyticsActionsTypes.UpdateCurrentConnectedAttendees,
	props<{ payload: number }>()
);
export const UpdateCurrentConnectedSpeakers = createAction(
	AnalyticsActionsTypes.UpdateCurrentConnectedSpeakers,
	props<{ payload: number }>()
);
export const UpdateTotalUsersByModule = createAction(
	AnalyticsActionsTypes.UpdateTotalUsersByModule,
	props<{ payload: any[] }>()
);
export const UpdateTotalAttendees = createAction(
	AnalyticsActionsTypes.UpdateTotalAttendees,
	props<{ payload: number }>()
);
export const UpdateTotalSpeakers = createAction(
	AnalyticsActionsTypes.UpdateTotalSpeakers,
	props<{ payload: number }>()
);

/******************************************/
/*********   Visios analytics    **********/
/******************************************/
export const UpdateVisiosAnalytics = createAction(
	AnalyticsActionsTypes.UpdateVisiosAnalytics,
	props<{ payload: IAnalyticsVisios }>()
);
export const UpdateTotalPIM = createAction(AnalyticsActionsTypes.UpdateTotalPIM, props<{ payload: number }>());
export const UpdateTotalRoomsForEvent = createAction(
	AnalyticsActionsTypes.UpdateTotalRoomsForEvent,
	props<{ payload: number }>()
);
export const UpdateTotalRoomsBetween2 = createAction(
	AnalyticsActionsTypes.UpdateTotalRoomsBetween2,
	props<{ payload: number }>()
);
