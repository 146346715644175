import {
	Component,
	DoCheck,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	SimpleChanges,
	ViewChild
} from "@angular/core";
import { limit, orderBy, where } from "@angular/fire/firestore";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, combineLatest, firstValueFrom, interval } from "rxjs";
import { map, take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeLastAccess } from "src/app/shared/enums/type-last-access";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, IMessage, IModule, IVisio } from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { IModuleUpdatedSettings } from "src/app/shared/interfaces/modules.interfaces";
import { IReport } from "src/app/shared/interfaces/reports.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getSpecificChat } from "src/app/shared/selectors/chats.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	ChatsService,
	EventUsersService,
	FirestoreService,
	LastAccessService,
	NotificationsService,
	ReportsService,
	StorageService,
	UtilityService,
	VisiosService
} from "src/app/shared/services";

@Component({
	selector: "app-chat-details",
	templateUrl: "./chat-details.component.html",
	styleUrls: ["./chat-details.component.scss"]
})
export class ChatDetailsComponent implements OnInit, OnDestroy, DoCheck {
	@ViewChild("msgsComp") msgsComp: ElementRef;
	subscriptions: Subscription[] = [];

	@Input() componentMode: boolean = false;
	@Input() canGoBack: boolean = false;
	@Output() changeView: EventEmitter<{ segmentType: string; type: string; uid: string }> = new EventEmitter();

	isMobile: boolean = false;
	loader: boolean = true;

	@Input() eventId: string;
	event: IEvent;
	@Input() moduleId: string;
	module: IModule;
	moduleUpdatedSettings: IModuleUpdatedSettings;
	@Input() chatId: string;
	chat: IChat;
	chatVisio: IVisio;
	messages: IMessage[] = [];
	eventUser: IEventUser;
	interlocutor: IEventUser; // Only for one to one chat

	isVisioEnabled: boolean = false;
	btnVideoCallVisibilty: boolean = false;

	message: string = "";

	loaderUploadPic: boolean = false;
	uploadedFile: string;

	showVisioWebApp: boolean = false;
	urlVisio: string;
	msgLinkedToVisioOpened: IMessage;

	showEmojiPicker: boolean = false;
	emojisExcluded: string[] = ["flags", "symbols", "places", "objects"];
	sets = ["native", "google", "twitter", "facebook", "emojione", "apple", "messenger"];
	set: any = "native";
	init: boolean = false;
	initScrolling: boolean = false;

	sending: boolean = false;
	creatingVisio: boolean = false;
	android: boolean = false;

	constructor(
		private SAnalytics: AnalyticsService,
		private SFirestore: FirestoreService,
		private store: Store,
		private SChats: ChatsService,
		private SEventUsers: EventUsersService,
		private SLastAccess: LastAccessService,
		private SNotifications: NotificationsService,
		private SReports: ReportsService,
		private STranslate: TranslateService,
		private SStorage: StorageService,
		private SUtility: UtilityService,
		private SVisios: VisiosService,
		private platform: Platform,
		private navCtrl: NavController,
		private modalCtrl: ModalController
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) ||
			this.platform.is("mobileweb") ||
			window.innerWidth < 768
				? true
				: false;
		this.android =
			this.platform.is("android") &&
			this.platform.is("mobile") &&
			window.innerWidth < 768 &&
			!this.platform.is("mobileweb")
				? true
				: false;
	}

	ngOnInit() {
		this.init = false;
		this.initScrolling = false;
		if (this.componentMode) {
			this.initDatas();
		}

		this.SVisios.createVisioSubject.subscribe((createVisio) => {
			if (createVisio && this.chat && this.chat.type === 0) {
				this.createVisio();
			}
		});
	}

	ionViewWillEnter() {
		this.init = false;
		this.initScrolling = false;
		if (!this.componentMode) {
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.chatId = params.chatId;
							this.subscriptions.forEach((sub) => sub.unsubscribe());

							// Analytics
							this.SAnalytics.chatAccess(this.eventId, this.moduleId, this.chatId);

							this.initDatas();
						});
				});
		}
		const toasts = document.querySelectorAll('ion-toast[id*="_globalMsg"]');
		if (toasts) {
			for (let i = 0; i < toasts.length; i++) {
				(toasts[i] as HTMLIonToastElement).style.display = toasts[i].id.includes("_globalMsg") ? "none" : "";
			}
		}
	}

	ionViewDidEnter() {
		document.querySelectorAll("ion-toast").forEach((item) => {
			item.id.split("_")[1] == this.chatId && item.remove();
		});
	}

	ngDoCheck(): void {
		if (this.messages.length > 2 && !this.initScrolling) {
			if (this.msgsComp && this.msgsComp.nativeElement && this.msgsComp.nativeElement.clientHeight > 0) {
				this.scrollToBottom();
				this.initScrolling = true;
			}
		}
	}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes && changes.chatId && !changes.chatId.firstChange) {
			this.subscriptions.forEach((sub) => sub?.unsubscribe());
			this.event = null;
			this.module = null;
			this.chat = null;
			this.messages = [];
			this.eventUser = null;
			this.initDatas();
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		if (!this.componentMode) {
			this.store.dispatch(ResetHeaderState(null));
		}
		this.updateLastAccess();
		this.showEmojiPicker = false;
		const toasts = document.querySelectorAll('ion-toast[id*="_globalMsg"]');
		if (toasts) {
			for (let i = 0; i < toasts.length; i++) {
				(toasts[i] as HTMLIonToastElement).style.display = toasts[i].id.includes("_globalMsg") ? "block" : "";
			}
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Scroll to bottom
	 */
	scrollToBottom() {
		if (this.messages.length > 2) {
			const checkInterval = interval(100).subscribe(() => {
				if (this.msgsComp && this.msgsComp.nativeElement && this.msgsComp.nativeElement.clientHeight > 0) {
					this.msgsComp.nativeElement.scrollTop = this.msgsComp.nativeElement.scrollHeight;
					checkInterval.unsubscribe();
				}
			});
		}
	}

	/**
	 * Init datas
	 */
	initDatas() {
		this.getEvent();
		this.getModule();
		this.getChatAndEventUser();
		this.getChatVisio();
		this.getMessages(200);
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);

		this.subscriptions.push(
			this.SFirestore.valueChangesDocument(
				`events/${this.event.uid}/modules-updated-settings/${this.moduleId}`
			).subscribe({
				next: (moduleUpdatedSettings) => {
					if (!_.isEqual(this.moduleUpdatedSettings, moduleUpdatedSettings))
						this.moduleUpdatedSettings = moduleUpdatedSettings;
				}
			})
		);
	}

	/**
	 * Getting chat and event user
	 */
	getChatAndEventUser() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getSpecificChat(this.chatId)),
				this.store.select(getCurrentEventUser)
			]).subscribe((results) => {
				if (!_.isEqual(this.chat, results[0]) || !_.isEqual(this.eventUser, results[1])) {
					if (this.chat && results[0] && this.chat.lastMessageId !== results[0].lastMessageId) {
						this.getMessages(1);
					}
					this.chat = results[0];
					this.eventUser = results[1];
					if (this.chat && this.eventUser) {
						if (!this.componentMode) {
							this.updateHeaderState();
						}
						if (!this.init) {
							this.init = true;
							this.updateLastAccess();
						}
					}
				} else if (this.chat && !this.eventUser) {
					if (!this.componentMode) {
						this.updateHeaderState();
					}
					if (!this.init) {
						this.init = true;
					}
				}
			})
		);
	}

	/**
	 * Get chat visio
	 */
	getChatVisio() {
		this.subscriptions.push(
			this.SVisios.getChatVisio(this.eventId, this.moduleId, this.chatId).subscribe((visio) => {
				if (!_.isEqual(this.chatVisio, visio)) {
					this.chatVisio = visio;
				}
			})
		);
	}

	/**
	 * Update header title
	 */
	updateHeaderState() {
		if (this.chat.type === 0) {
			this.getInterlocutor();
		} else if (this.chat.type === 1) {
			this.store.dispatch(
				GetHeaderState({
					payload: {
						item: this.chat,
						module: this.module,
						title: {
							ArAR: this.chat.options.name,
							DeDE: this.chat.options.name,
							EnUS: this.chat.options.name,
							EsES: this.chat.options.name,
							FrFR: this.chat.options.name,
							PtBR: this.chat.options.name
						},
						type: TypeHeader.CHAT_DETAIL
					}
				})
			);
		} else {
			this.store.dispatch(
				GetHeaderState({
					payload: {
						item: this.chat,
						module: this.module,
						title: this.module.name,
						type: TypeHeader.CHAT_DETAIL
					}
				})
			);
		}
	}

	/**
	 * Get interlocutor
	 */
	getInterlocutor() {
		if (this.chat && this.chat.type === 0 && this.chat.members.length === 2) {
			const interlocutor = this.chat.members.filter((userId) => userId !== this.eventUser.uid)[0];
			this.subscriptions.push(
				this.SEventUsers.getEventUser(this.eventId, interlocutor).subscribe({
					next: (interlocutor) => {
						if (!_.isEqual(this.interlocutor, interlocutor)) {
							this.interlocutor = interlocutor;
							this.store.dispatch(
								GetHeaderState({
									payload: {
										item: this.chat,
										module: this.module,
										title: {
											ArAR: this.interlocutor?.name,
											DeDE: this.interlocutor?.name,
											EnUS: this.interlocutor?.name,
											EsES: this.interlocutor?.name,
											FrFR: this.interlocutor?.name,
											PtBR: this.interlocutor?.name
										},
										type: TypeHeader.CHAT_DETAIL
									}
								})
							);
						}
					}
				})
			);
		} else {
			this.store.dispatch(
				GetHeaderState({
					payload: {
						item: this.chat,
						module: this.module,
						title: this.module.name,
						type: TypeHeader.CHAT_DETAIL
					}
				})
			);
		}
	}

	/**
	 * Get all messages
	 */
	getMessages(limitMessages: number) {
		// this.subscriptions.push(
		this.SFirestore.getDocumentsObs(
			`events/${this.eventId}/modules/${this.moduleId}/chats/${this.chatId}/chat-messages`,
			[limit(limitMessages), orderBy("sendAt", "desc")]
		)
			.pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as IMessage)))
			.subscribe({
				next: (messages) => {
					if (!_.isEqual(this.messages, messages)) {
						if (limitMessages > 1) {
							this.messages = messages;
						} else {
							this.messages.push(messages[0]);
						}

						this.messages = this.messages.sort((a, b) =>
							a.sendAt > b.sendAt ? 1 : a.sendAt < b.sendAt ? -1 : 0
						);
						this.scrollToBottom();
					}
				}
			});
	}

	/**
	 * Update last access of chat
	 */
	updateLastAccess() {
		let lastAccess = {};
		if (this.chat) {
			if (!this.chat.lastAccess) {
				lastAccess = {};
			} else {
				lastAccess = _.cloneDeep(this.chat.lastAccess);
			}

			if (this.eventUser) {
				lastAccess[this.eventUser.uid] = DateTime.local().setZone(this.event.timezone).toISO();
				this.SChats.updateChat(this.eventId, this.moduleId, this.chat.uid, {
					lastAccess: lastAccess
				});
				this.SLastAccess.createLastAccess(
					this.eventId,
					this.moduleId,
					this.eventUser.uid,
					TypeLastAccess.CHAT,
					{
						chatId: this.chatId
					},
					`events/${this.eventId}/modules/${this.moduleId}/chats/${this.chatId}/last-access`
				);
			}
		}
	}

	/**
	 * Add emoji
	 * @param evt
	 */
	addEmoji(evt: any) {
		this.message = `${this.message}${evt.emoji.native}`;
	}

	/**
	 * Get an image to send
	 */
	async getImageToSend() {
		this.loaderUploadPic = true;
		try {
			const image = await this.SStorage.getPicture();
			this.uploadedFile = "data:image/jpeg;base64," + image.base64String;
			this.loaderUploadPic = false;
		} catch (error) {
			this.loaderUploadPic = false;
		}
	}

	/**
	 * Convert date
	 * @param date
	 */
	getDate(date: string) {
		return DateTime.fromISO(date).toLocaleString(DateTime.DATETIME_SHORT);
	}

	/**
	 * Create a message
	 * @returns
	 */
	async createMessage() {
		if (!this.chat) {
			this.goBackToList();
			return;
		}

		// Prevent sending message for non authenticate users (event public case)
		if (!this.eventUser) {
			this.SUtility.alertLoginRegister(this.eventId, this.STranslate.instant("chat.chat"));
			return;
		}

		this.showEmojiPicker = false;
		if (!this.message && this.message.trim() === "" && (!this.uploadedFile || this.uploadedFile === undefined))
			return;

		try {
			if (!this.sending) {
				this.sending = true;
				const message: IMessage = {
					uid: this.SFirestore.createId(
						`events/${this.eventId}/modules/${this.moduleId}/chats/${this.chatId}/chat-messages`
					),
					appointment: null,
					content: this.message.replace(/<[^>]*>/g, ""),
					chatId: this.chatId,
					chatType: this.chat.type,
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					imageUrl: "",
					moduleId: this.moduleId,
					sender: {
						uid: this.eventUser.uid,
						eventId: this.eventUser.eventId,
						moduleId: this.eventUser.moduleId,
						identifier: this.eventUser.identifier,
						name: this.eventUser.name,
						queryName: this.eventUser.queryName,
						photoUrl: this.eventUser.photoUrl,
						email: this.eventUser.email,
						type: this.eventUser.type
					},
					sendAt: DateTime.local().setZone(this.event.timezone).toISO(),
					type: this.uploadedFile ? 1 : 0,
					visioUrl: ""
				};

				if (this.uploadedFile) {
					message.imageUrl = await this.SStorage.uploadBase64(
						this.uploadedFile.replace("data:image/jpeg;base64,", ""),
						"image/jpeg",
						`events/${this.eventId}/modules/${this.moduleId}/chats/${this.chatId}/chat-messages/${
							message.uid
						}/${this.eventUser ? this.eventUser.uid : "anonymous"}/${message.uid}.jpeg`
					);
				}
				await this.SChats.createMessage(this.eventId, this.moduleId, this.chatId, message);
				this.scrollToBottom();

				const copyMess = _.cloneDeep(this.message);
				this.message = "";
				this.uploadedFile = null;

				// Analytics
				this.chat.type === 0
					? this.SAnalytics.sendChatOrGroupMessage(
							this.eventId,
							this.moduleId,
							this.eventUser,
							this.chatId,
							TypeTracking.SEND_MESSAGE_ON_CHAT,
							message
					  )
					: this.SAnalytics.sendChatOrGroupMessage(
							this.eventId,
							this.moduleId,
							this.eventUser,
							this.chatId,
							TypeTracking.SEND_MESSAGE_ON_GROUP,
							message
					  );

				let members: string[] = [];
				if (this.chat.type === 0 || this.chat.type === 1) {
					members = _.cloneDeep(this.chat.members);
				}

				if (!members.includes(this.eventUser.uid)) {
					members.push(this.eventUser.uid);
				}

				// Update module settings for analytics
				if (this.moduleUpdatedSettings) {
					if (this.chat.type === 0) {
						members.forEach((member) => {
							if (!this.moduleUpdatedSettings.chatMembersArray.includes(member)) {
								this.moduleUpdatedSettings.chatMembersArray.push(member);
							}
						});
						const updatedDatas = { ...this.moduleUpdatedSettings };
						["eventId", "moduleId", "uid"].forEach((key) => {
							delete updatedDatas[key];
						});
						this.SFirestore.updateDocument(
							`events/${this.eventId}/modules-updated-settings/${this.moduleUpdatedSettings.uid}`,
							updatedDatas
						);
					} else if (this.chat.type === 1) {
						members.forEach((member) => {
							if (!this.moduleUpdatedSettings.discussionGroupsMembersArray.includes(member)) {
								this.moduleUpdatedSettings.discussionGroupsMembersArray.push(member);
							}
						});
						const updatedDatas = { ...this.moduleUpdatedSettings };
						["eventId", "moduleId", "uid"].forEach((key) => {
							delete updatedDatas[key];
						});
						this.SFirestore.updateDocument(
							`events/${this.eventId}/modules-updated-settings/${this.moduleUpdatedSettings.uid}`,
							updatedDatas
						);
					}
				} else {
					const newModuleUpdatedSettings: IModuleUpdatedSettings = {
						chatMembersArray: [],
						discussionGroupsMembersArray: [],
						eventId: this.event.uid,
						moduleId: this.chat.moduleId,
						searchStringDatas: [],
						uid: this.chat.moduleId
					};

					if (this.chat.type === 0) {
						members.forEach((member) => {
							if (!newModuleUpdatedSettings.chatMembersArray.includes(member)) {
								newModuleUpdatedSettings.chatMembersArray.push(member);
							}
						});
					} else if (this.chat.type === 1) {
						members.forEach((member) => {
							if (!newModuleUpdatedSettings.discussionGroupsMembersArray.includes(member)) {
								newModuleUpdatedSettings.discussionGroupsMembersArray.push(member);
							}
						});
					}
					this.SFirestore.setDocument(
						`events/${this.eventId}/modules-updated-settings/${newModuleUpdatedSettings.uid}`,
						newModuleUpdatedSettings
					);
				}
				await this.SChats.updateChat(this.eventId, this.moduleId, this.chat.uid, {
					lastMessageId: message.uid,
					lastMessageUser: {
						uid: this.eventUser.uid,
						eventId: this.eventUser.eventId,
						moduleId: this.eventUser.moduleId,
						identifier: this.eventUser.identifier,
						name: this.eventUser.name,
						queryName: this.eventUser.queryName,
						email: this.eventUser.email,
						type: this.eventUser.type
					},
					lastUpdated: DateTime.local().toISO(),
					members: members
				});
				this.sending = false;
				if (this.event && this.event.settings && this.event.settings.allowChatPopup && this.chat.allowNotifs) {
					let oneSignalUsersIds: string[] = [];

					if (this.chat.type === 0) {
						oneSignalUsersIds = await firstValueFrom(
							this.SNotifications.getOneSignalForUsers(
								this.eventId,
								members.filter((member) => member !== this.eventUser.uid),
								this.chatId
							)
						);
					} else if (this.chat.type === 1) {
						let publicDevices = (
							await firstValueFrom(this.SNotifications.getOneSignalPublicDeviceIds(this.eventId))
						).map((device) => device.userId);
						if (this.chat.options.groupsLink.linked && this.chat.options.groupsLink.groups.length > 0) {
							oneSignalUsersIds = await firstValueFrom(
								this.SNotifications.getOneSignalForUsersFromGroups(
									this.eventId,
									this.chat.options.groupsLink.groups,
									this.chatId,
									"chatsNotifs",
									this.eventUser.uid
								)
							);
							publicDevices = publicDevices.filter((device) => !oneSignalUsersIds.includes(device));
							oneSignalUsersIds = oneSignalUsersIds.concat(publicDevices);
						} else if (
							this.chat.options.specificMembersLink.linked &&
							this.chat.options.specificMembersLink.specifics.filter((id) => id !== this.eventUser.uid)
								.length > 0
						) {
							const eventUsersFromIds = await firstValueFrom(
								this.SEventUsers.getSpecificEventUsersForEvent(
									this.event.uid,
									null,
									this.chat.options.specificMembersLink.specifics.filter(
										(id) => id !== this.eventUser.uid
									)
								)
							);
							oneSignalUsersIds = eventUsersFromIds
								.filter(
									(eventUser) =>
										eventUser &&
										eventUser.uid !== this.eventUser.uid &&
										eventUser.options &&
										eventUser.options.notifOneSignalConfig &&
										eventUser.options.notifOneSignalConfig.userId &&
										(!eventUser.options ||
											!eventUser.options.chatsNotifs ||
											eventUser.options.chatsNotifs[this.chatId] === undefined ||
											eventUser.options.chatsNotifs[this.chatId])
								)
								.map((eventUser) => eventUser.options.notifOneSignalConfig.userId);

							publicDevices = publicDevices.filter((device) => !oneSignalUsersIds.includes(device));
							oneSignalUsersIds = oneSignalUsersIds.concat(publicDevices);
						} else {
							let oneSignalUsersIds = await firstValueFrom(
								this.SNotifications.getOneSignalForAllUsers(
									this.eventId,
									this.chatId,
									"chatsNotifs",
									this.eventUser.uid,
									[
										where("eventId", "==", this.eventId),
										where("options.notifOneSignalConfig.userId", "!=", null)
									]
								)
							);
							publicDevices = publicDevices.filter((device) => !oneSignalUsersIds.includes(device));
							oneSignalUsersIds = oneSignalUsersIds.concat(publicDevices);
						}
					}

					if (oneSignalUsersIds.length > 0) {
						firstValueFrom(
							this.SNotifications.sendNotification(
								this.event,
								this.module,
								message,
								{
									ar: this.chat.type === 0 ? this.eventUser.name : this.chat.options.name,
									en: this.chat.type === 0 ? this.eventUser.name : this.chat.options.name,
									es: this.chat.type === 0 ? this.eventUser.name : this.chat.options.name,
									fr: this.chat.type === 0 ? this.eventUser.name : this.chat.options.name,
									pt: this.chat.type === 0 ? this.eventUser.name : this.chat.options.name,
									de: this.chat.type === 0 ? this.eventUser.name : this.chat.options.name
								},
								{
									ar: copyMess ? copyMess : "لقد تلقيت رسالة جديدة",
									en: copyMess ? copyMess : "You have received a new message",
									es: copyMess ? copyMess : "Ha recibido un nuevo mensaje",
									fr: copyMess ? copyMess : "Vous avez reçu un nouveau message",
									pt: copyMess ? copyMess : "Você recebeu uma nova mensagem",
									de: copyMess ? copyMess : "Sie haben eine neue Nachricht erhalten"
								},
								oneSignalUsersIds,
								{
									redirectUrl: `event/${this.eventId}/chats/${this.moduleId}/chat/${this.chatId}`
								},
								""
							)
						);
					}
					// }
				}
			}
		} catch (error) {
			this.message = "";
			this.uploadedFile = null;
			this.sending = false;
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.cannot_send_message"),
				3000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * Navigate to path
	 * @param path
	 */
	goToProfile(eventUser: any) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/profile/${eventUser.moduleId}/${eventUser.uid}`);
	}

	/**
	 * Go back to chats list
	 */
	goBackToList() {
		if (this.componentMode) {
			this.changeView.emit({ segmentType: "chats", type: "chats", uid: "" });
		} else {
			this.SUtility.callGoBackOnHeader();
		}
	}

	/**
	 * Create visio and send mess on chat
	 */
	async createVisio() {
		const loader = await this.SUtility.presentLoading(
			this.STranslate.instant("visios.creating-visio"),
			0,
			"bubbles"
		);
		loader.present();
		if (!this.creatingVisio) {
			this.creatingVisio = true;
			try {
				// Creating visio if necessary
				const visio: IVisio = await firstValueFrom(
					this.SVisios.createNewVisioFor2(this.eventId, this.moduleId, this.chatId, [
						this.eventUser.uid,
						this.interlocutor.uid
					])
				);

				let members: string[] = [];
				if (this.chat.type === 0 || this.chat.type === 1) {
					members = _.cloneDeep(this.chat.members);
				}

				if (!members.includes(this.eventUser.uid)) {
					members.push(this.eventUser.uid);
				}

				const message: IMessage = {
					uid: this.SFirestore.createId(
						`events/${this.eventId}/modules/${this.moduleId}/chats/${this.chatId}/chat-messages`
					),
					appointment: null,
					content: this.STranslate.instant("texts.visio-launched"),
					chatId: this.chat.uid,
					chatType: this.chat.type,
					creationDate: DateTime.local().toISO(),
					eventId: this.event.uid,
					imageUrl: "",
					moduleId: this.moduleId,
					sender: {
						uid: this.eventUser.uid,
						eventId: this.eventUser.eventId,
						moduleId: this.eventUser.moduleId,
						identifier: this.eventUser.identifier,
						name: this.eventUser.name,
						queryName: this.eventUser.queryName,
						photoUrl: this.eventUser.photoUrl,
						email: this.eventUser.email,
						type: this.eventUser.type
					},
					sendAt: DateTime.local().setZone(this.event.timezone).toISO(),
					type: 2,
					visioUrl: visio.url
				};

				// Create visio message on chat
				await this.SChats.createVisioMessage(this.event, this.moduleId, this.chat, message);

				await this.SChats.updateChat(this.eventId, this.moduleId, this.chat.uid, {
					lastMessageId: message.uid,
					lastMessageUser: {
						uid: this.eventUser.uid,
						eventId: this.eventUser.eventId,
						moduleId: this.eventUser.moduleId,
						identifier: this.eventUser.identifier,
						name: this.eventUser.name,
						queryName: this.eventUser.queryName,
						email: this.eventUser.email,
						type: this.eventUser.type
					},
					lastUpdated: DateTime.local().toISO(),
					members: members
				});

				// Analytics
				this.SAnalytics.sendChatOrGroupMessage(
					this.eventId,
					this.moduleId,
					this.eventUser,
					this.chatId,
					TypeTracking.SEND_VISIO_MESSAGE_ON_CHAT,
					null
				);
				if (this.event && this.event.settings && this.event.settings.allowChatPopup && this.chat.allowNotifs) {
					let members: string[] = [];
					if (this.chat.type === 0) {
						// Basic chat
						members = this.chat.members.filter((member) => member !== this.eventUser.uid);
					}
					let oneSignalUsersIds: string[] = [];

					if (this.chat.type === 0) {
						oneSignalUsersIds = await firstValueFrom(
							this.SNotifications.getOneSignalForUsers(this.eventId, members, this.chatId)
						);
					}

					if (oneSignalUsersIds.length > 0) {
						firstValueFrom(
							this.SNotifications.sendNotification(
								this.event,
								this.module,
								message,
								{
									ar: "تم إطلاق مكالمة فيديو",
									en: "Video call launched",
									es: "Videollamada lanzada",
									fr: "Appel vidéo lancé",
									pt: "Chamada de vídeo lançada",
									de: "Video -oproep gelanceerd"
								},
								{
									ar: this.eventUser.name + " يدعوك للانضمام إلى Visio",
									en: this.eventUser.name + " invites you to join a visio",
									es: this.eventUser.name + " te invita a unirte a un visio",
									fr: this.eventUser.name + " vous invite à rejoindre une visio",
									pt: this.eventUser.name + " convida você a participar de um visio",
									de: this.eventUser.name + " Nodigt u uit om lid te worden van een visio"
								},
								oneSignalUsersIds,
								{
									redirectUrl: `event/${this.eventId}/chats/${this.moduleId}/chat/${this.chatId}`
								},
								""
							)
						);
					}
				}
				this.creatingVisio = false;
				loader.dismiss();
				this.SUtility.presentToast(this.STranslate.instant("visios.visio-created"), 2500, "bottom", "primary");
			} catch (error) {
				this.creatingVisio = false;
				loader.dismiss();
				this.SUtility.presentToast(
					this.STranslate.instant("visios.visio-created-error"),
					2500,
					"bottom",
					"danger"
				);
			}
		}
	}

	/**
	 * Open visio
	 * @returns
	 */
	async openVisio(message: IMessage) {
		const loader = await this.SUtility.presentLoading(
			this.STranslate.instant("visios.opening-visio"),
			0,
			"bubbles"
		);
		loader.present();
		try {
			if (!this.event.settings.allowVisioForTwo) {
				this.SUtility.presentToast(
					this.STranslate.instant("visios.visio-not-available"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}
			this.msgLinkedToVisioOpened = message;

			// Creating visio if necessary
			const visio = await firstValueFrom(
				this.SVisios.createNewVisioFor2(this.eventId, this.moduleId, this.chatId, [
					this.eventUser.uid,
					this.interlocutor.uid
				])
			);

			// Analytics
			this.SAnalytics.visioAccess(this.eventId, this.eventUser);

			if (this.platform.is("mobile") && window.innerWidth < 768) {
				const fullUrl =
					visio.url +
					"?embed&iframeSource=b3app&chat=on&screenshare=on&skipMediaPermissionPrompt&leaveButton=" +
					(this.platform.is("ios") ? "on" : "off");
				loader.dismiss();
				await this.SVisios.openInAppBrowserVisio(fullUrl);
			} else {
				const fullUrl = visio.url + "?embed&iframeSource=b3app";
				this.urlVisio = fullUrl;
				loader.dismiss();
				this.showVisioWebApp = true;
			}
		} catch (e) {
			loader.dismiss();
			this.SUtility.presentToast(this.STranslate.instant("visios.visio-open-error"), 2500, "bottom", "danger");
		}
	}

	/**
	 * Close visio
	 */
	async closeVisio() {
		this.showVisioWebApp = false;

		try {
			if (this.msgLinkedToVisioOpened) {
				const newMessage: IMessage = {
					uid: "",
					appointment: null,
					content: this.STranslate.instant("texts.visio-leaved"),
					chatId: this.chatId,
					chatType: this.chat.type,
					creationDate: DateTime.local().toISO(),
					eventId: this.eventId,
					imageUrl: "",
					moduleId: this.moduleId,
					sender: {
						uid: this.eventUser.uid,
						eventId: this.eventUser.eventId,
						moduleId: this.eventUser.moduleId,
						identifier: this.eventUser.identifier,
						name: this.eventUser.name,
						queryName: this.eventUser.queryName,
						photoUrl: this.eventUser.photoUrl,
						email: this.eventUser.email,
						type: this.eventUser.type
					},
					sendAt: DateTime.local().setZone(this.event.timezone).toISO(),
					type: 2,
					visioUrl: "leaved"
				};

				await this.SChats.createMessage(this.eventId, this.moduleId, this.chatId, newMessage);
				this.msgLinkedToVisioOpened = null;
			}
		} catch (error) {
			this.msgLinkedToVisioOpened = null;
		}
	}

	/**
	 * Open image
	 * @param url
	 */
	async openImage(url: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.imageViewerModal,
				componentProps: {
					url: url
				}
			});
			return await modal.present();
		} catch (error) {
			//("Modal error");
		}
	}

	async openReportAlert(message: IMessage) {
		const alert = await this.SUtility.presentAlert(
			this.STranslate.instant("reports.alert_title"),
			"",
			[
				{
					text: this.STranslate.instant("buttons.no")
				},
				{
					text: this.STranslate.instant("buttons.yes"),
					cssClass: "red-text",
					handler: (values: any) => {
						this.SEventUsers.getSpecificEventUser(this.eventId, message.sender.uid)
							// this.store
							// 	.select(getSpecificEventUser(message.sender.uid))
							.pipe(take(1))
							.subscribe(async (targetUser) => {
								if (targetUser) {
									const newReport: IReport = {
										uid: "",
										comment: values.comment,
										creationDate: DateTime.local().toISO(),
										eventId: this.eventId,
										moduleId: this.moduleId,
										options: {
											chatId: message.chatId,
											messageId: message.uid
										},
										reporterEmail: this.eventUser.email,
										reporterId: this.eventUser.uid,
										reporterIdentifier: this.eventUser.identifier,
										state: "progress",
										targetUserEmail: targetUser.email,
										targetUserId: message.sender.uid,
										targetUserIdentifier: targetUser.identifier,
										targetUserModuleId: targetUser.moduleId,
										type: TypeModule.NEWS_FEED
									};
									await this.SReports.createReport(this.eventId, newReport);
									this.SUtility.presentToast(
										this.STranslate.instant("reports.alert_send"),
										2000,
										"bottom",
										"success"
									);
								}
							});
					}
				}
			],
			[
				{
					label: this.STranslate.instant("labels.comment"),
					name: "comment",
					placeholder: this.STranslate.instant("labels.comment"),
					type: "textarea"
				}
			]
		);
		await alert.present();
	}
}
