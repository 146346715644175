import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { ITreasureHunt, ITreasureHuntQrcode, ITreasureHuntQrcodeResult } from "../interfaces/treasure-hunts.interfaces";
import { checkSameEvent } from "../selectors/gamification.selectors";
import { GetAllTreasureHunts, GetAllTreasureHuntsQrcodes } from "../actions/gamification.actions";
import { FirestoreService } from "./firestore.service";
import { where } from "@angular/fire/firestore";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";

@Injectable({
	providedIn: "root"
})
export class TreasureHuntsService {
	treasureHuntsSub: Subscription;
	treasureHuntsQrcodesSub: Subscription;

	constructor(private SFirestore: FirestoreService, private store: Store) {}

	unsubscribeAll() {
		[this.treasureHuntsSub, this.treasureHuntsQrcodesSub].forEach((sub) => {
			if (sub) sub.unsubscribe();
		});
	}

	/**
	 * Get all treasure hunts of event
	 * @param eventId
	 */
	getTreasureHuntsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "treasureHunts", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.treasureHuntsSub && !this.treasureHuntsSub.closed) {
					return;
				} else if (!sameEvent && this.treasureHuntsSub && !this.treasureHuntsSub.closed) {
					this.treasureHuntsSub.unsubscribe();
				}

				this.treasureHuntsSub = this.SFirestore.collectionGroupValueChangesDocuments("treasure-hunts", [
					where("eventId", "==", eventId),
					where("visibility", "==", true)
				]).subscribe((treasureHunts: ITreasureHunt[]) => {
					this.store.dispatch(GetAllTreasureHunts({ payload: treasureHunts, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initTreasureHunts"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initTreasureHunts", payload: true })
								);
							}
						});
				});
			});
	}

	/**
	 *
	 *
	 *
	 * Treasure hunts qr codes part
	 *
	 *
	 *
	 */

	/**
	 * Get all qrcodes of treasure hunts of event
	 * @param eventId
	 */
	getTreasureHuntsQrcodesOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "treasureHunts", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.treasureHuntsQrcodesSub && !this.treasureHuntsQrcodesSub.closed) {
					return;
				} else if (!sameEvent && this.treasureHuntsQrcodesSub && !this.treasureHuntsQrcodesSub.closed) {
					this.treasureHuntsQrcodesSub.unsubscribe();
				}

				this.treasureHuntsQrcodesSub = this.SFirestore.collectionGroupValueChangesDocuments(
					"treasure-hunt-qrcodes",
					[where("eventId", "==", eventId)]
				).subscribe((treasureHuntsQrcodes: ITreasureHuntQrcode[]) => {
					this.store.dispatch(
						GetAllTreasureHuntsQrcodes({
							payload: treasureHuntsQrcodes,
							eventId: eventId
						})
					);
				});
			});
	}

	/**
	 *
	 *
	 *
	 * Treasure hunts qrcodes results part
	 *
	 *
	 *
	 */

	/**
	 * Get results of treasure hunt
	 * @param eventId
	 * @param moduleId
	 * @param treasureHuntId
	 * @returns
	 */
	getResultsOfTreasureHunt(eventId: string, moduleId: string, treasureHuntId: string, eventUserId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("treasure-hunt-qrcodes-results", [
			where("eventId", "==", eventId),
			where("moduleId", "==", moduleId),
			where("treasureHuntId", "==", treasureHuntId),
			where("userId", "==", eventUserId)
		]);
	}

	/**
	 * Create treasure hunt qrcode result
	 * @param eventId
	 * @param moduleId
	 * @param treasureHuntId
	 * @param qrcodeId
	 * @param result
	 * @returns
	 */
	createTreasureHuntQrcodeResult(
		eventId: string,
		moduleId: string,
		treasureHuntId: string,
		qrcodeId: string,
		result: ITreasureHuntQrcodeResult
	) {
		result.uid = result.uid
			? result.uid
			: this.SFirestore.createId(
					`events/${eventId}/modules/${moduleId}/treasure-hunts/${treasureHuntId}/treasure-hunt-qrcodes/${qrcodeId}/treasure-hunt-qrcodes-results`
			  );
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/treasure-hunts/${treasureHuntId}/treasure-hunt-qrcodes/${qrcodeId}/treasure-hunt-qrcodes-results/${result.uid}`,
			result
		);
	}
}
