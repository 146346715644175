import { createAction, props } from "@ngrx/store";
import { ISchedule } from "../interfaces/schedules.interfaces";

export enum SchedulesActionsTypes {
	GetAllSchedules = "[Schedules Service] Get all schedules of event",
	ResetSchedulesState = "[Schedules Service] Reset schedules state"
}

export const GetAllSchedules = createAction(
	SchedulesActionsTypes.GetAllSchedules,
	props<{ payload: ISchedule[]; eventId: string }>()
);

export const ResetSchedulesState = createAction(SchedulesActionsTypes.ResetSchedulesState, props<any>());
