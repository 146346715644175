import { Component, OnInit } from "@angular/core";

@Component({
	selector: "app-trainings",
	templateUrl: "./trainings.component.html",
	styleUrls: ["./trainings.component.scss"]
})
export class TrainingsComponent implements OnInit {
	constructor() {}

	ngOnInit() {}
}
