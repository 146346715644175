import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IFeedNewsState, IPost } from "../interfaces/feed-news.interfaces";

export const getFeedNewsState = createFeatureSelector<IFeedNewsState>("feedNews");

export const checkSameEvent = (uid: string) =>
	createSelector(getFeedNewsState, (state: IFeedNewsState) => {
		return state.eventId === uid ? true : false;
	});

export const getPosts = createSelector(getFeedNewsState, (state: IFeedNewsState) => _.cloneDeep(state.posts));

export const getPostsOfModule = (moduleId: string) =>
	createSelector(getFeedNewsState, (state: IFeedNewsState) => {
		const posts = _.cloneDeep(state.posts);
		const fixedTopPosts = posts
			.filter((post) => post.fixedTop === 0 && moduleId === post.moduleId)
			.sort((a, b) => (a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0));
		const otherPosts = posts
			.filter((post) => post.fixedTop === 1 && moduleId === post.moduleId)
			.sort((a, b) => (a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0));
		return fixedTopPosts.concat(otherPosts);
	});

export const getSpecificPost = (uid: string) =>
	createSelector(getFeedNewsState, (state: IFeedNewsState) => {
		const post: IPost = state.posts.find((post) => post.uid == uid);
		return post ? _.cloneDeep(post) : null;
	});
