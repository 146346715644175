export enum TypeModule {
	DIVIDER = 1000,
	CARD_EXCHANGE = 110,
	FAVORITES = 100,
	NOTE_TAKING = 90,
	EXTERNAL_INTERACTIVITY = 80,
	SCHEDULE = 70,
	CHAT = 60,
	TREASURE_HUNTS = 33,
	TRACKS = 32,
	CUSTOM_FIELDS = 31,
	// DISCUSSION_GROUP = 30,
	APPOINTMENTS = 29,
	EXTERNAL_LINK = 28,
	// GENERIC = 34, // generic modules
	MANAGER_MODULES = 27,
	LEGAL = 26,
	// ROOM_PAIR = 31, // module for attendee pair hotel
	// INFOBOOTH = 30,
	LOCATION = 25,
	WIDGETS = 24,
	ASK_QUESTION = 23,
	FEEDBACKS = 22,
	// NETWORKING = 25, // module for networking (chat, groups, channels, etc)
	SELF_CHECKIN = 21, // auto checkin for attendees
	MANAGER_GROUP = 20, // manager group
	GAMIFICATION = 19, // gaming module
	QUIZ = 18, // quiz interactivity module
	TRAINING = 17, // training interactivity module
	// SUBSCRIPTION = 19, // subscription interactivity module
	WORDCLOUD = 16, // word cloud interactivity module
	SURVEY = 15, // survey list interactivity module
	RANKINGS = 14,
	// PAGE_CUSTOM_PAGE = 15,
	CUSTOM_PAGE = 13,
	PERSONALSCHEDULE = 12,
	EVENT = 11,
	PROFILE = 10,
	HOME = 9, // What's that ?
	NEWS_FEED = 8,
	CHECKIN = 7,
	NOTIFICATION = 6,
	// MAPS = 6,
	GALLERY = 5,
	INTERACTIVITY = 4,
	DOCUMENT = 3,
	ATTENDEE = 2,
	SPEAKER = 1
}

export const TypeModuleString = {
	[TypeModule.DIVIDER]: "divider",
    [TypeModule.CARD_EXCHANGE]: "cardExchange",
	[TypeModule.FAVORITES]: "favorites",
	[TypeModule.NOTE_TAKING]: "noteTaking",
	[TypeModule.EXTERNAL_INTERACTIVITY]: "externalInteractivity",
	[TypeModule.SCHEDULE]: "schedules",
	[TypeModule.CHAT]: "chats",
	[TypeModule.TREASURE_HUNTS]: "treasureHunts",
	[TypeModule.TRACKS]: "tracks",
	[TypeModule.CUSTOM_FIELDS]: "customFields",
	// [TypeModule.DI]: "discussionGroup",
	[TypeModule.APPOINTMENTS]: "appointments",
	[TypeModule.EXTERNAL_LINK]: "externalLink",
	[TypeModule.MANAGER_MODULES]: "modules",
	[TypeModule.LEGAL]: "legal",
	[TypeModule.LOCATION]: "location",
	[TypeModule.WIDGETS]: "widget",
	[TypeModule.ASK_QUESTION]: "askQuestion",
	[TypeModule.FEEDBACKS]: "feedbacks",
	[TypeModule.SELF_CHECKIN]: "selfCheckin",
	[TypeModule.MANAGER_GROUP]: "groups",
	[TypeModule.GAMIFICATION]: "gamification",
	[TypeModule.QUIZ]: "quiz",
	[TypeModule.TRAINING]: "training",
	[TypeModule.WORDCLOUD]: "wordCloud",
	[TypeModule.SURVEY]: "survey",
	[TypeModule.RANKINGS]: "rankings",
	[TypeModule.CUSTOM_PAGE]: "customPage",
	[TypeModule.PERSONALSCHEDULE]: "personalSchedule",
	[TypeModule.EVENT]: "event",
	[TypeModule.PROFILE]: "profile",
	[TypeModule.HOME]: "home",
	[TypeModule.NEWS_FEED]: "feedNews",
	[TypeModule.CHECKIN]: "checkin",
	[TypeModule.NOTIFICATION]: "notifications",
	[TypeModule.GALLERY]: "gallery",
	[TypeModule.INTERACTIVITY]: "interactivity",
	[TypeModule.DOCUMENT]: "documents",
	[TypeModule.ATTENDEE]: "attendees",
	[TypeModule.SPEAKER]: "speakers"
};

export const TypeModuleNumber = {
	divider: TypeModule.DIVIDER,
    cardExchange: TypeModule.CARD_EXCHANGE,
	favorites: TypeModule.FAVORITES,
	noteTaking: TypeModule.NOTE_TAKING,
	externalInteractivity: TypeModule.EXTERNAL_INTERACTIVITY,
	schedules: TypeModule.SCHEDULE,
	chats: TypeModule.CHAT,
	treasureHunts: TypeModule.TREASURE_HUNTS,
	tracks: TypeModule.TRACKS,
	customFields: TypeModule.CUSTOM_FIELDS,
	// discussionGroup: TypeModule.,
	appointments: TypeModule.APPOINTMENTS,
	externalLink: TypeModule.EXTERNAL_LINK,
	modules: TypeModule.MANAGER_MODULES,
	legal: TypeModule.LEGAL,
	location: TypeModule.LOCATION,
	widget: TypeModule.WIDGETS,
	askQuestion: TypeModule.ASK_QUESTION,
	feedbacks: TypeModule.FEEDBACKS,
	selfCheckin: TypeModule.SELF_CHECKIN,
	groups: TypeModule.MANAGER_GROUP,
	gamification: TypeModule.GAMIFICATION,
	quiz: TypeModule.QUIZ,
	training: TypeModule.TRAINING,
	wordCloud: TypeModule.WORDCLOUD,
	survey: TypeModule.SURVEY,
	rankings: TypeModule.RANKINGS,
	customPage: TypeModule.CUSTOM_PAGE,
	personalSchedule: TypeModule.PERSONALSCHEDULE,
	event: TypeModule.EVENT,
	profile: TypeModule.PROFILE,
	home: TypeModule.HOME,
	feedNews: TypeModule.FEEDBACKS,
	checkin: TypeModule.CHECKIN,
	notifications: TypeModule.NOTIFICATION,
	gallery: TypeModule.GALLERY,
	interactivity: TypeModule.INTERACTIVITY,
	documents: TypeModule.DOCUMENT,
	attendees: TypeModule.ATTENDEE,
	speakers: TypeModule.SPEAKER
};

export const collectionsNames = {
	[TypeModule.DIVIDER]: "divider",
    [TypeModule.CARD_EXCHANGE]: "card-exchange",
	[TypeModule.FAVORITES]: "favorites",
	[TypeModule.NOTE_TAKING]: "notes",
	[TypeModule.EXTERNAL_INTERACTIVITY]: "externals-interactivity",
	[TypeModule.SCHEDULE]: "schedules",
	[TypeModule.CHAT]: "chats",
	[TypeModule.TREASURE_HUNTS]: "treasure-hunts",
	[TypeModule.TRACKS]: "tracks",
	[TypeModule.CUSTOM_FIELDS]: "custom-fields",
	// [TypeModule.DI]: "discussionGroup",
	[TypeModule.APPOINTMENTS]: "appointments",
	[TypeModule.EXTERNAL_LINK]: "externalLink",
	[TypeModule.MANAGER_MODULES]: "modules",
	[TypeModule.LEGAL]: "legal",
	[TypeModule.LOCATION]: "locations",
	[TypeModule.WIDGETS]: "widgets",
	[TypeModule.ASK_QUESTION]: "asks-questions",
	[TypeModule.FEEDBACKS]: "feedbacks",
	[TypeModule.SELF_CHECKIN]: "checkins",
	[TypeModule.MANAGER_GROUP]: "groups",
	[TypeModule.GAMIFICATION]: "gamification",
	[TypeModule.QUIZ]: "quizs",
	[TypeModule.TRAINING]: "trainings",
	[TypeModule.WORDCLOUD]: "word-clouds",
	[TypeModule.SURVEY]: "surveys",
	[TypeModule.RANKINGS]: "rankings",
	[TypeModule.CUSTOM_PAGE]: "custom-pages",
	[TypeModule.PERSONALSCHEDULE]: "schedules",
	[TypeModule.EVENT]: "event",
	[TypeModule.PROFILE]: "profile",
	[TypeModule.HOME]: "home",
	[TypeModule.NEWS_FEED]: "feed-news",
	[TypeModule.CHECKIN]: "checkins",
	[TypeModule.NOTIFICATION]: "notifications",
	[TypeModule.GALLERY]: "images",
	[TypeModule.INTERACTIVITY]: "interactivity",
	[TypeModule.DOCUMENT]: "documents",
	[TypeModule.ATTENDEE]: "event-users",
	[TypeModule.SPEAKER]: "event-users"
};
