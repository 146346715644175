<div *ngIf="event && module && eventUser" class="background" style="overflow-y: auto">
    <ion-list *ngIf="!loader && treasureHunts.length > 0">
        <ion-item lines="none" class="treasure-hunt-item" [style.border-bottom-color]="event.styling.titleColor + '1C'"
            *ngFor="let treasureHunt of treasureHunts" (click)="navigateTo(treasureHunt)">
            <ion-label [style.color]="event.styling.titleColor">
                <h2>{{ treasureHunt.name[currentLanguage] }}</h2>
            </ion-label>
        </ion-item>
    </ion-list>

    <div *ngIf="!loader && treasureHunts.length === 0" class="container">
        <span class="wait-content" [style.color]="event.styling.titleColor">{{
            "texts.waiting_for_content" | translate
            }}</span>
        <ion-icon src="assets/images/emptycontent/AUCUNRESULTAT.svg" [style.color]="
        							event.styling.menuColor.includes('linear')
        								? event.styling.menuColor.split(',')[1]
        								: event.styling.menuColor">
        </ion-icon>
        <div class="text-empty-content" [style.color]="event.styling.contentTextColor">
            <span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
            <span>{{ "interactivity.default_empty_content_text" | translate }}</span>
        </div>
    </div>

    <div *ngIf="loader">
        <ion-item *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
            <ion-avatar slot="start">
                <ion-skeleton-text animated></ion-skeleton-text>
            </ion-avatar>
            <ion-label>
                <h2>
                    <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                </h2>
                <p>
                    <ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
                </p>
            </ion-label>
        </ion-item>
    </div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>