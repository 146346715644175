import {
	AfterViewInit,
	Component,
	ElementRef,
	EventEmitter,
	Input,
	OnDestroy,
	OnInit,
	Output,
	ViewChild
} from "@angular/core";
import { GestureController, ModalController, Platform } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { BehaviorSubject, combineLatest, Subscription } from "rxjs";
import { TypeLikeFeedNews } from "src/app/shared/enums/feed-new-like-type";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IPost } from "src/app/shared/interfaces";
import { ILike, IReactionEmoji } from "src/app/shared/interfaces/feed-news.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { AnalyticsService, FeedNewsService, UtilityService } from "src/app/shared/services";

@Component({
	selector: "app-feed-news-react",
	templateUrl: "./feed-news-react.component.html",
	styleUrls: ["./feed-news-react.component.scss"]
})
export class FeedNewsReactComponent implements OnInit, OnDestroy, AfterViewInit {
	subscriptions: Subscription[] = [];

	@Output() openComments: EventEmitter<boolean> = new EventEmitter();
	@Input() post: IPost;
	@Input() event: IEvent;
	@Input() eventUser: IEventUser;

	selectedIcon: IReactionEmoji = null;

	listIcons: IReactionEmoji[] = [];
	postReactions: IReactionEmoji[] = [];

	showIcons: boolean = false;
	isMobile: boolean = false;
	likes: ILike[] = [];

	likeClicked: boolean = false;

	enterReaction: BehaviorSubject<boolean> = new BehaviorSubject(false);
	enterBtnReaction: BehaviorSubject<boolean> = new BehaviorSubject(false);

	@ViewChild("btnReaction", { read: ElementRef }) btnReaction: ElementRef;

	constructor(
		private platform: Platform,
		private modalCtrl: ModalController,
		private SFeedNews: FeedNewsService,
		private SAnalytics: AnalyticsService,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		private gestureCtrl: GestureController
	) {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb") ? true : false;
		this.listIcons = this.SFeedNews.getReactionEmojis();
	}

	ngOnInit(): void {
		this.getLikesOfUser();
		this.subscriptions.push(
			combineLatest([this.enterBtnReaction, this.enterReaction]).subscribe((result) => {
				const [enterBtnReaction, enterReaction] = result;
				if (enterBtnReaction) {
					this.showIcons = true;
				} else {
					setTimeout(() => {
						if (enterReaction) {
							this.showIcons = true;
						} else {
							this.showIcons = false;
						}
					}, 100);
				}
			})
		);
	}
	longPressActivated: boolean = false;

	ngAfterViewInit() {
		const gesture = this.gestureCtrl.create({
			el: this.btnReaction.nativeElement,
			gestureName: "long-press",
			onStart: (ev) => {
				this.longPressActivated = true;
				this.showIcons = true;
				// setTimeout(() => {
				// }, 200);
			},
			onMove: (ev) => {
				//
			},
			onEnd: (ev) => {
				this.showIcons = false;
				const btns = document.getElementsByClassName("btn-icon");
				for (let i = 0; i < btns.length; i++) {
					const btn = btns[i];
					const { x, y, width, left, right } = btn.getBoundingClientRect();
					if (ev.currentX <= right && ev.currentX >= left) {
						this.selectEmoji(this.listIcons.find((icon) => icon.name === btn.id));
						return;
					}
				}
			}
		});
		gesture.enable(true);
	}

	ngOnDestroy(): void {
		this.subscriptions.forEach((subscription) => subscription.unsubscribe());
	}

	mobileShowBtnReactionBox() {
		if (this.isMobile) {
			this.enterBtnReaction.next(!this.showIcons);
		}
	}

	/**
	 * On mouse leave btn Like/Love ...
	 */
	onMouseLeave() {
		if (!this.isMobile) {
			this.enterBtnReaction.next(false);
			setTimeout(() => {
				// this.showIcons = this.enterReaction ? true : false;
				// if (!this.enterReaction) {
				// 	this.showIcons = false;
				// }
			}, 100);
		}
	}

	/**
	 * On mouse enter btn Like/Love ...
	 */
	onMouseEnter() {
		if (!this.isMobile) {
			this.enterBtnReaction.next(true);
			setTimeout(() => {
				// this.showIcons = this.enterReaction ? true : false;
				// this.showIcons = true;
				// this.enterBtnReaction = true;
			}, 200);
		}
	}

	/**
	 * On mouse enter one of emoji
	 */
	mouseEnterReactions() {
		this.enterReaction.next(true);
		// this.showIcons = true;
	}

	/**
	 * On mouse leave one of emoji
	 */
	mouseLeaveReactions() {
		this.enterReaction.next(false);
		// this.showIcons = this.enterBtnReaction ? true : false;
	}

	/**
	 * Select an emoji
	 * @param emoji
	 */
	selectEmoji(emoji: IReactionEmoji) {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		if (!this.eventUser) {
			this.SUtility.alertLoginRegister(this.event.uid, this.STranslate.instant("chat.chat"));
			return;
		}

		this.selectedIcon = { ...emoji };
		this.selectedIcon.selected = true;
		// this.showIcons = false;
		this.enterBtnReaction.next(false);
		this.enterReaction.next(false);
		this.sendDataToServer();
	}

	/**
	 * Get event user likes
	 */
	getLikesOfUser() {
		this.subscriptions.push(
			this.SFeedNews.getLikesOfPost(this.event.uid, this.post.moduleId, this.post.uid).subscribe((likes) => {
				this.likes = likes;
				this.postReactions = this.listIcons.filter((icon) =>
					this.likes.some((like) => like.type.toString() === icon.name && this.post.uid === like.postId)
				);
				const userLike = this.likes.find(
					(like) => like.userId === this.eventUser?.uid && like.postId === this.post.uid
				);
				if (userLike) {
					const text = this.STranslate.instant(`feed_news.${userLike.type}`);
					this.selectedIcon = {
						name: userLike.type,
						text,
						selected: true
					};
				}
			})
		);
	}

	/**
	 * Show modal of all users who react to the post
	 * @returns
	 */
	async showListReactions() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.feedNewsListReaction,
				componentProps: {
					likes: this.likes
						.filter((like) => this.post.uid === like.postId)
						.sort(
							(a, b) =>
								DateTime.fromISO(b.creationDate).valueOf() - DateTime.fromISO(a.creationDate).valueOf()
						),
					event: this.event
				},
				cssClass: this.isMobile ? "full-sized-modal" : ""
			});
			return await modal.present();
		} catch (error) {
			//console.error("Modal error");
		}
	}

	/**
	 * Check like of post
	 * @param post
	 * @returns
	 */
	verifiesLikedPost(post: IPost) {
		return this.likes.find((like) => like.postId === post.uid && like.userId === this.eventUser.uid) ? true : false;
	}

	/**
	 * Mark post liked/disliked on click
	 */
	likeDislikePost() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		if (!this.eventUser) {
			this.SUtility.alertLoginRegister(this.event.uid, this.STranslate.instant("chat.chat"));

			return;
		}
		if (!this.isMobile) {
			if (this.selectedIcon.selected) {
				this.selectedIcon = this.listIcons[0];
				this.selectedIcon.selected = false;
			} else {
				this.selectedIcon.selected = true;
			}
			this.sendDataToServer();
		}
	}

	/**
	 * Save Data
	 */
	async sendDataToServer() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		try {
			if (this.eventUser && this.eventUser.uid && !this.likeClicked) {
				this.likeClicked = true;
				const post = { ...this.post };

				if (!this.verifiesLikedPost(post)) {
					const like: ILike = {
						uid: "",
						creationDate: DateTime.local().toISO(),
						eventId: this.event.uid,
						moduleId: this.post.moduleId,
						postId: this.post.uid,
						type: this.selectedIcon.name as TypeLikeFeedNews,
						userId: this.eventUser.uid
					};
					post.totalLikes++;
					await this.SFeedNews.createLike(this.event.uid, this.post.moduleId, post.uid, like);
					await this.SFeedNews.updatePost(this.event.uid, this.post.moduleId, this.post.uid, post);
					this.likeClicked = false;

					// Tracking analytic (Send like)
					this.SAnalytics.sendOrRemoveLike(
						this.event.uid,
						this.post.moduleId,
						this.eventUser,
						post.uid,
						TypeTracking.SEND_LIKE_ON_FEED
					);
				} else {
					const like = this.likes.find(
						(like) => like.postId === post.uid && like.userId === this.eventUser.uid
					);

					if (like) {
						if (like.type.toString() === this.selectedIcon.name) {
							post.totalLikes--;
							await this.SFeedNews.deleteLike(this.event.uid, this.post.moduleId, post.uid, like);
							await this.SFeedNews.updatePost(this.event.uid, this.post.moduleId, this.post.uid, post);
							this.selectedIcon = null;
						} else {
							like.type = this.selectedIcon.name as TypeLikeFeedNews;
							await this.SFeedNews.updateLike(this.event.uid, this.post.moduleId, post.uid, like);
						}
						this.likeClicked = false;

						// Tracking analytic (Remove like)
						this.SAnalytics.sendOrRemoveLike(
							this.event.uid,
							this.post.moduleId,
							this.eventUser,
							post.uid,
							TypeTracking.REMOVE_LIKE_ON_FEED
						);
					}
				}
			}
		} catch (error) {
			this.likeClicked = false;
		}
	}

	/**
	 * SHow comments section
	 */
	commentPost() {
		if (this.event.blocked) {
			this.SUtility.presentToast(this.STranslate.instant("alerts.blocked-event-info"), 2500, "bottom", "danger");
			return;
		}
		this.openComments.emit(true);
	}
}
