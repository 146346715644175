import { DateTime } from "luxon";
import { IEvent } from "../interfaces";
import { DocumentReference, increment } from "@angular/fire/firestore";
import { FirestoreService } from "./firestore.service";

export class Counter {
	constructor(
		private doc: DocumentReference,
		private clientId: string,
		private field: string,
		private event: IEvent,
		private type: string,
		private SFirestore: FirestoreService
	) {}

	getIncrement(val: number) {
		return increment(val);
	}

	/**
	 * Increment the counter by a given value
	 */
	public incrementBy(val: number): Promise<void> {
		const increment: any = this.getIncrement(val);
		const update: {
			clientId: string;
			count: number;
			event: {
				creationDate: string;
				endDate: string;
				startDate: string;
				title: string;
				uid: string;
			};
			lastUpdated: string;
			type: string;
		} = this.field
			.split(".")
			.reverse()
			.reduce((value, name) => ({ [name]: value }), increment);

		update.clientId = this.clientId;
		update.count = increment;
		update.event = {
			uid: this.event.uid,
			title: this.event.title,
			creationDate: this.event.creationDate,
			startDate: this.event.startDate,
			endDate: this.event.endDate
		};
		update.lastUpdated = DateTime.local().toISO();
		update.type = this.type;

		return this.SFirestore.setDocumentMerge(this.doc.path, update);
	}
}
