export enum TypeAutomation {
	USER_REGISTRATION = 1,
	USER_ACCOMPANYING_REGISTRATION = 2,
	CHEKIN = 3,
	USER_EDITED_PROFIL = 4,
	SENDING_SURVEY = 5,
	CHAT_NOTIFICATION = 6,
	VISIO_ENTER = 7,
	VISIO_INVITATION = 8,
	MODULE = 9
}

export enum TypeTriggerAction {
	SEND_MAIL_TO_STAFF = 1,
	SEND_MAIL_TO_USER = 2,

	SEND_NOTIFICATION_TO_STAFF = 3,
	SEND_NOTIFICATION_TO_USER = 4,

	SEND_WEB_HOOK = 5
}

export enum TypeReceiver {
	USER_ALL = 1,
	USER_MODULE = 2,
	USER_GROUP = 3,
	USER_CONNECTED = 4,
	USER_NOT_CONNECTED = 5,
	SPECIFIC = 6, // Specific event users
	STAFF = 7, // All staff => manager + client
	ONLY_MANAGER = 8, // Only manager
	ONLY_CLIENT = 9, // Only client
	GOD = 10, // God => Admins
	SUPER_GOD = 11, // God => CEO
	USER = 12, // automation user
	OTHER = 13 // other
}

export enum TriggerType {
	// FEED_NEWS
	MODULE_SEND_POST = 1,
	MODULE_LIKE_POST = 2,
	MODULE_SEND_COMMENT = 3,
	MODULE_REACTION_TO_POST = 4,

	// EVENT USERS
	MODULE_REGISTER_EVENTUSER = 20,
	MODULE_REGISTER_ATTENDEE = 21,
	MODULE_REGISTER_SPEAKER = 22,
	MODULE_USER_COLLEGUE_REGISTER = 23,
	MODULE_USER_EDITED_PROFIL = 24,

	// CHECKIN
	MODULE_USER_CHECKIN = 30,
	MODULE_USER_SEFLCHECKIN = 31,

	// VISIO
	MODULE_VISIO_ENTER = 40,
	MODULE_VISIO_INVITATION = 41,

	// SCHEDULE
	MODULE_SCHEDULE = 50,
	// TODO: add all schedule triggers

	// INTERACTIVITY
	MODULE_INTERACTIVITY = 60,
	// TODO: add all interactivity triggers

	// GENERIC -> SHORTCUTS
	GENERIC_REGISTER_EVENTUSER = 1000,
	GENERIC_REGISTER_ATTENDEE = 1001,
	GENERIC_REGISTER_SPEAKER = 1002,
	GENERIC_USER_COLLEGUE_REGISTER = 10003,
	GENERIC_CHECKIN = 1004,
	GENERIC_VISIO_ENTER = 1008,
	GENERIC_VISIO_INVITATION = 1009
}

export enum LeftConditionType {
	USER_ENTITY = 1,

	// USER FIELDS
	USER_FIELDS = 2,
	USER_CUSTOM_FIELDS = 3
}

export enum RightConditionType {
	MODULE = 1,
	GROUP = 2,
	BOOLEAN = 3,
	STRING = 4,
	ARRAY = 5,
	NUMBER = 6,
	DATE = 7,
	EMPTY = 8,
	VALUE = 9
}

export enum RightConditionInputType {
	TEXT = 1,
	NUMBER = 2,
	DATE = 3,
	SELECT = 4,
	MULTI_SELECT = 5
}

export enum LeftConditionValueType {
	STRING = 1,
	NUMBER = 2,
	BOOLEAN = 3,
	DATE = 4,
	ARRAY = 5
}

export enum RightConditionValueType {
	STRING = 1,
	NUMBER = 2,
	BOOLEAN = 3,
	DATE = 4,
	ARRAY = 5
}

export enum OperatorType {
	// User fields && Custom fields
	EQUAL = 1,
	NOT_EQUAL = 2,
	LESS_THAN = 3,
	LESS_THAN_OR_EQUAL = 4,
	GREATER_THAN = 5,
	GREATER_THAN_OR_EQUAL = 6,
	CONTAINS = 7,
	NOT_CONTAINS = 8,
	IS_EMPTY = 9,
	IS_NOT_EMPTY = 10,

	// Modules & Groups
	IS_IN = 11,
	IS_NOT_IN = 12
}

export enum OperatorTypeStringi18n {
	"empty",
	"is_equal",
	"is_not_equal",
	"is_less_than",
	"is_less_than_or_equal_to",
	"is_greater_than",
	"is_greater_than_or_equal_to",
	"contains",
	"does_not_contain",
	"is_empty",
	"is_not_empty",
	"is_in",
	"is_not_in"
}

export const TypeReceiverLabel = {
	[TypeReceiver.USER_ALL]: "automation.triggers.actions.receivers.user_all",
	[TypeReceiver.USER_MODULE]: "automation.triggers.actions.receivers.user_module",
	[TypeReceiver.USER_GROUP]: "automation.triggers.actions.receivers.user_group",
	[TypeReceiver.USER_CONNECTED]: "automation.triggers.actions.receivers.user_connected",
	[TypeReceiver.USER_NOT_CONNECTED]: "automation.triggers.actions.receivers.user_not_connected",
	[TypeReceiver.SPECIFIC]: "automation.triggers.actions.receivers.specific",
	[TypeReceiver.STAFF]: "automation.triggers.actions.receivers.staff",
	[TypeReceiver.ONLY_MANAGER]: "automation.triggers.actions.receivers.only_manager",
	[TypeReceiver.ONLY_CLIENT]: "automation.triggers.actions.receivers.only_client",
	[TypeReceiver.GOD]: "automation.triggers.actions.receivers.god",
	[TypeReceiver.SUPER_GOD]: "automation.triggers.actions.receivers.super_god",
	[TypeReceiver.USER]: "automation.triggers.actions.receivers.user",
	[TypeReceiver.OTHER]: "automation.triggers.actions.receivers.other"
};

export const TriggerTypeName = {
	// FEED_NEWS
	[TriggerType.MODULE_SEND_POST]: "automation.triggers.module_send_post",
	[TriggerType.MODULE_LIKE_POST]: "automation.triggers.module_send_post_like",
	[TriggerType.MODULE_SEND_COMMENT]: "automation.triggers.module_send_post_comment",

	// CHECKIN
	[TriggerType.MODULE_USER_CHECKIN]: "automation.triggers.generic_checkin",
	[TriggerType.MODULE_USER_SEFLCHECKIN]: "automation.triggers.selfcheckin",

	// VISIO
	[TriggerType.MODULE_VISIO_ENTER]: "automation.triggers.generic_visio_enter",
	[TriggerType.MODULE_VISIO_INVITATION]: "automation.triggers.generic_visio_invitation",

	// EVENT USERS
	[TriggerType.MODULE_REGISTER_EVENTUSER]: "automation.triggers.module_register_eventuser",
	[TriggerType.MODULE_REGISTER_ATTENDEE]: "automation.triggers.module_register_attendee",
	[TriggerType.MODULE_REGISTER_SPEAKER]: "automation.triggers.module_register_speaker",
	[TriggerType.MODULE_USER_COLLEGUE_REGISTER]: "automation.triggers.module_register_eventuser_accompanying",
	[TriggerType.MODULE_USER_EDITED_PROFIL]: "automation.triggers.module_user_edited_profil",

	// GENERIC -> SHORTCUTS
	[TriggerType.GENERIC_REGISTER_EVENTUSER]: "automation.triggers.generic_register_eventuser",
	[TriggerType.GENERIC_REGISTER_ATTENDEE]: "automation.triggers.generic_register_attendee",
	[TriggerType.GENERIC_REGISTER_SPEAKER]: "automation.triggers.generic_register_speaker",
	[TriggerType.GENERIC_USER_COLLEGUE_REGISTER]: "automation.triggers.generic_user_collegue_register",
	[TriggerType.GENERIC_CHECKIN]: "automation.triggers.generic_checkin",
	[TriggerType.GENERIC_VISIO_ENTER]: "automation.triggers.generic_visio_enter",
	[TriggerType.GENERIC_VISIO_INVITATION]: "automation.triggers.generic_visio_invitation"
};

export const TriggerTypeDescription = {
	// FEED_NEWS
	[TriggerType.MODULE_SEND_POST]: "automation.automation_description.module_send_post",
	[TriggerType.MODULE_LIKE_POST]: "automation.automation_description.module_like_post_like",
	[TriggerType.MODULE_SEND_COMMENT]: "automation.automation_description.module_send_post_comment",

	// CHECKIN
	[TriggerType.MODULE_USER_CHECKIN]: "automation.automation_description.module_user_checkin",
	[TriggerType.MODULE_USER_SEFLCHECKIN]: "automation.automation_description.user_selfcheckin",

	// VISIO
	[TriggerType.MODULE_VISIO_ENTER]: "automation.automation_description.visio_entering",
	[TriggerType.MODULE_VISIO_INVITATION]: "automation.automation_description.visio_invitation",

	// EVENT USERS
	[TriggerType.MODULE_REGISTER_EVENTUSER]: "automation.automation_description.module_register_eventuser",
	[TriggerType.MODULE_REGISTER_ATTENDEE]: "automation.automation_description.module_register_attendee",
	[TriggerType.MODULE_REGISTER_SPEAKER]: "automation.automation_description.module_register_speaker",
	[TriggerType.MODULE_USER_COLLEGUE_REGISTER]:
		"automation.automation_description.module_register_eventuser_accompanying",
	[TriggerType.MODULE_USER_EDITED_PROFIL]: "automation.automation_description.module_user_edited_profil",

	// GENERIC -> SHORTCUTS
	[TriggerType.GENERIC_REGISTER_EVENTUSER]: "automation.automation_description.user_registration",
	[TriggerType.GENERIC_REGISTER_ATTENDEE]: "automation.automation_description.user_registration",
	[TriggerType.GENERIC_REGISTER_SPEAKER]: "automation.automation_description.user_registration",
	[TriggerType.GENERIC_USER_COLLEGUE_REGISTER]: "automation.automation_description.user_registration",
	[TriggerType.GENERIC_CHECKIN]: "automation.automation_description.user_checkin",
	[TriggerType.GENERIC_VISIO_ENTER]: "automation.automation_description.visio_entering",
	[TriggerType.GENERIC_VISIO_INVITATION]: "automation.automation_description.visio_invitation"
};

export const TypeTriggerActionName = {
	[TypeTriggerAction.SEND_MAIL_TO_STAFF]: "automation.triggers.actions.send_email_to_staff",
	[TypeTriggerAction.SEND_MAIL_TO_USER]: "automation.triggers.actions.send_email_to_user",

	[TypeTriggerAction.SEND_NOTIFICATION_TO_STAFF]: "automation.triggers.actions.send_notification_to_staff",
	[TypeTriggerAction.SEND_NOTIFICATION_TO_USER]: "automation.triggers.actions.send_notification_to_user",

	[TypeTriggerAction.SEND_WEB_HOOK]: "automation.triggers.actions.send_webhook"
};

export const TypeTriggerActionIconName = {
	[TypeTriggerAction.SEND_MAIL_TO_STAFF]: "email_admin",
	[TypeTriggerAction.SEND_MAIL_TO_USER]: "email_user",

	[TypeTriggerAction.SEND_NOTIFICATION_TO_STAFF]: "notification_admin",
	[TypeTriggerAction.SEND_NOTIFICATION_TO_USER]: "notification_user",

	[TypeTriggerAction.SEND_WEB_HOOK]: "webhook"
};
