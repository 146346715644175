<ion-header mode="md" #header>
	<ion-toolbar *ngIf="event" class="basic-toolbar">
		<ion-buttons *ngIf="!queryParams || queryParams.nav !== 'false'" slot="start">
			<ion-icon *ngIf="currentUrl !== baseUrl" [style.color]="event.styling.menuTextColor"
				[style.--color]="event.styling.menuTextColor" class="basic-transparent-icon-button icons-25 m-l-5"
				(click)="goBack()" icon="assets/icon/Arrow.svg"></ion-icon>
			<ion-menu-button *ngIf="
					(isMobile && event.settings.activateBurgerMenuOnMobile) ||
					(!isMobile && event.settings.activateBurgerMenuOnDesktop && currentUrl === baseUrl)
				" [style.color]="event.styling.menuTextColor" (click)="hamburgerClicked()">
			</ion-menu-button>
		</ion-buttons>

		<ion-title [style.color]="event.styling.menuTextColor" style="font-family: var(--font)">
			{{ headerState.title?.[currentLanguage] }}
		</ion-title>

		<ion-buttons *ngIf="!event.blocked" slot="end" mode="md">
			<!-- Appointments -->
			<ion-button slot="end" *ngIf="headerState && headerState.type === types.APPOINTMENTS && headerState.module"
				(click)="openAppointmentsSettings()">
				<ion-icon slot="icon-only" name="settings-outline" mode="md"></ion-icon>
			</ion-button>

			<!-- Favorite -->
			<ion-button slot="end" *ngIf="
					headerState &&
					headerState.type === types.WIDGETS &&
					favoriteModule &&
					favoriteModule.options &&
					!favoriteModule.options.hideFavoriteHeaderIcon &&
					currentUrl.includes(event.homePage)
				" (click)="openFavoriteModule()">
				<ion-icon src="assets/icon/bookmarks.svg"
					style="width: 25px; height: 25px; position: relative; left: 5px; top: -2px"></ion-icon>
			</ion-button>

			<!-- Favorite QrCode scan -->
			<ion-button slot="end" *ngIf="
					headerState &&
					headerState.type === types.FAVORITE_EVENT_USERS_FOLDERS &&
					headerState.item &&
					headerState.item.type === typeModule.ATTENDEE &&
					favoriteModule &&
					favoriteModule.options &&
					favoriteModule.options.allowUsersScans
				" (click)="favoriteScanQr()">
				<ion-icon src="assets/icon/Flash.svg"></ion-icon>
			</ion-button>

			<!-- Note taking button -->
			<ion-button *ngIf="
					headerState &&
					headerState.type === types.WIDGETS &&
					noteIconStatus &&
					!currentUrl.includes('note') &&
					currentUrl.includes(event.homePage) &&
					user
				" class="settings-button basic-icon-transparent-header-button" (click)="openNoteModule()">
				<ion-icon slot="icon-only" name="create-outline" mode="md"></ion-icon>
			</ion-button>

			<ion-button *ngIf="
					eventUser &&
					currentUrl?.includes(eventHomePage) &&
					event &&
					event.settings &&
					!event.settings.hideChat
				" slot="icon-only" (click)="openChatsModule()" class="btn-notifs-menu" [style.color]="event.styling.menuTextColor">
				<ion-icon src="assets/icon/Message.svg" mode="md"></ion-icon>
				<div id="notify-badge-menu" *ngIf="chatsBadge > 0"></div>
			</ion-button>
			<ion-button *ngIf="currentUrl?.includes(eventHomePage)" slot="icon-only" (click)="openNotifications()"
				class="btn-notifs-menu" [style.color]="event.styling.menuTextColor">
				<ion-icon src="assets/icon/Bell.svg" mode="md"></ion-icon>
				<div id="notify-badge-menu" *ngIf="notificationsBadge > 0"></div>
			</ion-button>

			<!-- Chat settings button  -->
			<ion-button *ngIf="
					headerState &&
					headerState.item &&
					headerState.type === types.CHAT_DETAIL &&
					SChats.checkMemberOfChat(headerState.item, eventUser)
				" class="settings-button basic-icon-transparent-header-button" (click)="leaveChat(headerState.item)">
				<ion-icon slot="icon-only" name="person-remove" mode="md"></ion-icon>
			</ion-button>
			<ion-button *ngIf="
					headerState &&
					headerState.item &&
					headerState.type === types.CHAT_DETAIL &&
					SChats.checkAdminOfChat(headerState.item, eventUser)
				" class="settings-button basic-icon-transparent-header-button" (click)="deleteChat(headerState.item)">
				<ion-icon slot="icon-only" name="trash" mode="md"></ion-icon>
			</ion-button>
			<ion-button *ngIf="
					headerState.type === types.CHAT &&
					eventUser &&
					headerState.module &&
					headerState.module.options &&
					headerState.module.options.allowAttendeesCreation
				" class="settings-button basic-icon-transparent-header-button" (click)="showCreateChat()">
				<ion-icon slot="icon-only" name="create" mode="md"></ion-icon>
			</ion-button>
			<ion-button *ngIf="
					headerState.item &&
					headerState.type === types.CHAT_DETAIL &&
					eventUser &&
					headerState.item.allowNotifs
				" class="settings-button basic-icon-transparent-header-button" (click)="presentPopover($event)">
				<ion-icon slot="icon-only" name="settings" mode="md"></ion-icon>
			</ion-button>
			<ion-button *ngIf="
					event.settings.allowVisio &&
					event.settings.allowVisioForTwo &&
					headerState.item &&
					headerState.type === types.CHAT_DETAIL &&
					headerState.item.type === 0
				" class="settings-button basic-icon-transparent-header-button" (click)="createVisioChat()">
				<ion-icon class="icon-camera" src="assets/icon/Camera2.svg"> </ion-icon>
			</ion-button>

			<!-- Notifications buttons for feed news -->
			<ion-button slot="end" *ngIf="
					headerState.type === types.NEWS_FEED &&
					eventUser &&
					headerState &&
					headerState.module &&
					headerState.module.options &&
					headerState.module.options.activateNotifs
				" (click)="updateNotifsEventUser('feedNewsNotifs', headerState.module.uid)">
				<ion-icon class="notifs-icon"
					[name]="checkAllowNotifs(headerState.module) ? 'notifications' : 'notifications-off'" slot="end">
				</ion-icon>
			</ion-button>

			<!-- Checkin button -->
			<ion-button slot="end" *ngIf="headerState.type === types.CHECKIN" (click)="scanQr()">
				<ion-icon src="assets/icon/Flash.svg"></ion-icon>
			</ion-button>

			<!-- Checkin repair button -->
			<!-- <ion-button slot="end" *ngIf="headerState.type === types.CHECKINS" (click)="repairCheckin()">
                <ion-icon src="assets/icon/wrench.svg"></ion-icon>
            </ion-button> -->

			<!-- Add eventUser button -->
			<ion-button slot="end" *ngIf="
					headerState.type === types.CHECKIN &&
					eventUser &&
					eventUser.isClientAdmin &&
					headerState.module &&
					headerState.module.options?.allowUserRegistration
				" (click)="openRegisterForm()">
				<ion-icon src="assets/icon/User Add.svg"></ion-icon>
			</ion-button>

			<!-- Profile edit button -->
			<ion-button slot="end" *ngIf="
					user &&
					eventUserModule &&
					eventUserModule.options.allowedEditProfile &&
					headerState.item &&
					headerState.item.uid === user.uid &&
					headerState.type === types.PROFILE
				" (click)="
					navigateToProfile()
				">
				<span class="edit-profile-text">{{ "buttons.edit-profil" | translate }}</span>
				&nbsp; &nbsp;
				<ion-icon class="edit-profile-icon" src="assets/icon/ManageAccount.svg"></ion-icon>
			</ion-button>

			<!-- Accompanying add button -->
			<ion-button slot="end" *ngIf="
					user &&
					eventUserModule &&
					registerForm &&
					registerForm?.formSettings?.enableAccompanyingUsers &&
					!eventUser.isAccompanying &&
					headerState.item &&
					headerState.item.uid === user.uid &&
					headerState.type === types.PROFILE
				" (click)="openAccompanyingForm()">
				<span class="add-accompanying-text">{{
					"register.register_an_accompanying_user_title" | translate
					}}</span>
			</ion-button>

			<!-- Add eventUser button -->
			<ion-button slot="end" *ngIf="headerState.type === types.CARD_EXCHANGE" (click)="cardExchangeSCanQr()">
				<ion-icon src="assets/icon/scan_icon.svg"></ion-icon>
			</ion-button>
		</ion-buttons>
	</ion-toolbar>
</ion-header>