import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertsComponent } from "./alerts/alerts.component";
import { IonicModule } from "@ionic/angular";
import { TranslateModule } from "@ngx-translate/core";

@NgModule({
	declarations: [AlertsComponent],
	imports: [CommonModule, IonicModule, TranslateModule],
	exports: [AlertsComponent]
})
export class GenericComponentsModule {}
