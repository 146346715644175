import { DOCUMENT } from "@angular/common";
import { Component, HostListener, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Browser } from "@capacitor/browser";
import { ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { where } from "firebase/firestore";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, firstValueFrom, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { GetHeaderState, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { Screen } from "src/app/shared/enums/screen";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeModule, TypeModuleNumber } from "src/app/shared/enums/type-module";
import { TypeVisionCheckin } from "src/app/shared/enums/type-vision-checkin";
import { WidgetType } from "src/app/shared/enums/type-widget";
import { IEvent, IEventUser, IModule, IQuiz, ISurvey } from "src/app/shared/interfaces";
import { IRegisterForm } from "src/app/shared/interfaces/register-form.interfaces";
import { IWidget } from "src/app/shared/interfaces/widget.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getFoldersOfModule } from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getPostsOfModule } from "src/app/shared/selectors/feed-news.selectors";
import { getModulesByType, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";
import { getMenuActivated } from "src/app/shared/selectors/utility.selectors";
import { getWidgetsOfModule } from "src/app/shared/selectors/widgets.selectors";
import { FirestoreService, NotificationsService, SchedulesService, CardExchangeService } from "src/app/shared/services";
import { AnalyticsService } from "src/app/shared/services/analytics.service";
import { RegisterFormService } from "src/app/shared/services/register-form.service";
import { environment } from "src/environments/environment";

const MOBILE_COLUMN = 8;
const DESKTOP_COLUMN = 16;
const ALPHABETS = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
const GRID_GAP = 7;

@Component({
	selector: "app-widgets",
	templateUrl: "./widgets.component.html",
	styleUrls: ["./widgets.component.scss"]
})
export class WidgetComponent implements OnDestroy {
	// @HostBinding("style.background") backgroundColor = this.getHostBackground();
	DEBUG_MODE = false; //  *** POUR LES DEVELOPPEURS *** pour afficher la grid: true pour afficher

	subscriptions: Subscription[] = [];
	langSubscription: Subscription;

	@ViewChild(HTMLDivElement) container: HTMLDivElement;

	isMobile: boolean = false;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	cardExchangeModule: IModule;
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;

	desktopWidgets: IWidget[];
	mobileWidgets: IWidget[];

	mobileGrid = [] as any[];
	desktopGrid = [] as any[];

	loaderMobile: boolean = true;
	loaderDesktop: boolean = true;

	isDesktopWidgetsStaggered: boolean = false;
	isMobileWidgetsStaggered: boolean = false;

	@HostListener("window:resize", ["$event"])
	onResize() {
		this.isMobile = window.innerWidth < 768;
		this.container = this.container
			? this.container
			: (document.getElementById(`container${this.module.uid}`) as HTMLDivElement);
		if (this.container) {
			if (this.isMobile) {
				this.loaderMobile = true;
				let mobileWidgets = _.cloneDeep(this.mobileWidgets);
				if (this.isMobileWidgetsStaggered) {
					this.mobileGrid.map((line) =>
						line.map((c) => {
							c.taken = false;
							return c;
						})
					);
					mobileWidgets = [
						...this.shiftWidgets("mobile", mobileWidgets),
						...mobileWidgets.filter((w) => !w.config.startId)
					];
				}
				setTimeout(() => {
					this.mobileWidgets =
						mobileWidgets && mobileWidgets.length > 0
							? mobileWidgets.map((widget) => this.placeWidget(widget))
							: [];
					this.loaderMobile = false;
				}, 200);
			} else {
				this.loaderDesktop = true;
				let desktopWidgets = _.cloneDeep(this.desktopWidgets);
				if (this.isDesktopWidgetsStaggered) {
					this.desktopGrid.map((line) =>
						line.map((c) => {
							c.taken = false;
							return c;
						})
					);

					desktopWidgets = [
						...this.shiftWidgets("desktop", desktopWidgets),
						...desktopWidgets.filter((w) => !w.config.startId)
					];
				}
				setTimeout(() => {
					this.desktopWidgets =
						desktopWidgets && desktopWidgets.length > 0
							? desktopWidgets.map((widget) => this.placeWidget(widget))
							: [];
					this.loaderDesktop = false;
				}, 200);
			}
		}
	}

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private SAnalytics: AnalyticsService,
		public platform: Platform,
		private store: Store,
		private navCtrl: NavController,
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		private SSchedules: SchedulesService,
		private STranslate: TranslateService,
		private SNotifications: NotificationsService,
		private SFirestore: FirestoreService,
		private SRegisterForm: RegisterFormService,
		private SCardExchange: CardExchangeService
	) {
		this.isMobile = window.innerWidth < 768;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;

		this.store
			.select(selectRouteNestedParams)
			.pipe(take(1))
			.subscribe((params) => {
				this.loaderDesktop = true;
				this.loaderMobile = true;
				this.eventId = params.eventId;
				this.moduleId = params.moduleId;
				this.subscriptions.concat([this.langSubscription]).forEach((sub) => sub?.unsubscribe());

				this.langSubscription = this.STranslate.onLangChange.subscribe((lang) => {
					this.currentLanguage = lang.lang;
				});
				this.getEvent();
				this.getEventUser();
				this.getModule();
				this.getWidgets();
				this.setMenuSubsciption();
			});
	}

	ionViewDidEnter() {
		this.container = this.container
			? this.container
			: (document.getElementById(`container${this.moduleId}`) as HTMLDivElement);
		this.store
			.select(selectRouteNestedParams)
			.pipe(take(1))
			.subscribe(() => {
				// Analytics
				this.SAnalytics.moduleAccess(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_WIDGET_MODULE);
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.concat([this.langSubscription]).forEach((sub) => sub?.unsubscribe());
	}

	/**
	 * Get Event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);
	}

	/**
	 * Get Module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module) && module && module.type === TypeModuleNumber.widget) {
					this.module = module;
				}

				if (this.module) {
					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: null,
								module: this.module,
								title: this.module.name,
								type: TypeModule.WIDGETS
							}
						})
					);
				}
			})
		);
	}

	/**
	 * Set menu subscription
	 */
	setMenuSubsciption() {
		this.subscriptions.push(
			this.store.select(getMenuActivated).subscribe(() => {
				if (this.isMobile) {
					if (!this.loaderMobile && this.mobileWidgets && this.mobileWidgets.length > 0) {
						setTimeout(() => {
							this.mobileWidgets.map((widget) => this.placeWidget(widget));
						}, 150);
					}
				} else {
					if (!this.loaderDesktop && this.desktopWidgets && this.desktopWidgets.length > 0) {
						setTimeout(() => {
							this.desktopWidgets = this.desktopWidgets.map((widget) => this.placeWidget(widget));
						}, 200);
					}
				}
			})
		);
	}

	/**
	 * Get user data
	 */
	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);
	}

	/**
	 * Get all widgets
	 */
	getWidgets() {
		this.subscriptions.push(
			this.store
				.select(getWidgetsOfModule(this.moduleId))
				.pipe(skipWhile(() => !this.event || !this.module))
				.subscribe((result) => {
					if (result.init) {
						const widgets = result.widgets.filter(
							(widget) =>
								(this.event.settings.visibility &&
									widget.typeVision !== TypeVisionCheckin.GROUP_VISION &&
									((this.eventUser &&
										(widget.visForLoggedUser || widget.visForLoggedUser === undefined)) ||
										(!this.eventUser &&
											(widget.visForUnloggedUser || widget.visForUnloggedUser === undefined)))) ||
								(widget.typeVision === TypeVisionCheckin.GROUP_VISION &&
									this.eventUser &&
									widget.groups.some((gr) => this.eventUser.groups.includes(gr))) ||
								(!this.event.settings.visibility &&
									(widget.typeVision === TypeVisionCheckin.GLOBAL_VISION ||
										(widget.typeVision === TypeVisionCheckin.GROUP_VISION &&
											widget.groups.some(
												(gr) => this.eventUser && this.eventUser.groups.includes(gr)
											))))
						);

						if (this.isMobile) {
							const widgetMobileLastLine = widgets
								.filter((widget) => widget.screen === Screen.MOBILE)
								.map((widget) => {
									return widget.config.squaresTaken && widget.config.squaresTaken.length > 0
										? widget.config.squaresTaken[widget.config.squaresTaken.length - 1].line
										: widget.config.startId
										? widget.config.startId.split("-")[0]
										: null;
								})
								.filter((line) => line)
								.sort((a, b) =>
									ALPHABETS.indexOf(a) < ALPHABETS.indexOf(b)
										? 1
										: ALPHABETS.indexOf(a) > ALPHABETS.indexOf(b)
										? -1
										: 0
								);
							const lengthMobile =
								widgetMobileLastLine.length > 0 ? ALPHABETS.indexOf(widgetMobileLastLine[0]) + 1 : 16;
							this.mobileGrid = [...new Array(lengthMobile)].map((x, indexAlph) => {
								const array = [...new Array(MOBILE_COLUMN)].map((a, i) => {
									return { id: `${ALPHABETS[indexAlph]}-${i + 1}`, taken: false, widget: "" };
								});
								return array;
							});
							this.mobileGrid = [...new Array(lengthMobile)].map((x, indexAlph) => {
								const array = [...new Array(MOBILE_COLUMN)].map((a, i) => {
									return { id: `${ALPHABETS[indexAlph]}-${i + 1}`, taken: false, widget: "" };
								});
								return array;
							});
							setTimeout(() => {
								this.mobileWidgets = widgets
									.filter((widget) => widget.screen === Screen.MOBILE)
									.map((widget) => {
										this.placeWidget(widget);
										return widget;
									});
								this.loaderMobile = false;
							}, 200);
						} else {
							const widgetDesktopLastLine = widgets
								.filter((widget) => widget.screen === Screen.DESKTOP)
								.map((widget) => {
									return widget.config.squaresTaken && widget.config.squaresTaken.length > 0
										? widget.config.squaresTaken[widget.config.squaresTaken.length - 1].line
										: widget.config.startId
										? widget.config.startId.split("-")[0]
										: null;
								})
								.filter((line) => line)
								.sort((a, b) =>
									ALPHABETS.indexOf(a) < ALPHABETS.indexOf(b)
										? 1
										: ALPHABETS.indexOf(a) > ALPHABETS.indexOf(b)
										? -1
										: 0
								);
							const lengthDesktop =
								widgetDesktopLastLine.length > 0 ? ALPHABETS.indexOf(widgetDesktopLastLine[0]) + 1 : 14;
							this.desktopGrid = [...new Array(lengthDesktop)].map((x, indexAlph) => {
								const array = [...new Array(DESKTOP_COLUMN)].map((a, i) => {
									return {
										id: `${ALPHABETS[indexAlph]}-${i + 1}`,
										taken: false,
										widget: ""
									};
								});
								return array;
							});
							setTimeout(() => {
								this.desktopWidgets = widgets
									.filter((widget) => widget.screen === Screen.DESKTOP)
									.map((widget) => {
										this.placeWidget(widget);
										return widget;
									});
								this.loaderDesktop = false;
							}, 200);
						}
					}
				})
		);
	}

	/**
	 * Check if widget have notification badge
	 * @param widget
	 * @returns
	 */
	checkNotifsWidget(widget: IWidget) {
		const splitedRoute: string[] = widget.route.split("/");
		const moduleId: string = splitedRoute && splitedRoute[4] ? splitedRoute[4] : "";
		if (widget.route.includes("feed-news") && moduleId) {
			return this.store.select(getPostsOfModule(moduleId)).pipe(
				switchMap((posts) => {
					return of(
						posts.filter(
							(post) =>
								this.eventUser &&
								this.eventUser.updatedSettings &&
								this.eventUser.updatedSettings.accessModule &&
								this.eventUser.updatedSettings.accessModule[moduleId] &&
								this.eventUser.updatedSettings.accessModule[moduleId] < post.creationDate &&
								post.active
						).length
					);
				})
			);
		}
		return of(-1);
	}

	/**
	 * Shift widgets to top left
	 * @param type
	 * @returns
	 */
	shiftWidgets(type: "mobile" | "desktop", widgets: IWidget[]) {
		// const widgetsRef = type === "mobile" ? this.mobileWidgets : this.desktopWidgets;
		return widgets
			.filter((w) => w.config.startId)
			.sort((a, b) =>
				a.config.startId.split("-")[0] < b.config.startId.split("-")[0]
					? -1
					: a.config.startId.split("-")[0] > b.config.startId.split("-")[0]
					? 1
					: Number(a.config.startId.split("-")[1]) - Number(b.config.startId.split("-")[1])
			)
			.map((widget) => {
				const config = { ...widget.config };
				config.startId = this.getNewStartId(widget);
				widget.config = config;
				return widget;
			});
	}

	/**
	 * Get new startId value and mark block in grid as taken
	 * @param widget
	 * @returns
	 */
	getNewStartId(widget: IWidget) {
		let indexes = [];
		let found = false;
		const gridRef = widget.screen === Screen.MOBILE ? this.mobileGrid : this.desktopGrid;
		let startId = "";
		for (let i = 0; i < gridRef.length; i++) {
			if (found) {
				break;
			}
			for (let j = 0; j < gridRef[i].length; j++) {
				indexes = this.getIndexes({ x: i, y: j }, widget.config.line, widget.config.column);
				if (
					indexes.every(
						(index) => gridRef[index.x] && gridRef[index.x][index.y] && !gridRef[index.x][index.y].taken
					)
				) {
					startId = `${ALPHABETS[indexes[0].x]}-${indexes[0].y + 1}`;
					found = true;
					indexes.forEach((index) => {
						gridRef[index.x][index.y].taken = true;
					});
					return startId;
					// break;
				}
			}
		}
		return startId;
	}
	/**
	 * Set widget style
	 * @param el
	 * @param widget
	 * @returns widget style object
	 */
	getWidgetDim(el, widget: IWidget) {
		const offsets = el.getBoundingClientRect();
		const columns = widget.screen === Screen.DESKTOP ? DESKTOP_COLUMN : MOBILE_COLUMN;
		let height: any, width: any;

		const squareWidth = (offsets.width - (GRID_GAP - 2) * (columns - 1)) / columns;
		// const squareWidth =
		// 	(offsets.width - 12 - (this.isMobile ? 0 : offsets.width * 0.1) - GRID_GAP * (columns - 1)) / columns;

		height = squareWidth * widget.config.line + (widget.config.line - 1) * (GRID_GAP - 2);
		width = squareWidth * widget.config.column + (widget.config.column - 1) * (GRID_GAP - 2);

		width = width + "px";
		height = height + "px";
		const image = widget.image[this.currentLanguage]
			? widget.image[this.currentLanguage]
			: widget.image[Object.keys(widget.image).find((k) => widget.image[k])];
		const backgroundImage =
			widget.type === WidgetType.EMPTY
				? `url('assets/images/empty_widget.png')`
				: widget.type === WidgetType.IMAGE
				? `url(${image})`
				: ``;

		return {
			width,
			height,
			backgroundImage
		};
	}

	/**
	 * Set Grid properties (columns, rows)
	 * @param el
	 * @param type
	 * @returns style object
	 */
	getColumns(el: HTMLDivElement, type: string) {
		const offsets = el.getBoundingClientRect();
		const columnNbr = type === "desktop" ? DESKTOP_COLUMN : MOBILE_COLUMN;
		const squareWidth = (offsets.width - (GRID_GAP - 2) * (columnNbr - 1)) / columnNbr;
		// const squareWidth =
		// 	(offsets.width - 12 - (this.isMobile ? 0 : offsets.width * 0.1) - GRID_GAP * (columnNbr - 1)) / columnNbr;
		///////Breaking
		return {
			"grid-template-columns": `repeat(${columnNbr}, ${squareWidth}px)`,
			"grid-template-rows": `repeat(${
				type === "desktop" ? this.desktopGrid.length : this.mobileGrid.length
			}, ${squareWidth}px)`
		};
	}

	/**
	 * Set widget positions according to div grid positions
	 * @param widget
	 */
	placeWidget(widget: IWidget) {
		widget = Object.assign(widget, {});
		widget.config = Object.assign(widget.config, {});
		const parent = widget.screen === Screen.DESKTOP ? "desktop" : "mobile";

		this.container = this.container
			? this.container
			: (document.getElementById(`container${this.module.uid}`) as HTMLDivElement);
		if (this.container) {
			const offsetsParent = this.container.getBoundingClientRect();

			if (widget.config.startId) {
				const square = document.querySelector(
					`#container${this.module.uid} #${parent}-${widget.config.startId}`
				);
				// const square = document.getElementById(`${parent}-${widget.config.startId}`);
				if (square) {
					const offsetsSquare = square.getBoundingClientRect();

					widget.config.left = Math.abs(offsetsSquare.left - offsetsParent.left) + (this.isMobile ? 6 : 0);
					widget.config.top =
						Math.abs(offsetsSquare.top - (offsetsParent.top - this.container.scrollTop)) +
						(this.isMobile ? 6 : 0);
				}
			} else {
				const square = document.querySelector(`#container${this.module.uid} #${parent}-a-1`);
				// const square = document.getElementById(`${parent}-a-1`);
				if (square) {
					const squareWidth = square.getBoundingClientRect().width;
					const { leftFormula, topFormula } = widget.config;
					let [aLeft, bLeft] = leftFormula.split("|") as any[];
					let [aTop, bTop] = topFormula.split("|") as any[];
					aLeft = Number(aLeft.split(":")[1]);
					bLeft = Number(bLeft.split(":")[1]);
					aTop = Number(aTop.split(":")[1]);
					bTop = Number(bTop.split(":")[1]);
					const top = aTop * (squareWidth + GRID_GAP) + bTop * squareWidth;

					const bottom = top + (squareWidth * widget.config.line + (widget.config.line - 1) * GRID_GAP);

					widget.config.left = aLeft * (squareWidth + GRID_GAP) + bLeft * squareWidth + 6;
					widget.config.top = top - 6;
					if (bottom > offsetsParent.height) {
						this.container.style.height = `${bottom + 10}px`;
					}
				}
			}
		}
		return widget;
	}

	getIndexes(startIndex: { x: number; y: number }, lines: number, columns: number): Array<{ x: number; y: number }> {
		const indexes = [];
		for (let i = startIndex.x; i < startIndex.x + lines; i++) {
			for (let j = startIndex.y; j < startIndex.y + columns; j++) {
				indexes.push({ x: i, y: j });
			}
		}
		return indexes;
	}

	/**
	 * Get host background
	 * @returns
	 */
	getHostBackground() {
		let background = "#F6F6F6";
		if (this.module) {
			if (!this.module.options.backgroundIsDegrade) {
				background = this.module.options.backgroundColor;
			} else {
				background = `linear-gradient(to right, ${this.module.options.backgroundColor}, ${this.module.options.backgroundColorSecondary})`;
			}
		}
		return background;
	}

	/**
	 * Calc min height for mobile
	 * @param container
	 * @returns
	 */
	calcMinHeight(container: any) {
		let minHeight = "100%";

		if (this.module.options.backgroundTypeImage) {
			if (this.isMobile) {
				minHeight = container.clientWidth * 2 + "px";
			} else {
				minHeight = (container.clientWidth * 56.25) / 100 + "px";
			}
		}
		return minHeight;
	}

	/**
	 * Set backround
	 * @returns
	 */
	getModuleBackground() {
		let style = {};
		style = { "background-color": "#F6F6F6" };
		if (this.module) {
			if (!this.module.options.backgroundTypeImage) {
				if (!this.module.options.backgroundIsDegrade) {
					style = {
						position: "absolute",
						"background-color": this.module.options.backgroundColor
					};
				} else {
					style = {
						position: "absolute",
						// eslint-disable-next-line max-len
						background: `linear-gradient(to right, ${this.module.options.backgroundColor}, ${this.module.options.backgroundColorSecondary})`
					};
				}
			} else if (this.module.options.backgroundTypeImage && this.module.options.backgroundFixed) {
				const image = this.isMobile
					? this.module.options.backgroundMobileImage
					: this.module.options.backgroundDesktopImage;
				if (this.module.options.backgroundTypeImage) {
					style = {
						position: "fixed",
						"background-image": `url(${image})`,
						"background-size": this.isMobile ? "auto 100%" : "100% auto",
						"background-repeat": "no-repeat",
						"background-position": "center top",
						"background-origin": "content-box"
					};
				}
			} else {
				const image = this.isMobile
					? this.module.options.backgroundMobileImage
					: this.module.options.backgroundDesktopImage;
				if (this.module.options.backgroundTypeImage) {
					style = {
						position: "absolute",
						"background-image": `url(${image})`,
						"background-size": this.isMobile ? "auto 100%" : "100% auto",
						"background-repeat": "no-repeat",
						"background-position": "center top"
					};
				}
			}
		}
		return style;
	}

	/**
	 * Open Widget
	 * @param widget
	 */
	async openWidget(widget: IWidget) {
		if (widget.route == "-1" || !widget.route) {
			return;
		}
		if (widget.isExtern) {
			if (widget.openLinkBehavior === "sf") {
				if (
					this.platform.is("mobile") &&
					window.innerWidth < 768 &&
					(this.platform.is("ios") || this.platform.is("android"))
				) {
					await Browser.open({
						url: widget.route,
						toolbarColor: this.event.styling.menuColor,
						presentationStyle: "fullscreen"
					});
				} else {
					this.openExternalLink(widget.route);
				}
			} else if (widget.openLinkBehavior === "in-app" || !widget.openLinkBehavior) {
				window.open(widget.route, "_blank");
			}
		} else {
			if (
				!widget.route.includes(`event/${this.eventId}`) &&
				widget.route !== "notification" &&
				widget.route !== "PublicRegister" &&
				widget.route !== "PublicLogin" &&
				widget.route !== "card-exchange" &&
				widget.route.split("_").length <= 1
			) {
				if (widget.openLinkBehavior === "sf") {
					if (
						this.platform.is("mobile") &&
						window.innerWidth < 768 &&
						(this.platform.is("ios") || this.platform.is("android"))
					) {
						await Browser.open({
							url: widget.route,
							toolbarColor: this.event.styling.menuColor,
							presentationStyle: "fullscreen"
						});
					} else {
						this.openExternalLink(widget.route);
					}
				} else if (widget.openLinkBehavior === "in-app" || !widget.openLinkBehavior) {
					window.open(widget.route, "_blank");
				}
			} else if (widget.route === "notification") {
				this.openNotifications();
			} else if (widget.route === "PublicRegister") {
				this.openRegister();
			} else if (widget.route === "PublicLogin") {
				this.openLogin();
			} else if (widget.route.includes("register-form")) {
				this.openRegister(widget.route?.split("_")?.[1]);
			} else if (widget.route === "card-exchange") {
				this.openCardExchange();
			} else if (widget.route.includes("chats")) {
				this.navCtrl.navigateForward([widget.route]);
			} else if (widget.route.includes("profile")) {
				this.navCtrl.navigateForward([
					`/event/${this.eventId}/profile/${this.eventUser.moduleId}/${this.eventUser.uid}`
				]);
			} else if (widget.route.includes("gallery") || widget.route.includes("documents")) {
				const folders = await firstValueFrom(
					this.store.select(
						getFoldersOfModule({
							moduleId: widget.route.split("/")[widget.route.split("/").length - 1],
							order: "asc",
							language: this.currentLanguage
						})
					)
				);
				folders.length === 1
					? this.navCtrl.navigateForward([`${widget.route}/folder/${folders[0].uid}`])
					: this.navCtrl.navigateForward([widget.route]);
			} else if (widget.route.includes("schedule")) {
				const sessions = await firstValueFrom(
					this.SSchedules.getSessionsOfModuleWithFilters(
						this.eventId,
						widget.route.split("/")[widget.route.split("/").length - 1],
						null,
						false
					)
				);
				sessions.datas.length === 1
					? this.navCtrl.navigateForward([`${widget.route}/session/${sessions.datas[0].uid}`])
					: this.navCtrl.navigateForward([widget.route]);
			} else if (
				widget.route.includes("asks-questions") ||
				widget.route.includes("quizs") ||
				widget.route.includes("surveys")
			) {
				this.navCtrl.navigateForward([widget.route]);
			} else {
				this.navCtrl.navigateForward([widget.route]);
			}
		}
	}

	checkAccessible(data: IQuiz | ISurvey) {
		return (
			!data.eventUserResults ||
			data.eventUserResults.length === 0 ||
			(data.eventUserResults &&
				data.eventUserResults.length !==
					(data.questions as any[]).filter((question) => question.type !== "plainText").length)
		);
	}

	/**
	 * Open notification modal
	 * @returns
	 */
	async openNotifications() {
		const notifications = this.SNotifications.getNotifications();
		const modal = await this.modalCtrl.create({
			component: PathComponents.notifications,
			componentProps: {
				eventId: this.event.uid,
				// currentUser: this.eventUser,
				openTime: DateTime.now().valueOf(),
				notifications: notifications.filter((notif) => notif.deliveryDate <= DateTime.now().valueOf()),
				displayNotifID: ""
			},
			cssClass: "full-sized-modal"
		});
		return await modal.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin() {
		try {
			const modal = await this.modalCtrl.create({
				id: "login-modal",
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event.uid,
					manualOpen: true
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister();
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister(formModuleId?: string) {
		try {
			// eslint-disable-next-line @typescript-eslint/await-thenable
			const form: IRegisterForm = (await this.SRegisterForm.getRegisterFormOfModule(
				this.eventId,
				formModuleId
					? !this.eventUser
						? formModuleId
						: this.eventUser.moduleId
					: this.eventUser
					? this.eventUser.moduleId
					: this.event.settings.defaultAttendeesModule
			)) as IRegisterForm;

			if (
				form &&
				!form.formSettings.canBeEditedAfterSubmission &&
				this.eventUser &&
				this.eventUser.hasFilledRegisterForm
			) {
				this.snackbar.open(this.STranslate.instant("snackbar.form_cannot_be_edited_after_submission"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			const totalEventUsers = await firstValueFrom(
				this.SFirestore.getCountOfQueryObs("event-users", "group", [where("eventId", "==", this.event.uid)])
			);

			if (totalEventUsers >= this.event?.limitations?.usersLimit) {
				this.snackbar.open(this.STranslate.instant("register.event_users_limit_reached"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				return;
			}

			const modal = await this.modalCtrl.create({
				id: !this.eventUser ? "register-modal" : "fill-form-modal",
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid,
					moduleId: formModuleId
						? !this.eventUser
							? formModuleId
							: this.eventUser.moduleId
						: this.eventUser
						? this.eventUser.moduleId
						: this.event.settings.defaultAttendeesModule,
					mode: !this.eventUser ? "register-modal" : "fill-form-modal",
					manualOpen: true
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openLogin) {
				this.openLogin();
			}

			// <HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
			// 	((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
			// 		"hidden");
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open card exchange
	 */
	async openCardExchange() {
		try {
			this.cardExchangeModule = (await firstValueFrom(
				this.store.select(getModulesByType(TypeModule.CARD_EXCHANGE)).pipe(
					take(1),
					switchMap((modules: IModule[]) => (modules.length > 0 ? of(modules[0]) : of([])))
				)
			)) as IModule;
			this.navCtrl.navigateForward([`/event/${this.eventId}/card-exchange/${this.cardExchangeModule.uid}/`]);
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open external link modal
	 * @returns
	 */
	async openExternalLink(url: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.externalLinkComponent,
				componentProps: {
					event: this.event,
					url: url
				},
				cssClass: "sized-modal-80"
			});
			await modal.present();
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}
}
