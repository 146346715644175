import { Injectable } from "@angular/core";
import { DateTime } from "luxon";
import { combineLatest } from "rxjs";
import { auditTime } from "rxjs/operators";
import { IAskQuestionsResult, IAskQuestionsResultVote } from "../interfaces/ask-questions.interfaces";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class ResultsService {
	constructor(private SFirestore: FirestoreService) {}

	/**
	 * Getting basic datas for results
	 * @param eventId
	 * @param moduleId
	 * @returns
	 */
	gettingBasicDatas(eventId: string, moduleId: string) {
		return combineLatest([
			this.SFirestore.valueChangesDocument(`events/${eventId}`),
			this.SFirestore.valueChangesDocument(`events/${eventId}/modules/${moduleId}`)
		]).pipe(auditTime(500));
	}

	async createResults(eventId: string, moduleId: string, askQuestionsId: string, userId: string) {
		const id = this.SFirestore.createId(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results`
		);

		const askQuestionNew: IAskQuestionsResult = {
			uid: id,
			askQuestionsId: askQuestionsId,
			answered: true,
			creationDate: "",
			edit: false,
			eventId: eventId,
			markedAnonymousByUser: false,
			moduleId: moduleId,
			question: "Test question",
			totalVotes: 0,
			userId: userId,
			visibility: true
		};

		await this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${id}`,
			askQuestionNew
		);

		const idNewVote = this.SFirestore.createId(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${id}/ask-questions-results-votes`
		);
		const newVote: IAskQuestionsResultVote = {
			askQuestionsId: askQuestionsId,
			askQuestionsResultId: id,
			creationDate: DateTime.local().toISO(),
			eventId: eventId,
			moduleId: moduleId,
			uid: idNewVote,
			userId: userId
		};

		await this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${id}/ask-questions-results-votes/${idNewVote}`,
			newVote
		);
	}
}
