<div *ngIf="event && visio" class="container-visio" [class.background-mini-player]="miniPlayer && type === 'chat'"
    [class.background-large-player]="!miniPlayer && type === 'chat'" (mouseup)="moveMiniPlayerActivation(false, $event)"
    #container>
    <div id="backgroundIframe" class="background-iframe" [class.mini-player]="miniPlayer" *ngIf="!isMobile"
        [class.no-transform]="noTransform" #backgroundIframe [style.height]="height">
        <div *ngIf="joined && urlIframe" class="btn-visio-container">
            <div class="fullscreen-container fullscreen-icon" (click)="fullScreenVisio()">
                <ion-icon class="custom-icon-visio" src="/assets/icon/scan.svg" style="stroke: white;"></ion-icon>
            </div>
            <div *ngIf="visio.options.allowLeaveBtn" class="fullscreen-container exit-icon" (click)="leaveVisio()">
                <ion-icon class="custom-icon-visio" name="exit" color="light"></ion-icon>
            </div>
        </div>

        <div *ngIf="miniPlayer && !fullscreenMode" class="btn-mini-player-container">
            <div class="fullscreen-container fullscreen-icon" (mousedown)="moveMiniPlayerActivation(true, $event)">
                <ion-icon class="custom-icon-visio" name="move" color="light"></ion-icon>
            </div>
        </div>

        <!-- Whereby -->
        <iframe [style.width]="width" [style.height]="height" [style.max-height]="height" id="visioIframe"
            *ngIf="joined && (!session || !session.visio.type || (session.visio.type && session.visio.type !== 'zoom'))"
            [src]="urlIframe" allow="camera; microphone; fullscreen; speaker; display-capture"></iframe>

        <!-- Zoom iframe -->
        <!-- <div id="zmmtg-root-2" [style.--width]="width" [style.--height]="height" [style.--max-height]="height"
            #zoomContainer></div> -->

        <span *ngIf="!joined" (click)="joinedVisio()" class="btn-join-visio" [style.color]="event.styling.btnBgColor">
            <ion-icon src="assets/icon/Camera2.svg"></ion-icon>
            <span>{{ "texts.join-visio" | translate }}</span>
        </span>
    </div>

    <span *ngIf="isMobile && showJoinBtn" (click)="openVisio()" class="btn-join-visio"
        [style.color]="event.styling.btnBgColor">
        <ion-icon src="assets/icon/Camera2.svg"></ion-icon>
        <span>{{"texts.join-visio" | translate}}</span>
    </span>
</div>