<span class="text" *ngIf="text.length <= textLength">
	{{ text }}
</span>

<span class="text" *ngIf="text.length > textLength && !showMore">
	{{ text.substr(0, textLength) }}...
	<button class="show-more-button" (click)="changeShowState()">
		{{ "buttons.show_more" | translate }}
	</button>
</span>

<span class="text" *ngIf="text.length > textLength && showMore">
	{{ text }}
	<button class="show-less-button" (click)="changeShowState()">{{ "buttons.show_less" | translate }}</button>
</span>
