<div *ngIf="event && module" class="background" [class.full-w]="componentMode" [style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'">
    <ng-container *ngIf="externalsInteractivity.length > 0; else noContent">
        <div *ngFor="let externalInteractivity of externalsInteractivity">
            <ion-item detail class="card-content cursor-pointer" [style.background]="event.styling.bgContentColor"
                *ngIf="externalInteractivity.visibility" (click)="openExternalInteractivity(externalInteractivity)">
                <span [style.color]="event.styling.titleColor">{{ externalInteractivity.name[currentLanguage] }}</span>
            </ion-item>
        </div>
    </ng-container>

    <ng-template #noContent>
        <div class="container-no-content">
            <span class="wait-content" [style.color]="event.styling.titleColor">{{"texts.waiting_for_content" |
                translate }}</span>
            <ion-icon src="assets/images/emptycontent/AUCUNRESULTAT.svg"
                [style.color]="event.styling.menuColor.includes('linear') ? event.styling.menuColor.split(',')[1] : event.styling.menuColor">
            </ion-icon>
            <div class="text-empty-content" [style.color]="event.styling.contentTextColor">
                <span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
                <span>{{ "interactivity.default_empty_content_text" | translate }}</span>
            </div>
        </div>
    </ng-template>
</div>

<!-- <app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons> -->