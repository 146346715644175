import { createReducer, on } from "@ngrx/store";
import * as AutomationsActions from "../actions/automations.actions";
import { IAutomationState } from "../interfaces";

export const initialState: IAutomationState = {
	eventId: "",
	automations: []
};

export const automationsFeatureKey = "automations";

export const automationsReducer = createReducer(
	initialState,
	on(AutomationsActions.GetAllAutomations, (state, action) => ({
		...state,
		automations: action.payload,
		eventId: action.eventId
	})),
	on(AutomationsActions.ResetAutomationsState, () => ({
		...initialState
	}))
);
