import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEventUser, ISchedule, ISchedulesState } from "../interfaces";
import { IChatsState } from "../interfaces/chats.interfaces";
import { getCurrentEventUser } from "./auth.selectors";
import { getSchedulesState } from "./schedules.selectors";

export const getChatsState = createFeatureSelector<IChatsState>("chats");

export const checkSameEvent = (uid: string) =>
	createSelector(getChatsState, (state: IChatsState) => {
		return state.eventId === uid ? true : false;
	});

export const getChats = createSelector(getChatsState, (state: IChatsState) => _.cloneDeep(state.chats));

export const getSpecificChat = (uid: string) =>
	createSelector(getChatsState, (state: IChatsState) => _.cloneDeep(state.chats.find((chat) => chat.uid === uid)));

export const getChatOfTwo = (data: { myId: string; userId: string; eventId: string }) =>
	createSelector(getChatsState, (state: IChatsState) =>
		_.cloneDeep(state.chats).filter(
			(chat) =>
				chat.type === 0 &&
				chat.eventId === data.eventId &&
				chat.members.includes(data.myId) &&
				chat.members.includes(data.userId)
		)
	);

export const getMessages = createSelector(getChatsState, (state: IChatsState) => _.cloneDeep(state.messages));

export const getMessagesForChatType = (type: number) =>
	createSelector(getChatsState, (state: IChatsState) =>
		_.cloneDeep(state.messages).filter(
			(message) =>
				state.chats.find((chat) => chat.uid === message.chatId) &&
				state.chats.find((chat) => chat.uid === message.chatId).type === type
		)
	);

export const getMessagesOfChat = (chatId: string) =>
	createSelector(getChatsState, (state: IChatsState) =>
		_.cloneDeep(state.messages).filter((message) => message.chatId === chatId)
	);

export const getSessionDiscussionsGroups = (session: ISchedule) =>
	createSelector(getChatsState, (state: IChatsState) => {
		const chats = state.chats.filter((chat) => {
			return (
				chat.type === 1 &&
				chat.options &&
				chat.options.schedulesLink.linked &&
				(chat.options.schedulesLink.linkType === 0 ||
					(chat.options.schedulesLink.linkType === 1 &&
						chat.options.schedulesLink.linkedModules.includes(session.moduleId)) ||
					(chat.options.schedulesLink.linkType === 2 &&
						chat.options.schedulesLink.linkedSpecifics.includes(session.uid)))
			);
		});
		return chats ? _.cloneDeep(chats) : [];
	});

export const getAccessiblesChatsByType = (type: number, sessionId: string) =>
	createSelector(
		getChatsState,
		getCurrentEventUser,
		getSchedulesState,
		(state: IChatsState, eventUser: IEventUser, schedulesState: ISchedulesState) => {
			let chats = _.cloneDeep(state.chats);
			const session = schedulesState.schedules.find((session) => session.uid == sessionId);
			if (type === 1) {
				chats = chats
					.filter(
						(chat) =>
							chat.type === 1 &&
							(!chat.options.groupsLink.linked ||
								(chat.options.groupsLink.linked &&
									chat.options.groupsLink.groups.some((groupId) =>
										eventUser.groups.includes(groupId)
									)))
					)
					.filter(
						(chat) =>
							chat.options &&
							chat.options.schedulesLink.linked &&
							(chat.options.schedulesLink.linkType === 0 ||
								(chat.options.schedulesLink.linkType === 1 &&
									chat.options.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(chat.options.schedulesLink.linkType === 2 &&
									chat.options.schedulesLink.linkedSpecifics.includes(sessionId)))
					);
			}
			return chats;
		}
	);
