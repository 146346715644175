/* eslint-disable max-len */
import { Injectable } from "@angular/core";
import { PrivacyTermsComponent } from "src/app/content/components/privacy-terms/privacy-terms.component";
import { TermsOfUseComponent } from "src/app/content/components/terms-of-use/terms-of-use.component";
import { HeaderComponent } from "src/app/content/pages/event/components/header/header.component";
import { AdsModalComponent } from "src/app/content/pages/event/components/modals/ads-modal/ads-modal.component";
import { ConfirmIcsDownloadComponent } from "src/app/content/pages/event/components/modals/confirm-ics-download/confirm-ics-download.component";
import { ConfirmRegisterComponent } from "src/app/content/pages/event/components/modals/confirm-register/confirm-register.component";
import { CreateChatComponent } from "src/app/content/pages/event/components/modals/create-chat/create-chat.component";
import { CustomFieldCountryComponent } from "src/app/content/pages/event/components/modals/custom-field-country/custom-field-country.component";
import { ExternalLinkComponent } from "src/app/content/pages/event/components/modals/external-link/external-link.component";
import { ImageViewerComponent } from "src/app/content/pages/event/components/modals/image-viewer/image-viewer.component";
import { LocationModalComponent } from "src/app/content/pages/event/components/modals/location-modal/location-modal.component";
import { LoginComponent } from "src/app/content/pages/event/components/modals/login/login.component";
import { NotificationsComponent } from "src/app/content/pages/event/components/modals/notifications/notifications.component";
import { AccompanyingInviteModalComponent } from "src/app/content/pages/event/components/modals/register-form/components/accompanying-invite-modal/accompanying-invite-modal.component";
import { FormFieldErrorsComponent } from "src/app/content/pages/event/components/modals/register-form/components/form-field-errors/form-field-errors.component";
import { FormFieldIconsComponent } from "src/app/content/pages/event/components/modals/register-form/components/form-field-icons/form-field-icons.component";
import { FormFieldsComponent } from "src/app/content/pages/event/components/modals/register-form/components/form-fields/form-fields.component";
import { RegisterFormComponent } from "src/app/content/pages/event/components/modals/register-form/register-form.component";
import { TermsNPrivacyComponent } from "src/app/content/pages/event/components/modals/terms-n-privacy/terms-n-privacy.component";
import { TimezoneSwitchComponent } from "src/app/content/pages/event/components/modals/timezone-switch/timezone-switch.component";
import { AppointmentDetailsPage } from "src/app/content/pages/event/components/modules/appointments/appointment-details/appointment-details.page";
import { AppointmentsSettingsComponent } from "src/app/content/pages/event/components/modules/appointments/appointments-settings/appointments-settings.component";
import { ListAppointmentsPage } from "src/app/content/pages/event/components/modules/appointments/list-appointments/list-appointments.page";
import { UserProfileTimeslotsPage } from "src/app/content/pages/event/components/modules/appointments/user-profile-timeslots/user-profile-timeslots.page";
import { AskQuestionsComponent } from "src/app/content/pages/event/components/modules/asks-questions/ask-questions/ask-questions.component";
import { AsksQuestionsComponent } from "src/app/content/pages/event/components/modules/asks-questions/asks-questions.component";
import { CardExchangeListComponent } from "src/app/content/pages/event/components/modules/card-exchange/card-exchange-list/card-exchange-list.component";
import { CardExchangeProfileComponent } from "src/app/content/pages/event/components/modules/card-exchange/card-exchange-profile/card-exchange-profile.component";
import { CardExchangeComponent } from "src/app/content/pages/event/components/modules/card-exchange/card-exchange.component";
import { ChatsComponent } from "src/app/content/pages/event/components/modules/chats/chats.component";
import { ChatDetailsComponent } from "src/app/content/pages/event/components/modules/chats/components/chat-details/chat-details.component";
import { CheckinComponent } from "src/app/content/pages/event/components/modules/checkins/checkin/checkin.component";
import { CheckinsComponent } from "src/app/content/pages/event/components/modules/checkins/checkins.component";
import { CardExchangeFormComponent } from "src/app/content/pages/event/components/modules/components/card-exchange-form/card-exchange-form.component";
import { CustomFieldsEditComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/custom-fields-edit/custom-fields-edit.component";
import { CustomFieldsTagsComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/custom-fields-tags/custom-fields-tags.component";
import { CustomFieldsViewComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/custom-fields-view/custom-fields-view.component";
import { FilterComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/filter/filter.component";
import { FormVisualErrorComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/small-components/cf-form-visual-error/cf-form-visual-error.component";
import { FormVisualIconComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/small-components/cf-form-visual-icon/cf-form-visual-icon.component";
import { MoreLessComponent } from "src/app/content/pages/event/components/modules/components/custom-fields/small-components/cf-more-less/cf-more-less.component";
import { IonIntlTelInputComponent } from "src/app/content/pages/event/components/modules/components/ion-intl-tel-input/ion-intl-tel-input.component";
import { LoginRegisterButtonsComponent } from "src/app/content/pages/event/components/modules/components/login-register-buttons/login-register-buttons.component";
import { MiniVideoPlayerComponent } from "src/app/content/pages/event/components/modules/components/mini-video-player/mini-video-player.component";
import { VideoPlayerComponent } from "src/app/content/pages/event/components/modules/components/video-player/video-player.component";
import { VisioComponent } from "src/app/content/pages/event/components/modules/components/visio/visio.component";
import { CustomPagesComponent } from "src/app/content/pages/event/components/modules/custom-pages/custom-pages.component";
import { DocumentsComponent } from "src/app/content/pages/event/components/modules/documents-folders-list/components/documents/documents.component";
import { DocumentsFoldersListComponent } from "src/app/content/pages/event/components/modules/documents-folders-list/documents-folders-list.component";
import { EventUserItemComponent } from "src/app/content/pages/event/components/modules/event-users/event-user-item/event-user-item.component";
import { EventUsersComponent } from "src/app/content/pages/event/components/modules/event-users/event-users.component";
import { ExternalInteractivityComponent } from "src/app/content/pages/event/components/modules/externals-interactivity/external-interactivity/external-interactivity.component";
import { ExternalsInteractivityComponent } from "src/app/content/pages/event/components/modules/externals-interactivity/externals-interactivity.component";
import { EventUserFavoriteComponent } from "src/app/content/pages/event/components/modules/favorites/event-users-favorites.component/event-users-favorites.component";
import { FavoritesComponent } from "src/app/content/pages/event/components/modules/favorites/favorites.component";
import { ScheduleFavoritesComponent } from "src/app/content/pages/event/components/modules/favorites/schedule-favorites/schedule-favorites.component";
import { FeedNewsCommentsComponent } from "src/app/content/pages/event/components/modules/feed-news/components/feed-news-comments/feed-news-comments.component";
import { FeedNewsListReactionComponent } from "src/app/content/pages/event/components/modules/feed-news/components/feed-news-list-reaction/feed-news-list-reaction.component";
import { FeedNewsReactComponent } from "src/app/content/pages/event/components/modules/feed-news/components/feed-news-react/feed-news-react.component";
import { FeedNewsComponent } from "src/app/content/pages/event/components/modules/feed-news/feed-news.component";
import { FeedbackComponent } from "src/app/content/pages/event/components/modules/feedbacks/feedback/feedback.component";
import { FeedbacksComponent } from "src/app/content/pages/event/components/modules/feedbacks/feedbacks.component";
import { GalleryComponent } from "src/app/content/pages/event/components/modules/gallery-folders-list/components/gallery/gallery.component";
import { GalleryFoldersListComponent } from "src/app/content/pages/event/components/modules/gallery-folders-list/gallery-folders-list.component";
import { GamificationComponent } from "src/app/content/pages/event/components/modules/gamification/gamification.component";
import { InfoComponent } from "src/app/content/pages/event/components/modules/info/info.component";
import { InteractivityManagerComponent } from "src/app/content/pages/event/components/modules/interactivity/interactivity-manager/interactivity-manager.component";
import { InteractivityComponent } from "src/app/content/pages/event/components/modules/interactivity/interactivity.component";
import { LocationComponent } from "src/app/content/pages/event/components/modules/locations/location/location.component";
import { LocationsComponent } from "src/app/content/pages/event/components/modules/locations/locations.component";
import { NoteTakingAddComponent } from "src/app/content/pages/event/components/modules/note-taking/note-taking-add/note-taking-add.component";
import { NoteTakingDetailsComponent } from "src/app/content/pages/event/components/modules/note-taking/note-taking-details/note-taking-details.component";
import { NoteTakingEditComponent } from "src/app/content/pages/event/components/modules/note-taking/note-taking-edit/note-taking-edit.component";
import { NoteTakingComponent } from "src/app/content/pages/event/components/modules/note-taking/note-taking.component";
import { PersonalScheduleComponent } from "src/app/content/pages/event/components/modules/personal-schedule/personal-schedule.component";
import { ProfileGeneralInfoComponent } from "src/app/content/pages/event/components/modules/profile/components/profile-general-info/profile-general-info.component";
import { ProfileEditPage } from "src/app/content/pages/event/components/modules/profile/profile-edit/profile-edit.page";
import { ProfilePage } from "src/app/content/pages/event/components/modules/profile/profile.page";
import { QuizResultComponent } from "src/app/content/pages/event/components/modules/quizs/quiz-result/quiz-result.component";
import { QuizComponent } from "src/app/content/pages/event/components/modules/quizs/quiz/quiz.component";
import { QuizsComponent } from "src/app/content/pages/event/components/modules/quizs/quizs.component";
import { RankingComponent } from "src/app/content/pages/event/components/modules/rankings-list/components/ranking/ranking.component";
import { RankingsListComponent } from "src/app/content/pages/event/components/modules/rankings-list/rankings-list.component";
import { SchedulesFiltersComponent } from "src/app/content/pages/event/components/modules/schedules/schedules-filters/schedules-filters.component";
import { SchedulesComponent } from "src/app/content/pages/event/components/modules/schedules/schedules.component";
import { SessionComponent } from "src/app/content/pages/event/components/modules/schedules/session/session.component";
import { SelfCheckinComponent } from "src/app/content/pages/event/components/modules/self-checkin/self-checkin.component";
import { SurveyComponent } from "src/app/content/pages/event/components/modules/surveys/survey/survey.component";
import { SurveysComponent } from "src/app/content/pages/event/components/modules/surveys/surveys.component";
import { TrainingsComponent } from "src/app/content/pages/event/components/modules/trainings/trainings.component";
import { TreasureHuntComponent } from "src/app/content/pages/event/components/modules/treasure-hunts/treasure-hunt/treasure-hunt.component";
import { TreasureHuntsComponent } from "src/app/content/pages/event/components/modules/treasure-hunts/treasure-hunts.component";
import { WidgetComponent } from "src/app/content/pages/event/components/modules/widgets/widgets.component";
import { WordsCloudComponent } from "src/app/content/pages/event/components/modules/words-clouds/words-cloud/words-cloud.component";
import { WordsCloudsComponent } from "src/app/content/pages/event/components/modules/words-clouds/words-clouds.component";
import { ChatSettingsPopupComponent } from "src/app/content/pages/event/components/popovers/chat-settings-popup/chat-settings-popup.component";
import { EventPage } from "src/app/content/pages/event/event.page";
import { EventsListPage } from "src/app/content/pages/events-list/events-list.page";
import { LoginPage } from "src/app/content/pages/login/login.page";
import { ResetPasswordComponent } from "src/app/content/pages/reset-password/reset-password.component";
import { SplashPage } from "src/app/content/pages/splash/splash.page";

@Injectable()
export class PathComponents {
	static splash = SplashPage;

	static login = LoginPage;
	static resetPassword = ResetPasswordComponent;

	// Popovers
	static groupChatsSettings = ChatSettingsPopupComponent;

	// Modals
	static externalLinkComponent = ExternalLinkComponent;
	static imageViewerModal = ImageViewerComponent;
	static confirmRegisterModal = ConfirmRegisterComponent;
	static inviteAccompanyingModal = AccompanyingInviteModalComponent;
	static confirmIcsDownloadComponent = ConfirmIcsDownloadComponent;
	static coutryListModal = CustomFieldCountryComponent;
	static loginModal = LoginComponent;
	static registerFormFields = FormFieldsComponent;
	static filterModal = FilterComponent;
	static timezoneSwitch = TimezoneSwitchComponent;

	static privacy = PrivacyTermsComponent;
	static termsOfUse = TermsOfUseComponent;
	static termAndPrivacy = TermsNPrivacyComponent;

	static eventsList = EventsListPage;
	static event = EventPage;
	static header = HeaderComponent;

	// Reusable components
	static moreless = MoreLessComponent;
	static formVisualError = FormVisualErrorComponent;
	static formVisualIcon = FormVisualIconComponent;
	static loginRegisterBtns = LoginRegisterButtonsComponent;
	static formFieldsError = FormFieldErrorsComponent;
	static formFieldsIcon = FormFieldIconsComponent;

	/**
	 * Modules
	 */

	// Appointments
	static appointmentDetails = AppointmentDetailsPage;
	static appointmentsList = ListAppointmentsPage;
	static userProfileTimeSlots = UserProfileTimeslotsPage;
	static appointmentsSettings = AppointmentsSettingsComponent;

	// Ask questions
	static asksQuestions = AsksQuestionsComponent;
	static askQuestions = AskQuestionsComponent;

	// Cards Exchange
	static cardExchange = CardExchangeComponent;
	static cardExchangeList = CardExchangeListComponent;
	static cardExchangeProfile = CardExchangeProfileComponent;
	static cardExchangeForm = CardExchangeFormComponent;

	// Chats
	static chats = ChatsComponent;
	static chatDetails = ChatDetailsComponent;
	static createChat = CreateChatComponent;

	// Checkins
	static checkins = CheckinsComponent;
	static checkin = CheckinComponent;
	static selfCheckin = SelfCheckinComponent;

	// Custom pages
	static customPages = CustomPagesComponent;

	// Custom fields
	static customFieldsEdit = CustomFieldsEditComponent;
	static customFieldsView = CustomFieldsViewComponent;
	static customFieldsTags = CustomFieldsTagsComponent;

	// Phone number Input
	static ionIntlTelInputComponent = IonIntlTelInputComponent;

	// Documents
	static documentsFolders = DocumentsFoldersListComponent;
	static documents = DocumentsComponent;

	// Event information
	static eventInfo = InfoComponent;

	// Event users
	static eventUsers = EventUsersComponent;
	static eventUserItem = EventUserItemComponent;

	// Externals interactivity
	static externalsInteractivity = ExternalsInteractivityComponent;
	static externalInteractivity = ExternalInteractivityComponent;

	static favorites = FavoritesComponent;
	static scheduleFavorites = ScheduleFavoritesComponent;
	static eventUserFavorites = EventUserFavoriteComponent;

	// Feedbacks
	static feedbacks = FeedbacksComponent;
	static feedback = FeedbackComponent;

	// Feed news
	static feedNews = FeedNewsComponent;
	static feedNewsComment = FeedNewsCommentsComponent;
	static feedNewsReact = FeedNewsReactComponent;
	static feedNewsListReaction = FeedNewsListReactionComponent;

	// Gallery
	static galleryFolders = GalleryFoldersListComponent;
	static gallery = GalleryComponent;

	// Gamification
	static gamification = GamificationComponent;

	// Interactivity
	static interactivity = InteractivityComponent;
	static interactivityManager = InteractivityManagerComponent;

	// Locations
	static locations = LocationsComponent;
	static location = LocationComponent;
	static locationModal = LocationModalComponent;

	// Note taking
	static noteTaking = NoteTakingComponent;
	static noteTakingDetails = NoteTakingDetailsComponent;
	static noteTakingAdd = NoteTakingAddComponent;
	static noteTakingEdit = NoteTakingEditComponent;

	// Notifications
	static notifications = NotificationsComponent;

	// Profile
	static profile = ProfilePage;
	static profileGeneralInfo = ProfileGeneralInfoComponent;
	static profileEdit = ProfileEditPage;

	// Quizs
	static quizs = QuizsComponent;
	static quiz = QuizComponent;
	static quizResult = QuizResultComponent;

	// Register Form (new)
	static registerForm = RegisterFormComponent;

	// Ads Modal
	static adsModal = AdsModalComponent;

	// Rankings
	static rankings = RankingsListComponent;
	static ranking = RankingComponent;

	// Schedules
	static personalSchedule = PersonalScheduleComponent;
	static schedules = SchedulesComponent;
	static session = SessionComponent;
	static schedulesFilters = SchedulesFiltersComponent;

	// Surveys
	static surveys = SurveysComponent;
	static survey = SurveyComponent;

	// Trainings
	static trainings = TrainingsComponent;

	// Treasure hunts
	static treasureHunt = TreasureHuntComponent;
	static treasureHunts = TreasureHuntsComponent;

	// Video player
	static miniVideoPlayer = MiniVideoPlayerComponent;
	static videoPlayer = VideoPlayerComponent;

	// Visio
	static visio = VisioComponent;

	// Word clouds
	static wordsClouds = WordsCloudsComponent;
	static wordsCloud = WordsCloudComponent;

	// Widgets
	static widgets = WidgetComponent;
}
