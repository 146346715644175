<div class="react-container">
    <div>
        <div class="normal-text like-container">
            <div class="nbr-likes" [style.color]="event.styling.btnBgColor" (click)="showListReactions()">
                <div style="position: relative;height: 26px;" [style.width.px]="(postReactions.length + 1) * 26">
                    <div class="reacted-icon" *ngFor="let icon of postReactions">
                        <ion-avatar [style.background]="'var(--'+icon.name+'-bg)'">
                            <ion-icon src="assets/icon/Reactions/{{icon.name}}.svg"></ion-icon>
                        </ion-avatar>
                    </div>
                    <span *ngIf="post.totalLikes > 0" class="total-like"
                        style="text-align: center; background: transparent !important; margin-top: 2px;"> {{
                        post.totalLikes }} </span>
                </div>
            </div>

            <span [style.cursor]="post.totalComments > 0 ? 'pointer' : 'default'"
                (click)="post.totalComments > 0 ? commentPost() : ''">
                {{ post.totalComments }} {{post.totalComments > 1 ? ("feed_news.comments" | translate) :
                ("feed_news.comment" | translate) }}
            </span>

        </div>
        <div class="actions">
            <div (click)="isMobile ? mobileShowBtnReactionBox() : likeDislikePost()" class="btn"
                (mouseenter)="onMouseEnter()" (mouseleave)="onMouseLeave()" #btnReaction>
                <ion-icon
                    [src]="selectedIcon && selectedIcon.selected ? 'assets/icon/Reactions/' + selectedIcon.name + '.svg' : 'assets/icon/Reactions/thumb-up-2.svg'">
                </ion-icon>
                <ion-label [class]="selectedIcon && selectedIcon.selected ? selectedIcon.name : 'not-selected' "
                    [style.font-weight]="selectedIcon && selectedIcon.selected ? 700 : 400">
                    {{selectedIcon && selectedIcon.selected ? selectedIcon.text : "feed_news.thumb-up" | translate}}
                </ion-label>
            </div>
            <div class="btn" (click)="commentPost()">
                <ion-icon src="assets/icon/Message.svg"></ion-icon>
                <ion-label>{{"buttons.comment" | translate}}</ion-label>
            </div>
        </div>
    </div>
    <div *ngIf="showIcons" class="reactions" (mouseenter)="mouseEnterReactions()" (mouseleave)="mouseLeaveReactions()">
        <div style="background: transparent; position: relative;">
            <ion-button (click)="selectEmoji(icon)" *ngFor="let icon of listIcons" style="overflow: hidden;"
                class="btn-icon" [id]="icon.name">
                <ion-icon src="assets/icon/Reactions/{{icon.name}}.svg"></ion-icon>
            </ion-button>
        </div>
    </div>
</div>