import { createAction, props } from "@ngrx/store";
import { ITreasureHunt, ITreasureHuntQrcode } from "../interfaces/treasure-hunts.interfaces";

export enum GamificationTypes {
	GetAllTreasureHunts = "[TreasureHunts Service] Get all treasure hunts",
	GetAllTreasureHuntsQrcodes = "[TreasureHunts Service] Get all treasure hunts qrcodes",
	ResetGamificationState = "[Gamification Service] Reset gamification state"
}

export const GetAllTreasureHunts = createAction(
	GamificationTypes.GetAllTreasureHunts,
	props<{ payload: ITreasureHunt[]; eventId: string }>()
);

export const GetAllTreasureHuntsQrcodes = createAction(
	GamificationTypes.GetAllTreasureHuntsQrcodes,
	props<{ payload: ITreasureHuntQrcode[]; eventId: string }>()
);

export const ResetGamificationState = createAction(GamificationTypes.ResetGamificationState, props<any>());
