import { createReducer, on } from "@ngrx/store";
import * as EventUsersActions from "../actions/event-users.actions";
import { IEventUsersState } from "../interfaces/event-users.interfaces";

export const initialState: IEventUsersState = {
	eventId: "",
	eventUsers: []
};

export const eventUsersFeatureKey = "eventUsers";

export const eventUsersReducers = createReducer(
	initialState,
	on(EventUsersActions.GetAllEventUsers, (state, action) => ({
		...state,
		eventId: action.eventId,
		eventUsers: action.payload
	}))
);
