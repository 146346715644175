import { createReducer, on } from "@ngrx/store";
import * as GamificationActions from "../actions/gamification.actions";
import { IGamificationState } from "../interfaces/states.interfaces";

export const initialState: IGamificationState = {
	treasureHunts: {
		eventId: "",
		treasureHunts: []
	},
	treasureHuntsQrcodes: {
		eventId: "",
		treasureHuntsQrcodes: []
	}
};

export const gamificationFeatureKey = "gamification";

export const gamificationReducers = createReducer(
	initialState,
	on(GamificationActions.GetAllTreasureHunts, (state, action) => ({
		...state,
		treasureHunts: {
			eventId: action.eventId,
			treasureHunts: action.payload
		}
	})),
	on(GamificationActions.GetAllTreasureHuntsQrcodes, (state, action) => ({
		...state,
		treasureHuntsQrcodes: {
			eventId: action.eventId,
			treasureHuntsQrcodes: action.payload
		}
	})),
	on(GamificationActions.ResetGamificationState, () => ({
		...initialState
	}))
);
