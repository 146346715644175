<!-- Blank (Required) Error field -->
<div
	*ngIf="
		computedCustomField.moduleSettings.required &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		).errors?.required &&
		(form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		).touched ||
			form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			).dirty)
	"
	class="form-bloc-error"
>
	<p class="error-label-text">
		{{ "edit-profil.errors.blank.simple_text" | translate }}
	</p>
</div>

<!-- Invalids format -->
<!-- (--minimum length error --) -->
<div
	*ngIf="
		computedCustomField.baseSettings.type !== 1 &&
		computedCustomField.baseSettings.type !== 2 &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		).errors?.minlength
	"
	class="form-bloc-error"
>
	<p class="error-label-text">
		{{ "edit-profil.errors.invalid.min_length" | translate }}&nbsp;
		{{ 1 }}
		{{ "edit-profil.errors.characters" | translate }}&nbsp;
	</p>
</div>

<!-- (--maximum length error x--) -->
<div
	*ngIf="
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		).errors?.maxlength
	"
	class="form-bloc-error"
>
	<p class="error-label-text">{{ "edit-profil.errors.invalid.multiple_text-too-long" | translate }} (100)</p>
</div>

<!-- Numeric error -->
<div
	*ngIf="
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		).errors?.max
	"
	class="form-bloc-error"
>
	<p class="error-label-text">
		{{ "edit-profil.errors.invalid.big_number" | translate }}
	</p>
</div>

<!-- Pattern error -->
<div
	*ngIf="
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		) &&
		form.get(
			mode === 'singleLanguage'
				? computedCustomField.baseSettings.uid
				: computedCustomField.baseSettings.uid + '_' + language
		).errors?.pattern
	"
	class="form-bloc-error"
>
	<!-- URL -->
	<p *ngIf="computedCustomField.baseSettings.type === typeCustomFields.URL" class="error-label-text">
		{{ "edit-profil.errors.invalid.url" | translate }}
	</p>
	<!-- EMAIL -->
	<p *ngIf="computedCustomField.baseSettings.type === typeCustomFields.EMAIL" class="error-label-text">
		{{ "edit-profil.errors.invalid.email" | translate }}
	</p>
	<!-- Date -->
	<p *ngIf="computedCustomField.baseSettings.type === typeCustomFields.DATE" class="error-label-text">
		{{ "edit-profil.errors.invalid.date" | translate }}
	</p>
</div>

<!-- Phone number error -->
<div *ngIf="computedCustomField.baseSettings.type === typeCustomFields.PHONE" class="form-bloc-error">
	<p
		*ngIf="
			form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			) &&
			form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			).errors?.required &&
			(form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			).touched ||
				form.get(
					mode === 'singleLanguage'
						? computedCustomField.baseSettings.uid
						: computedCustomField.baseSettings.uid + '_' + language
				).dirty)
		"
		class="error-label-text"
	>
		{{ "edit-profil.errors.blank.required" | translate }}
	</p>
	<p
		*ngIf="
			form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			) &&
			!form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			).errors?.required &&
			form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			).errors?.pattern &&
			(form.get(
				mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.uid + '_' + language
			).touched ||
				form.get(
					mode === 'singleLanguage'
						? computedCustomField.baseSettings.uid
						: computedCustomField.baseSettings.uid + '_' + language
				).dirty)
		"
		class="error-label-text"
	>
		{{ "edit-profil.errors.invalid.phone_number" | translate }}
	</p>
</div>

<!-- Custom Validators -->

<!-- Multi-text -->
<div
	*ngIf="
		computedCustomField.baseSettings.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(computedCustomField.baseSettings.uid))) as errorType
	"
	class="form-bloc-error"
>
	<p
		*ngIf="
			errorType === 'multiTextIsRequired' ||
			errorType === 'controlShouldNotContainValue' ||
			errorType === 'multiTextShouldContainValue'
		"
		class="error-label-text"
	>
		{{ form.get(computedCustomField.baseSettings.uid)?.errors?.[errorType]?.['msg'] | translate }}
	</p>
</div>

<!-- Min/max dateValidators -->
<div
	*ngIf="
		computedCustomField.baseSettings.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(computedCustomField.baseSettings.uid))) as errorType
	"
	class="form-bloc-error"
>
	<p *ngIf="errorType === 'dateMaximum'" class="error-label-text" style="font-size: 0.8rem; width: 100%">
		{{ form.get(computedCustomField.baseSettings.uid)?.errors?.[errorType]?.['msg'] | translate:{maxDate: SLuxon.convertIsoDateToFormat(computedCustomField.baseSettings?.dateRule?.maxDayDateIsEnabled ? dateTime.now().toISO() :  computedCustomField.baseSettings?.dateRule?.maxDate, language) } }}
	</p>

	<p *ngIf="errorType === 'dateMinimum'" class="error-label-text" style="font-size: 0.8rem; width: 100%">
		{{ form.get(computedCustomField.baseSettings.uid)?.errors?.[errorType]?.['msg'] | translate:{minDate: SLuxon.convertIsoDateToFormat(computedCustomField.baseSettings?.dateRule?.minDayDateIsEnabled ? dateTime.now().toISO() :  computedCustomField.baseSettings?.dateRule?.minDate, language)} }}
	</p>
</div>
