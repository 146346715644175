import { Pipe, PipeTransform } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";

// custom pipe to display safe html code and style property correctly

@Pipe({
	name: "safeHtml"
})
export class SafeHtmlPipe implements PipeTransform {
	constructor(private sanitizer: DomSanitizer) {}
	transform(value: any, type?: string) {
		switch (type) {
			case "html":
				return this.sanitizer.bypassSecurityTrustHtml(value);
			case "style":
				return this.sanitizer.bypassSecurityTrustStyle(value);
			case "script":
				return this.sanitizer.bypassSecurityTrustScript(value);
			case "url":
				return this.sanitizer.bypassSecurityTrustUrl(value);
			case "resourceUrl":
				return this.sanitizer.bypassSecurityTrustResourceUrl(value);
			default:
				return this.sanitizer.bypassSecurityTrustHtml(value);
		}
	}
}
