import { createAction, props } from "@ngrx/store";
import { IEvent, IEventsState } from "../interfaces/events.interfaces";

export enum EventsActionsTypes {
	GetEventsState = "[Events Service] Get events state",
	GetAllEvents = "[Events Service] Get all events",
	GetEvent = "[Events Service] Get event",
	GetMyEvents = "[Events Service] Get my events",
	ResetEventsState = "[Events Service] Reset events state"
}

export const GetEventsState = createAction(EventsActionsTypes.GetEventsState, props<{ payload: IEventsState }>());
export const GetAllEvents = createAction(EventsActionsTypes.GetAllEvents, props<{ payload: IEvent[] }>());
export const GetEvent = createAction(EventsActionsTypes.GetEvent, props<{ payload: IEvent }>());
export const GetMyEvents = createAction(EventsActionsTypes.GetMyEvents, props<{ payload: IEvent[] }>());
export const ResetEventsState = createAction(EventsActionsTypes.ResetEventsState, props<any>());
