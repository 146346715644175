export enum TypeLogs {
	TINY_LOAD = 1000
}
export const logsTypeDatas = [
	{
		labelName: "tiny.tiny-title",
		values: [
			{
				name: "logs.loads-of-tiny-editor",
				value: TypeLogs.TINY_LOAD
			}
		]
	}
];
