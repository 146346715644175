<ion-content *ngIf="event && module && eventUser" class="page">
	<div *ngIf="event" style="position: relative" class="background">
		<!-- <div *ngIf="module && folder" class="m-t-5"> -->
		<div *ngIf="module">
			<!-- <ion-searchbar [style.--background]="'rgb(234, 239, 242)'" [placeholder]="'texts.search_plc' | translate" (>
      </ion-searchbar> -->

			<div *ngIf="showTitle" [style.color]="event.styling.titleColor" class="title-documents">
				<ion-icon src="assets/icon/Clip.svg"></ion-icon>
				<span>{{ "documents.default_title" | translate }}</span>
			</div>
			<ion-list *ngIf="folders.length > 0">
				<ion-item
					*ngFor="let fol of folders"
					detail="false"
					lines="full"
					class="align-flex clickable"
					(click)="openFolder(fol)"
				>
					<i class="material-icons menu-icon" *ngIf="fol.iconFamily === 'material-icons'">
						{{ fol.icon }}
					</i>

					<span *ngIf="fol.iconFamily === 'custom-icon'">
						<img [src]="fol.icon" class="menu-icon-img" />
					</span>

					<span
						*ngIf="fol.iconFamily !== 'custom-icon' && fol.iconFamily !== 'material-icons'"
						class="b3-icons {{ fol.iconFamily }} menu-icon"
					></span>
					<ion-label [style.color]="event.styling.titleColor" style="margin-left: 10px">
						<h2 style="font-weight: 700">{{ fol?.name[currentLanguage] }}</h2>
						<!-- <p style="font-size: 0.6rem">{{ 'labels' + typeModuleString[fol.moduleLinkedType] | translate }}</p> -->
					</ion-label>
				</ion-item>
			</ion-list>

			<div *ngIf="folders.length === 0" class="container">
				<ion-icon
					[src]="'assets/images/emptycontent/AUCUNRESULTAT.svg'"
					[style.color]="
						event.styling.menuColor.includes('linear')
							? event.styling.menuColor.split(',')[1]
							: event.styling.menuColor
					"
				>
				</ion-icon>
				<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
					<span class="title"> {{ "texts.no_result_title" | translate }}</span>
					<span>{{ "texts.no_result_text" | translate }}</span>
				</div>
			</div>
		</div>
	</div>
</ion-content>

<app-login-register-buttons *ngIf="event && module && !eventUser" [event]="event"></app-login-register-buttons>
