import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Share } from "@capacitor/share";
import { AlertController, LoadingController, ModalController, ToastController } from "@ionic/angular";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { NgxImageCompressService } from "ngx-image-compress";
import { Subject } from "rxjs";
import { environment } from "src/environments/environment";
import { IEvent, IEventUser, IFullCustomField, ILanguage } from "../interfaces";
import { PathComponents } from "../paths/path-components";
import { FirestoreService } from "./firestore.service";
import { Network } from "@capacitor/network";
import { Store } from "@ngrx/store";
import { NetworkStatus } from "../actions/utility.actions";
import { IFilters } from "../interfaces/filters.interfaces";
import { TypeCustomFields } from "../enums/type-custom-fields";
import * as _ from "lodash-es";
import { v4 as uuidv4 } from "uuid";

@Injectable({
	providedIn: "root"
})
export class UtilityService {
	goBackSubject: Subject<boolean> = new Subject();
	headerMenuVisibility: Subject<boolean> = new Subject();

	constructor(
		private toastCtrl: ToastController,
		private STranslate: TranslateService,
		private alertCtrl: AlertController,
		private loadingCtrl: LoadingController,
		private modalCtrl: ModalController,
		private snackbar: MatSnackBar,
		private imageCompress: NgxImageCompressService,
		private SFirestore: FirestoreService,
		private store: Store
	) {}

	getAppSettings() {
		return this.SFirestore.valueChangesDocument("app-settings/settings");
	}

	listenToNetworkStatus() {
		Network.getStatus().then((status) => {
			this.store.dispatch(NetworkStatus({ payload: status.connected }));
		});
		// Network status & event user presence status
		Network.addListener("networkStatusChange", (status) => {
			this.store.dispatch(NetworkStatus({ payload: status.connected }));
		});
	}

	/**
	 * Present a toast
	 * @param message
	 * @param duration
	 * @param position
	 * @param closeButton
	 * @param color
	 */
	async presentToast(message: string, duration: number, position: "top" | "bottom" | "middle", color?: string) {
		const toast = await this.toastCtrl.create({
			message: message,
			duration: duration,
			position: position,
			color: color ? color : "",
			cssClass: "toast-message"
		});

		toast.present();
	}

	/**
	 * Present an alert
	 * @param title
	 * @param message
	 * @param buttons
	 * @param inputs
	 */
	async presentAlert(title: string, message: string, buttons = [], inputs = [], backdropDismiss?: boolean) {
		const alert = await this.alertCtrl.create({
			header: title,
			message: message,
			buttons: buttons,
			inputs: inputs,
			backdropDismiss: backdropDismiss ? backdropDismiss : true
		});

		return alert;
	}

	/**
	 * Present loading
	 * @param message
	 * @param duration
	 * @param spinner
	 */
	async presentLoading(
		message: string,
		duration: number,
		spinner: "bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small"
	) {
		const loader = await this.loadingCtrl.create({
			message: message,
			duration: duration,
			spinner: spinner,
			cssClass: "spinner-css-class"
		});

		return loader;
	}

	/**
	 * Convert language
	 * @param language
	 */
	convertLanguage(language: string) {
		switch (language) {
			case "ar_AR":
				return "ArAR";
			case "de_DE":
				return "DeDE";
			case "en_US":
				return "EnUS";
			case "es_ES":
				return "EsES";
			case "fr_FR":
				return "FrFR";
			case "pt_BR":
				return "PtBR";
			default:
				return environment.platform.defaultLanguage;
		}
	}

	/**
	 * Get locale
	 * @param language
	 * @returns
	 */
	getLocale(language: string) {
		return language.slice(0, 2).toLowerCase() + "-" + language.slice(2, 4).toUpperCase();
	}

	getDateWithTimezoneType(event: IEvent, type: string, date: string) {
		return !type || type === "event" ? DateTime.fromISO(date).setZone(event.timezone) : DateTime.fromISO(date);
	}

	configureDateTime(event: IEvent, eventUser: IEventUser, date: DateTime) {
		date = date.setLocale(
			this.STranslate.currentLang
				? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
						"-" +
						this.STranslate.currentLang.slice(2, 4).toUpperCase()
				: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
		);

		if (
			event &&
			event.timezoneType === "local" &&
			eventUser &&
			eventUser.updatedSettings &&
			eventUser.updatedSettings.timezoneType === "event"
		) {
			date = date.setZone(event.timezone);
		}
		return date;
	}

	getDateOnConfigTimezone(event: IEvent, eventUser: IEventUser, date: string) {
		let newDate: DateTime;
		if (
			(event.timezoneType === "local" &&
				(!eventUser || (eventUser && !eventUser.updatedSettings.timezoneType))) ||
			(event.timezoneType === "local" && eventUser && eventUser.updatedSettings.timezoneType === "local")
		) {
			newDate = DateTime.fromISO(date).setLocale(
				this.STranslate.currentLang
					? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
							"-" +
							this.STranslate.currentLang.slice(2, 4).toUpperCase()
					: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
			);
		} else if (event.timezoneType === "local" && eventUser && eventUser.updatedSettings.timezoneType === "event") {
			newDate = DateTime.fromISO(date)
				.setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
				)
				.setZone(event.timezone);
		} else if (event.timezoneType === "date") {
			newDate = DateTime.fromISO(date && date.split("+").length > 0 ? date.split("+")[0] : date).setLocale(
				this.STranslate.currentLang
					? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
							"-" +
							this.STranslate.currentLang.slice(2, 4).toUpperCase()
					: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
			);
		}
		return newDate;
	}

	formatDate(event: IEvent, eventUser: IEventUser, date: string, unit: string) {
		return this.getDateWithTimezoneType(event, eventUser.updatedSettings.timezoneType, date)
			.setLocale(
				this.STranslate.currentLang
					? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
							"-" +
							this.STranslate.currentLang.slice(2, 4).toUpperCase()
					: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
			)
			.toFormat(
				unit === "short" &&
					(this.STranslate.currentLang === "DeDE" ||
						this.STranslate.currentLang === "FrFR" ||
						this.STranslate.currentLang === "PtBR" ||
						this.STranslate.currentLang === "EsES" ||
						this.STranslate.currentLang === "ArAR")
					? "dd/MM/yyyy"
					: unit === "short" && this.STranslate.currentLang === "EnUS"
					? "MM/dd/yyyy"
					: unit === "full"
					? "EEEE, d MMMM y"
					: unit === "day"
					? "dd"
					: unit === "month"
					? "MMM"
					: unit === "time" &&
					  (this.STranslate.currentLang === "DeDE" ||
							this.STranslate.currentLang === "FrFR" ||
							this.STranslate.currentLang === "PtBR" ||
							this.STranslate.currentLang === "EsES" ||
							this.STranslate.currentLang === "ArAR")
					? "HH:mm"
					: "hh:mm a"
			);
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(event: IEvent, eventUser: IEventUser, date: string, unit: string) {
		let dateString = "";
		if (
			(event.timezoneType === "local" &&
				(!eventUser || (eventUser && !eventUser.updatedSettings.timezoneType))) ||
			(event.timezoneType === "local" && eventUser && eventUser.updatedSettings.timezoneType === "local")
		) {
			dateString = DateTime.fromISO(date)
				.setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
				)
				.toFormat(
					unit === "short" &&
						(this.STranslate.currentLang === "DeDE" ||
							this.STranslate.currentLang === "FrFR" ||
							this.STranslate.currentLang === "PtBR" ||
							this.STranslate.currentLang === "EsES" ||
							this.STranslate.currentLang === "ArAR")
						? "dd/MM/yyyy"
						: unit === "short" && this.STranslate.currentLang === "EnUS"
						? "MM/dd/yyyy"
						: unit === "full"
						? "EEEE, d MMMM y"
						: unit === "day"
						? "dd"
						: unit === "month"
						? "MMM"
						: unit === "time" &&
						  (this.STranslate.currentLang === "DeDE" ||
								this.STranslate.currentLang === "FrFR" ||
								this.STranslate.currentLang === "PtBR" ||
								this.STranslate.currentLang === "EsES" ||
								this.STranslate.currentLang === "ArAR")
						? "HH:mm"
						: "hh:mm a"
				);
		} else if (event.timezoneType === "local" && eventUser && eventUser.updatedSettings.timezoneType === "event") {
			dateString = DateTime.fromISO(date)
				.setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
				)
				.setZone(event.timezone)
				.toFormat(
					unit === "short" &&
						(this.STranslate.currentLang === "DeDE" ||
							this.STranslate.currentLang === "FrFR" ||
							this.STranslate.currentLang === "PtBR" ||
							this.STranslate.currentLang === "EsES" ||
							this.STranslate.currentLang === "ArAR")
						? "dd/MM/yyyy"
						: unit === "short" && this.STranslate.currentLang === "EnUS"
						? "MM/dd/yyyy"
						: unit === "full"
						? "EEEE, d MMMM y"
						: unit === "day"
						? "dd"
						: unit === "month"
						? "MMM"
						: unit === "time" &&
						  (this.STranslate.currentLang === "DeDE" ||
								this.STranslate.currentLang === "FrFR" ||
								this.STranslate.currentLang === "PtBR" ||
								this.STranslate.currentLang === "EsES" ||
								this.STranslate.currentLang === "ArAR")
						? "HH:mm"
						: "hh:mm a"
				);
		} else if (event.timezoneType === "date") {
			dateString = DateTime.fromISO(date ? date.slice(0, 23) : date)
				.setLocale(
					this.STranslate.currentLang
						? this.STranslate.currentLang.slice(0, 2).toLowerCase() +
								"-" +
								this.STranslate.currentLang.slice(2, 4).toUpperCase()
						: event.language.slice(0, 2).toLowerCase() + "-" + event.language.slice(2, 4).toUpperCase()
				)
				.toFormat(
					unit === "short" &&
						(this.STranslate.currentLang === "DeDE" ||
							this.STranslate.currentLang === "FrFR" ||
							this.STranslate.currentLang === "PtBR" ||
							this.STranslate.currentLang === "EsES" ||
							this.STranslate.currentLang === "ArAR")
						? "dd/MM/yyyy"
						: unit === "short" && this.STranslate.currentLang === "EnUS"
						? "MM/dd/yyyy"
						: unit === "full"
						? "EEEE, d MMMM y"
						: unit === "day"
						? "dd"
						: unit === "month"
						? "MMM"
						: unit === "time" &&
						  (this.STranslate.currentLang === "DeDE" ||
								this.STranslate.currentLang === "FrFR" ||
								this.STranslate.currentLang === "PtBR" ||
								this.STranslate.currentLang === "EsES" ||
								this.STranslate.currentLang === "ArAR")
						? "HH:mm"
						: "hh:mm a"
				);
		} else {
			dateString = this.STranslate.instant("schedule_detail.no_date");
		}
		return dateString;
	}

	/**
	 * getDateFormat
	 * @param date
	 * @param language
	 * @returns
	 */
	getDateFormat(date: string, language: string) {
		if (!date) return "";
		const format =
			language === "DeDE" ||
			language === "FrFR" ||
			language === "PtBR" ||
			language === "EsES" ||
			language === "ArAR"
				? "dd/MM/yyyy"
				: "MM/dd/yyyy";

		return DateTime.fromISO(date).toFormat(format);
	}

	/**
	 * alertLoginRegister
	 * @param alertTitle
	 * @param message
	 */
	async alertLoginRegister(eventId: string, alertTitle: string, message?: string, isLoggedIn?: boolean) {
		const alert = await this.alertCtrl.create({
			header: alertTitle,
			message: message ? message : this.STranslate.instant("texts.need_login_to_use"),
			buttons: !isLoggedIn
				? [
						{
							text: this.STranslate.instant("buttons.authenticate"),
							handler: async () => this.openLogin(eventId)
						},
						{
							text: this.STranslate.instant("buttons.cancel"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ]
				: [
						{
							text: this.STranslate.instant("buttons.ok"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ],
			backdropDismiss: true,
			cssClass: "center-alert-items"
		});

		alert.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin(eventId: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.loginModal,
				componentProps: {
					eventId: eventId
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister(eventId);
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister(eventId: string) {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.registerForm,
				componentProps: {
					eventId: eventId
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openLogin) {
				this.openLogin(eventId);
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	removeAccents(word: string): string {
		let copyWord: string = word.slice();
		const map = [
			["[àáâãäå]", "a"],
			["æ", "ae"],
			["ç", "c"],
			["[èéêë]", "e"],
			["[ìíîï]", "i"],
			["ñ", "n"],
			["[òóôõö]", "o"],
			["œ", "oe"],
			["[ùúûü]", "u"],
			["[ýÿ]", "y"]
		];

		for (let i = 0; i < map.length; ++i) {
			copyWord = copyWord.replace(new RegExp(map[i][0], "gi"), function (match) {
				if (match.toLowerCase() === match) {
					return map[i][1].toLowerCase();
				} else {
					return map[i][1];
				}
			});
		}

		return copyWord;
	}

	shareImage(url: any) {
		Share.share({
			url: url
		});
	}

	shareText(title: string, text: string, dialogTitle: string) {
		return Share.share({
			title: title,
			text: text,
			dialogTitle: dialogTitle
		});
	}

	/**
	 * compressImage
	 * @param image
	 * @param ratio
	 * @param quality
	 * @returns
	 */
	compressImage(image: string, ratio: number, quality: number): Promise<string> {
		return this.imageCompress.compressFile(image, null, ratio, quality);
	}

	/**
	 * getDatePipeData
	 * @param eventLanguage
	 * @param formatType
	 * @returns
	 */
	getDatePipeData(eventLanguage: string, formatType: string): string[] {
		if (!eventLanguage || !formatType) return ["dd/MM/yyyy", "", ""];
		return [
			eventLanguage === "DeDE" ||
			eventLanguage === "FrFR" ||
			eventLanguage === "PtBR" ||
			eventLanguage === "EsES" ||
			eventLanguage === "ArAR"
				? formatType === "short"
					? "dd/MM/yyyy"
					: "EEEE, d MMMM y"
				: formatType === "short"
				? "MM/dd/yyyy"
				: "EEEE, MMMM d y",
			"",
			eventLanguage
				.replace(/(\w{2})(\w{2})/, "$1")
				.toLowerCase()
				.concat(`-${eventLanguage.replace(/(\w{2})(\w{2})/, "$2")}`)
		];
	}

	/**
	 * getDatePipeData
	 * @param eventLanguage
	 * @param formatType
	 * @returns
	 */
	getDateTimePipeData(eventLanguage: string): string[] {
		if (!eventLanguage) return ["HH:mm", "", "fr-FR"];
		return [
			eventLanguage === "DeDE" ||
			eventLanguage === "FrFR" ||
			eventLanguage === "PtBR" ||
			eventLanguage === "EsES" ||
			eventLanguage === "ArAR"
				? "HH:mm"
				: "hh:mm a",
			"",
			eventLanguage
				.replace(/(\w{2})(\w{2})/, "$1")
				.toLowerCase()
				.concat(`-${eventLanguage.replace(/(\w{2})(\w{2})/, "$2")}`)
		];
	}

	// replace a char at a specific index by its lowercase
	replaceCharAt(str: string, index: number, replacement: string) {
		return str.substr(0, index) + replacement + str.substr(index + replacement.length);
	}

	callGoBackOnHeader() {
		this.goBackSubject.next(true);
	}

	hideMenuAndHeader() {
		this.headerMenuVisibility.next(false);
	}

	showMenuAndHeader() {
		this.headerMenuVisibility.next(true);
	}

	computeReachableDatas(
		event: IEvent,
		allDatas: any[],
		filteredDatas: any[],
		computedCustomFields: IFullCustomField[],
		filters: IFilters
	) {
		const notSelectedDatas: IFilters = {
			locations: [],
			tracks: [],
			customFields: [],
			groups: [],
			principalKey: ""
		};

		notSelectedDatas.locations = filters.locations.filter(() =>
			filters.locations.every((location) => !location.checked)
		);
		notSelectedDatas.groups = filters.groups.filter(() => filters.groups.every((group) => !group.checked));
		notSelectedDatas.tracks = filters.tracks.filter(() => filters.tracks.every((track) => !track.checked));
		notSelectedDatas.customFields = filters.customFields.filter(
			(filter) => !filter.values.find((value) => value.isSelected)
		);

		filters.locations = filters.locations.map((filter) => {
			const total =
				filters.principalKey === "locations"
					? allDatas.filter((data) => {
							if (data["locations"]) {
								if (data["locations"].includes(filter.uid)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
					  }).length
					: filteredDatas.filter((data) => {
							if (data["locations"]) {
								if (data["locations"].includes(filter.uid)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
					  }).length;

			filter.totalDatas = total;
			return filter;
		});

		filters.groups = filters.groups.map((filter) => {
			const total =
				filters.principalKey === "groups"
					? allDatas.filter((data) => {
							if (data["groups"]) {
								if (data["groups"].includes(filter.uid)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
					  }).length
					: filteredDatas.filter((data) => {
							if (data["groups"]) {
								if (data["groups"].includes(filter.uid)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
					  }).length;

			filter.totalDatas = total;
			return filter;
		});

		filters.tracks = filters.tracks.map((filter) => {
			const total =
				filters.principalKey === "tracks"
					? allDatas.filter((data) => {
							if (data["tracks"]) {
								if (data["tracks"].includes(filter.uid)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
					  }).length
					: filteredDatas.filter((data) => {
							if (data["tracks"]) {
								if (data["tracks"].includes(filter.uid)) {
									return true;
								} else {
									return false;
								}
							} else {
								return false;
							}
					  }).length;

			filter.totalDatas = total;
			return filter;
		});

		filters.customFields = filters.customFields.map((filter) => {
			const customField = computedCustomFields.find((computed) => computed.baseSettings.uid === filter.uid);
			if (customField) {
				filter.values = filter.values.map((value) => {
					const principalKeySplit = filters.principalKey.split("|");
					const total =
						principalKeySplit[0] === "customFields" && principalKeySplit[1] === value.filterId
							? allDatas.filter((data) => {
									const custom = data.customFields.find((cus) => {
										return (
											cus &&
											cus.uid === filter.uid &&
											(((this.getCustomFieldType(computedCustomFields, cus.uid) ===
												TypeCustomFields.TEXT ||
												this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.SELECT) &&
												value.value.toString().toLowerCase() ===
													cus.field.multiLanguageText[event.language]
														.toString()
														.toLowerCase()) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageText &&
													cus.field.multiLanguageText[event.language] &&
													cus.field.multiLanguageText[event.language] === "") ||
												((this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.URL ||
													this.getCustomFieldType(computedCustomFields, cus.uid) ===
														TypeCustomFields.EMAIL) &&
													value.value.toString().toLowerCase() ===
														cus.field.text.toLowerCase()) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.text === "") ||
												(this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.NUMERIC &&
													value.value.toString() === cus.field.numeric.toString()) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.numeric === -1) ||
												(this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.MULTI_SELECT &&
													cus.field.multiLanguageSelectArray
														.map((opt) => opt[event.language].toString().toLowerCase())
														.includes(value.value.toString().toLowerCase())) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field &&
													cus.field.multiLanguageSelectArray &&
													cus.field.multiLanguageSelectArray.map((opt) => opt[event.language])
														.length === 0))
										);
									});

									return custom ? true : false;
							  }).length
							: filteredDatas.filter((data) => {
									const custom = data.customFields.find(
										(cus) =>
											cus &&
											cus.field &&
											cus.uid === filter.uid &&
											(((this.getCustomFieldType(computedCustomFields, cus.uid) ===
												TypeCustomFields.TEXT ||
												this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.SELECT) &&
												value.value.toString().toLowerCase() ===
													cus.field.multiLanguageText[event.language]
														.toString()
														.toLowerCase()) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.multiLanguageText &&
													cus.field.multiLanguageText[event.language] &&
													cus.field.multiLanguageText[event.language] === "") ||
												((this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.URL ||
													this.getCustomFieldType(computedCustomFields, cus.uid) ===
														TypeCustomFields.EMAIL) &&
													value.value.toString().toLowerCase() ===
														cus.field.text.toLowerCase()) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.text === "") ||
												(this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.NUMERIC &&
													value.value.toString() === cus.field.numeric.toString()) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field.numeric === -1) ||
												(this.getCustomFieldType(computedCustomFields, cus.uid) ===
													TypeCustomFields.MULTI_SELECT &&
													cus.field.multiLanguageSelectArray
														.map((opt) => opt[event.language].toString().toLowerCase())
														.includes(value.value.toString().toLowerCase())) ||
												(value.value.toString().toLowerCase() ===
													this.STranslate.instant("filter.not_specified").toLowerCase() &&
													cus.field &&
													cus.field.multiLanguageSelectArray &&
													cus.field.multiLanguageSelectArray.map((opt) => opt[event.language])
														.length === 0))
									);

									return custom ? true : false;
							  }).length;

					value.totalDatas = total;
					return value;
				});
			} else {
				filter.values = filter.values.map((value) => {
					value.totalDatas = 0;
					return value;
				});
			}
			return filter;
		});

		return { notSelectedDatas: notSelectedDatas, filters: filters };
	}

	/**
	 * computeReachableDatas
	 * @description Calculates reachables options (checkboxs) based on the selected items
	 *
	 */
	computeReachableDatas2(
		event: IEvent,
		allDatas: any[],
		computedCustomFields: IFullCustomField[],
		filters: IFilters
	) {
		const notSelectedDatas: IFilters = {
			locations: [],
			tracks: [],
			customFields: [],
			groups: [],
			principalKey: ""
		};

		const filtersEmpty =
			filters.groups.some((filter) => filter.checked) ||
			filters.locations.some((filter) => filter.checked) ||
			filters.tracks.some((filter) => filter.checked) ||
			filters.customFields.some((filter) => filter.values.some((value) => value.isSelected))
				? false
				: true;

		if (filtersEmpty) {
			// Back to initial state (every checkbox are reachable)

			// Locations fields
			filters.locations.forEach((location) => (location.isReachable = true));

			// Tracks fields
			filters.tracks.forEach((track) => (track.isReachable = true));

			// Custom fields
			filters.customFields.forEach((item) => {
				item.values.forEach((data) => {
					item.strictSelectionMode &&
					data.value.toString().toLowerCase() ===
						this.STranslate.instant("filter.not_specified").toLowerCase()
						? (data.isReachable = false)
						: (data.isReachable = true);
				});
			});

			return { notSelectedDatas: notSelectedDatas, filters: filters };
		}

		/********
		 * Steps :
		 *
		 * 1 => Get not selected filters / datas
		 *
		 * 2 => Check in sessions if there is matching possibility with selected datas
		 */
		notSelectedDatas.locations = filters.locations.filter(() =>
			filters.locations.every((location) => !location.checked)
		);
		notSelectedDatas.groups = filters.groups.filter(() => filters.groups.every((group) => !group.checked));
		notSelectedDatas.tracks = filters.tracks.filter(() => filters.tracks.every((track) => !track.checked));
		notSelectedDatas.customFields = filters.customFields.filter(
			(filter) => !filter.values.find((value) => value.isSelected)
		);

		/****** Locations *****/
		let locationMatches = 0;
		const locationDatas: string[] = [];
		notSelectedDatas.locations.forEach((filter) => {
			allDatas.forEach((data) => {
				if (data["locations"]) {
					// Checking sessions that include location present in filter
					const location = data.locations.includes(filter.uid) ? filter : null;
					if (location) {
						if (
							!locationDatas.find((data) => data.toLowerCase() === location.name.toString().toLowerCase())
						)
							locationDatas.push((location.name as string).toLowerCase());

						locationMatches++;
					}

					// Checking sessions with not specified location
					if (
						allDatas.some((session) => session.locations.length === 0) &&
						!locationDatas.find(
							(data) =>
								data.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
						)
					)
						locationDatas.push((this.STranslate.instant("filter.not_specified") as string).toLowerCase());
				}
			});
		});

		// Setting reachable allDatas
		if (locationMatches > 0) {
			notSelectedDatas.locations.filter((fil) => {
				if (
					locationDatas.findIndex((data) => data.toLowerCase() === fil.name.toString().toLowerCase()) === -1
				) {
					fil.isReachable = false;
					return true;
				} else {
					fil.isReachable = true;
					return false;
				}
			});
		}

		/****** Tracks *****/
		let trackMatches = 0;
		const trackDatas: string[] = [];
		notSelectedDatas.tracks.forEach((filter) => {
			allDatas.forEach((data) => {
				// Checking datas that include track present in filter
				const track = data.tracks.includes(filter.uid) ? filter : null;
				if (track) {
					if (!trackDatas.find((data) => data.toLowerCase() === track.name[event.language].toLowerCase()))
						trackDatas.push(track.name[event.language].toLowerCase());
					trackMatches++;
				}

				// Checking datas with not specified track
				if (
					allDatas.some((data) => data.tracks.length === 0) &&
					!trackDatas.find(
						(data) => data.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
					)
				)
					trackDatas.push((this.STranslate.instant("filter.not_specified") as string).toLowerCase());
			});
		});

		// Setting reachable allDatas
		if (trackMatches > 0) {
			notSelectedDatas.tracks.filter((fil) => {
				if (
					trackDatas.findIndex((data) => data.toLowerCase() === fil.name[event.language].toLowerCase()) === -1
				) {
					fil.isReachable = false;
					return true;
				} else {
					fil.isReachable = true;
					return false;
				}
			});
		}

		/***** Custom fields *****/
		notSelectedDatas.customFields.forEach((filter) => {
			let matches = 0;
			const datas: string[] = [];
			allDatas.forEach((data: any) => {
				const custom = data.customFields.find(
					(cus) =>
						cus.uid === filter.uid &&
						(((this.getCustomFieldType(computedCustomFields, cus.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(computedCustomFields, cus.uid) === TypeCustomFields.SELECT) &&
							filter.values.find(
								(value) =>
									value.value.toString().toLowerCase() ===
										cus.field.multiLanguageText[event.language].toString().toLowerCase() ||
									(value.value.toString().toLowerCase() ===
										this.STranslate.instant("filter.not_specified").toLowerCase() &&
										this.STranslate.instant("filter.not_specified").toLowerCase() &&
										cus.field.multiLanguageText[event.language] === "")
							)) ||
							((this.getCustomFieldType(computedCustomFields, cus.uid) === TypeCustomFields.URL ||
								this.getCustomFieldType(computedCustomFields, cus.uid) === TypeCustomFields.EMAIL) &&
								filter.values.find(
									(value) =>
										value.value.toString().toLowerCase() === cus.field.text.toLowerCase() ||
										(value.value.toString().toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											cus.field.text === "")
								)) ||
							(this.getCustomFieldType(computedCustomFields, cus.uid) === TypeCustomFields.NUMERIC &&
								filter.values.find(
									(value) =>
										value.value.toString() === cus.field.numeric.toString() ||
										(value.value.toString().toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											cus.field.numeric === -1)
								)) ||
							(this.getCustomFieldType(computedCustomFields, cus.uid) === TypeCustomFields.MULTI_SELECT &&
								filter.values.find(
									(value) =>
										cus.field.multiLanguageSelectArray
											.map((opt) => opt[event.language].toString().toLowerCase())
											.includes(value.value.toString().toLowerCase()) ||
										(value.value.toString().toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											cus.field.multiLanguageSelectArray.map((opt) => opt[event.language])
												.length === 0)
								)))
				);

				if (custom) {
					if (
						!datas.find(
							(data) =>
								((this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.TEXT ||
									this.getCustomFieldType(computedCustomFields, custom.uid) ===
										TypeCustomFields.SELECT) &&
									data.toString().toLowerCase() ===
										custom.field.multiLanguageText[event.language].toString().toLowerCase()) ||
								((this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.URL ||
									this.getCustomFieldType(computedCustomFields, custom.uid) ===
										TypeCustomFields.EMAIL) &&
									data.toString().toLowerCase() === custom.field.text.toLowerCase()) ||
								(this.getCustomFieldType(computedCustomFields, custom.uid) ===
									TypeCustomFields.NUMERIC &&
									data.toString() === custom.field.numeric.toString()) ||
								(this.getCustomFieldType(computedCustomFields, custom.uid) ===
									TypeCustomFields.MULTI_SELECT &&
									custom.field.multiLanguageSelectArray
										?.map((opt: ILanguage) => {
											return opt[event.language].toString().toLowerCase();
										})
										.some((dat: string) => dat.toString() === data.toString().toLowerCase()))
						)
					)
						this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.TEXT ||
						this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.SELECT
							? datas.push(custom.field.multiLanguageText[event.language])
							: this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.URL ||
							  this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.EMAIL
							? datas.push(custom.field.text)
							: this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.NUMERIC
							? datas.push(custom.field.numeric.toString())
							: custom.field.multiLanguageSelectArray.length > 0 &&
							  datas.push(
									custom.field.multiLanguageSelectArray
										?.map((opt: ILanguage) => {
											return opt[event.language].toString().toLowerCase();
										})
										.join("*")
							  );
					if (
						(((this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.SELECT) &&
							custom.field.multiLanguageText[event.language] === "") ||
							((this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.URL ||
								this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.EMAIL) &&
								custom.field.text === "") ||
							(this.getCustomFieldType(computedCustomFields, custom.uid) === TypeCustomFields.NUMERIC &&
								custom.field.numeric === -1) ||
							(this.getCustomFieldType(computedCustomFields, custom.uid) ===
								TypeCustomFields.MULTI_SELECT &&
								custom.field.multiLanguageSelectArray?.map((option) => option[event.language])
									.length === 0)) &&
						!datas.find(
							(data) =>
								data.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
						)
					)
						datas.push(this.STranslate.instant("filter.not_specified"));

					matches++;
				}
			});

			if (matches === datas.length) {
				// Flat arrays and remove duplicated items
				const splitedDatas = [
					...new Set(datas.map((dat) => dat.toString().split("*")).reduce((acc, val) => acc.concat(val), []))
				];

				filter.values
					.map((fil) => fil.value !== "-1" && fil)
					.filter((fil) => {
						if (
							splitedDatas
								.filter((dat) => dat)
								.findIndex(
									(dat) => dat?.toString().toLowerCase() === fil.value?.toString().toLowerCase()
								) === -1
						) {
							fil.isReachable = false;
							return true;
						} else {
							filter.strictSelectionMode &&
							fil.value.toString().toLowerCase() ===
								this.STranslate.instant("filter.not_specified").toLowerCase()
								? (fil.isReachable = false)
								: (fil.isReachable = true);
							return false;
						}
					});
			}
		});
		return { notSelectedDatas: notSelectedDatas, filters: filters };
	}

	/**
	 * getCustomFieldType
	 * @description return type of custom field based on the uid
	 * @param uid
	 * @return number
	 */
	getCustomFieldType(computedCustomFields: IFullCustomField[], uid: string): number {
		return computedCustomFields.find((computedCustomField) => computedCustomField.baseSettings.uid === uid)
			?.baseSettings.type;
	}

	generateUuid() {
		return uuidv4();
	}
}
