export enum TypeHeader {
	CARD_EXCHANGE = 120,
	CHECKINS = 110,
	FAVORITE_EVENT_USERS_FOLDERS = 102,
	FAVORITE_SCHEDULE_FOLDERS = 101,
	FAVORITES = 100,
	NOTE_TAKING = 90,
	EXTERNAL_INTERACTIVITY = 80,
	SESSION = 71,
	CHAT_DETAIL = 61,
	CHAT = 60,
	TREASURE_HUNTS = 33,
	TRACKS = 32,
	CUSTOM_FIELDS = 31,
	DISCUSSION_GROUP = 30,
	APPOINTMENTS = 29,
	EXTERNAL_LINK = 28,
	// GENERIC = 34, // generic modules
	MANAGER_MODULES = 27,
	LEGAL = 26,
	// ROOM_PAIR = 31, // module for attendee pair hotel
	// INFOBOOTH = 30,
	LOCATION = 25,
	WIDGETS = 24,
	ASK_QUESTION = 23,
	FEEDBACKS = 22,
	// NETWORKING = 25, // module for networking (chat, groups, channels, etc)
	SELF_CHECKIN = 21, // auto checkin for attendees
	MANAGER_GROUP = 20, // manager group
	GAMIFICATION = 19, // gaming module
	QUIZ = 18, // quiz interactivity module
	TRAINING = 17, // training interactivity module
	// SUBSCRIPTION = 19, // subscription interactivity module
	WORDCLOUD = 16, // word cloud interactivity module
	SURVEY = 15, // survey list interactivity module
	RANKINGS = 14,
	// PAGE_CUSTOM_PAGE = 15,
	CUSTOM_PAGE = 13,
	PERSONALSCHEDULE = 12,
	EVENT = 11,
	PROFILE = 10,
	HOME = 9, // What's that ?
	NEWS_FEED = 8,
	CHECKIN = 7,
	NOTIFICATION = 6,
	// MAPS = 6,
	GALLERY = 5,
	INTERACTIVITY = 4,
	DOCUMENT = 3,
	ATTENDEE = 2,
	SPEAKER = 1,
	SCHEDULE = 0
}
