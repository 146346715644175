import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRoute } from "@angular/router";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { AlertController, MenuController, ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, combineLatest, firstValueFrom, interval } from "rxjs";
import { take } from "rxjs/operators";
import { InitAuth, InitEvents, InitUser, Loading } from "src/app/shared/actions/utility.actions";
import { PasswordSecurity } from "src/app/shared/enums/password-security";
import { TypeLogin } from "src/app/shared/enums/type-login";
import { TypeUser } from "src/app/shared/enums/type-user";
import { IContainer, IEvent, IUser } from "src/app/shared/interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getContainersState } from "src/app/shared/selectors/containers.selectors";
import { AuthService, EventUsersService, EventsService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-login",
	templateUrl: "./login.page.html",
	styleUrls: ["./login.page.scss"]
})
export class LoginPage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	interval: Subscription;
	loginLogoSize: string = environment.platform.loginLogoClass;
	containerId: string = environment.platform.containerId;

	loader: boolean = true;
	activeLoader: boolean = false;

	container: IContainer;
	user: IUser;
	eventId: string;
	event: IEvent;
	codeNumber: string = "";

	count: number = 180;
	timeRemaining: string;

	passwordScreen: boolean = false;

	loginForm: UntypedFormGroup;

	emailView: boolean = false;
	createPasswordView: boolean = false;
	passwordView: boolean = false;
	firstAccessView: boolean = false;
	shortcodeView: boolean = false;

	showPwd: boolean = false;
	isMobile: boolean;
	emailCodeLanguage: string;
	appActive: boolean = true;
	networkStatus: boolean = true;

	constructor(
		private route: ActivatedRoute,
		private menu: MenuController,
		private modalCtrl: ModalController,
		private alertCtrl: AlertController,
		private navCtrl: NavController,
		private fb: UntypedFormBuilder,
		private store: Store,
		private SAuth: AuthService,
		private SEvents: EventsService,
		private STranslate: TranslateService,
		private SUtility: UtilityService,
		private snackbar: MatSnackBar,
		private elementRef: ElementRef,
		private platform: Platform,
		private SEventUsers: EventUsersService
	) {
		Network.addListener("networkStatusChange", (status) => {
			this.networkStatus = status.connected;
			if (!status.connected) {
				// snackbar with message "You are offline"
				this.snackbar.open(this.STranslate.instant("texts.network_offline"), "OK", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		});

		this.menu.enable(false);

		this.getDeviceLanguage();

		this.route.params.pipe(take(1)).subscribe((params) => {
			this.eventId = params.eventId ? params.eventId : "";
		});

		this.loginForm = this.fb.group({
			email: [
				"",
				[
					Validators.required,
					Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
					Validators.email
				]
			],
			password: ["", [Validators.required, Validators.minLength(6)]],
			confirmPassword: [""],
			codeNumber: [""],
			termCheck: [false]
		});

		this.subscriptions.push(
			this.loginForm.get("termCheck").valueChanges.subscribe((changes) => {
				if (changes && !this.loginForm.get("termCheck").disabled) {
					this.loginForm.get("termCheck").disable();
				}
			})
		);

		this.isMobile = this.platform.is("ios") || this.platform.is("android");
	}

	ngOnInit() {
		if (this.interval && !this.interval.closed) {
			this.interval.unsubscribe();
		}
		this.subscriptions.push(
			this.store.select(getContainersState).subscribe({
				next: (containersState) => {
					this.container =
						!environment.platform.containerId ||
						environment.platform.containerId === "defaultContainer" ||
						!containersState.currentContainer
							? containersState.defaultContainer
							: containersState.currentContainer;
					if (this.container) {
						if (
							this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM ||
							this.container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM
						) {
							this.changeView(true, false, false, false, false);
						} else if (this.container.loginSettings.type === TypeLogin.WITH_SHORTCODE) {
							this.changeView(false, false, false, false, true);
							if (!this.loginForm.get("shortcode")) {
								this.loginForm.addControl("shortcode", new UntypedFormControl(""));
							}
						}
					}
					this.loader = false;
					this.store.dispatch(Loading({ payload: false }));
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub?.unsubscribe());
		if (this.interval && !this.interval.closed) {
			this.interval.unsubscribe();
		}
	}

	/**
	 * First step verif email and send code if necessary
	 */
	async firstStepVerifEmail() {
		const data = this.loginForm.getRawValue();
		try {
			this.activeLoader = true;
			if (data.email) {
				const results: any[] = await firstValueFrom(
					combineLatest([
						this.SAuth.checkAuthAccountExist(data.email, "", "email"),
						this.SAuth.getUserByEmail(data.email.toLowerCase())
					])
				);
				const authAccountResult = results[0];
				this.user = results[1];
				if (
					authAccountResult &&
					authAccountResult.code === 200 &&
					this.user &&
					authAccountResult.result &&
					authAccountResult.result.uid === this.user.uid &&
					authAccountResult.result &&
					authAccountResult.result.email === this.user.email
				) {
					// If auth account and user data exist and auth account uid and email same than user uid and email all good for continuing login user
					// Disable email for not changing it
					this.loginForm.get("email").disable();
					// Change view to email view and password view
					this.changeView(true, false, true, false, false);
					this.activeLoader = false;
				} else if (
					this.user &&
					(!authAccountResult || authAccountResult.code === 204) &&
					this.user.type >= TypeUser.SPEAKER
				) {
					// If user exist but no auth account
					this.loginForm.get("email").disable();
					// Change view to first access view or create pass view
					if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
						this.changeView(false, false, false, true, false);
						this.createCodeNumber();
					} else {
						this.changeView(false, true, false, false, false);
					}
				} else {
					if (!this.networkStatus) {
						this.snackbar.open(this.STranslate.instant("snackbar.no_network"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
					} else {
						// User not found
						this.snackbar.open(this.STranslate.instant("login.email_not_found"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
					}
					this.activeLoader = false;
				}
			}

			this.activeLoader = false;
		} catch (error) {
			this.activeLoader = false;
		}
	}

	/**
	 * To create account, the user enter your password two times,
	 * check both is equal and add to Firebase Authentication and update in Firestore
	 */
	async createAccount() {
		const data = this.loginForm.getRawValue();
		try {
			this.activeLoader = true;
			if (data.password.length >= 6) {
				if (data.password === data.confirmPassword) {
					if (data.termCheck) {
						if (this.user) {
							await firstValueFrom(this.SAuth.createOnlyAuthAccount(this.user, data.password));
							this.login();
						} else {
							this.activeLoader = false;
							this.snackbar.open(this.STranslate.instant("snackbar.no_user"), "", {
								duration: 3000,
								panelClass: "error-snackbar"
							});
						}
					} else {
						this.activeLoader = false;
						this.snackbar.open(this.STranslate.instant("login.term_check_required"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
					}
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.password_not_match"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_create_account"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Login user
	 */
	login() {
		const data = this.loginForm.getRawValue();
		try {
			this.activeLoader = true;
			if (data.password) {
				if (data.termCheck) {
					this.SEvents.unsubscribeAll();
					this.store.dispatch(InitAuth({ payload: false }));
					this.store.dispatch(InitEvents({ payload: false }));
					this.store.dispatch(InitUser({ payload: false }));
					this.SAuth.loginUser(data.email.toLowerCase(), data.password).subscribe({
						next: (user) => {
							if (user && user.user && user.user.uid) {
								// if (this.user.type <= TypeUser.EMPLOYEE) {
								// 	this.SUtility.presentAlert(
								// 		this.STranslate.instant("alerts.login_client_title_error"),
								// 		this.STranslate.instant("alerts.login_client_error"),
								// 		["OK"],
								// 		[]
								// 	).then((alert) => {
								// 		alert.present();
								// 		this.SAuth.logoutUser();
								// 		this.activeLoader = false;
								// 		this.loginForm.get("email").enable();
								// 		this.loginForm.get("password").patchValue("");
								// 		this.loginForm.get("confirmPassword").patchValue("");
								// 		this.loginForm.get("codeNumber").patchValue("");
								// 		this.loginForm.get("termCheck").patchValue(false);

								// 		this.changeView(true, false, false, false, false);
								// 	});
								// } else {
								if (this.user) {
									this.user.connectedPlatformToken = this.SAuth.deviceId ? this.SAuth.deviceId : "";

									if (!this.user.firstAccess) {
										// Update first access if not true
										this.user.firstAccess = true;
									}
									this.SAuth.updateUser(this.user);
								}

								// If user exist and no error
								if (this.eventId) {
									this.store.dispatch(Loading({ payload: true }));
									// If event id in routing params, redirect to that event
									this.navCtrl.navigateRoot("/event/" + this.eventId);
									this.elementRef.nativeElement.remove();
								} else {
									this.store.dispatch(Loading({ payload: true }));
									// If no event id in routing params, redirect to list of events
									this.navCtrl.navigateRoot(
										"/events-list/" +
											(this.container.loginSettings.showAccessPublicEventsBtn
												? "all"
												: "my-events")
									);
									this.elementRef.nativeElement.remove();
								}

								if (this.event && this.user) {
									// Update last access date property
									this.SEventUsers.updateLastAccessOfEventForEventUser(this.event.uid, this.user.uid);
								}
								// }
							} else {
								this.activeLoader = false;
								this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							}
						},
						error: (error) => {
							this.activeLoader = false;
							if (error.code === "auth/wrong-password") {
								this.snackbar.open(this.STranslate.instant("login.incorrect_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code == "auth/too-many-requests") {
								this.snackbar.open(this.STranslate.instant("login.many_pass_errors"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code == "auth/argument-error") {
								this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code === "auth/network-request-failed") {
								this.snackbar.open(this.STranslate.instant("snackbar.no_network"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else {
								this.snackbar.open(this.STranslate.instant("snackbar.cannot_login"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							}
						}
					});
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.term_check_required"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_login"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Create code number
	 */
	async createCodeNumber() {
		try {
			const result = await firstValueFrom(this.SAuth.sendCodeNumber(this.user.uid, this.emailCodeLanguage));

			if (result.code === 200 && result.user && result.user.codeNumber) {
				// If sending code ok
				this.codeNumber = result.user.codeNumber;

				this.count = 180;
				this.interval = interval(1000).subscribe(() => {
					const date = new Date(null);
					date.setSeconds(this.count);
					this.timeRemaining = date.toISOString().substring(14, 19);
					this.count--;

					if (this.count === 0) {
						if (this.interval && !this.interval.closed) {
							this.interval.unsubscribe();
						}
						this.snackbar.open(this.STranslate.instant("login.resend_code_number"), "", {
							duration: 3000,
							panelClass: "warning-snackbar"
						});
					}
				});
				this.activeLoader = false;
			} else {
				this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				this.activeLoader = false;
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
			this.activeLoader = false;
		}
	}

	/**
	 * In case of first access, the user needs to verify your identity
	 * by code number sended to e-mail and create your account
	 */
	async verifyCodeNumber() {
		const data = this.loginForm.getRawValue();
		try {
			if (this.count > 0) {
				if (data.codeNumber) {
					this.activeLoader = true;
					const checkCode = await firstValueFrom(
						this.SAuth.verifyCodeNumber(data.codeNumber?.toString(), this.user.uid)
					);
					if (checkCode) {
						if (this.interval && !this.interval.closed) {
							this.interval.unsubscribe();
						}
						this.changeView(false, true, false, false, false);
						this.activeLoader = false;
					} else {
						this.snackbar.open(this.STranslate.instant("login.invalid_code"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
						this.activeLoader = false;
					}
				} else {
					this.snackbar.open(this.STranslate.instant("login.invalid_code"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.activeLoader = false;
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			//console.error("Error: ", error);
		}
	}

	/**
	 * Recover password
	 * @param email
	 */
	async recoveryPassword() {
		try {
			const email = this.loginForm.getRawValue().email;
			if (email) {
				this.activeLoader = true;
				await firstValueFrom(this.SAuth.recoveryPassword(email.toLowerCase()));
				this.presentAlertConfirm();
				this.activeLoader = false;
			}
		} catch (error) {
			console.error("🚀 ~ LoginPage ~ recoveryPassword ~ error:", error);
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("login.recovery_fail"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * recoveryPasswordFromSMTPFirebase
	 */
	async recoveryPasswordFromSMTPFirebase() {
		try {
			await this.SAuth.recoveryPasswordFromFirebase(this.loginForm.getRawValue().email);

			this.snackbar.open(this.STranslate.instant("login.recovery_success"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			console.error("🚀 ~ LoginPage ~ recoveryPasswordFromSMTPFirebase ~ error:", error);
			this.snackbar.open(this.STranslate.instant("login.recovery_fail"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	async presentAlertConfirm() {
		const alert = await this.alertCtrl.create({
			header: this.STranslate.instant("login.recovery_success"),
			message: this.STranslate.instant("login.recovery_text"),
			buttons: [
				{
					text: "Ok",
					handler: () => {
						alert.dismiss();
					}
				},
				{
					text: this.STranslate.instant("login.resend_email_reset_link"),
					handler: () => {
						this.recoveryPasswordFromSMTPFirebase();
					}
				}
			],
			cssClass: "my-custom-alert",
			backdropDismiss: false
		});

		await alert.present();
	}

	async presentAlertConfirmWithMessage(title: string, message: string) {
		const alert = await this.alertCtrl.create({
			header: title,
			message: message,
			buttons: [
				{
					text: "Ok",
					handler: () => {
						alert.dismiss();
					}
				}
			],
			cssClass: "my-custom-alert",
			backdropDismiss: false
		});

		await alert.present();
	}

	/**
	 * Changing view
	 * @param emailView
	 * @param createPasswordView
	 * @param passwordView
	 * @param firstAccessView
	 * @param shortcodeView
	 */
	changeView(
		emailView: boolean,
		createPasswordView: boolean,
		passwordView: boolean,
		firstAccessView: boolean,
		shortcodeView: boolean
	) {
		if (createPasswordView) {
			if (this.container.loginSettings.passwordSecurityLevel) {
				if (this.container.loginSettings.passwordSecurityLevel === PasswordSecurity.BASIC) {
					if (
						this.loginForm
							.get("password")
							.hasValidator(
								Validators.pattern(
									"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{11,}"
								)
							)
					) {
						this.loginForm
							.get("password")
							.removeValidators(
								Validators.pattern(
									"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{11,}"
								)
							);
					}
					this.loginForm.get("password").addValidators(Validators.minLength(6));
				} else {
					if (this.loginForm.get("password").hasValidator(Validators.minLength(6))) {
						this.loginForm.get("password").removeValidators(Validators.minLength(6));
					}
					this.loginForm
						.get("password")
						.addValidators(
							Validators.pattern(
								"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{11,}"
							)
						);
				}
			}
		}
		this.emailView = emailView;
		this.createPasswordView = createPasswordView;
		this.passwordView = passwordView;
		this.firstAccessView = firstAccessView;
		this.shortcodeView = shortcodeView;
	}

	/**
	 * Open terms or privacy modal
	 * @param type
	 * @returns
	 */
	async openModalTermsOrPrivacy(type: string) {
		const modal = await this.modalCtrl.create({
			component: type === "privacy" ? PathComponents.privacy : PathComponents.termsOfUse,
			componentProps:
				type === "privacy"
					? {
							privacy: this.container.privacyTerms
					  }
					: {
							terms: this.container.termsOfUse
					  }
		});
		return await modal.present();
	}

	/**
	 * Open short code alert
	 */
	async openShortcodeCtrl() {
		const data = this.loginForm.getRawValue();
		const prompt = await this.alertCtrl.create({
			header: this.STranslate.instant("buttons.access_by_shortcode"),
			message: this.STranslate.instant("alerts.type_event_shortcode"),
			inputs: [
				{
					name: "shortcode",
					placeholder: this.STranslate.instant("labels.code"),
					value: data.shortcode ? data.shortcode : "",
					type: "text"
				}
			],
			buttons: [
				{
					text: this.STranslate.instant("buttons.cancel"),
					role: "cancel"
				},
				{
					text: this.STranslate.instant("buttons.btn_verify"),
					handler: (dataAlert) => {
						this.checkShortCode(dataAlert, "alert");
					}
				}
			]
		});
		prompt.present();
	}

	/**
	 * Checking shortcode
	 * @param dataAlert
	 */
	async checkShortCode(dataAlert: any, type: string) {
		const shortcode =
			type === "shortcodeView" ? this.loginForm.getRawValue().shortcode : dataAlert.shortcode.toLowerCase();
		// Check if shortcode input is filled
		if (shortcode) {
			this.loginForm.patchValue({
				shortcode: shortcode
			});
			try {
				const event = await firstValueFrom(this.SEvents.getEventByShortcode(shortcode.toLowerCase()));
				if (!event) {
					// Case event doesn't exist
					this.snackbar.open(this.STranslate.instant("snackbar.event_shortcode_blank"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.loginForm.patchValue({
						shortcode: ""
					});
				} else {
					if (!event.settings.visibility) {
						// Case event is private
						this.presentAlertConfirmWithMessage(
							this.STranslate.instant("login.attempt_to_login_with_short_code_private_event_title"),
							this.STranslate.instant("login.attempt_to_login_with_short_code_private_event_msg")
						);
						return;
					}
					this.store.dispatch(Loading({ payload: true }));
					this.navCtrl.navigateRoot([`/shortcode/${shortcode}`]);
					this.loginForm.patchValue({
						shortcode: ""
					});
					this.elementRef.nativeElement.remove();
				}
			} catch (error) {
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} else {
			// case blank, show error
			this.snackbar.open(this.STranslate.instant("snackbar.event_shortcode_blank"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Back to app login
	 */
	backToAppLogin() {
		this.event = null;
		this.store.dispatch(Loading({ payload: true }));
		this.navCtrl.navigateRoot(["/"]);
		this.elementRef.nativeElement.remove();
	}

	/**
	 * Go to public events list
	 */
	goPublicEventsList() {
		this.store.dispatch(Loading({ payload: true }));
		this.navCtrl.navigateRoot("/events-list/publics");
		this.elementRef.nativeElement.remove();
	}

	/**
	 * getDeviceLanguage
	 * @description Get email code language
	 */
	async getDeviceLanguage() {
		try {
			const langTag = await Device.getLanguageTag();
			if (["fr", "en", "pt", "de", "es"].includes(langTag.value.split("-")[0])) {
				const lang = langTag.value.split("-")[0];
				switch (lang) {
					case "ar":
						this.emailCodeLanguage = "ArAR";
						break;
					case "fr":
						this.emailCodeLanguage = "FrFR";
						break;
					case "en":
						this.emailCodeLanguage = "EnUS";
						break;
					case "pt":
						this.emailCodeLanguage = "PtBR";
						break;
					case "de":
						this.emailCodeLanguage = "DeDE";
						break;
					case "es":
						this.emailCodeLanguage = "EsES";
						break;
				}
			}
		} catch (error) {
			//console.error("LoginPage ~ getEmailCodeLanguage ~ error", error);
		}
	}
}
