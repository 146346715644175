<div class="no-user-container">
    <label class="label-auth" [style.color]="event.styling.contentTextColor">{{
        "auth.need_login_register_for_participating" | translate
        }}</label>
    <div *ngIf="event" class="auth-buttons">
        <!-- <ion-button *ngIf="event.settings.allowPublicLogin" shape="round" (click)="openLogin()"
            [style.max-width]="'250px'" [style.width]="'100%'" class="btn-default access-btn"
            [style.margin]="!event.settings.allowPublicRegister && 'auto'">
            {{"buttons.access" | translate}}
        </ion-button>
        <ion-button *ngIf="event.settings.defaultAttendeesModule && event.settings.allowPublicRegister" shape="round"
            (click)="openRegister()" [style.max-width]="'250px'" [style.width]="'100%'" class="btn-default access-btn"
            [style.margin]="!event.settings.allowPublicLogin && 'auto'"
            [style.background]="event.styling.menuTextColorGradient">
            {{"buttons.register" | translate}}
        </ion-button> -->
        <ion-button *ngIf="event.settings.allowPublicLogin" shape="round" (click)="openLogin()"
            [style.color]="event.styling.menuTextColorGradient" [style.--background]="event.styling.menuColor"
            [style.border]="'2px solid ' + event.styling.menuTextColorGradient"
            [style.margin]="!event.settings.allowPublicRegister && 'auto'">
            {{"buttons.access" | translate}}
        </ion-button>
        <ion-button *ngIf="event.settings.defaultAttendeesModule && event.settings.allowPublicRegister" shape="round"
            (click)="openRegister()"
            [style.color]="event.styling.menuColor.includes('linear') ? event.styling.menuColor.split(',')[1] : event.styling.menuColor"
            [style.--background]="event.styling.menuTextColorGradient"
            [style.margin]="!event.settings.allowPublicLogin && 'auto'">
            {{"buttons.register" | translate}}
        </ion-button>

    </div>
</div>