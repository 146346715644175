<ion-content *ngIf="event && module && eventUserProfile" [style.--background]="isMobile ? '#fff' : 'transparent'">
	<div
		class="complete-header"
		[style.background]="'var(--bg-header)'"
		[style.color]="event.styling.menuTextColor"
	></div>

	<app-profile-general-info
		[event]="event"
		[module]="module"
		[myEventUser]="myEventUser"
		[eventUserProfile]="eventUserProfile"
		[currentLanguage]="currentLanguage"
		[isPrivateMode]="isProfilMode"
	>
	</app-profile-general-info>

	<div class="background" [style.min-height]="isMobile ? 'fit-content !important' : 'calc(100% - 164px) !important'">
		<div
			class="chat-row"
			[style.grid-template-columns]="checkTemplateColumns()"
			*ngIf="module.options.allowChat && eventUserProfile?.email !== '' && (
            (event.settings.allowChat && canRequestAppointment) ||
            (event.settings.allowChat) ||
            (event.settings.allowVisio && event.settings.allowVisioForTwo )
        )"
		>
			<button
				[class.chat_button]="chat_button_loader"
				*ngIf="event.settings.allowChat && eventUserProfile?.email && eventUserProfile?.uid !== myEventUser?.uid"
				shape="round"
				class="btn-chat"
				(click)="openChat()"
				[style.width]="!(event.settings.allowVisio && event.settings.allowVisioForTwo) && '85%'"
				[style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				[style.grid-column-end]=" (!(event.settings.allowVisio && event.settings.allowVisioForTwo) && !canRequestAppointment) ? 3 :'auto'"
			>
				<ion-icon *ngIf="!chat_button_loader" src="assets/icon/Message.svg"></ion-icon>
				<span *ngIf="!chat_button_loader" class="btn-txt">{{"profile.start_chat" | translate}}</span>
			</button>

			<button
				shape="round"
				class="icon-camera"
				[class.visio_button]="visio_button_loader"
				(click)="createVisio()"
				*ngIf="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton && 
                    eventUserProfile?.uid !== myEventUser?.uid && eventUserProfile?.email"
				[style.border-color]="event.styling.btnBgColor"
				[style.color]="event.styling.btnBgColor"
			>
				<ion-icon *ngIf="!visio_button_loader" src="assets/icon/Camera2.svg"></ion-icon>
				<span *ngIf="!visio_button_loader" class="btn-txt">{{"profile.start_visio" | translate}}</span>
			</button>

			<button
				shape="round"
				class="appointment-btn"
				[style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				[style.width]="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton &&
                    eventUserProfile?.uid !== myEventUser?.uid && eventUserProfile?.email ? '45%' : '85%' "
				[style.grid-column-start]="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton &&
                    eventUserProfile?.uid !== myEventUser?.uid && eventUserProfile?.email ? 1 : 'auto'"
				[style.grid-column-end]="event && event.settings.allowVisio && event.settings.allowVisioForTwo && module && module.options.showVisioButton &&
                    eventUserProfile?.uid !== myEventUser?.uid && eventUserProfile?.email ? 3 : 'auto'"
				(click)="openUserTimeslots()"
				*ngIf="event.settings.allowAppointments && canRequestAppointment && eventUserProfile?.uid !== myEventUser?.uid && eventUserProfile?.email && eventUserProfile.type === typeUser.ATTENDEE"
			>
				<ion-icon src="assets/icon/Calendar2.svg"></ion-icon>
				<span>{{ "appointments.make_appointment" | translate }}</span>
			</button>
		</div>

		<div class="profil-content">
			<!-- QR Code -->
			<ion-grid
				*ngIf="event.settings.allowProfileQR && myEventUser && myEventUser?.uid === eventUserProfile?.uid"
			>
				<ion-row>
					<ion-col text-center>
						<div class="qr-code-container">
							<ion-icon src="assets/icon/scan-qr-code.svg"></ion-icon>
							<qrcode
								[qrdata]="event.uid + '+' + eventUserProfile?.uid"
								[elementType]="'canvas'"
								[width]="256"
								[errorCorrectionLevel]="'L'"
							>
							</qrcode>
						</div>
					</ion-col>
				</ion-row>
			</ion-grid>

			<!-- Event user's description -->
			<ion-card
				class="custom-card"
				*ngIf="((isProfilMode && !module.options.requiredFields.description?.hiding.profil)|| 
                (!isProfilMode && !SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, eventUserProfile, 'description', 'baseFields') && !module.options.requiredFields.description?.hiding.public)) && 
                eventUserProfile?.description && eventUserProfile?.description[currentLanguage] && 
                eventUserProfile?.description[currentLanguage] !== ''"
			>
				<ion-card-content>
					<div
						class="container-description"
						[class.show]="showMore"
						[style.maxHeight]="module.options.showMoreOrLess && !showMore ? '100px' : 'fit-content'"
					>
						<span
							class="html-card-content"
							style="white-space: pre-line"
							[innerHTML]="eventUserProfile?.description[currentLanguage] | linky | safeHtml"
						>
						</span>
					</div>
					<button *ngIf="module.options.showMoreOrLess" (click)="showMore = !showMore">
						{{ showMore ? ("buttons.show_less" | translate) : ("buttons.show_more" | translate)}}
					</button>
					<!-- visibility chip -->
					<span
						*ngIf="
                            module.options.enableUserFieldsHideAbility &&
                            isProfilMode
                        "
						class="visibility-state"
						style="top: 0 !important"
					>
						<ion-chip
							*ngIf="!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, eventUserProfile, 'description', 'baseFields')"
							color="success"
							>{{ "labels.visible" | translate }}</ion-chip
						>
						<ion-chip
							*ngIf="SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, eventUserProfile, 'description', 'baseFields')"
							color="danger"
							>{{ "labels.hidden" | translate }}</ion-chip
						>
					</span>
				</ion-card-content>
			</ion-card>

			<!-- Event user's group(s) -->
			<div
				*ngIf="((isProfilMode && !module.options.requiredFields.group?.hiding.profil)|| 
            (!isProfilMode && !module.options.requiredFields.group?.hiding.public)) && eventUserProfile.groups.length > 0"
				class="group-field"
			>
				<div class="item-name" [style.color]="event.styling.titleColor">
					<i class="fa fa-users icon-people" aria-hidden="true"></i>
					<h1 *ngIf="eventUserProfile.groups.length === 1" class="group-title">
						{{ "labels.group" | translate }}
					</h1>
					<h1 *ngIf="eventUserProfile.groups.length > 1" class="group-title">
						{{ "labels.groups" | translate }}
					</h1>
				</div>
				<div class="group-value">
					<small
						class="group-name"
						*ngFor="let group of getCorrespondingGroups(eventUserProfile.groups)"
						[style.color]="group ? group.textColor : ''"
						[style.background]="group
                            ? group.backgroundColor
                            : ''
                    "
					>
						{{ group.name }}
					</small>
				</div>
			</div>

			<!-- Email -->
			<ion-card
				*ngIf="((isProfilMode && !module.options.requiredFields.email?.hiding.profil)|| 
                (!isProfilMode && !SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, eventUserProfile, 'email', 'baseFields') && !module.options.requiredFields.email?.hiding.public)) && eventUserProfile?.email !== ''"
			>
				<ion-card-content>
					<div class="email-bloc">
						<ion-icon src="assets/icon/Send.svg"></ion-icon>
						<div>
							<span class="email-label">{{ "labels.email" | translate }}:</span>
							<a
								class="email-text"
								href="mailto:{{ eventUserProfile?.email }}"
								[style.color]="event.styling.btnBgColor"
							>
								{{ eventUserProfile?.email }}
							</a>
						</div>

						<!-- visibility chip -->
						<span
							*ngIf="
                                module.options.enableUserFieldsHideAbility &&
                                isProfilMode
                            "
							class="visibility-state"
						>
							<ion-chip
								*ngIf="!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, eventUserProfile, 'email', 'baseFields')"
								color="success"
								>{{ "labels.visible" | translate }}</ion-chip
							>
							<ion-chip
								*ngIf="SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, moduleCF, eventUserProfile, 'email', 'baseFields')"
								color="danger"
								>{{ "labels.hidden" | translate }}</ion-chip
							>
						</span>
					</div>
				</ion-card-content>
			</ion-card>

			<!-- Customs fields -->
			<app-custom-fields-view
				[event]="event"
				[module]="module"
				[eventUserProfile]="eventUserProfile"
				[scheduleId]="null"
				[moduleType]="eventUserProfile?.type === typeModule.SPEAKER ? typeModule.SPEAKER : typeModule.ATTENDEE"
				[currentLanguage]="currentLanguage"
			>
			</app-custom-fields-view>

			<!-- Feedbacks -->
			<div
				*ngIf="eventUserProfile && user && eventUserProfile?.uid !== user.uid && feedbacks.length > 0"
				class="feedbacks-containerS"
			>
				<ng-container *ngFor="let feedback of feedbacks">
					<h6 class="module-name-block line-clamp-2" [style.color]="event.styling.titleColor">
						<ion-icon class="module-icon" src="assets/icon/icon-32.svg"></ion-icon>
						<span class="module-name"
							>{{"feedbacks.default_title" | translate}}: {{ feedback.name[currentLanguage] }}</span
						>
					</h6>
					<app-feedback
						[componentMode]="true"
						[eventId]="eventId"
						[moduleId]="feedback.moduleId"
						[feedbackId]="feedback.uid"
						[sessionId]="eventUserProfile?.uid"
						[showBackBtn]="false"
					>
					</app-feedback>
					<hr />
				</ng-container>
			</div>

			<!-- Delete account -->
			<div class="f-row-c-c">
				<ion-button
					*ngIf="event.settings.allowDeleteUserAccount && eventUserProfile && user && eventUserProfile.uid === user.uid"
					shape="round"
					(click)="deleteAccount()"
					color="danger"
				>
					{{ "buttons.delete_account" | translate }}
				</ion-button>
			</div>
		</div>
	</div>

	<div *ngIf="loader" class="loader-container">
		<div class="loader-main" [style.width]="'60px'" [style.height]="'60px'"></div>
	</div>
</ion-content>
