import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot, CanActivate } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, combineLatest, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { selectRouteNestedParams } from "../selectors/router.selectors";
import { getInitContainers, getInitEvents, getInitUser } from "../selectors/utility.selectors";
import { EventsService, EventUsersService } from "../services";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { NavController } from "@ionic/angular";

@Injectable()
export class RegisterFormGuard implements CanActivate {
	constructor(
		private store: Store,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private SEvents: EventsService,
		private SEventUsers: EventUsersService,
		private navCtrl: NavController
	) {}
	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitEvents).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitContainers).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(selectRouteNestedParams)
		]).pipe(
			take(1),
			switchMap((results) => {
				const params = results[3];
				return combineLatest([
					this.store.select(getCurrentUser),
					this.SEvents.getSpecificEvent(params.eventId),
					this.store.select(getContainersState)
				]).pipe(take(1));
			}),
			switchMap((results) => {
				const user = results[0];
				const event = results[1];
				const container = results[2];

				// check if the user exist in the event
				if (user) {
					return this.SEventUsers.getEventUserByEmail(user.email, event.uid, "complete").pipe(
						switchMap((eventUser) => {
							if (eventUser) {
								this.snackbar.open(
									this.STranslate.instant("snackbar.register-form-already-connected-guard"),
									"OK",
									{
										duration: 3000,
										panelClass: ["snackbar"]
									}
								);

								if (event.homePage) {
									if (event.homePage !== "/" + route.url.map((u) => u.path).join("/")) {
										this.navCtrl.navigateRoot(event.homePage);
										return of(false);
									} else {
										this.navCtrl.navigateRoot(
											"/events-list/" +
												(container.currentContainer.loginSettings.showAccessPublicEventsBtn
													? "all"
													: "my-events")
										);
										return of(false);
									}
								} else {
									this.snackbar.open(this.STranslate.instant("snackbar.no_homepage"), "", {
										duration: 3000,
										panelClass: "error-snackbar"
									});
									this.navCtrl.navigateRoot(
										"/events-list/" +
											(container.currentContainer.loginSettings.showAccessPublicEventsBtn
												? "all"
												: "my-events")
									);
									return of(false);
								}
							}
							return of(true);
						})
					);
				}
				return of(true);
			})
		);
	}
}
