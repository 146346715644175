import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TypeLogin } from "../enums/type-login";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { getAllEvents, getMyEvents } from "../selectors/events.selectors";
import { getInitContainers, getInitEvents, getInitUser } from "../selectors/utility.selectors";

@Injectable()
export class EventsListGuard  {
	constructor(private store: Store, private navCtrl: NavController) {}
	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(skipWhile((init) => init === false)),
			this.store.select(getInitEvents).pipe(skipWhile((init) => init === false)),
			this.store.select(getInitContainers).pipe(skipWhile((init) => init === false))
		]).pipe(
			take(1),
			switchMap(() =>
				combineLatest([
					this.store.select(getCurrentUser),
					this.store.select(getAllEvents),
					this.store.select(getMyEvents),
					this.store.select(getContainersState)
				]).pipe(take(1))
			),
			switchMap((results) => {
				const user = results[0];
				const containersState = results[3];
				const container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;
				const events = container.type === "independant" ? results[1] : results[2];
				const routeType = route.params.type;
				if (container.loginSettings.type === TypeLogin.PUBLIC_EVENT_HOME) {
					// If container for public events
					if (container.loginSettings.publicEventsIds.length === 1) {
						// Redirect to event
						this.navCtrl.navigateRoot("/event/" + container.loginSettings.publicEventsIds[0]);
						return of(false);
					} else {
						if (routeType === "publics") {
							// If already events list publics route allow
							return of(true);
						} else {
							// If not publics route ex all -> Redirect to list of publics events
							this.navCtrl.navigateRoot("/events-list/publics");
							return of(false);
						}
					}
				} else if (
					container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM ||
					container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM
				) {
					if (!user && !container.loginSettings.showAccessPublicEventsBtn) {
						// If user don't exist and publics events lst not allowed redirect to login
						this.navCtrl.navigateRoot("/login");
						return of(false);
					} else {
						if (events.length === 1) {
							// If user logged and only one event, redirect to it
							this.navCtrl.navigateRoot("/event/" + events[0].uid);
							return of(false);
						} else {
							if (routeType === "publics") {
								if (!user) {
									// If no user allow
									return of(true);
								} else {
									// If user and route type === publics redirect to all if publics
									// events allowed if not redirect to my - events
									this.navCtrl.navigateRoot(
										"/events-list/" +
											(container.loginSettings.showAccessPublicEventsBtn ? "all" : "my-events")
									);
									return of(false);
								}
							} else {
								// If good route type allow
								return of(true);
							}
						}
					}
				} else {
					// If shortcode or unknow container login type redirect to login
					this.navCtrl.navigateRoot("/login");
					return of(false);
				}
			})
		);
	}
}
