import { createReducer, on } from "@ngrx/store";
import * as RankingsActions from "../actions/rankings.actions";
import { IRankingsState } from "../interfaces/ranking.interfaces";

export const initialState: IRankingsState = {
	eventId: "",
	rankings: []
};

export const rankingsFeatureKey = "rankings";

export const rankingsReducers = createReducer(
	initialState,
	on(RankingsActions.GetAllRankings, (state, action) => ({
		...state,
		eventId: action.eventId,
		rankings: action.payload
	})),
	on(RankingsActions.ResetRankingsState, () => ({
		...initialState
	}))
);
