import { Injectable } from "@angular/core";

import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { combineLatest, Observable, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TypeLogin } from "../enums/type-login";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { getAllEvents } from "../selectors/events.selectors";
import { getInitContainers, getInitEvents, getInitUser } from "../selectors/utility.selectors";

@Injectable()
export class LoginGuard  {
	constructor(private store: Store, private navCtrl: NavController) {}
	canActivate(): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(skipWhile((init) => init === false)),
			this.store.select(getInitEvents).pipe(skipWhile((init) => init === false)),
			this.store.select(getInitContainers).pipe(skipWhile((init) => init === false))
		]).pipe(
			take(1),
			switchMap(() =>
				combineLatest([
					this.store.select(getCurrentUser),
					this.store.select(getAllEvents),
					this.store.select(getContainersState)
				]).pipe(take(1))
			),
			switchMap((results) => {
				const user = results[0];
				const events = results[1];
				const containersState = results[2];
				const container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;

				if (container.loginSettings.type === TypeLogin.PUBLIC_EVENT_HOME) {
					// If container for public events
					if (container.loginSettings.publicEventsIds.length === 1) {
						// Redirect to event
						this.navCtrl.navigateRoot("/event/" + container.loginSettings.publicEventsIds[0]);
						return of(false);
					} else {
						// Redirect to list of public event
						this.navCtrl.navigateRoot("/events-list/publics");
						return of(false);
					}
				} else {
					if (user) {
						if (
							container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM ||
							container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM
						) {
							if (events.length === 1) {
								this.navCtrl.navigateRoot("/event/" + events[0].uid);
								return of(false);
							} else {
								this.navCtrl.navigateRoot(
									"/events-list/" +
										(container.loginSettings.showAccessPublicEventsBtn ? "all" : "my-events")
								);
								return of(false);
							}
						} else {
							return of(true);
						}
					} else {
						return of(true);
					}
				}
			})
		);
	}
}
