import { DOCUMENT } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, ViewChild } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Browser } from "@capacitor/browser";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { AlertController, ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, combineLatest, firstValueFrom, map, of, switchMap, take } from "rxjs";
import { GetHeaderState, Loading, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { AppointmentTimeSlotStatus } from "src/app/shared/enums/type-appointments";
import {
	ICustomField,
	IEvent,
	IEventUser,
	IFullCustomField,
	ILocation,
	IModule,
	IModuleCustomField,
	IVisio
} from "src/app/shared/interfaces";
import { IAppointment, IAppointmentRule } from "src/app/shared/interfaces/appointments.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getLocations,
	getModulesCustomFields,
	getSpecificLocation
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	AppointmentsService,
	FirestoreService,
	LanguagesService,
	UtilityService,
	VisiosService
} from "src/app/shared/services";
import { ConfirmIcsDownloadComponent } from "../../../modals/confirm-ics-download/confirm-ics-download.component";
import { VisioComponent } from "../../components/visio/visio.component";
import { TypeModule, TypeModuleString } from "src/app/shared/enums/type-module";

@Component({
	selector: "app-appointment-details",
	templateUrl: "./appointment-details.page.html",
	styleUrls: ["./appointment-details.page.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppointmentDetailsPage implements OnDestroy {
	languageSub: Subscription;
	subscriptions: Subscription[] = [];

	loader: boolean = true;

	event: IEvent;
	eventId: string;
	module: IModule;
	moduleId: string;
	eventUser: IEventUser = null;
	locations: ILocation[] = [];
	baseCustomFields: ICustomField[] = [];
	modulesCustomsFields: IModuleCustomField[] = [];

	appointmentId: string;
	appointment: IAppointment;

	appointments: IAppointment[] = [];

	AppointmentTimeSlotStatus = AppointmentTimeSlotStatus;

	isMobile: boolean = false;
	icsLoader: boolean = false;
	appointmentDownloadButtonLoader: boolean = false;

	currentLanguage: string;

	constructor(
		@Inject(DOCUMENT) private _document: Document,
		private store: Store,
		private platform: Platform,
		private SAppointments: AppointmentsService,
		private SFirestore: FirestoreService,
		private SLanguages: LanguagesService,
		public SUtility: UtilityService,
		private alertCtrl: AlertController,
		private navCtrl: NavController,
		private snackbar: MatSnackBar,
		private modalCtrl: ModalController,
		private STranslate: TranslateService,
		private SVisios: VisiosService,
		private cd: ChangeDetectorRef,
		private SAnalytics: AnalyticsService
	) {
		this.isMobile = this.platform.is("ios") || this.platform.is("android") ? true : false;
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
	}

	ionViewWillEnter() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.appointmentId = params.appointmentId;
						this.subscriptions.forEach((sub) => sub.unsubscribe());

						this.initDatas();
					});
			});
	}

	initDatas() {
		this.getEvent();
		this.getModule();
		this.getEventUser();
		this.getLocations();
		this.getCustomFields();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		if (this.languageSub && !this.languageSub.closed) {
			this.languageSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.store
			.select(getSpecificModule(this.moduleId))
			.pipe(take(1))
			.subscribe((module) => {
				this.module = module;
				if (this.module) {
					this.getAppointment();
				}
			});
	}

	/**
	 * Get event user
	 */
	getEventUser() {
		this.store
			.select(getCurrentEventUser)
			.pipe(take(1))
			.subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					this.getAppointments();
				}
			});
	}

	getLocations() {
		this.subscriptions.push(
			this.store.select(getLocations("asc")).subscribe((locations) => {
				if (!_.isEqual(this.locations, locations)) {
					this.locations = locations;
				}
			})
		);
	}

	/**
	 * getCustomFields
	 */
	getCustomFields() {
		this.subscriptions.push(
			combineLatest([
				this.store.select(getBaseCustomFields),
				this.store.select(getModulesCustomFields)
			]).subscribe((results) => {
				this.baseCustomFields = results[0];
				this.modulesCustomsFields = results[1];
				this.cd.markForCheck();
			})
		);
	}

	getAppointments() {
		this.subscriptions.push(
			this.SAppointments.getAppointmentsOfEventUser(this.eventId, this.moduleId, this.eventUser.uid).subscribe(
				(appointments) => {
					if (!_.isEqual(this.appointments, appointments)) {
						this.appointments = appointments;
					}
				}
			)
		);
	}

	getAppointment() {
		this.subscriptions.push(
			this.SFirestore.valueChangesDocument(
				`events/${this.eventId}/modules/${this.moduleId}/appointments/${this.appointmentId}`
			)
				.pipe(
					switchMap((appointment: IAppointment) => {
						return combineLatest([
							this.SFirestore.getDocumentObs(
								`events/${appointment.applicant.eventId}/modules/${appointment.applicant.moduleId}/event-users/${appointment.applicant.uid}`
							).pipe(map((snapshot) => snapshot.data() as IEventUser)),
							this.SFirestore.getDocumentObs(
								`events/${appointment.recipient.eventId}/modules/${appointment.recipient.moduleId}/event-users/${appointment.recipient.uid}`
							).pipe(map((snapshot) => snapshot.data() as IEventUser))
						]).pipe(
							take(1),
							switchMap((results) => {
								const applicant = results[0];
								const recipient = results[1];
								appointment.applicantDatas = applicant ? applicant : null;
								appointment.recipientDatas = recipient ? recipient : null;
								return of(appointment);
							})
						);
					})
				)
				.subscribe((appointment) => {
					if (!_.isEqual(this.appointment, appointment)) {
						this.appointment = appointment;
						this.cd.markForCheck();
					}

					this.store.dispatch(
						GetHeaderState({
							payload: {
								item: null,
								module: this.module,
								type: null,
								title: {
									ArAR: this.SLanguages.getLanguageJson("ArAR")["appointments"]["appointment-infos"],
									DeDE: this.SLanguages.getLanguageJson("DeDE")["appointments"]["appointment-infos"],
									EnUS: this.SLanguages.getLanguageJson("EnUS")["appointments"]["appointment-infos"],
									EsES: this.SLanguages.getLanguageJson("EsES")["appointments"]["appointment-infos"],
									FrFR: this.SLanguages.getLanguageJson("FrFR")["appointments"]["appointment-infos"],
									PtBR: this.SLanguages.getLanguageJson("PtBR")["appointments"]["appointment-infos"]
								}
							}
						})
					);
				})
		);
	}

	getLocationsOfRule(appointment: IAppointment) {
		const rule: IAppointmentRule =
			this.module.options && this.module.options.rules && this.module.options.rules.length > 0
				? this.module.options.rules.find((rule) => rule.uid === appointment.sourceRuleId)
				: null;

		if (rule) {
			return this.locations.filter((location) => rule.localisations.includes(location.uid));
		} else {
			return [];
		}
	}

	getLocation(locationId: string) {
		return this.locations.find((location) => location.uid === locationId);
	}

	filterModulesCustomFields(eventUser: IEventUser) {
		return this.modulesCustomsFields.filter((module) => module.moduleId === eventUser.moduleId);
	}

	buildCustomFieldsForEventUser(eventUser: IEventUser) {
		const modulesCustomsFields: IModuleCustomField[] = this.filterModulesCustomFields(eventUser);
		const customFields: IFullCustomField[] = [];
		if (modulesCustomsFields.filter((cus) => cus.canBeTag).length > 0) {
			modulesCustomsFields
				.filter((cus) => cus.canBeTag)
				.forEach((customField) => {
					const baseCustomFieldCorresponding = this.baseCustomFields.find(
						(custField) => custField.uid === customField.uid
					);

					if (baseCustomFieldCorresponding) {
						const data = eventUser.customFields.find((data) => data.uid === customField.uid);
						customFields.push({
							baseSettings: baseCustomFieldCorresponding,
							moduleSettings: customField,
							fieldDatas: data
								? data
								: {
										uid: "",
										field: {}
								  }
						});
					}
				});
		}
		return customFields;
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(date: string, unit: string) {
		return this.SUtility.formatDate(this.event, this.eventUser, date, unit);
	}

	getEndScheduleDate(date: string, duration: number) {
		return this.SUtility.getDateWithTimezoneType(this.event, this.eventUser.updatedSettings.timezoneType, date)
			.plus({ minutes: duration })
			.toISO();
	}

	appointmentIsEnded(appointment: IAppointment) {
		let dateNow = this.SUtility.getDateWithTimezoneType(
			this.event,
			this.eventUser.updatedSettings.timezoneType,
			DateTime.local().toISO()
		);
		let dateEndAppointment = this.SUtility.getDateWithTimezoneType(
			this.event,
			this.eventUser.updatedSettings.timezoneType,
			appointment.startDate
		).plus({
			minutes: appointment.duration
		});
		return (
			appointment.status === this.AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && dateEndAppointment < dateNow
		);
	}

	canAcceptAppointment(appointment: IAppointment) {
		const appointmentsAcceptedOfRule = this.appointments.filter(
			(app) =>
				app.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED &&
				app.sourceRuleId === appointment.sourceRuleId
		);
		const rule =
			this.module.options && this.module.options.rules
				? this.module.options.rules.find((rule) => rule.uid === appointment.sourceRuleId)
				: null;

		if (!rule) {
			return false;
		}

		if (appointmentsAcceptedOfRule.length >= rule.maximumAppointmentsByUserForRecipients) {
			return false;
		}

		return true;
	}

	async acceptAppointment(appointment: IAppointment) {
		try {
			if (!this.canAcceptAppointment(appointment)) {
				this.SUtility.presentToast(
					this.STranslate.instant("appointments.alerts.appointment-accepted-limit"),
					2500,
					"bottom",
					"danger"
				);
				return;
			}
			if (appointment.location) {
				this.store.dispatch(Loading({ payload: true }));
				// appointment.location = this.locationSelected;
				await firstValueFrom(
					this.SAppointments.changeAppointmentStatus(
						this.eventUser,
						appointment,
						AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
					)
				);
				this.store.dispatch(Loading({ payload: false }));
			}
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	async refuseAppointment(appointment: IAppointment) {
		try {
			this.store.dispatch(Loading({ payload: true }));
			await firstValueFrom(
				this.SAppointments.changeAppointmentStatus(
					this.eventUser,
					appointment,
					AppointmentTimeSlotStatus.APPOINTMENT_REJECTED
				)
			);
			this.store.dispatch(Loading({ payload: false }));
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	async cancelAppointment(appointment: IAppointment) {
		try {
			this.store.dispatch(Loading({ payload: true }));
			await firstValueFrom(
				this.SAppointments.changeAppointmentStatus(
					this.eventUser,
					appointment,
					AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED
				)
			);
			this.store.dispatch(Loading({ payload: false }));
		} catch (error) {
			this.store.dispatch(Loading({ payload: false }));
		}
	}

	openProfile(eventUser: IEventUser) {
		this.navCtrl.navigateForward(`event/${eventUser.eventId}/profile/${eventUser.moduleId}/${eventUser.uid}`);
	}

	// Generate ics file part

	/**
	 * openDownloadModal
	 * @param session
	 */
	async openDownloadModal(appointment: IAppointment) {
		try {
			const modal = await this.modalCtrl.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					eventUser: this.eventUser,
					appointment: appointment,
					language: this.event.language
				},
				cssClass: this.isMobile ? "confirm-ics-download-modal-mobile" : "confirm-ics-download-modal"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	/**
	 * Generate ics file
	 * @param session
	 * @returns
	 */
	generateIcsFile(appointment: IAppointment) {
		this.icsLoader = true;
		// Implement a loader
		if (appointment) {
			this.SAppointments.generateAppointmentIcsFile(appointment);
			this.icsLoader = false;
		}
	}

	/**
	 * linkToCalendar
	 * @param timeSlot
	 */
	async linkToCalendar(appointment: IAppointment) {
		this.icsLoader = true;
		let location: string = "";
		if (appointment.location) {
			location = (await firstValueFrom(this.store.select(getSpecificLocation(appointment.location))))?.address;
		}
		const link = this.SAppointments.linkToCalendar(this.event, this.eventUser, location, appointment);
		if (this.isMobile) {
			try {
				await Browser.open({
					url: link
				});
			} catch (error) {
				//
			}
		} else {
			window.open(link, "_blank");
		}
		this.icsLoader = false;
	}

	/**
	 * Download schedule sessions ics
	 */
	async downloadAppointments(event?: any) {
		// Implement a loader
		event.stopPropagation();
		this.appointmentDownloadButtonLoader = true;
		const filteredAppointments = [this.appointment].filter(
			(appointment) => appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED
		);
		try {
			await this.SAppointments.generateAppointmentsIcsFile(filteredAppointments);
			this.appointmentDownloadButtonLoader = false;
		} catch (error) {
			this.appointmentDownloadButtonLoader = false;
		}
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	async downloadFile(filename: string, text) {
		try {
			if (!this.isMobile) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(text));
				element.setAttribute("download", filename);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				if (this.platform.is("ios")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: filename,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				} else if (this.platform.is("android")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: `download/${filename}`,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				}
			}
			this.appointmentDownloadButtonLoader = false;
		} catch (error) {
			this.appointmentDownloadButtonLoader = false;
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 *
	 * Alerts part
	 *
	 */

	/**
	 * alertLoginRegister
	 * @param alertTitle
	 * @param message
	 */
	async alertLoginRegister(alertTitle: string, message?: string, isLoggedIn?: boolean) {
		const alert = await this.alertCtrl.create({
			header: alertTitle,
			message: message ? message : this.STranslate.instant("texts.need_login_to_use"),
			buttons: !isLoggedIn
				? [
						{
							text: this.STranslate.instant("buttons.authenticate"),
							handler: async () => this.openLogin()
						},
						{
							text: this.STranslate.instant("buttons.cancel"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ]
				: [
						{
							text: this.STranslate.instant("buttons.ok"),
							role: "cancel",
							handler: () => {
								//
							}
						}
				  ],
			backdropDismiss: true,
			cssClass: "center-alert-items"
		});

		alert.present();
	}

	/**
	 * Open login modal
	 * @returns
	 */
	async openLogin() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.loginModal,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openRegister) {
				this.openRegister();
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Open register modal
	 * @returns
	 */
	async openRegister() {
		try {
			const modal = await this.modalCtrl.create({
				component: PathComponents.registerForm,
				componentProps: {
					eventId: this.event.uid
				},
				cssClass: "full-sized-modal"
			});
			await modal.present();

			const { data } = await modal.onWillDismiss();
			if (data && data.openLogin) {
				this.openLogin();
			}

			<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0] &&
				((<HTMLElement>this._document.getElementsByClassName("grecaptcha-badge")[0]).style.visibility =
					"hidden");
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 *
	 * Visio part
	 *
	 */

	showVisioWebApp: boolean = false;
	@ViewChild("visioConference") visioConferenceComponent: VisioComponent;
	visioLoading: boolean = false;
	visio: IVisio;

	checkVisioAllowed(appointment: IAppointment) {
		const rule =
			this.module.options && this.module.options.rules
				? this.module.options.rules.find((rule) => rule.uid === appointment.sourceRuleId)
				: null;
		return !rule ||
			(rule && !rule.authorizeVisio) ||
			!this.event.extraFeatures.visio ||
			!this.event.extraFeatures.visioForTwo
			? false
			: true;
	}

	/**
	 * Join visio
	 * @returns
	 */
	async joinVisio(appointment: IAppointment) {
		if (
			appointment.status !== AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED ||
			!this.checkVisioAllowed(appointment)
		) {
			return;
		}
		if (!this.visioLoading) {
			this.visioLoading = true;
			this.store.dispatch(Loading({ payload: true }));
			try {
				if (!appointment.urlVisio) {
					this.visio = await firstValueFrom(
						this.SAppointments.createVisioForAppointment(this.eventId, this.moduleId, appointment)
					);
				} else {
					// Get visio if url exist
					this.visio = await firstValueFrom(
						this.SVisios.getChatVisio(this.eventId, this.moduleId, appointment.uid).pipe(take(1))
					);
				}
				this.showVisioWebApp = true;
				let visioOpen = false;
				this.visioLoading = false;
				this.store.dispatch(Loading({ payload: false }));
				if (this.platform.is("ios") || this.platform.is("android")) {
					const intervalRef = setInterval(() => {
						if (this.visioConferenceComponent) {
							this.visioConferenceComponent.openVisio();
							visioOpen = true;
						}
						if (visioOpen) {
							clearInterval(intervalRef);
						}
					}, 50);
				}

				this.SAnalytics.visioAccess(this.eventId, this.eventUser, this.visio, TypeModule.APPOINTMENTS);

				this.cd.markForCheck();
			} catch (error) {
				this.visioLoading = false;
				this.showVisioWebApp = false;
				this.store.dispatch(Loading({ payload: false }));
				this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				this.cd.markForCheck();
			}
		}
	}

	onCloseVisio() {
		console.log("****** Check close visio");
		this.visioLoading = false;
		this.showVisioWebApp = false;
	}
}
