<div *ngIf="event && module && eventUserProfile" style="height: 100%; z-index: 9999; overflow-y: scroll">
	<div class="background">
		<div *ngIf="!loader; else loading">
			<!-- <h3 class="title" style="margin-top: 10px" [style.color]="event.styling.titleColor">
                {{ "appointments.request_appointment" | translate }}
            </h3> -->

			<p style="font-weight: bold" [style.color]="event.styling.contentTextColor">
				{{ step === 0 ? ("appointments.choose_time_slot" | translate) : ("appointments.confirm-appointment" |
				translate) }}
			</p>

			<div style="
					display: flex;
					flex-direction: column;
					align-items: center;
					justify-content: center;
					border-radius: 10px;
					border: 1px solid #e0e0e0;
					padding: 10px;
				">
				<div style="
						width: 100%;
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: flex-start;
					">
					<img [src]="
                            eventUserProfile && eventUserProfile.photoUrl && eventUserProfile.photoUrl !== ''
                                ? eventUserProfile.photoUrl
                                : 'assets/icon/User2.svg'
                        " class="profile-picture"
						[style.border-color]="isMobile ? '#fff' : event.styling.menuTextColorGradient" />
					<div style="
							width: 100%;
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							justify-content: center;
							line-height: normal;
						">
						<span style="font-size: 18px" [style.color]="event.styling.titleColor">{{ eventUserProfile.name
							}}</span>
						<app-custom-fields-tags [event]="event" [module]="module"
							[modulesCustomsFields]="modulesCustomsFields" [eventUserProfile]="eventUserProfile"
							[customFields]="customFields" [fontSize]="12"
							[isPrivateMode]="eventUser && eventUserProfile && eventUser.uid === eventUserProfile.uid ? true : false"
							[currentLanguage]="currentLanguage"></app-custom-fields-tags>
					</div>
				</div>
				<div *ngIf="selectedSchedule"
					style="width: 100%; height: 1px; background: rgba(0, 0, 0, 0.1); margin: 10px 0"></div>

				<div *ngIf="selectedSchedule" style="
						width: 100%;
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						justify-content: center;
					">
					<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
						<ion-icon [style.color]="event.styling.contentTextColor"
							style="font-size: 12px; margin-right: 10px" name="calendar-clear-outline"></ion-icon>
						<span [style.color]="event.styling.contentTextColor"
							style="font-size: 12px; font-weight: 600">{{ getPartOfDate(selectedSchedule.startSchedule,
							"full") | titlecase }}</span>
					</div>
					<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
						<ion-icon [style.color]="event.styling.contentTextColor"
							style="font-size: 12px; margin-right: 10px" name="time-outline"></ion-icon>
						<span [style.color]="event.styling.contentTextColor"
							style="font-size: 12px; font-weight: 600">{{ getPartOfDate(selectedSchedule.startSchedule,
							"time") | titlecase }} - {{
							getPartOfDate(getEndScheduleDate(selectedSchedule.startSchedule, selectedSchedule.duration),
							"time") | titlecase }}</span>
					</div>
				</div>
			</div>

			<!-- Date step -->
			<div *ngIf="step === 0">
				<mat-accordion *ngFor="let timeSlotsDay of showXTimeSlots(); let i = index">
					<mat-expansion-panel style="margin: 10px 0; border: 1px solid #e0e0e0; box-shadow: none"
						[expanded]="i === 0 ? true : false">
						<mat-expansion-panel-header>
							<mat-panel-title style="font-weight: bold" [style.color]="event.styling.contentTextColor">
								{{ getPartOfDate(timeSlotsDay.day, "full") | titlecase }}
							</mat-panel-title>
						</mat-expansion-panel-header>
						<div class="slots-container" style="border-top: 1px solid #e0e0e0; padding-top: 20px">
							<div class="slot" *ngFor="let schedule of timeSlotsDay.schedules"
								(click)="selectSchedule(timeSlotsDay, schedule)"
								[style.border]="schedule === selectedSchedule ? '1px solid ' + event.styling.titleColor : '1px solid transparent'"
								[style.background]="schedule === selectedSchedule ? event.styling.btnBgColor : event.styling.titleColor + '14'"
								[style.color]="schedule === selectedSchedule ? event.styling.btnTextColor : event.styling.titleColor"
								[style.font-weight]="schedule === selectedSchedule ? 700 : 600">
								{{ getPartOfDate(schedule.startSchedule, "time") }}
							</div>
						</div>
					</mat-expansion-panel>
				</mat-accordion>
			</div>

			<!-- Informations step -->
			<div *ngIf="step === 1" style="margin-bottom: 10px">
				<ion-input mode="md" placeholder="{{ 'appointments.subject'| translate }}*"
					[style.--color]="event.styling.contentTextColor" type="text" [maxlength]="50"
					style="border-radius: 10px; border: 1px solid #e0e0e0; margin-top: 8px"
					[(ngModel)]="appointmentSubject">
				</ion-input>

				<ion-textarea autocapitalize="sentences" mode="md"
					placeholder="{{ 'appointments.informations'| translate }}"
					[style.--color]="event.styling.contentTextColor" [(ngModel)]="appointmentInformations"
					style="min-height: 156px; border-radius: 10px; border: 1px solid #e0e0e0">
				</ion-textarea>
			</div>

			<div *ngIf="step === 0 && limit < timeSlotsByDay.length" style="width: 100%; display: flex">
				<button class="btn-validate" (click)="limit = limit + 15" [style.background]="event.styling.btnBgColor"
					[style.color]="event.styling.btnTextColor">
					<span>{{"appointments.show-more-timeslots" | translate}}</span>
				</button>
			</div>

			<div style="width: 100%; display: flex">
				<button *ngIf="step > 0" class="btn-validate" (click)="goPreviousStep()"
					[style.opacity]="step > 0 ? 1 : 0.4" [style.background]="event.styling.btnBgColor"
					[style.color]="event.styling.btnTextColor">
					<span>{{"buttons.prev" | translate}}</span>
				</button>

				<button *ngIf="step < 1" class="btn-validate" (click)="goNextStep()" [disabled]="!selectedSchedule"
					[style.opacity]="!selectedSchedule ? 0.4 : 1" [style.background]="event.styling.btnBgColor"
					[style.color]="event.styling.btnTextColor">
					<span>{{"buttons.next" | translate}}</span>
				</button>

				<button *ngIf="step === 1" class="btn-validate" (click)="createAppointment()"
					[disabled]="!canSubmit || creating" [style.opacity]="!canSubmit ? 0.4 : 1"
					[style.background]="event.styling.btnBgColor" [style.color]="event.styling.btnTextColor">
					<span>{{"buttons.enter" | translate}}</span>
				</button>
			</div>
		</div>
		<ng-template #loading>
			<div class="align-loader">
				<div class="loader-default"></div>
			</div>
		</ng-template>
	</div>
</div>

<app-alerts *ngIf="showAlertConfirm" [event]="event" [title]="'appointments.alerts.send-appointment-title'"
	[message]="'appointments.alerts.send-appointment-message'" [bgIconColor]="'#4CB16A'" [iconColor]="'white'"
	[maxWidth]="isMobile ? '100%' : '450px'" [confirmBtn]="'buttons.ok'"
	(confirmAlert)="showAlertConfirm = false; redirectToList()"></app-alerts>
<app-alerts *ngIf="showAlertSlotsTaken" [event]="event" [type]="'error'"
	[title]="'appointments.alerts.send-appointment-slots-taken-title'"
	[message]="'appointments.alerts.send-appointment-slots-taken-message'" [bgIconColor]="'red'" [iconColor]="'white'"
	[maxWidth]="isMobile ? '100%' : '450px'" [confirmBtn]="'buttons.ok'"
	(confirmAlert)="showAlertSlotsTaken = false"></app-alerts>
<app-alerts *ngIf="showAlertLimitApplicant" [event]="event" [type]="'error'"
	[title]="'appointments.alerts.send-appointment-limit-accepted-applicant-title'"
	[message]="'appointments.alerts.send-appointment-limit-accepted-applicant-message'" [bgIconColor]="'red'"
	[iconColor]="'white'" [maxWidth]="isMobile ? '100%' : '450px'" [confirmBtn]="'buttons.ok'"
	(confirmAlert)="showAlertLimitApplicant = false"></app-alerts>
<app-alerts *ngIf="showAlertLimitRecipient" [event]="event" [type]="'error'"
	[title]="'appointments.alerts.send-appointment-limit-accepted-recipient-title'"
	[message]="'appointments.alerts.send-appointment-limit-accepted-recipient-message'" [bgIconColor]="'red'"
	[iconColor]="'white'" [maxWidth]="isMobile ? '100%' : '450px'" [confirmBtn]="'buttons.ok'"
	(confirmAlert)="showAlertLimitRecipient = false"></app-alerts>
<app-alerts *ngIf="showAlertError" [event]="event" [type]="'error'"
	[title]="'appointments.alerts.send-appointment-exist-title'"
	[message]="'appointments.alerts.send-appointment-exist-message'" [bgIconColor]="'red'" [iconColor]="'white'"
	[maxWidth]="isMobile ? '100%' : '450px'" [confirmBtn]="'buttons.ok'"
	(confirmAlert)="showAlertError = false"></app-alerts>
<app-alerts *ngIf="showAlertSlotDisabledRecipient" [event]="event" [type]="'error'"
	[title]="'appointments.alerts.send-appointment-slot-disabled-recipient-title'"
	[message]="'appointments.alerts.send-appointment-slot-disabled-recipient-message'" [bgIconColor]="'red'"
	[iconColor]="'white'" [maxWidth]="isMobile ? '100%' : '450px'" [confirmBtn]="'buttons.ok'"
	(confirmAlert)="showAlertSlotDisabledRecipient = false"></app-alerts>