import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { ModalController, NavController, NavParams } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { skipWhile, Subscription } from "rxjs";
import { SetNotificationBadge } from "src/app/shared/actions/utility.actions";
import { TypeModule } from "src/app/shared/enums/type-module";
import { IEvent, IEventUser, ILocation, INotification } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getLocations, getNotifications } from "src/app/shared/selectors/generics-modules-data.selectors";
import { EventUsersService, StorageService, UtilityService } from "src/app/shared/services";

@Component({
	selector: "app-notifications",
	templateUrl: "./notifications.component.html",
	styleUrls: ["./notifications.component.scss"]
})
export class NotificationsComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	event: IEvent;
	eventId: string;
	eventUser: IEventUser;
	locations: ILocation[] = [];

	detailNotification: INotification;

	@Input() displayNotifID: string = "";
	currentUser: IEventUser;
	@Input() openTime: number;

	notifications: INotification[] = [];
	displayedNotification: INotification;

	typeModules = TypeModule;

	userLastSeenNotifTimeUpdated: boolean = false;
	constructor(
		private store: Store,
		private SStorage: StorageService,
		private navParams: NavParams,
		public modalCtrl: ModalController,
		private SEventUser: EventUsersService,
		private SUtility: UtilityService,
		private STranslate: TranslateService,
		private navCtrl: NavController
	) {
		this.eventId = this.navParams.get("eventId");
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store.select(getNotifications()).subscribe((res) => {
				const notifs = [...res].filter((n) => n.deliveryDate <= this.openTime);
				if (!_.isEqual(this.notifications, notifs)) {
					this.notifications = notifs;
					// const notif = res
					// 	.sort((a, b) => b.deliveryDate - a.deliveryDate)
					// 	.find((notif) => notif.deliveryDate <= DateTime.now().valueOf());
					// if (notif && this.notifications.filter((n) => n.uid === notif.uid)) {
					// 	this.notifications.unshift(notif);
					// }
				}
			})
		);
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;
				}
			})
		);

		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				this.eventUser = eventUser;
			})
		);

		this.subscriptions.push(
			this.store.select(getLocations("asc")).subscribe((locations) => {
				this.locations = locations;
			})
		);

		this.subscriptions.push(
			this.store
				.select(getCurrentEventUser)
				.pipe(skipWhile(() => !this.event))
				.subscribe((eventUser) => {
					if (!_.isEqual(this.currentUser, eventUser)) {
						this.currentUser = eventUser;
						// modifier lastTime one time on load component
						if (this.currentUser && !this.userLastSeenNotifTimeUpdated) {
							const currentUser = { ...this.currentUser };
							if (this.displayNotifID) {
								// update
								let readNotifications =
									this.currentUser.updatedSettings &&
									this.currentUser.updatedSettings.readNotifications
										? this.currentUser.updatedSettings.readNotifications
										: "";
								readNotifications += `${this.displayNotifID};`;
								currentUser.updatedSettings.readNotifications = readNotifications;
							}
							currentUser.updatedSettings.lastSeenNotifTime = DateTime.now().valueOf();
							// Set for public if no user
							this.SEventUser.updateEventUserUpdatedSettings(
								this.eventId,
								this.currentUser.moduleId,
								this.currentUser.uid,
								{
									readNotifications: currentUser.updatedSettings.readNotifications,
									lastSeenNotifTime: currentUser.updatedSettings.lastSeenNotifTime
								}
							);
							this.store.dispatch(SetNotificationBadge({ payload: 0 }));
							this.userLastSeenNotifTimeUpdated = true;
						}
					}
					if (this.event) {
						this.SStorage.set("lastSeenNotif" + this.event.uid, DateTime.now().valueOf());
						this.store.dispatch(SetNotificationBadge({ payload: 0 }));
					}
				})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Show notification details
	 * @param notification
	 */
	async notificationOpen(notification: INotification) {
		try {
			if (
				notification &&
				notification.functionalityType &&
				notification.functionalityType === this.typeModules.APPOINTMENTS
			) {
				console.log(
					"Check: ",
					notification,
					`event/${notification.functionalityDatas.eventId}/appointments/${notification.functionalityDatas.moduleId}/details/${notification.functionalityDatas.uid}`
				);
				this.navCtrl.navigateForward(
					`event/${notification.functionalityDatas.eventId}/appointments/${notification.functionalityDatas.moduleId}/details/${notification.functionalityDatas.uid}`,
					{ replaceUrl: true }
				);
				this.close();
			} else {
				this.displayNotifID = notification.uid;
				this.detailNotification = this.notifications.find((n) => n.uid === this.displayNotifID);
				if (this.displayNotifID && this.currentUser) {
					const currentUser = { ...this.currentUser };
					let readNotifications =
						this.currentUser.updatedSettings && this.currentUser.updatedSettings.readNotifications
							? this.currentUser.updatedSettings.readNotifications
							: "";
					readNotifications += `${this.displayNotifID};`;
					currentUser.updatedSettings.readNotifications = readNotifications;
					await this.SEventUser.updateEventUserUpdatedSettings(
						this.eventId,
						this.currentUser.moduleId,
						this.currentUser.uid,
						{
							readNotifications: currentUser.updatedSettings.readNotifications
						}
					);
					// await this.SEventUser.updateEventUser(this.eventId, this.currentUser.moduleId, currentUser);
				}
			}
		} catch (error) {
			this.SUtility.presentToast(this.STranslate.instant("snackbar.error_occured"), 3000, "bottom", "warning");
		}
	}

	getSpecificLocation(locationId: string) {
		return this.locations.find((loc) => loc.uid === locationId);
	}

	/**
	 * Go back to list notifications
	 */
	goBack() {
		this.displayNotifID = "";
		this.detailNotification = null;
	}

	/**
	 * Close modal notification
	 */
	close() {
		this.modalCtrl.dismiss();
		this.eventId = null;
	}

	/**
	 * Get notification Title
	 * @param notification
	 * @returns
	 */
	getNotificationTitle(notification: INotification | string) {
		const lang =
			this.currentUser && this.currentUser.updatedSettings && this.currentUser.updatedSettings.language
				? this.currentUser.updatedSettings.language.toLowerCase().substring(0, 2)
				: this.event.language.toLowerCase().substring(0, 2);
		return typeof notification === "string"
			? this.notifications.find((n) => n.uid === notification).titles[lang]
			: notification.titles[lang];
	}

	/**
	 * Get notification content
	 * @param notification
	 * @returns
	 */
	getNotificationContent(notification: INotification) {
		const lang =
			this.currentUser && this.currentUser.updatedSettings && this.currentUser.updatedSettings.language
				? this.currentUser.updatedSettings.language.toLowerCase().substring(0, 2)
				: this.event.language.toLowerCase().substring(0, 2);
		return notification.contents[lang];
	}

	/**
	 * Check if notification has been read by user
	 * @param notification
	 * @returns
	 */
	isNotificationRead(notification: INotification) {
		if (
			this.currentUser &&
			this.currentUser.updatedSettings &&
			this.currentUser.updatedSettings.readNotifications
		) {
			return this.currentUser.updatedSettings.readNotifications.includes(notification.uid);
		}
		return false;
	}

	/**
	 * Get  date
	 * @param date
	 * @returns
	 */
	getPartOfDate(date: string, type: string) {
		return date ? this.SUtility.getPartOfDate(this.event, this.eventUser, date, type) : "";
	}

	getEndScheduleDate(date: string, duration: number) {
		return DateTime.fromISO(date).plus({ minutes: duration }).toISO();
	}
}
