import { createAction, props } from "@ngrx/store";
import { IWidget } from "../interfaces/widget.interfaces";

export enum WidgetActionsTypes {
	GetWidgets = "[Widget Service] Get Widgets",
	ResetWidgetsState = "[Widget Service] Reset widgets state"
}

export const GetWidgets = createAction(WidgetActionsTypes.GetWidgets, props<{ payload: IWidget[]; eventId: string }>());
export const ResetWidgetsState = createAction(WidgetActionsTypes.ResetWidgetsState, props<any>());
