import { environment } from "src/environments/environment";

// Function Decorators
export const LogFuncTrace = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	if (environment.production) {
		return descriptor;
	}
	const originalMethod = descriptor.value;
	descriptor.value = function (...args: any[]) {
		console.warn(`[TRACE] ${target.constructor.name}.${propertyKey}()`);
		return originalMethod.apply(this, args);
	};
	return descriptor;
};

export const LogFuncTime = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	if (environment.production) {
		return descriptor;
	}

	const originalMethod = descriptor.value;
	descriptor.value = function (...args: any[]) {
		const start = performance.now();
		const result = originalMethod.apply(this, args);
		const end = performance.now();
		console.warn(`[TIME] ${target.constructor.name}.${propertyKey}() took ${end - start}ms`);
		return result;
	};
	return descriptor;
};

export const LogFuncError = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	if (environment.production) {
		return descriptor;
	}
	const originalMethod = descriptor.value;
	descriptor.value = function (...args: any[]) {
		try {
			originalMethod.apply(this, args);
		} catch (error) {
			console.error(`[ERROR] ${target.constructor.name}.${propertyKey}()`);
			console.error(error);
		}
	};
	return descriptor;
};

export const LogFuncArgs = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	if (environment.production) {
		return descriptor;
	}
	const originalMethod = descriptor.value;
	descriptor.value = function (...args: any[]) {
		console.warn(`[ARGS] ${target.constructor.name}.${propertyKey}() with arguments
		${JSON.stringify(args)}`);
		return originalMethod.apply(this, args);
	};

	return descriptor;
};

export const LogFuncStats = (target: any, propertyKey: string, descriptor: PropertyDescriptor) => {
	if (environment.production) {
		return descriptor;
	}

	LogFuncTrace(target, propertyKey, descriptor);
	LogFuncError(target, propertyKey, descriptor);
	LogFuncArgs(target, propertyKey, descriptor);
	LogFuncTime(target, propertyKey, descriptor);
	return descriptor;
};

// Property Decorators
export const LogProperty = (target: any, propertyKey: string) => {
	if (environment.production) {
		return;
	}

	let value = target[propertyKey];

	const getter = () => {
		// console.warn(`[LogProperty] value of ${propertyKey}: `);
		// console.warn(value);
		return value;
	};

	const setter = (newValue) => {
		console.warn(`[LogProperty] Updated value of ${propertyKey} to: `);
		console.warn(newValue);
		value = newValue;
	};

	Object.defineProperty(target, propertyKey, {
		get: getter,
		set: setter,
		enumerable: true,
		configurable: true
	});
};
