import { createReducer, on } from "@ngrx/store";
import * as DocumentsActions from "../actions/documents.actions";
import { IDocumentsState } from "../interfaces/documents.interfaces";

export const initialState: IDocumentsState = {
	documents: [],
	eventId: "",
	folders: [],
	images: [],
	videos: []
};

export const documentsFeatureKey = "documents";

export const documentsReducers = createReducer(
	initialState,
	on(DocumentsActions.GetAllDocuments, (state, action) => ({
		...state,
		eventId: action.eventId,
		documents: action.payload
	})),
	on(DocumentsActions.GetAllFolders, (state, action) => ({
		...state,
		eventId: action.eventId,
		folders: action.payload
	})),
	on(DocumentsActions.GetAllImages, (state, action) => ({
		...state,
		eventId: action.eventId,
		images: action.payload
	})),
	on(DocumentsActions.GetAllVideos, (state, action) => ({
		...state,
		eventId: action.eventId,
		videos: action.payload
	})),
	on(DocumentsActions.ResetDocumentsState, () => ({
		...initialState
	}))
);
