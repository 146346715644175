import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { ExternalInteractivityService, FeedNewsService, SchedulesService, SurveysService } from ".";
import { GetMyEventUser, LogOutUser } from "../actions/auth.actions";
import { ResetChatsState } from "../actions/chats.actions";
import { ResetCheckinsState } from "../actions/checkins.actions";
import { ResetCustomPagesState } from "../actions/custom-pages.actions";
import { ResetDocumentsState } from "../actions/documents.actions";
import { GetEvent, ResetEventsState } from "../actions/events.actions";
import { ResetFeedNewsState } from "../actions/feed-news.actions";
import { ResetGamificationState } from "../actions/gamification.actions";
import { ResetGenericDatasState } from "../actions/generics-modules-data.actions";
import { ResetInteractivityState } from "../actions/interactivity.actions";
import { ResetModulesState } from "../actions/modules.actions";
import { ResetRankingsState } from "../actions/rankings.actions";
import { ResetSchedulesState } from "../actions/schedules.actions";
import { ResetInitDatasEvent, ResetUtilityState } from "../actions/utility.actions";
import { ResetStateVideoPlayer } from "../actions/video-player.actions";
import { ResetWidgetsState } from "../actions/widgets.actions";
import { AskQuestionsService } from "./ask-questions.service";
import { ChatsService } from "./chats.service";
import { CheckinsService } from "./checkins.service";
import { CustomFieldsService } from "./custom-fields.service";
import { CustomPagesService } from "./custom-pages.service";
import { DocumentsService } from "./documents.service";
import { FeedbacksService } from "./feedbacks.service";
import { GroupsService } from "./groups.service";
import { InteractivityService } from "./interactivity.service";
import { LocationsService } from "./locations.service";
import { ModulesManagerService } from "./modules-manager.service";
import { NotificationsService } from "./notifications.service";
import { QuizsService } from "./quizs.service";
import { RankingsService } from "./rankings.service";
import { TracksService } from "./tracks.service";
import { TreasureHuntsService } from "./treasure-hunts.service";
import { WidgetsService } from "./widgets.service";
import { WordCloudsService } from "./word-clouds.service";
import { AutomationsService } from "./automations.service";
import { RegisterFormService } from "./register-form.service";
import { ResetAutomationsState } from "../actions/automations.actions";
import { ResetRegisterFormsState } from "../actions/register-form.actions";

@Injectable({
	providedIn: "root"
})
export class StateManagerService {
	constructor(
		private store: Store,
		private SAsksQuestions: AskQuestionsService,
		private SAutomations: AutomationsService,
		private SChats: ChatsService,
		private SCheckins: CheckinsService,
		private SCustomFields: CustomFieldsService,
		private SCustomPages: CustomPagesService,
		private SDocuments: DocumentsService,
		private SExternalInteractivities: ExternalInteractivityService,
		// private SFavorites: FavoritesService,
		private SFeedbacks: FeedbacksService,
		private SFeedNews: FeedNewsService,
		private SGroups: GroupsService,
		private SInteractivityService: InteractivityService,
		private SLocations: LocationsService,
		private SModules: ModulesManagerService,
		private SNotifications: NotificationsService,
		private SQuizs: QuizsService,
		private SRankings: RankingsService,
		private SRegister: RegisterFormService,
		private SSchedules: SchedulesService,
		private SSurveys: SurveysService,
		private STracks: TracksService,
		private STreasureHunts: TreasureHuntsService,
		private SWidgets: WidgetsService,
		private SWordClouds: WordCloudsService
	) {}

	unsubscribeEvent() {
		this.SAsksQuestions.unsubscribeAll();
		this.SAutomations.unsubscribeAll();
		this.SChats.unsubscribeChats();
		this.SCheckins.unsubscribeAll();
		this.SCustomFields.unsubscribeAll();
		this.SCustomPages.unsubscribeAll();
		this.SDocuments.unsubscribeAll();
		this.SExternalInteractivities.unsubscribeAll();
		// this.SFavorites.unsubscribeAll();
		this.SFeedbacks.unsubscribeAll();
		this.SFeedNews.unsubscribeAll();
		this.SGroups.unsubscribeAll();
		this.SInteractivityService.unsubscribeAll();
		this.SLocations.unsubscribeAll();
		this.SModules.unsubscribeAll();
		this.SNotifications.unsubscribeAll();
		this.SQuizs.unsubscribeAll();
		this.SRankings.unsubscribeAll();
		this.SRegister.unsubscribeAll();
		this.SSchedules.unsubscribeAll();
		this.SSurveys.unsubscribeAll();
		this.STracks.unsubscribeAll();
		this.STreasureHunts.unsubscribeAll();
		this.SWidgets.unsubscribeAll();
		this.SWordClouds.unsubscribeAll();
	}

	unsubscribeAll() {
		this.SAsksQuestions.unsubscribeAll();
		this.SAutomations.unsubscribeAll();
		this.SChats.unsubscribeChats();
		this.SCheckins.unsubscribeAll();
		this.SCustomFields.unsubscribeAll();
		this.SCustomPages.unsubscribeAll();
		this.SDocuments.unsubscribeAll();
		this.SExternalInteractivities.unsubscribeAll();
		// this.SFavorites.unsubscribeAll();
		this.SFeedbacks.unsubscribeAll();
		this.SFeedNews.unsubscribeAll();
		this.SGroups.unsubscribeAll();
		this.SInteractivityService.unsubscribeAll();
		this.SLocations.unsubscribeAll();
		this.SModules.unsubscribeAll();
		this.SNotifications.unsubscribeAll();
		this.SQuizs.unsubscribeAll();
		this.SRankings.unsubscribeAll();
		this.SRegister.unsubscribeAll();
		this.SSchedules.unsubscribeAll();
		this.SSurveys.unsubscribeAll();
		this.STracks.unsubscribeAll();
		this.STreasureHunts.unsubscribeAll();
		this.SWidgets.unsubscribeAll();
		this.SWordClouds.unsubscribeAll();
	}

	resetStateEvent() {
		this.store.dispatch(ResetAutomationsState(null));
		this.store.dispatch(ResetChatsState(null));
		this.store.dispatch(ResetCheckinsState(null));
		this.store.dispatch(ResetCustomPagesState(null));
		this.store.dispatch(ResetDocumentsState(null));
		this.store.dispatch(GetMyEventUser({ payload: null, eventId: null }));
		this.store.dispatch(ResetFeedNewsState(null));
		this.store.dispatch(ResetGenericDatasState(null));
		this.store.dispatch(ResetInteractivityState(null));
		this.store.dispatch(ResetModulesState(null));
		this.store.dispatch(ResetRankingsState(null));
		this.store.dispatch(ResetSchedulesState(null));
		this.store.dispatch(ResetGamificationState(null));
		this.store.dispatch(ResetUtilityState(null));
		this.store.dispatch(ResetInitDatasEvent(null));
		this.store.dispatch(ResetRegisterFormsState(null));
		this.store.dispatch(ResetStateVideoPlayer(null));
		this.store.dispatch(ResetWidgetsState(null));
		this.store.dispatch(GetEvent({ payload: null }));
	}

	resetStateAll() {
		this.store.dispatch(ResetAutomationsState(null));
		this.store.dispatch(ResetChatsState(null));
		this.store.dispatch(ResetCheckinsState(null));
		this.store.dispatch(ResetCustomPagesState(null));
		this.store.dispatch(ResetDocumentsState(null));
		this.store.dispatch(ResetEventsState(null));
		this.store.dispatch(GetMyEventUser({ payload: null, eventId: null }));
		this.store.dispatch(ResetFeedNewsState(null));
		this.store.dispatch(ResetGenericDatasState(null));
		this.store.dispatch(ResetInteractivityState(null));
		this.store.dispatch(ResetModulesState(null));
		this.store.dispatch(ResetRankingsState(null));
		this.store.dispatch(ResetSchedulesState(null));
		this.store.dispatch(ResetGamificationState(null));
		this.store.dispatch(ResetUtilityState(null));
		this.store.dispatch(ResetInitDatasEvent(null));
		this.store.dispatch(ResetRegisterFormsState(null));
		this.store.dispatch(ResetStateVideoPlayer(null));
		this.store.dispatch(ResetWidgetsState(null));
		this.store.dispatch(LogOutUser(null));
	}
}
