import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEventUser, ISchedule } from "../interfaces";
import { ICustomField, IModuleCustomField } from "../interfaces/custom-fields.interfaces";
import { IFeedback, IFeedbackQuestionResult } from "../interfaces/feedbacks.interfaces";
import { IFavoriteFolder } from "../interfaces/folders.interfaces";
import { IGroup } from "../interfaces/groups.interfaces";
import { ILocation } from "../interfaces/location.interfaces";
import { INotification } from "../interfaces/notifications.interfaces";
import { IGenericsModulesData } from "../interfaces/states.interfaces";
import { ITrack } from "../interfaces/tracks.interfaces";
import { getCurrentEventUser } from "./auth.selectors";

export const getGenericsModulesData = createFeatureSelector<IGenericsModulesData>("genericsModulesData");

export const checkSameEvent = (data: { key: string; uid: string }) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		return state[data.key].eventId === data.uid ? true : false;
	});

/**
 * Base Custom fields part
 */
export const getBaseCustomFields = createSelector(getGenericsModulesData, (state: IGenericsModulesData) =>
	_.cloneDeep(state.baseCustomFields.baseCustomFields)
);

export const getSpecificBaseCustomField = (data: { key: string; uid: string }) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const customField: ICustomField = state.baseCustomFields.baseCustomFields.find(
			(customField) => customField.uid == data.uid
		);
		return customField ? _.cloneDeep(customField) : null;
	});

/**
 * Modules Custom fields part
 */
export const getModulesCustomFields = createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
	return _.cloneDeep(state.modulesCustomFields.modulesCustomFields);
});

export const getModulesCustomsFieldsOfModule = (moduleId: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) =>
		_.cloneDeep(state.modulesCustomFields.modulesCustomFields)
			.filter((custom) => custom.moduleId === moduleId)
			.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0))
	);

export const getModulesCustomsFieldsForModuleForRegister = (moduleId: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) =>
		_.cloneDeep(state.modulesCustomFields.modulesCustomFields)
			.filter((custom) => custom.moduleId === moduleId)
			.sort((a, b) =>
				a.registerOptions.order > b.registerOptions.order
					? 1
					: a.registerOptions.order < b.registerOptions.order
					? -1
					: 0
			)
	);

export const getSpecificModulesCustomField = (uid: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const customField: IModuleCustomField = state.modulesCustomFields.modulesCustomFields.find(
			(customField) => customField.uid == uid
		);
		return customField ? _.cloneDeep(customField) : null;
	});

/**
 * Feedbacks parts
 */
export const getResultsOfFeedbacks = createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
	return _.cloneDeep(state.feedbacks.feedbacksResults);
});

export const getResultOfFeedback = (feedbackId: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const results = state.feedbacks.feedbacksResults.filter((result) => result.feedbackId === feedbackId);
		return results ? _.cloneDeep(results) : null;
	});

export const getFeedbacks = createSelector(
	getGenericsModulesData,
	getResultsOfFeedbacks,
	(state: IGenericsModulesData, feedbacksResults: IFeedbackQuestionResult[]) => {
		const feedbacks = _.cloneDeep(state.feedbacks.feedbacks);
		return feedbacks.map((feedback) => {
			feedback.eventUserResults = feedbacksResults.filter((result) => result.feedbackId === feedback.uid);
			return feedback;
		});
	}
);

export const getSpecificFeedback = (uid: string) =>
	createSelector(
		getGenericsModulesData,
		getResultOfFeedback(uid),
		(state: IGenericsModulesData, feedbackResults: IFeedbackQuestionResult[]) => {
			const feedback: IFeedback = _.cloneDeep(state.feedbacks.feedbacks).find((feedback) => feedback.uid == uid);
			if (feedback) feedback.eventUserResults = feedbackResults;
			return feedback ? feedback : null;
		}
	);

export const getAccessiblesFeedbacksByType = (type: number, session: ISchedule | IEventUser) =>
	createSelector(
		getGenericsModulesData,
		getResultsOfFeedbacks,
		(state: IGenericsModulesData, feedbacksResults: IFeedbackQuestionResult[]) => {
			const feedbacks = _.cloneDeep(state.feedbacks.feedbacks).filter((feedback) => feedback.visibility);
			if (type === 0) {
				return feedbacks.map((feedback) => {
					feedback.eventUserResults = feedbacksResults.filter((result) => result.feedbackId === feedback.uid);
					return feedback;
				});
			} else {
				return feedbacks
					.filter(
						(feedback) =>
							feedback.link.linkType === 0 ||
							(feedback.link.linkType === 1 && feedback.link.linkedModules.includes(session.moduleId)) ||
							(feedback.link.linkType === 2 && feedback.link.linkedSpecifics.includes(session.uid))
					)
					.map((feedback) => {
						feedback.eventUserResults = feedbacksResults.filter(
							(result) => result.feedbackId === feedback.uid && result.sessionId === session.uid
						);
						return feedback;
					});
			}
		}
	);

/**
 * Groups part
 */
export const getGroups = createSelector(getGenericsModulesData, (state: IGenericsModulesData) =>
	_.cloneDeep(state.groups.datas)
);

export const getGroupsByOrder = (order: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const groups = _.cloneDeep(state.groups.datas);
		if (order === "asc") {
			return groups.sort((a, b) =>
				a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
			);
		} else if (order === "desc") {
			return groups.sort((a, b) =>
				b.name.localeCompare(a.name, undefined, { numeric: true, sensitivity: "base" })
			);
		} else if (order === "oldest") {
			return groups.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else {
			return groups.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		}
	});

export const getSpecificGroup = (uid: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const group: IGroup = state.groups.datas.find((group) => group.uid == uid);
		return group ? _.cloneDeep(group) : null;
	});

export const getSpecificGroups = (uids: string[]) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const groups: IGroup[] = state.groups.datas.filter((group) => uids.includes(group.uid));
		return groups ? _.cloneDeep(groups) : [];
	});

/**
 * Locations part
 */
export const getLocations = (order: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const locations = _.cloneDeep(state.locations.datas);
		if (order === "asc") {
			return locations.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0));
		} else if (order === "desc") {
			return locations.sort((a, b) => (a.name < b.name ? 1 : a.name > b.name ? -1 : 0));
		} else if (order === "oldest") {
			return locations.sort((a, b) =>
				a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
			);
		} else {
			return locations.sort((a, b) =>
				a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0
			);
		}
	});

export const getSpecificLocation = (uid: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const location: ILocation = state.locations.datas.find((location) => location.uid == uid);
		return location ? _.cloneDeep(location) : null;
	});

/**
 * Notifications part
 */
export const getNotifications = () =>
	createSelector(getGenericsModulesData, getCurrentEventUser, (state: IGenericsModulesData, eventUser: IEventUser) =>
		_.cloneDeep(state.notifications.notifications).filter(
			(notification) =>
				// (notification.subscriptionSettings.moduleSubscriptionId === 'agendaPerso'  && ) ||
				notification.sendTo === "all" ||
				(notification.sendTo === "group" &&
					eventUser &&
					eventUser.groups.some((gr) => notification.groupsIds.includes(gr))) ||
				(notification.sendTo === "specific" && eventUser && notification.specificUsers.includes(eventUser.uid))
		)
	);

export const getSpecificNotification = (uid: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const notification: INotification = state.notifications.notifications.find(
			(notification) => notification.uid == uid
		);
		return notification ? _.cloneDeep(notification) : null;
	});

/**
 * Tracks part
 */
export const getTracks = createSelector(getGenericsModulesData, (state: IGenericsModulesData) =>
	_.cloneDeep(state.tracks.datas)
);

export const getSpecificTrack = (uid: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const track: ITrack = state.tracks.datas.find((track) => track.uid == uid);
		return track ? _.cloneDeep(track) : null;
	});

/**
 * Favorites folders part
 */
export const getFavoritesFolders = createSelector(getGenericsModulesData, (state: IGenericsModulesData) =>
	_.cloneDeep(state.favoritesFolders.folders)
);

export const getSpecificFavoriteFolder = (uid: string) =>
	createSelector(getGenericsModulesData, (state: IGenericsModulesData) => {
		const folder: IFavoriteFolder = state.favoritesFolders.folders.find((folder) => folder.uid == uid);
		return folder ? _.cloneDeep(folder) : null;
	});
