import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { ModalController, NavController, NavParams, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { DateTime } from "luxon";
import { Subscription, combineLatest, firstValueFrom, interval } from "rxjs";
import { skipWhile, take, tap } from "rxjs/operators";
import { InitAuth, InitClaims, InitEvents, InitUser } from "src/app/shared/actions/utility.actions";
import { PasswordSecurity } from "src/app/shared/enums/password-security";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeLogin } from "src/app/shared/enums/type-login";
import { TypeModule } from "src/app/shared/enums/type-module";
import { TypeUser } from "src/app/shared/enums/type-user";
import { IContainer, IEvent, IEventUser, IUser } from "src/app/shared/interfaces";
import { IEventUserUpdatedSettings } from "src/app/shared/interfaces/event-users.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getContainersState } from "src/app/shared/selectors/containers.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getModulesByType } from "src/app/shared/selectors/modules.selectors";
import { selectUrl, selectRouteNestedParams } from "src/app/shared/selectors/router.selectors";
import { getInitAuth, getInitEvents, getInitUser } from "src/app/shared/selectors/utility.selectors";
import { AuthService, EventUsersService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-login",
	templateUrl: "./login.component.html",
	styleUrls: ["./login.component.scss"]
})
export class LoginComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	interval: Subscription;

	loader: boolean = true;
	activeLoader: boolean = false;

	user: IUser;
	eventId: string;
	moduleId: string;
	event: IEvent;
	container: IContainer;
	codeNumber: string = "";
	eventUser: IEventUser;

	count: number = 180;
	timeRemaining: string;

	loginForm: UntypedFormGroup;

	emailView: boolean = false;
	createPasswordView: boolean = false;
	passwordView: boolean = false;
	firstAccessView: boolean = false;

	showPwd: boolean = false;
	showConfirmPwd: boolean = false;

	isMobile: boolean = false;
	addToEventMode: boolean = false;
	msgError: string = "";
	init: boolean;
	isModal: boolean;
	navigateToRegister: boolean;
	navigatingToRegisterPage: boolean = false;

	constructor(
		private nav: NavController,
		private modalCtrl: ModalController,
		private SAuth: AuthService,
		private SEventUsers: EventUsersService,
		private STranslate: TranslateService,
		private store: Store,
		private fb: UntypedFormBuilder,
		private snackbar: MatSnackBar,
		private platform: Platform,
		private router: Router,
		private SUtility: UtilityService
	) {
		this.loginForm = this.fb.group({
			email: [
				"",
				[
					Validators.required,
					Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
					Validators.email
				]
			],
			password: ["", [Validators.required, Validators.minLength(6)]],
			confirmPassword: [""],
			codeNumber: [""],
			termCheck: [false]
		});

		if ((this.platform.is("mobile") && window.innerWidth < 768) || window.innerWidth < 768) {
			this.isMobile = true;
		}
	}

	ngOnInit() {
		if (this.interval && !this.interval.closed) {
			this.interval.unsubscribe();
		}

		this.subscriptions.push(
			this.store
				.select(selectUrl)
				.pipe(take(1))
				.subscribe(() => {
					this.store
						.select(selectRouteNestedParams)
						.pipe(take(1))
						.subscribe((params) => {
							this.eventId = params.eventId;
							this.moduleId = params.moduleId;
							this.init = false;
						});
				})
		);

		this.subscriptions.push(
			this.store.select(getContainersState).subscribe((containersState) => {
				this.container =
					!environment.platform.containerId ||
					environment.platform.containerId === "defaultContainer" ||
					!containersState.currentContainer
						? containersState.defaultContainer
						: containersState.currentContainer;
			})
		);
		this.subscriptions.push(
			this.store
				.select(getCurrentEvent)
				// .pipe(take(1))
				.subscribe((event) => {
					this.event = event;
					if (!this.passwordView && !this.createPasswordView && !this.firstAccessView) {
						this.changeView(true, false, false, false);
					}
					this.loader = false;
				})
		);
	}

	ngAfterContentChecked() {
		if (this.router.url.includes("login-form")) {
			this.isModal = false;
			this.SUtility.hideMenuAndHeader();
		} else {
			this.isModal = true;
		}
	}

	ngOnDestroy() {
		this.SUtility.showMenuAndHeader();

		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.interval && !this.interval.closed) {
			this.interval.unsubscribe();
		}
	}

	ionViewWillLeave() {
		!this.navigatingToRegisterPage && this.SUtility.showMenuAndHeader();
	}

	/**
	 * First step verif email and send code if necessary
	 */
	async firstStepVerifEmail() {
		const data = this.loginForm.getRawValue();
		try {
			this.activeLoader = true;
			if (data.email) {
				const results: any[] = await firstValueFrom(
					combineLatest([
						this.SAuth.checkAuthAccountExist(data.email, "", "email"),
						this.SAuth.getUserByEmail(data.email.toLowerCase()),
						this.SEventUsers.getEventUserByEmail(data.email.toLowerCase(), this.eventId, "partial"),
						this.store.select(getModulesByType(TypeModule.ATTENDEE))
					])
				);

				const authAccountResult = results[0];
				this.user = results[1];
				this.eventUser = results[2];
				const attendeesModules = results[3];

				if (
					this.eventUser &&
					authAccountResult &&
					authAccountResult.code === 200 &&
					this.user &&
					authAccountResult.result &&
					authAccountResult.result.uid === this.user.uid &&
					authAccountResult.result &&
					authAccountResult.result.email === this.user.email
				) {
					// If user already registered for this event
					if (this.event.settings.visibility) {
						// If there's not default attendee module setted
						if (
							(!this.event.settings.defaultAttendeesModule ||
								this.event.settings.defaultAttendeesModule === "") &&
							attendeesModules.length === 0
						) {
							this.activeLoader = false;
							this.snackbar.open(this.STranslate.instant("snackbar.not_authorized_event"), "", {
								duration: 3000,
								panelClass: "error-snackbar"
							});
							return;
						}

						if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
							this.changeView(true, false, true, false);
							// this.createCodeNumber();
						} else {
							this.changeView(true, false, true, false);
						}

						// Disable email for not changing it
						this.loginForm.get("email").disable();
						// Patch name value if eventUser already exist in the event
						if (this.eventUser.eventId === this.eventId) {
							this.loginForm.get("name").patchValue(this.eventUser.name);
						} else {
							this.addToEventMode = true;
						}

						this.msgError =
							this.eventUser.eventId === this.eventId
								? this.STranslate.instant("login.event_user_already_exist_in_event")
								: this.STranslate.instant("login.account_already_exist_with_email");
					} else {
						// Disable email for not changing it
						this.loginForm.get("email").disable();
						// Change view to email view and password view
						this.changeView(true, false, true, false);
						this.activeLoader = false;
					}
				} else if (this.user && this.eventUser && (!authAccountResult || authAccountResult.code === 204)) {
					if (this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM) {
						this.changeView(false, false, false, true);
						this.createCodeNumber();
					} else {
						this.changeView(false, true, true, false);
					}
				} else if (!this.eventUser) {
					// Event User not found
					this.snackbar.open(this.STranslate.instant("login.not_registered"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.activeLoader = false;
				} else {
					// User not found
					this.snackbar.open(this.STranslate.instant("login.email_not_found"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.activeLoader = false;
				}
			}

			this.activeLoader = false;
		} catch (error) {
			this.activeLoader = false;
		}
	}

	/**
	 * To create account, the user enter your password two times,
	 * check both is equal and add to Firebase Authentication and update in Firestore
	 */
	async createAccount() {
		const data = this.loginForm.getRawValue();
		try {
			this.activeLoader = true;
			if (data.password.length >= 6) {
				if (data.password === data.confirmPassword) {
					if (data.termCheck) {
						if (this.user) {
							await firstValueFrom(this.SAuth.createOnlyAuthAccount(this.user, data.password));
							this.login();
						} else {
							this.activeLoader = false;
							this.snackbar.open(this.STranslate.instant("snackbar.no_user"), "", {
								duration: 3000,
								panelClass: "error-snackbar"
							});
						}
					} else {
						this.activeLoader = false;
						this.snackbar.open(this.STranslate.instant("login.term_check_required"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
					}
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.password_not_match"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_create_account"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Login user
	 */
	login() {
		const data = this.loginForm.getRawValue();
		try {
			this.activeLoader = true;
			if (data.password) {
				if (data.termCheck) {
					this.store.dispatch(InitAuth({ payload: false }));
					this.store.dispatch(InitClaims({ payload: false }));
					this.store.dispatch(InitEvents({ payload: false }));
					this.store.dispatch(InitUser({ payload: false }));
					this.SAuth.loginUser(data.email.toLowerCase(), data.password).subscribe(
						async (user) => {
							if (user && user.user && user.user.uid) {
								this.user = await firstValueFrom(this.SAuth.getUserByEmail(data.email.toLowerCase()));

								if (this.addToEventMode) {
									// If event user doesn't exist in this event (but user account exist)
									// create event user after login for verifying the password

									const matchIds: number = (
										await firstValueFrom(
											this.SEventUsers.getEventUserIdentifierByIdentifier(
												user.user.displayName.toUpperCase(),
												this.eventId
											)
										)
									).length;

									const eventUser: IEventUser = {
										checkins: {
											checkinsChecked: [],
											checkinsNotChecked: []
										},
										createdInApp: true,
										creationDate: DateTime.local().toISO(),
										createMode: "Registration",
										customFields: [],
										description: {
											ArAR: "",
											DeDE: "",
											EsES: "",
											EnUS: "",
											FrFR: "",
											PtBR: ""
										},
										documents: [],
										editedProfile: false,
										email: data.email.toLowerCase(),
										emailRecovery: "",
										eventId: this.event.uid,
										favorites: [],
										firstAccess: false,
										firstAccessDate: "",
										groups: [],
										groupsDatas: [],
										hasFilledRegisterForm: false,
										identifier:
											matchIds > 0
												? `${user.user.displayName.toUpperCase()} - ${matchIds}`
												: user.user.displayName.toUpperCase(),
										isClientAdmin: false,
										moduleId: this.event.settings.defaultAttendeesModule,
										name: user.user.displayName,
										options: {
											chatsNotifs: {},
											feedNewsNotifs: {},
											notifOneSignalConfig: {}
										},
										photoUrl: "",
										points: 0,
										queryName: user.user.displayName.toUpperCase(),
										searchStringDatas: [],
										sessions: [],
										socialMedias: {
											website: "",
											facebook: "",
											instagram: "",
											linkedin: "",
											twitter: ""
										},
										type: TypeUser.ATTENDEE,
										uid: user.user.uid,
										visibility: true
									};

									const newEventUserUpdatedSettings: IEventUserUpdatedSettings = {
										accessModule: {},
										accessModulesArray: [],
										appointmentsSchedules: [],
										connected: true,
										eventId: this.eventId,
										fieldsVisibility: {},
										language: this.event.language,
										lastAccessDate: "",
										lastSeenNotifTime: 0,
										moduleId: this.event.settings.defaultAttendeesModule,
										readNotifications: "",
										timezoneType: "local",
										userId: eventUser && eventUser.uid ? eventUser.uid : "",
										typeUser: eventUser.type
									};

									this.SEventUsers.createEventUser(
										this.event.uid,
										this.event.settings.defaultAttendeesModule,
										eventUser,
										newEventUserUpdatedSettings
									);

									if (this.user) {
										// Security
										this.store.select(getInitAuth).subscribe((init) => {
											if (!init) {
												this.store.dispatch(InitAuth({ payload: true }));
											}
										});
									}
								}

								if (this.user) {
									this.user.connectedPlatformToken = this.SAuth.deviceId ? this.SAuth.deviceId : "";
								}

								if (this.user && !this.user.firstAccess) {
									// Update first access if not true
									this.user.firstAccess = true;
								}
								this.SAuth.updateUser(this.user);

								combineLatest([
									this.store.select(getInitAuth).pipe(skipWhile((init) => init === false)),
									this.store.select(getInitUser).pipe(skipWhile((init) => init === false)),
									this.store.select(getInitEvents).pipe(skipWhile((init) => init === false))
								])
									.pipe(take(1))
									.subscribe(() => {
										this.close();
									});
							} else {
								this.activeLoader = false;
								this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							}
						},
						(error) => {
							this.activeLoader = false;

							if (error.code === "auth/wrong-password") {
								this.snackbar.open(this.STranslate.instant("login.incorrect_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code == "auth/too-many-requests") {
								this.snackbar.open(this.STranslate.instant("login.many_pass_errors"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else if (error.code == "auth/argument-error") {
								this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							} else {
								this.snackbar.open(this.STranslate.instant("snackbar.cannot_login"), "", {
									duration: 3000,
									panelClass: "error-snackbar"
								});
							}
						}
					);
				} else {
					this.activeLoader = false;
					this.snackbar.open(this.STranslate.instant("login.term_check_required"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.invalid_pass"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			this.activeLoader = false;
			this.snackbar.open(this.STranslate.instant("snackbar.cannot_login"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Create code number
	 */
	async createCodeNumber() {
		try {
			const result = await firstValueFrom(this.SAuth.sendCodeNumber(this.user.uid));

			if (result.code === 200 && result.user && result.user.codeNumber) {
				if (this.interval && !this.interval.closed) {
					this.interval.unsubscribe();
				}

				// If sending code ok
				this.codeNumber = result.user.codeNumber;

				this.count = 20;
				this.interval = interval(1000).subscribe(() => {
					const date = new Date(null);
					date.setSeconds(this.count);
					this.timeRemaining = date.toISOString().substring(14, 19);
					this.count--;

					if (this.count === 0) {
						if (this.interval && !this.interval.closed) {
							this.interval.unsubscribe();
						}
						this.snackbar.open(this.STranslate.instant("login.resend_code_number"), "", {
							duration: 3000,
							panelClass: "warning-snackbar"
						});
					}
				});
				this.activeLoader = false;
			} else {
				this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
				this.activeLoader = false;
			}
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
			this.activeLoader = false;
		}
	}

	/**
	 * In case of first access, the user needs to verify your identity
	 * by code number sended to e-mail and create your account
	 */
	async verifyCodeNumber() {
		const data = this.loginForm.getRawValue();
		try {
			if (this.count > 0) {
				if (data.codeNumber) {
					this.activeLoader = true;
					const checkCode = await firstValueFrom(
						this.SAuth.verifyCodeNumber(data.codeNumber?.toString(), this.user.uid)
					);
					if (checkCode) {
						if (this.interval && !this.interval.closed) {
							this.interval.unsubscribe();
						}
						this.changeView(false, true, false, false);
						this.activeLoader = false;
					} else {
						this.snackbar.open(this.STranslate.instant("login.invalid_code"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
						this.activeLoader = false;
					}
				} else {
					this.snackbar.open(this.STranslate.instant("login.invalid_code"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.activeLoader = false;
				}
			} else {
				this.activeLoader = false;
				this.snackbar.open(this.STranslate.instant("login.error_send_email_code"), "", {
					duration: 3000,
					panelClass: "error-snackbar"
				});
			}
		} catch (error) {
			//error("Error login: ", error);
		}
	}

	/**
	 * Recover password
	 * @param email
	 */
	async recoveryPassword() {
		try {
			const email: string = this.loginForm.get("email").value;
			await firstValueFrom(this.SAuth.recoveryPassword(email));
			this.snackbar.open(this.STranslate.instant("login.recovery_success"), "", {
				duration: 3000,
				panelClass: "success-snackbar"
			});
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("login.recovery_fail"), "", {
				duration: 3000,
				panelClass: "error-snackbar"
			});
		}
	}

	/**
	 * Changing view
	 * @param emailView
	 * @param createPasswordView
	 * @param passwordView
	 * @param firstAccessView
	 */
	changeView(emailView: boolean, createPasswordView: boolean, passwordView: boolean, firstAccessView: boolean) {
		if (createPasswordView) {
			if (this.container.loginSettings.passwordSecurityLevel) {
				if (this.container.loginSettings.passwordSecurityLevel === PasswordSecurity.BASIC) {
					if (
						this.loginForm
							.get("password")
							.hasValidator(
								Validators.pattern(
									"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{11,}"
								)
							)
					) {
						this.loginForm
							.get("password")
							.removeValidators(
								Validators.pattern(
									"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{11,}"
								)
							);
					}
					this.loginForm.get("password").addValidators(Validators.minLength(6));
				} else {
					if (this.loginForm.get("password").hasValidator(Validators.minLength(6))) {
						this.loginForm.get("password").removeValidators(Validators.minLength(6));
					}
					this.loginForm
						.get("password")
						.addValidators(
							Validators.pattern(
								"(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-zd$@$!%*?&].{11,}"
							)
						);
				}
			}
		}
		this.emailView = emailView;
		this.createPasswordView = createPasswordView;
		this.passwordView = passwordView;
		this.firstAccessView = firstAccessView;
	}

	/**
	 * Open terms or privacy modal
	 * @param type
	 * @returns
	 */
	async openModalTermsOrPrivacy(type: string) {
		const modal = await this.modalCtrl.create({
			component: type === "privacy" ? PathComponents.privacy : PathComponents.termsOfUse,
			componentProps:
				type === "privacy"
					? {
							privacy: this.container.privacyTerms
					  }
					: {
							terms: this.container.termsOfUse
					  }
		});
		return await modal.present();
	}

	/**
	 * Open register page
	 */
	openRegister() {
		this.navigatingToRegisterPage = true;
		this.activeLoader == false;
		if (this.isModal) {
			this.modalCtrl.dismiss({
				openRegister: true
			});
		} else {
			this.nav.navigateRoot(`/event/${this.eventId}/register-form/${this.moduleId}`);
		}
	}

	/**
	 * Close modal
	 */
	close() {
		this.activeLoader == false;
		if (this.event.settings.requiredSignup && !this.user) {
			return;
		}
		if (this.isModal) {
			this.modalCtrl.dismiss();
		} else {
			this.navigatingToRegisterPage = false;
			this.SUtility.showMenuAndHeader();
			this.nav.navigateRoot(this.event.homePage);
		}
	}

	/**
	 * Close modal and refresh page
	 */
	closeWithReloading() {
		window.location.reload();
		if (this.isModal) {
			this.modalCtrl.dismiss();
		} else {
			this.SUtility.showMenuAndHeader();
			this.nav.navigateRoot(this.event.homePage);
		}
	}
}
