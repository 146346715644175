import { createAction, props } from "@ngrx/store";
import { IVideoPlayerLink, IVideoPlayerSettings, IVideoPlayerState } from "../interfaces/video-player.interfaces";

export enum VideoPlayerActionsTypes {
	GetVideoPlayerState = "[VideoPlayer Service] Update video player full state",
	UpdateMiniVideoPlayer = "[VideoPlayer Service] Update mini video player",
	UpdateMiniVideoPlayerActivated = "[VideoPlayer Service] Update mini video player activated",
	UpdateMiniVideoPlayerState = "[VideoPlayer Service] Update mini video player state",
	UpdateMiniVideoPlayerTime = "[VideoPlayer Service] Update mini video player time",
	UpdateMiniVideoPlayerLink = "[VideoPlayer Service] Update mini video player link",
	UpdateBasicVideoPlayer = "[VideoPlayer Service] Update basic video player",
	UpdateBasicVideoPlayerState = "[VideoPlayer Service] Update basic video player state",
	UpdateBasicVideoPlayerTime = "[VideoPlayer Service] Update basic video player time",
	UpdateBasicVideoPlayerLink = "[VideoPlayer Service] Update video player link",
	ResetStateVideoPlayer = "[VideoPlayer Service] Reset state video player"
}

export const GetVideoPlayer = createAction(
	VideoPlayerActionsTypes.GetVideoPlayerState,
	props<{ payload: IVideoPlayerState }>()
);
export const UpdateMiniVideoPlayer = createAction(
	VideoPlayerActionsTypes.UpdateMiniVideoPlayer,
	props<{ payload: IVideoPlayerSettings }>()
);
export const UpdateMiniVideoPlayerActivated = createAction(
	VideoPlayerActionsTypes.UpdateMiniVideoPlayerActivated,
	props<{ payload: boolean }>()
);
export const UpdateMiniVideoPlayerState = createAction(
	VideoPlayerActionsTypes.UpdateMiniVideoPlayerState,
	props<{ payload: number }>()
);
export const UpdateMiniVideoPlayerTime = createAction(
	VideoPlayerActionsTypes.UpdateMiniVideoPlayerTime,
	props<{ payload: number }>()
);
export const UpdateMiniVideoPlayerLink = createAction(
	VideoPlayerActionsTypes.UpdateMiniVideoPlayerLink,
	props<{ payload: IVideoPlayerLink }>()
);
export const UpdateBasicVideoPlayer = createAction(
	VideoPlayerActionsTypes.UpdateBasicVideoPlayer,
	props<{ payload: IVideoPlayerSettings }>()
);
export const UpdateBasicVideoPlayerState = createAction(
	VideoPlayerActionsTypes.UpdateBasicVideoPlayerState,
	props<{ payload: number }>()
);
export const UpdateBasicVideoPlayerTime = createAction(
	VideoPlayerActionsTypes.UpdateBasicVideoPlayerTime,
	props<{ payload: number }>()
);
export const UpdateBasicVideoPlayerLink = createAction(
	VideoPlayerActionsTypes.UpdateBasicVideoPlayerLink,
	props<{ payload: IVideoPlayerLink }>()
);

export const ResetStateVideoPlayer = createAction(VideoPlayerActionsTypes.ResetStateVideoPlayer, props<any>());
