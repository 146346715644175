/**
 * By default it was set to use the "Europe / Paris" zone as the default for all dates. That goes for Api too.
    backoffice
 */

import { Injectable } from "@angular/core";
import { DateTime, Settings } from "luxon";
import { IDate } from "src/app/shared/interfaces/date.interfaces";
import { environment } from "../../../environments/environment";

@Injectable()
export class LuxonService {
	constructor() {
		//  Settings.defaultZone = "Europe/Paris"
		// Settings.defaultZone = "America/Sao_Paulo"
		Settings.defaultZone = environment.platform.defaultTimezone;
	}

	/* cria data com a fuso horário do timezane 
    
    return object DateTime
    */
	createDate(year: string, month: string, day: string, hour: string, minute: string, seconds: string) {
		const aux = year + "-" + month + "-" + day + "T" + hour + ":" + minute + ":" + seconds;
		return DateTime.fromISO(aux);
	}

	/**
	 * Convert a timestamp seconds to iso string date
	 * @param timestamp
	 */
	convertTimestampToISo(timestamp: number) {
		return DateTime.fromSeconds(timestamp).toISO();
	}

	// cria timestamp
	createTimeStamp(date: DateTime) {
		return date.valueOf() / 1000;
	}

	// converte timestamp para DateTime
	convertTimestampToDate(timestamp: number) {
		const aux = DateTime.fromMillis(timestamp * 1000);
		return aux;
	}

	// converte um  DateTime para uma string do formato dd/mm/aaaa
	convertDateToStringIsNotUSA(date: DateTime): any {
		return date.toLocaleString({
			day: "numeric",
			month: "numeric",
			year: "numeric"
		});
	}

	// converte um DateTime para uuma string com em português do Brasil
	convertDateToStringTitlePortBrazil(date: DateTime) {
		const f = { year: "numeric", month: "long", day: "numeric" };
		return date.setLocale("pt-BR").toLocaleString();
	}

	// converte um  DateTime para uma string do formato dd/mm/aaaa
	convertDateToStringIsUSA(date: DateTime): any {
		const year = date.year;
		const month = date.month;
		const day = date.day;

		return month + "/" + day + "/" + year;
	}

	// converte um DateTime em uma string no formato yyyy-mm-aa
	convertDateToStringYearMonthDay(date: DateTime) {
		return date.toISODate();
	}

	// retorna a hora e o minuto da data
	dateTime(date: DateTime) {
		return date.toLocaleString({
			hour: "numeric",
			minute: "numeric",
			second: "numeric"
		});
	}

	// retorna a hora, inuto e os segundos da data.
	dateTimeWithSeconds(date: DateTime) {
		return date.toLocaleString({
			hour: "numeric",
			minute: "numeric",
			second: "numeric"
		});
	}

	dateTimeUSA(date: DateTime): any {
		const aux = date.setLocale("en-US");
		return aux.toLocaleString(DateTime.TIME_SIMPLE);
	}

	// return current time
	currentTime(): DateTime {
		const now = DateTime.local();
		return now;
	}

	/**
	 * Create a DateTime object from IDate object
	 * @param date
	 */
	createDateTime(date: IDate): DateTime {
		if (date.zone == "local") {
			return DateTime.local(
				date.year,
				date.month,
				date.day,
				date.hour,
				date.minute,
				date.second,
				date.millisecond
			);
		} else {
			return DateTime.fromObject(date);
		}
	}

	/**
	 * Return formated date base on the format passed
	 * @param date
	 * @param format
	 */
	formatDate(date: DateTime, format: string, locale: string) {
		return date.setLocale(locale).toFormat(format);
	}

	/**
	 * Get iso from IDate object
	 * @param date
	 */
	getIsoFromDate(date: IDate) {
		if (date.zone == "local") {
			return DateTime.local(
				date.year,
				date.month,
				date.day,
				date.hour,
				date.minute,
				date.second,
				date.millisecond
			).toISO();
		} else {
			return DateTime.fromObject(date).toISO();
		}
	}

	/**
	 * Get timestamp from IDate object
	 * @param date
	 */
	getTimestampFromDate(date: IDate) {
		if (date.zone == "local") {
			return (
				DateTime.local(
					date.year,
					date.month,
					date.day,
					date.hour,
					date.minute,
					date.second,
					date.millisecond
				).valueOf() / 1000
			);
		} else {
			return DateTime.fromObject(date).valueOf() / 1000;
		}
	}

	/**
	 * Get string date from IDate object
	 * @param date
	 */
	getStringDateFromDate(date: IDate) {
		if (date.zone == "local") {
			return DateTime.local(
				date.year,
				date.month,
				date.day,
				date.hour,
				date.minute,
				date.second,
				date.millisecond
			).toFormat("yyyy-MM-dd");
		} else {
			return DateTime.fromObject(date).toFormat("yyyy-MM-dd");
		}
	}

	/**
	 * Get string time from IDate object
	 * @param date
	 */
	getStringTimeFromDate(date: IDate) {
		if (date.zone == "local") {
			return DateTime.local(
				date.year,
				date.month,
				date.day,
				date.hour,
				date.minute,
				date.second,
				date.millisecond
			).toFormat("HH:mm:ss.SSS");
		} else {
			return DateTime.fromObject(date).toFormat("HH:mm:ss.SSS");
		}
	}

	/**
	 * Get gmt date
	 * @param date
	 */
	getGmtDate(date: string) {
		return DateTime.fromISO(date).toFormat("yyyy-MM-ddTHH:mm:ssZ");
	}

	/**
	 * Get IDate date from iso
	 * @param isoDate
	 */
	getIDateFromIso(isoDate: string, zone: string) {
		const date = DateTime.fromISO(isoDate);
		return {
			year: date.year,
			month: date.month,
			day: date.day,
			hour: date.hour,
			minute: date.minute,
			second: date.second,
			millisecond: date.millisecond,
			zone: zone
		};
	}

	/**
	 * Get string date from iso
	 * @param isoDate
	 * @param zone
	 */
	getStringDateFromIso(isoDate: string, zone: string) {
		if (zone === "local") {
			const date = DateTime.fromISO(isoDate);
			return DateTime.local(
				date.year,
				date.month,
				date.day,
				date.hour,
				date.minute,
				date.second,
				date.millisecond
			).toFormat("yyyy-MM-dd");
		} else {
			return DateTime.fromISO(isoDate).toFormat("yyyy-MM-dd");
		}
	}

	/**
	 * convertIsoDateToFormat
	 * @param isoDate
	 * @param language
	 * @description Convert iso date to X format based on the language/locale
	 * @returns
	 */
	convertIsoDateToFormat(isoDate: string, language: string) {
		const locale = language.slice(0, 2).toLowerCase() + "-" + language.slice(2, 4).toUpperCase();
		return locale
			? DateTime.fromISO(isoDate).setLocale(locale).toLocaleString()
			: DateTime.fromISO(isoDate).setLocale("FR").toLocaleString();
	}
}
