<div class="background" [style.width]="'100% !important'" [style.padding]="isMobile ? '0' : '0 15% !important'">
	<ion-list *ngIf="!loader && event && module && rankings.length > 0">
		<ion-item
			lines="none"
			class="ranking-item"
			[style.border-bottom-color]="event.styling.titleColor + '1C'"
			*ngFor="
				let ranking of rankings
					| sortList : (module ? module.options.rankingsListOrder : 'asc') : currentLanguage
			"
			(click)="navigateTo(ranking)"
		>
			<ion-avatar
				item-start
				[style.background]="event.styling.menuColor"
				[style.color]="event.styling.menuTextColor"
			>
				<ion-icon src="assets/icon/Star.svg"></ion-icon>
			</ion-avatar>
			<ion-label class="spaced" [style.color]="event.styling.titleColor">
				<h2>{{ ranking.name[currentLanguage] }}</h2>
			</ion-label>
		</ion-item>
	</ion-list>

	<div *ngIf="!loader && event && module && rankings.length === 0" class="container">
		<span class="wait-content" [style.color]="event.styling.titleColor">{{
			"texts.waiting_for_content" | translate
		}}</span>
		<ion-icon
			src="assets/images/emptycontent/PARTICIPANTS.svg"
			[style.color]="
				event.styling.menuColor.includes('linear')
					? event.styling.menuColor.split(',')[1]
					: event.styling.menuColor
			"
		>
		</ion-icon>
		<div class="text-empty-content" [style.color]="event.styling.contentTextColor">
			<span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
			<span>{{ "interactivity.default_empty_content_text" | translate }}</span>
		</div>
	</div>

	<div *ngIf="loader || !event || !module">
		<ion-item *ngFor="let item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
			<ion-avatar slot="start">
				<ion-skeleton-text animated></ion-skeleton-text>
			</ion-avatar>
			<ion-label>
				<h2>
					<ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
				</h2>
				<p>
					<ion-skeleton-text animated style="width: 20%"></ion-skeleton-text>
				</p>
			</ion-label>
		</ion-item>
	</div>
</div>
