import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IContainersState } from "../interfaces/containers.interfaces";

export const getContainersState = createFeatureSelector<IContainersState>("containers");

export const getCurrentContainer = createSelector(getContainersState, (state: IContainersState) =>
	_.cloneDeep(state.currentContainer)
);

export const getDefaultContainer = createSelector(getContainersState, (state: IContainersState) =>
	_.cloneDeep(state.defaultContainer)
);
