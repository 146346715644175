import { createReducer, on } from "@ngrx/store";
import * as _ from "lodash-es";
import * as GenericsModulesDataActions from "../actions/generics-modules-data.actions";
import { IGenericsModulesData } from "../interfaces/states.interfaces";

export const initialState: IGenericsModulesData = {
	baseCustomFields: {
		baseCustomFields: [],
		eventId: ""
	},
	modulesCustomFields: {
		modulesCustomFields: [],
		eventId: ""
	},
	feedbacks: {
		eventId: "",
		feedbacks: [],
		feedbacksResults: []
	},
	groups: {
		eventId: "",
		datasDocs: [],
		datas: []
	},
	locations: {
		eventId: "",
		datasDocs: [],
		datas: []
	},
	notifications: {
		eventId: "",
		notifications: []
	},
	tracks: {
		eventId: "",
		datasDocs: [],
		datas: []
	},
	favoritesFolders: {
		eventId: "",
		folders: []
	}
};

export const genericsModulesDataFeatureKey = "genericsModulesData";

export const genericsModulesDataReducers = createReducer(
	initialState,
	on(GenericsModulesDataActions.GetAllBaseCustomFields, (state, action) => ({
		...state,
		baseCustomFields: {
			eventId: action.eventId,
			baseCustomFields: action.payload
		}
	})),
	on(GenericsModulesDataActions.GetAllModulesCustomFields, (state, action) => ({
		...state,
		modulesCustomFields: {
			eventId: action.eventId,
			modulesCustomFields: action.payload
		}
	})),
	on(GenericsModulesDataActions.GetAllFeedbacks, (state, action) => ({
		...state,
		feedbacks: {
			...state.feedbacks,
			eventId: action.eventId,
			feedbacks: action.payload
		}
	})),
	on(GenericsModulesDataActions.GetAllFeedbacksResults, (state, action) => ({
		...state,
		feedbacks: {
			...state.feedbacks,
			feedbacksResults: action.payload
		}
	})),
	on(GenericsModulesDataActions.GetAllGroups, (state, action) => {
		const datas = _.flatten(
			action.payload.map((datasDocs) =>
				datasDocs && datasDocs.datas && datasDocs.datas.length > 0 ? datasDocs.datas : []
			)
		);
		return {
			...state,
			groups: {
				eventId: action.eventId,
				datasDocs: action.payload,
				datas: datas
			}
		};
	}),
	on(GenericsModulesDataActions.GetAllLocations, (state, action) => {
		const datas = _.flatten(
			action.payload.map((datasDocs) =>
				datasDocs && datasDocs.datas && datasDocs.datas.length > 0 ? datasDocs.datas : []
			)
		);
		return {
			...state,
			locations: {
				eventId: action.eventId,
				datasDocs: action.payload,
				datas: datas
			}
		};
	}),
	on(GenericsModulesDataActions.GetAllNotifications, (state, action) => ({
		...state,
		notifications: {
			eventId: action.eventId,
			notifications: action.payload
		}
	})),
	on(GenericsModulesDataActions.GetAllTracks, (state, action) => {
		const datas = _.flatten(
			action.payload.map((datasDocs) =>
				datasDocs && datasDocs.datas && datasDocs.datas.length > 0 ? datasDocs.datas : []
			)
		);
		return {
			...state,
			tracks: {
				eventId: action.eventId,
				datasDocs: action.payload,
				datas: datas
			}
		};
	}),
	on(GenericsModulesDataActions.GetAllFavoritesFolders, (state, action) => ({
		...state,
		favoritesFolders: {
			eventId: action.eventId,
			folders: action.payload
		}
	})),
	on(GenericsModulesDataActions.ResetGenericDatasState, () => ({
		...initialState
	}))
);
