<ion-content class="page">
    <div *ngIf="event && eventUser" class="background" [style.padding-bottom]="isMobile ? '80px' : '50px'">
        <ion-list *ngIf="event && module && checkins.length > 0" class="f-col-c-fs">
            <ion-card *ngFor="let checkin of checkins | sortList:(module ? module.options.checkinsListOrder : 'asc')"
                (click)="openCheckin(checkin)" [style.width]="isMobile ? '100%' : '95%'"
                class="f-row-c-sb cursor-pointer hover-scale">
                <qrcode id="{{ moduleId + '+' + checkin.uid }}" [qrdata]="moduleId + '+' + checkin.uid"
                    [width]="isMobile ? 60 : 120">
                </qrcode>
                <ion-card-header [style.max-width]="isMobile ? '50%' : '75%'">
                    <ion-card-title [style.font-size]="isMobile ? '20px' : '25px'"
                        [style.color]="event.styling.titleColor">
                        {{ checkin.name }}
                    </ion-card-title>
                </ion-card-header>
                <ion-card-content class="f-row-c-c" [style.padding]="'0 13px 0 0'">
                    <ion-icon [class.icons-25]="isMobile" [class.icons-30]="!isMobile"
                        [style.color]="event.styling.btnBgColor" name="enter-outline">
                    </ion-icon>
                </ion-card-content>
            </ion-card>
        </ion-list>

        <div *ngIf="event && module && checkins.length === 0" class="container">
            <span class="wait-content" [style.color]="event.styling.titleColor">{{
                "texts.waiting_for_content" | translate
                }}</span>
            <ion-icon src="assets/images/emptycontent/PARTICIPANTS.svg" [style.color]="
        							event.styling.menuColor.includes('linear')
        								? event.styling.menuColor.split(',')[1]
        								: event.styling.menuColor">
            </ion-icon>
            <div class="text-empty-content" [style.color]="event.styling.contentTextColor">
                <span class="title"> {{ "interactivity.default_empty_content_title" | translate }}</span>
                <span>{{ "interactivity.default_empty_content_text" | translate }}</span>
            </div>
        </div>

        <ng-container *ngIf="!event || !module">
            <ion-item lines="none" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
                <ion-label>
                    <h3>
                        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                    </h3>
                </ion-label>
            </ion-item>
        </ng-container>
    </div>
    <app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
</ion-content>