<div *ngIf="event && module && externalInteractivity" class="background"
    [style.height]="isMobile ? 'calc(100vh - 50px)' : '100%'" [class.full-w]="componentMode"
    [style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'">
    <ion-row *ngIf="externalInteractivity && componentMode && showBackBtn">
        <ion-icon class="icons-25" name="arrow-back" [style.color]="event.styling.titleColor" (click)="goBackToList()">
        </ion-icon>
    </ion-row>

    <div [class.full-w]="componentMode" style="height:100%">
        <div style="padding: 30px 15px; height: 100%">
            <div *ngIf="!externalInteractivity.type || externalInteractivity.type === 'editor'"
                [innerHTML]="externalInteractivity.htmlContent[currentLanguage] | safeHtml"
                style="margin-left: 0px; padding-left: 0px; margin: 0px; width: 100%;">
            </div>

            <iframe *ngIf="externalInteractivity.type && externalInteractivity.type === 'url'"
                [src]="externalInteractivity.url | safeHtml: 'resourceUrl'" frameborder="0"
                style="margin-left: 0px; padding-left: 0px; margin: 0px; width: 100%;" height="100%">
            </iframe>
        </div>
    </div>
</div>

<!-- <app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons> -->