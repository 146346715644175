import { createReducer, on } from "@ngrx/store";
import * as CustomPagesActions from "../actions/custom-pages.actions";
import { ICustomPagesState } from "../interfaces/custom-pages.interfaces";

export const initialState: ICustomPagesState = {
	eventId: "",
	customPages: []
};

export const customPagesFeatureKey = "customPages";

export const customPagesReducers = createReducer(
	initialState,
	on(CustomPagesActions.GetAllCustomPages, (state, action) => ({
		...state,
		eventId: action.eventId,
		customPages: action.payload
	})),
	on(CustomPagesActions.ResetCustomPagesState, () => ({
		...initialState
	}))
);
