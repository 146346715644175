<ion-content fullscreen>

    <ion-item class="close-fake" lines="none" text-center>
        <ion-buttons slot="start">
            <ion-button (click)="close()" fill="clear" color="light">
                <ion-icon src="assets/icon/Close.svg" slot="start"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-buttons slot="end">
            <ion-button (click)="!zoomed ? zoom(true) : ''" fill="clear" color="light">
                <ion-icon name="add" slot="start"></ion-icon>
            </ion-button>

            <ion-button fill="clear" (click)="zoomed ? zoom(false) : ''">
                <ion-icon class="habilited" name="remove" slot="start"></ion-icon>
            </ion-button>
            <!-- <ion-button fill="clear" disabled *ngIf="!zoomer">
                <ion-icon class="blocked" name="remove" slot="start" *ngIf="!zoomer"></ion-icon>
            </ion-button> -->
        </ion-buttons>
    </ion-item>

    <app-pinch-zoom [style.height]="'calc(100% - 140px)'" style="width: 100%;" [zoomChanged]="zoomed"
        [buttonEnabled]="false" [wheel]="false">
        <ng-container class="background">
            <img style="height: auto; width: auto; object-fit: cover;" [src]="url">
        </ng-container>
    </app-pinch-zoom>

    <!-- <lib-ngx-image-zoom style="width: 100%;" [style.height]="'calc(100% - 100px)'" [thumbImage]="url"
        [zoomMode]="'toggle'" [magnification]="5" [maxZoomRatio]="5"></lib-ngx-image-zoom> -->

    <div
        style="position: fixed; bottom: 10px; right: 10px; display: flex; flex-direction: row; align-items: center; justify-content: center; z-index: 999999999">
        <ion-button fill="clear" *ngIf="allowDownload && !isMobile" (click)="download()">
            <ion-icon ion-fab name="cloud-download" size="large" *ngIf="!downloading" class="habilited"></ion-icon>
            <ion-spinner name="crescent" *ngIf="downloading"></ion-spinner>
        </ion-button>
        <ion-button fill="clear" *ngIf="allowDownload && isMobile" (click)="share()">
            <ion-icon ion-fab name="share-outline" size="large" class="habilited"></ion-icon>
        </ion-button>
    </div>
</ion-content>