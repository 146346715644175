import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { DomSanitizer } from "@angular/platform-browser";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { combineLatest, Observable, of, Subscription } from "rxjs";
import { auditTime, map, switchMap, take } from "rxjs/operators";
import { IEvent } from "src/app/shared/interfaces/events.interfaces";
import { IUser } from "src/app/shared/interfaces/user.interfaces";
import { GetAllEvents, GetEvent, GetMyEvents } from "../actions/events.actions";
import { GetCustomFont, InitEvents, InitSpecificEventDatasPart } from "../actions/utility.actions";
import { TypeLogin } from "../enums/type-login";
import { IContainer } from "../interfaces";
import { checkSameEventId } from "../selectors/events.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class EventsService {
	initSub: Subscription;
	getEventsSubscriptions: Subscription;
	getCurrentEventSub: Subscription;
	eventsSub: Subscription;

	currentUser: IUser;
	currentContainer: IContainer;

	previousEvents: {
		eventUsersEventIds: string[];
		events: IEvent[];
	};

	logo: string = "";
	loaderColor: string = "";
	loaderBackgroundColor: string = "";
	openAdsModalOpened = false;

	constructor(private SFirestore: FirestoreService, private store: Store, private sanitizer: DomSanitizer) {}

	unsubscribeAll() {
		if (this.eventsSub && !this.eventsSub.closed) {
			this.eventsSub.unsubscribe();
		}

		if (this.getCurrentEventSub && !this.getCurrentEventSub.closed) {
			this.getCurrentEventSub.unsubscribe();
		}
	}

	/**
	 * Init events
	 * @param containerType
	 * @param container
	 * @param user
	 */
	initEvents(containerType: string, container: IContainer, user: IUser) {
		if (container) {
			if (_.isEqual(container, this.currentContainer) && _.isEqual(user, this.currentUser)) {
				return;
			} else if (this.eventsSub && !this.eventsSub.closed) {
				this.eventsSub.unsubscribe();
			}

			this.store.dispatch(InitEvents({ payload: false }));

			let eventsObs: Observable<{ eventUsersEventIds: string[]; events: IEvent[] }>;
			if (
				container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM ||
				container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM
			) {
				// For container with or without email confirm
				if (user) {
					eventsObs = this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
						where("uid", "==", user.uid)
					]).pipe(
						switchMap((eventUsers) => {
							if (eventUsers.length === 0) {
								return of({
									eventUsersEventIds: [],
									events: []
								});
							}
							const eventUsersEventIds = _.uniq(eventUsers.map((eventUser) => eventUser.eventId));
							const chunkedEventIds = _.chunk(eventUsersEventIds, 10);
							const eventsObs: Observable<any[]>[] = [];
							chunkedEventIds.forEach((chunk) => {
								eventsObs.push(
									this.SFirestore.valueChangesDocuments(
										"events",
										containerType === "client" && container.type === "independant"
											? [
													where("clientId", "==", container.client.clientId),
													where("archived", "==", false),
													where("uid", "in", chunk)
											  ]
											: [where("archived", "==", false), where("uid", "in", chunk)]
									)
								);
							});
							return combineLatest(eventsObs).pipe(
								switchMap((results: IEvent[][]) => {
									return of({
										eventUsersEventIds: eventUsersEventIds,
										events: _.flatten(results)
									});
								})
							);
						})
					);
				} else {
					if (container.loginSettings.showAccessPublicEventsBtn || container.loginSettings.showShortCodeBtn) {
						/**
						 * If not user take only public events if container allow it
						 */
						eventsObs = this.SFirestore.valueChangesDocuments(
							"events",
							containerType === "client" && container.type === "independant"
								? [
										where("settings.visibility", "==", true),
										where("clientId", "==", container.client.clientId),
										where("archived", "==", false)
								  ]
								: [where("settings.visibility", "==", true), where("archived", "==", false)]
						).pipe(
							switchMap((events) => {
								return of({
									eventUsersEventIds: [],
									events: events
								});
							})
						);
					} else {
						/**
						 * If no user and not allowed return empty
						 */
						eventsObs = of({
							eventUsersEventIds: [],
							events: []
						});
					}
				}
			} else if (container.loginSettings.type === TypeLogin.PUBLIC_EVENT_HOME) {
				/**
				 * Case multiple public events home page get all these events
				 */
				eventsObs = combineLatest(
					container.loginSettings.publicEventsIds.map((eventId) => {
						return this.SFirestore.valueChangesDocument(`events/${eventId}`);
					})
				).pipe(
					switchMap((events) => {
						return of({
							eventUsersEventIds: [],
							events: events.filter((event) => !event.archived)
						});
					})
				);
			} else if (container.loginSettings.type === TypeLogin.WITH_SHORTCODE) {
				/**
				 * If shortcode login type don't take event (connection to one event with shortcode)
				 */
				eventsObs = of({
					eventUsersEventIds: [],
					events: []
				});
			}

			this.eventsSub = eventsObs.subscribe({
				next: (results) => {
					if (!_.isEqual(this.previousEvents, results)) {
						this.previousEvents = results;
						this.store.dispatch(
							GetMyEvents({
								payload:
									results.eventUsersEventIds && results.eventUsersEventIds.length > 0
										? results.events.filter((event) =>
												results.eventUsersEventIds.includes(event.uid)
										  )
										: []
							})
						);

						this.store.dispatch(
							GetAllEvents({
								payload: results.events && results.events.length > 0 ? results.events : []
							})
						);

						this.currentContainer = container;
						this.currentUser = user;
					}
					// Init down
					this.store.dispatch(InitEvents({ payload: true }));
				},
				error: () => {
					//
				}
			});
		}
	}

	/**
	 * Get event on store
	 * @param eventId
	 */
	getCurrentEventOnStore(eventId: string) {
		this.store
			.select(checkSameEventId(eventId))
			.pipe(auditTime(200), take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.getCurrentEventSub && !this.getCurrentEventSub.closed) {
					return;
				} else if (!sameEvent && this.getCurrentEventSub && !this.getCurrentEventSub.closed) {
					this.getCurrentEventSub.unsubscribe();
				}

				this.getCurrentEventSub = this.SFirestore.valueChangesDocument(`events/${eventId}`).subscribe(
					(event: IEvent) => {
						// Init custom event poperties styling
						this.logo = event.identity ? event.identity.logo : "";
						if (event.styling) {
							this.loaderColor = event.styling.menuColor;
							this.loaderBackgroundColor = event.styling.menuTextColor;
							document.body.style.setProperty("--bg-content-color", event.styling.bgContentColor);
							document.body.style.setProperty("--bg-general-color", event.styling.bgGeneralColor);
							document.body.style.setProperty("--btn-bg-color", event.styling.btnBgColor);
							document.body.style.setProperty("--btn-text-color", event.styling.btnTextColor);
							document.body.style.setProperty("--content-text-color", event.styling.contentTextColor);
							document.body.style.setProperty("--default-color", event.styling.defaultColor);
							document.body.style.setProperty(
								"--default-color-contrast",
								event.styling.defaultColorContrast
							);
							document.body.style.setProperty("--default-color-shade", event.styling.defaultColorShade);
							document.body.style.setProperty("--default-color-tint", event.styling.defaultColorTint);
							document.body.style.setProperty("--link-color", event.styling.linkColor);
							document.body.style.setProperty("--menu-active-color", event.styling.menuActiveColor);
							document.body.style.setProperty(
								"--menu-color",
								event.styling.allowGradient
									? `linear-gradient(to right,
										${event.styling.menuColor}, ${event.styling.menuColorGradient})`
									: event.styling.menuColor
							);
							document.body.style.setProperty("--menu-color-gradient", event.styling.menuColorGradient);
							document.body.style.setProperty("--menu-text-color", event.styling.menuTextColor);
							document.body.style.setProperty(
								"--menu-text-color-gradient",
								event.styling.menuTextColorGradient
							);
							document.body.style.setProperty("--title-color", event.styling.titleColor);
							document.body.style.setProperty(
								"--bg-header",
								event.styling.allowGradient
									? `linear-gradient(to right,
										${event.styling.menuColor}, ${event.styling.menuColorGradient})`
									: event.styling.menuColor
							);
							document.body.style.setProperty(`--btn-validate-primary-color`, event.styling.btnTextColor);
							document.body.style.setProperty(
								`--btn-validate-primary-bg-color`,
								event.styling.btnBgColor
							);
							if (event.styling.font) {
								const customFont = this.sanitizer.bypassSecurityTrustResourceUrl(
									`https://fonts.googleapis.com/css?family=${event.styling.font}:300,400,600,700`
								);
								document.body.style.setProperty(
									"--font",
									`'${event.styling.font.split("+").join(" ")}'`
								);
								this.store.dispatch(GetCustomFont({ payload: customFont }));
							} else {
								this.store.dispatch(GetCustomFont({ payload: null }));
							}
						} else {
							this.store.dispatch(GetCustomFont({ payload: null }));
						}
						this.store.dispatch(GetEvent({ payload: event ? event : null }));

						this.store
							.select(getInitSpecificEventDatasPart("initEvent"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initEvent", payload: true })
									);
								}
							});
					}
				);
			});
	}

	getSpecificEvent(eventId: string) {
		return this.SFirestore.getDocumentObs(`events/${eventId}`).pipe(map((snapshot) => snapshot.data() as IEvent));
	}

	/**
	 * Get event by shortcode
	 * @param shortcode
	 * @returns
	 */
	getEventByShortcode(shortcode: string): Observable<IEvent | null> {
		return this.SFirestore.getDocumentsObs(`events`, [where("shortcode", "==", shortcode)]).pipe(
			switchMap((results) => {
				if (results.size > 0) {
					const event = results.docs[0].data();
					// this.getCurrentEventOnStore(event.uid);
					return of(event);
				} else {
					return of(null);
				}
			})
		);
	}
}
