import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup } from "@angular/forms";
import { IRegisterFormItem } from "src/app/shared/interfaces/register-form.interfaces";

@Component({
	selector: "form-field-icons",
	templateUrl: "./form-field-icons.component.html",
	styleUrls: ["./form-field-icons.component.scss"]
})
export class FormFieldIconsComponent {
	@Input() fieldId: string;
	@Input() form: UntypedFormGroup | AbstractControl;
	@Input() language: string;
	@Input() fieldType: "singleLanguage" | "multiLanguage";

	constructor() {}
}
