import { createAction, props } from "@ngrx/store";
import { IPost } from "../interfaces/feed-news.interfaces";

export enum FeedNewsActionsTypes {
	GetAllPosts = "[FeedNews Service] Get all posts",
	ResetFeedNewsState = "[FeedNews Service] Reset feed news state"
}

export const GetAllPosts = createAction(
	FeedNewsActionsTypes.GetAllPosts,
	props<{ payload: IPost[]; eventId: string }>()
);

export const ResetFeedNewsState = createAction(FeedNewsActionsTypes.ResetFeedNewsState, props<any>());
