<!-- <ion-content class="page" style="min-height: calc(100vh - 56px);"> -->
<div *ngIf="event && module && survey && eventUser" class="background" [class.full-w]="componentMode" [style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'">
    <!-- <div class="align-loader-custom" *ngIf="!event || !module || !survey || !eventUser">
        <div class="loader-default"></div>
    </div> -->

    <ion-row *ngIf="survey && componentMode && showBackBtn">
        <ion-icon class="icons-25" name="arrow-back" [style.color]="event.styling.titleColor" (click)="goBackToList()">
        </ion-icon>
    </ion-row>

    <form [formGroup]="questionsForm" style="background-color: #fff;">
        <div *ngFor="let question of survey.questions; let i = index" style="padding-top: 10px;">
            <div *ngIf="question.visibility"
                [ngStyle]="{'border-bottom': i !== survey.questions.length - 1 ? '1px solid ' + event.styling.titleColor + '4D' : 'none', 'padding-bottom': '10px'}">
                <ion-label *ngIf="question.optionalName && question?.optionalName[currentLanguage]"
                    class="question-title-main ion-text-wrap ion-text-left" style="width:100%; margin-left: 10px;"
                    [style.color]="event.styling.titleColor">
                    {{ question.optionalName[currentLanguage] }}
                </ion-label>
                <div [class.evaluation-box]="question.type === 'evaluation' || question.type === 'document'">
                    <div class="question-title-infobooth">
                        <p class="question-title ion-text-wrap" style="width:100%;"
                            [style.color]="event.styling.titleColor">
                            {{ question.name[currentLanguage] }}
                        </p>

                        <div class="infobooth" *ngIf="question.description && question.description[currentLanguage]">
                            <small [style.color]="event.styling.contentTextColor">
                                <p [innerHTML]="question.description[currentLanguage] | safeHtml">
                                </p>
                            </small>
                        </div>
                    </div>
                    <div class="questions-to-user" *ngIf="question.type === 'oneSelect'">
                        <ion-list>
                            <ion-radio-group [style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
                                [formControlName]="question.uid" [name]="question.uid">
                                <ion-item class="justify-text" *ngFor="let option of question.answers; let ind = index"
                                    [lines]="ind !== question.answers.length - 1 ? 'full':'none'">
                                    <ion-label class="option-size ion-text-wrap"
                                        [style.color]="event.styling.contentTextColor">
                                        {{ option.answer[currentLanguage]}}
                                    </ion-label>
                                    <ion-radio legacy="true" [disabled]="questionsForm.get(question.uid).disabled"
                                        [value]="option.uid">
                                        <!-- <span [style.color]="event.styling.contentTextColor">
                                            {{ option.answer[currentLanguage] }}
                                        </span> -->
                                    </ion-radio>
                                </ion-item>
                            </ion-radio-group>
                        </ion-list>
                    </div>

                    <div class="questions-to-user" *ngIf="question.type === 'multipleSelect'">
                        <ion-list>
                            <div *ngFor="let option of question.answers; let ind = index">
                                <ion-item class="justify-text"
                                    [lines]="ind !== question.answers.length - 1 ? 'full':'none'">
                                    <ion-label class="option-size ion-text-wrap"
                                        [style.color]="event.styling.contentTextColor">
                                        {{ option.answer[currentLanguage] }}
                                    </ion-label>
                                    <ion-checkbox [disabled]="!checkCanChangeAnswer(question)"
                                        [checked]="checkCheckbox(question, option)" mode="md"
                                        (ionChange)="updateCheckboxs(question, option, $event)">
                                    </ion-checkbox>
                                </ion-item>
                            </div>
                        </ion-list>
                    </div>

                    <div class="justify-text evaluation" lines="none" *ngIf="question.type === 'evaluation'">
                        <div class="evaluation-align">
                            <ngx-star-rating [disabled]="!checkCanChangeAnswer(question)"
                                [style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
                                [formControlName]="question.uid" [id]="question.uid">
                            </ngx-star-rating>
                        </div>
                    </div>

                    <div class="questions-to-user" *ngIf="question.type === 'dissertative'">
                        <ion-list>
                            <ion-item class="ion-text-wrap" lines="none">
                                <ion-textarea autocapitalize=sentences
                                    [style.opacity]="checkCanChangeAnswer(question) ? 1 : 0.7"
                                    [formControlName]="question.uid" [name]="question.uid"
                                    placeholder="{{ 'surveys.answer_plc' | translate }}" style="--padding-start: 10px;">
                                </ion-textarea>
                            </ion-item>
                        </ion-list>
                    </div>

                    <div class="questions-to-user" *ngIf="question.type === 'date'">
                        <!-- <ion-item class="ion-text-wrap" lines="none"> -->
                        <ion-datetime style="margin: auto" presentation="date" [formControlName]="question.uid"
                            [name]="question.uid" min="1900-01-01" max="2050-01-01" [locale]="locale">
                        </ion-datetime>
                        <!-- </ion-item> -->
                    </div>

                    <!-- Document survey -->
                    <div style="margin:auto" class="questions-to-user document-type-question"
                        *ngIf="question.type === 'document'">
                        <label *ngIf="!questionsForm.get(question.uid).value ||
                        questionsForm.get(question.uid).value===null" for="document_survey" style="cursor: pointer;">
                            <img [src]="(checkImg(question)) ? getImg(question) : '/assets/images/importer.png'"
                                class="question-document-img" alt="{{ 'buttons.click-to-select-document' |
                                translate}}">
                        </label>
                        <label *ngIf="questionsForm.get(question.uid).value &&
                        questionsForm.get(question.uid).value !== null" for="">
                            <ion-thumbnail class="question-document-img" style="width: 200px; height: 200px;"
                                slot="start">

                                <img [src]="(checkImg(question)) ? getImg(question) : '/assets/icon/Files/' + assetFileName +'.svg'"
                                    class="question-document-img" alt="{{ 'buttons.click-to-select-document' |
                            translate}}">
                            </ion-thumbnail>
                        </label>

                        <ion-label *ngIf="getResultOfQuestion(question) && getResultOfQuestion(question).document">{{
                            getResultOfQuestion(question) &&
                            getResultOfQuestion(question).document ? getResultOfQuestion(question).document.name : "" }}
                        </ion-label>
                        <div class="upload-btn-wrapper" *ngIf="checkCanChangeAnswer(question)">
                            <!-- <a class="pic-style" [style.color]="event.styling.titleColor">
                                <i class="material-icons photo-icon">vertical_align_bottom</i>
                            </a> -->
                            <input id="document_survey" type="file" class="file-input"
                                accept="SDocument.supportedExtensions" (change)="getDocument($event, question)"
                                style="display: none;" />
                            <span *ngIf="!questionsForm.get(question.uid).value ||
                                questionsForm.get(question.uid).value===null"> {{ 'buttons.click-to-select-document' |
                                translate }}</span>
                            <span *ngIf="questionsForm.get(question.uid).value &&
                                questionsForm.get(question.uid).value !== null"> {{ documentName }}</span>
                            <label style="cursor: pointer; position: relative; top: -20px; left: 30px;"
                                *ngIf="questionsForm.get(question.uid).value && questionsForm.get(question.uid).value !== null"
                                for="document_survey">
                                <img [src]="'/assets/images/replace.png'" class="question-document-img"
                                    style="width: 25px; height: 25px;"
                                    alt="{{ 'buttons.click-on-document-to-replace' | translate}}">
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>

    <br>

    <ion-spinner name="lines" class="spinner" *ngIf="loadingSend"></ion-spinner>

    <div style="display: flex;width: 100%;padding-top: 7px;">
        <button shape="round" class="btn-validate" [style.background]="event.styling.btnBgColor"
            [style.color]="event.styling.btnTextColor" [style.opacity]="checkCanSendSurvey() ? 1 : 0.7"
            [disabled]="!checkCanSendSurvey()" (click)="sendSurvey()" *ngIf="!loadingSend && checkCanSendSurvey()">
            {{ "buttons.answer" | translate }}
        </button>
    </div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
<!-- </ion-content> -->