<ng-container class="tag-container">
	<p
		*ngFor="let computedCustomField of customFields"
		[hidden]="!computedCustomField.moduleSettings.canBeTag"
		class="tags-info"
		[style.font-size]="fontSize > 0 ? fontSize + 'px' : '16px'"
		[style.height]="!isMobile && customFields.length > 2 ? '20px' : 'auto'"
		[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
	>
		<ng-container
			*ngIf="
				((!isPrivateMode &&
					!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
						module,
						modulesCustomsFields,
						eventUserProfile,
						computedCustomField.baseSettings.uid,
						'customFields'
					)) ||
					isPrivateMode) &&
				computedCustomField.moduleSettings.canBeTag
			"
		>
			<!-- Multi-language text field -->
			<span
				*ngIf="
			((!isPrivateMode &&
				!SCustomFields.isUserOrModuleFieldsVisibilityHidden(module, modulesCustomsFields, eventUserProfile, computedCustomField.baseSettings.uid, 'customFields')) ||
				isPrivateMode) &&
				(computedCustomField.baseSettings.type === typeCustomFields.TEXT ||
					computedCustomField.baseSettings.type === typeCustomFields.SELECT) &&
				computedCustomField.fieldDatas.field.multiLanguageText?.[currentLanguage] !== ''
			"
			>
				{{ computedCustomField.fieldDatas.field.multiLanguageText?.[currentLanguage] }}
			</span>

			<!-- Numeric field -->
			<span
				*ngIf="
					((!isPrivateMode &&
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							'customFields'
						)) ||
						isPrivateMode) &&
					computedCustomField.baseSettings.type === typeCustomFields.NUMERIC &&
					computedCustomField.fieldDatas.field.numeric !== 0
				"
			>
				{{ computedCustomField.fieldDatas.field.numeric }}
			</span>

			<!-- URL field -->
			<a
				[style.color]="event.styling.btnBgColor"
				target="_blank"
				href="{{ computedCustomField.fieldDatas.field.text }}"
				*ngIf="
					((!isPrivateMode &&
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							'customFields'
						)) ||
						isPrivateMode) &&
					computedCustomField.baseSettings.type === typeCustomFields.URL &&
					computedCustomField.fieldDatas.field.text !== ''
				"
			>
				{{ computedCustomField.fieldDatas.field.text }}
			</a>

			<a
				[style.color]="event.styling.btnBgColor"
				href="mailto:{{ computedCustomField.fieldDatas.field.text }}"
				*ngIf="
					((!isPrivateMode &&
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							'customFields'
						)) ||
						isPrivateMode) &&
					computedCustomField.baseSettings.type === typeCustomFields.EMAIL &&
					computedCustomField.fieldDatas.field.text !== '' &&
					computedCustomField.moduleSettings.canBeTag
				"
			>
				{{ computedCustomField.fieldDatas.field.text }}
			</a>

			<!-- Phone field -->
			<span
				*ngIf="
					((!isPrivateMode &&
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							'customFields'
						)) ||
						isPrivateMode) &&
					computedCustomField.fieldDatas.field.phoneNumber?.internationalNumber !== '' &&
					computedCustomField.baseSettings.type === typeCustomFields.PHONE
				"
			>
				{{ computedCustomField.fieldDatas.field.phoneNumber?.internationalNumber }}
			</span>

			<!-- visibility chip -->
			<span
				*ngIf="
					SCustomFields.checkValueCustomField(
						computedCustomField.baseSettings.type,
						computedCustomField.fieldDatas,
						currentLanguage
					) &&
					module.options.enableUserFieldsHideAbility &&
					isPrivateMode
				"
				class="visibility-state"
			>
				<ion-chip
					*ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							'customFields'
						)
					"
					color="success"
					>{{ "labels.visible" | translate }}</ion-chip
				>
				<ion-chip
					*ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid,
							'customFields'
						)
					"
					color="danger"
					>{{ "labels.hidden" | translate }}</ion-chip
				>
			</span>
		</ng-container>
	</p>
</ng-container>
