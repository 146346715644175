import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetAllFavoritesFolders, GetAllLocations } from "../actions/generics-modules-data.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IBaseDocument } from "../interfaces";
import { checkSameEvent } from "../selectors/generics-modules-data.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";
import { StorageService } from "./storage.service";

@Injectable({
	providedIn: "root"
})
export class LocationsService {
	locationsSub: Subscription;
	foldersSub: Subscription;

	constructor(private SFirestore: FirestoreService, private storage: StorageService, private store: Store) {}

	unsubscribeAll() {
		if (this.locationsSub) {
			this.locationsSub.unsubscribe();
		}
	}

	/**
	 * Get locations for an event
	 * @param eventId
	 * @param typeOrder
	 * @returns
	 */
	getLocationsForEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "locations", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.locationsSub && !this.locationsSub.closed) {
					return;
				} else if (!sameEvent && this.locationsSub && !this.locationsSub.closed) {
					this.locationsSub.unsubscribe();
				}

				this.locationsSub = this.SFirestore.collectionGroupValueChangesDocuments("locations", [
					where("eventId", "==", eventId)
				]).subscribe((locations: IBaseDocument[]) => {
					this.store.dispatch(GetAllLocations({ payload: locations, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initLocations"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initLocations", payload: true })
								);
							}
						});
				});
			});
	}

	/********** TODO: Favorite part (will move in favorite service file soon) **********/
	/**
	 * Get all folders of event
	 * @param eventId
	 * @returns
	 */
	getAllFavoriteFoldersOfEvent(eventId: string) {
		this.store.select(checkSameEvent({ key: "baseCustomFields", uid: eventId })).subscribe((sameEvent) => {
			if (sameEvent && this.foldersSub && !this.foldersSub.closed) {
				return;
			} else if (!sameEvent && this.foldersSub && !this.foldersSub.closed) {
				this.foldersSub.unsubscribe();
			}

			this.foldersSub = this.SFirestore.collectionGroupValueChangesDocuments("favorite-folders", [
				where("eventId", "==", eventId)
			]).subscribe((folders) => {
				this.store.dispatch(GetAllFavoritesFolders({ payload: folders, eventId: eventId }));

				this.store
					.select(getInitSpecificEventDatasPart("initFavorites"))
					.pipe(take(1))
					.subscribe((init) => {
						if (!init) {
							this.store.dispatch(InitSpecificEventDatasPart({ part: "initFavorites", payload: true }));
						}
					});
			});
		});
	}

	/**
	 * getEventUsers
	 * @param eventId
	 * @returns
	 */
	getEventUsers(eventId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("event-users", [where("eventId", "==", eventId)]);
	}
}
