import { Component, ElementRef, OnDestroy, ViewChild } from "@angular/core";
import { ModalController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import { Subscription, combineLatest } from "rxjs";
import { take } from "rxjs/operators";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeVideoSource } from "src/app/shared/enums/type-gallery";
import {
	IEvent,
	IEventUser,
	IFolder,
	IGalleryImage,
	IModule,
	IUser,
	IVideoPlayerSettings
} from "src/app/shared/interfaces";
import { IGalleryEmbededVideo } from "src/app/shared/interfaces/documents.interfaces";
import { PathComponents } from "src/app/shared/paths/path-components";
import { getCurrentEventUser, getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import {
	getFoldersOfModule,
	getImagesOfFolder,
	getSpecificFolder,
	getVideosOfFolder
} from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService, UtilityService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-gallery",
	templateUrl: "./gallery.component.html",
	styleUrls: ["./gallery.component.scss"]
})
export class GalleryComponent implements OnDestroy {
	@ViewChild("videoPlayer", { static: false }) videoPlayer: any;
	@ViewChild("youtubeVideoPlayerContainer") youtubeVideoPlayerContainer: ElementRef;

	subscriptions: Subscription[] = [];
	analyticsArraySub: { name: string; sub: Subscription }[] = [];

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	folderId: string;
	folder: IFolder;
	imagesSaved: IGalleryImage[] = [];
	images: IGalleryImage[] = [];
	user: IUser;
	eventUser: IEventUser;

	currentLanguage: string = environment.platform.defaultLanguage;

	width: number = window.innerWidth;
	folders: IFolder[];
	videosSaved: IGalleryEmbededVideo[];
	videos: IGalleryEmbededVideo[];
	playerStart: number = 0;
	playerWidth: number = 640;
	playerHeight: number = 360;

	typeVideoSource = TypeVideoSource;
	/**
	 * When true, the player is hidden (css)
	 * avoiding performance issues when dragging
	 */
	moving: boolean = false;
	init: boolean = false;
	videoPlayerState: IVideoPlayerSettings;
	youtubeState: number = -1;

	youtubePlayerReady: boolean = false;
	videosWidth: string | number;
	videosHeight: number;
	isMobile: boolean;

	constructor(
		private SAnalytics: AnalyticsService,
		private store: Store,
		private modalCtrl: ModalController,
		private platform: Platform,
		private STranslate: TranslateService,
		private SUtility: UtilityService
	) {
		this.isMobile =
			this.platform.is("ios") || this.platform.is("android") || this.platform.is("mobileweb") ? true : false;
	}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.folderId = params.folderId;

						// Analytics
						this.SAnalytics.galleryFolderAccess(this.eventId, this.moduleId, this.folderId);

						this.getEvent();
						this.getModule();
						this.getFolder();
						this.getFolders();
						this.getImages();
						this.getEmbededVideos();
						this.getUser();
					});
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		this.removeIframes();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 * Getting module
	 */
	getModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	/**
	 * Getting folder
	 */
	getFolder() {
		this.subscriptions.push(
			this.store.select(getSpecificFolder(this.folderId)).subscribe((folder) => {
				if (!_.isEqual(this.folder, folder)) {
					if (
						(this.folder && folder && !_.isEqual(this.folder.orderInFolder, folder.orderInFolder)) ||
						(!this.folder && folder)
					) {
						this.folder = folder;
						this.getImages();
					}
					this.folder = folder;
				}

				if (this.folder) {
					this.store.dispatch(
						GetHeaderTitle({
							payload: {
								ArAR: this.folder.name.ArAR,
								DeDE: this.folder.name.DeDE,
								EnUS: this.folder.name.EnUS,
								EsES: this.folder.name.EsES,
								FrFR: this.folder.name.FrFR,
								PtBR: this.folder.name.PtBR
							}
						})
					);
				}
			})
		);
	}

	/**
	 * Getting all folders of module
	 */
	getFolders() {
		this.subscriptions.push(
			this.store
				.select(
					getFoldersOfModule({
						moduleId: this.moduleId,
						order: this.module.typeOrder,
						language: this.currentLanguage
					})
				)
				.pipe(take(1))
				.subscribe((folders) => {
					this.folders = folders;

					if (this.folders && this.folders.length === 1) {
						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_GALLERY_MODULE
						);
					}
				})
		);
	}

	/**
	 * Get images
	 */
	getImages() {
		this.store
			.select(
				getImagesOfFolder({
					folderId: this.folderId,
					order: this.folder.orderInFolder
				})
			)
			.pipe(take(1))
			.subscribe((images) => {
				if (!_.isEqual(this.imagesSaved, images)) {
					this.imagesSaved = images;
					this.images = images;

					if (!this.isMobile) {
						if (
							this.folder.folderAllowModificationGridDisplayType &&
							this.folder.folderGridDisplayType === "column"
						) {
							this.videosWidth = "100%";
							this.videosHeight = 340;
						} else {
							this.videosWidth = "100%";
							this.videosHeight = 640;
						}
					} else {
						this.videosWidth = 380;
						this.videosHeight = 240;
					}
				}
			});
	}

	/**
	 * getEmbededVideos
	 */
	getEmbededVideos() {
		this.subscriptions.push(
			this.store
				.select(
					getVideosOfFolder({
						folderId: this.folderId,
						order: this.folder.orderInFolder
					})
				)
				.pipe(take(1))
				.subscribe((videos) => {
					this.videosSaved = videos;
					this.videos = videos;

					if (!this.isMobile) {
						if (
							this.folder.folderAllowModificationGridDisplayType &&
							this.folder.folderGridDisplayType === "column"
						) {
							this.videosWidth = "100%";
							this.videosHeight = 340;
						} else {
							this.videosWidth = "100%";
							this.videosHeight = 640;
						}
					} else {
						this.videosWidth = 380;
						this.videosHeight = 240;
					}
				})
		);
	}

	/**
	 * Getting user datas
	 */
	getUser() {
		this.subscriptions.push(
			combineLatest([this.store.select(getCurrentUser), this.store.select(getCurrentEventUser)]).subscribe(
				(results) => {
					this.user = results[0];
					this.eventUser = results[1];
				}
			)
		);
	}

	/**
	 * removeIframes
	 */
	removeIframes() {
		this.videos.forEach((video) => {
			const frame = window.parent.document.getElementById(video.uid);
			frame.parentNode.removeChild(frame);
		});
	}

	/**
	 * Open image
	 * @param image
	 */
	async openImage(image: IGalleryImage) {
		const modal = await this.modalCtrl.create({
			component: PathComponents.imageViewerModal,
			componentProps: {
				url: image.url,
				type: image.type,
				name: image.name,
				allowDownload: this.module.options.allowImageDownload,
				allowSharing: this.module.options.allowImageSharing
			}
		});

		modal.present();

		// Tracking analytic
		this.SAnalytics.imageOpended(this.eventId, this.moduleId, this.eventUser, this.folderId, image.uid);
	}

	/**
	 * Get rows
	 * @param len
	 * @param parentDiv
	 * @returns
	 */
	getRows(len: number, parentDiv: any) {
		if (this.folder.folderGridDisplayType === "column") {
			const numberColumn = this.width < 768 ? 2 : 4;
			const height = this.width < 768 ? this.width / 2 : Math.ceil(parentDiv.clientWidth / 4);
			const nbrLignes = Math.ceil(len / numberColumn);
			return `repeat(${nbrLignes},${height}px)`;
		} else {
			return `repeat(${len},auto)`;
		}
	}

	/**
	 * Get columns
	 * @param parentDiv
	 * @returns
	 */
	getColumns(parentDiv: any) {
		if (this.folder.folderGridDisplayType === "column") {
			return this.width < 768 ? "repeat(2, auto)" : `repeat(4, ${Math.ceil((parentDiv.clientWidth - 9) / 4)}px)`;
		} else {
			return this.width < 768 ? this.width + "px" : parentDiv.clientWidth + "px";
		}
	}

	/**
	 * Get image height
	 * @param parentDiv
	 * @returns
	 */
	getImageHeight(parentDiv: any) {
		if (this.folder.folderGridDisplayType === "column") {
			return this.width < 768
				? Math.ceil(this.width / 2) + "px"
				: Math.ceil((parentDiv.clientWidth - 9) / 4) + "px";
		} else {
			return this.width < 768 ? "38vh" : "65vh";
		}
	}

	/**
	 * Get width of mini video player
	 * @returns
	 */
	getVideoWidth(): string | number {
		if (this.isMobile) {
			return this.platform.width() - 20;
		} else {
			return this.videosWidth;
		}
	}

	/**
	 * Get height of mini video player
	 * @returns
	 */
	getVideoHeight() {
		if (this.isMobile) {
			return this.platform.height() * 0.3;
		} else {
			return this.videosHeight;
		}
	}

	/**
	 * Background size type
	 * @returns
	 */
	backgroundSize() {
		return this.folder.folderGridDisplayType === "column" ? "contain" : "contain";
	}

	changeVideoDimensions(format: string) {
		if (this.folder.folderGridDisplayType === "column") {
			this.videosWidth = "100%";
			this.videosHeight = 340;
		} else {
			this.videosWidth = "100%";
			this.videosHeight = 640;
		}
	}
}
