import { createReducer, on } from "@ngrx/store";
import * as CheckinsActions from "../actions/checkins.actions";
import { ICheckinsState } from "../interfaces/checkin.interfaces";

export const initialState: ICheckinsState = {
	checkins: [],
	eventId: ""
};

export const checkinsFeatureKey = "checkins";

export const checkinsReducers = createReducer(
	initialState,
	on(CheckinsActions.GetAllCheckins, (state, action) => ({
		...state,
		eventId: action.eventId,
		checkins: action.payload
	})),
	on(CheckinsActions.ResetCheckinsState, () => ({
		...initialState
	}))
);
