export enum AppointmentTimeSlotStatus {
	APPOINTMENT_ACCEPTED = 1,
	APPOINTMENT_REJECTED = 2,
	APPOINTMENT_CANCELLED = 3,
	APPOINTMENT_PENDING = 4,
	TIME_SLOT_ENABLED = 5,
	TIME_SLOT_DISABLED = 6,
	APPOINTMENT_REJECTED_AUTOMATICALLY = 7,
	APPOINTMENT_CANCELLED_AUTOMATICALLY = 8,
	APPOINTMENT_ENDED = 100
}

export const ALL_USERS_ID: string = "allUsers";
