import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class PathApi {
	// BASE URL FROM ENVIRONMENT IN USE
	static baseUrl = environment.platform.apiBaseUrl;

	// AUTHENTICATION
	static authCreateUser = environment.platform.apiV2BaseUrl
		? "https://auth-createUser" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-createUser";
	static checkAuthAccountExist = environment.platform.apiV2BaseUrl
		? "https://auth-checkAuthAccountExist" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-checkAuthAccountExist";
	static authCreateOnlyAuthAccount = environment.platform.apiV2BaseUrl
		? "https://auth-createOnlyAuthAccount" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-createOnlyAuthAccount";
	static authCreateUserWithoutAuth = environment.platform.apiV2BaseUrl
		? "https://auth-createUserWithoutAuth" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-createUserWithoutAuth";
	static authDeleteUser = environment.platform.apiV2BaseUrl
		? "https://auth-deleteUser" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-deleteUser";
	static authGetClaimsUser = environment.platform.apiV2BaseUrl
		? "https://auth-getClaimsUser" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-getClaimsUser";
	static authGetUserByEmail = environment.platform.apiV2BaseUrl
		? "https://auth-getUserByEmail" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-getUserByEmail";
	static authSendCodeNumber = environment.platform.apiV2BaseUrl
		? "https://auth-sendCodeNumber" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-sendCodeNumber";
	static authUpdateClaimsUser = environment.platform.apiV2BaseUrl
		? "https://auth-updateClaimsUser" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-updateClaimsUser";
	static authUpdateUser = environment.platform.apiV2BaseUrl
		? "https://auth-updateUser" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-updateUser";
	static authVerifyEmailExist = environment.platform.apiV2BaseUrl
		? "https://auth-verifyEmailExist" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-verifyEmailExist";
	static authVerifyCaptcha = environment.platform.apiV2BaseUrl
		? "https://auth-verifyCaptcha" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-verifyCaptcha";
	static sendToUserPasswordResetLink = environment.platform.apiV2BaseUrl
		? "https://auth-sendToUserPasswordResetLink" + environment.platform.apiV2BaseUrl
		: environment.platform.apiBaseUrl + "auth-sendToUserPasswordResetLink";
	static sendEmailTo = "automations-sendEmailTo";
}
