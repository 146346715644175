import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEvent, IEventUser } from "../interfaces";
import { IWidgetsState } from "../interfaces/widget.interfaces";
import { getCurrentEventUser } from "./auth.selectors";
import { getCurrentEvent } from "./events.selectors";

export const getWidgetsState = createFeatureSelector<IWidgetsState>("widgets");

export const checkSameEvent = (uid: string) =>
	createSelector(getWidgetsState, (state: IWidgetsState) => state.eventId === uid);

export const getWidgetsOfModule = (moduleId: string) =>
	createSelector(
		getWidgetsState,
		getCurrentEvent,
		getCurrentEventUser,
		(state: IWidgetsState, event: IEvent, eventUser: IEventUser) => {
			const widgets = _.cloneDeep(state.widgets)
				.filter((widget) => widget.moduleId === moduleId && widget.eventId === state.eventId)
				.filter(
					(widget) =>
						(event &&
							event.settings &&
							event.settings.allowStepByStep &&
							eventUser &&
							eventUser.points >= widget.visibleForMinPoints) ||
						(event && event.settings && !event.settings.allowStepByStep)
				)
				.map((widget) => {
					const newConfig = _.cloneDeep(widget.config);
					newConfig.left = 0;
					newConfig.top = 0;
					widget.config = newConfig;
					return widget;
				});

			return {
				init: state.eventId ? true : false,
				widgets: widgets
			};
		}
	);
