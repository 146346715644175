import { createReducer, on } from "@ngrx/store";
import * as ModulesActions from "../actions/modules.actions";
import { IModulesState } from "../interfaces/modules.interfaces";

export const initialState: IModulesState = {
	eventId: "",
	modules: []
};

export const modulesFeatureKey = "modules";

export const modulesReducers = createReducer(
	initialState,
	on(ModulesActions.GetAllModules, (state, action) => ({
		...state,
		eventId: action.eventId,
		modules: action.payload
	})),
	on(ModulesActions.ResetModulesState, () => ({
		...initialState
	}))
);
