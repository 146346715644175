import { createAction, props } from "@ngrx/store";
import { IEventUser } from "../interfaces/event-users.interfaces";

export enum EventUsersActionsTypes {
	GetAllEventUsers = "[EventUsers Service] Get all event users"
}

export const GetAllEventUsers = createAction(
	EventUsersActionsTypes.GetAllEventUsers,
	props<{ payload: IEventUser[]; eventId: string }>()
);
