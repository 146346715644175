<ion-content class="page">
	<div *ngIf="event && module && note" class="background" style="position: relative; padding: 50px 40px 0 40px;">
		<ion-button *ngIf="isMobile" class="settings-button basic-icon-transparent-header-button"
			style="position: absolute; top: 10px; right: 10px" (click)="shareNote()">
			<ion-icon slot="icon-only" name="share-outline" mode="md" [style.color]="'var(--title-color)'"></ion-icon>
		</ion-button>
		<div style="font-size: small; opacity: 0.5; position: absolute; top: 10px; left: 10px">
			{{ SUtility.getPartOfDate(event, null, note.creationDate, "full") }}
		</div>
		<div style="width: 100%; text-align: center; white-space: pre-wrap; font-weight: bold; font-size: large">
			{{ note.title }}
		</div>

		<p class="input-label-text label-color" style="width: 100%; white-space: pre-wrap; margin-top: 30px">{{
			note.content }}</p>
	</div>
</ion-content>