import { Component, OnInit } from "@angular/core";
import { MenuController } from "@ionic/angular";

@Component({
	selector: "app-splash",
	templateUrl: "./splash.page.html",
	styleUrls: ["./splash.page.scss"]
})
export class SplashPage implements OnInit {
	constructor(private menuCtrl: MenuController) {
		this.menuCtrl.enable(false);
	}

	ngOnInit() {}
}
