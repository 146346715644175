import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, combineLatest, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { selectRouteNestedParams } from "../selectors/router.selectors";
import { getInitContainers, getInitEvents, getInitUser } from "../selectors/utility.selectors";
import { getSpecificEvent } from "../selectors/events.selectors";

@Injectable()
export class EventBlockedGuard  {
	constructor(private store: Store, private snackbar: MatSnackBar, private STranslate: TranslateService) {}
	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitEvents).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitContainers).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(selectRouteNestedParams)
		]).pipe(
			take(1),
			switchMap((results) => {
				const params = results[3];
				return this.store
					.select(getSpecificEvent(params["eventId"] ? params["eventId"] : route.params.eventId))
					.pipe(take(1));
			}),
			switchMap((event) => {
				if (event && !event.blocked) {
					return of(true);
				} else {
					this.snackbar.open(this.STranslate.instant("alerts.blocked-event-info"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					return of(false);
				}
			})
		);
	}
}
