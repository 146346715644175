/* eslint-disable no-empty */

import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { DateTime } from "luxon";
import { distinctUntilChanged } from "rxjs";
import { IModule, IEvent, IUser, ILanguage } from "../interfaces";
import { getCurrentUser } from "../selectors/auth.selectors";
import { FirestoreService } from "./firestore.service";
import { ILog } from "../interfaces/logs.interfaces";
import { TypeLogs } from "../enums/type-logs";

@Injectable({ providedIn: "root" })
export class TinyService {
	eventId: string;
	moduleId: string;
	module: IModule;
	event: IEvent;
	user: IUser;
	url: string;

	constructor(private SFirestore: FirestoreService, private store: Store, private router: Router) {
		this.url = "/event/" + window.location.href?.split("/event/")[1];

		this.eventId = this.url.split("/")[2];
		this.moduleId = this.url.split("/")[4];

		this.router.events
			.pipe(distinctUntilChanged((a: any, b: any) => a?.routerEvent?.url === b?.routerEvent?.url))
			.subscribe(async (params: any) => {
				if (params?.routerEvent?.url ?? params?.url) {
					this.url = params?.routerEvent?.url ?? params?.url;

					this.eventId = this.url.split("/")[2];
					this.moduleId = this.url.split("/")[4];

					try {
						await this.getEventAndModule();
					} catch (error) {}
				}
			});

		this.store
			.select(getCurrentUser)
			.pipe(distinctUntilChanged((a: IUser, b: IUser) => a?.uid === b.uid))
			.subscribe((user) => {
				this.user = user;
			});
	}

	/**
	 * trackTinyMceLoad
	 *
	 */
	async trackTinyMceLoad() {
		try {
			if (!this.eventId) {
				console.error("No event id found");
				return;
			}

			await this.getEventAndModule();

			const newLog: ILog = {
				uid: this.SFirestore.createId(`events/${this.eventId}/logs`),
				creationDate: DateTime.local().toISO(),
				eventId: this.eventId,
				moduleId: this.moduleId ?? "",
				moduleName:
					this.module && this.module.name
						? Object.entries(this.module.name)
								.map(
									([key, value]) =>
										({ [key]: value + " (Front Office)" } as { [key: string]: string })
								)
								.reduce((acc, curr) => {
									const lang = Object.keys(curr)[0];
									acc[lang] = curr[lang];
									return acc;
								}, {} as ILanguage)
						: {
								FrFR: this.url.split("/")[3] + " (Front Office)",
								EnUS: this.url.split("/")[3] + " (Front Office)",
								EsES: this.url.split("/")[3] + " (Front Office)",
								PtBR: this.url.split("/")[3] + " (Front Office)",
								DeDE: this.url.split("/")[3] + " (Front Office)",
								ArAR: this.url.split("/")[3] + " (Front Office)"
						  },
				moduleType: this.module?.type ?? null,
				type: TypeLogs.TINY_LOAD,
				initiator: {
					email: this.user?.email ?? "",
					uid: this.user?.uid ?? ""
				},
				target: {
					email: "",
					uid: "",
					url: this.url
				},
				targets: []
			};
			this.SFirestore.setDocument(`events/${this.eventId}/logs/${newLog.uid}`, newLog);
		} catch (error) {}
	}

	/**
	 * Get event and module
	 */
	async getEventAndModule() {
		try {
			if (this.eventId) {
				this.event = (await this.SFirestore.getDocument(`events/${this.eventId}`))?.data() as IEvent;
			}

			if (this.eventId && this.moduleId) {
				this.module = (
					await this.SFirestore.getDocument(`events/${this.eventId}/modules/${this.moduleId}`)
				)?.data() as IModule;
			}
		} catch (error) {}
	}
}
