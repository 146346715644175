<div *ngIf="event && module && form && myEventUser" class="background" [class.full-w]="componentMode">
	<div class="header">
		<div [ngClass]="device === 'ios' ? 'ios-close-btn' : 'close-btn'" class="p-l-5 p-t-15">
			<ion-button fill="clear" color="dark" (click)="closeModal()">
				<ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
			</ion-button>
		</div>
		<!-- Form title -->
		<div class="title">
			<h1 class="title-text-main" [style.color]="event.styling.titleColor">{{ form.name[currentLanguage] }}</h1>
			<h1 class="title-text-sub" [style.color]="event.styling.contentTextColor">
				({{
					contactDatas.length > 0
						? ("labels.contact-editing" | translate)
						: ("labels.contact-creation" | translate)
				}})
			</h1>
			<br />
		</div>
	</div>

	<!-- content -->
	<div
		[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
		[style.marginTop]="isMobile && componentMode ? '30px' : '10px'"
		class="content"
	>
		<form [formGroup]="fieldsForm" style="background-color: #fff">
			<div *ngFor="let field of form.fields; let i = index" style="padding-top: 10px">
				<div
					*ngIf="field.visibility"
					[ngStyle]="{
						'border-bottom':
							i !== form.fields.length - 1 ? '1px solid ' + event.styling.titleColor + '4D' : 'none',
						'padding-bottom': '10px'
					}"
				>
					<ion-label
						*ngIf="field.optionalName && field?.optionalName[currentLanguage]"
						class="field-title-main ion-text-wrap ion-text-left"
						style="width: 100%; margin-left: 10px"
						[style.color]="event.styling.titleColor"
					>
						{{ field.optionalName[currentLanguage] }}
					</ion-label>
					<div [class.evaluation-box]="field.type === 'evaluation' || field.type === 'document'">
						<div class="field-title-infobooth">
							<p
								class="field-title ion-text-wrap"
								style="width: 100%"
								[style.color]="event.styling.titleColor"
							>
								{{ field.name[currentLanguage] }}
							</p>

							<div class="infobooth" *ngIf="field.description && field.description[currentLanguage]">
								<small [style.color]="event.styling.contentTextColor">
									<p [innerHTML]="field.description[currentLanguage] | safeHtml"></p>
								</small>
							</div>
						</div>
						<div class="fields-to-user" *ngIf="field.type === 'oneSelect'">
							<ion-list>
								<ion-radio-group [style.opacity]="1" [formControlName]="field.uid" [name]="field.uid">
									<ion-item
										class="justify-text"
										*ngFor="let option of field.answers; let ind = index"
										[lines]="ind !== field.answers.length - 1 ? 'full' : 'none'"
									>
										<ion-label
											class="option-size ion-text-wrap"
											[style.color]="event.styling.contentTextColor"
										>
											{{ option.answer[currentLanguage] }}
										</ion-label>
										<ion-radio legacy="true" [value]="option.uid">
											<!-- <span [style.color]="event.styling.contentTextColor">
												{{ option.answer[currentLanguage] }}
											</span> -->
										</ion-radio>
									</ion-item>
								</ion-radio-group>
							</ion-list>
						</div>

						<div [formGroupName]="field.uid" class="fields-to-user" *ngIf="field.type === 'multipleSelect'">
							<ion-list>
								<div *ngFor="let answer of field.answers; let ind = index">
									<ion-item
										class="justify-text"
										[lines]="ind !== field.answers.length - 1 ? 'full' : 'none'"
									>
										<ion-label
											class="option-size ion-text-wrap"
											[style.color]="event.styling.contentTextColor"
										>
											{{ answer?.answer[currentLanguage] }}
										</ion-label>
										<ion-checkbox [formControlName]="answer.uid" mode="md"> </ion-checkbox>
									</ion-item>
								</div>
							</ion-list>
						</div>

						<div class="justify-text evaluation" lines="none" *ngIf="field.type === 'evaluation'">
							<div class="evaluation-align">
								<ngx-star-rating [style.opacity]="1" [formControlName]="field.uid" [id]="field.uid">
								</ngx-star-rating>
							</div>
						</div>

						<div class="fields-to-user" *ngIf="field.type === 'dissertative' || field.type === 'plainText'">
							<ion-list>
								<ion-item class="ion-text-wrap" lines="none">
									<ion-textarea
										autocapitalize="sentences"
										[style.opacity]="1"
										[formControlName]="field.uid"
										[name]="field.uid"
										placeholder="{{ 'surveys.answer_plc' | translate }}"
										style="--padding-start: 10px"
									>
									</ion-textarea>
								</ion-item>
							</ion-list>
						</div>

						<div class="fields-to-user" *ngIf="field.type === 'date'">
							<!-- <ion-item class="ion-text-wrap" lines="none"> -->
							<ion-datetime
								style="margin: auto"
								presentation="date"
								[formControlName]="field.uid"
								[name]="field.uid"
								min="1900-01-01"
								max="2050-01-01"
								[locale]="locale"
							>
							</ion-datetime>
							<!-- </ion-item> -->
						</div>

						<!-- Document form -->
						<div
							style="margin: auto"
							class="fields-to-user document-type-question"
							*ngIf="field.type === 'document'"
						>
							<label
								*ngIf="!fieldsForm.get(field.uid).value || fieldsForm.get(field.uid).value === null"
								style="cursor: pointer"
								matTooltip="{{ 'buttons.click-to-select-document' | translate }}"
								for="document_field"
							>
								<img
									[src]="checkImg(field) ? getImg(field) : '/assets/images/importer.png'"
									class="field-document-img"
									alt="{{ 'buttons.click-to-select-document' | translate }}"
								/>
							</label>
							<label
								*ngIf="fieldsForm.get(field.uid).value && fieldsForm.get(field.uid).value !== null"
								style="cursor: pointer"
								matTooltip="{{ 'tooltips.click-to-replace-document' | translate }}"
								for="document_field"
							>
								<ion-thumbnail
									class="field-document-img"
									style="width: 200px; height: 200px"
									slot="start"
								>
									<img
										[src]="
											checkImg(field)
												? getImg(field)
												: '/assets/icon/Files/' + assetFileName + '.svg'
										"
										class="field-document-img"
										alt="{{ 'buttons.click-to-select-document' | translate }}"
									/>
								</ion-thumbnail>
							</label>

							<ion-label *ngIf="getresultOfField(field) && getresultOfField(field).document"
								>{{
									getresultOfField(field) && getresultOfField(field).document
										? getresultOfField(field).document.name
										: ""
								}}
							</ion-label>
							<div class="upload-btn-wrapper">
								<!-- <a class="pic-style" [style.color]="event.styling.titleColor">
									<i class="material-icons photo-icon">vertical_align_bottom</i>
								</a> -->
								<input
									id="document_field"
									type="file"
									class="file-input"
									accept="SDocument.supportedExtensions"
									(change)="getDocument($event, field)"
									style="display: none"
								/>
								<span
									*ngIf="!fieldsForm.get(field.uid).value || fieldsForm.get(field.uid).value === null"
								>
									{{ "buttons.click-to-select-document" | translate }}</span
								>
								<span
									*ngIf="fieldsForm.get(field.uid).value && fieldsForm.get(field.uid).value !== null"
								>
									{{ documentName }}</span
								>

								<!-- Download icon -->
								<ion-icon
									*ngIf="
										getresultOfField(field) &&
										getresultOfField(field).document &&
										getresultOfField(field).document?.url !== ''
									"
									(click)="downloadDocument(getresultOfField(field).document.url)"
									name="cloud-download"
									class="download-icon"
									[style.color]="event.styling.titleColor"
									matTooltip="{{ 'tooltips.click-to-download-document' | translate }}"
								></ion-icon>
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>

	<!-- Footer -->
	<div class="footer">
		<ion-spinner name="lines" class="spinner" *ngIf="loadingSend"></ion-spinner>

		<div [style.position]="isMobile ? 'absolute' : 'relative'" style="bottom: 0; text-align: center; width: 100%">
			<button
				shape="round"
				class="btn-validate"
				[style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor"
				[disabled]="fieldsForm.invalid"
				(click)="sendForm()"
				*ngIf="!loadingSend"
			>
				{{ "buttons.answer" | translate }}
			</button>
		</div>
	</div>

	<br />
</div>

<div class="align-loader-custom" *ngIf="componentLoader">
	<div class="loader-default"></div>
</div>
