import { createAction, props } from "@ngrx/store";
import { IEventUser } from "../interfaces";
import { IUser } from "../interfaces/user.interfaces";

export enum AuthActionsTypes {
	GetCurrentUser = "[Auth Service] Get current user",
	GetMyEventUser = "[Auth Service] Get my event user",
	LogInUser = "[Auth Service] Log in user",
	LogOutUser = "[Auth Service] Log out user"
}

export const GetCurrentUser = createAction(AuthActionsTypes.GetCurrentUser, props<{ payload: IUser }>());

export const GetMyEventUser = createAction(
	AuthActionsTypes.GetMyEventUser,
	props<{ payload: IEventUser; eventId: string }>()
);

export const LogInUser = createAction(AuthActionsTypes.LogInUser, props<{ payload: boolean }>());

export const LogOutUser = createAction(AuthActionsTypes.LogOutUser, props<any>());
