<div [ngClass]="module.type === typeModule.SCHEDULE ? 'container-schedule' : 'container-profile'"
	*ngFor="let computedCustomField of computedCustomFields">
	<!-- Text field -->
	<div class="simpletext-field" *ngIf="
			(computedCustomField.baseSettings.type === typeCustomFields.EMAIL ||
				computedCustomField.baseSettings.type === typeCustomFields.URL) &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.text &&
			computedCustomField.fieldDatas.field.text !== ''
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />

			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- visibility chip -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<a *ngIf="computedCustomField.baseSettings.type === typeCustomFields.EMAIL"
				href="mailto:{{ computedCustomField.fieldDatas.field.text }}">
				{{ computedCustomField.fieldDatas.field.text }}
			</a>
			<a *ngIf="computedCustomField.baseSettings.type === typeCustomFields.URL"
				href="{{ computedCustomField.fieldDatas.field.text }}" target="_blank">
				{{ computedCustomField.fieldDatas.field.text }}
			</a>
		</div>
		<!-- <div #itemDiv [style.color]="event.styling.contentTextColor" class="description"
			style="white-space: pre-line;"
			[innerHTML]="item.textValue[userLanguage] | safeHtml">
		</div>    -->
	</div>

	<!-- Multi language Text & Select field -->
	<div class="multi-laguage-text-field" *ngIf="
			(computedCustomField.baseSettings.type === typeCustomFields.TEXT ||
				computedCustomField.baseSettings.type === typeCustomFields.SELECT) &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.multiLanguageText &&
			computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !== ''
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] }}</span>
		</div>
	</div>

	<!-- Muli-Text field -->
	<div class="multi-text-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.MULTI_TEXT &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage] &&
			computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage].length > 0
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== undefined
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>

			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span *ngFor="
					let data of computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage];
					let i = index
				">
				<span
					*ngIf="i < computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage].length - 1">{{
					data }},&nbsp;</span>
				<span *ngIf="
						i === computedCustomField.fieldDatas.field.multiLanguageTextArray[currentLanguage].length - 1
					">
					{{ data }}
				</span>
			</span>
		</div>
	</div>

	<!-- Multi-Select field -->
	<div class="multi-select-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.MULTI_SELECT &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.multiLanguageSelectArray &&
			computedCustomField.fieldDatas.field.multiLanguageSelectArray.length > 0 &&
			checkMultiSelectDatas(computedCustomField.fieldDatas.field.multiLanguageSelectArray, currentLanguage)
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span *ngFor="let data of computedCustomField.fieldDatas.field.multiLanguageSelectArray; let i = index">
				<span *ngIf="
						i < computedCustomField.fieldDatas.field.multiLanguageSelectArray.length - 1 &&
						data[currentLanguage]?.trim() !== ''
					">{{ data[currentLanguage]?.trim() }},&nbsp;</span>
				<span *ngIf="
						i === computedCustomField.fieldDatas.field.multiLanguageSelectArray.length - 1 &&
						data[currentLanguage]?.trim() !== ''
					">
					{{ data[currentLanguage] }}
				</span>
			</span>
		</div>
	</div>

	<!-- Numeric field -->
	<div class="numeric-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.NUMERIC &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.numeric &&
			computedCustomField.fieldDatas.field.numeric !== null
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.numeric }}</span>
		</div>
	</div>

	<!-- Date field -->
	<div class="simpletext-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.DATE &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.date.fullDateISO &&
			computedCustomField.fieldDatas.field.date.fullDateISO !== null
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ getPartOfDate(computedCustomField.fieldDatas.field.date.fullDateISO, "full") }}</span>
		</div>
	</div>

	<!-- Address field -->
	<div class="simpletext-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.ADDRESS &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.address.fullAddress !== ''
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.address.fullAddress }}</span>
		</div>
	</div>

	<!-- Checkbox field -->
	<div class="simpletext-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.CHECKBOX &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.checkbox
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span>{{ computedCustomField.fieldDatas.field.checkbox }}</span>
		</div>
	</div>

	<!-- Phone number field -->
	<div class="phonenumber-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.PHONE &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.phoneNumber &&
			computedCustomField.fieldDatas.field.phoneNumber.internationalNumber !== ''
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<a href="tel:{{ computedCustomField.fieldDatas.field.phoneNumber.internationalNumber }}">{{
				computedCustomField.fieldDatas.field.phoneNumber.internationalNumber
				}}</a>
		</div>
	</div>

	<!-- Country field -->
	<div class="simpletext-field" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.COUNTRY &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.country &&
			computedCustomField.fieldDatas.field.country.name !== ''
		">
		<div class="item-name" [style.color]="event.styling.titleColor">
			<span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== undefined &&
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== 'custom-icon'
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
			<!-- show custom picto img -->
			<img class="menu-icon" style="width: 22px; height: 22px" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'custom-icon' &&
					computedCustomField.baseSettings.icon.icon !== null
				" [src]="computedCustomField.baseSettings.icon.icon" />
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>

			<!-- Visibility state -->
			<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
				<ion-chip *ngIf="
						!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="success">{{ "labels.visible" | translate }}</ion-chip>
				<ion-chip *ngIf="
						SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						)
					" color="danger">{{ "labels.hidden" | translate }}</ion-chip>
			</span>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<span *ngIf="computedCustomField.baseSettings.type === typeCustomFields.COUNTRY">
				{{ computedCustomField.fieldDatas.field.country.name }}
			</span>
		</div>
		<!-- <div #itemDiv [style.color]="event.styling.contentTextColor" class="description"
				style="white-space: pre-line;"
				[innerHTML]="item.textValue[userLanguage] | safeHtml">
			</div>    -->
	</div>

	<!-- Phone number field -->
	<!-- <div class="phonenumber-field" *ngIf="
	computedCustomField.baseSettings.type === typeCustomFields.ADDRESS &&
	((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
	((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
		((!computedCustomField.moduleSettings.canBeTag && !computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
			(!computedCustomField.moduleSettings.hiding.list && !isProfilMode)))) &&
			computedCustomField.fieldDatas.field.phoneNumber &&
			computedCustomField.fieldDatas.field.phoneNumber.internationalNumber !== ''
		">
		<div class="item-name" [style.color]="event.styling.titleColor"> -->
	<!-- <ion-icon [src]="getIconCustomField(item.name)" *ngIf="getIconCustomField(item.name)!==''"></ion-icon> -->
	<!-- <span class="b3-icons {{ computedCustomField.baseSettings.icon.iconFamily }} b3app-icons" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
					computedCustomField.baseSettings.icon.iconFamily !== undefined
				">
			</span>
			<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon" *ngIf="
					computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
					computedCustomField.baseSettings.icon.icon !== null
				">{{ computedCustomField.baseSettings.icon.icon }}</i>
				
			<h1 class="custom-field-title">{{ computedCustomField.baseSettings.name[currentLanguage] }}</h1>
		</div>
		<div class="text-value" [style.color]="event.styling.contentTextColor">
			<a href="tel:{{ computedCustomField.fieldDatas.field.phoneNumber.internationalNumber }}">{{
				computedCustomField.fieldDatas.field.phoneNumber.internationalNumber
				}}</a>
		</div>
	</div> -->

	<!-- HTML - Tiny text fields -->
	<!-- Only display if the Tiny / Rich texts fields are filled  -->
	<ion-card class="html-card" *ngIf="
			computedCustomField.baseSettings.type === typeCustomFields.HMTL &&
			((module.type === typeModule.SCHEDULE && !computedCustomField.moduleSettings.hiding.general) ||
				((module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
					((!computedCustomField.moduleSettings.canBeTag &&
						!computedCustomField.moduleSettings.hiding.profil &&
						isProfilMode) ||
						(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
							module,
							modulesCustomsFields,
							eventUserProfile,
							computedCustomField.baseSettings.uid
						) &&
							!computedCustomField.moduleSettings.hiding.list &&
							!isProfilMode)))) &&
			computedCustomField.fieldDatas.field.multiLanguageText &&
			computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] !== ''
		">
		<div class="custom-card">
			<div class="block-content">
				<span class="custom-field-name-block">
					<i class="{{ computedCustomField.baseSettings.icon.iconFamily }} menu-icon custom-field-icon" *ngIf="
							computedCustomField.baseSettings.icon.iconFamily === 'material-icons' &&
							computedCustomField.baseSettings.icon.icon !== ''
						">
						{{ computedCustomField.baseSettings.icon.icon }}
					</i>

					<span class="b3-icons {{
							computedCustomField.baseSettings.icon.iconFamily
						}} b3app-icons custom-field-icon" *ngIf="
							computedCustomField.baseSettings.icon.iconFamily !== 'material-icons' &&
							computedCustomField.baseSettings.icon.iconFamily !== undefined
						">
					</span>

					<ion-label class="custom-field-name">
						{{ computedCustomField.baseSettings.name[currentLanguage] }}
					</ion-label>
				</span>

				<div class="html-card-content"
					[innerHTML]="computedCustomField.fieldDatas.field.multiLanguageText[currentLanguage] | safeHtml">
				</div>
			</div>
		</div>
	</ion-card>

	<!-- user document -->
	<div class="custom-card" *ngIf="
			((!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
				(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
					module,
					modulesCustomsFields,
					eventUserProfile,
					computedCustomField.baseSettings.uid
				) &&
					!computedCustomField.moduleSettings.hiding.list &&
					!isProfilMode)) &&
			computedCustomField.baseSettings.type === typeCustomFields.FILE &&
			computedCustomField.fieldDatas.field.file &&
			computedCustomField.fieldDatas.field.file.url &&
			computedCustomField.fieldDatas.field.file.url !== ''
		">
		<ng-container>
			<div style="display: flex">
				<h6 class="custom-field-name-block" [style.font-family]="event.styling.font"
					[style.color]="event.styling.titleColor">
					<ion-icon class="custom-field-icon" src="assets/icon/Clip.svg"></ion-icon>
					<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
				</h6>
				<!-- Visibility state -->
				<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
					<ion-chip *ngIf="
							!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								module,
								modulesCustomsFields,
								eventUserProfile,
								computedCustomField.baseSettings.uid
							)
						" color="success" style="position: relative; top: 18px !important">{{ "labels.visible" | translate }}</ion-chip>
					<ion-chip *ngIf="
							SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								module,
								modulesCustomsFields,
								eventUserProfile,
								computedCustomField.baseSettings.uid
							)
						" color="danger" style="position: relative; top: 18px !important">{{ "labels.hidden" | translate }}</ion-chip>
				</span>
			</div>

			<ion-list>
				<ion-item id="toto" lines="none" class="align-flex clickable-doc"
					(click)="openDocument(computedCustomField.fieldDatas.field.file, 'user')">
					<ion-thumbnail class="margin-padding-0" slot="start">
						<img src="assets/icon/Files/{{
							typeFormatFiles.includes( computedCustomField.fieldDatas.field.file.type?.split('/')?.[1]) ? computedCustomField.fieldDatas.field.file.type.split('/')[1] : 'generic-file'
							}}_ico.svg" />
					</ion-thumbnail>
					<ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
						<h4 class="margin-padding-0" [style.font-family]="event.styling.font">
							{{ computedCustomField.fieldDatas.field.file.name }}
						</h4>
						<p class="margin-padding-0" [style.font-family]="event.styling.font">
							{{
							SDocument.checkType(
							computedCustomField.fieldDatas.field.file &&
							computedCustomField.fieldDatas.field.file.type &&
							computedCustomField.fieldDatas.field.file.type.toString().split("/")[1]
							)
							}}
						</p>
					</ion-label>
					<ion-icon slot="end" [style.color]="event.styling.btnBgColor" style="cursor: pointer;"
						name="copy-outline"
						(click)="copyToClipboard(computedCustomField.fieldDatas.field.file); $event.stopPropagation()"></ion-icon>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>

	<!-- user Image -->
	<div class="custom-card" *ngIf="
			((!computedCustomField.moduleSettings.hiding.profil && isProfilMode) ||
				(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
					module,
					modulesCustomsFields,
					eventUserProfile,
					computedCustomField.baseSettings.uid
				) &&
					!computedCustomField.moduleSettings.hiding.list &&
					!isProfilMode)) &&
			computedCustomField.baseSettings.type === typeCustomFields.IMAGE &&
			computedCustomField.fieldDatas.field.image &&
			computedCustomField.fieldDatas.field.image.url &&
			computedCustomField.fieldDatas.field.image.url !== ''
		">
		<ng-container>
			<div style="display: flex">
				<h6 class="custom-field-name-block" [style.font-family]="event.styling.font"
					[style.color]="event.styling.titleColor">
					<ion-icon class="custom-field-icon" src="assets/icon/Clip.svg"></ion-icon>
					<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
				</h6>
				<!-- Visibility state -->
				<span *ngIf="module.options.enableUserFieldsHideAbility && isProfilMode" class="visibility-state">
					<ion-chip *ngIf="
							!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								module,
								modulesCustomsFields,
								eventUserProfile,
								computedCustomField.baseSettings.uid
							)
						" color="success" style="position: relative; top: 18px !important">{{ "labels.visible" | translate }}</ion-chip>
					<ion-chip *ngIf="
							SCustomFields.isUserOrModuleFieldsVisibilityHidden(
								module,
								modulesCustomsFields,
								eventUserProfile,
								computedCustomField.baseSettings.uid
							)
						" color="danger" style="position: relative; top: 18px !important">{{ "labels.hidden" | translate }}</ion-chip>
				</span>
			</div>
			<ion-list>
				<ion-item lines="none" class="align-flex clickable-doc"
					(click)="openDocument(computedCustomField.fieldDatas.field.image, 'user')">
					<ion-thumbnail class="margin-padding-0" slot="start">
						<img src="assets/images/picture-2.svg" />
					</ion-thumbnail>
					<ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
						<h4 class="margin-padding-0" [style.font-family]="event.styling.font">
							{{ computedCustomField.fieldDatas.field.image.name }}
						</h4>
						<p class="margin-padding-0" [style.font-family]="event.styling.font">
							{{
							SDocument.checkType(
							computedCustomField.fieldDatas.field.image.format &&
							computedCustomField.fieldDatas.field.image.format.toString()?.split("/")[1]
							)
							}}
						</p>
					</ion-label>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>

	<!-- Attendees -->
	<div class="custom-card" *ngIf="showRelatedDatas(computedCustomField, typeModule.ATTENDEE)">
		<ng-container *ngIf="computedModule.length >= 1">
			<h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
				<ion-icon class="custom-field-icon" src="assets/icon/User3.svg"></ion-icon>
				<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
			</h6>
			<ion-list *ngFor="
					let attendee of returnCustomModuleItemsEventUsers(
						computedCustomField.fieldDatas.field.module.items,
						typeModule.ATTENDEE
					);
					let i = index
				">
				<ion-item lines="none" class="align-flex clickable-doc" (click)="openUserProfil(attendee)">
					<ion-avatar *ngIf="attendee?.photoUrl && attendee?.photoUrl !== ''" [ngClass]="
							attendee?.photoUrl || attendee?.photoUrl !== ''
								? 'event-user-avatar-img'
								: 'event-user-avatar-blank'
						" class="margin-padding-0" slot="start">
						<img [ngClass]="{
								'event-user-avatar-img-blank':
									!attendee?.photoUrl || attendee?.photoUrl === 'event-user-blank-img'
							}" [src]="attendee?.photoUrl" />
					</ion-avatar>
					<ion-label class="margin-padding-0">
						<h4 class="margin-padding-0 custom-fields-event-users-module-item-title"
							[style.color]="event.styling.titleColor">
							{{ attendee.name }}
						</h4>
						<div class="custom-fields-event-user-tag-bloc">
							<div class="margin-padding-0" *ngFor="let customField of getEventUserTags(attendee)">
								<h5 class="custom-fields-event-users-module-item-tags" *ngIf="
										(customField.field.text && customField.field.text?.trim() !== '') ||
										(customField.field.multiLanguageText &&
											customField.field.multiLanguageText[currentLanguage]?.trim() !== '')
									" [style.color]="event.styling.contentTextColor">
									{{
									customField.field.text?.trim() ||
									customField.field.multiLanguageText[currentLanguage]?.trim()
									}}
								</h5>
							</div>
						</div>
					</ion-label>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>

	<!-- Speakers -->
	<div class="custom-card" *ngIf="showRelatedDatas(computedCustomField, typeModule.SPEAKER)">
		<ng-container *ngIf="computedModule.length >= 1">
			<h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
				<ion-icon [style.fontSize]="'1.3em'" class="custom-field-icon" src="assets/icon/User.svg"> </ion-icon>
				<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
			</h6>
			<ion-list *ngFor="
					let speaker of returnCustomModuleItemsEventUsers(
						computedCustomField.fieldDatas.field.module?.items,
						typeModule.SPEAKER
					);
					let i = index
				">
				<ion-item lines="none" class="align-flex clickable-doc" (click)="openUserProfil(speaker)" [ngStyle]="
						!speaker.photoUrl || speaker.photoUrl === ''
							? { 'padding-left': '0px', left: '-10px' }
							: { 'padding-left': '0px', left: '-10px' }
					">
					<ion-avatar *ngIf="speaker?.photoUrl && speaker?.photoUrl !== ''" class="event-user-avatar-blank"
						class="margin-padding-0" slot="start" style="color: rgb(133, 133, 133)">
						<img [ngClass]="{
								'event-user-avatar-img-blank':
									!speaker?.photoUrl || speaker?.photoUrl === 'event-user-blank-img'
							}" *ngIf="!speaker?.photoUrl || speaker?.photoUrl === ''" src="assets/icon/User2.svg" />
						<img *ngIf="speaker?.photoUrl && speaker?.photoUrl !== ''" [src]="speaker?.photoUrl" />
					</ion-avatar>
					<ion-label class="margin-padding-0" style="margin: 0 !important; padding: 0 !important" [ngStyle]="
							!speaker.photoUrl || speaker.photoUrl === ''
								? { position: 'relative', left: '0px' }
								: { position: 'relative', left: '8px' }
						">
						<h4 [style.color]="event.styling.titleColor"
							class="margin-padding-0 custom-fields-event-users-module-item-title">
							{{ speaker.name }}
						</h4>
						<div class="custom-fields-event-user-tag-bloc">
							<div class="margin-padding-0" *ngFor="let customField of getEventUserTags(speaker)">
								<h5 class="custom-fields-event-users-module-item-tags" *ngIf="
										(customField.field.text && customField.field.text?.trim() !== '') ||
										(customField.field.multiLanguageText &&
											customField.field.multiLanguageText[currentLanguage]?.trim() !== '')
									" [style.color]="event.styling.contentTextColor">
									{{
									customField.field.text?.trim() ||
									customField.field.multiLanguageText[currentLanguage]?.trim()
									}}
								</h5>
							</div>
						</div>
					</ion-label>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>

	<!-- Session -->
	<!-- <div class="custom-card" *ngIf="showRelatedDatas(computedCustomField, typeModule.SCHEDULE)">
        <ng-container *ngIf="computedModule.length >= 1">
            <h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
                <ion-icon class="custom-field-icon" src="assets/icon/Calendar.svg"></ion-icon>
                <span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
            </h6>
            <ion-list *ngFor="
					let session of returnCustomModuleItems(
						computedCustomField.fieldDatas.field.module?.items,
						typeModule.SCHEDULE
					); let i = index
				">
                <ion-item lines="none" class="align-flex clickable-doc" (click)="openSession(session)">
                    <ion-avatar class="margin-padding-0 session-avatar" slot="start">
                        <img style="border-radius: 5px" src="assets/icon/Calendar.svg" />
                    </ion-avatar>
                    <ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
                        <h4 class="margin-padding-0 custom-fields-session-module-item-title">
                            {{ session.name[currentLanguage] }}
                        </h4>
                        <p class="margin-padding-0">
                            {{ session.startDate | dateFormat }}
                            {{ session.endDate ? "- " + getPartOfDate(session.endDate, "time") : "" }}
                        </p>
                    </ion-label>
                </ion-item>

            </ion-list>
        </ng-container>
    </div> -->

	<!-- Schedule module sessions for Schedule module -->
	<ng-container *ngIf="
			module.type === typeModule.SCHEDULE &&
			computedCustomField.baseSettings.type === typeCustomFields.MODULE &&
			showRelatedDatas(computedCustomField, typeModule.SCHEDULE) &&
			computedModule.length >= 1 &&
			getSessionsOfCustomField(computedCustomField.baseSettings.uid)?.length > 0
		">
		<div class="container-profile speakers-sessions" style="margin-left: 0px !important">
			<h6 class="speakers-sessions-name-block" [style.color]="event.styling.titleColor">
				<ion-icon class="speakers-sessions-icon" src="assets/icon/Calendar.svg"></ion-icon>
				<span class="speakers-sessions-title">{{ "labels.sessions" | translate }}</span>
			</h6>
			<div class="viewHour" [ngClass]="isMobile ? 'relative-top-12' : 'relative-top-8'" [style.padding]="'0'">
				<!-- <div class="download-agenda-container">
                    <ion-button *ngIf="module.options.allowSessionsDownload" [style.color]="event.styling.menuTextColor"
                        [style.background]="event.styling.menuColor" class="header-ics-download"
                        (click)="SSchedules.downloadSchedule(event, module)">
                        {{ "schedule.download_schedule" | translate }}
                    </ion-button>
                </div> -->

				<ion-list *ngFor="
						let date of SSchedules.getPaginatedDates(
							sessions,
							getSessionsOfCustomField(computedCustomField.baseSettings.uid)
						)
					" style="padding: 0">
					<ion-list-header>
						<ion-label [style.color]="event.styling.titleColor" class="header-date">
							{{ SUtility.getPartOfDate(event, myEventUser, date, "full") | titlecase }}
						</ion-label>
					</ion-list-header>
					<div id="{{ session.uid }}" [hidden]="
							!getSessionsOfCustomField(computedCustomField.baseSettings.uid) ||
							getSessionsOfCustomField(computedCustomField.baseSettings.uid).length === 0
						" *ngFor="
							let session of getSessionsOfCustomField(computedCustomField.baseSettings.uid);
							let i = index
						" class="session-container">
						<div class="session-item" *ngIf="SSchedules.checkSessionDate(session, date)" [style.background]="
								SSchedules.getFirstTrackForSession(session, tracks)
									? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
										? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor
										: SSchedules.getFirstTrackForSession(session, tracks).textColor
									: event.styling.titleColor
							" [style.margin-top]="i === 0 && 0" (click)="openSession(session)" [style.width]="
								SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload
									? 'calc(100% - 0px)'
									: '100%'
							">
							<div class="label-bg" [style.border]="
									session.tracks.length === 0
										? '1px solid ' + event.styling.titleColor + '33'
										: 'none'
								">
								<div [style.background]="
										SSchedules.getFirstTrackForSession(session, tracks)
											? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes(
													'#fff'
											  )
												? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor +
												  '1A'
												: SSchedules.getFirstTrackForSession(session, tracks).textColor + '1A'
											: '#fff'
									" class="label">
									<div>
										<small [hidden]="
												!SSchedules.getModuleOfSession(session.moduleId, sessionModules)
													?.options.baseFields?.track?.canBeTag
											" class="track-name" *ngFor="let trackId of session.tracks" [style.color]="
												SSchedules.getSpecificTrack(trackId, tracks)
													? SSchedules.getSpecificTrack(trackId, tracks).textColor
													: ''
											" [style.background]="
												SSchedules.getSpecificTrack(trackId, tracks)
													? SSchedules.getSpecificTrack(trackId, tracks).backgroundColor
													: ''
											">
											{{
											SSchedules.getSpecificTrack(trackId, tracks) &&
											SSchedules.getSpecificTrack(trackId, tracks).name[
											eventUserProfile &&
											SSchedules.getSpecificTrack(trackId, tracks).name[
											currentLanguage
											]
											? currentLanguage
											: currentLanguage
											]
											}}
										</small>
									</div>
									<div [style.color]="event.styling.titleColor" class="session-name">
										{{
										session.name[
										eventUserProfile && session.name[currentLanguage]
										? currentLanguage
										: currentLanguage
										]
										}}
									</div>
									<div [style.color]="event.styling.contentTextColor" class="session-time">
										<span *ngIf="session.startDate">
											{{
											SUtility.getPartOfDate(event, myEventUser, session.startDate, "time")
											| uppercase
											}}
										</span>
										<!-- {{ getPartOfDate(session.startDate, "time") }} -->
										-
										<span *ngIf="session.endDate">
											{{
											SUtility.getPartOfDate(event, myEventUser, session.endDate, "time")
											| uppercase
											}}
										</span>
										<ion-icon *ngIf="
												checkSessionDate(session, date) && module.options.allowSessionsDownload
											" style="
												float: right;
												position: relative;
												right: 10px;
												width: 25px;
												height: 25px;
											" fill="{{ event.styling.menuColor }}" (click)="openDownloadModal(session, $event)"
											class="material-icons ics-download"
											src="assets/images/calendar_colloqium.svg" alt="download"></ion-icon>
									</div>
									<div style="white-space: pre-line" *ngIf="
											SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												.baseFields?.description?.canBeTag
										" [innerHTML]="
											session.description[
												eventUserProfile && session.description[currentLanguage]
													? currentLanguage
													: currentLanguage
											] | safeHtml
										" class="session-description"></div>
									<br />
									<div>
										<small [hidden]="
												!SSchedules.getModuleOfSession(session.moduleId, sessionModules)
													?.options.baseFields?.location?.canBeTag
											" style="display: inline-flex; align-items: center; margin-right: 5px"
											*ngFor="let locationId of session.locations"
											[style.color]="event.styling.btnBgColor">
											<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
											<span>{{
												SSchedules.getSpecificLocation(locationId, locations)?.name
												}}</span>
										</small>
									</div>
								</div>
							</div>
						</div>
						<!-- <div *ngIf="SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload"
                            [style.background]="event.styling.menuColor" [style.color]="event.styling.menuTextColor"
                            [ngStyle]="{ 'margin-top': i === 0 ? '0px' : '10px' }" class="session-item-ics">
                            <i (click)="SSchedules.linkEventToGoogleCalendar(session, currentLanguage)"
                                class="google-calendar"><img
                                    src="https://img.icons8.com/color/50/000000/google-calendar--v1.png" />
                            </i>
                            <i (click)="SSchedules.generateIcsFile(event.uid, module.uid, session.uid, currentLanguage, 0)"
                                class="material-icons ics-download">file_download</i>
                        </div> -->
					</div>
				</ion-list>
			</div>
		</div>
	</ng-container>

	<!-- Schedule module session for eventUsers module -->
	<ng-container *ngIf="
			(module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER) &&
			computedCustomField.baseSettings.type === typeCustomFields.MODULE &&
			showRelatedDatas(computedCustomField, typeModule.SCHEDULE) &&
			getSessionsOfCustomField(computedCustomField.baseSettings.uid)?.length > 0
		">
		<div class="container-profile speakers-sessions" style="margin-left: 0px !important">
			<h6 class="speakers-sessions-name-block" [style.color]="event.styling.titleColor">
				<ion-icon class="speakers-sessions-icon" src="assets/icon/Calendar.svg"></ion-icon>
				<span class="speakers-sessions-title">{{ "labels.sessions" | translate }}</span>
			</h6>
			<div class="viewHour" [ngClass]="isMobile ? 'relative-top-12' : 'relative-top-8'" [style.padding]="'0'">
				<div class="download-agenda-container">
					<!-- <ion-button *ngIf="module.options.allowSessionsDownload" [style.color]="event.styling.menuTextColor"
                        [style.background]="event.styling.menuColor" class="header-ics-download"
                        (click)="SSchedules.downloadSchedule(event, module)">
                        {{ "schedule.download_schedule" | translate }}
                    </ion-button> -->
				</div>

				<ion-list *ngFor="
						let date of SSchedules.getPaginatedDates(
							sessions,
							getSessionsOfCustomField(computedCustomField.baseSettings.uid)
						)
					" style="padding: 0">
					<ion-list-header>
						<ion-label [style.color]="event.styling.titleColor" class="header-date">
							{{ SUtility.getPartOfDate(event, myEventUser, date, "full") | titlecase }}
						</ion-label>
					</ion-list-header>
					<div id="{{ session.uid }}" [hidden]="
							!getSessionsOfCustomField(computedCustomField.baseSettings.uid) ||
							getSessionsOfCustomField(computedCustomField.baseSettings.uid).length === 0
						" *ngFor="
							let session of getSessionsOfCustomField(computedCustomField.baseSettings.uid);
							let i = index
						" class="session-container">
						<div class="session-item" *ngIf="SSchedules.checkSessionDate(session, date)" [style.background]="
								SSchedules.getFirstTrackForSession(session, tracks)
									? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
										? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor
										: SSchedules.getFirstTrackForSession(session, tracks).textColor
									: event.styling.titleColor
							" [style.margin-top]="i === 0 && 0" (click)="openSession(session)" [style.width]="
								SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload
									? 'calc(100% - 0px)'
									: '100%'
							">
							<div class="label-bg" [style.border]="
									session.tracks.length === 0
										? '1px solid ' + event.styling.titleColor + '33'
										: 'none'
								">
								<div [style.background]="
										SSchedules.getFirstTrackForSession(session, tracks)
											? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes(
													'#fff'
											  )
												? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor +
												  '1A'
												: SSchedules.getFirstTrackForSession(session, tracks).textColor + '1A'
											: '#fff'
									" class="label">
									<div>
										<small [hidden]="
												!SSchedules.getModuleOfSession(session.moduleId, sessionModules)
													?.options.baseFields?.track?.canBeTag
											" class="track-name" *ngFor="let trackId of session.tracks" [style.color]="
												SSchedules.getSpecificTrack(trackId, tracks)
													? SSchedules.getSpecificTrack(trackId, tracks).textColor
													: ''
											" [style.background]="
												SSchedules.getSpecificTrack(trackId, tracks)
													? SSchedules.getSpecificTrack(trackId, tracks).backgroundColor
													: ''
											">
											{{
											SSchedules.getSpecificTrack(trackId, tracks) &&
											SSchedules.getSpecificTrack(trackId, tracks).name[
											eventUserProfile &&
											SSchedules.getSpecificTrack(trackId, tracks).name[
											currentLanguage
											]
											? currentLanguage
											: currentLanguage
											]
											}}
										</small>
									</div>
									<div [style.color]="event.styling.titleColor" class="session-name">
										{{
										session.name[
										eventUserProfile && session.name[currentLanguage]
										? currentLanguage
										: currentLanguage
										]
										}}
									</div>
									<div [style.color]="event.styling.contentTextColor" class="session-time">
										<span *ngIf="session.startDate">
											{{
											SUtility.getPartOfDate(event, myEventUser, session.startDate, "time")
											| uppercase
											}}
										</span>
										<!-- {{ getPartOfDate(session.startDate, "time") }} -->
										-
										<span *ngIf="session.endDate">
											{{
											SUtility.getPartOfDate(event, myEventUser, session.endDate, "time")
											| uppercase
											}}
										</span>
										<ion-icon *ngIf="
												checkSessionDate(session, date) && module.options.allowSessionsDownload
											" style="
												float: right;
												position: relative;
												right: 10px;
												width: 25px;
												height: 25px;
											" fill="{{ event.styling.menuColor }}" (click)="openDownloadModal(session, $event)"
											class="material-icons ics-download"
											src="assets/images/calendar_colloqium.svg" alt="download"></ion-icon>
									</div>
									<div style="white-space: pre-line" *ngIf="
											SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												?.baseFields?.description?.canBeTag
										" [innerHTML]="
											session.description[
												eventUserProfile && session.description[currentLanguage]
													? currentLanguage
													: currentLanguage
											] | safeHtml
										" class="session-description"></div>
									<br />
									<div>
										<small [hidden]="
												!SSchedules.getModuleOfSession(session.moduleId, sessionModules)
													?.options?.baseFields?.location?.canBeTag
											" style="display: inline-flex; align-items: center; margin-right: 5px"
											*ngFor="let locationId of session.locations"
											[style.color]="event.styling.btnBgColor">
											<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
											<span>{{
												SSchedules.getSpecificLocation(locationId, locations)?.name
												}}</span>
										</small>

										<br />

										<!-- Agenda name -->
										<small style="display: inline-flex; align-items: center; margin-right: 5px"
											[style.color]="event.styling.btnBgColor">
											<ion-icon src="assets/icon/Calendar.svg"></ion-icon>&nbsp;

											<span>{{
												SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.name[
												eventUserProfile
												? eventUserProfile.updatedSettings.language
												: event.language
												]
												}}</span>
										</small>
									</div>
								</div>
							</div>
						</div>
						<!-- <div *ngIf="SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload"
                            [style.background]="event.styling.menuColor" [style.color]="event.styling.menuTextColor"
                            [ngStyle]="{ 'margin-top': i === 0 ? '0px' : '10px' }" class="session-item-ics">
                            <i (click)="SSchedules.linkEventToGoogleCalendar(session, currentLanguage)"
                                class="google-calendar"><img
                                    src="https://img.icons8.com/color/50/000000/google-calendar--v1.png" />
                            </i>
                            <i (click)="SSchedules.generateIcsFile(event.uid, module.uid, session.uid, currentLanguage, 0)"
                                class="material-icons ics-download">file_download</i>
                        </div> -->
					</div>
				</ion-list>
			</div>
		</div>
	</ng-container>

	<!-- Tracks -->
	<div class="custom-card" *ngIf="showRelatedDatas(computedCustomField, typeModule.TRACKS)">
		<div class="track-custom-field-name-block">
			<img class="custom-field-icon-img" src="assets/icon/Filter.svg" />
			<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
		</div>
		<div class="track-bloc-content" *ngFor="
				let track of returnCustomModuleItems(
					computedCustomField.fieldDatas.field.module.items,
					typeModule.TRACKS
				);
				let i = index
			">
			<span class="custom-field-track-name-block" [style.background-color]="track.backgroundColor">
				<img class="custom-field-icon-img doc-avatar" src="assets/images/filter-list.png" />

				<h4 class="margin-padding-0 custom-fields-track-module-item-title">
					{{ track.name[currentLanguage] }}
				</h4>
				<p class="margin-padding-0"></p>
			</span>
		</div>
	</div>

	<!-- Documents module -->
	<div class="custom-card" *ngIf="showRelatedDatas(computedCustomField, typeModule.DOCUMENT)">
		<ng-container *ngIf="computedModule.length >= 1">
			<h6 class="custom-field-name-block" [style.color]="event.styling.titleColor">
				<ion-icon class="custom-field-icon" src="assets/icon/Clip.svg"></ion-icon>
				<span class="custom-field-name">{{ computedCustomField.baseSettings.name[currentLanguage] }}</span>
			</h6>
			<ion-list *ngFor="
					let document of returnCustomModuleItems(
						computedCustomField.fieldDatas.field.module.items,
						typeModule.DOCUMENT
					);
					let i = index
				">
				<ion-item lines="none" class="align-flex clickable-doc" (click)="openDocument(document, 'module')">
					<ion-thumbnail class="margin-padding-0" slot="start">
						<img src="assets/icon/Files/{{ document.icon | splitFirst : ['.'] }}.svg" />
					</ion-thumbnail>
					<ion-label class="margin-padding-0" [style.color]="event.styling.titleColor">
						<h4 class="margin-padding-0 custom-fields-document-module-item-title">
							{{ document.name[currentLanguage] }}
						</h4>
						<p class="margin-padding-0 custom-fields-document-module-item-type">
							{{ SDocument.checkType(document.type) }}
						</p>
					</ion-label>
					<ion-icon slot="end" [style.color]="event.styling.btnBgColor" style="cursor: pointer;"
						name="copy-outline" (click)="copyToClipboard(document); $event.stopPropagation()"></ion-icon>
				</ion-item>
			</ion-list>
		</ng-container>
	</div>
</div>

<!-- Base Schedule module sessions -->
<ng-container *ngIf="eventUserSessions && eventUserSessions.length > 0">
	<div class="container-profile speakers-sessions">
		<h6 class="speakers-sessions-name-block" [style.color]="event.styling.titleColor">
			<ion-icon class="speakers-sessions-icon" src="assets/icon/Calendar.svg"></ion-icon>
			<span class="speakers-sessions-title">{{ "labels.sessions" | translate }}</span>
		</h6>
		<div *ngIf="!module.options.activateTracksFiltering" class="viewHour"
			[ngClass]="isMobile ? 'relative-top-12' : 'relative-top-8'" [style.padding]="'0'">
			<div class="download-agenda-container">
				<ion-button *ngIf="module.options.allowSessionsDownload" [style.color]="event.styling.menuTextColor"
					[style.background]="event.styling.menuColor" class="header-ics-download"
					(click)="SSchedules.downloadSchedule(event, module)">
					{{ "schedule.download_schedule" | translate }}
				</ion-button>
			</div>

			<ion-list *ngFor="
					let date of SSchedules.getPaginatedDates(
						sessions,
						eventUserSessions ? eventUserSessions : sessionSchedules
					)
				" style="padding: 0">
				<ion-list-header>
					<ion-label [style.color]="event.styling.titleColor" class="header-date">
						{{ SUtility.getPartOfDate(event, myEventUser, date, "full") | titlecase }}
					</ion-label>
				</ion-list-header>
				<div id="{{ session.uid }}"
					*ngFor="let session of eventUserSessions ? eventUserSessions : sessionSchedules; let i = index"
					class="session-container">
					<div class="session-item" *ngIf="SSchedules.checkSessionDate(session, date)" [style.background]="
							SSchedules.getFirstTrackForSession(session, tracks)
								? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
									? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor
									: SSchedules.getFirstTrackForSession(session, tracks).textColor
								: event.styling.titleColor
						" [style.margin-top]="i === 0 && 0" (click)="openSession(session)" [style.width]="
							SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload
								? 'calc(100% - 0px)'
								: '100%'
						">
						<div class="label-bg" [style.border]="
								session.tracks.length === 0 ? '1px solid ' + event.styling.titleColor + '33' : 'none'
							">
							<div [style.background]="
									SSchedules.getFirstTrackForSession(session, tracks)
										? SSchedules.getFirstTrackForSession(session, tracks).textColor.includes('#fff')
											? SSchedules.getFirstTrackForSession(session, tracks).backgroundColor + '1A'
											: SSchedules.getFirstTrackForSession(session, tracks).textColor + '1A'
										: '#fff'
								" class="label">
								<div>
									<small [hidden]="
											!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												.baseFields?.track?.canBeTag
										" class="track-name" *ngFor="let trackId of session.tracks" [style.color]="
											SSchedules.getSpecificTrack(trackId, tracks)
												? SSchedules.getSpecificTrack(trackId, tracks).textColor
												: ''
										" [style.background]="
											SSchedules.getSpecificTrack(trackId, tracks)
												? SSchedules.getSpecificTrack(trackId, tracks).backgroundColor
												: ''
										">
										{{
										SSchedules.getSpecificTrack(trackId, tracks) &&
										SSchedules.getSpecificTrack(trackId, tracks).name[
										eventUserProfile &&
										SSchedules.getSpecificTrack(trackId, tracks).name[currentLanguage]
										? currentLanguage
										: currentLanguage
										]
										}}
									</small>
								</div>
								<div [style.color]="event.styling.titleColor" class="session-name">
									{{
									session.name[
									eventUserProfile && session.name[currentLanguage]
									? currentLanguage
									: currentLanguage
									]
									}}
								</div>
								<div [style.color]="event.styling.contentTextColor" class="session-time">
									<span *ngIf="session.startDate">
										{{
										SUtility.getPartOfDate(event, myEventUser, session.startDate, "time")
										| uppercase
										}}
									</span>
									-
									<span *ngIf="session.endDate">
										{{
										SUtility.getPartOfDate(event, myEventUser, session.endDate, "time")
										| uppercase
										}}
									</span>
									<ion-icon
										*ngIf="checkSessionDate(session, date) && module.options.allowSessionsDownload"
										style="float: right; position: relative; right: 10px; width: 25px; height: 25px"
										fill="{{ event.styling.menuColor }}"
										(click)="openDownloadModal(session, $event)" class="material-icons ics-download"
										src="assets/images/calendar_colloqium.svg" alt="download"></ion-icon>
								</div>
								<div style="white-space: pre-line" *ngIf="
										SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
											.baseFields?.description?.canBeTag
									" [innerHTML]="
										session.description[
											eventUserProfile && session.description[currentLanguage]
												? currentLanguage
												: currentLanguage
										] | safeHtml
									" class="session-description"></div>
								<br />
								<div>
									<small [hidden]="
											!SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.options
												.baseFields?.location?.canBeTag
										" style="display: inline-flex; align-items: center; margin-right: 5px"
										*ngFor="let locationId of session.locations"
										[style.color]="event.styling.btnBgColor">
										<ion-icon src="assets/icon/Map.svg"></ion-icon>&nbsp;
										<span>{{ SSchedules.getSpecificLocation(locationId, locations)?.name }}</span>
									</small>

									<br />

									<!-- Agenda name -->
									<small style="display: inline-flex; align-items: center; margin-right: 5px"
										[style.color]="event.styling.btnBgColor">
										<ion-icon src="assets/icon/Calendar.svg"></ion-icon>&nbsp;

										<span>{{
											SSchedules.getModuleOfSession(session.moduleId, sessionModules)?.name[
											eventUserProfile
											? eventUserProfile.updatedSettings.language
											: event.language
											]
											}}</span>
									</small>
								</div>
							</div>
						</div>
					</div>
					<!-- <div *ngIf="SSchedules.checkSessionDate(session, date) && module.options.allowSessionsDownload"
                        [style.background]="event.styling.menuColor" [style.color]="event.styling.menuTextColor"
                        [ngStyle]="{ 'margin-top': i === 0 ? '0px' : '10px' }" class="session-item-ics">
                        <i (click)="SSchedules.linkEventToGoogleCalendar(session, currentLanguage)"
                            class="google-calendar"><img
                                src="https://img.icons8.com/color/50/000000/google-calendar--v1.png" />
                        </i>
                        <i (click)="SSchedules.generateIcsFile(event.uid, module.uid, session.uid, currentLanguage, 0)"
                            class="material-icons ics-download">file_download</i>
                    </div> -->
				</div>
			</ion-list>
		</div>
	</div>
</ng-container>