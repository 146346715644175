import { createAction, props } from "@ngrx/store";
import { IDocument, IGalleryEmbededVideo, IGalleryImage } from "../interfaces/documents.interfaces";
import { IFolder } from "../interfaces/folders.interfaces";

export enum DocumentsActionsTypes {
	GetAllDocuments = "[Documents Service] Get all documents",
	GetAllFolders = "[Documents Service] Get all folders",
	GetAllImages = "[Documents Service] Get all images",
	GetAllVideos = "[Documents Service] Get all embeded videos",
	ResetDocumentsState = "[Documents Service] Reset documents state"
}

export const GetAllDocuments = createAction(
	DocumentsActionsTypes.GetAllDocuments,
	props<{ payload: IDocument[]; eventId: string }>()
);

export const GetAllImages = createAction(
	DocumentsActionsTypes.GetAllImages,
	props<{ payload: IGalleryImage[]; eventId: string }>()
);

export const GetAllVideos = createAction(
	DocumentsActionsTypes.GetAllVideos,
	props<{ payload: IGalleryEmbededVideo[]; eventId: string }>()
);

export const GetAllFolders = createAction(
	DocumentsActionsTypes.GetAllFolders,
	props<{ payload: IFolder[]; eventId: string }>()
);

export const ResetDocumentsState = createAction(DocumentsActionsTypes.ResetDocumentsState, props<any>());
