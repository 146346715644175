<ion-header>
    <ion-toolbar class="ion-color" [style.background]="event.styling.menuColor"
        [style.color]="event.styling.menuTextColor">
        <ion-buttons slot="start">
            <ion-button slot="icon-only" (click)="close()">
                <ion-icon src="assets/icon/Close.svg"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>{{"chat.create_chat" | translate}}</ion-title>
    </ion-toolbar>
</ion-header>
<ion-content class="hide-scrollbar" style="--background: var(--bg-color-content);">
    <div class="background" style="width: 100% !important;">
        <form *ngIf="event && module && currentUser" class="full-h f-row-c-c" [formGroup]="chatForm">
            <ion-grid class="ion-padding">
                <!-- Input Name  -->
                <ion-row>
                    <ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
                        <ion-item lines="none" mode="md" class="input-item">
                            <ion-label position="floating" mode="md" class="input-label-text  label-color">
                                {{ "labels.name" | translate }}</ion-label>
                            <ion-input autocapitalize=sentences mode="md" class="custom-input email-input input-color"
                                type="name" formControlName="name">
                            </ion-input>
                        </ion-item>
                    </ion-col>
                </ion-row>

                <ion-row>
                    <ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
                        <ion-item lines="none" mode="md" class="input-item">
                            <ion-label position="floating" mode="md" class="input-label-text  label-color">
                                {{ "labels.members" | translate }}</ion-label>
                            <ion-input autocapitalize=sentences mode="md" class="custom-input email-input input-color"
                                type="name" formControlName="queryMembers">
                            </ion-input>
                        </ion-item>
                        <div class="form-group">
                            <div class="filter-group" *ngIf="filteredMembers.length > 0">
                                <ul>
                                    <li *ngFor="let member of filteredMembers">
                                        <div class="member-name-container"
                                            [style.background]="event.styling.bgContentColor"
                                            [style.color]="event.styling.contentTextColor">
                                            <a class="member-name-link" [style.color]="event.styling.contentTextColor"
                                                (click)="selectMember(member)">
                                                {{ member.name }}
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                                <br /><br />
                            </div>

                            <div class="filter-member" *ngIf="selectedMembers.length > 0">
                                <span *ngFor="let member of selectedMembers" class="member-name-container"
                                    [style.background]="event.styling.bgContentColor"
                                    [style.color]="event.styling.contentTextColor">
                                    <span class="member-name">{{ member.name }}</span>
                                    <i class="material-icons closebtn remove-btn"
                                        (click)="removeMember(member)">close</i>
                                </span>
                                <br />
                            </div>
                        </div>
                    </ion-col>
                </ion-row>

                <!-- Buttons-->
                <ion-row>
                    <ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3" text-center>
                        <ion-button shape="round" class="btn-default access-btn"
                            [style.--background]="event.styling.btnBgColor" (click)="createChat()"
                            [disabled]="creating">
                            <span *ngIf="!creating">{{"buttons.create"| translate}}</span>
                            <ion-spinner name="crescent" color="light" *ngIf="creating"></ion-spinner>
                        </ion-button>
                    </ion-col>
                </ion-row>

            </ion-grid>
        </form>
    </div>
</ion-content>