<div *ngIf="event && module && eventUser && module.options && module.options.rules" class="background"
	style="padding: 10px">

	<app-timezone-switch [event]="event" [disposition]="'row'" [height]="'auto'"></app-timezone-switch>

	<div [style.color]="event.styling.contentTextColor"
		style="font-size: 16px; font-style: normal; font-weight: 700; line-height: normal;">
		{{ "appointments.select-disponibilities" | translate }}
	</div>

	<mat-accordion *ngFor="let rule of filterRules(module.options.rules); let i = index">
		<mat-expansion-panel style="margin: 10px 0; border: 1px solid #e0e0e0; box-shadow: none"
			[expanded]="i === 0 ? true : false">
			<mat-expansion-panel-header>
				<mat-panel-title style="font-weight: bold;" [style.color]="event.styling.contentTextColor">
					{{ rule.ruleName }}
				</mat-panel-title>
			</mat-expansion-panel-header>
			<div style="width: 100%; margin-bottom: 10px">
				<ion-select mode="md" interface="action-sheet" [compareWith]="compareWith"
					(ionChange)="handleChange($event)" [style.--padding-bottom]="'5px'" [style.--padding-end]="'5px'"
					[style.--padding-start]="'5px'" [style.--padding-top]="'5px'"
					style="width: 100%; border: 0.5px solid rgba(0, 0, 0, 0.20); border-radius: 3px" mode="md"
					[style.--placeholder-color]="event.styling.contentTextColor" [style.--placeholder-opacity]="1"
					placeholder="{{ 'appointments.select-day' | translate }}" type="text">
					<ion-select-option *ngFor="let data of filterDaysForRuleAndEventUser(rule);" [value]="data">
						{{ getPartOfDate(data.day, "full") | titlecase }}
					</ion-select-option>
				</ion-select>
			</div>
			<div *ngIf="selectedDay" class="slots-container" style="border-top: 1px solid #e0e0e0; padding-top: 20px;">
				<ng-container *ngFor="let schedule of selectedDay.schedules">
					<div *ngIf="showSchedule(schedule)" class="slot"
						(click)="schedule.disabledEventUser = !schedule.disabledEventUser;"
						[style.border]="!schedule.disabledEventUser ? '1px solid ' + event.styling.titleColor : '1px solid transparent'"
						[style.background]="!schedule.disabledEventUser ? event.styling.btnBgColor : event.styling.titleColor + '14'"
						[style.color]="!schedule.disabledEventUser ? event.styling.btnTextColor : event.styling.titleColor"
						[style.font-weight]="!schedule.disabledEventUser ? 700 : 600">
						{{ getPartOfDate(schedule.startSchedule, "time") }}
					</div>
				</ng-container>
			</div>
		</mat-expansion-panel>
	</mat-accordion>

	<div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%;">
		<div (click)="modifySchedule()" [style.background-color]="event.styling.titleColor"
			[style.border-color]="event.styling.titleColor" [style.color]="event.styling.btnTextColor"
			style="display: flex; flex-direction: row; align-items: center; justify-content: center; text-align: center; border: 1px solid; font-size: 14px; font-weight: 700; border-radius: 28px; padding: 10px 20px; margin: 10px; cursor: pointer;">
			{{ "buttons.answer" | translate }}
		</div>
	</div>
</div>