import { createReducer, on } from "@ngrx/store";
import * as VideoPlayerActions from "../actions/video-player.actions";
import { IVideoPlayerState } from "../interfaces/video-player.interfaces";

export const initialState: IVideoPlayerState = {
	miniVideoPlayer: {
		activated: false,
		link: {
			moduleType: null,
			moduleId: null,
			itemId: null
		},
		options: {
			state: null
		},
		src: null,
		start: null,
		type: null
	},
	videoPlayer: {
		activated: false,
		link: {
			moduleType: null,
			moduleId: null,
			itemId: null
		},
		options: {
			state: null
		},
		src: null,
		start: null,
		type: null
	}
};

export const videoPlayerFeatureKey = "videoPlayer";

export const videoPlayerReducer = createReducer(
	initialState,
	on(VideoPlayerActions.GetVideoPlayer, (state, action) => action.payload),
	on(VideoPlayerActions.UpdateMiniVideoPlayer, (state, action) => ({
		...state,
		miniVideoPlayer: action.payload
	})),
	on(VideoPlayerActions.UpdateMiniVideoPlayerActivated, (state, action) => ({
		...state,
		miniVideoPlayer: {
			...state.miniVideoPlayer,
			activated: action.payload
		}
	})),
	on(VideoPlayerActions.UpdateMiniVideoPlayerState, (state, action) => ({
		...state,
		miniVideoPlayer: {
			...state.miniVideoPlayer,
			options: {
				state: action.payload
			}
		}
	})),
	on(VideoPlayerActions.UpdateMiniVideoPlayerTime, (state, action) => ({
		...state,
		miniVideoPlayer: {
			...state.miniVideoPlayer,
			start: action.payload
		}
	})),
	on(VideoPlayerActions.UpdateMiniVideoPlayerLink, (state, action) => ({
		...state,
		miniVideoPlayer: {
			...state.videoPlayer,
			link: action.payload
		}
	})),
	on(VideoPlayerActions.UpdateBasicVideoPlayer, (state, action) => ({
		...state,
		videoPlayer: action.payload
	})),
	on(VideoPlayerActions.UpdateBasicVideoPlayerState, (state, action) => ({
		...state,
		videoPlayer: {
			...state.videoPlayer,
			options: {
				state: action.payload
			}
		}
	})),
	on(VideoPlayerActions.UpdateBasicVideoPlayerTime, (state, action) => ({
		...state,
		videoPlayer: {
			...state.videoPlayer,
			start: action.payload
		}
	})),
	on(VideoPlayerActions.UpdateBasicVideoPlayerLink, (state, action) => ({
		...state,
		videoPlayer: {
			...state.videoPlayer,
			link: action.payload
		}
	})),
	on(VideoPlayerActions.ResetStateVideoPlayer, () => ({
		...initialState
	}))
);
