import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { Component, Inject, OnInit } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { TranslateService } from "@ngx-translate/core";
import { IEvent, IEventUser } from "src/app/shared/interfaces";
import { IRegisterForm } from "src/app/shared/interfaces/register-form.interfaces";
import { RegisterFormService } from "src/app/shared/services/register-form.service";
import { ModalController } from "@ionic/angular";
import { EventUsersService } from "src/app/shared/services";

@Component({
	selector: "app-accompanying-invite-modal",
	templateUrl: "./accompanying-invite-modal.component.html",
	styleUrls: ["./accompanying-invite-modal.component.scss"]
})
export class AccompanyingInviteModalComponent implements OnInit {
	event: IEvent;
	eventUser: IEventUser;
	registerForm: IRegisterForm;
	remainingPlaces: number;

	constructor(
		private SRegisterForm: RegisterFormService,
		private snackbar: MatSnackBar,
		private STranslate: TranslateService,
		private modalCtrl: ModalController,
		private SEventUsers: EventUsersService
	) {}

	ngOnInit() {}

	/**
	 * openAccompanyingRegisterForm
	 */
	async openAccompanyingRegisterForm() {
		try {
			if (!this.event || !this.eventUser || !this.registerForm) {
				throw new Error("Missing data");
			}
			await this.SRegisterForm.openAccompanyingForm(this.event, this.eventUser, this.registerForm);
			this.modalCtrl.dismiss(
				{
					reOpenModal: {
						accompanyingModal: false,
						inviteModal: true,
						confirmModal: false
					}
				},
				null,
				"accompanying-invite-modal"
			);
		} catch (error) {
			this.snackbar.open(this.STranslate.instant("snackbar.error_occured"), "", {
				duration: 3000,
				panelClass: ["error-snackbar"]
			});
		}
	}

	closeModal() {
		this.modalCtrl.dismiss(
			{
				reOpenModal: {
					accompanyingModal: false,
					inviteModal: false,
					confirmModal: false
				}
			},
			null,
			"accompanying-invite-modal"
		);
	}
}
