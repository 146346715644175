import { Component, OnDestroy } from "@angular/core";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, combineLatest, take } from "rxjs";
import { GetHeaderTitle, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { IEvent, IEventUser, IModule, ITreasureHunt } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { getTreasureHuntsOfModule } from "src/app/shared/selectors/gamification.selectors";
import { getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import { AnalyticsService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-treasure-hunts",
	templateUrl: "./treasure-hunts.component.html",
	styleUrls: ["./treasure-hunts.component.scss"]
})
export class TreasureHuntsComponent implements OnDestroy {
	necessaryDatasSub: Subscription;
	treasureHuntsSub: Subscription;
	languageSub: Subscription;

	loader: boolean = true;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	treasureHunts: ITreasureHunt[] = [];
	eventUser: IEventUser;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAnalytics: AnalyticsService,
		private STranslate: TranslateService,
		private navCtrl: NavController,
		private store: Store
	) {}

	ionViewWillEnter() {
		this.currentLanguage = this.STranslate.currentLang;
		this.languageSub = this.STranslate.onLangChange.subscribe((lang) => {
			this.currentLanguage = lang.lang;
		});
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.getNecessaryDatas();
					});

				// Analytics
				this.SAnalytics.moduleAccess(this.eventId, this.moduleId, TypeTracking.ACCESS_TO_TREASURE_HUNTS_MODULE);
			});
	}

	ionViewWillLeave() {
		this.store.dispatch(ResetHeaderState(null));
		[this.necessaryDatasSub, this.treasureHuntsSub, this.languageSub].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		[this.necessaryDatasSub, this.treasureHuntsSub, this.languageSub].forEach((sub) => {
			if (sub && !sub.closed) {
				sub.unsubscribe();
			}
		});
	}

	/**
	 * Getting necessary datas
	 */
	getNecessaryDatas() {
		if (this.necessaryDatasSub && !this.necessaryDatasSub.closed) {
			this.necessaryDatasSub.unsubscribe();
		}

		this.necessaryDatasSub = combineLatest([
			this.store.select(getCurrentEvent),
			this.store.select(getSpecificModule(this.moduleId)),
			this.store.select(getCurrentEventUser)
		]).subscribe((results) => {
			this.event = results[0];
			this.module = results[1];
			this.eventUser = results[2];

			if (this.module && this.eventUser) {
				this.store.dispatch(GetHeaderTitle({ payload: this.module.name }));
				this.getTreasureHunts();
			}
		});
	}

	/**
	 * Getting all treasureHunts of module
	 */
	getTreasureHunts() {
		if (this.treasureHuntsSub && !this.treasureHuntsSub.closed) {
			this.treasureHuntsSub.unsubscribe();
		}
		this.treasureHuntsSub = this.store
			.select(getTreasureHuntsOfModule(this.moduleId))
			.subscribe((treasureHunts) => {
				this.treasureHunts = treasureHunts.sort((a, b) => {
					if (this.module) {
						if (this.module.options.treasureHuntsOrder === "asc") {
							return a.name[this.event.language] > b.name[this.event.language]
								? 1
								: a.name[this.event.language] < b.name[this.event.language]
								? -1
								: 0;
						} else if (this.module.options.treasureHuntsOrder === "desc") {
							return a.name[this.event.language] < b.name[this.event.language]
								? 1
								: a.name[this.event.language] > b.name[this.event.language]
								? -1
								: 0;
						} else if (this.module.options.treasureHuntsOrder === "recent") {
							return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
						} else if (this.module.options.treasureHuntsOrder === "oldest") {
							return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
						} else {
							return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
						}
					} else {
						return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
					}
				});
				this.loader = false;
			});
	}

	/**
	 * Navigate to treasureHunt
	 * @param treasureHunt
	 */
	navigateTo(treasureHunt: ITreasureHunt) {
		this.navCtrl.navigateForward(`/event/${this.eventId}/treasure-hunts/${this.moduleId}/${treasureHunt.uid}`);
	}
}
