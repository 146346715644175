import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { combineLatest, Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { GetAllFeedbacks, GetAllFeedbacksResults } from "../actions/generics-modules-data.actions";
import { IFeedback, IFeedbackQuestionResult } from "../interfaces/feedbacks.interfaces";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import { checkSameEvent } from "../selectors/generics-modules-data.selectors";
import * as _ from "lodash-es";
import { FirestoreService } from "./firestore.service";
import { where } from "@angular/fire/firestore";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IEventUser } from "../interfaces";
import { getResultsOfFeedbacks } from "../selectors/generics-modules-data.selectors";

@Injectable({
	providedIn: "root"
})
export class FeedbacksService {
	feedbackSub: Subscription;
	feedbacksResultsSubsSaves: { feedbackId: string; subscription: Subscription }[] = [];
	currentEventUser: IEventUser;

	constructor(private SFirestore: FirestoreService, private https: HttpClient, private store: Store) {}

	unsubscribeAll() {
		if (this.feedbackSub && !this.feedbackSub.closed) {
			this.feedbackSub.unsubscribe();
		}
		// if (this.feedbacksResultsSub && !this.feedbacksResultsSub.closed) {
		// 	this.feedbacksResultsSub.unsubscribe();
		// }

		for (let i = 0; i < this.feedbacksResultsSubsSaves.length; i++) {
			const save = this.feedbacksResultsSubsSaves[i];
			if (save && save.feedbackId && save.subscription && !save.subscription.closed) {
				save.subscription.unsubscribe();
			}
		}
		this.feedbacksResultsSubsSaves = [];
	}

	/**
	 * Get feedback of event
	 * @param eventId
	 */
	getFeedbacksOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "feedbacks", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.feedbackSub && !this.feedbackSub.closed) {
					return;
				} else if (!sameEvent && this.feedbackSub && !this.feedbackSub.closed) {
					this.feedbackSub.unsubscribe();
				}

				this.feedbackSub = this.SFirestore.collectionGroupValueChangesDocuments("feedbacks", [
					where("eventId", "==", eventId),
					where("visibility", "==", true)
				]).subscribe((feedbacks: IFeedback[]) => {
					this.store.dispatch(GetAllFeedbacks({ payload: feedbacks, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initFeedbacks"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initFeedbacks", payload: true })
								);
							}
						});

					this.getResultsOfFeedbacks(feedbacks);
				});
			});
	}

	getResultsOfFeedbacks(feedbacks: IFeedback[]) {
		feedbacks.forEach((feedback) => {
			if (!this.feedbacksResultsSubsSaves.find((save) => save.feedbackId === feedback.uid)) {
				combineLatest([
					this.store.select(getInitSpecificEventDatasPart("initFeedbacks")).pipe(
						skipWhile((init) => !init),
						take(1)
					),
					this.store.select(getCurrentEventUser).pipe(
						skipWhile((eventUser) => !eventUser),
						take(1)
					)
				])
					.pipe(take(1))
					.subscribe({
						next: (results) => {
							const eventUser = results[1];
							if (eventUser) {
								if (!this.feedbacksResultsSubsSaves.find((save) => save.feedbackId === feedback.uid)) {
									this.feedbacksResultsSubsSaves.push({
										feedbackId: feedback.uid,
										subscription: this.SFirestore.valueChangesDocuments(
											`events/${feedback.eventId}/modules/${feedback.moduleId}/feedbacks/${feedback.uid}/feedback-results`,
											[where("userId", "==", eventUser.uid)]
										).subscribe((feedbackResults) => {
											this.store
												.select(getResultsOfFeedbacks)
												.pipe(take(1))
												.subscribe({
													next: (feedbacksResults) => {
														this.store.dispatch(
															GetAllFeedbacksResults({
																payload: _.cloneDeep(feedbacksResults)
																	.filter(
																		(result) => result.feedbackId !== feedback.uid
																	)
																	.concat(feedbackResults)
															})
														);
													}
												});
										})
									});
								}
							}
						}
					});
			}
		});
	}

	/**
	 * Create feedback results
	 * @param eventId
	 * @param moduleId
	 * @param feedbackId
	 * @param results
	 */
	createFeedbackResults(eventId: string, moduleId: string, feedbackId: string, results: IFeedbackQuestionResult[]) {
		const batch = this.SFirestore.getBatch();

		results.forEach((result) => {
			batch.set(
				this.SFirestore.docRef(
					`events/${eventId}/modules/${moduleId}/feedbacks/${feedbackId}/feedback-results/${result.uid}`
				),
				result
			);
		});

		return batch.commit();
	}
}
