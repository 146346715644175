import { createReducer, on } from "@ngrx/store";
import * as AuthActions from "../actions/auth.actions";
import { IAuthState } from "../interfaces/auth.interfaces";

export const initialState: IAuthState = {
	authenticated: false,
	currentUser: null,
	eventUser: {
		eventId: null,
		eventUser: null
	}
};

export const authReducers = createReducer(
	initialState,
	on(AuthActions.GetCurrentUser, (state, action) => {
		return {
			...state,
			currentUser: action.payload
		};
	}),
	on(AuthActions.GetMyEventUser, (state, action) => {
		return {
			...state,
			eventUser: {
				eventId: action.eventId,
				eventUser: action.payload
			}
		};
	}),
	on(AuthActions.LogInUser, (state, action) => {
		return {
			...state,
			authenticated: action.payload
		};
	}),
	on(AuthActions.LogOutUser, () => initialState)
);
