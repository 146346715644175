import { createAction, props } from "@ngrx/store";
import { IMessage } from "../interfaces";
import { IChat } from "../interfaces/chats.interfaces";

export enum ChatsActionsTypes {
	GetAllChats = "[Chats Service] Get all chats",
	GetAllMessages = "[Chats Service] Get all messages",
	ResetChatsState = "[Chats Service] Reset chats state"
}

export const GetAllChats = createAction(ChatsActionsTypes.GetAllChats, props<{ payload: IChat[]; eventId: string }>());
export const GetAllMessages = createAction(
	ChatsActionsTypes.GetAllMessages,
	props<{ payload: IMessage[]; eventId: string }>()
);
export const ResetChatsState = createAction(ChatsActionsTypes.ResetChatsState, props<any>());
