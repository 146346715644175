<div *ngIf="event && module && session" class="background" [style.overflow]="isMobile ? 'auto' : 'unset'"
    style="position: relative;background: var(--bg-content); display: flex; flex-direction: column;">
    <div #completeHeader class="complete-header" style="flex-grow: 0; height: fit-content"
        [style.background]="isMobile ? '#ffff' : 'var(--bg-header)'" [style.color]="event.styling.menuTextColor"
        [style.padding-bottom]="module.options.habilitedPersonal && '66px'">
        <button class="prev" *ngIf="module.options.allowNextPrevBtn && sessions.length > 1"
            [style.color]="isMobile ? event.styling.btnBgColor : event.styling.menuTextColor"
            [style.opacity]="prevBtn ? 1 : 0.5" (click)="goNextOrPrevSession('prev')" [disabled]="!prevBtn">
            <ion-icon src="assets/icon/ArrowLeft.svg"></ion-icon>
            <span>{{ "schedule_detail.prev-session" | translate }}</span>
        </button>
        <div class="session-info">
            <div class="date-location"
                [style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColor">
                <small class="date-time" style="text-align: center;">
                    <ion-icon src="assets/icon/Calendar.svg"></ion-icon>
                    <span>
                        {{ SUtility.getPartOfDate(event, eventUser, session.startDate, "full") | titlecase}}
                    </span> <br *ngIf="isMobile"> <span *ngIf="!isMobile" style="margin: 0 6px">•</span>
                    <span *ngIf="!session.hideSchedule">
                        {{ SUtility.getPartOfDate(event, eventUser, session.startDate, "time") | uppercase }}
                    </span>
                    <span *ngIf="session.endDate && !session.hideSchedule"> - {{ SUtility.getPartOfDate(event,
                        eventUser, session.endDate,
                        "time") | uppercase}}</span>

                </small>
                <small [hidden]="module.options.baseFields.location.hiding.general"
                    *ngFor="let locationId of session.locations" class="location">
                    <div *ngIf="getLocation(locationId)" style="cursor: pointer;"
                        (click)="openLocationModal(locationId)">
                        <ion-icon src="assets/icon/Map.svg"></ion-icon>
                        <span>{{ getLocation(locationId).name }}</span>
                    </div>
                </small>
                <small *ngIf="favoriteFolder" style="text-align: center; margin-right: 10px;">
                    <!-- add bookmark icon  -->
                    <ion-icon slot="end" name="bookmark" class="material-icons bookmark-icon"
                        style="position: relative !important;left: 5px !important;width: 25px;height: 25px;top: 2px !important;"
                        [fill]="isMobile ? event.styling.menuTextColor : event.styling.menuColor"
                        src="assets/icon/bookmarks.svg" *ngIf="eventUser && favoriteModule && favoriteModule.options 
                     && (!favoriteModule.options.hideFavoriteMenuIcon 
                     || !favoriteModule.options.hideFavoriteHeaderIcon) && !favoriteState?.[session.uid]"
                        (click)="addToFavorite(session.uid, $event)"></ion-icon>

                    <ion-icon slot="end" name="bookmark_remove" class="material-icons bookmark-icon"
                        style="position: relative !important;left: 5px !important;width: 25px;height: 25px;top: 2px !important;"
                        [fill]="isMobile ? event.styling.menuTextColor : event.styling.menuColor"
                        src="assets/icon/bookmark_remove.svg" *ngIf="eventUser && favoriteModule && favoriteModule.options
                          && (!favoriteModule.options.hideFavoriteMenuIcon 
                          || !favoriteModule.options.hideFavoriteHeaderIcon) && favoriteState?.[session.uid]"
                        (click)="removeFromFavorite(session.uid, $event)">
                    </ion-icon>
                </small>
            </div>
            <div class="session-attendees"
                *ngIf="module.options.habilitedPersonal && networkStatus && ((module.options.habilitedLimit && session.attendeesLimit > 0) || (module.options.habilitedLimit && session.attendeesLimit < 0))"
                [style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColor">
                <div class="nbr-attendees">
                    <span class="txt">{{ "schedule_detail.number_attendees" | translate }}</span>
                    <span class="nbr">{{ session.registeredUsers && registeredUsersCurrentSchedule ?
                        registeredUsersCurrentSchedule.length : 0 }}</span>
                </div>
                <div class="bar">|</div>
                <div *ngIf="session.registeredUsers && registeredUsersCurrentSchedule && registeredUsersCurrentSchedule.length <= session.attendeesLimit"
                    class="nbr-attendees">
                    <span class="txt">{{ "schedule_detail.number_available_vacancies" | translate }}</span>
                    <span class="nbr">{{ session.attendeesLimit - registeredUsersCurrentSchedule.length }}</span>
                </div>
            </div>
        </div>
        <button class="next" *ngIf="module.options.allowNextPrevBtn && sessions.length > 1"
            [style.color]="isMobile ? event.styling.btnBgColor : event.styling.menuTextColor"
            [style.opacity]="nextBtn ? 1 : 0.5" (click)="goNextOrPrevSession('next')" [disabled]="!nextBtn">
            <span>{{ "schedule_detail.next-session" | translate }}</span>
            <ion-icon src="assets/icon/ArrowRight.svg"></ion-icon>
        </button>
    </div>

    <ion-buttons *ngIf="eventUser && module.options.habilitedPersonal" class="btn-calendar" style="flex-grow: 0"
        [style.top]="'calc(' + completeHeader.offsetHeight + 'px - ' + (isMobile ? '60' : '36') + 'px)'">
        <ion-button shape="round" class="limit-disabled btn-validate-primary"
            (click)="addOrRemoveSessionOnPersonalSchedule()" [disabled]="isButtonDisabled">
            <span class="btn-txt">
                <ion-icon [src]="buttonIconSource"></ion-icon>
                <span>{{ buttonTextShow }}</span>
            </span>
        </ion-button>
    </ion-buttons>

    <ion-grid class="no-paddings" style="flex-grow: 1; display: flex"
        [style.height]="isMobile ? 'auto' : 'calc(100% - ' + completeHeader.offsetHeight + 'px)'">
        <ion-row style="flex-grow: 1"
            [style.height]="isMobile ? 'auto' : 'calc(100vh - ' + (completeHeader.offsetHeight + 56) + 'px)'">
            <ion-col *ngIf="(event && event.settings.allowVisio && session && visio && visio.url)
            || (session.videoPlayer && session.videoPlayer.type && session.videoPlayer.url && videoPlayerActivated)
            || (event && event.integrations && event.integrations.sparkup && session && session.integrations && session.integrations.sparkup && session.integrations.sparkup.url)
            || (!module.options.baseFields.description.hiding.general && session.description && session.description[currentLanguage])
            || (!isMobile && !module.options.baseFields.group?.hiding.general && session.groups.length > 0)
            || (module.options.showRegisteredAttendees && registeredUsersCurrentSchedule.length !== 0)
            || (!isMobile)" [size]="!fullWidth && !isMobile ? 8.5 : 12" [style.padding]="isMobile ? '0px' : '0px 5px'"
                [style.height]="isMobile ? 'auto' : '100%'">
                <div class="schedule-details-container" [style.overflow]="isMobile ? 'unset' : 'auto'"
                    [style.padding-bottom]="isMobile ? '5px' : '0px'"
                    [style.min-height]="isMobile ? '0' : 'calc(100vh - ' + (completeHeader.offsetHeight + 56) + 'px)'"
                    [style.width]="'100%'">
                    <app-visio *ngIf="event && event.settings.allowVisio && session && visio && visio.url"
                        [type]="'schedule'" [event]="event" [eventUser]="eventUser" [session]="session" [visio]="visio">
                    </app-visio>
                    <div #videoPlaceholder class="video-player-container" *ngIf="
							session.videoPlayer &&
							session.videoPlayer.type &&
							session.videoPlayer.url &&
							videoPlayerActivated
						">
                        <app-video-player #videoComponent></app-video-player>
                    </div>

                    <!-- Sparkup integration -->
                    <ion-card *ngIf="event && event.integrations
                        && event.integrations.sparkup
                        && session && session.integrations
                        && session.integrations.sparkup
                        && session.integrations.sparkup.url" style="margin: 0 0 20px 0 !important">
                        <ion-card-content>
                            <iframe [src]="getSparkupSrc() | safeHtml :'resourceUrl'"
                                [style.min-width]="isMobile ? '200px' : '840px'"
                                [style.min-height]="isMobile ? '400px' : '568px'" width="100%" height="100%"
                                allow="autoplay; encrypted-media; picture-in-picture; camera *; microphone *"
                                frameborder="0" scrolling="no" allowtransparency="true" allowfullscreen="true"></iframe>
                        </ion-card-content>
                    </ion-card>

                    <ion-card *ngIf="!module.options.baseFields.description.hiding.general &&
							session.description &&
							session.description[currentLanguage]
						">
                        <ion-card-content>
                            <div [style.color]="event.styling.contentTextColor" class="description" [innerHTML]="
									session.description[currentLanguage] | safeHtml
								"></div>
                        </ion-card-content>
                    </ion-card>


                    <!-- Session's group(s) -->
                    <div class="group-field"
                        *ngIf="!isMobile && !module.options.baseFields.group?.hiding.general && session.groups.length > 0">
                        <div class="item-name" [style.color]="event.styling.titleColor">
                            <i class="fa fa-users icon-people" aria-hidden="true"></i>
                            <h1 *ngIf="session.groups.length === 1" class="group-title">{{
                                "labels.group" | translate
                                }}</h1>
                            <h1 *ngIf="session.groups.length > 1" class="group-title">{{
                                "labels.groups" | translate }}
                            </h1>
                        </div>
                        <div class="group-value">
                            <small class="group-name" *ngFor="let group of getCorrespondingGroups(session.groups)"
                                [style.color]="group ? group.textColor : ''" [style.background]="group
                            ? group.backgroundColor
                            : ''">
                                {{
                                group.name
                                }}
                            </small>
                        </div>
                    </div>

                    <!-- Customs fields -->
                    <app-custom-fields-view *ngIf="!isMobile" [event]="event" [module]="module"
                        [eventUserProfile]="null" [scheduleId]="sessionId" [moduleType]="typeModule.SCHEDULE"
                        [currentLanguage]="STranslate.currentLang">
                    </app-custom-fields-view>

                    <div *ngIf="module.options.showRegisteredAttendees && registeredUsersCurrentSchedule.length !== 0"
                        [style.color]="event.styling.titleColor">
                        <h6 class="name-block">
                            <ion-icon src="assets/icon/User3.svg"></ion-icon>
                            <span class="m-l-5">{{"schedule_detail.registered_users_list" | translate}}</span>
                        </h6>
                        <ion-list>
                            <ion-item *ngFor="let user of registeredUsersCurrentSchedule" class="cursor-pointer"
                                lines="none" (click)="openUserProfil(user)">
                                <ion-avatar [ngClass]="user?.photoUrl || user?.photoUrl !== ''
                                    ? 'event-user-avatar-img'
                                    : 'event-user-avatar-blank'
                                    " slot="start" style="color: rgb(133, 133, 133)">
                                    <img [ngClass]="{
                                            'event-user-avatar-img-blank':
                                                !user?.photoUrl || user?.photoUrl === 'event-user-blank-img'
                                        }" *ngIf="!user?.photoUrl || user?.photoUrl === ''"
                                        src="assets/icon/User2.svg" />
                                    <img *ngIf="user?.photoUrl && user?.photoUrl !== ''" [src]="user?.photoUrl" />
                                </ion-avatar>
                                <ion-label>
                                    <h4 [style.color]="event.styling.titleColor" class="m-0 p-0 custom-fields-title">{{
                                        user.name }}</h4>
                                    <div class="custom-fields-event-user-tag-bloc">
                                        <div *ngFor="let customField of getEventUserTags(user)">
                                            <h5 class="custom-fields-tags m-t-0" *ngIf="
                                                    (customField.field.text && customField.field.text?.trim() !== '') ||
                                                    (customField.field.multiLanguageText &&
                                                        customField.field.multiLanguageText[currentLanguage]?.trim() !== '')
                                                " [style.color]="event.styling.contentTextColor">
                                                {{
                                                customField.field.text?.trim() ||
                                                customField.field.multiLanguageText[currentLanguage]?.trim()
                                                }}
                                            </h5>
                                        </div>
                                    </div>
                                </ion-label>
                            </ion-item>
                        </ion-list>
                    </div>
                </div>

                <!-- Fabs buttons -->
                <ion-fab-button *ngIf="!isMobile && module.options.allowSessionsDownload" class="basic-fab-button"
                    style="position: fixed !important; top: 85% !important; z-index: 9999999"
                    [ngClass]="{'fab-mobile': isMobile, 'fab-desktop': !isMobile}"
                    (click)=" openDownloadModal(session, $event)">
                    <ion-icon src="assets/images/calendar_colloqium.svg"></ion-icon>
                </ion-fab-button>
            </ion-col>
            <ion-col *ngIf="event && !event.blocked" [size]="isMobile || fullWidth ? 12 : 3.5"
                [style.height]="isMobile ? 'auto' : 'calc(100vh - ' + (completeHeader.offsetHeight + 56) + 'px)'"
                style="padding: 0px 5px" [id]="sessionId + '-col-detail'" style="background: #fff;">
                <app-interactivity-manager [style.height]="isMobile ? 'auto' : '100%'" [eventId]="eventId"
                    [moduleId]="moduleId" [sessionId]="sessionId">
                </app-interactivity-manager>
            </ion-col>
            <!-- Fabs buttons -->
            <ion-fab-button *ngIf="isMobile && module.options.allowSessionsDownload" class="basic-fab-button"
                [ngClass]="{'fab-mobile': isMobile}" (click)=" openDownloadModal(session, $event)">
                <ion-icon src="assets/images/calendar_colloqium.svg"></ion-icon>
            </ion-fab-button>
        </ion-row>
    </ion-grid>
</div>

<div *ngIf="!event || !module || !session" class="background">
    <div class="align-loader">
        <div class="loader-default"></div>
    </div>
</div>