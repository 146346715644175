import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IUtilityState } from "../interfaces/states.interfaces";

export const getUtilityState = createFeatureSelector<IUtilityState>("utility");

export const getInit = createSelector(getUtilityState, (state: IUtilityState) => state.init);
export const getInitAuth = createSelector(getUtilityState, (state: IUtilityState) => state.init.initAuth);
export const getInitClaims = createSelector(getUtilityState, (state: IUtilityState) => state.init.initClaims);
export const getInitContainers = createSelector(getUtilityState, (state: IUtilityState) => state.init.initContainers);
export const getInitEvents = createSelector(getUtilityState, (state: IUtilityState) => state.init.initEvents);
export const getInitUser = createSelector(getUtilityState, (state: IUtilityState) => state.init.initUser);
export const getLoading = createSelector(getUtilityState, (state: IUtilityState) => state.loading);
export const getMenuActivated = createSelector(getUtilityState, (state: IUtilityState) => state.menuActivated);
export const getScanning = createSelector(getUtilityState, (state: IUtilityState) => state.scanning);
export const getHeaderState = createSelector(getUtilityState, (state: IUtilityState) => state.header);
export const getHeaderItem = createSelector(getUtilityState, (state: IUtilityState) => state.header.item);
export const getHeaderTitle = createSelector(getUtilityState, (state: IUtilityState) => state.header.title);
export const getHeaderType = createSelector(getUtilityState, (state: IUtilityState) => state.header.type);

export const getPaginationRecords = createSelector(getUtilityState, (state: IUtilityState) =>
	_.cloneDeep(state.paginationsRecords)
);
export const getSpecificPaginationRecord = (moduleId: string) =>
	createSelector(getUtilityState, (state: IUtilityState) =>
		state.paginationsRecords.find((pagRecord) => pagRecord.listModuleId === moduleId)
	);

export const getChatsBadge = createSelector(getUtilityState, (state: IUtilityState) => state.chatsBadge);

export const getNotificationsBadge = createSelector(
	getUtilityState,
	(state: IUtilityState) => state.notificationsBadge
);

export const getInitSpecificEventDatasPart = (
	part:
		| "initAsksQuestions"
		| "initAttendees"
		| "initChats"
		| "initCheckins"
		| "initColorsCharts"
		| "initCustomPages"
		| "initDocuments"
		| "initImages"
		| "initVideos"
		| "initFolders"
		| "initEvent"
		| "initEventUser"
		| "initExternalsInteractivity"
		| "initFeedNews"
		| "initBaseCustomFields"
		| "initModulesCustomFields"
		| "initFeedbacks"
		| "initGroups"
		| "initLocations"
		| "initTracks"
		| "initNotifications"
		| "initModules"
		| "initRankings"
		| "initSchedules"
		| "initSpeakers"
		| "initQuizs"
		| "initSurveys"
		| "initTreasureHunts"
		| "initWidgets"
		| "initWordClouds"
		| "initAutomations"
		| "initFavorites"
		| "initRegisterForms"
) => createSelector(getUtilityState, (state: IUtilityState) => state.init.initAllDatasEvent[part]);

export const checkIfDatasEventReady = (
	parts: (
		| "initAsksQuestions"
		| "initAttendees"
		| "initChats"
		| "initCheckins"
		| "initColorsCharts"
		| "initCustomPages"
		| "initDocuments"
		| "initImages"
		| "initVideos"
		| "initFolders"
		| "initEvent"
		| "initEventUser"
		| "initExternalsInteractivity"
		| "initFeedNews"
		| "initBaseCustomFields"
		| "initModulesCustomFields"
		| "initFeedbacks"
		| "initGroups"
		| "initLocations"
		| "initTracks"
		| "initNotifications"
		| "initModules"
		| "initRankings"
		| "initSchedules"
		| "initSpeakers"
		| "initQuizs"
		| "initSurveys"
		| "initTreasureHunts"
		| "initWidgets"
		| "initWordClouds"
		| "initAutomations"
		| "initFavorites"
		| "initRegisterForms"
		| "all"
	)[]
) =>
	createSelector(getUtilityState, (state: IUtilityState) => {
		let check: boolean = true;
		const initKeys = parts.includes("all") ? Object.keys(state.init.initAllDatasEvent) : parts;
		for (const key of initKeys) {
			if (!state.init.initAllDatasEvent[key]) {
				check = false;
			}
		}
		return check;
	});

export const getInitDatasEvent = createSelector(
	getUtilityState,
	(state: IUtilityState) => state.init.initAllDatasEvent
);

export const getCustomFont = createSelector(getUtilityState, (state: IUtilityState) => state.customFont);

export const getNetworkStatus = createSelector(getUtilityState, (state: IUtilityState) => state.networkStatus);
