import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IGamificationState } from "../interfaces/states.interfaces";
import * as _ from "lodash-es";
import { ITreasureHunt, ITreasureHuntQrcode } from "../interfaces/treasure-hunts.interfaces";
import { getCurrentEventUser } from "./auth.selectors";
import { IEventUser, IModule } from "../interfaces";
import { getSpecificModule } from "./modules.selectors";

export const getGamificationState = createFeatureSelector<IGamificationState>("gamification");

export const checkSameEvent = (data: { key: string; uid: string }) =>
	createSelector(getGamificationState, (state: IGamificationState) => {
		return state[data.key].eventId === data.uid ? true : false;
	});

/**
 * Treasure hunts part
 */
export const getTreasureHunts = createSelector(getGamificationState, (state: IGamificationState) =>
	_.cloneDeep(state.treasureHunts.treasureHunts)
);

export const getTreasureHuntsOfModule = (moduleId: string) =>
	createSelector(
		getGamificationState,
		getSpecificModule(moduleId),
		getCurrentEventUser,
		(state: IGamificationState, module: IModule, eventUser: IEventUser) => {
			return _.cloneDeep(state.treasureHunts.treasureHunts).filter(
				(treasureHunt) =>
					treasureHunt.moduleId === moduleId &&
					(!treasureHunt.groupsLink.linked ||
						(treasureHunt.groupsLink.linked &&
							eventUser &&
							treasureHunt.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId))))
			);
		}
	);

export const getAccessiblesTreasureHuntsOfModule = (moduleId: string) =>
	createSelector(getGamificationState, getCurrentEventUser, (state: IGamificationState, eventUser: IEventUser) => {
		return _.cloneDeep(state.treasureHunts.treasureHunts).filter(
			(treasureHunt) =>
				treasureHunt.moduleId === moduleId &&
				treasureHunt.visibility &&
				(!treasureHunt.groupsLink.linked ||
					(treasureHunt.groupsLink.linked &&
						eventUser &&
						treasureHunt.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId))))
		);
	});

export const getSpecificTreasureHunt = (uid: string) =>
	createSelector(getGamificationState, (state: IGamificationState) => {
		const treasureHunt: ITreasureHunt = state.treasureHunts.treasureHunts.find(
			(treasureHunt) => treasureHunt.uid === uid
		);
		return treasureHunt ? _.cloneDeep(treasureHunt) : null;
	});

/**
 * Treasure hunts qrcodes part
 */
export const getTreasureHuntsQrcodes = createSelector(getGamificationState, (state: IGamificationState) =>
	_.cloneDeep(state.treasureHuntsQrcodes.treasureHuntsQrcodes)
);

export const getTreasureHuntsQrcodesOfModule = (moduleId: string) =>
	createSelector(getGamificationState, (state: IGamificationState) => {
		const treasureHuntsQrcodes = _.cloneDeep(state.treasureHuntsQrcodes.treasureHuntsQrcodes);
		return treasureHuntsQrcodes.filter((treasureHuntQrcodes) => treasureHuntQrcodes.moduleId === moduleId);
	});

export const getTreasureHuntQrcodes = (treasureHuntId: string) =>
	createSelector(getGamificationState, (state: IGamificationState) => {
		const treasureHuntQrcodes = _.cloneDeep(state.treasureHuntsQrcodes.treasureHuntsQrcodes);
		return treasureHuntQrcodes.filter(
			(treasureHuntQrcodes) => treasureHuntQrcodes.treasureHuntId === treasureHuntId
		);
	});

export const getSpecificTreasureHuntQrcode = (uid: string) =>
	createSelector(getGamificationState, (state: IGamificationState) => {
		const treasureHuntQrcode: ITreasureHuntQrcode = state.treasureHuntsQrcodes.treasureHuntsQrcodes.find(
			(treasureHuntQrcode) => treasureHuntQrcode.uid === uid
		);
		return treasureHuntQrcode ? _.cloneDeep(treasureHuntQrcode) : null;
	});
