import { createAction, props } from "@ngrx/store";
import { IRegisterForm } from "../interfaces/register-form.interfaces";

export enum RegisterFomrsActionsTypes {
	GetRegisterForms = "[RegisterForms Service] Get register forms",
	GetCurrentRegisterForm = "[RegisterForms Service] Get current register form",
	ResetRegisterFormsState = "[RegisterForms Service] Reset register forms state"
}

export const GetRegisterForms = createAction(
	RegisterFomrsActionsTypes.GetRegisterForms,
	props<{ payload: IRegisterForm[]; eventId: string }>()
);

export const GetCurrentRegisterForm = createAction(
	RegisterFomrsActionsTypes.GetCurrentRegisterForm,
	props<{ payload: IRegisterForm; eventId: string; moduleId: string }>()
);

export const ResetRegisterFormsState = createAction(RegisterFomrsActionsTypes.ResetRegisterFormsState, props<any>());
