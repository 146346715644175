import { createAction, props } from "@ngrx/store";
import { IAutomation } from "../interfaces/automations.interfaces";

export enum AutomationsActionsTypes {
	GetAllAutomations = "[Automations Service] Get all automations",
	ResetAutomationsState = "[Automations Service] Reset automations state"
}

export const GetAllAutomations = createAction(
	AutomationsActionsTypes.GetAllAutomations,
	props<{ payload: IAutomation[]; eventId: string }>()
);

export const ResetAutomationsState = createAction(AutomationsActionsTypes.ResetAutomationsState, props<any>());
