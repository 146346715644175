import { Component, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import * as _ from "lodash";
import { DateTime } from "luxon";
import { debounceTime, Subscription, take } from "rxjs";
import { IEvent, IEventUser, IModule } from "src/app/shared/interfaces";
import { IChat } from "src/app/shared/interfaces/chats.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import { ChatsService, EventUsersService, FirestoreService } from "src/app/shared/services";
import { getGenericModuleByType } from "src/app/shared/selectors/modules.selectors";
import { TypeModule } from "src/app/shared/enums/type-module";
import { where } from "@angular/fire/firestore";

@Component({
	selector: "app-create-chat",
	templateUrl: "./create-chat.component.html",
	styleUrls: ["./create-chat.component.scss"]
})
export class CreateChatComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	event: IEvent;
	module: IModule;
	currentUser: IEventUser;

	chatForm: UntypedFormGroup;
	selectedMembers: IEventUser[] = [];
	filteredMembers: IEventUser[] = [];

	tmpEventUsers: IEventUser[] = [];

	searchType: "full" | "partial" = "partial";
	searchInit: boolean = false;

	creating: boolean = false;

	constructor(
		private SEventUsers: EventUsersService,
		private SFirestore: FirestoreService,
		private store: Store,
		public modalCtrl: ModalController,
		private SChats: ChatsService,
		private fb: UntypedFormBuilder
	) {
		this.chatForm = this.fb.group({
			name: ["", Validators.compose([Validators.required])],
			queryMembers: [""]
		});

		this.chatForm
			.get("queryMembers")
			.valueChanges.pipe(debounceTime(200))
			.subscribe((queryMembers) => {
				this.filterMembers(queryMembers);
			});
	}

	ngOnInit() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.currentUser, eventUser)) {
					this.currentUser = eventUser;
				}
			})
		);
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) {
					this.event = event;

					if (!this.searchInit && this.event) {
						this.SFirestore.getCountOfQueryObs("event-users", "group", [
							where("eventId", "==", this.event.uid)
						]).subscribe((total) => {
							if (total > 800) {
								this.searchType = "partial";
							} else {
								this.searchType = "full";
								this.SEventUsers.getAllEventUsersForEvent(this.event.uid, "oneshot").subscribe(
									(eventUsers) => {
										this.tmpEventUsers = eventUsers.sort((a, b) =>
											a.queryName > b.queryName ? 1 : a.queryName < b.queryName ? -1 : 0
										);
									}
								);
							}
						});
						this.searchInit = true;
					}
				}
			})
		);

		this.subscriptions.push(
			this.store.select(getGenericModuleByType(TypeModule.CHAT)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.module = module;
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Close modal
	 */
	close() {
		this.modalCtrl.dismiss();
	}

	/**
	 * Create chat
	 */
	async createChat() {
		try {
			if (!this.creating) {
				this.creating = true;
				const data = this.chatForm.getRawValue();
				this.selectedMembers.push(this.currentUser);
				const newChat: IChat = {
					uid: this.SFirestore.createId(`events/${this.event.uid}/modules/${this.module.uid}/chats`),
					allowNotifs: true,
					creationDate: DateTime.local().toISO(),
					createdFrom: "app",
					disabled: false,
					eventId: this.event.uid,
					lastAccess: "",
					lastMessageId: "",
					lastMessageUser: null,
					lastUpdated: "",
					members: [],
					moduleId: this.module.uid,
					options: {
						allowVisio: false,
						description: "",
						groupCreator: {
							uid: this.currentUser.uid,
							email: this.currentUser.email,
							eventId: this.currentUser.eventId,
							identifier: this.currentUser.identifier,
							moduleId: this.currentUser.moduleId,
							name: this.currentUser.name,
							type: this.currentUser.type
						},
						groupImage: "",
						groupsLink: {
							linked: false,
							groups: []
						},
						specificMembersLink: {
							linked: true,
							specifics: this.selectedMembers.map((member) => member.uid)
						},
						identifier: data.name,
						muted: [],
						name: data.name,
						official: false,
						schedulesLink: {
							linked: false,
							linkType: -1,
							linkedModules: [],
							linkedSpecifics: []
						}
					},
					type: 1,
					visibility: true
				};
				await this.SChats.createChat(this.event.uid, this.module.uid, newChat);
				this.creating = false;
				this.close();
			}
		} catch (error) {
			this.creating = false;
		}
	}

	/**
	 * Filter members
	 */
	filterMembers(queryMembers) {
		if (queryMembers) {
			const queryTerm = queryMembers.trim().toLocaleUpperCase();
			if (this.searchType === "full") {
				this.filteredMembers = this.tmpEventUsers.filter((eventUser) =>
					eventUser.queryName.includes(queryTerm)
				);
			} else {
				this.SEventUsers.getEventUsersFromSearchName(this.event.uid, null, queryTerm)
					.pipe(take(1))
					.subscribe((eventUsers) => {
						this.filteredMembers = eventUsers;
					});
			}
		} else {
			this.filteredMembers = [];
		}
	}

	/**
	 * Selected members
	 * @param item
	 */
	selectMember(item: IEventUser) {
		if (this.selectedMembers.length > 0) {
			const index = this.selectedMembers.indexOf(item);
			if (index == -1) {
				this.selectedMembers.push(item);
			}
		} else {
			this.selectedMembers.push(item);
		}
		this.selectedMembers.sort((a, b) =>
			a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: "base" })
		);
		this.chatForm.patchValue({
			queryMembers: ""
		});
		this.filteredMembers = [];
	}

	/**
	 * Remove member from selection
	 * @param item
	 */
	removeMember(item) {
		this.selectedMembers.splice(this.selectedMembers.indexOf(item), 1);
	}
}
