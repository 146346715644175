import { createAction, props } from "@ngrx/store";
import { IContainer } from "../interfaces";

export enum ContainersActionsTypes {
	GetCurrentContainer = "[Containers Service] Get current container",
	GetContainers = "[Containers Service] Get current and default containers",
	GetDefaultContainer = "[Containers Service] Get default container"
}

export const GetCurrentContainer = createAction(
	ContainersActionsTypes.GetCurrentContainer,
	props<{ payload: IContainer }>()
);
export const GetDefaultContainer = createAction(
	ContainersActionsTypes.GetDefaultContainer,
	props<{ payload: IContainer }>()
);
export const GetContainers = createAction(
	ContainersActionsTypes.GetContainers,
	props<{ payload: { currentContainer: IContainer; defaultContainer: IContainer } }>()
);
