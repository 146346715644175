<div class="schedules-filters-container">
	<ion-label class="primary-title basic-label">{{ "labels.filters" | translate }}</ion-label>

	<div class="separator" [style.background]="event.styling.titleColor"></div>

	<ion-label class="secondary-title basic-label">{{ "labels.name" | translate }}</ion-label>
	<ion-input [(ngModel)]="filters.name" [placeholder]="'labels.name' | translate"></ion-input>

	<div class="separator" [style.background]="event.styling.titleColor"></div>

	<ion-label class="secondary-title basic-label">{{ "labels.locations" | translate }}</ion-label>
	<ion-list>
		<ion-item lines="none" *ngFor="let location of filters.locations">
			<ion-checkbox [style.--background-checked]="event.styling.contentTextColor"
				[style.--border-color-checked]="event.styling.contentTextColor"
				[style.--border-color]="event.styling.contentTextColor" slot="start" [(ngModel)]="location.checked">
			</ion-checkbox>
			<ion-label [style.color]="event.styling.contentTextColor">{{ location.name }} </ion-label>
		</ion-item>
	</ion-list>

	<div class="separator" [style.background]="event.styling.titleColor"></div>

	<div class="separator" [style.background]="event.styling.titleColor"></div>

	<ion-label class="secondary-title basic-label">{{ "labels.tracks" | translate }}</ion-label>
	<ion-list>
		<ion-item lines="none" *ngFor="let track of filters.tracks">
			<ion-checkbox [style.--background-checked]="event.styling.contentTextColor"
				[style.--border-color-checked]="event.styling.contentTextColor"
				[style.--border-color]="event.styling.contentTextColor" slot="start" [(ngModel)]="track.checked">
			</ion-checkbox>
			<ion-label [style.color]="event.styling.contentTextColor">{{ track.name[currentLanguage] }}
			</ion-label>
		</ion-item>
	</ion-list>

	<div class="full-w f-row-c-c">
		<ion-button class="basic-button" (click)="dismiss()">{{ "buttons.apply" | translate }} </ion-button>
	</div>
</div>