import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetAllTracks } from "../actions/generics-modules-data.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IBaseDocument } from "../interfaces";
import { checkSameEvent } from "../selectors/generics-modules-data.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class TracksService {
	tracksSub: Subscription;

	constructor(private SFirestore: FirestoreService, private store: Store) {}

	unsubscribeAll() {
		if (this.tracksSub) {
			this.tracksSub.unsubscribe();
		}
	}

	/**
	 * Get all tracks of event
	 * @param eventId
	 * @returns
	 */
	getTracksOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "tracks", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.tracksSub && !this.tracksSub.closed) {
					return;
				} else if (!sameEvent && this.tracksSub && !this.tracksSub.closed) {
					this.tracksSub.unsubscribe();
				}

				this.tracksSub = this.SFirestore.collectionGroupValueChangesDocuments("tracks", [
					where("eventId", "==", eventId)
				]).subscribe((tracksDocs: IBaseDocument[]) => {
					this.store.dispatch(GetAllTracks({ payload: tracksDocs, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initTracks"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initTracks", payload: true }));
							}
						});
				});
			});
	}
}
