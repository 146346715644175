import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { ISchedulesState } from "../interfaces/schedules.interfaces";

export const getSchedulesState = createFeatureSelector<ISchedulesState>("schedules");

export const checkSameEvent = (uid: string) =>
	createSelector(getSchedulesState, (state: ISchedulesState) => {
		return state.eventId === uid ? true : false;
	});

// export const getAllSessions = createSelector(getSchedulesState, (state: ISchedulesState) =>
// 	_.cloneDeep(state.schedules)
// );

// export const getPersonalScheduleSessions = createSelector(
// 	getSchedulesState,
// 	getCurrentEventUser,
// 	(state: ISchedulesState, eventUser: IEventUser) =>
// 		_.cloneDeep(state.schedules)
// 			.filter((schedule) => eventUser && schedule.registeredUsers.includes(eventUser.uid))
// 			.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0))
// );

// export const getSessionsOfModule = (moduleId: string) =>
// 	createSelector(
// 		getSchedulesState,
// 		getSpecificModule(moduleId),
// 		getCurrentEventUser,
// 		getCurrentEvent,
// 		(state: ISchedulesState, module: IModule, eventUser: IEventUser, event: IEvent) => {
// 			return _.cloneDeep(state.schedules)
// 				.filter(
// 					(schedule) =>
// 						schedule.moduleId === moduleId && (schedule.visibility || schedule.visibility === undefined)
// 				)
// 				.filter(
// 					(schedule) =>
// 						module &&
// 						module.options &&
// 						(!module.options.viewOnlyGroupsContent ||
// 							(module.options.viewOnlyGroupsContent &&
// 								eventUser &&
// 								schedule.groups.some((grpId) => eventUser.groups.includes(grpId))))
// 				)
// 				.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
// 		}
// 	);

// export const getFavoritesessionsOfEventUser = (moduleId) =>
// 	createSelector(
// 		getSchedulesState,
// 		getSpecificModule(moduleId),
// 		getCurrentEventUser,
// 		getCurrentEvent,
// 		(state: ISchedulesState, module: IModule, eventUser: IEventUser, event: IEvent) => {
// 			return _.cloneDeep(state.schedules)
// 				.filter((schedule) => eventUser && eventUser.favorites && eventUser.favorites?.includes(schedule.uid))
// 				.filter(
// 					(schedule) =>
// 						schedule.moduleId === moduleId && (schedule.visibility || schedule.visibility === undefined)
// 				)
// 				.filter(
// 					(schedule) =>
// 						module &&
// 						module.options &&
// 						(!module.options.viewOnlyGroupsContent ||
// 							(module.options.viewOnlyGroupsContent &&
// 								eventUser &&
// 								schedule.groups.some((grpId) => eventUser.groups.includes(grpId))))
// 				)
// 				.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
// 		}
// 	);

// export const getSpecificSession = (uid: string) =>
// 	createSelector(getSchedulesState, (state: ISchedulesState) => {
// 		const schedule = state.schedules.find((schedule) => schedule.uid === uid);
// 		return schedule ? _.cloneDeep(schedule) : null;
// 	});

/**
 * Check for first and last
 */
// export const sessionIsLastPrev = (data: { currentSessionId: string; moduleId: string }) =>
// 	createSelector(
// 		getSchedulesState,
// 		getSpecificModule(data.moduleId),
// 		getCurrentEventUser,
// 		(state: ISchedulesState, module: IModule, eventUser: IEventUser) => {
// 			const schedules = state.schedules
// 				.filter((schedule) => schedule.visibility || schedule.visibility === undefined)
// 				.filter(
// 					(schedule) =>
// 						!module.options.viewOnlyGroupsContent ||
// 						(module.options.viewOnlyGroupsContent &&
// 							eventUser &&
// 							schedule.groups.some((grpId) => eventUser.groups.includes(grpId)))
// 				)
// 				.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
// 			if (!schedules || schedules.length <= 1) {
// 				return {
// 					status: "error",
// 					last: false,
// 					first: false
// 				};
// 			}
// 			const currentIndex = schedules.indexOf(schedules.find((session) => session.uid == data.currentSessionId));
// 			const response = {
// 				status: "valid",
// 				last: false,
// 				first: false
// 			};

// 			if (currentIndex == -1) {
// 				return {
// 					status: "error",
// 					last: false,
// 					first: false
// 				};
// 			}
// 			if (currentIndex === schedules.length - 1) {
// 				response.last = true;
// 			}
// 			if (currentIndex === 0) {
// 				response.first = true;
// 			}

// 			return response;
// 		}
// 	);

/**
 * Return next session
 */
// export const getNextSession = (data: { currentSessionId: string; moduleId: string }) =>
// 	createSelector(
// 		getSchedulesState,
// 		getSpecificModule(data.moduleId),
// 		getCurrentEventUser,
// 		getTracks,
// 		(state: ISchedulesState, module: IModule, eventUser: IEventUser, tracks: ITrack[]) => {
// 			const filteredSchedules = _.cloneDeep(state.schedules)
// 				.filter(
// 					(schedule) =>
// 						schedule.moduleId === data.moduleId &&
// 						(schedule.visibility || schedule.visibility === undefined)
// 				)
// 				.filter(
// 					(schedule) =>
// 						!module.options.viewOnlyGroupsContent ||
// 						(module.options.viewOnlyGroupsContent &&
// 							eventUser &&
// 							schedule.groups.some((grpId) => eventUser.groups.includes(grpId)))
// 				)
// 				.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
// 			const schedules = module.options.activateTracksFiltering
// 				? filterByTracks(tracks, filteredSchedules)
// 				: filteredSchedules;
// 			const currentIndex = schedules.indexOf(schedules.find((session) => session.uid == data.currentSessionId));

// 			if (currentIndex === -1) {
// 				return {
// 					status: "error",
// 					session: null
// 				};
// 			} else if (currentIndex === schedules.length - 1) {
// 				return {
// 					status: "last",
// 					session: schedules[currentIndex]
// 				};
// 			} else {
// 				return {
// 					status: "valid",
// 					session: schedules[currentIndex + 1]
// 				};
// 			}
// 		}
// 	);

/**
 * Return previous session
 */
// export const getPrevSession = (data: { currentSessionId: string; moduleId: string }) =>
// 	createSelector(
// 		getSchedulesState,
// 		getSpecificModule(data.moduleId),
// 		getCurrentEventUser,
// 		getTracks,
// 		(state: ISchedulesState, module: IModule, eventUser: IEventUser, tracks: ITrack[]) => {
// 			const filteredSchedules = _.cloneDeep(state.schedules)
// 				.filter(
// 					(schedule) =>
// 						schedule.moduleId === data.moduleId &&
// 						(schedule.visibility || schedule.visibility === undefined)
// 				)
// 				.filter(
// 					(schedule) =>
// 						!module.options.viewOnlyGroupsContent ||
// 						(module.options.viewOnlyGroupsContent &&
// 							eventUser &&
// 							schedule.groups.some((grpId) => eventUser.groups.includes(grpId)))
// 				)
// 				.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
// 			const schedules = module.options.activateTracksFiltering
// 				? filterByTracks(tracks, filteredSchedules)
// 				: filteredSchedules;
// 			const currentIndex = schedules.indexOf(schedules.find((session) => session.uid == data.currentSessionId));

// 			if (currentIndex === -1) {
// 				return {
// 					status: "error",
// 					session: null
// 				};
// 			} else if (currentIndex === 0) {
// 				return {
// 					status: "first",
// 					session: schedules[currentIndex]
// 				};
// 			} else {
// 				return {
// 					status: "valid",
// 					session: schedules[currentIndex - 1]
// 				};
// 			}
// 		}
// 	);

// export const checkFullWidth = (session: ISchedule) =>
// 	createSelector(
// 		getCurrentEventUser,
// 		getAllAccessiblesInteractivity(session),
// 		(
// 			eventUser: IEventUser,
// 			interactivityState: {
// 				asksQuestionsState: IAskQuestions[];
// 				feedbacksState: IFeedback[];
// 				quizsState: IQuiz[];
// 				externalsInteractivityState: IExternalInteractivity[];
// 				surveysState: ISurvey[];
// 				chatsState: IChat[];
// 			}
// 		) => {
// 			const feedbacks = interactivityState.feedbacksState.filter(
// 				(feedback) =>
// 					feedback.link.linkType === 0 ||
// 					(feedback.link.linkType === 1 && feedback.link.linkedModules.includes(session.moduleId)) ||
// 					(feedback.link.linkType === 2 && feedback.link.linkedSpecifics.includes(session.uid))
// 			);
// 			const askQuestions = interactivityState.asksQuestionsState
// 				.filter(
// 					(askQuestions) =>
// 						!askQuestions.groupsLink.linked ||
// 						(askQuestions.groupsLink.linked &&
// 							eventUser &&
// 							askQuestions.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
// 				)
// 				.filter(
// 					(askQuestion) =>
// 						askQuestion.schedulesLink.linked &&
// 						(askQuestion.schedulesLink.linkType === 0 ||
// 							(askQuestion.schedulesLink.linkType === 1 &&
// 								askQuestion.schedulesLink.linkedModules.includes(session.moduleId)) ||
// 							(askQuestion.schedulesLink.linkType === 2 &&
// 								askQuestion.schedulesLink.linkedSchedules.includes(session.uid)))
// 				);
// 			const surveys = interactivityState.surveysState
// 				.filter(
// 					(survey) =>
// 						!survey.groupsLink.linked ||
// 						(survey.groupsLink.linked &&
// 							eventUser &&
// 							survey.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
// 				)
// 				.filter(
// 					(survey) =>
// 						survey.schedulesLink.linked &&
// 						(survey.schedulesLink.linkType === 0 ||
// 							(survey.schedulesLink.linkType === 1 &&
// 								survey.schedulesLink.linkedModules.includes(session.moduleId)) ||
// 							(survey.schedulesLink.linkType === 2 &&
// 								survey.schedulesLink.linkedSchedules.includes(session.uid)))
// 				);
// 			const quizs = interactivityState.quizsState
// 				.filter(
// 					(quiz) =>
// 						!quiz.groupsLink.linked ||
// 						(quiz.groupsLink.linked &&
// 							eventUser &&
// 							quiz.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
// 				)
// 				.filter(
// 					(quiz) =>
// 						quiz.schedulesLink.linked &&
// 						(quiz.schedulesLink.linkType === 0 ||
// 							(quiz.schedulesLink.linkType === 1 &&
// 								quiz.schedulesLink.linkedModules.includes(session.moduleId)) ||
// 							(quiz.schedulesLink.linkType === 2 &&
// 								quiz.schedulesLink.linkedSchedules.includes(session.uid)))
// 				);
// 			const externalsInteractivity = interactivityState.externalsInteractivityState
// 				.filter(
// 					(externalInteractivity) =>
// 						!externalInteractivity.groupsLink.linked ||
// 						(externalInteractivity.groupsLink.linked &&
// 							eventUser &&
// 							externalInteractivity.groupsLink.groups.some((groupId) =>
// 								eventUser.groups.includes(groupId)
// 							))
// 				)
// 				.filter(
// 					(externalInteractivity) =>
// 						externalInteractivity.schedulesLink.linked &&
// 						(externalInteractivity.schedulesLink.linkType === 0 ||
// 							(externalInteractivity.schedulesLink.linkType === 1 &&
// 								externalInteractivity.schedulesLink.linkedModules.includes(session.moduleId)) ||
// 							(externalInteractivity.schedulesLink.linkType === 2 &&
// 								externalInteractivity.schedulesLink.linkedSchedules.includes(session.uid)))
// 				);
// 			const discussionsGroups = interactivityState.chatsState
// 				.filter(
// 					(chat) =>
// 						chat.options &&
// 						chat.options.groupsLink &&
// 						(!chat.options.groupsLink.linked ||
// 							(chat.options.groupsLink.linked &&
// 								eventUser &&
// 								chat.options.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId))))
// 				)
// 				.filter(
// 					(chat) =>
// 						chat.type === 1 &&
// 						chat.options &&
// 						chat.options.schedulesLink.linked &&
// 						(chat.options.schedulesLink.linkType === 0 ||
// 							(chat.options.schedulesLink.linkType === 1 &&
// 								chat.options.schedulesLink.linkedModules.includes(session.moduleId)) ||
// 							(chat.options.schedulesLink.linkType === 2 &&
// 								chat.options.schedulesLink.linkedSpecifics.includes(session.uid)))
// 				);
// 			return (
// 				(askQuestions.length === 0 || askQuestions.every((askQuestion) => !askQuestion.visibility)) &&
// 				(feedbacks.length === 0 || feedbacks.every((feedback) => !feedback.visibility)) &&
// 				(surveys.length === 0 || surveys.every((survey) => !survey.visibility)) &&
// 				(quizs.length === 0 || quizs.every((quiz) => !quiz.visibility)) &&
// 				(externalsInteractivity.length === 0 ||
// 					externalsInteractivity.every((externalInteractivity) => !externalInteractivity.visibility)) &&
// 				(discussionsGroups.length === 0 || discussionsGroups.every((chat) => !chat.visibility))
// 			);
// 		}
// 	);

// /**
//  * Filter schedules by tracks
//  * @param tracks
//  * @param schedules
//  * @returns
//  */
// const filterByTracks = (tracks: ITrack[], schedules: ISchedule[]) => {
// 	const sessionsByTrackFiltered: {
// 		beginDate: string;
// 		endDate: string;
// 		mainLocation: any;
// 		sessions: ISchedule[];
// 		track: ITrack;
// 	}[] = [];
// 	schedules.forEach((session) => {
// 		if (session.tracks.length > 0) {
// 			session.tracks.forEach((trackId) => {
// 				const track = tracks.find((track) => track.uid === trackId);
// 				const groupTrack = sessionsByTrackFiltered.find(
// 					(group) => group.track && group.track.uid === track.uid
// 				);
// 				if (!groupTrack) {
// 					sessionsByTrackFiltered.push({
// 						beginDate: "",
// 						endDate: "",
// 						mainLocation: null,
// 						sessions: [session],
// 						track: track ? track : null
// 					});
// 				} else {
// 					groupTrack.sessions.push(session);
// 				}
// 			});
// 		} else {
// 			sessionsByTrackFiltered.push({
// 				beginDate: session.startDate,
// 				endDate: session.endDate ? session.endDate : null,
// 				mainLocation: null,
// 				sessions: [session],
// 				track: null
// 			});
// 		}
// 	});
// 	sessionsByTrackFiltered
// 		.map((groupTrack) => {
// 			groupTrack.sessions.sort((a, b) => (a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0));
// 			groupTrack.beginDate = groupTrack.sessions.length > 0 ? groupTrack.sessions[0].startDate : "";
// 			groupTrack.endDate =
// 				groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].endDate
// 					? groupTrack.sessions[groupTrack.sessions.length - 1].endDate
// 					: "";

// 			return groupTrack;
// 		})
// 		.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));
// 	let allSessionsSorted: ISchedule[] = [];
// 	sessionsByTrackFiltered.forEach((groupTrack) => {
// 		allSessionsSorted = allSessionsSorted.concat(groupTrack.sessions);
// 	});
// 	return allSessionsSorted;
// };
