<ion-app *ngIf="!scanning && !onMaintenanceApp">
    <ion-router-outlet></ion-router-outlet>

    <!-- <div *ngIf="loader" class="background-loader fx-col-c-c">
        <div class="loader-default"></div>

        <label class="loading-label">{{ "labels.loading" | translate }}</label>
    </div> -->
    <div *ngIf="loader" class="loader-container">
        <div class="loader-main" [style.width]="'60px'" [style.height]="'60px'"></div>
    </div>
</ion-app>

<div *ngIf="scanning" style="position: absolute; top: 20px; right: 20px">
    <ion-icon (click)="stopScan()"
        style="position: absolute; top: 20px; right: 20px; font-size: 30px; cursor: pointer; z-index: 99999999"
        color="danger" name="close">
    </ion-icon>
</div>

<div class="logo-container" *ngIf="progressBar && currentEvent">
    <div
        style="position: relative; width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center;">
        <img *ngIf="logo" class="logo" [src]="logo">
        <!-- <div class="loader" [style.border-color]="loaderColor" [style.width]="'60px'" [style.height]="'60px'"></div> -->
        <ion-progress-bar style="margin-top: 20px;" [value]="initProgress"></ion-progress-bar>
    </div>
</div>

<div *ngIf="onMaintenanceApp"
    style="position: fixed; z-index: 9999999999; top: 0; left: 0; width: 100vw; height: 100vh; background-color: white; display: flex; flex-direction: row; align-items: center; justify-content: center;">
    <div
        style="display: flex; flex-direction: column; align-items: center; justify-content: center; width: 400px; height: auto; padding: 20px">
        <img style="width: 250px; height: auto; object-fit: cover; margin-bottom: 20px" [src]="logoPlatform">
        <span style="font-weight: bold; font-size: x-large; text-align: center; width: 100%;">{{
            "labels.on-maintenance" | translate }}</span>
    </div>
</div>