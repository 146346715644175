<div class="container">
  <!-- <ion-button fill=" clear" slot="start" color="dark" (click)="closeModal()">
    <ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
  </ion-button> -->
  <ion-searchbar class="searchbar" searchIcon="assets/icon/Search.svg" mode="md"
    placeholder="{{ 'texts.search_plc' | translate }}" (ionClear)="resetFilter()" (keyup)="filterCountriesList($event)">
  </ion-searchbar>

  <ion-content class="content">
    <!-- list of country -->
    <ion-list class="country-list">
      <ion-item class="item" *ngFor="let country of countryListFiltered" (click)="selectCountry(country)">
        <ion-label>{{country.name}}</ion-label>
        <ion-label slot="end"> {{ country.flag }}</ion-label>
      </ion-item>
    </ion-list>
  </ion-content>

</div>