import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEventUser } from "../interfaces";
import { ICheckinsState } from "../interfaces/checkin.interfaces";
import { getCurrentEventUser } from "./auth.selectors";

export const getCheckinsState = createFeatureSelector<ICheckinsState>("checkins");

export const checkSameEvent = (uid: string) =>
	createSelector(getCheckinsState, (state: ICheckinsState) => {
		return state.eventId === uid ? true : false;
	});

/**
 * Checkins part
 */

export const getAllCheckins = createSelector(getCheckinsState, (state: ICheckinsState) => {
	return _.cloneDeep(state.checkins);
});

export const getCheckins = createSelector(
	getCheckinsState,
	getCurrentEventUser,
	(state: ICheckinsState, eventUser: IEventUser) => {
		const checkins = _.cloneDeep(state.checkins).filter(
			(checkin) =>
				checkin.visibility &&
				(checkin.groupsViewType === 0 ||
					(checkin.groupsViewType === 1 &&
						eventUser.groups.some((grpId) => checkin.groupsViewCheckin.includes(grpId))))
		);
		return checkins;
	}
);

export const getCheckinsForSelf = createSelector(
	getCheckinsState,
	getCurrentEventUser,
	(state: ICheckinsState, eventUser: IEventUser) => {
		const checkins = _.cloneDeep(state.checkins).filter(
			(checkin) =>
				checkin.visibility &&
				(checkin.groupsInType === 0 ||
					(checkin.groupsInType !== 0 &&
						eventUser.groups.some((grpId) => checkin.groupsInCheckin.includes(grpId))))
		);
		return checkins;
	}
);

export const getCheckinsOfModule = (moduleId: string) =>
	createSelector(getCheckinsState, getCurrentEventUser, (state: ICheckinsState, eventUser: IEventUser) => {
		const checkins = _.cloneDeep(state.checkins);
		return checkins
			.filter(
				(checkin) =>
					checkin.visibility &&
					checkin.moduleId === moduleId &&
					(checkin.groupsViewType === 0 ||
						(checkin.groupsViewType === 1 &&
							eventUser &&
							eventUser.groups.some((grpId) => checkin.groupsViewCheckin.includes(grpId))))
			)
			.sort((a, b) => (a.order > b.order ? 1 : a.order < b.order ? -1 : 0));
	});

export const getSpecificCheckin = (uid: string) =>
	createSelector(getCheckinsState, (state: ICheckinsState) =>
		_.cloneDeep(state.checkins).find((checkin) => checkin.uid === uid)
	);
