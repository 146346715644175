import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ActivatedRouteSnapshot } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Observable, combineLatest, of } from "rxjs";
import { skipWhile, switchMap, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { TypeUser } from "../enums/type-user";
import { getCurrentUser } from "../selectors/auth.selectors";
import { getContainersState } from "../selectors/containers.selectors";
import { selectRouteNestedParams } from "../selectors/router.selectors";
import { getInitContainers, getInitEvents, getInitUser } from "../selectors/utility.selectors";
import { EventsService } from "../services";

@Injectable()
export class EventGuard {
	constructor(
		private store: Store,
		private snackbar: MatSnackBar,
		private navCtrl: NavController,
		private SEvents: EventsService,
		private STranslate: TranslateService
	) {}
	canActivate(route: ActivatedRouteSnapshot): boolean | Observable<boolean> {
		return combineLatest([
			this.store.select(getInitUser).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitEvents).pipe(
				skipWhile((init) => init === false),
				take(1)
			),
			this.store.select(getInitContainers).pipe(
				skipWhile((init) => init === false),
				take(1)
			)
		]).pipe(
			take(1),
			switchMap(() =>
				combineLatest([
					this.store.select(getCurrentUser),
					this.SEvents.getSpecificEvent(route.params.eventId),
					this.store.select(getContainersState),
					this.store.select(selectRouteNestedParams)
				]).pipe(take(1))
			),
			switchMap((results) => {
				const user = results[0];
				const event = results[1];
				const containersState = results[2];
				const container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;
				const currentParams = results[3];

				if (event && ((user && event.blocked && user.type <= TypeUser.CLIENT) || !event.blocked)) {
					// Update last access of event user if exist
					// user && this.SEventUsers.updateLastAccessOfEventForEventUser(event.uid, user.uid);
					if (currentParams.eventId && currentParams.eventId === event.uid && currentParams.moduleId) {
						return of(true);
					} else if (event.homePage) {
						if (event.homePage !== "/" + route.url.map((u) => u.path).join("/")) {
							this.navCtrl.navigateRoot(event.homePage);
							return of(false);
						} else {
							return of(true);
						}
					} else {
						this.snackbar.open(this.STranslate.instant("snackbar.no_homepage"), "", {
							duration: 3000,
							panelClass: "error-snackbar"
						});
						this.navCtrl.navigateRoot(
							user
								? "/events-list/" +
										(container.loginSettings.showAccessPublicEventsBtn ? "all" : "my-events")
								: "/login"
						);
						return of(false);
					}
				} else {
					this.snackbar.open(this.STranslate.instant("snackbar.not_authorized_event"), "", {
						duration: 3000,
						panelClass: "error-snackbar"
					});
					this.navCtrl.navigateRoot(
						user
							? "/events-list/" +
									(container.loginSettings.showAccessPublicEventsBtn ? "all" : "my-events")
							: "/login"
					);
					return of(false);
				}
			})
		);
	}
}
