<ion-content>
	<div
		*ngIf="event"
		class="background"
		[ngStyle]="{
			'background-image': isMobile
				? 'url(' + event.settings.publicMobileBackground + ')'
				: 'url(' + event.settings.publicDesktopBackground + ')'
		}"
	>
		<div *ngIf="isModal && (!event.settings.requiredSignup || user)" class="close-btn p-l-10 p-t-20">
			<ion-button fill="clear" color="dark" (click)="close()">
				<ion-icon color="danger" src="assets/icon/Close.svg"></ion-icon>
			</ion-button>
		</div>

		<div class="align-loader" *ngIf="loader">
			<div class="loader-default"></div>
		</div>

		<div *ngIf="!loader" class="f-col-c-fs main-container" style="height: 100%">
			<div class="p-t-40" *ngIf="event.settings.publicLogo">
				<img [src]="event.settings.publicLogo" class="basic-logo" />
			</div>

			<form [formGroup]="loginForm">
				<!-- Email view -->
				<div *ngIf="emailView">
					<ion-grid class="ion-padding">
						<ion-row class="p-t-40">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<h3 class="login-title" [style.color]="event.styling.titleColor">
									{{ "login.access_your_account" | translate }}
								</h3>
							</ion-col>
						</ion-row>

						<!-- Input email  -->
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label position="floating" mode="md" class="input-label-text label-color">
										{{ "login.email" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input email-input input-color"
										type="email"
										formControlName="email"
									>
										<ion-icon
											class="icon"
											class="input-icons-color"
											*ngIf="passwordView"
											src="assets/icon/Check.svg"
										>
										</ion-icon>
									</ion-input>
								</ion-item>
							</ion-col>
						</ion-row>

						<!-- Input password -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label position="floating" mode="md" class="input-label-text label-color">
										{{ "login.password" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input input-color"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="password"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											[src]="
												showPwd ? 'assets/icon/Password_1.svg' : 'assets/icon/Password_2.svg'
											"
											class="input-icons-color"
										></ion-icon>
									</ion-button>
								</ion-item>
							</ion-col>
						</ion-row>

						<!-- Password forgotten -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<p class="p-forgot-password">
									<a class="forgot-password link" (click)="recoveryPassword()">{{
										"login.forgot_pass" | translate
									}}</a>
								</p>
							</ion-col>
						</ion-row>

						<!-- Term & conditions -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<div class="term-conditions">
									<ion-checkbox
										[style.--background-checked]="event.styling.contentTextColor"
										[style.--border-color-checked]="event.styling.contentTextColor"
										formControlName="termCheck"
									></ion-checkbox>
									<ion-label
										class="p-l-10"
										[style.color]="event.styling.contentTextColor"
										[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									>
										{{ "login.accept_term" | translate }}&nbsp;
										<a
											class="conditions-class"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('terms')"
											>{{ "login.terms_of_use" | translate }}</a
										>
										{{ "labels.and" | translate }}&nbsp;
										<a
											class="conditions-class"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('privacy')"
											>{{ "login.privacy_txt" | translate }}</a
										>
									</ion-label>
								</div>
							</ion-col>
						</ion-row>

						<!-- Buttons-->
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									[style.--background]="event.styling.btnBgColor"
									(click)="firstStepVerifEmail()"
									*ngIf="!passwordView"
									[disabled]="activeLoader || !loginForm.get('email').valid"
								>
									<span *ngIf="!activeLoader">{{ "buttons.btn_verify" | translate }}</span>
									<ion-spinner name="crescent" color="light" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									[style.--background]="event.styling.btnBgColor"
									(click)="login()"
									*ngIf="passwordView"
									[disabled]="activeLoader"
								>
									<span *ngIf="!activeLoader">{{ "buttons.enter" | translate }}</span>
									<ion-spinner name="crescent" color="light" *ngIf="activeLoader"></ion-spinner>
								</ion-button>

								<p
									*ngIf="
										event.settings.defaultAttendeesModule &&
										event.settings.defaultAttendeesModule !== '' &&
										event.settings.allowSwitchForm
									"
									class="to-register-message"
									[style.color]="event.styling.contentTextColor"
								>
									{{ "register.user_doesnt_has_an_account" | translate }}
									<a class="link" (click)="openRegister()">{{ "register.register" | translate }}</a>
								</p>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>

				<!-- First access view -->
				<div *ngIf="firstAccessView">
					<ion-grid class="ion-padding">
						<ion-row>
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
							>
								<p class="login-title">{{ "login.first_access_txt_01" | translate }}</p>
								<p class="font-roobert">{{ "login.first_access_txt_02" | translate }}</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label position="floating" mode="md" class="input-label-text label-color">
										{{ "login.verify_code" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input input-color"
										type="number"
										pattern="[0-9]*"
										min="0"
										max="10"
										formControlName="codeNumber"
									>
									</ion-input>
								</ion-item>

								<br /><span *ngIf="count !== 0" class="timer"
									>{{ "login.remaining_time" | translate }} {{ timeRemaining }}</span
								>
							</ion-col>
						</ion-row>
						<ion-row *ngIf="count === 0">
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<ion-button
									class="btn-default btn-outline-default"
									shape="round"
									fill="outline"
									[style.--background]="event.styling.btnBgColor"
									[disabled]="activeLoader"
									(click)="createCodeNumber(); activeLoader = true"
								>
									<span *ngIf="!activeLoader">{{ "login.resend_code" | translate }}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"> </ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>

						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									(click)="verifyCodeNumber()"
									[style.--background]="event.styling.btnBgColor"
									[disabled]="activeLoader"
								>
									<span *ngIf="!activeLoader">{{ "buttons.btn_verify" | translate }}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"> </ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>
						<!-- forgot password  -->
						<ion-row *ngIf="passwordView">
							<ion-col size-sm="12" size-md="6" size-lg="6" offset-md="4" offset-lg="4">
								<a
									class="forgot-password"
									[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									(click)="recoveryPassword()"
									>{{ "login.forgot_pass" | translate }}</a
								>
							</ion-col>
						</ion-row>
					</ion-grid>
				</div>

				<div *ngIf="createPasswordView">
					<ion-grid class="ion-padding">
						<ion-row>
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
							>
								<p class="login-title">{{ "login.great_title" | translate }}</p>
								<p class="font-roobert align-center content-text-color">
									{{
										!container.loginSettings.passwordSecurityLevel ||
										container.loginSettings.passwordSecurityLevel === 1
											? ("login.make_pass_txt" | translate)
											: ("login.make_pass_strong_txt" | translate)
									}}
								</p>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label position="floating" mode="md" class="input-label-text label-color">
										{{ "login.new_pass" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input input-color"
										[type]="showPwd ? 'text' : 'password'"
										formControlName="password"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showPwd = !showPwd">
										<ion-icon
											slot="icon-only"
											class="input-icons-color"
											[src]="
												showPwd ? 'assets/icon/Password_1.svg' : 'assets/icon/Password_2.svg'
											"
										>
										</ion-icon>
									</ion-button>
								</ion-item>
							</ion-col>
						</ion-row>
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<ion-item lines="none" mode="md" class="input-item">
									<ion-label position="floating" mode="md" class="input-label-text label-color">
										{{ "login.confirm_new_pass" | translate }}</ion-label
									>
									<ion-input
										mode="md"
										class="custom-input input-color"
										[type]="showConfirmPwd ? 'text' : 'password'"
										formControlName="confirmPassword"
									>
									</ion-input>
									<ion-button slot="end" class="pwd-btn" (click)="showConfirmPwd = !showConfirmPwd">
										<ion-icon
											slot="icon-only"
											class="input-icons-color"
											[src]="
												showConfirmPwd
													? 'assets/icon/Password_1.svg'
													: 'assets/icon/Password_2.svg'
											"
										>
										</ion-icon>
									</ion-button>
								</ion-item>
							</ion-col>
						</ion-row>
						<br />
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3">
								<div class="term-conditions">
									<ion-checkbox
										[style.--background-checked]="event.styling.contentTextColor"
										[style.--border-color-checked]="event.styling.contentTextColor"
										formControlName="termCheck"
									></ion-checkbox>
									<ion-label
										class="p-l-10"
										[style.color]="event.styling.contentTextColor"
										[ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
									>
										{{ "login.accept_term" | translate }}&nbsp;
										<a
											class="conditions-class"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('terms')"
											>{{ "login.terms_of_use" | translate }}</a
										>
										{{ "labels.and" | translate }}&nbsp;
										<a
											class="conditions-class"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('privacy')"
											>{{ "login.privacy_txt" | translate }}</a
										>
									</ion-label>
								</div>
							</ion-col>
						</ion-row>
						<br />
						<ion-row>
							<ion-col size-sm="12" size-md="6" size-lg="6" push-md="3" push-lg="3" text-center>
								<ion-button
									shape="round"
									class="btn-default access-btn"
									[style.--background]="event.styling.btnBgColor"
									(click)="createAccount()"
									[disabled]="
										activeLoader ||
										!loginForm.get('password').valid ||
										loginForm.getRawValue().password !== loginForm.getRawValue().confirmPassword ||
										!loginForm.getRawValue().termCheck
									"
								>
									<span *ngIf="!activeLoader">{{ "login.btn_make_pass" | translate }}</span>
									<ion-spinner name="crescent" color="secondary" *ngIf="activeLoader"></ion-spinner>
								</ion-button>
							</ion-col>
						</ion-row>
						<!-- forgot password  -->
						<!-- <ion-row *ngIf="passwordView">
                            <ion-col size-sm="12" size-md="6" size-lg="6" offset-md="4" offset-lg="4">
                                <a class="forgot-password"
                                    [ngClass]="passwordView ? 'color-2nd-screen' : 'color-1st-screen'"
                                    (click)="recoveryPassword()">{{"login.forgot_pass" | translate}}</a>
                            </ion-col>
                        </ion-row> -->
					</ion-grid>
				</div>
			</form>
		</div>
	</div>
</ion-content>
