import { TypeCustomFields } from "../enums/type-custom-fields";
import { FormItemType } from "../enums/type-register-forms";
import { IRegisterFormItem, IRegisterFormSection } from "../interfaces/register-form.interfaces";

export const registerFormLastSectionCreate: IRegisterFormSection = {
	uid: "submitSection",
	title: "Submit section",
	description: null,
	icon: {
		icon: "icon",
		iconFamily: "#000000"
	},
	items: [
		{
			uid: "password",
			type: FormItemType.INPUT,
			inputFieldType: TypeCustomFields.PASSWORD,
			label: {
				FrFR: "Mot de passe",
				EnUS: "Password",
				DeDE: "Passwort",
				PtBR: "Senha",
				ArAR: "كلمة المرور ",
				EsES: "Contraseña"
			},
			placeholder: "",
			description: {
				FrFR: "Entre votre mot de passe",
				EnUS: "Enter your password",
				DeDE: "Gib dein Passwort ein",
				PtBR: "Insira sua senha",
				ArAR: "أدخل كلمة المرور",
				EsES: "Introduce tu contraseña"
			},
			order: 0,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true,
			value: null,
			rules: {}
		} as IRegisterFormItem,
		{
			uid: "passwordConfirmation",
			type: FormItemType.INPUT,
			inputFieldType: TypeCustomFields.PASSWORD,
			label: {
				FrFR: "Confirmer le mot de passe",
				EnUS: "Confirm password",
				DeDE: "Bestätigen Sie Ihr neues Passwort",
				PtBR: "Confirme sua senha",
				ArAR: "أدخل تأكيد كلمة المرور",
				EsES: "Confirma tu contraseña"
			},
			placeholder: "",
			description: {
				FrFR: "Confirmer le mot de passe",
				EnUS: "Confirm password",
				DeDE: "Bestätigen Sie Ihr neues Passwort",
				PtBR: "Confirme sua senha",
				ArAR: "أدخل تأكيد كلمة المرور",
				EsES: "Confirma tu contraseña"
			},
			order: 1,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true,
			value: null,
			rules: {}
		} as IRegisterFormItem,
		// termCheck (checkbox inputfield type)
		{
			uid: "termCheck",
			type: FormItemType.INPUT,
			inputFieldType: TypeCustomFields.CHECKBOX,
			label: {
				FrFR: "J'accepte les termes et conditions",
				EnUS: "I accept the terms and conditions",
				DeDE: "Ich akzeptiere die Nutzungsbedingungen",
				PtBR: "Eu aceito os termos e condições",
				ArAR: "تأكيد الشروط والاحكام",
				EsES: "Acepto los términos y condiciones"
			},
			placeholder: "",
			order: 2,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true
		} as IRegisterFormItem
	],
	isInitialSection: false,
	isSubmitSection: true,
	defaultNextSectionId: null
};

export const registerFormLastSectionLogin: IRegisterFormSection = {
	uid: "submitSection",
	title: "Submit section",
	description: null,
	icon: {
		icon: "icon",
		iconFamily: "#000000"
	},
	items: [
		{
			uid: "password",
			type: FormItemType.INPUT,
			inputFieldType: TypeCustomFields.PASSWORD,
			label: {
				FrFR: "Mot de passe",
				EnUS: "Password",
				DeDE: "Passwort",
				PtBR: "Senha",
				ArAR: "كلمة المرور ",
				EsES: "Contraseña"
			},
			placeholder: "",
			description: {
				FrFR: "Entre votre mot de passe",
				EnUS: "Enter your password",
				DeDE: "Gib dein Passwort ein",
				PtBR: "Insira sua senha",
				ArAR: "أدخل كلمة المرور",
				EsES: "Introduce tu contraseña"
			},
			order: 0,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true,
			value: null,
			rules: {}
		} as IRegisterFormItem,

		// termCheck (checkbox inputfield type)
		{
			uid: "termCheck",
			type: FormItemType.INPUT,
			inputFieldType: TypeCustomFields.CHECKBOX,
			label: {
				FrFR: "J'accepte les termes et conditions",
				EnUS: "I accept the terms and conditions",
				DeDE: "Ich akzeptiere die Nutzungsbedingungen",
				PtBR: "Eu aceito os termos e condições",
				ArAR: "تأكيد الشروط والاحكام",
				EsES: "Acepto los términos y condiciones"
			},
			placeholder: "",
			order: 2,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true
		} as IRegisterFormItem
	],
	isInitialSection: false,
	isSubmitSection: true,
	defaultNextSectionId: null
};

export const loginRegisterFormLastSection: IRegisterFormSection = {
	uid: "initialSection",
	title: "Initial section",
	description: null,
	icon: {
		icon: "icon",
		iconFamily: "#000000"
	},
	items: [
		// email
		{
			uid: "email",
			type: FormItemType.INPUT,
			label: {
				FrFR: "E-mail",
				EnUS: "E-mail",
				DeDE: "E-mail",
				PtBR: "E-mail",
				ArAR: "E-mail",
				EsES: "E-mail"
			},
			inputFieldType: TypeCustomFields.EMAIL,
			placeholder: "",
			description: {
				FrFR: "",
				EnUS: "",
				DeDE: "",
				PtBR: "",
				ArAR: "",
				EsES: ""
			},
			order: 2,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true,
			value: null,
			rules: {}
		},
		{
			uid: "password",
			type: FormItemType.INPUT,
			inputFieldType: TypeCustomFields.PASSWORD,
			label: {
				FrFR: "Mot de passe",
				EnUS: "Password",
				DeDE: "Passwort",
				PtBR: "Senha",
				ArAR: "كلمة المرور ",
				EsES: "Contraseña"
			},
			placeholder: "",
			description: {
				FrFR: "Entre votre mot de passe",
				EnUS: "Enter your password",
				DeDE: "Gib dein Passwort ein",
				PtBR: "Insira sua senha",
				ArAR: "أدخل كلمة المرور",
				EsES: "Introduce tu contraseña"
			},
			order: 0,
			required: true,
			hidden: false,
			showPlaceholder: false,
			enableRules: false,
			isBaseField: true,
			value: null,
			rules: {}
		} as IRegisterFormItem
	],
	isInitialSection: false,
	isSubmitSection: true,
	defaultNextSectionId: null
};
