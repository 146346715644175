import { Pipe, PipeTransform } from "@angular/core";
import { TypeModule } from "../enums/type-module";

@Pipe({
	name: "sortList"
})
export class SortListPipe implements PipeTransform {
	transform(list: any[], sortType: string, language?: string, moduleType?: number): any {
		// check if search term is undefined
		if (!sortType) {
			return list;
		}
		// return updated List of objects searched
		return list.sort((a, b) => {
			if (moduleType !== TypeModule.CHAT) {
				if (sortType === "asc") {
					if (language && a.name[language] && b.name[language]) {
						return a.name[language]
							.toLocaleUpperCase()
							.localeCompare(b.name[language].toLocaleUpperCase(), undefined, {
								numeric: true,
								sensitivity: "base"
							});
					} else {
						if (moduleType === -5) {
							return a.title.toLocaleUpperCase().localeCompare(b.title.toLocaleUpperCase(), undefined, {
								numeric: true,
								sensitivity: "base"
							});
						} else {
							return a.name.toLocaleUpperCase().localeCompare(b.name.toLocaleUpperCase(), undefined, {
								numeric: true,
								sensitivity: "base"
							});
						}
					}
				} else if (sortType === "desc") {
					if (language && a.name[language] && b.name[language]) {
						return b.name[language]
							.toLocaleUpperCase()
							.localeCompare(a.name[language].toLocaleUpperCase(), undefined, {
								numeric: true,
								sensitivity: "base"
							});
					} else {
						if (moduleType === -5) {
							return b.title.toLocaleUpperCase().localeCompare(a.title.toLocaleUpperCase(), undefined, {
								numeric: true,
								sensitivity: "base"
							});
						} else {
							return b.name.toLocaleUpperCase().localeCompare(a.name.toLocaleUpperCase(), undefined, {
								numeric: true,
								sensitivity: "base"
							});
						}
					}
				} else if (sortType === "recent") {
					return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
				} else if (sortType === "oldest") {
					if (moduleType && moduleType === TypeModule.SCHEDULE) {
						return a.startDate < b.startDate ? 1 : a.startDate > b.startDate ? -1 : 0;
					} else {
						return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
					}
				} else if (sortType === "order") {
					return a.order > b.order ? 1 : a.order < b.order ? -1 : 0;
				} else if (sortType === "id") {
					return a.identifier.toLocaleUpperCase().localeCompare(b.identifier.toLocaleUpperCase(), undefined, {
						numeric: true,
						sensitivity: "base"
					});
				} else if (sortType === "date") {
					return a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0;
				}
			} else {
				if (sortType === "asc") {
					return a.options.name
						.toLocaleUpperCase()
						.localeCompare(b.options.name.toLocaleUpperCase(), undefined, {
							numeric: true,
							sensitivity: "base"
						});
				} else if (sortType === "desc") {
					return b.options.name
						.toLocaleUpperCase()
						.localeCompare(a.options.name.toLocaleUpperCase(), undefined, {
							numeric: true,
							sensitivity: "base"
						});
				} else if (sortType === "recent") {
					return a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0;
				} else if (sortType === "oldest") {
					return a.creationDate < b.creationDate ? 1 : a.creationDate > b.creationDate ? -1 : 0;
				}
			}
		});
	}
}
