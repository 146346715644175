import { createReducer, on } from "@ngrx/store";
import * as FeedNewsActions from "../actions/feed-news.actions";
import { IFeedNewsState } from "../interfaces/feed-news.interfaces";

export const initialState: IFeedNewsState = {
	eventId: "",
	posts: []
};

export const feedNewsFeatureKey = "feedNews";

export const feedNewsReducers = createReducer(
	initialState,
	on(FeedNewsActions.GetAllPosts, (state, action) => ({
		...state,
		eventId: action.eventId,
		posts: action.payload
	})),
	on(FeedNewsActions.ResetFeedNewsState, () => ({
		...initialState
	}))
);
