export enum EmailDestinationTypeUsers {
	ALL = 1, // All event users
	GROUPS = 2, // All event event users in groups
	CONNECTED_USERS = 3, // All connected event users
	NOT_CONNECTED_USERS = 4, // All not connected event users
	MIN_POINTS = 5, // All event users with min points
	SPECIFIC = 6, // Specific event users
	STAFF = 7, // All staff => manager + client
	ONLY_MANAGER = 8, // Only manager
	ONLY_CLIENT = 9, // Only client
	GOD = 10, // God => Admins
	SUPER_GOD = 11 // God => CEO
}

export const EmailDestinationValue = {
	[EmailDestinationTypeUsers.SUPER_GOD]: "emmanuel@invent-app.com"
};

export const EmailDestinationTypeUsersLabel = {
	[EmailDestinationTypeUsers.ALL]: "Tous les utilisateurs de l'événement",
	[EmailDestinationTypeUsers.GROUPS]: "Tous les utilisateurs de l'événement dans les groupes",
	[EmailDestinationTypeUsers.CONNECTED_USERS]: "Tous les utilisateurs connectés à l'événement",
	[EmailDestinationTypeUsers.NOT_CONNECTED_USERS]: "Tous les utilisateurs non connectés à l'événement",
	[EmailDestinationTypeUsers.MIN_POINTS]: "Tous les utilisateurs de l'événement avec un minimum de points",
	[EmailDestinationTypeUsers.SPECIFIC]: "Utilisateurs spécifiques",
	[EmailDestinationTypeUsers.STAFF]: "Tout le staff",
	[EmailDestinationTypeUsers.ONLY_MANAGER]: "Seulement les managers",
	[EmailDestinationTypeUsers.ONLY_CLIENT]: "Seulement les clients",
	[EmailDestinationTypeUsers.GOD]: "Seulement les admins",
	[EmailDestinationTypeUsers.SUPER_GOD]: "Seulement le CEO"
};

export const EmailDestinationTypeUsersShort = {
	[EmailDestinationTypeUsers.ALL]: "all",
	[EmailDestinationTypeUsers.GROUPS]: "groups",
	[EmailDestinationTypeUsers.CONNECTED_USERS]: "connected",
	[EmailDestinationTypeUsers.NOT_CONNECTED_USERS]: "notconnected",
	[EmailDestinationTypeUsers.MIN_POINTS]: "minpoints",
	[EmailDestinationTypeUsers.SPECIFIC]: "specific",
	[EmailDestinationTypeUsers.STAFF]: "staff",
	[EmailDestinationTypeUsers.ONLY_MANAGER]: "manager",
	[EmailDestinationTypeUsers.ONLY_CLIENT]: "client",
	[EmailDestinationTypeUsers.GOD]: "god",
	[EmailDestinationTypeUsers.SUPER_GOD]: "superGod"
};
