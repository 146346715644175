export enum TypeCaptchaMethod {
	INVISIBLE = 0,
	IMAGE = 1
}

export enum TypeCaptchaLevel {
	EASY = 0,
	MEDIUM = 1,
	HARD = 2
}
