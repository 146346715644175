<div class="main" *ngIf="eventUserProfile">
	<div
		class="complete-header"
		[style.background]="event.styling.menuColor"
		[style.color]="event.styling.menuTextColor"
	></div>
	<app-profile-general-info
		[event]="event"
		[module]="module"
		[eventUserProfile]="eventUserProfile"
		[editMode]="true"
		[isPrivateMode]="true"
	>
	</app-profile-general-info>
	<div class="background" [style.paddingRight]="module.options.enableUserFieldsHideAbility && isMobile ? '25px' : ''">
		<div class="main-container">
			<form [formGroup]="formEditEventUser">
				<ion-row
					[class.upper-btn-in-desktop]="!isMobile"
					[class.upper-btn-in-mobile]="isMobile"
					class="upper-btn-in-desktop"
				>
					<ion-col size="12">
						<button
							mat-raised-button
							class="btn-validate"
							type="submit"
							[class.button-spinner]="loader"
							(click)="editEventUser()"
							[disabled]="loader || !formEditEventUser.valid || !customFieldFormValid"
							[ngClass]="{'btn-validate[disabled]': !formEditEventUser.valid || !customFieldFormValid}"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
						>
							<span *ngIf="!loader">{{"edit-profil.submit"| translate}}</span>
						</button>
					</ion-col>
				</ion-row>

				<!-- Name field -->
				<ion-row>
					<ion-col class="custom-ion-col" col-12>
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating">
								{{"edit-profil.name" | translate}}
								<span class="required"> * </span>
							</ion-label>
							<ion-input
								autocapitalize="sentences"
								mode="md"
								class="custom-input"
								type="text"
								formControlName="name"
								[ngStyle]="{'color': formEditEventUser.get('name').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.name_text-info' | translate }}"
							>
							</ion-input>
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('name').touched &&
                !formEditEventUser.get('name').dirty) || 
                formEditEventUser.get('name').value === '' && !formEditEventUser.get('name').errors"
								src="assets/icon/Check.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('name').errors &&
            (formEditEventUser.get('name').touched || formEditEventUser.get('name').dirty) &&
                    formEditEventUser.get('name').value !== ''"
								src="assets/icon/Check.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('name').errors &&
                (formEditEventUser.get('name').touched || formEditEventUser.get('name').dirty)"
								src="assets/icon/Close.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>

						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
            formEditEventUser.get('name').errors?.required &&
            (formEditEventUser.get('name').touched || formEditEventUser.get('name').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.name" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
            formEditEventUser.get('name').errors?.minlength"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.name" | translate }}</p>
						</div>
					</ion-col>
				</ion-row>

				<!-- Email field -->
				<ion-row *ngIf="module && module.options.requiredFields">
					<ion-col class="custom-ion-col" col-12>
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating">
								{{"edit-profil.email" | translate}}
								<span *ngIf="module.options.requiredFields.email.required" class="required"> * </span>
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="email"
								[ngStyle]="{'color': formEditEventUser.get('email').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.example_email2' | translate }}"
								formControlName="email"
								[readonly]="true"
							>
							</ion-input>
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('email').touched &&
                !formEditEventUser.get('email').dirty) || 
                formEditEventUser.get('email').value === '' && !formEditEventUser.get('email').errors"
								src="assets/icon/Check.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('email').errors &&
            (formEditEventUser.get('email').touched || formEditEventUser.get('email').dirty) &&
                formEditEventUser.get('email').value !== ''"
								src="assets/icon/Check.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('email').errors &&
                (formEditEventUser.get('email').touched || formEditEventUser.get('email').dirty)"
								src="assets/icon/Close.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
            module.options.requiredFields.email.required &&
            formEditEventUser.get('email').errors?.required &&
            (formEditEventUser.get('email').touched || formEditEventUser.get('email').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.email" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf=" 
            formEditEventUser.get('email').errors?.minlength && 
            formEditEventUser.get('email').errors?.email"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.email" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							(click)="updateFieldVisibility('email')"
							[src]="showUserOrModuleVisibilityOptions('email') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>
				</ion-row>

				<!-- Biography / Description field -->
				<ion-row
					*ngIf="module && module.options.requiredFields && module.options.requiredFields.description.editionScope"
				>
					<ion-col class="custom-ion-col" col-12>
						<ion-item mode="md" lines="none" class="textarea-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.biography" | translate}}
								<span *ngIf="module.options.requiredFields.description.required" class="required">
									*
								</span>
							</ion-label>
							<app-tiny-preview-button
								height="150px"
								[editorRef]="editorRef"
								style="width: 100%; padding: 15px"
							>
							</app-tiny-preview-button>
							<ng-template #editorRef>
								<editor
									[apiKey]="tinyApiKey"
									[init]="getTinyInit()"
									formControlName="description"
									style="width: 100%; margin: 23px 0; white-space: pre-line"
									[disabled]="module.options.requiredFields.description.uniqueEdit &&  eventUserProfile.description[event.language] !== ''"
								>
								</editor>
							</ng-template>
							<!-- <ion-textarea
								autocapitalize="sentences"
								mode="md"
								rows="5"
								formControlName="description"
								[ngStyle]="{'color': formEditEventUser.get('description').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.bio_text-info' | translate }}"
								style="min-height: 156px; white-space: pre-line"
								[disabled]="module.options.requiredFields.description.uniqueEdit &&  eventUserProfile.description[event.language] !== ''"
							>
							</ion-textarea> -->
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('description').touched &&
                    !formEditEventUser.get('description').dirty) || 
                    formEditEventUser.get('description').value === '' && !formEditEventUser.get('description').errors"
								src="assets/icon/Check.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('description').errors &&
                (formEditEventUser.get('description').touched || formEditEventUser.get('description').dirty) &&
                    formEditEventUser.get('description').value !== ''"
								src="assets/icon/Check.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('description').errors &&
                    (formEditEventUser.get('description').touched || formEditEventUser.get('description').dirty)"
								src="assets/icon/Close.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
                module.options.requiredFields.description.required &&
                formEditEventUser.get('description').errors?.required &&
                (formEditEventUser.get('description').touched || formEditEventUser.get('description').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
                formEditEventUser.get('description').errors?.minlength ||
                formEditEventUser.get('description').errors?.maxlength"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.bio" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							(click)="updateFieldVisibility('description')"
							[src]="showUserOrModuleVisibilityOptions('description') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>
				</ion-row>

				<!-- Custom fields edit component -->
				<app-custom-fields-edit
					[event]="event"
					[module]="module"
					[eventUserProfile]="eventUserProfile"
					[language]="currentLanguage"
					(validEvent)="customFieldFormValid = $event"
				>
				</app-custom-fields-edit>

				<!-- Web site field -->
				<ion-row *ngIf="module && module.options.requiredFields?.website.editionScope">
					<ion-col class="custom-ion-col" size-xs="12" size-sm="12" size-lg="12">
						<ion-item style="width: 98% !important" mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.website" | translate}}
								<span *ngIf="module.options.requiredFields.website.required" class="required"> * </span>
							</ion-label>

							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('website').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.website_text-info' | translate }}"
								formControlName="website"
								[disabled]="module.options.requiredFields.website.uniqueEdit &&  eventUserProfile.socialMedias.website !== ''"
							>
							</ion-input>

							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('website').touched &&
                    !formEditEventUser.get('website').dirty) || 
                    formEditEventUser.get('website').value === '' && !formEditEventUser.get('website').errors"
								src="assets/icon/Web.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('website').errors &&
                (formEditEventUser.get('website').touched || formEditEventUser.get('website').dirty) &&
                    formEditEventUser.get('website').value !== ''"
								src="assets/icon/Web.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('website').errors &&
                    (formEditEventUser.get('website').touched || formEditEventUser.get('website').dirty)"
								src="assets/icon/Web.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
                module.options.requiredFields.website.required &&
                formEditEventUser.get('website').errors?.required &&
                (formEditEventUser.get('website').touched || formEditEventUser.get('website').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
                formEditEventUser.get('website').errors?.pattern"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.url" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							(click)="updateFieldVisibility('website')"
							[src]="showUserOrModuleVisibilityOptions('website') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>
				</ion-row>

				<ion-row>
					<!-- Facebook field -->
					<ion-col
						*ngIf="module && module.options.requiredFields?.facebook.editionScope"
						class="custom-ion-col"
						size-xs="12"
						size-sm="12"
						size-lg="6"
					>
						<ion-item style="width: 98% !important" mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.facebook" | translate}}
								<span *ngIf="module.options.requiredFields.facebook.required" class="required">
									*
								</span>
							</ion-label>

							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('facebook').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.facebook_text-info' | translate }}"
								formControlName="facebook"
								[disabled]="module.options.requiredFields.facebook.uniqueEdit &&  eventUserProfile.socialMedias.facebook !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('facebook').touched &&
                    !formEditEventUser.get('facebook').dirty) || 
                    formEditEventUser.get('facebook').value === '' && !formEditEventUser.get('facebook').errors"
								src="assets/icon/Social/Facebook.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('facebook').errors &&
                (formEditEventUser.get('facebook').touched || formEditEventUser.get('facebook').dirty) &&
                    formEditEventUser.get('facebook').value !== ''"
								src="assets/icon/Social/Facebook.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('facebook').errors &&
                    (formEditEventUser.get('facebook').touched || formEditEventUser.get('facebook').dirty)"
								src="assets/icon/Social/Facebook.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
                module.options.requiredFields.facebook.required &&
                formEditEventUser.get('facebook').errors?.required &&
                (formEditEventUser.get('facebook').touched || formEditEventUser.get('facebook').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
                formEditEventUser.get('facebook').errors?.pattern"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.facebook" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							[ngClass]="!isMobile && module.options.enableUserFieldsHideAbility ? 'social' : ''"
							(click)="updateFieldVisibility('facebook')"
							[src]="showUserOrModuleVisibilityOptions('facebook') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>

					<!-- Linkedin field -->
					<ion-col
						*ngIf="module && module.options.requiredFields?.linkedin.editionScope"
						class="custom-ion-col"
						size-xs="12"
						size-sm="12"
						size-lg="6"
					>
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.linkedin" | translate}}
								<span *ngIf="module.options.requiredFields.linkedin.required" class="required">
									*
								</span>
							</ion-label>

							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('linkedin').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.linkedin_text-info' | translate }}"
								formControlName="linkedin"
								[disabled]="module.options.requiredFields.linkedin.uniqueEdit &&  eventUserProfile.socialMedias.linkedin !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('linkedin').touched &&
                    !formEditEventUser.get('linkedin').dirty) ||
                     formEditEventUser.get('linkedin').value === '' && !formEditEventUser.get('linkedin').errors"
								src="assets/icon/Social/Linkedin.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('linkedin').errors &&
                (formEditEventUser.get('linkedin').touched || formEditEventUser.get('linkedin').dirty) &&
                    formEditEventUser.get('linkedin').value !== ''"
								src="assets/icon/Social/Linkedin.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('linkedin').errors &&
                    (formEditEventUser.get('linkedin').touched || formEditEventUser.get('linkedin').dirty)"
								src="assets/icon/Social/Linkedin.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
                module.options.requiredFields.linkedin.required &&
                formEditEventUser.get('linkedin').errors?.required &&
                (formEditEventUser.get('linkedin').touched || formEditEventUser.get('linkedin').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
                formEditEventUser.get('linkedin').errors?.pattern"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.linkedin" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							(click)="updateFieldVisibility('linkedin')"
							[src]="showUserOrModuleVisibilityOptions('linkedin') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>
				</ion-row>

				<ion-row>
					<!-- Twitter field -->
					<ion-col
						*ngIf="module && module.options.requiredFields?.twitter.editionScope"
						class="custom-ion-col"
						size-xs="12"
						size-sm="12"
						size-lg="6"
					>
						<ion-item style="width: 98% !important" mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.twitter" | translate}}
								<span *ngIf="module.options.requiredFields.twitter.required" class="required"> * </span>
							</ion-label>

							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('twitter').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.twitter_text-info' | translate }}"
								formControlName="twitter"
								[disabled]="module.options.requiredFields.twitter.uniqueEdit &&  eventUserProfile.socialMedias.twitter !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('twitter').touched &&
                    !formEditEventUser.get('twitter').dirty) || 
                    formEditEventUser.get('twitter').value === '' && !formEditEventUser.get('twitter').errors"
								src="assets/icon/Social/Twitter X.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('twitter').errors &&
                (formEditEventUser.get('twitter').touched || formEditEventUser.get('twitter').dirty) &&
                    formEditEventUser.get('twitter').value !== ''"
								src="assets/icon/Social/Twitter X.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('twitter').errors &&
                    (formEditEventUser.get('twitter').touched || formEditEventUser.get('twitter').dirty)"
								src="assets/icon/Social/Twitter X.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
                module.options.requiredFields.twitter.required &&
                formEditEventUser.get('twitter').errors?.required &&
                (formEditEventUser.get('twitter').touched || formEditEventUser.get('twitter').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
                formEditEventUser.get('twitter').errors?.pattern"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.twitter" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							[ngClass]="!isMobile && module.options.enableUserFieldsHideAbility ? 'social' : ''"
							(click)="updateFieldVisibility('twitter')"
							[src]="showUserOrModuleVisibilityOptions('twitter') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>

					<!-- Instagram field -->
					<ion-col
						*ngIf="module && module.options.requiredFields?.instagram.editionScope"
						class="custom-ion-col"
						size-xs="12"
						size-sm="12"
						size-lg="6"
					>
						<ion-item mode="md" lines="none" class="input-item">
							<ion-label mode="md" position="floating" [style.color]="event.styling.titleColor">
								{{"edit-profil.instagram" | translate}}
								<span *ngIf="module.options.requiredFields.instagram.required" class="required">
									*
								</span>
							</ion-label>
							<ion-input
								mode="md"
								class="custom-input"
								type="text"
								[ngStyle]="{'color': formEditEventUser.get('instagram').errors ? '#900C3F' : '#4B4D4D'}"
								placeholder="{{ 'edit-profil.placeholders.instagram_text-info' | translate }}"
								formControlName="instagram"
								[disabled]="module.options.requiredFields.instagram.uniqueEdit &&  eventUserProfile.socialMedias.instagram !== ''"
							>
							</ion-input>
							<!-- Untouched field icon -->
							<ion-icon
								*ngIf="(!formEditEventUser.get('instagram').touched &&
                    !formEditEventUser.get('instagram').dirty) || 
                    formEditEventUser.get('instagram').value === '' && !formEditEventUser.get('instagram').errors"
								src="assets/icon/Social/Instagram.svg"
								class="check-icon"
								slot="end"
							>
							</ion-icon>
							<!-- Success icon -->
							<ion-icon
								*ngIf="!formEditEventUser.get('instagram').errors &&
                (formEditEventUser.get('instagram').touched || formEditEventUser.get('instagram').dirty) &&
                    formEditEventUser.get('instagram').value !== ''"
								src="assets/icon/Social/Instagram.svg"
								class="check-icon"
								slot="end"
								color="primary"
							>
							</ion-icon>
							<!-- Errors icon -->
							<ion-icon
								*ngIf="formEditEventUser.get('instagram').errors &&
                    (formEditEventUser.get('instagram').touched || formEditEventUser.get('instagram').dirty)"
								src="assets/icon/Social/Instagram.svg"
								class="check-icon"
								slot="end"
								color="danger"
							>
							</ion-icon>
						</ion-item>
						<!-- Error MSGs -->

						<!-- Blank field -->
						<div
							*ngIf="
                module.options.requiredFields.instagram.required &&
                formEditEventUser.get('instagram').errors?.required &&
                (formEditEventUser.get('instagram').touched || formEditEventUser.get('instagram').dirty)"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.blank.simple_text" | translate }}</p>
						</div>

						<!-- Invalid format -->
						<div
							*ngIf="
                formEditEventUser.get('instagram').errors?.pattern"
							class="form-bloc-error"
						>
							<p class="error-label-text">{{ "edit-profil.errors.invalid.instagram" | translate }}</p>
						</div>

						<!-- Disabled/enabled button -->
						<ion-icon
							*ngIf="module.options.enableUserFieldsHideAbility"
							class="disabled-icon"
							(click)="updateFieldVisibility('instagram')"
							[src]="showUserOrModuleVisibilityOptions('instagram') ? 'assets/icon/visibility_off.svg' :
						'assets/icon/visibility_on.svg'"
						>
						</ion-icon>
					</ion-col>
				</ion-row>

				<ion-row [class.btn-in-desktop]="!isMobile" [class.btn-in-mobile]="isMobile">
					<ion-col size="12">
						<button
							mat-raised-button
							[class.button-spinner]="loader"
							class="btn-validate"
							type="submit"
							(click)="editEventUser()"
							[disabled]="loader || !formEditEventUser.valid || !customFieldFormValid"
							[ngClass]="{'btn-validate[disabled]': !formEditEventUser.valid || !customFieldFormValid}"
							[style.background]="event.styling.btnBgColor"
							[style.color]="event.styling.btnTextColor"
						>
							<span *ngIf="!loader">{{"edit-profil.submit"| translate}}</span>
						</button>
					</ion-col>
				</ion-row>
			</form>
		</div>
	</div>
</div>
