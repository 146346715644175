import { createAction, props } from "@ngrx/store";
import { ICustomField, IModuleCustomField } from "../interfaces/custom-fields.interfaces";
import { IFeedback, IFeedbackQuestionResult } from "../interfaces/feedbacks.interfaces";
import { IFavoriteFolder } from "../interfaces/folders.interfaces";
import { IBaseDocument } from "../interfaces/generics.interfaces";
import { INotification } from "../interfaces/notifications.interfaces";

export enum GenericsModulesDataActionsTypes {
	GetAllBaseCustomFields = "[Custom fields Service] Get all base custom fields",
	GetAllModulesCustomFields = "[Custom fields Service] Get all modules custom fields",
	GetAllFeedbacks = "[Feedbacks Service] Get all feedbacks",
	GetAllFeedbacksResults = "[Feedbacks Service] Get all feedbacks results",
	GetAllGroups = "[Groups Service] Get all groups",
	GetAllLocations = "[Locations Service] Get all locations",
	GetAllNotifications = "[Notifications Service] Get all notifications",
	GetAllTracks = "[Tracks Service] Get all tracks",
	GetAllFavoritesFolders = "[Favorites Service] Get all favorites folders",
	ResetGenericDatasState = "[Generic datas Service] Reset generic datas state"
}

export const GetAllBaseCustomFields = createAction(
	GenericsModulesDataActionsTypes.GetAllBaseCustomFields,
	props<{ payload: ICustomField[]; eventId: string }>()
);

export const GetAllModulesCustomFields = createAction(
	GenericsModulesDataActionsTypes.GetAllModulesCustomFields,
	props<{ payload: IModuleCustomField[]; eventId: string }>()
);

export const GetAllFeedbacks = createAction(
	GenericsModulesDataActionsTypes.GetAllFeedbacks,
	props<{ payload: IFeedback[]; eventId: string }>()
);

export const GetAllFeedbacksResults = createAction(
	GenericsModulesDataActionsTypes.GetAllFeedbacksResults,
	props<{ payload: IFeedbackQuestionResult[] }>()
);

export const GetAllGroups = createAction(
	GenericsModulesDataActionsTypes.GetAllGroups,
	props<{ payload: IBaseDocument[]; eventId: string }>()
);

export const GetAllLocations = createAction(
	GenericsModulesDataActionsTypes.GetAllLocations,
	props<{ payload: IBaseDocument[]; eventId: string }>()
);

export const GetAllNotifications = createAction(
	GenericsModulesDataActionsTypes.GetAllNotifications,
	props<{ payload: INotification[]; eventId: string }>()
);

export const GetAllTracks = createAction(
	GenericsModulesDataActionsTypes.GetAllTracks,
	props<{ payload: IBaseDocument[]; eventId: string }>()
);

export const GetAllFavoritesFolders = createAction(
	GenericsModulesDataActionsTypes.GetAllFavoritesFolders,
	props<{ payload: IFavoriteFolder[]; eventId: string }>()
);

export const ResetGenericDatasState = createAction(
	GenericsModulesDataActionsTypes.ResetGenericDatasState,
	props<any>()
);
