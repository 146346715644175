export enum TypeCustomFields {
	TEXT = 0, // multiLanguageText
	SELECT = 1, // multiLanguageText
	MULTI_SELECT = 2, // multiLanguageSelectArray
	HMTL = 3, // text html
	MULTI_TEXT = 4, // multiLanguageText array
	NUMERIC = 5, // numeric
	URL = 6, // text
	DATE = 7, // date
	IMAGE = 8, // image
	FILE = 9, // file
	EMAIL = 10, // text
	PHONE = 11, // phoneNumber
	MODULE = 12, // module
	ADDRESS = 13, // address
	CHECKBOX = 14, // checkbox
	COUNTRY = 15, // country
	PASSWORD = 16 // password
}

export enum TypeCustomModule {
	BASE = 0,
	MODULE = 1
}

export enum TypeCustomFieldText {
	"text-field" = 0,
	"selection-box-unique" = 1,
	"selection-box-multiple" = 2,
	"rich-text" = 3,
	"multiple-text" = 4,
	"number" = 5,
	"url" = 6,
	"date" = 7,
	"picture" = 8,
	"file" = 9,
	"email" = 10,
	"phone" = 11,
	"module-type" = 12,
	"address" = 13,
	"checkbox" = 14,
	"country" = 15
}

export const TypeFieldDataString = {
	[TypeCustomFields.COUNTRY]: "text",
	[TypeCustomFields.CHECKBOX]: "checkbox",
	[TypeCustomFields.ADDRESS]: "text",
	[TypeCustomFields.MODULE]: "",
	[TypeCustomFields.PHONE]: "tel",
	[TypeCustomFields.EMAIL]: "email",
	[TypeCustomFields.FILE]: "file",
	[TypeCustomFields.IMAGE]: "image",
	[TypeCustomFields.DATE]: "date",
	[TypeCustomFields.URL]: "url",
	[TypeCustomFields.NUMERIC]: "number",
	[TypeCustomFields.MULTI_TEXT]: "text",
	[TypeCustomFields.HMTL]: "textarea",
	[TypeCustomFields.MULTI_SELECT]: "select",
	[TypeCustomFields.SELECT]: "select",
	[TypeCustomFields.TEXT]: "text"
};

export const TypeCustomFieldsDataCorrespondence = {
	[TypeCustomFields.COUNTRY]: "country",
	[TypeCustomFields.CHECKBOX]: "checkbox",
	[TypeCustomFields.ADDRESS]: "text",
	[TypeCustomFields.MODULE]: "module",
	[TypeCustomFields.PHONE]: "phoneNumber",
	[TypeCustomFields.EMAIL]: "text",
	[TypeCustomFields.FILE]: "file",
	[TypeCustomFields.IMAGE]: "image",
	[TypeCustomFields.DATE]: "date",
	[TypeCustomFields.URL]: "url",
	[TypeCustomFields.NUMERIC]: "numeric",
	[TypeCustomFields.MULTI_TEXT]: "multiLanguageTextArray",
	[TypeCustomFields.HMTL]: "multiLanguageText",
	[TypeCustomFields.MULTI_SELECT]: "multiLanguageSelectArray",
	[TypeCustomFields.SELECT]: "multiLanguageText",
	[TypeCustomFields.TEXT]: "text"
};
