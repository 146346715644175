import { createAction, props } from "@ngrx/store";
import { ICheckin } from "../interfaces/checkin.interfaces";

export enum CheckinsActionsTypes {
	GetAllCheckins = "[Checkins Service] Get all checkins",
	ResetCheckinsState = "[Checkins Service] Reset checkins state"
}

export const GetAllCheckins = createAction(
	CheckinsActionsTypes.GetAllCheckins,
	props<{ payload: ICheckin[]; eventId: string }>()
);

export const ResetCheckinsState = createAction(CheckinsActionsTypes.ResetCheckinsState, props<any>());
