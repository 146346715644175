import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { BarcodeScanner } from "@capacitor-community/barcode-scanner";
import { Device } from "@capacitor/device";
import { Network } from "@capacitor/network";
import { SplashScreen } from "@capacitor/splash-screen";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { GetScanning } from "./shared/actions/utility.actions";
import { IAppSettings, IEvent, IEventUser } from "./shared/interfaces";
import { getContainersState } from "./shared/selectors/containers.selectors";
import {
	checkIfDatasEventReady,
	getInit,
	getInitDatasEvent,
	getLoading,
	getScanning
} from "./shared/selectors/utility.selectors";
import { AuthService, ContainersService, StorageService, UtilityService } from "./shared/services";
import { NotificationsService } from "./shared/services/notifications.service";
import "zone.js/plugins/zone-patch-rxjs";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import * as _ from "lodash-es";
import { getCurrentEvent } from "./shared/selectors/events.selectors";
// import { register } from "swiper/element/bundle";
// register();

@Component({
	selector: "app-root",
	templateUrl: "app.component.html",
	styleUrls: ["app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy, AfterViewInit {
	inApp: boolean = false;
	eventUser: IEventUser;

	subscriptions: Subscription[] = [];

	loader: boolean = true;

	isMobile: boolean;
	scanning: boolean = false;

	currentEvent: IEvent;

	onMaintenanceApp: boolean = false;
	logoPlatform =
		environment.firebase.projectId === "invent-app-prd"
			? "/assets/images/invent/logo.png"
			: environment.firebase.projectId === "ceu-app-prd"
			? "/assets/images/ceu/logo.png"
			: "/assets/images/b3/logo.png";

	constructor(
		private STranslate: TranslateService,
		private SContainers: ContainersService,
		private store: Store,
		private SAuth: AuthService,
		private SNotifications: NotificationsService,
		private SStorage: StorageService,
		private platform: Platform,
		private SUtility: UtilityService
	) {
		try {
			if (this.platform.is("mobile") && window.innerWidth < 768 && !this.platform.is("mobileweb")) {
				// window.screen.orientation.lock("portrait");
				ScreenOrientation.lock({ orientation: "portrait" });
			}
			this.store.select(getScanning).subscribe((scanning) => {
				this.scanning = scanning;

				if (scanning) {
					document.body.style.setProperty(`background-color`, "transparent !important");
				} else {
					document.body.style.setProperty(`background-color`, "inherit");
				}
			});
			this.isMobile = this.platform.is("ios") || this.platform.is("android");
			this.subscriptions.push(
				this.SUtility.getAppSettings().subscribe((settings: IAppSettings) => {
					this.onMaintenanceApp =
						settings?.onMaintenanceApp === undefined ? false : settings.onMaintenanceApp;
				})
			);
			this.setLanguageUse();

			this.SUtility.listenToNetworkStatus();

			this.manageSplashscreen("show");
			this.initApp();
		} catch (error) {
			console.log("***** Error app: ", error);
		}
	}

	ngOnInit() {
		this.SStorage.init();
		// Get container
		this.SContainers.getContainers(
			!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
				? "defaultContainer"
				: environment.platform.containerId
		);
		// Init auth
		this.SAuth.initAuth();
		// Init my event user
		// this.SEventUsers.initMyEventUser();

		// init notif config
		this.isMobile &&
			!this.platform.is("mobileweb") &&
			this.SNotifications.initOneSignal(environment.platform.oneSignalAppId);

		this.subscriptions.push(
			this.store.select(getLoading).subscribe((loading) => {
				this.loader = loading;
			})
		);

		this.getContainer();

		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!this.currentEvent || (this.currentEvent && event && this.currentEvent.uid !== event.uid)) {
					this.currentEvent = event;
					this.logo =
						this.currentEvent && this.currentEvent.identity && this.currentEvent.identity.logo
							? this.currentEvent.identity.logo
							: "";
					this.initProgressBar();
				}
			})
		);
	}

	ngAfterViewInit() {
		if (navigator.appVersion.indexOf("Win") != -1) {
			const elements = document.querySelectorAll(".windows_scrollbar");

			elements.forEach((e) => {
				e.setAttribute("class", "custom_scrollbar");
			});
		}
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		//this.SPresence.setPresence("offline");
	}

	progressBar: boolean = true;
	initProgress: number = 0;
	progressBarSub: Subscription;
	logo: string = "";
	initProgressBar() {
		this.progressBar = false;
		// this.store
		// 	.select(
		// 		checkIfDatasEventReady([
		// 			"initBaseCustomFields",
		// 			"initChats",
		// 			"initDocuments",
		// 			"initEvent",
		// 			"initEventUser",
		// 			"initFolders",
		// 			"initGroups",
		// 			"initImages",
		// 			"initModules",
		// 			"initModulesCustomFields",
		// 			"initNotifications",
		// 			"initSchedules",
		// 			"initSpeakers",
		// 			"initVideos"
		// 		])
		// 	)
		// 	.pipe(
		// 		skipWhile((init) => !init),
		// 		take(1)
		// 	)
		// 	.subscribe(() => {
		// 		this.progressBar = false;
		// 	});

		// if (this.progressBarSub && !this.progressBarSub.closed) {
		// 	this.progressBarSub.unsubscribe();
		// }
		// this.progressBarSub = this.store.select(getInitDatasEvent).subscribe((init) => {
		// 	// console.log("Init: ", _.cloneDeep(init));
		// 	const step: number =
		// 		1 /
		// 		Object.keys(init).filter((key) =>
		// 			[
		// 				"initBaseCustomFields",
		// 				"initChats",
		// 				"initDocuments",
		// 				"initEvent",
		// 				"initEventUser",
		// 				"initFolders",
		// 				"initGroups",
		// 				"initImages",
		// 				"initModules",
		// 				"initModulesCustomFields",
		// 				"initNotifications",
		// 				"initSchedules",
		// 				"initSpeakers",
		// 				"initVideos"
		// 			].includes(key)
		// 		).length;
		// 	let progress = 0;
		// 	for (const key of Object.keys(init)) {
		// 		if (init[key]) {
		// 			progress += step;
		// 		}
		// 	}
		// 	if (progress > this.initProgress) {
		// 		this.initProgress = progress;
		// 	}
		// });
	}

	async initApp() {
		const status = await Network.getStatus();
		if (status && status.connected) {
			this.store
				.select(getInit)
				.pipe(
					skipWhile((init) => !init.initAuth || !init.initUser || !init.initContainers),
					take(1)
				)
				.subscribe(() => {
					// When init down stop spash screen etc
					this.manageSplashscreen("hide");
				});
		} else {
			this.manageSplashscreen("hide");
		}
	}

	stopScan() {
		this.store.dispatch(GetScanning({ payload: false }));
		// Case scanning running
		BarcodeScanner.showBackground();
		BarcodeScanner.stopScan();
	}

	/**
	 * Setting used language
	 * @param language
	 */
	async setLanguageUse() {
		let language = environment.platform.defaultLanguage;
		if (this.platform.is("ios") || this.platform.is("android")) {
			const langTag = await Device.getLanguageTag();
			if (["fr-FR", "en-US", "pt-BR", "de-DE", "es-ES"].includes(langTag.value)) {
				const lang = langTag.value.slice(0, 2).toLowerCase() + langTag.value.slice(3, 5);
				language = lang.charAt(0).toUpperCase() + lang.slice(1);
			}
		}
		this.STranslate.setDefaultLang(language);
		this.STranslate.use(language);
	}

	/**
	 * Container and init style
	 */
	getContainer() {
		this.subscriptions.push(
			this.store.select(getContainersState).subscribe((containersState) => {
				const container =
					!environment.platform.containerId || !containersState.currentContainer
						? containersState.defaultContainer
						: containersState.currentContainer;
				if (container) {
					/**
					 * Setting default color
					 */
					document.body.style.setProperty(`--ion-color-default`, container.ionicColors.defaultColor);
					document.body.style.setProperty(
						`--ion-color-default-contrast`,
						container.ionicColors.defaultContrastColor
					);
					document.body.style.setProperty(
						`--ion-color-default-shade`,
						container.ionicColors.defaultShadeColor
					);
					document.body.style.setProperty(`--ion-color-default-tint`, container.ionicColors.defaultTintColor);

					/**
					 * Setting colors of login
					 */
					document.body.style.setProperty(`--valid-btn-bg-color`, container.loginColors.validBtnBgColor);
					document.body.style.setProperty(`--valid-btn-color`, container.loginColors.validBtnColor);
					document.body.style.setProperty(`--login-btn-bg-color`, container.loginColors.loginBtnBgColor);
					document.body.style.setProperty(`--login-btn-color`, container.loginColors.loginBtnColor);
					document.body.style.setProperty(
						`--btn-bg-color-hover`,
						`${container.loginColors.validBtnBgColor}d9`
					);
					document.body.style.setProperty(
						`--text-color-1st-login`,
						container.loginColors.loginTextFirstColor
					);
					document.body.style.setProperty(
						`--text-color-2nd-login-desktop`,
						container.loginColors.loginTextSecondColorDesktop
					);
					document.body.style.setProperty(
						`--text-color-2nd-login-mobile`,
						container.loginColors.loginTextSecondColorMobile
					);
					document.body.style.setProperty(`--label-login-color`, container.loginColors.loginTextColor);

					/**
					 * Menu color
					 */
					document.body.style.setProperty(`--menu-background-color`, container.loginColors.loginTextColor);
					document.body.style.setProperty(`--menu-text-color`, container.loginColors.loginTextColor);
					if (navigator.appVersion.indexOf("Win") != -1) {
						if (!document.getElementById("id-custom-scrollbar")) {
							const sheet = document.createElement("style");
							sheet.id = "id-custom-scrollbar";
							sheet.innerHTML =
								// eslint-disable-next-line max-len
								".custom-scrollbar {--offset-bottom: auto !important;--overflow: hidden;overflow: auto;&::-webkit-scrollbar {width: 8px;-ms-overflow-style: none;scrollbar-width: none;scroll-behavior: smooth;}&::-webkit-scrollbar-thumb {background-color: #00000065 !important;border-radius: 12px !important;}}";
							document.body.appendChild(sheet);
						}
					}
				}
				// this.loader = false;
			})
		);
	}

	/**
	 * Manage splashscreen
	 * @param type
	 */
	async manageSplashscreen(type: string) {
		try {
			const deviceInfo = await Device.getInfo();
			if (
				deviceInfo &&
				deviceInfo.platform &&
				(deviceInfo.platform === "android" || deviceInfo.platform === "ios")
			) {
				if (type === "show") {
					await SplashScreen.show({
						autoHide: false
					});
				} else {
					await SplashScreen.hide();
				}
			}
		} catch (error) {
			// console.error("----- Log Error splashscreen: " + JSON.stringify(error));
		}
	}
}
