import { createReducer, on } from "@ngrx/store";
import * as _ from "lodash-es";
import * as UtilityActions from "../actions/utility.actions";
import { IUtilityState } from "../interfaces/states.interfaces";

export const initialState: IUtilityState = {
	header: {
		item: null,
		module: null,
		title: {
			ArAR: "",
			DeDE: "",
			EnUS: "",
			EsES: "",
			FrFR: "",
			PtBR: ""
		},
		type: null
	},
	init: {
		initAuth: false,
		initClaims: false,
		initContainers: false,
		initEvents: false,
		initUser: false,
		initAllDatasEvent: {
			initAsksQuestions: false,
			initChats: false,
			initCheckins: false,
			initColorsCharts: false,
			initCustomPages: false,
			initDocuments: false,
			initImages: false,
			initVideos: false,
			initFolders: false,
			initEvent: false,
			initEventUser: false,
			initExternalsInteractivity: false,
			initFeedNews: false,
			initBaseCustomFields: false,
			initModulesCustomFields: false,
			initFeedbacks: false,
			initGroups: false,
			initLocations: false,
			initTracks: false,
			initNotifications: false,
			initModules: false,
			initRankings: false,
			initSchedules: false,
			initQuizs: false,
			initSurveys: false,
			initTreasureHunts: false,
			initWidgets: false,
			initWordClouds: false,
			initAutomations: false,
			initRegisterForms: false
		}
	},
	loading: true,
	menuActivated: false,
	scanning: false,
	chatsBadge: 0,
	notificationsBadge: -1,
	paginationsRecords: [],
	customFont: null,
	networkStatus: false
};

export const utilityFeatureKey = "utility";

export const utilityReducers = createReducer(
	initialState,
	on(UtilityActions.InitAuth, (state, action) => ({
		...state,
		init: {
			...state.init,
			initAuth: action.payload
		}
	})),
	on(UtilityActions.InitClaims, (state, action) => ({
		...state,
		init: {
			...state.init,
			initClaims: action.payload
		}
	})),
	on(UtilityActions.InitContainers, (state, action) => ({
		...state,
		init: {
			...state.init,
			initContainers: action.payload
		}
	})),
	on(UtilityActions.InitEvents, (state, action) => ({
		...state,
		init: {
			...state.init,
			initEvents: action.payload
		}
	})),
	on(UtilityActions.InitUser, (state, action) => ({
		...state,
		init: {
			...state.init,
			initUser: action.payload
		}
	})),
	on(UtilityActions.Loading, (state, action) => ({
		...state,
		loading: action.payload
	})),
	on(UtilityActions.MenuActivated, (state, action) => ({
		...state,
		menuActivated: action.payload
	})),
	on(UtilityActions.GetScanning, (state, action) => ({
		...state,
		scanning: action.payload
	})),
	on(UtilityActions.GetHeaderState, (state, action) => ({
		...state,
		header: {
			item: action.payload.item,
			module: action.payload.module ? action.payload.module : null,
			title: action.payload.title,
			type: action.payload.type
		}
	})),
	on(UtilityActions.ResetHeaderState, (state) => ({
		...state,
		header: {
			item: null,
			module: null,
			title: {
				ArAR: "",
				FrFR: "",
				EnUS: "",
				EsES: "",
				PtBR: "",
				DeDE: ""
			},
			type: 0
		}
	})),
	on(UtilityActions.ResetUtilityState, (state) => ({
		...state,
		header: {
			item: null,
			module: null,
			title: {
				ArAR: "",
				DeDE: "",
				EnUS: "",
				EsES: "",
				FrFR: "",
				PtBR: ""
			},
			type: null
		},
		chatsBadge: -1,
		notificationsBadge: -1,
		paginationsRecords: []
	})),
	on(UtilityActions.InitSpecificEventDatasPart, (state, action) => {
		const newState = _.cloneDeep(state);
		newState.init.initAllDatasEvent[action.part] = action.payload;
		return newState;
	}),
	on(UtilityActions.ResetInitDatasEvent, (state) => ({
		...state,
		init: {
			initAuth: state.init.initAuth,
			initClaims: state.init.initClaims,
			initContainers: state.init.initContainers,
			initEvents: state.init.initEvents,
			initUser: state.init.initUser,
			initAllDatasEvent: {
				initAsksQuestions: false,
				initChats: false,
				initCheckins: false,
				initColorsCharts: false,
				initCustomPages: false,
				initDocuments: false,
				initImages: false,
				initVideos: false,
				initFolders: false,
				initEvent: false,
				initEventUser: false,
				initExternalsInteractivity: false,
				initFeedNews: false,
				initBaseCustomFields: false,
				initModulesCustomFields: false,
				initFeedbacks: false,
				initGroups: false,
				initLocations: false,
				initTracks: false,
				initNotifications: false,
				initModules: false,
				initRankings: false,
				initSchedules: false,
				initQuizs: false,
				initSurveys: false,
				initTreasureHunts: false,
				initWidgets: false,
				initWordClouds: false,
				initAutomations: false,
				initRegisterForms: false
			}
		}
	})),
	on(UtilityActions.GetHeaderItem, (state, action) => ({
		...state,
		header: {
			...state.header,
			item: action.payload
		}
	})),
	on(UtilityActions.GetHeaderTitle, (state, action) => ({
		...state,
		header: {
			...state.header,
			title: action.payload
		}
	})),
	on(UtilityActions.GetHeaderType, (state, action) => ({
		...state,
		header: {
			...state.header,
			type: action.payload
		}
	})),
	on(UtilityActions.GetPaginationRecords, (state, action) => ({
		...state,
		paginationsRecords: action.payload
	})),
	on(UtilityActions.SetChatsBadge, (state, action) => ({
		...state,
		chatsBadge: action.payload
	})),
	on(UtilityActions.SetNotificationBadge, (state, action) => ({
		...state,
		notificationsBadge: action.payload
	})),
	on(UtilityActions.GetCustomFont, (state, action) => ({
		...state,
		customFont: action.payload
	})),
	on(UtilityActions.NetworkStatus, (state, action) => ({
		...state,
		networkStatus: action.payload
	}))
);
