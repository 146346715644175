import { createReducer, on } from "@ngrx/store";
import * as ChatsActions from "../actions/chats.actions";
import { IChatsState } from "../interfaces/chats.interfaces";

export const initialState: IChatsState = {
	eventId: "",
	chats: [],
	messages: []
};

export const chatsFeatureKey = "chats";

export const chatsReducers = createReducer(
	initialState,
	on(ChatsActions.GetAllChats, (state, action) => ({
		...state,
		eventId: action.eventId,
		chats: action.payload
	})),
	on(ChatsActions.GetAllMessages, (state, action) => ({
		...state,
		eventId: action.eventId,
		messages: action.payload
	})),
	on(ChatsActions.ResetChatsState, () => ({
		...initialState
	}))
);
