import { createReducer, on } from "@ngrx/store";
import * as WidgetActions from "../actions/widgets.actions";
import { IWidgetsState } from "../interfaces/widget.interfaces";

export const initialState: IWidgetsState = {
	eventId: "",
	widgets: []
};

export const widgetsFeatureKey = "widgets";

export const widgetsReducers = createReducer(
	initialState,
	on(WidgetActions.GetWidgets, (state, action) => ({
		...state,
		eventId: action.eventId,
		widgets: action.payload
	})),
	on(WidgetActions.ResetWidgetsState, () => ({
		...initialState
	}))
);
