<!-- <ion-content class="page"> -->
<div *ngIf="event && module && eventUser" class="background" [class.full-w]="componentMode"
    [style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'">
    <ng-container *ngIf="surveys.length > 0; else noContent">

        <!-- Unanswered surveys list -->
        <ng-container
            *ngIf="(componentMode && getAnsweredOrNotSurveys('unanswered').length > 1 ||
            (getAnsweredOrNotSurveys('unanswered').length === 1 && getAnsweredOrNotSurveys('answered').length > 0)) || (!componentMode && getAnsweredOrNotSurveys('unanswered').length > 0)">
            <ion-item-divider *ngIf="getAnsweredOrNotSurveys('unanswered').length > 0">
                <h6 class="section-title">
                    &nbsp;&nbsp;&nbsp;
                    {{ "surveys.not_answered" | translate }}
                </h6>
            </ion-item-divider>

            <div *ngFor="let survey of getAnsweredOrNotSurveys('unanswered');">
                <ion-item detail class="card-content cursor-pointer survey-item" (click)="openSurvey(survey)"
                    [class.disabled-survey]="survey.activateMinPoints && survey.minPoints > eventUser.points">
                    <ion-avatar slot="start" [style.background]="event.styling.menuColor">
                        <div>
                            <ion-icon src="assets/icon/Star.svg"></ion-icon>
                        </div>
                    </ion-avatar>
                    <ion-label>
                        <h2 [style.color]="event.styling.titleColor">{{ survey.name[currentLanguage] }}</h2>
                        <p [style.color]="event.styling.contentTextColor">
                            {{countQuestions(survey.questions)}} {{ "schedule_detail.interactivity.ask-questions" |
                            translate
                            | lowercase}}
                        </p>
                    </ion-label>
                    <ion-icon slot="end" *ngIf="survey.activateMinPoints && survey.minPoints > eventUser.points"
                        name="information-circle-outline" (click)="presentMinPointsPopover($event,survey.minPoints)"
                        style="cursor: pointer !important;">
                    </ion-icon>
                </ion-item>
            </div>
        </ng-container>

        <ng-container
            *ngIf="getAnsweredOrNotSurveys('unanswered').length === 1 && getAnsweredOrNotSurveys('answered').length === 0 && componentMode">
            <app-survey [componentMode]="true" [eventId]="eventId"
                [moduleId]="getAnsweredOrNotSurveys('unanswered')[0].moduleId"
                [surveyId]="getAnsweredOrNotSurveys('unanswered')[0].uid">
            </app-survey>
        </ng-container>

        <ng-container *ngIf="getAnsweredOrNotSurveys('answered').length > 0">
            <ion-item-divider *ngIf="getAnsweredOrNotSurveys('answered').length > 0">
                <h6 class="section-title">
                    &nbsp;&nbsp;&nbsp;
                    {{ "surveys.answered" | translate }}</h6>
            </ion-item-divider>

            <div *ngFor="let survey of getAnsweredOrNotSurveys('answered')">
                <ion-item detail *ngIf="(survey.changeAnswers || survey.viewAnswered) && survey.visibility"
                    (click)="openSurvey(survey)" class="card-content cursor-pointer" [disabled]="!survey.viewAnswered">
                    <ion-avatar slot="start" [style.background]="event.styling.menuColor">
                        <div>
                            <ion-icon src="assets/icon/Star.svg"></ion-icon>
                        </div>
                    </ion-avatar>
                    <ion-label>
                        <h2 [style.color]="event.styling.titleColor">{{ survey.name[currentLanguage] }}</h2>
                        <p [style.color]="event.styling.contentTextColor">
                            {{countQuestions(survey.questions)}} {{ "schedule_detail.interactivity.ask-questions" |
                            translate
                            | lowercase }}
                        </p>
                    </ion-label>
                </ion-item>

                <ion-item *ngIf="(!survey.changeAnswers && !survey.viewAnswered) && survey.visibility"
                    class="card-content cursor-pointer" [disabled]="!survey.viewAnswered">
                    <ion-avatar slot="start" [style.background]="event.styling.menuColor">
                        <div>
                            <ion-icon src="assets/icon/Star.svg"></ion-icon>
                        </div>
                    </ion-avatar>
                    <ion-label>
                        <h2 [style.color]="event.styling.titleColor">{{ survey.name[currentLanguage] }}</h2>
                        <p [style.color]="event.styling.contentTextColor">
                            {{countQuestions(survey.questions)}} {{ "schedule_detail.interactivity.ask-questions" |
                            translate
                            | lowercase }}
                        </p>
                    </ion-label>
                </ion-item>
            </div>
        </ng-container>


    </ng-container>
    <ng-template #noContent>
        <div class="container-no-content">
            <span class="wait-content" [style.color]="event.styling.titleColor">{{"texts.waiting_for_content" |
                translate }}</span>
            <ion-icon src="assets/images/emptycontent/ENQUETE.svg"
                [style.color]="event.styling.menuColor.includes('linear') ? event.styling.menuColor.split(',')[1] : event.styling.menuColor">
            </ion-icon>
            <div class="text-empty-content" [style.color]="event.styling.contentTextColor">
                <span class="title"> {{ "interactivity.survey_empty_content_title" | translate }}</span>
                <span>{{ "interactivity.survey_empty_content_text" | translate }}</span>
            </div>
        </div>
    </ng-template>

    <div *ngIf="!event || !module">
        <ion-item-divider style="height: 60px;">
            <ion-skeleton-text animated style="width: 150px; margin-left: 20px;"></ion-skeleton-text>
        </ion-item-divider>
        <ion-item lines="none" *ngFor="let item of [1,2,3,4,5,6,7,8,9,10]">
            <ion-avatar slot="start"
                style="display: flex; flex-direction: row; align-items: center; justify-content: center;">
                <ion-skeleton-text style="width: 32px; height: 32px"></ion-skeleton-text>
            </ion-avatar>
            <ion-label>
                <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
            </ion-label>
        </ion-item>
    </div>
</div>

<app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons>
<!-- </ion-content> -->