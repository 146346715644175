import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IEvent, IEventsState } from "../interfaces/events.interfaces";
import * as _ from "lodash-es";

export const getEventsState = createFeatureSelector<IEventsState>("events");

export const getAllEvents = createSelector(getEventsState, (state: IEventsState) => {
	const events = _.cloneDeep(state.events);
	return events.sort((a, b) => (a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0));
});

export const getMyEvents = createSelector(getEventsState, (state: IEventsState) => {
	if (!state.myEvents) {
		return [];
	}
	const events = _.cloneDeep(state.myEvents);
	return events.sort((a, b) => (a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0));
});

export const getPublicsEvents = createSelector(getEventsState, (state: IEventsState) => {
	const publicsEvents = _.cloneDeep(
		state.events.filter((event) => state.myEvents && !state.myEvents.find((evt) => evt.uid === event.uid))
	);
	return publicsEvents.sort((a, b) =>
		a.creationDate > b.creationDate ? 1 : a.creationDate < b.creationDate ? -1 : 0
	);
});

export const getSpecificEvent = (uid: string) =>
	createSelector(getEventsState, (state: IEventsState) => {
		const event: IEvent = state.events.find((event) => event.uid == uid);
		return event ? _.cloneDeep(event) : null;
	});

export const getCurrentEvent = createSelector(getEventsState, (state: IEventsState) => _.cloneDeep(state.currentEvent));

export const checkSameEventId = (uid: string) =>
	createSelector(getCurrentEvent, (event: IEvent) => {
		return event && event.uid === uid ? true : false;
	});
