<div class="main-container">
	<div class="content">
		<mat-stepper
			#stepper
			[class.disable-progress-bar]="true"
			[animationDuration]="'600'"
			(selectionChange)="stepChanged($event)"
			[orientation]="registerForm.formSettings.orientation"
		>
			<form [formGroup]="form">
				<!-- Step 1 (Info) -->
				<mat-step
					[label]="null"
					(completed)="(false)"
					[stepControl]="form.get(section.uid)"
					*ngFor="let section of sections"
				>
					<ng-template matStepLabel>
						{{ section.title }}
					</ng-template>

					<div [formGroupName]="section.uid" class="item-content" *ngFor="let item of section.items">
						<!-- Text type fields -->
						<div *ngIf="item.type === formItemType.TEXT">
							<div
								class="text-data"
								style="white-space: pre-line"
								[innerHTML]="item.value[currentLanguage] | safeHtml"
							></div>
						</div>

						<!-- Input type fields -->
						<div *ngIf="item.type === formItemType.INPUT">
							<div class="bloc-input">
								<!-- "BASE FIELDS" ('NAME' & 'EMAIL') -->
								<ion-item
									*ngIf="
										item.isBaseField &&
										item.inputFieldType !== typeCustomFields.CHECKBOX &&
										item.inputFieldType !== typeCustomFields.IMAGE
									"
									mode="md"
									fill="outline"
									shape="round"
								>
									<!-- Item name -->
									<ion-label mode="md" position="stacked" class="item-name-label label-color">
										{{ item.label[currentLanguage] }}
										<span class="required" *ngIf="item.required"> * </span>
									</ion-label>

									<!-- Text input -->
									<ion-input
										*ngIf="item.inputFieldType === typeCustomFields.TEXT"
										autocapitalize="sentences"
										mode="md"
										class="custom-input"
										[ngClass]="
											mode !== 'register-modal' &&
											mode !== 'accompanying-form-modal' &&
											mode !== 'checkin' &&
											item.isBaseField
												? 'readonly-input-style'
												: 'input-color'
										"
										type="text"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid)?.errors ? '#900C3F' : '#4B4D4D'
										}"
										[placeholder]="STranslate.instant('edit-profil.placeholders.name_text-info')"
										formControlName="{{ item.uid }}"
									>
									</ion-input>

									<!-- Email input -->
									<ion-input
										*ngIf="item.inputFieldType === typeCustomFields.EMAIL"
										mode="md"
										class="custom-input"
										[ngClass]="
											mode !== 'register-modal' &&
											mode !== 'accompanying-form-modal' &&
											mode !== 'checkin' &&
											item.isBaseField
												? 'readonly-input-style'
												: 'input-color'
										"
										[ngClass]="
											registerForm.formSettings.enableAnonymousEmails &&
											item.enableAutoGenerateEmails &&
											'autofill-btn-adapt-input'
										"
										type="email"
										[readonly]="
											mode !== 'register-modal' &&
											mode !== 'accompanying-form-modal' &&
											mode !== 'checkin' &&
											item.isBaseField
										"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid)?.errors ? '#900C3F' : '#4B4D4D'
										}"
										[placeholder]="STranslate.instant('edit-profil.placeholders.example.email1')"
										formControlName="{{ item.uid }}"
									>
										<button
											*ngIf="
												registerForm.formSettings.enableAnonymousEmails &&
												item.enableAutoGenerateEmails
											"
											mat-button
											(click)="generateRandomEmail()"
											[class.auto-generate-button-spinner]="emailButtonLoader"
											[disabled]="emailButtonLoader"
											class="autofill-btn"
											matTooltip="{{ 'buttons.generate-email-address' | translate }}"
										>
											<img
												src="assets/images/magic-wand.png"
												alt="{{ 'buttons.generate-email' | translate }}"
												class="icon"
											/>&nbsp;
											<span class="text">{{ "buttons.generate-email" | translate }}</span>
										</button>
									</ion-input>

									<!-- Password input -->
									<ion-input
										*ngIf="item.inputFieldType === typeCustomFields.PASSWORD"
										autocapitalize="sentences"
										mode="md"
										class="custom-input input-color"
										type="password"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid)?.errors ? '#900C3F' : '#4B4D4D'
										}"
										[placeholder]="
											item.uid === 'passwordConfirmation'
												? STranslate.instant(
														'edit-profil.placeholders.password_confirm_text-info'
												  )
												: STranslate.instant(
														'edit-profil.placeholders.password_create_text-info'
												  )
										"
										formControlName="{{ item.uid }}"
									>
									</ion-input>

									<!-- Icons -->
									<form-field-icons
										style="position: absolute; top: 19px; right: 15px; z-index: 999"
										[fieldId]="item.uid"
										[form]="form.get(section.uid)"
										[language]="currentLanguage"
										[fieldType]="
											!SForms.isMultiLanguageField(item) ? 'singleLanguage' : 'multiLanguage'
										"
									>
									</form-field-icons>
								</ion-item>

								<!-- Profil picture -->
								<div
									*ngIf="item.isBaseField && item.inputFieldType === typeCustomFields.IMAGE"
									class="profile-update-container"
								>
									<label class="profile-image-label label-color"
										>{{ item.label[currentLanguage] }}
										<span class="required" *ngIf="item.required"> * </span>
									</label>
									<div class="image-upload-preview">
										<img
											[src]="
												(eventUser && eventUser?.photoUrl) ||
												actualImage?.webPath ||
												'assets/icon/profile.png'
											"
											class="profile-image-preview"
											alt="Profile Image"
										/>
									</div>

									<label class="icon-bloc">
										<img
											*ngIf="!actualImage && !eventUser?.photoUrl"
											matTooltip="{{ 'edit-profil.placeholders.image_text-info' | translate }}"
											class="upload-icon-button"
											src="assets/icon/camera.png"
											(click)="promptCameraModal(section.uid, item.uid)"
											alt="Télécharger une image"
										/>
										<mat-icon
											*ngIf="actualImage || eventUser?.photoUrl"
											(click)="deleteProfilPic(item)"
											style="cursor: pointer"
											>delete</mat-icon
										>
									</label>
								</div>
								<!-- End of "BASE FIELDS" -->

								<!-- Custom fields -->
								<ion-item
									*ngIf="
										!item.isBaseField &&
										item.inputFieldType !== typeCustomFields.IMAGE &&
										item.inputFieldType !== typeCustomFields.FILE &&
										item.inputFieldType !== typeCustomFields.DATE &&
										item.inputFieldType !== typeCustomFields.PASSWORD &&
										item.inputFieldType !== typeCustomFields.PHONE &&
										item.inputFieldType !== typeCustomFields.CHECKBOX &&
										item.inputFieldType !== typeCustomFields.ADDRESS &&
										item.inputFieldType !== typeCustomFields.MULTI_SELECT
									"
									mode="md"
									fill="outline"
									shape="round"
								>
									<!-- Item name -->
									<ion-label mode="md" position="stacked" class="item-name-label label-color">
										{{ item.label[currentLanguage] }}
										<span class="required" *ngIf="item.required"> * </span>
									</ion-label>

									<!-- Multilanguage text -->
									<ion-input
										*ngIf="item.inputFieldType === typeCustomFields.TEXT"
										autocapitalize="sentences"
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid + '_' + currentLanguage)?.errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										[placeholder]="
											item.uid === 'email'
												? STranslate.instant('edit-profil.placeholders.example.email1')
												: STranslate.instant('edit-profil.placeholders.simple_text-info')
										"
										formControlName="{{ item.uid + '_' + currentLanguage }}"
									>
									</ion-input>

									<!-- Single TExt fields (numeric, email, etc..) fields -->
									<ion-input
										*ngIf="
											item.inputFieldType === typeCustomFields.URL ||
											item.inputFieldType === typeCustomFields.NUMERIC ||
											item.inputFieldType === typeCustomFields.EMAIL
										"
										autocapitalize="sentences"
										mode="md"
										class="custom-input input-color"
										[type]="typeFieldDataString[item.inputFieldType]"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid)?.errors ? '#900C3F' : '#4B4D4D'
										}"
										style="width: 90% !important; margin-left: 0; margin-right: auto"
										[placeholder]="
											item.inputFieldType === typeCustomFields.EMAIL
												? STranslate.instant('edit-profil.placeholders.example.email1')
												: item.inputFieldType === typeCustomFields.NUMERIC
												? STranslate.instant('edit-profil.placeholders.numeric_text-info')
												: STranslate.instant('edit-profil.placeholders.url_text-info')
										"
										formControlName="{{ item.uid }}"
									>
									</ion-input>

									<!-- Icons -->
									<form-field-icons
										style="position: absolute; top: 22px; right: 15px; z-index: 999"
										[fieldId]="item.uid"
										[form]="form.get(section.uid)"
										[language]="currentLanguage"
										[fieldType]="
											!SForms.isMultiLanguageField(item) ? 'singleLanguage' : 'multiLanguage'
										"
									>
									</form-field-icons>

									<!-- Select fields -->
									<ng-container
										*ngIf="getFullOrPartOfComputedCustomField(item.uid, 'both') as computedCus"
									>
										<ion-select
											*ngIf="item.inputFieldType === typeCustomFields.SELECT"
											(ionChange)="onCustomFieldOptionChange($any(computedCus), $event)"
											#myIonSelect
											mode="md"
											class="custom-input input-color select-field"
											type="text"
											[ngStyle]="{
												color:
													form.get(section.uid)?.get(item.uid + '_' + currentLanguage) &&
													form.get(section.uid)?.get(item.uid + '_' + currentLanguage)?.errors
														? '#900C3F'
														: '#4B4D4D'
											}"
											placeholder="{{ 'edit-profil.placeholders.select_text-info' | translate }}"
											formControlName="{{ item.uid + '_' + currentLanguage }}"
											[selectedText]="form.get(section.uid)?.get(item.uid + '_' + currentLanguage)?.[currentLanguage]"
											[compareWith]="compareWithFn"
										>
											<ion-select-option
												*ngFor="
													let option of $any(item.value).baseSettings.options;
													let optIndex = index
												"
												[value]="option"
												disabled="{{
													!eventUser &&
														$any(item.value)?.baseSettings.enableSelectLimit &&
														!canDisableOptionOptionLimit(item.uid, optIndex)
												}}"
											>
												<span>{{ option[currentLanguage] }}</span>
												&nbsp;&nbsp;

												<small
													class="number-limit"
													*ngIf="
														!eventUser &&
														myIonSelect.ionDismiss &&
														realTimeCountValues &&
														$any(item.value)?.baseSettings.enableSelectLimit
													"
													>{{'(' +
													(realTimeCountValues[item.uid]?.["datas"]?.["countValues"]?.[optIndex]?.[
														event.language + "_Limit_" + optIndex
													]
													 + realTimeCountValues[item.uid]?.["datas"]?.["tempCountValues"]?.[optIndex]?.[
														event.language + "_Limit_" + optIndex
													]) +
														" / " +
														$any(getFullOrPartOfComputedCustomField(item.uid, "base"))
															?.optionsLimit?.[optIndex]?.[event.language + "_Limit_" + optIndex] + ')'}}</small
												>
											</ion-select-option>
										</ion-select>
									</ng-container>

									<!-- "Multiple text" field type -->
									<ion-input
										*ngIf="item.inputFieldType === typeCustomFields.MULTI_TEXT"
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid + '_' + currentLanguage)?.errors
												? '#900C3F'
												: '#4B4D4D'
										}"
										formControlName="{{ item.uid + '_' + currentLanguage }}"
										(keypress)="
											onEnter(
												$event,
												getMultiTextInputIndex(item.uid),
												item.label[currentLanguage],
												item.uid
											)
										"
										(focusout)="reinitializeInputStatus($event)"
										id="{{ item.label[currentLanguage] }}"
										placeholder="{{ 'edit-profil.placeholders.multiple_text-info' | translate }}"
									>
									</ion-input>

									<!-- Country field type -->
									<ion-input
										*ngIf="item.inputFieldType === typeCustomFields.COUNTRY"
										[readonly]="true"
										(click)="showCoutryModal($any(item.value), section.uid)"
										autocapitalize="sentences"
										mode="md"
										class="custom-input input-color"
										type="text"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid)?.errors ? '#900C3F' : '#4B4D4D'
										}"
										placeholder="{{ 'edit-profil.placeholders.simple_text-info' | translate }}"
										[formControlName]="item.uid"
									>
									</ion-input>
								</ion-item>

								<!-- "ADDRESS" field type -->
								<mat-form-field
									class="mat-form-field-simple-text"
									floatLabel="always"
									[style.width]="isMobile ? '100%' : '50%'"
									*ngIf="item.inputFieldType === typeCustomFields.ADDRESS"
								>
									<mat-label class="label-color">
										{{ item.label[currentLanguage] }}
									</mat-label>
									<input
										#addressInput
										formControlName="{{ item.uid }}"
										matInput
										style="width: 90% !important; margin-left: 0; margin-right: auto"
										[matAutocomplete]="auto"
										class="custom-input addressInput"
										type="text"
										(keyup)="onKeyupAddress(item.uid, addressInput?.value)"
										[ngStyle]="{
											color: form.get(section.uid).get(item.uid)?.errors ? '#900C3F' : '#4B4D4D'
										}"
										placeholder="{{ 'placeholders.address_plc' | translate }}"
									/>
									<!-- Icons -->
									<form-field-icons
										*ngIf="!getCustomFieldAddressState(item.uid)?.loading"
										style="position: absolute; top: 22px; right: 0px; z-index: 999"
										[fieldId]="item.uid"
										[form]="form.get(section.uid)"
										[language]="currentLanguage"
										[fieldType]="
											!SForms.isMultiLanguageField(item) ? 'singleLanguage' : 'multiLanguage'
										"
									>
									</form-field-icons>
									<span
										class="address-loader-spinner"
										[class.form-button-spinner]="getCustomFieldAddressState(item.uid)?.loading"
									></span>

									<mat-autocomplete
										#auto="matAutocomplete"
										style="position: relative; top: -10px; left: 5px"
										[displayWith]="displayFn"
									>
										<mat-option
											*ngFor="
												let prediction of getPlacePredictionsById(
													$any(item.value).baseSettings.uid
												)
											"
											(optionSelections)="
												displayFn($event);
												getCustomFieldAddressState(
													$any(item.value).baseSettings.uid
												).loading = false
											"
											(click)="
												displayFn($event);
												getCustomFieldAddressState(
													$any(item.value).baseSettings.uid
												).loading = false
											"
											[value]="prediction.description"
										>
											{{ prediction.description }}
										</mat-option>
									</mat-autocomplete>
								</mat-form-field>

								<!-- multi-select fields -->
								<ng-container
									style="
										width: 100%;
										display: flex;
										justify-content: center;
										align-items: center;
										margin: 16px;
									"
									*ngIf="getFullOrPartOfComputedCustomField(item.uid, 'both') as computedCus"
								>
									<mat-form-field
										class="mat-form-field"
										[style.width]="isMobile ? '100%' : '50%'"
										lines="none"
										*ngIf="item.inputFieldType === typeCustomFields.MULTI_SELECT"
									>
										<mat-label class="label-color">{{ item.label[currentLanguage] }}</mat-label>

										<mat-select
											multiple
											#myMultiSelect
											[compareWith]="compareWithFn"
											(selectionChange)="onCustomFieldOptionChange($any(computedCus), $event)"
											placeholder="{{ 'edit-profil.placeholders.select_text-info' | translate }}"
											formControlName="{{ item.uid + '_' + currentLanguage }}"
											shape="round"
										>
											<mat-option
												[disabled]="
													item.inputFieldType === typeCustomFields.MULTI_SELECT &&
													$any(item.value)?.baseSettings.enableChoiceLimit &&
													isOptionDisabled(
														section.uid,
														item.uid,
														option,
														$any(item.value)?.baseSettings.choiceLimit
													)
												"
												*ngFor="
													let option of $any(item.value).baseSettings.options;
													trackBy: trackOptionsByFn
												"
												[value]="option"
											>
												{{ option[currentLanguage] }}
											</mat-option>
										</mat-select>
										<ion-note *ngIf="myMultiSelect.value" style="font-style: italic; opacity: 0.7">
											{{
												"texts.x_selected_items"
													| translate : { count: myMultiSelect.value.length }
											}}
										</ion-note>
									</mat-form-field>
								</ng-container>

								<!-- Date Field type -->

								<mat-form-field
									class="mat-form-field-simple-text"
									*ngIf="item.inputFieldType === typeCustomFields.DATE"
									labelFloat="always"
									[style.width]="!isMobile ? '50%' : '100%'"
								>
									<mat-label class="label-color">{{ item.label[currentLanguage] }} </mat-label>
									<input
										matInput
										readonly="readonly"
										style="width: 90% !important; margin-left: 0; margin-right: auto"
										[matDatepicker]="picker"
										[formControlName]="item.uid"
									/>
									<mat-datepicker-toggle matSuffix [for]="picker"> </mat-datepicker-toggle>
									<mat-datepicker #picker></mat-datepicker>
								</mat-form-field>

								<!-- "Phone number" field type -->
								<div
									class="custom-field-container tel-input-container"
									*ngIf="item.inputFieldType === typeCustomFields.PHONE"
								>
									<ion-label class="item-has-focus label-color">
										{{ item.label[currentLanguage] }}
										<span class="required" *ngIf="item.required"> * </span>
									</ion-label>

									<ngx-intl-tel-input
										style="width: 90% !important; margin-left: 0; margin-right: auto"
										class="input"
										[cssClass]="'tel-input'"
										[preferredCountries]="preferedCountries"
										[enableAutoCountrySelect]="false"
										[enablePlaceholder]="true"
										[searchCountryFlag]="true"
										[searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
										[selectFirstCountry]="false"
										[selectedCountryISO]="
											itemsValues[item.uid] &&
											itemsValues[item.uid]?.field &&
											itemsValues[item.uid]?.field?.phoneNumber &&
											itemsValues[item.uid]?.field?.phoneNumber?.countryCode
												? itemsValues[item.uid]?.field?.phoneNumber?.countryCode?.toLowerCase()
												: CountryISO[currentLanguage.slice(2)]
										"
										[maxLength]="15"
										[phoneValidation]="true"
										[inputId]="item.uid"
										[enablePlaceholder]="true"
										name="phone"
										formControlName="{{ item.uid }}"
									></ngx-intl-tel-input>

									<cf-form-visual-icon
										style="position: absolute; top: 25px; right: 15px; z-index: 999"
										[mode]="'singleLanguage'"
										class="input-icon"
										[computedCustomField]="$any(item.value)"
										[form]="form.get(section.uid)"
										[language]="currentLanguage"
									></cf-form-visual-icon>
									<!-- Validation -->
								</div>

								<!-- multi text view -->
								<div
									*ngIf="!item.isBaseField && item.inputFieldType === typeCustomFields.MULTI_TEXT"
									class="center-item"
								>
									<!-- On Enter Error MSG -->
									<div
										*ngIf="
											multipleTextInputError[getMultiTextInputErrorIndex(item.uid)]?.msg !== ''
										"
										class="form-bloc-error"
									>
										<p class="error-label-text">
											{{ multipleTextInputError[getMultiTextInputErrorIndex(item.uid)]?.msg }}
										</p>
									</div>

									<!-- Ion chip item -->
									<div
										class="filter-group padding-10 margin-b-0 multiple-text-input"
										*ngIf="
											multipleTextValues[getMultiTextInputIndex(item.uid)]
												?.multiLanguageTextArray &&
											multipleTextValues[getMultiTextInputIndex(item.uid)]
												?.multiLanguageTextArray[currentLanguage].length > 0
										"
										width="50%"
									>
										<span
											*ngFor="
												let value of multipleTextValues[getMultiTextInputIndex(item.uid)]
													?.multiLanguageTextArray[currentLanguage]
											"
										>
											<ion-chip>
												<!-- <ion-icon name="pin" color="primary"></ion-icon> -->
												<ion-label class="multiple-text-value">{{ value }}</ion-label>
												<ion-icon
													*ngIf="
														(item.uniqueEdit &&
															this.multipleTextValues[
																this.getMultiTextInputIndex(item.uid)
															].multiLanguageTextArray[currentLanguage].length === 0) ||
														!item.uniqueEdit
													"
													name="close-circle"
													(click)="removeQueryItem(item.uid, value, 99, currentLanguage)"
												></ion-icon>
											</ion-chip>
										</span>
										<br />
									</div>
								</div>

								<!-- Image field type -->
								<div
									*ngIf="!item.isBaseField && item.inputFieldType === typeCustomFields.IMAGE"
									[style.width]="isMobile ? '100%' : '50%'"
								>
									<input
										[formControlName]="item.uid"
										type="file"
										[attr.id]="section.uid + '_' + item.uid"
										style="width: 90% !important; margin-left: 0; margin-right: auto"
										name="image-input"
										class="image-input"
										accept="image/*"
										(change)="loadCustomFieldFileImage($event, $any(item.value), section.uid)"
									/>

									<label class="image-input-label">
										<div class="image-input-bloc">
											<label class="custom-field-name">
												{{ item.label[currentLanguage] }}
												<span class="required" *ngIf="item.required"> * </span>
											</label>
											<a
												target="_blank"
												(click)="openDocumentOrImage($any(item.value).fieldDatas?.field?.image)"
												*ngIf="
													($any(item.value).fieldDatas &&
														$any(item.value).fieldDatas.field?.image?.url &&
														$any(item.value).fieldDatas.field?.image?.url !== '') ||
													isValidUrl
												"
												class="input-image-link"
											>
												<span>
													<input
														style="cursor: pointer"
														[formControlName]="item.uid + '_URL'"
														[placeholder]="
															($any(item.value).fieldDatas &&
																$any(item.value).fieldDatas.field?.image?.url &&
																$any(item.value).fieldDatas.field.image.url !== '') ||
															isValidUrl
																? getCorrespondingItemFileOrImage(item.uid)?.name
																: STranslate.instant(
																		'placeholders.link-or-import-image'
																  )
														"
														type="text"
														class="input-title"
														slot="end"
														(input)="getImageByUrl($event, $any(item.value), section.uid)"
														[disabled]="
															isValidUrl ||
															($any(item.value).fieldDatas.field.image.url &&
																$any(item.value).fieldDatas.field.image.url !== '')
														"
													/>
												</span>
											</a>
											<input
												*ngIf="
													!$any(item.value).fieldDatas?.field?.image?.url ||
													$any(item.value).fieldDatas.field?.image?.url === ''
												"
												class="custom-input-placeholder"
												[formControlName]="item.uid + '_URL'"
												[placeholder]="STranslate.instant('placeholders.link-or-import-image')"
												type="url"
												class="input-title"
												slot="end"
												(input)="getImageByUrl($event, $any(item.value), section.uid)"
												[disabled]="
													isValidUrl ||
													($any(item.value).fieldDatas?.field?.image?.url &&
														$any(item.value).fieldDatas?.field?.image?.url !== '')
												"
											/>
										</div>

										<label
											*ngIf="
												($any(item.value).fieldDatas &&
													$any(item.value).fieldDatas.field?.image?.url &&
													$any(item.value)?.fieldDatas?.field?.image?.url !== '') ||
												isValidUrl
											"
											class="file-clear-icon"
											(click)="deleteCustomFieldFileOrImage($any(item.value), section.uid)"
										>
											<img
												src="assets/images/trash.png"
												class="check-icon"
												slot="end"
												color="danger"
											/>
										</label>

										<label [attr.for]="section.uid + '_' + item.uid" class="icon-bloc">
											<img
												class="input-icon"
												lazy="true"
												slot="end"
												src="/assets/images/upload.png"
											/>
										</label>
									</label>
								</div>

								<!-- "File" field type -->
								<div
									*ngIf="item.inputFieldType === typeCustomFields.FILE"
									[style.width]="isMobile ? '100%' : '50%'"
								>
									<input
										class="custom-input-placeholder"
										[formControlName]="item.uid"
										type="file"
										[attr.id]="section.uid + '_' + item.uid"
										style="width: 90% !important; margin-left: 0; margin-right: auto"
										name="file-input"
										class="file-input"
										accept=".xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.pdf,.mp3,audio/*,image/*"
										(change)="loadCustomFieldFileImage($event, $any(item.value))"
									/>

									<label class="file-input-label">
										<div class="bloc-label">
											<label class="custom-field-name">
												{{ item.label[currentLanguage] }}
												<span class="required" *ngIf="item.required"> * </span>
											</label>
											<label class="input-title" style="color: rgb(109, 109, 109)" slot="end">
												<span
													class="file-name-clickable"
													(click)="
														openDocumentOrImage(
															$any(item.value).fieldDatas?.field?.file
																? $any(item.value).fieldDatas.field?.file
																: null
														)
													"
													*ngIf="
														$any(item.value).fieldDatas &&
														$any(item.value).fieldDatas?.field?.file &&
														$any(item.value).fieldDatas?.field.file?.name !== ''
													"
												>
													{{ $any(item.value).fieldDatas.field?.file?.name }}</span
												>
												<span
													class="file-name-placeholder"
													*ngIf="
														($any(item.value).fieldDatas &&
															$any(item.value).fieldDatas?.field?.file?.name === '') ||
														$any(item.value).fieldDatas?.field?.file?.name === null ||
														$any(item.value).fieldDatas?.field?.file?.name === undefined
													"
												>
													{{ "edit-profil.placeholders.file_text-info" | translate }}
												</span>
											</label>
										</div>

										<label
											*ngIf="
												$any(item.value).fieldDatas &&
												$any(item.value).fieldDatas?.field?.file?.url &&
												$any(item.value).fieldDatas.field.file.url !== ''
											"
											class="file-clear-icon"
											(click)="deleteCustomFieldFileOrImage($any(item.value), section.uid)"
										>
											<img
												src="assets/images/trash.png"
												class="check-icon"
												slot="end"
												color="danger"
											/>
										</label>

										<label [attr.for]="section.uid + '_' + item.uid" class="icon-bloc">
											<img
												class="input-icon"
												lazy="true"
												slot="end"
												src="/assets/images/file-upload.png"
											/>
										</label>
									</label>
								</div>

								<!-- "CHECKBOX" field type -->
								<div
									style="
										margin: 15px 0 5px 0;
										display: flex;
										justify-content: center;
										align-items: center;
									"
									[style.width]="isMobile ? '100%' : '50%'"
									*ngIf="item.inputFieldType === typeCustomFields.CHECKBOX"
								>
									<ion-checkbox
										style="min-width: 25px"
										[formControlName]="item.uid"
										[style.--background-checked]="event.styling.contentTextColor"
										[style.--border-color-checked]="event.styling.contentTextColor"
										mode="ios"
									>
									</ion-checkbox>
									<ion-label
										*ngIf="!item.isBaseField"
										for="{{ item.uid }}"
										class="p-l-10"
										style="padding: 0 10px"
										[style.color]="event.styling.contentTextColor"
									>
										{{ item.label[currentLanguage] }}&nbsp;
										<span class="required" *ngIf="item.required"> * </span>
									</ion-label>
									<ion-label
										*ngIf="item.isBaseField"
										class="p-l-10"
										[style.color]="event.styling.contentTextColor"
									>
										{{ "login.accept_term" | translate }}&nbsp;
										<a
											class="conditions-class"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('terms')"
											>{{ "login.terms_of_use" | translate }}</a
										>
										{{ "labels.and" | translate }}&nbsp;
										<a
											class="conditions-class"
											ion-button
											clear
											icon-only
											(click)="openModalTermsOrPrivacy('privacy')"
											>{{ "login.privacy_txt" | translate }}</a
										>
										<span class="required" *ngIf="item.required"> * </span>
									</ion-label>
								</div>

								<!-- Errors -->
								<ion-note style="text-align: center" slot="error">
									<form-field-errors
										[field]="item"
										[form]="form.get(section.uid)"
										[language]="currentLanguage"
										[fieldType]="
											!SForms.isMultiLanguageField(item) ? 'singleLanguage' : 'multiLanguage'
										"
									>
									</form-field-errors>
								</ion-note>

								<!-- Item description -->
								<ion-label *ngIf="item.showDescription" class="field-description">
									<p [innerHTML]="item.description[currentLanguage] | safeHtml"></p>
								</ion-label>
							</div>
						</div>

						<!-- Module type fields -->
						<ng-container *ngIf="item.type === formItemType.MODULE">
							<ng-container *ngIf="getSpecificModule($any(item.value)?.uid) as registerModule">
								<ng-container
									*ngIf="
										getSpecificItemsOfModule(
											$any(item.value)?.uid,
											registerModule.type
										) as registerSessionItems
									"
								>
									<div
										*ngIf="
											item.type === formItemType.MODULE && $any(item.value)?.uid && registerModule
										"
										style="
											width: 100%;
											display: flex;
											flex-direction: column;
											align-items: center;
											justify-content: center;
										"
									>
										<ion-label *ngIf="item.showDescription" class="field-description">
											<p [innerHTML]="item.description[currentLanguage] | safeHtml"></p>
										</ion-label>
										<cdk-virtual-scroll-viewport
											[itemSize]="70"
											minBufferPx="280"
											maxBufferPx="560"
											[style.width]="isMobile ? '100%' : '50%'"
											[style.height]="
												registerSessionItems && registerSessionItems.length <= 4
													? registerSessionItems.length * 70 + 'px'
													: '280px'
											"
										>
											<div
												*cdkVirtualFor="let session of registerSessionItems"
												style="
													width: 100%;
													height: 70px;
													display: flex;
													flex-direction: row;
													align-items: center;
													justify-content: space-between;
													padding: 0 25px;
												"
												[style.opacity]="
													myCheckbox &&
													!myCheckbox.checked &&
													checkRegisterCheckboxDisable(item, session, registerModule.type)
														? 0.3
														: 1
												"
											>
												<div
													style="
														width: calc(100% - 70px);
														height: 100%;
														display: flex;
														flex-direction: column;
														align-items: flex-start;
														justify-content: center;
													"
												>
													<div
														style="
															width: 100%;
															height: fit-content;
															display: flex;
															flex-direction: row;
															align-items: center;
															justify-content: flex-start;
														"
													>
														<ion-icon
															name="calendar-outline"
															slot="start"
															style="margin-right: 10px"
														></ion-icon>
														<ion-label
															style="
																white-space: nowrap;
																overflow: hidden;
																text-overflow: ellipsis;
																width: 100%;
															"
															>{{ session.name[currentLanguage] }}</ion-label
														>
													</div>
													<span
														style="
															font-size: small;
															font-weight: bold;
															margin-left: 25px;
															font-style: italic;
														"
														>{{
															SUtility.getPartOfDate(
																event,
																null,
																session.startDate,
																"short"
															) +
																" - " +
																SUtility.getPartOfDate(
																	event,
																	null,
																	session.startDate,
																	"time"
																)
														}}{{
															session.endDate
																? " - " +
																  SUtility.getPartOfDate(
																		event,
																		null,
																		session.endDate,
																		"time"
																  )
																: ""
														}}</span
													>
													<span
														*ngIf="
															registerModule.type === typeModule.SCHEDULE &&
															session.attendeesLimit >= 0
														"
														style="
															font-size: small;
															font-weight: bold;
															margin-left: 25px;
															font-style: italic;
														"
													>
														({{ session.registeredUsers.length }}/{{
															session.attendeesLimit
														}})
													</span>
												</div>
												<ion-checkbox
													#myCheckbox
													slot="end"
													[value]="checkValueOfItem(item, session, registerModule.type)"
													(ionChange)="
														registerOrUnregisterToItem(
															item,
															session,
															registerModule.type,
															$event
														)
													"
													[disabled]="
														!myCheckbox.checked &&
														checkRegisterCheckboxDisable(item, session, registerModule.type)
													"
												></ion-checkbox>
											</div>
										</cdk-virtual-scroll-viewport>
									</div>
								</ng-container>
							</ng-container>
						</ng-container>
					</div>

					<div class="reset-password">
						<p
							*ngIf="shouldShowResetPasswordText(section, $any(mode))"
							class="to-login-message"
							style="text-align: center; cursor: pointer"
						>
							<a class="forgot-password link" (click)="recoveryPassword()">{{
								"login.forgot_pass" | translate
							}}</a>
						</p>
					</div>

					<div class="error-msg">
						<!-- Error messages -->
						<ion-row *ngIf="messages?.errorMsg !== ''">
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								style="padding-left: 5px !important; padding-right: 5px !important"
							>
								<p class="form-msg-text-error">{{ messages?.errorMsg }}</p>
							</ion-col>
						</ion-row>

						<!-- Warning messages -->
						<ion-row *ngIf="messages?.warningMsg !== ''">
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								style="padding-left: 5px !important; padding-right: 5px !important"
							>
								<p class="form-msg-text-warning">{{ messages?.warningMsg }}</p>
							</ion-col>
						</ion-row>

						<!-- Success messages -->
						<ion-row *ngIf="messages?.successMsg !== ''">
							<ion-col
								size-sm="12"
								size-md="6"
								size-lg="6"
								push-md="3"
								push-lg="3"
								style="padding-left: 5px !important; padding-right: 5px !important"
							>
								<p class="form-msg-text-success">{{ messages?.successMsg }}</p>
							</ion-col>
						</ion-row>
					</div>

					<!-- Actions buttons -->
					<div [ngClass]="actualStep === 0 ? 'step-action-center' : 'step-action-arround'">
						<!-- Previous step button -->
						<ion-button
							*ngIf="actualStep > 0"
							(click)="onPreviousStep()"
							shape="round"
							class="btn-default access-btn"
							[style.color]="event.styling.btnTextColor"
							[style.--background]="event.styling.btnBgColor"
							[attr.disabled]="loader"
						>
							<span *ngIf="!buttonLoader">{{ "register.previous_step" | translate }}</span>
							<ion-spinner *ngIf="buttonLoader" name="crescent" color="light"></ion-spinner>
						</ion-button>

						<!-- Next step button -->
						<ion-button
							[hidden]="mode !== 'register-modal' && nextSectionId === 'submitSection'"
							*ngIf="
								(nextSectionId && nextSectionId !== 'submitSection') ||
								(section.defaultNextSectionId && section.defaultNextSectionId !== 'submitSection') ||
								(mode === 'register-modal' && actualStep < sections.length - 1)
							"
							(click)="onNextStep(section)"
							shape="round"
							class="btn-default access-btn"
							[attr.disabled]="
								loader || !form.get(section.uid).valid || !isProfilPictureValid || lockNextButton
							"
							[style.color]="event.styling.btnTextColor"
							[style.--background]="event.styling.btnBgColor"
						>
							<span *ngIf="!loader">{{ "register.next_step" | translate }}</span>
							<ion-spinner *ngIf="loader" name="crescent" color="light"></ion-spinner>
						</ion-button>

						<!-- Validate button for 'register' & 'checkin' modes -->
						<ion-button
							(click)="onValidate()"
							*ngIf="
								actualStep === sections.length - 1 ||
								(nextSectionId === 'submitSection' &&
									mode !== 'register-modal' &&
									mode !== 'accompanying-form-modal' &&
									mode !== 'checkin') ||
								(mode === 'fill-form-modal' &&
									(actualStep === sections.length - 1 ||
										nextSectionId === 'submitSection' ||
										section.defaultNextSectionId === 'submitSection')) ||
								((mode === 'accompanying-form-modal' || mode === 'checkin') &&
									(actualStep === sections.length - 1 ||
										nextSectionId === 'submitSection' ||
										section.defaultNextSectionId === 'submitSection'))
							"
							shape="round"
							class="btn-default access-btn"
							[style.--background]="registerForm.formContent.textColor"
							[attr.disabled]="canNotSubmitForm()"
						>
							<span *ngIf="!loader">{{ "buttons.validate" | translate }}</span>
							<ion-spinner *ngIf="loader" name="crescent" color="light"></ion-spinner>
						</ion-button>

						<!-- User already has an account -->
						<p
							*ngIf="mode === 'register-modal' && event.settings.allowSwitchForm && actualStep === 0"
							class="to-login-message"
							[style.color]="event.styling.contentTextColor"
						>
							{{ "register.user_already_has_an_account" | translate }}
							<a class="link" (click)="openLogin()">{{ "login.access_your_account" | translate }}</a>
						</p>
					</div>
				</mat-step>
			</form>
		</mat-stepper>
	</div>
</div>
