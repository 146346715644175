<div class="content" [style.backgroundColor]="event.styling.bgContentColor">
	<h2 [style.color]="event.styling.titleColor" mat-dialog-title style="font-weight: 600; text-align: center">
		{{ "register.register_an_accompanying_user_title" | translate }}
	</h2>
	<div class="text-content">
		<p style="text-align: center" [style.color]="event.styling.contentTextColor">
			{{ "register.accompanying_remaining_places" | translate : { places: remainingPlaces } }}
		</p>
	</div>
	<div></div>
	<div class="actions-buttons">
		<button
			[style.backgroundColor]="event.styling.btnBgColor"
			[style.color]="event.styling.btnTextColor"
			(click)="openAccompanyingRegisterForm()"
			mat-button
		>
			{{ "buttons.new" | translate }}
		</button>
		<button (click)="closeModal()" mat-button>
			{{ "buttons.close" | translate }}
		</button>
	</div>
</div>
