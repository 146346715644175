import { ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, OnInit, Output } from "@angular/core";
import { ModalController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import { Subscription } from "rxjs";
import { IEvent, IEventUser } from "src/app/shared/interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { EventUsersService } from "src/app/shared/services";
import { environment } from "src/environments/environment";
import * as _ from "lodash-es";

@Component({
	selector: "app-timezone-switch",
	templateUrl: "./timezone-switch.component.html",
	styleUrls: ["./timezone-switch.component.scss"]
})
export class TimezoneSwitchComponent implements OnInit {
	subscriptions: Subscription[] = [];
	@Input() event: IEvent;
	eventUser: IEventUser;

	@Input() disposition: "row" | "column" = "column";
	@Input() height: string = "200px";

	language: string = environment.platform.defaultLanguage;
	updating: boolean = false;
	timezoneType: boolean = false;

	constructor(
		private store: Store,
		private modalCtrl: ModalController,
		private STranslate: TranslateService,
		private SEventUsers: EventUsersService,
		private cd: ChangeDetectorRef
	) {}

	ngOnInit() {
		this.language = this.STranslate.currentLang;
		this.getEventUser();
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	getCurrentTimezone() {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return timezone ? ` (${timezone})` : "";
	}

	getEventUser() {
		this.subscriptions.push(
			this.store.select(getCurrentEventUser).subscribe((eventUser) => {
				if (!_.isEqual(this.eventUser, eventUser)) {
					this.eventUser = eventUser;
					this.timezoneType = this.eventUser.updatedSettings.timezoneType === "local" ? true : false;
				}
			})
		);
	}
	/**
	 * Change timezone type
	 * @param change
	 */
	async changeTimezoneType(change: any) {
		try {
			if (!this.updating && this.event && this.eventUser) {
				this.updating = true;
				this.eventUser.updatedSettings.timezoneType = change.detail.checked ? "local" : "event";
				await this.SEventUsers.updateEventUserUpdatedSettings(
					this.event.uid,
					this.eventUser.moduleId,
					this.eventUser.uid,
					{
						timezoneType: this.eventUser.updatedSettings.timezoneType
					}
				);
				// await this.SEventUsers.updateEventUser(this.event.uid, this.eventUser.moduleId, this.eventUser);
				this.updating = false;
				this.cd.markForCheck();
			}
		} catch (error) {
			this.updating = false;
			this.cd.markForCheck();
		}
	}

	/**
	 * closeFilter
	 */
	closeFilter() {
		this.modalCtrl.dismiss();
	}
}
