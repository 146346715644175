<div *ngIf="event && module && asksQuestions.length === 1" [style.width]="componentMode ? '100%' : '70%'"
    [style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'" style="margin: auto; height: 100%;">
    <app-ask-questions [componentMode]="true" [eventId]="eventId" [moduleId]="asksQuestions[0].moduleId"
        [askQuestionsId]="asksQuestions[0].uid" [showBackBtn]="false">
    </app-ask-questions>
</div>
<div *ngIf="event && module && (asksQuestions.length === 0 || asksQuestions.length > 1)" class="background"
    [class.full-w]="componentMode">
    <ng-container *ngIf="asksQuestions.length > 0; else noContent">
        <div *ngFor="let askQuestions of asksQuestions">
            <ion-item detail class="card-content cursor-pointer" [style.--background]="'transparent'"
                (click)="openAskQuestions(askQuestions)">
                <ion-avatar slot="start" [style.background]="event.styling.menuColor">
                    <div>
                        <ion-icon name="help-outline"></ion-icon>
                    </div>
                </ion-avatar>
                <ion-label>
                    <span [style.color]="event.styling.titleColor">{{ askQuestions.name[currentLanguage] }}</span>
                </ion-label>
            </ion-item>
        </div>
    </ng-container>

    <ng-template #noContent>
        <div class="container-no-content">
            <span class="wait-content" [style.color]="event.styling.titleColor">{{"texts.waiting_for_content" |
                translate }}</span>
            <ion-icon src="assets/images/emptycontent/QUESTION.svg"
                [style.color]="event.styling.menuColor.includes('linear') ? event.styling.menuColor.split(',')[1] : event.styling.menuColor">
            </ion-icon>
            <div class="text-empty-content" [style.color]="event.styling.contentTextColor">
                <span class="title"> {{ "interactivity.questions_empty_content_title" | translate }}</span>
                <span>{{ "interactivity.questions_empty_content_text" | translate }}</span>
            </div>
        </div>
    </ng-template>
</div>