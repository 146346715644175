import { Injectable } from "@angular/core";
import { Auth, authState } from "@angular/fire/auth";
import { Database, objectVal, ref, update, onDisconnect, serverTimestamp } from "@angular/fire/database";
import { Store } from "@ngrx/store";
import { firstValueFrom, map, of, skipWhile, Subscription, switchMap, take, tap } from "rxjs";
import { IEvent } from "../interfaces";
import { getCurrentEvent } from "../selectors/events.selectors";

@Injectable({
	providedIn: "root"
})
export class PresenceService {
	eventSub: Subscription;
	updateOnUserSub: Subscription;
	updateOnDisconnectSub: Subscription;

	eventId: string = null;
	event: IEvent;
	user;
	previousEventId: string = null;

	constructor(private auth: Auth, private db: Database, private store: Store) {}

	getEvent() {
		return this.store
			.select(getCurrentEvent)
			.pipe(skipWhile((event) => !event))
			.subscribe((event) => {
				this.eventId = event ? event.uid : null;
				if (
					(!this.previousEventId && this.eventId !== null && this.previousEventId !== this.eventId) ||
					(this.previousEventId && this.eventId !== null && this.previousEventId !== this.eventId)
				) {
					this.previousEventId = this.eventId;
				}
			});
	}

	getUser() {
		return authState(this.auth)
			.pipe(skipWhile((user) => !user))
			.subscribe((user) => {
				this.user = user;
			});
	}

	getPresence(uid: string) {
		return objectVal(ref(this.db, `status/${uid}`));
		// return this.db.object(`status/${uid}`).valueChanges();
	}

	async setPresence(status: string) {
		try {
			(!this.user || this.user === null) &&
				(this.user = await firstValueFrom(
					authState(this.auth).pipe(
						skipWhile((user) => !user),
						take(1)
					)
				));
			((status === "online" && (!this.eventId || this.eventId === null)) ||
				(status === "offline" && (!this.previousEventId || this.previousEventId === null))) &&
				(this.eventId = (
					await firstValueFrom(
						this.store.select(getCurrentEvent).pipe(
							skipWhile((event) => !event),
							take(1)
						)
					)
				)?.uid);
			if (
				this.user &&
				((this.eventId && this.eventId !== null) || (this.previousEventId && this.previousEventId !== null))
			) {
				return update(ref(this.db, `status/${this.user.uid}`), {
					state: status,
					eventId: status === "online" ? this.eventId : this.previousEventId,
					lastChanged: this.timestamp
				});
			}
		} catch (error) {
			//
		}
	}

	get timestamp() {
		return serverTimestamp();
	}

	/**
	 * updateOnUser
	 * @returns
	 */
	updateOnUser() {
		const connection = objectVal(ref(this.db, ".info/connected")).pipe(
			map((connected: boolean) => (connected ? "online" : "offline"))
		);

		return authState(this.auth).pipe(
			switchMap((user) => {
				return user ? connection : of("offline");
			}),
			tap((status) => this.setPresence(status))
		);
	}

	/**
	 * updateOnDisconnect
	 * @desc Fires when eventuser closes the browser window, or app crash or internet disconnect
	 * @returns
	 */
	updateOnDisconnect() {
		return authState(this.auth).pipe(
			tap(async (user) => {
				this.eventId = (
					await firstValueFrom(
						this.store.select(getCurrentEvent).pipe(
							skipWhile((event) => !event),
							take(1)
						)
					)
				)?.uid;
				if (user && this.eventId) {
					onDisconnect(ref(this.db, `status/${user.uid}`)).update({
						state: "offline",
						eventId: this.eventId,
						lastChanged: serverTimestamp()
					});
				}
			})
		);
	}
}
