import { createAction, props } from "@ngrx/store";
import { IModule } from "../interfaces/modules.interfaces";

export enum ModulesActionsTypes {
	GetAllModules = "[Modules Service] Get all modules of event",
	ResetModulesState = "[Modules Service] Reset modules state"
}

export const GetAllModules = createAction(
	ModulesActionsTypes.GetAllModules,
	props<{ payload: IModule[]; eventId: string }>()
);

export const ResetModulesState = createAction(ModulesActionsTypes.ResetModulesState, props<any>());
