import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import Player from "@vimeo/player";
import { Subscription } from "rxjs";
import {
	UpdateMiniVideoPlayer,
	UpdateMiniVideoPlayerActivated,
	UpdateMiniVideoPlayerState,
	UpdateMiniVideoPlayerTime
} from "src/app/shared/actions/video-player.actions";
import { IEvent } from "src/app/shared/interfaces";
import { IVideoPlayerSettings } from "src/app/shared/interfaces/video-player.interfaces";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	checkSamePage,
	getBaseMiniVideoPlayer,
	getMiniVideoPlayerState
} from "src/app/shared/selectors/video-player.selectors";

@Component({
	selector: "app-mini-video-player",
	templateUrl: "./mini-video-player.component.html",
	styleUrls: ["./mini-video-player.component.scss"]
})
export class MiniVideoPlayerComponent implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];
	samePageSub: Subscription;
	@ViewChild("videoPlayer", { static: false }) videoPlayer: any;
	src: string;
	type: string;
	start: number = 0;
	width: any = 640;
	height: any = 360;

	event: IEvent;

	vimeoPlayer: Player;

	/**
	 * When true, the player is hidden (css)
	 * avoiding performance issues when dragging
	 */
	moving: boolean = false;
	init: boolean = false;
	videoPlayerState: IVideoPlayerSettings;
	youtubeState: number = -1;

	youtubePlayerReady: boolean = false;

	constructor(private store: Store, private platform: Platform) {}

	ngOnInit() {
		this.getEvent();

		this.subscriptions.push(
			this.store.select(getBaseMiniVideoPlayer).subscribe((miniVideoPlayerState) => {
				if (miniVideoPlayerState && miniVideoPlayerState.src && miniVideoPlayerState.type) {
					this.videoPlayerState = miniVideoPlayerState;
					this.type = miniVideoPlayerState.type ? miniVideoPlayerState.type : "";
					// this.src = miniVideoPlayerState.src ? miniVideoPlayerState.src : "";
					this.src = miniVideoPlayerState.src
						? this.type === "youtube"
							? miniVideoPlayerState.src.split("embed/")[1]?.split('"')?.[0]
							: this.type === "vimeo"
							? miniVideoPlayerState.src.split("https://player.vimeo.com/video/")[1]?.split("?")?.[0]
							: miniVideoPlayerState.src.split("src=")[1]?.split('"')?.[0]
						: "";
					this.start = miniVideoPlayerState.start ? miniVideoPlayerState.start : 0;

					// On youtube player seek and play video on update time or state if start > 0 (so already played)
					if (this.type === "youtube" && this.youtubePlayerReady && this.start && this.start > 0) {
						this.seekToYoutubeVideo(this.start, true);
						if (this.youtubeState === 1) {
							this.playYoutubeVideo();
						} else if (this.youtubeState === 2 || this.youtubeState === -1) {
							this.pauseWithoutUpdateYoutubeVideo();
						}
					}

					if (this.type == "vimeo" && !this.init) {
						if (!this.vimeoPlayer) {
							setTimeout(() => {
								this.initVimeoVideo();
							}, 200);
						} else {
							this.vimeoPlayer.setCurrentTime(this.start);
						}
					}

					if (this.samePageSub && !this.samePageSub.closed) {
						this.samePageSub.unsubscribe();
					}

					this.samePageSub = this.store
						.select(checkSamePage(this.videoPlayerState.link.itemId, "mini"))
						.subscribe((same) => {
							if (same) {
								this.destroyPlayer();
							}
						});
					this.init = true;
				} else {
					this.destroyPlayer();
				}
			})
		);

		// Get state of youtube player
		this.subscriptions.push(
			this.store.select(getMiniVideoPlayerState).subscribe((state) => {
				if (this.type && this.type === "youtube") {
					this.youtubeState = state;
				}
			})
		);
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
	}

	/**
	 * Get current event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				this.event = event;
			})
		);
	}

	/**
	 *
	 * Youtube part
	 *
	 */

	/**
	 * On ready youtube video player
	 * @param evt
	 */
	onReady(evt) {
		if (evt.target.playerInfo.playerState === 5 || evt.target.playerInfo.playerState === -1) {
			this.youtubePlayerReady = true;

			// Pause video directly on ready / no auto play
			this.pauseWithoutUpdateYoutubeVideo();

			if (this.start > 0) {
				this.seekToYoutubeVideo(this.start, true);
			}
		}
	}

	/**
	 * On state change of youtube video player
	 * @param evt
	 */
	stateChange(evt) {
		// -1 = unstarted, 1 = playing, 2 = Paused, 3 = buffering, 5 = cued
		if (evt.data === -1 && this.youtubePlayerReady) {
			this.seekToYoutubeVideo(this.start, true);

			if (this.youtubeState === 2) {
				setTimeout(() => {
					this.pauseWithoutUpdateYoutubeVideo();
				}, 200);
			}
		}

		if (evt.data === 1 || evt.data === 2) {
			this.store.dispatch(UpdateMiniVideoPlayerState({ payload: evt.data }));
		}
	}

	onError() {
		this.youtubePlayerReady = false;
	}

	/**
	 * Play youtube video
	 */
	playYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.playVideo();
		}
	}

	/**
	 * Pause youtube video and update time
	 */
	pauseYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.pauseVideo();
			this.updateCurrentTimeYoutubeVideo();
		}
	}

	/**
	 * Pause youtube video
	 */
	pauseWithoutUpdateYoutubeVideo() {
		this.videoPlayer.pauseVideo();
	}

	/**
	 * Stop and unload video
	 */
	stopYoutubeVideo() {
		if (this.youtubePlayerReady) {
			this.videoPlayer.stopVideo();
		}
	}

	/**
	 * Go to number of seconds specified of the video
	 * @param seconds
	 * @param allowSeekAhead
	 */
	seekToYoutubeVideo(seconds: number, allowSeekAhead: boolean) {
		if (this.youtubePlayerReady) {
			this.videoPlayer.seekTo(seconds, allowSeekAhead);
		}
	}

	/**
	 * Get current time of youtube video
	 */
	getCurrentTimeYoutubeVideo() {
		if (this.youtubePlayerReady) {
			return this.videoPlayer.getCurrentTime();
		}
	}

	/**
	 * Update current time of a youtube video on store
	 */
	updateCurrentTimeYoutubeVideo() {
		if (this.youtubePlayerReady) {
			const time = this.videoPlayer.getCurrentTime();
			this.store.dispatch(UpdateMiniVideoPlayerTime({ payload: time }));
		}
	}

	/**
	 * Destroy player
	 */
	async destroyPlayer() {
		if (this.samePageSub && !this.samePageSub.closed) {
			this.samePageSub.unsubscribe();
		}
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		let time: number = 0;
		if (this.videoPlayerState.type === "youtube" && this.youtubePlayerReady) {
			time = this.videoPlayer.getCurrentTime();
		} else if (this.videoPlayerState.type === "vimeo" && this.vimeoPlayer) {
			time = await this.vimeoPlayer.getCurrentTime();
		} else {
			time = this.videoPlayer.nativeElement.currentTime;
		}

		// Update mini video player time on destroy player
		if (time > 0) {
			this.store.dispatch(UpdateMiniVideoPlayerTime({ payload: time }));
		}

		this.src = "";
		this.type = "";
		this.start = 0;
		if (this.vimeoPlayer) {
			this.vimeoPlayer.destroy();
			this.vimeoPlayer = null;
		}

		this.init = false;
		this.store.dispatch(UpdateMiniVideoPlayerActivated({ payload: false }));
	}

	/**
	 *
	 * Vimeo part
	 *
	 */

	/**
	 * Init a vimeo video
	 */
	initVimeoVideo() {
		if (this.src.includes("https")) {
			this.vimeoPlayer = new Player(this.videoPlayer.nativeElement, {
				width: this.getWidth(),
				height: this.getHeight(),
				url: this.src
			});
		} else {
			this.vimeoPlayer = new Player(this.videoPlayer.nativeElement, {
				width: this.getWidth(),
				height: this.getHeight(),
				id: this.src
			});
		}

		this.vimeoPlayer.setCurrentTime(this.start);
		if (this.start > 0) {
			this.vimeoPlayer.play();
		}

		this.vimeoPlayer.on("timeupdate", (currentTime) => {
			this.store.dispatch(UpdateMiniVideoPlayerTime({ payload: Math.round(currentTime.seconds) }));
		});
	}

	/**
	 * Get width of mini video player
	 * @returns
	 */
	getWidth() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			return this.platform.width();
		} else {
			return 400;
		}
	}

	/**
	 * Get height of mini video player
	 * @returns
	 */
	getHeight() {
		if (this.platform.is("ios") || this.platform.is("android")) {
			return this.platform.height() * 0.4;
		} else {
			return 225;
		}
	}

	/**
	 * If true, the iframe is displayed.
	 * Otherwise, the <video></video>
	 */
	isYoutubeOrVimeo() {
		// youtu because can be youtube or youtu.be
		return ["youtu", "vimeo"].some((p) => (this.src || "").includes(p));
	}

	onCloseClick() {
		this.store.dispatch(
			UpdateMiniVideoPlayer({
				payload: {
					activated: false,
					link: {
						moduleType: null,
						moduleId: null,
						itemId: null
					},
					options: {
						state: null
					},
					src: null,
					start: null,
					type: null
				}
			})
		);
	}
}
