import { createReducer, on } from "@ngrx/store";
import * as EventsActions from "../actions/events.actions";
import { IEventsState } from "../interfaces/events.interfaces";

export const initialState: IEventsState = {
	events: [],
	myEvents: [],
	currentEvent: null
};

export const eventsReducers = createReducer(
	initialState,
	on(EventsActions.GetEventsState, (state, action) => ({
		...state,
		events: action.payload.events,
		myEvents: action.payload.myEvents,
		currentEvent: action.payload.currentEvent
	})),
	on(EventsActions.GetAllEvents, (state, action) => ({
		...state,
		events: action.payload
	})),
	on(EventsActions.GetEvent, (state, action) => ({
		...state,
		currentEvent: action.payload
	})),
	on(EventsActions.GetMyEvents, (state, action) => ({
		...state,
		myEvents: action.payload
	})),
	on(EventsActions.ResetEventsState, () => ({
		...initialState
	}))
);
