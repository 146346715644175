<app-visio *ngIf="showVisioWebApp" [type]="'chat'" [joined]="true" [showJoinBtn]="false" [event]="event" [visio]="visio"
	[height]="'100%'" (closeVisioEvent)="onCloseVisio()" #visioConference>
</app-visio>

<div *ngIf="event && module && eventUser && appointment" class="background">
	<ion-fab
		*ngIf="module.options.allowIcsDownload && (appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED || appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING || appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ENDED)"
		(click)="openDownloadModal(appointment)" slot="fixed" horizontal="right" vertical="bottom"
		style="right: 15px; bottom: 15px">
		<ion-fab-button class="basic-fab-button" style="width: 50px; height: 50px">
			<ion-icon [color]="event.styling.btnTextColor" src="assets/images/add-on-calendar.svg"
				style="font-size: 26px"></ion-icon>
		</ion-fab-button>
	</ion-fab>

	<div [style.background-color]="event.styling.menuColor" style="
			width: 100%;
			padding: 5px 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
		">
		<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
			<ion-icon [style.color]="event.styling.menuTextColor" style="font-size: 12px; margin-right: 10px"
				name="calendar-clear-outline"></ion-icon>
			<span [style.color]="event.styling.menuTextColor" style="font-size: 12px; font-weight: 600">
				{{ getPartOfDate(appointment.startDate, "full") | titlecase }}
			</span>
		</div>
		<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
			<ion-icon [style.color]="event.styling.menuTextColor" style="font-size: 12px; margin-right: 10px"
				name="time-outline"></ion-icon>
			<span [style.color]="event.styling.menuTextColor" style="font-size: 12px; font-weight: 600">{{
				getPartOfDate(appointment.startDate, "time") | titlecase }} - {{
				getPartOfDate(getEndScheduleDate(appointment.startDate, appointment.duration), "time") | titlecase }}
			</span>
		</div>
		<div *ngIf="appointment.location && getLocation(appointment.location)"
			style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
			<ion-icon [style.color]="event.styling.menuTextColor" style="font-size: 12px; margin-right: 10px"
				name="location-outline"></ion-icon>
			<span [style.color]="event.styling.menuTextColor" style="font-size: 12px; font-weight: 600">{{
				getLocation(appointment.location).name }}
			</span>
		</div>
	</div>

	<div *ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && !appointmentIsEnded(appointment) && checkVisioAllowed(appointment)"
		[style.background-color]="event.styling.btnBgColor" style="
			width: 100%;
			padding: 5px 10px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: space-between;
			cursor: pointer;
		" (click)="joinVisio(appointment)">
		<div style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
			<ion-icon [style.color]="event.styling.btnTextColor" style="font-size: 12px; margin-right: 10px"
				name="videocam-outline"></ion-icon>
			<span [style.color]="event.styling.btnTextColor" style="font-size: 12px; font-weight: 600">
				{{ "texts.join-visio" | translate }}
			</span>
		</div>
		<ion-icon [style.color]="event.styling.btnTextColor" style="font-size: 18px"
			name="chevron-forward-outline"></ion-icon>
	</div>

	<div style="
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			padding: 10px;
			cursor: pointer;
		">
		<div *ngIf="appointment && ((appointment.recipientDatas && appointment.recipientDatas.uid !== eventUser.uid) || (appointment.applicantDatas && appointment.applicantDatas.uid !== eventUser.uid))"
			(click)="openProfile(appointment.recipientDatas.uid !== eventUser.uid ? appointment.recipientDatas : appointment.applicantDatas)"
			style="
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: flex-start;
				position: relative;
				padding-bottom: 10px;
				border-bottom: 1px solid rgba(0, 0, 0, 0.1);
			">
			<img [src]="
            appointment.recipientDatas && appointment.recipientDatas.uid !== eventUser.uid && appointment.recipientDatas.photoUrl && appointment.recipientDatas.photoUrl !== ''
                ? appointment.recipientDatas.photoUrl
                : appointment.applicantDatas && appointment.applicantDatas.uid !== eventUser.uid && appointment.applicantDatas.photoUrl && appointment.applicantDatas.photoUrl !== ''
                ? appointment.applicantDatas.photoUrl
                : 'assets/icon/User2.svg'
        " class="profile-picture" [style.border-color]="isMobile ? '#fff' : event.styling.menuTextColorGradient" />
			<div style="
					width: 100%;
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					justify-content: center;
					line-height: normal;
				">
				<span style="font-size: 18px" [style.color]="event.styling.titleColor">{{ appointment.recipientDatas.uid
					!== eventUser.uid ? appointment.recipientDatas.name :
					appointment.applicantDatas.name }}</span>
				<app-custom-fields-tags [event]="event" [module]="module"
					[modulesCustomsFields]="filterModulesCustomFields(appointment.recipientDatas)" [eventUserProfile]="appointment.recipientDatas.uid !== eventUser.uid ? appointment.recipientDatas :
							appointment.applicantDatas" [customFields]="buildCustomFieldsForEventUser(appointment.recipientDatas.uid !== eventUser.uid ? appointment.recipientDatas :
							appointment.applicantDatas)" [fontSize]="12"
					[isPrivateMode]="eventUser && ((appointment.recipientDatas && eventUser.uid === appointment.recipientDatas.uid) || (appointment.applicantDatas && eventUser.uid === appointment.applicantDatas.uid)) ? true : false"
					[currentLanguage]="currentLanguage"></app-custom-fields-tags>
				<div *ngIf="appointment.location && getLocation(appointment.location)"
					style="display: flex; flex-direction: row; align-items: center; justify-content: flex-start">
					<ion-icon [style.color]="event.styling.contentTextColor" style="font-size: 12px; margin-right: 5px"
						name="location-outline"></ion-icon>
					<span [style.color]="event.styling.contentTextColor" style="font-size: 12px; font-weight: 600">
						{{ getLocation(appointment.location).name }}
					</span>
				</div>
			</div>

			<ion-icon [style.color]="event.styling.contentTextColor"
				style="font-size: 18px; position: absolute; bottom: calc(50% -18px); right: 0; cursor: pointer"
				name="chevron-forward-outline"></ion-icon>
		</div>

		<div style="
				width: 100%;
				padding: 20px 0;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
			">
			<div *ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && !appointmentIsEnded(appointment)"
				[style.background-color]="'#E9FFE1'" [style.border-color]="'#E9FFE1'" [style.color]="'#25EB2D'" style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					text-align: center;
					border: 1px solid;
					font-size: 12px;
					font-weight: 700;
					border-radius: 28px;
					padding: 5px 10px;
					margin: 0 10px 0 10px;
				">
				{{ "appointments.app_status.accepted" | translate }}
			</div>

			<div *ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
				[style.background-color]="'#FFF1E1'" [style.border-color]="'#FFF1E1'" [style.color]="'#EB9C25'" style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					text-align: center;
					border: 1px solid;
					font-size: 12px;
					font-weight: 700;
					border-radius: 28px;
					padding: 5px 10px;
					margin: 0 10px 0 10px;
				">
				{{ "appointments.app_status.waiting" | translate }}
			</div>

			<div *ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_CANCELLED"
				[style.background-color]="'#FFEFEF'" [style.border-color]="'#FFEFEF'" [style.color]="'#EB2525'" style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					text-align: center;
					border: 1px solid;
					font-size: 12px;
					font-weight: 700;
					border-radius: 28px;
					padding: 5px 10px;
					margin: 0 10px 0 10px;
				">
				{{ "appointments.app_status.cancelled" | translate }}
			</div>

			<div *ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_REJECTED"
				[style.background-color]="'#FFEFEF'" [style.border-color]="'#FFEFEF'" [style.color]="'#EB2525'" style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					text-align: center;
					border: 1px solid;
					font-size: 12px;
					font-weight: 700;
					border-radius: 28px;
					padding: 5px 10px;
					margin: 0 10px 0 10px;
				">
				{{ "appointments.app_status.rejected" | translate }}
			</div>

			<div *ngIf="appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && appointmentIsEnded(appointment)"
				[style.background-color]="'#FFEFEF'" [style.border-color]="'#FFEFEF'" [style.color]="'#EB2525'" style="
					display: flex;
					flex-direction: row;
					align-items: center;
					justify-content: center;
					text-align: center;
					border: 1px solid;
					font-size: 12px;
					font-weight: 700;
					border-radius: 28px;
					padding: 5px 10px;
					margin: 0 10px 0 10px;
				">
				{{ "appointments.app_status.ended" | translate }}
			</div>
		</div>

		<div style="
				width: 100%;
				padding: 10px;
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				justify-content: flex-start;
				border-radius: 7px;
				border: 1px solid rgba(0, 0, 0, 0.1);
			">
			<div [style.color]="event.styling.contentTextColor" style="font-size: 12px; font-weight: 700">
				{{ "appointments.subject" | translate }}
			</div>

			<div [style.color]="event.styling.contentTextColor" style="font-size: 12px; font-weight: 300">
				{{ appointment.appointmentInformations.subject }}
			</div>

			<div [style.color]="event.styling.contentTextColor" style="font-size: 12px; font-weight: 700">
				{{ "appointments.aditionnal-infos" | translate }}
			</div>

			<div [style.color]="event.styling.contentTextColor" style="font-size: 12px; font-weight: 300">
				{{ appointment.appointmentInformations.informations }}
			</div>
		</div>

		<div style="
				display: flex;
				flex-direction: column;
				align-items: center;
				justify-content: center;
				width: 100%;
				margin-top: 50px;
			">
			<div *ngIf="appointment.recipient && appointment.recipient.uid === eventUser.uid && appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING && getLocationsOfRule(appointment).length > 0"
				style="width: 100%; margin-bottom: 10px">
				<ion-select mode="md" interface="action-sheet" [(ngModel)]="appointment.location"
					[style.--padding-bottom]="'5px'" [style.--padding-end]="'5px'" [style.--padding-start]="'5px'"
					[style.--padding-top]="'5px'"
					style="width: 100%; border: 0.5px solid rgba(0, 0, 0, 0.2); border-radius: 3px" mode="md"
					[style.--placeholder-color]="event.styling.contentTextColor" [style.--placeholder-opacity]="1"
					placeholder="{{ 'appointments.select-location' | translate }}" type="text">
					<ion-select-option value="none"> {{ "buttons.nonees" | translate }} </ion-select-option>
					<ion-select-option *ngFor="let location of getLocationsOfRule(appointment);"
						value="{{ location.uid }}">
						{{ location.name }}
					</ion-select-option>
				</ion-select>
			</div>
			<div style="display: flex; flex-direction: row; align-items: center; justify-content: center; width: 100%">
				<div *ngIf="appointment.recipient && appointment.recipient.uid === eventUser.uid && appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
					[style.pointer-events]="appointment.location ? 'all' : 'none'"
					[style.opacity]="appointment.location ? 1 : 0.3" (click)="acceptAppointment(appointment)"
					[style.background-color]="'#4CB169'" [style.border-color]="'#4CB169'" [style.color]="'white'" style="
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						text-align: center;
						border: 1px solid;
						font-size: 12px;
						font-weight: 700;
						border-radius: 28px;
						padding: 5px 10px;
						margin: 0 10px 0 10px;
						cursor: pointer;
					">
					{{ "appointments.buttons.accept" | translate }}
				</div>
				<div *ngIf="appointment.recipient && appointment.recipient.uid === eventUser.uid && appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING"
					(click)="refuseAppointment(appointment)" [style.background-color]="'#D6242A'"
					[style.border-color]="'#D6242A'" [style.color]="'white'" style="
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						text-align: center;
						border: 1px solid;
						font-size: 12px;
						font-weight: 700;
						border-radius: 28px;
						padding: 5px 10px;
						margin: 0 10px 0 10px;
						cursor: pointer;
					">
					{{ "appointments.buttons.refuse" | translate }}
				</div>
				<div *ngIf="(appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_PENDING && appointment.applicant.uid === eventUser.uid) || (appointment.status === AppointmentTimeSlotStatus.APPOINTMENT_ACCEPTED && !appointmentIsEnded(appointment))"
					(click)="cancelAppointment(appointment)" [style.background-color]="'#D6242A'"
					[style.border-color]="'#D6242A'" [style.color]="'white'" style="
						display: flex;
						flex-direction: row;
						align-items: center;
						justify-content: center;
						text-align: center;
						border: 1px solid;
						font-size: 12px;
						font-weight: 700;
						border-radius: 28px;
						padding: 5px 10px;
						margin: 0 10px 0 10px;
						cursor: pointer;
					">
					{{ "appointments.buttons.cancel" | translate }}
				</div>
			</div>
		</div>
	</div>
</div>