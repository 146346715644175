import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpResponse } from "@angular/common/http";
import { DateTime } from "luxon";
import { environment } from "../../../environments/environment";
import { switchMap } from "rxjs/operators";
import { IVisio } from "../interfaces";
import { firstValueFrom, Subject } from "rxjs";
import { Browser } from "@capacitor/browser";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class VisiosService {
	headers: HttpHeaders;
	baseApiUrl: string = environment.platform.apiBaseUrl;
	createVisioSubject: Subject<boolean> = new Subject();

	constructor(private https: HttpClient, private SFirestore: FirestoreService) {
		this.headers = new HttpHeaders({
			"Content-Type": "application/json"
		});
	}

	/**
	 * Create a meeting on whereby
	 * @param meetingData
	 */
	createMeeting(meetingData: any): Promise<HttpResponse<any>> {
		return firstValueFrom(
			this.https.post(
				environment.platform.apiV2BaseUrl
					? "https://visios-createWherebyMeeting" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "visios-createWherebyMeeting",
				meetingData,
				{
					headers: this.headers,
					observe: "response"
				}
			)
		);
	}

	/**
	 * Delete a meeting on whereby
	 * @param meetingId
	 */
	deleteMeeting(meetingId: string) {
		return firstValueFrom(
			this.https.post(
				environment.platform.apiV2BaseUrl
					? "https://visios-deleteWherebyMeeting" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "visios-deleteWherebyMeeting",
				{
					meetingId: meetingId
				},
				{
					headers: this.headers,
					observe: "response"
				}
			)
		);
	}

	/**
	 * Get specified meeting
	 * @param meetingId
	 */
	getMeeting(meetingId: string) {
		return firstValueFrom(
			this.https.post(
				environment.platform.apiV2BaseUrl
					? "https://visios-getWherebyMeeting" + environment.platform.apiV2BaseUrl
					: environment.platform.apiBaseUrl + "visios-getWherebyMeeting",
				{
					meetingId: meetingId
				},
				{
					headers: this.headers,
					observe: "response"
				}
			)
		);
	}

	/**
	 * Get chat visio
	 * @param eventId
	 * @param chatId
	 * @returns
	 */
	getChatVisio(eventId: string, moduleId: string, chatId: string) {
		return this.SFirestore.valueChangesDocument(`events/${eventId}/modules/${moduleId}/visios/${chatId}`);
	}

	/**
	 * Create a new visio room for 2
	 * @param eventId
	 * @param chatId
	 * @param ids
	 */
	createNewVisioFor2(eventId: string, moduleId: string, chatId: string, ids: string[]) {
		return this.SFirestore.getDocumentObs(`events/${eventId}/modules/${moduleId}/visios/${chatId}`).pipe(
			switchMap(async (visioDoc) => {
				const visioData = visioDoc.data() as IVisio;
				if (
					!visioData ||
					(visioData && visioData.options.meetingId && visioData.endDate <= DateTime.local().toISO())
				) {
					// If visio expired delete it
					if (visioData && visioData.options.meetingId && visioData.endDate <= DateTime.local().toISO()) {
						await this.deleteMeeting(visioData.options.meetingId);
						await this.SFirestore.deleteDocument(visioDoc.ref.path);
					}

					// Create new visio on whereby and on firestore
					const visio: IVisio = {
						uid: chatId,
						endDate: DateTime.local().plus({ days: 2 }).toISO(),
						eventId: eventId,
						moduleId: moduleId,
						members: ids,
						options: {
							allowChat: true,
							allowLeaveBtn: true,
							allowScreenshare: true,
							hostGroups: [],
							hostRoomUrl: "",
							isLocked: false,
							meetingId: "",
							roomMode: "normal",
							roomPrefix: "/" + chatId
						},
						startDate: DateTime.local().toISO(),
						type: "whereby",
						url: ""
					};

					const response = await this.createMeeting({
						isLocked: visio.options.isLocked,
						roomNamePrefix: visio.options.roomPrefix,
						roomMode: visio.options.roomMode,
						endDate: visio.endDate
					});

					visio.options.meetingId = response.body.meetingId;
					visio.url = response.body.roomUrl;
					await this.SFirestore.setDocument(`events/${eventId}/modules/${moduleId}/visios/${chatId}`, visio);
					return visio;
				} else {
					return visioData;
				}
			})
		);
	}

	/**
	 * Open in app browser visio
	 * @param url
	 * @returns
	 */
	openInAppBrowserVisio(url: string) {
		return Browser.open({ url: url });
	}

	/**
	 * Open visio on desktop
	 * @param url
	 */
	openDesktopVisio(url: string): void {
		window.open(url, "_system");
	}
}
