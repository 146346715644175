<ion-header>
    <ion-toolbar class="ion-color" [style.background]="event.styling.menuColor"
        [style.color]="event.styling.menuTextColor">
        <ion-buttons slot="start">
            <ion-button slot="icon-only" *ngIf="showAll || (!showAll && type)" (click)="close()">
                <ion-icon src="assets/icon/Close.svg"></ion-icon>
            </ion-button>
            <ion-button slot="icon-only" *ngIf="!showAll && !type" (click)="goBack()">
                <ion-icon src="assets/icon/Arrow.svg"></ion-icon>
            </ion-button>
        </ion-buttons>
        <ion-title>
            <span *ngIf="showAll">{{ event.legalButtonName && event.legalButtonName[currentLanguage] ?
                event.legalButtonName[currentLanguage]
                : ("labels.legal_mentions" | translate) }}</span>
            <span *ngIf="showTerms">
                {{ event.termsOfUse.name[currentLanguage] ? event.termsOfUse.name[currentLanguage] :
                event.termsOfUse.name[event.language] }}
            </span>
            <span *ngIf="showPrivacy">
                {{ event.privacy.name[currentLanguage] ? event.privacy.name[currentLanguage] :
                event.privacy.name[event.language] }}
            </span>
            <span *ngIf="showCookiePolicy">
                {{ event.cookiePolicy.name[currentLanguage] ? event.cookiePolicy.name[currentLanguage] :
                event.cookiePolicy.name[event.language] }}
            </span>
        </ion-title>
    </ion-toolbar>
</ion-header>

<ion-content class="hide-scrollbar" style="--background: var(--bg-color-content);">
    <div class="background height-100" *ngIf="showAll">
        <ion-item detail lines="none" class="ion-color" *ngIf="event.termsOfUse.showLegal"
            (click)="showRightBlock('terms-of-use')">
            <ion-label class="ion-color" [style.color]="event.styling.contentTextColor">
                <h2>{{ event.termsOfUse.name[currentLanguage] ? event.termsOfUse.name[currentLanguage] :
                    event.termsOfUse.name[event.language] }}</h2>
            </ion-label>
        </ion-item>
        <ion-item detail lines="none" class="ion-color" *ngIf="event.privacy.showLegal"
            (click)="showRightBlock('privacy')">
            <ion-label class="ion-color" [style.color]="event.styling.contentTextColor">
                <h2>{{ event.privacy.name[currentLanguage] ? event.privacy.name[currentLanguage] :
                    event.privacy.name[event.language] }}</h2>
            </ion-label>
        </ion-item>
        <ion-item detail lines="none" class="ion-color" *ngIf="event.cookiePolicy.showLegal"
            (click)="showRightBlock('cookiePolicy')">
            <ion-label class="ion-color" [style.color]="event.styling.contentTextColor">
                <h2>{{ event.cookiePolicy.name[currentLanguage] ? event.cookiePolicy.name[currentLanguage] :
                    event.cookiePolicy.name[event.language] }}</h2>
            </ion-label>
        </ion-item>
    </div>
    <div class="background padding-sides-15 terms" *ngIf="showTerms" padding>
        <p
            [innerHTML]="(event.termsOfUse.content[currentLanguage] ? event.termsOfUse.content[currentLanguage] : event.termsOfUse.content) | safeHtml">
        </p>
    </div>
    <div class="background padding-sides-15 terms" *ngIf="showPrivacy" padding>
        <p
            [innerHTML]="(event.privacy.content[currentLanguage] ? event.privacy.content[currentLanguage] : event.privacy.content)  | safeHtml">
        </p>
    </div>
    <div class="background padding-sides-15 terms" *ngIf="showCookiePolicy" padding>
        <p
            [innerHTML]="(event.cookiePolicy.content[currentLanguage] ? event.cookiePolicy.content[currentLanguage] : event.cookiePolicy.content)  | safeHtml">
        </p>
    </div>
</ion-content>