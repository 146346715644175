import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { GetAllExternalsInteractivity } from "../actions/interactivity.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IExternalInteractivity } from "../interfaces/external-interactivity.interfaces";
import { checkSameEvent } from "../selectors/interactivity.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class ExternalInteractivityService {
	externalInteractivitySub: Subscription;

	constructor(private SFirestore: FirestoreService, private store: Store) {}

	/**
	 * Get all ask questions of event
	 * @param eventId
	 */
	getExternalInteractivityOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "externalInteractivity", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.externalInteractivitySub && !this.externalInteractivitySub.closed) {
					return;
				} else if (!sameEvent && this.externalInteractivitySub && !this.externalInteractivitySub.closed) {
					this.externalInteractivitySub.unsubscribe();
				}

				this.externalInteractivitySub = this.SFirestore.collectionGroupValueChangesDocuments(
					"externals-interactivity",
					[where("eventId", "==", eventId)]
				).subscribe((externalsInteractivity: IExternalInteractivity[]) => {
					this.store.dispatch(
						GetAllExternalsInteractivity({ payload: externalsInteractivity, eventId: eventId })
					);

					this.store
						.select(getInitSpecificEventDatasPart("initExternalsInteractivity"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initExternalsInteractivity", payload: true })
								);
							}
						});
				});
			});
	}

	unsubscribeAll() {
		if (this.externalInteractivitySub && !this.externalInteractivitySub.closed) {
			this.externalInteractivitySub.unsubscribe();
		}
	}
}
