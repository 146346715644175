export enum TypeGallery {
	IMAGE = 1,
	VIDEO = 2
}

export enum TypeVideoSource {
	YOUTUBE = 1,
	VIMEO = 2,
	DAILYMOTION = 3,
	FACEBOOK = 4,
	OTHER = 5
}

export enum TypeVideoSourceString {
	"Youtube" = 1,
	"Vimeo" = 2,
	"Dailymotion" = 3,
	"Facebook" = 4,
	"Other" = 5
}
