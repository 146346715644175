import { createAction, props } from "@ngrx/store";
import { IRanking } from "../interfaces/ranking.interfaces";

export enum RankingsActionsTypes {
	GetAllRankings = "[Rankings Service] Get all rankings",
	ResetRankingsState = "[Rankings Service] Reset rankings state"
}

export const GetAllRankings = createAction(
	RankingsActionsTypes.GetAllRankings,
	props<{ payload: IRanking[]; eventId: string }>()
);

export const ResetRankingsState = createAction(RankingsActionsTypes.ResetRankingsState, props<any>());
