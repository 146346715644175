import { Injectable } from "@angular/core";
import { Firestore, increment, runTransaction, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Observable, Subscription, combineLatest, from, of } from "rxjs";
import { map, switchMap, take } from "rxjs/operators";
import { GetAllAskQuestions } from "../actions/interactivity.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IEventUser } from "../interfaces";
import { IAskQuestions, IAskQuestionsResult, IAskQuestionsResultVote } from "../interfaces/ask-questions.interfaces";
import { getCurrentEventUser } from "../selectors/auth.selectors";
import { checkSameEvent } from "../selectors/interactivity.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class AskQuestionsService {
	askQuestionsSub: Subscription;
	requiredDatasSub: Subscription;
	currentEventUserId: string = "";

	constructor(private firestore: Firestore, private SFirestore: FirestoreService, private store: Store) {}

	/**
	 * Get all ask questions of event
	 * @param eventId
	 */
	getAskQuestionsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent({ key: "asksQuestions", uid: eventId }))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.askQuestionsSub && !this.askQuestionsSub.closed) {
					return;
				} else if (!sameEvent && this.askQuestionsSub && !this.askQuestionsSub.closed) {
					this.askQuestionsSub.unsubscribe();
				}

				this.requiredDatasSub = this.store.select(getCurrentEventUser).subscribe((eventUser) => {
					if (eventUser) {
						this.currentEventUserId = eventUser.uid;
						this.askQuestionsSub = this.SFirestore.collectionGroupValueChangesDocuments("asks-questions", [
							where("eventId", "==", eventId)
						]).subscribe({
							next: (asksQuestions: IAskQuestions[]) => {
								this.store.dispatch(GetAllAskQuestions({ payload: asksQuestions, eventId: eventId }));

								this.store
									.select(getInitSpecificEventDatasPart("initAsksQuestions"))
									.pipe(take(1))
									.subscribe((init) => {
										if (!init) {
											this.store.dispatch(
												InitSpecificEventDatasPart({ part: "initAsksQuestions", payload: true })
											);
										}
									});
							}
						});
					} else {
						this.store
							.select(getInitSpecificEventDatasPart("initAsksQuestions"))
							.pipe(take(1))
							.subscribe((init) => {
								if (!init) {
									this.store.dispatch(
										InitSpecificEventDatasPart({ part: "initAsksQuestions", payload: true })
									);
								}
							});
					}
				});
			});
	}

	unsubscribeAll() {
		if (this.askQuestionsSub && !this.askQuestionsSub.closed) {
			this.askQuestionsSub.unsubscribe();
		}

		if (this.requiredDatasSub && !this.requiredDatasSub.closed) {
			this.requiredDatasSub.unsubscribe();
		}
	}

	/**
	 * Get ask questions results
	 * @param eventId
	 * @param moduleId
	 * @param askQuestionsId
	 * @returns
	 */
	getAskQuestionsResults(eventId: string, moduleId: string, askQuestionsId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results`,
			[]
		).pipe(
			switchMap((questions) => {
				if (questions.length === 0) {
					return of([]);
				} else {
					const obsArray: Observable<IEventUser>[] = [];
					questions.forEach((question) => {
						obsArray.push(
							from(
								this.SFirestore.getDocumentsCollectionGroup("event-users", [
									where("eventId", "==", eventId),
									where("uid", "==", question.userId)
								])
							).pipe(
								map((docs) => docs.docs.map((doc) => doc.data() as IEventUser)),
								switchMap((eventUsers) => {
									if (eventUsers.length === 0) {
										return of(null);
									} else {
										return of(eventUsers[0]);
									}
								})
							)
						);
					});

					return combineLatest(obsArray).pipe(
						switchMap((eventUsers) => {
							return of(
								questions.map((question) => {
									const eventUser = eventUsers.find(
										(eventUser) => eventUser && question && eventUser.uid === question.userId
									);
									question.userData = eventUser ? eventUser : null;
									return question;
								})
							);
						})
					);
				}
			})
		);
	}

	/**
	 * Create ask questions result
	 * @param eventId
	 * @param moduleId
	 * @param askQuestionsId
	 * @param question
	 * @returns
	 */
	createAskQuestionsResult(eventId: string, moduleId: string, askQuestionsId: string, question: IAskQuestionsResult) {
		question.uid = question.uid
			? question.uid
			: this.SFirestore.createId(
					`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results`
			  );
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${question.uid}`,
			question
		);
	}

	/**
	 * Get ask questions votes for event user
	 * @param eventId
	 * @param moduleId
	 * @param askQuestionsId
	 * @param eventUserId
	 * @returns
	 */
	getAskQuestionsEventUserVotes(
		eventId: string,
		moduleId: string,
		askQuestionsId: string,
		eventUserId: string
	): Observable<IAskQuestionsResultVote[]> {
		return this.SFirestore.collectionGroupValueChangesDocuments("ask-questions-results-votes", [
			where("eventId", "==", eventId),
			where("moduleId", "==", moduleId),
			where("askQuestionsId", "==", askQuestionsId),
			where("userId", "==", eventUserId)
		]);
	}

	/**
	 * Increment or decrement total votes value
	 * @param eventId
	 * @param moduleId
	 * @param askQuestionsId
	 * @param questionId
	 * @param incOrDec True for increment, false for decrement
	 * @returns
	 */
	incOrDecTotalVotesOfQuestion(
		eventId: string,
		moduleId: string,
		askQuestionsId: string,
		questionId: string,
		incOrDec: boolean
	) {
		return runTransaction(this.firestore, (transaction) => {
			const docRef = this.SFirestore.docRef(
				`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${questionId}`
			);
			return transaction
				.get(docRef)
				.then((questionDoc) => {
					if (!questionDoc.exists()) {
						throw new Error("not-exist");
					}

					transaction.update(docRef, {
						totalVotes: incOrDec ? increment(1) : increment(-1)
					});
				})
				.catch((error) => {
					//console.error("Transaction failed : ", error);
				});
		});
	}

	/**
	 * Create vote for a question
	 * @param eventId
	 * @param moduleId
	 * @param askQuestionsId
	 * @param questionId
	 * @param vote
	 * @returns
	 */
	createVote(
		eventId: string,
		moduleId: string,
		askQuestionsId: string,
		questionId: string,
		vote: IAskQuestionsResultVote
	) {
		vote.uid = vote.uid
			? vote.uid
			: this.SFirestore.createId(
					`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${questionId}/ask-questions-results-votes`
			  );
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${questionId}/ask-questions-results-votes/${vote.uid}`,
			vote
		);
	}

	/**
	 * Delete vote for a question
	 * @param eventId
	 * @param moduleId
	 * @param askQuestionsId
	 * @param questionId
	 * @param voteId
	 * @returns
	 */
	deleteVote(eventId: string, moduleId: string, askQuestionsId: string, questionId: string, voteId: string) {
		return this.SFirestore.deleteDocument(
			`events/${eventId}/modules/${moduleId}/asks-questions/${askQuestionsId}/ask-questions-results/${questionId}/ask-questions-results-votes/${voteId}`
		);
	}
}
