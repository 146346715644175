import { Component, OnInit, ViewChild } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Browser } from "@capacitor/browser";
import { Directory, Encoding, Filesystem } from "@capacitor/filesystem";
import { IonInfiniteScroll, ModalController, NavController, Platform } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash";
import {
	Observable,
	Subscription,
	combineLatest,
	firstValueFrom,
	from,
	map,
	of,
	skipWhile,
	switchMap,
	take
} from "rxjs";
import { GetHeaderTitle, GetHeaderType, ResetHeaderState } from "src/app/shared/actions/utility.actions";
import { TypeTracking } from "src/app/shared/enums/type-analytics";
import { TypeCustomFields } from "src/app/shared/enums/type-custom-fields";
import { TypeHeader } from "src/app/shared/enums/type-header";
import { TypeModule } from "src/app/shared/enums/type-module";
import {
	ICustomField,
	IDocument,
	IEvent,
	IEventUser,
	IFullCustomField,
	IGroup,
	ILanguage,
	ILocation,
	IModule,
	IModuleCustomField,
	ISchedule,
	ITrack
} from "src/app/shared/interfaces";
import {
	ICustomFieldData,
	IFilterOptions,
	IFilteredItemFormat
} from "src/app/shared/interfaces/custom-fields.interfaces";
import { IScheduleByTrack } from "src/app/shared/interfaces/schedules.interfaces";
import { getCurrentEventUser } from "src/app/shared/selectors/auth.selectors";
import { getDocuments } from "src/app/shared/selectors/documents.selectors";
import { getCurrentEvent } from "src/app/shared/selectors/events.selectors";
import {
	getBaseCustomFields,
	getGroupsByOrder,
	getLocations,
	getModulesCustomFields,
	getSpecificFavoriteFolder,
	getTracks
} from "src/app/shared/selectors/generics-modules-data.selectors";
import { getModulesByTypes, getSpecificModule } from "src/app/shared/selectors/modules.selectors";
import { selectQueryParams, selectRouteNestedParams, selectUrl } from "src/app/shared/selectors/router.selectors";
import {
	AnalyticsService,
	CustomFieldsService,
	DocumentsService,
	EventUsersService,
	FirestoreService,
	SchedulesService,
	UtilityService
} from "src/app/shared/services";
import { environment } from "src/environments/environment";
import { IFavoriteFolder } from "../../../../../../../shared/interfaces/folders.interfaces";
import { ConfirmIcsDownloadComponent } from "../../../modals/confirm-ics-download/confirm-ics-download.component";
import { TimezoneSwitchComponent } from "../../../modals/timezone-switch/timezone-switch.component";
import { FilterComponent } from "../../components/custom-fields/filter/filter.component";
import { IFilter, IFilters } from "src/app/shared/interfaces/filters.interfaces";

@Component({
	selector: "app-schedule-favorites",
	templateUrl: "./schedule-favorites.component.html",
	styleUrls: ["./schedule-favorites.component.scss"]
})
export class ScheduleFavoritesComponent implements OnInit {
	@ViewChild("ionInfiniteScroll", { static: false }) infiniteScroll: IonInfiniteScroll;
	@ViewChild("blocSessions") blocSessions;

	// @ViewChild("datesSwiper", { static: false }) datesSwiper: ElementRef;
	// swiperModules = [IonicSlides];
	// swiperOptions: SwiperOptions = {
	// 	effect: "flip",
	// 	slidesPerView: 6,
	// 	slidesPerGroup: 1,
	// 	updateOnWindowResize: true,
	// 	centerInsufficientSlides: true
	// };

	subscriptions: Subscription[] = [];
	sessionsSub: Subscription;
	eventUserSub: Subscription;
	langSubscription: Subscription;

	typeModule = TypeModule;
	typeCustomFields = TypeCustomFields;

	scheduleLoader = true;
	loader: boolean = true;
	loaderCustomFields: boolean = true;
	loaderLocations: boolean = true;
	loaderTracks: boolean = true;

	eventId: string;
	event: IEvent;
	moduleId: string;
	module: IModule;
	computedCustomFields: IFullCustomField[] = [];
	baseCustomFields: ICustomField[] = [];
	modulesCustomFields: IModuleCustomField[] = [];
	sessions: ISchedule[] = [];
	baseSessions: ISchedule[] = [];
	tmpSessionsLocations: ISchedule[] = [];
	tmpSessionsTracks: ISchedule[] = [];
	tmpSessionsGroups: ISchedule[] = [];
	sessionsFiltered: ISchedule[] = [];
	sessionsByTrackFiltered: IScheduleByTrack[] = [];
	tracks: ITrack[] = [];
	locations: ILocation[] = [];
	locationsIds: string[] = [];
	eventUser: IEventUser;
	dates: string[] = [];
	selectedDate: string = "all";
	filters: IFilters = {
		locations: [],
		tracks: [],
		groups: [],
		customFields: [],
		principalKey: ""
	};
	selectedFilters: IFilteredItemFormat[] = [];
	selectedDatas: IFilterOptions[] = [];
	notSelectedCustomFieldFilters: IFilteredItemFormat[];
	notSelectedLocationDatas: IFilter[];
	notSelectedTrackDatas: IFilter[];
	showFilter = true;
	showSearchBar = true;
	searchBarValue: string = "";
	sessionsPerPage: number = 15; // Min 15
	numberOfSessionsShowed: number = 15; // Min 15
	paginateByTrack: number = 1;
	isMobile: boolean = false;

	eventUsers: IEventUser[] = [];
	documents: IDocument[] = [];
	eventUsersModules: IModule[];
	showNoResultImage: boolean;

	currentLanguage: string = environment.platform.defaultLanguage;
	storedDatas: any = {
		tracks: { value: [] },
		locations: { value: [] },
		baseCustomFields: { value: [] },
		modulesCustomFields: { value: [] },
		computedCustomFields: { value: [] },
		sessions: { value: [] },
		baseSessions: { value: [] },
		filters: { locations: [], tracks: [], groups: [], customFields: [] }
	};
	baseCustomFields_changed: boolean;
	modulesCustomFields_changed: boolean;

	lastSessionId: string = "";
	timezoneType: boolean = false;
	updating: boolean = false;
	onPauseSubscription: Subscription;
	onResumeSubscription: Subscription;
	appStateSub: Subscription;

	groupTrackCollapseState: { uid: string; collapsed: boolean }[] = [];
	filtersCollapseState: { uid: string; collapsed: boolean }[] = [];
	groups: IGroup[] = [];
	groupIds: string[];
	itemsWithHeights: (string | number)[][] = [];
	totalItemFiltered: number;
	sessionLoader: boolean = false;
	icsSessionLoader: { sessionId: string; state: boolean }[] = [];
	scheduleDownloadButtonLoader: boolean = false;

	storedEventUsersUids: string[] = [];
	storedEventUsersDatas: IEventUser[] = [];

	queryParams: any = {};
	eventUsersFromTags: { eventUsers: IEventUser[]; track: ITrack }[] = [];
	folder: IFavoriteFolder;
	folderId: any;
	favoriteModule: IModule;
	eventUserFavoritesState: any = {};
	allSessions: ISchedule[];

	constructor(
		private SEventUsers: EventUsersService,
		private SAnalytics: AnalyticsService,
		private store: Store,
		public SDocument: DocumentsService,
		private SFirestore: FirestoreService,
		private STranslate: TranslateService,
		private navCtrl: NavController,
		private SSchedules: SchedulesService,
		private snackBar: MatSnackBar,
		private SCustomFields: CustomFieldsService,
		private modalController: ModalController,
		public platform: Platform,
		public SUtility: UtilityService
	) {
		platform.ready().then(() => {
			if ((this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb")) {
				this.onResumeSubscription = this.platform.resume.subscribe(() => {
					// Reentering in app
					this.initDatas();
				});

				this.onPauseSubscription = this.platform.pause.subscribe(() => {
					// App goes in background
					this.scheduleLoader = true;
					this.loader = true;
					this.unloadDatas();
				});
			}
		});

		this.subscriptions.push(
			this.store.select(selectQueryParams).subscribe((queryParams) => {
				this.queryParams = queryParams;
			})
		);
	}

	ngOnInit() {
		this.isMobile =
			(this.platform.is("mobile") && window.innerWidth < 768) || this.platform.is("mobileweb") ? true : false;
	}

	ionViewWillEnter() {
		this.scheduleLoader = true;
	}

	ionViewDidEnter() {
		this.currentLanguage = this.STranslate.currentLang;

		this.loader = true;

		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						this.eventId = params.eventId;
						this.moduleId = params.moduleId;
						this.folderId = params.folderId;
						this.subscriptions
							.concat([this.sessionsSub, this.eventUserSub, this.langSubscription])
							.forEach((sub) => sub?.unsubscribe());

						this.langSubscription = this.STranslate.onLangChange.subscribe((lang) => {
							this.currentLanguage = lang.lang;
						});

						// Analytics
						this.SAnalytics.moduleAccess(
							this.eventId,
							this.moduleId,
							TypeTracking.ACCESS_TO_SCHEDULE_MODULE
						);

						this.initDatas();
					});
			});
	}

	initDatas() {
		// Get pagination record
		// this.store
		// 	.select(getSpecificPaginationRecord(this.moduleId))
		// 	.pipe(take(1))
		// 	.subscribe((paginationRecord) => {
		// 		if (paginationRecord) {
		// 			this.lastSessionId = paginationRecord.itemId;
		// 			this.numberOfSessionsShowed =
		// 				paginationRecord.totalItemsLoaded > 15 ? paginationRecord.totalItemsLoaded : 15;
		// 		}
		// 	});
		this.getEvent();
		this.getFavoriteModule();
		this.getEventUser();
		this.getFolder();
		// this.getEventUsers();
		this.getGroups();
		this.buildDatas();
		this.getScheduleSessions();
		// update eventUsers datas on sessions if needed
		// this.buildScheduleSessionsOnCustomFieldsChanges();
	}

	/**
	 * Unsubscribe all subscriptions
	 */
	ngOnDestroy() {
		this.subscriptions
			.concat([
				this.sessionsSub,
				this.eventUserSub,
				this.langSubscription,
				this.onResumeSubscription,
				this.onPauseSubscription
			])
			.forEach((sub) => sub?.unsubscribe());
	}

	ionViewWillLeave() {
		this.store
			.select(selectUrl)
			.pipe(take(1))
			.subscribe(() => {
				this.store
					.select(selectRouteNestedParams)
					.pipe(take(1))
					.subscribe((params) => {
						if (params.moduleId !== this.moduleId) {
							this.unloadDatas();
						}
					});
			});

		// this.loader = true;
		this.store.dispatch(ResetHeaderState(null));
		// this.store
		// 	.select(getPaginationRecords)
		// 	.pipe(take(1))
		// 	.subscribe((paginationRecords) => {
		// 		let paginationRecord = paginationRecords.find((pagRecord) => pagRecord.listModuleId === this.moduleId);
		// 						if (paginationRecord) {
		// 			paginationRecord.itemId = this.getPaginatedSessions()[this.getPaginatedSessions().length - 1].uid;
		// 			paginationRecord.listModuleId = this.moduleId;
		// 			paginationRecord.totalItemsLoaded = this.numberOfSessionsShowed;
		// 		} else {
		// 			paginationRecord = {
		// 				itemId: this.getPaginatedSessions()[this.getPaginatedSessions().length - 1].uid,
		// 				listModuleId: this.moduleId,
		// 				totalItemsLoaded: this.numberOfSessionsShowed
		// 			};
		// 			paginationRecords.push(paginationRecord);
		// 		}
		// 		this.store.dispatch(GetPaginationRecords({ payload: paginationRecords }));
		// 		// this.loader = false;
		// 		// this.init = false;
		// 	});
	}

	getCurrentTimezone() {
		const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
		return timezone ? ` (${timezone})` : "";
	}

	/**
	 * unloadDatas
	 */
	unloadDatas() {
		this.subscriptions.concat([this.sessionsSub, this.eventUserSub]).forEach((sub) => sub?.unsubscribe());

		if (this.sessions.length <= 20) {
			this.sessionsPerPage = this.sessions.length;
			this.numberOfSessionsShowed = this.sessions.length;
		} else {
			this.sessionsPerPage = 15;
			this.numberOfSessionsShowed = 15;
		}
		this.sessions = [];
		// this.storedDatas["baseSessions"]["value"] = [];
		this.sessionsFiltered = [];
	}

	/**
	 * Get event
	 */
	getEvent() {
		this.subscriptions.push(
			this.store.select(getCurrentEvent).subscribe((event) => {
				if (!_.isEqual(this.event, event)) this.event = event;
			})
		);
	}

	/**
	 * Getting getFavoriteModule
	 */
	getFavoriteModule() {
		this.subscriptions.push(
			this.store.select(getSpecificModule(this.moduleId)).subscribe((module) => {
				if (!_.isEqual(this.module, module)) {
					this.favoriteModule = module;
				}

				if (module) {
					this.store.dispatch(GetHeaderTitle({ payload: module.name }));

					if (module.options.activateTracksFiltering && module.options.trackFilterItemsUnfoldDefault)
						this.numberOfSessionsShowed = 20;
				}
			})
		);
	}

	/**
	 * getFolder
	 */
	getFolder() {
		this.subscriptions.push(
			this.store.select(getSpecificFavoriteFolder(this.folderId)).subscribe((folder) => {
				this.folder = folder;

				if (folder) {
					this.subscriptions.push(
						this.store
							.select(getSpecificModule(this.folder.moduleLinkedId))
							.pipe(take(1))
							.subscribe((module) => {
								this.module = module;
								this.store.dispatch(GetHeaderType({ payload: TypeHeader.FAVORITE_SCHEDULE_FOLDERS }));
								this.store.dispatch(GetHeaderTitle({ payload: this.folder.name }));
							})
					);
				}
			})
		);
	}

	/**
	 * getEventUser
	 */
	getEventUser() {
		if (this.eventUserSub && !this.eventUserSub.closed) {
			this.eventUserSub.unsubscribe();
		}

		this.eventUserSub = this.store.select(getCurrentEventUser).subscribe((eventUser) => {
			this.buildEventUser(eventUser);
		});
	}

	/**
	 * getEventUsers
	 */
	// getEventUsers() {
	// 	this.store
	// 		.select(getAllEventUsers)
	// 		.pipe(take(1))
	// 		.subscribe((eventUsers) => {
	// 			this.buildEventUsers(eventUsers);
	// 		});
	// }

	/**
	 * buildDatas
	 */
	buildDatas() {
		// this.subscriptions.push(
		combineLatest([
			this.store.select(getBaseCustomFields),
			this.store.select(getModulesCustomFields),
			this.store.select(getTracks),
			this.store.select(getLocations("asc")),
			this.store.select(getDocuments),
			this.store.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER]))
		])
			.pipe(
				skipWhile(() => !this.event || !this.module),
				take(1)
			)
			.subscribe((results) => {
				/** CUTSOM FIELDS - Part */
				this.buildCustomFields(results[0], results[1]);
				/** End of - CUTSOM FIELDS - Part */

				/** TRACKS - Part */
				this.buildTracks(results[2]);
				/** End of - TRACKS - Part */

				/** LOCATIONS - Part */
				this.buildLocations(results[3]);
				/** End of - LOCATIONS - Part */
				/** DOCUMENTS - Part */
				this.documents = results[4];
				/** End of - DOCUMENT - Part */

				/** EVENTUSERS MODULES - PARTS */
				this.eventUsersModules = results[5];
				/** End of - EVENTUSERS MODULES - PARTS */

				/** SESSIONS - Part */
				// this.buildScheduleSessions(results[2], results[3], results[4]);
				/** End of - SESSION - Part  */
			});
		// );
	}

	/**
	 * Get event user
	 */
	buildEventUser(eventUser: IEventUser) {
		if (
			(this.eventUser &&
				eventUser &&
				!this.SEventUsers.compareEventUsersWithoutConnectedProp(this.eventUser, eventUser)) ||
			!this.eventUser
		) {
			this.eventUser = eventUser;
			this.timezoneType =
				this.eventUser &&
				this.eventUser.updatedSettings &&
				this.eventUser.updatedSettings.timezoneType === "local"
					? true
					: false;
		}
	}

	/**
	 * getAllEventUsers
	 */
	// buildEventUsers(eventUsers: IEventUser[]) {
	// 	if (
	// 		(this.eventUsers.length !== 0 &&
	// 			eventUsers &&
	// 			eventUsers.length !== 0 &&
	// 			!this.eventUsers.every((eventUser) =>
	// 				this.SEventUsers.compareEventUsersWithoutConnectedProp(
	// 					eventUser,
	// 					eventUsers.find((usr) => usr.uid === eventUser.uid)
	// 				)
	// 			)) ||
	// 		this.eventUsers.length === 0 ||
	// 		!this.eventUsers
	// 	) {
	// 		this.eventUsers = eventUsers;
	// 		this.storedDatas["eventUsers"] = eventUsers;

	// 		// update eventUsers datas on sessions if needed
	// 		this.buildScheduleSessionsOnCustomFieldsChanges();
	// 	}
	// }

	/**
	 * getAllEventUsersModules
	 */
	getAllEventUsersModules() {
		this.subscriptions.push(
			this.store
				.select(getModulesByTypes([TypeModule.ATTENDEE, TypeModule.SPEAKER]))
				.pipe(skipWhile((modules) => !modules))
				.subscribe((modules) => {
					if (!_.isEqual(this.eventUsersModules, modules)) {
						this.eventUsersModules = modules;
						this.storedDatas["eventUsersModules"] = modules;
					}
				})
		);
	}

	/**
	 * getAllDocuments
	 */
	getAllDocuments() {
		this.subscriptions.push(
			this.store
				.select(getDocuments)
				.pipe(skipWhile((documents) => !documents))
				.subscribe((documents) => {
					if (!_.isEqual(this.documents, documents)) {
						this.documents = documents;
						this.storedDatas["documents"] = documents;
					}
				})
		);
	}

	/**
	 * Get groups
	 */
	getGroups() {
		this.subscriptions.push(
			this.store.select(getGroupsByOrder("asc")).subscribe((groups) => {
				this.groups = groups;
				this.groupIds = this.groups.map((group) => group.uid);
			})
		);
	}

	/**
	 * getCustomFields
	 */
	async buildCustomFields(baseCustomFields: ICustomField[], modulesCustomFields: IModuleCustomField[]) {
		if (
			(this.storedDatas["baseCustomFields"]["value"].length !== 0 &&
				this.storedDatas["modulesCustomFields"]["value"].length !== 0 &&
				(!_.isEqual(modulesCustomFields, this.storedDatas["modulesCustomFields"]["value"]) ||
					!_.isEqual(this.storedDatas["baseCustomFields"]["value"], baseCustomFields))) ||
			this.storedDatas["baseCustomFields"]["value"].length === 0 ||
			this.storedDatas["modulesCustomFields"]["value"].length === 0 ||
			this.storedDatas["computedCustomFields"]["value"].length === 0
		) {
			this.storedDatas["baseCustomFields"]["value"] = _.cloneDeep(baseCustomFields);
			this.storedDatas["modulesCustomFields"]["value"] = _.cloneDeep(modulesCustomFields);

			this.storedDatas["computedCustomFields"]["value"] =
				modulesCustomFields.length > 0
					? modulesCustomFields.map((customField) => {
							const baseCustomFieldCorresponding = baseCustomFields.find(
								(custField) => custField.uid === customField.uid
							);
							return {
								baseSettings: baseCustomFieldCorresponding ? baseCustomFieldCorresponding : null,
								moduleSettings: customField,
								fieldDatas: {
									uid: "",
									field: {
										date: {
											day: 0,
											month: 0,
											year: 0,
											value: "",
											fullDateISO: "",
											zone: ""
										},
										file: {
											lastModifiedDate: "",
											name: "",
											type: "",
											url: "",
											size: 0
										},
										image: {
											format: "",
											lastModifiedDate: "",
											name: "",
											url: "",
											size: 0
										},
										module: {
											items: [],
											moduleType: -1
										},
										multiLanguageText: {
											ArAR: "",
											DeDE: "",
											EnUS: "",
											EsES: "",
											FrFR: "",
											PtBR: ""
										},
										multiLanguageSelectArray: [],
										multiLanguageTextArray: {
											ArAR: [],
											DeDE: [],
											EnUS: [],
											EsES: [],
											FrFR: [],
											PtBR: []
										},
										numeric: 0,
										phoneNumber: {
											countryCode: "",
											dialCode: "",
											e164Number: "",
											internationalNumber: "",
											nationalNumber: "",
											number: ""
										},
										text: ""
									}
								}
							};
					  })
					: [];

			if (
				this.computedCustomFields.length === 0 &&
				this.storedDatas["computedCustomFields"]["value"].length > 0
			) {
				this.computedCustomFields = this.storedDatas["computedCustomFields"]["value"];
			} else {
				if (this.storedDatas["computedCustomFields"]["value"].length < this.computedCustomFields.length) {
					this.computedCustomFields.splice(
						this.storedDatas["computedCustomFields"]["value"].length,
						this.computedCustomFields.length - this.storedDatas["computedCustomFields"]["value"].length
					);
				}

				this.storedDatas["computedCustomFields"]["value"].forEach((computedCus, index) => {
					if (
						computedCus &&
						this.computedCustomFields[index] &&
						!_.isEqual(computedCus, this.computedCustomFields[index])
					) {
						this.computedCustomFields[index] = _.cloneDeep(computedCus);
					}
				});
			}

			// Update sessions who has CF modified
			await this.buildScheduleSessions(
				this.storedDatas["sessions"]["value"],
				this.storedDatas["tracks"]["value"],
				this.storedDatas["locations"]["value"]
			);

			// Build custom field filter data
			if (
				this.module?.options.showFilter &&
				this.storedDatas["sessions"]["value"].length > 0 &&
				this.storedDatas["filters"]["customFields"].length > 0
			) {
				this.buildCustomFieldsFilters();
			}
		}
	}

	compareDates(dateOne: string, dateTwo: string) {
		return (
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"day"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"year"
			) &&
			this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateOne).hasSame(
				this.SUtility.getDateOnConfigTimezone(this.event, this.eventUser, dateTwo),
				"month"
			)
		);
	}

	/**
	 * buildCustomFieldsFilters
	 */
	buildCustomFieldsFilters() {
		// this.filters.customFields = [];
		const customFieldsFilters: IFilteredItemFormat[] = [];

		this.computedCustomFields.forEach((customField) => {
			if (
				customField.moduleSettings.moduleId === this.moduleId &&
				customField.moduleSettings.enableFilter &&
				(customField.baseSettings.type === TypeCustomFields.TEXT ||
					customField.baseSettings.type === TypeCustomFields.SELECT ||
					customField.baseSettings.type === TypeCustomFields.MULTI_SELECT ||
					customField.baseSettings.type === TypeCustomFields.DATE ||
					customField.baseSettings.type === TypeCustomFields.URL ||
					customField.baseSettings.type === TypeCustomFields.EMAIL ||
					customField.baseSettings.type === TypeCustomFields.NUMERIC)
			) {
				const values: IFilterOptions[] = [];
				this.sessions.forEach((session) => {
					const cus = session.customFields.find((cus) => cus.uid === customField.baseSettings.uid);
					if (cus) {
						customField.baseSettings.type === TypeCustomFields.TEXT ||
						customField.baseSettings.type === TypeCustomFields.SELECT
							? values.push({
									isReachable: true,
									isSelected: false,
									filterId: customField.baseSettings.uid,
									value:
										cus.field &&
										cus.field.multiLanguageText[this.currentLanguage] &&
										(cus.field.multiLanguageText[this.currentLanguage] !== null ||
											cus.field.multiLanguageText[this.currentLanguage] !== "")
											? cus.field.multiLanguageText[this.currentLanguage]
											: this.STranslate.instant("filter.not_specified")
							  } as IFilterOptions)
							: customField.baseSettings.type === TypeCustomFields.DATE
							? values.push({
									isNotSpecified: cus.field.date.fullDateISO !== "" ? false : true,
									isReachable: true,
									isSelected: false,
									filterId: customField.baseSettings.uid,
									value:
										cus.field.date.fullDateISO !== ""
											? cus.field.date.fullDateISO?.split("T")[0]
											: this.STranslate.instant("filter.not_specified"),
									totalDatas: 0
							  })
							: customField.baseSettings.type === TypeCustomFields.URL ||
							  customField.baseSettings.type === TypeCustomFields.EMAIL
							? values.push({
									isNotSpecified: cus.field.text !== "" ? false : true,
									isReachable: true,
									isSelected: false,
									filterId: customField.baseSettings.uid,
									value:
										cus.field.text !== ""
											? cus.field.text
											: this.STranslate.instant("filter.not_specified"),
									totalDatas: 0
							  })
							: customField.baseSettings.type === TypeCustomFields.NUMERIC
							? values.push({
									isNotSpecified: cus.field.numeric?.toString() !== "-1" ? false : true,
									isReachable: true,
									isSelected: false,
									filterId: customField.baseSettings.uid,
									value:
										cus.field.numeric?.toString() !== "-1"
											? cus.field.numeric?.toString()
											: this.STranslate.instant("filter.not_specified"),
									totalDatas: 0
							  })
							: cus.field.multiLanguageSelectArray
									?.map((option: ILanguage) => option[this.currentLanguage])
									.forEach((option: string) => {
										values.push({
											isNotSpecified:
												option !== "" && !values.find((value) => value.value === option)
													? false
													: true,
											isReachable: true,
											isSelected: false,
											filterId: customField.baseSettings.uid,
											value:
												option !== "" && !values.find((value) => value.value === option)
													? option
													: this.STranslate.instant("filter.not_specified"),
											totalDatas: 0
										});
									});
					}
				});
				customFieldsFilters.push({
					uid: customField.baseSettings.uid,
					name: customField.baseSettings.name[this.currentLanguage],
					strictSelectionMode: customField.baseSettings.type === TypeCustomFields.MULTI_SELECT ? false : null,
					values: values
						.filter(
							(item, i, self) =>
								i ===
								self.findIndex(
									(it) =>
										item &&
										item.value &&
										item.value !== "" &&
										it.value &&
										it.value !== "" &&
										it.value.toString().trim()?.toLowerCase() ===
											item.value.toString().trim()?.toLowerCase()
								)
						)
						.sort((a, b) => {
							if (a.value.toString().toLowerCase() == b.value.toString().toLowerCase()) return 0;
							if (
								a.value.toString().toLowerCase() ==
								this.STranslate.instant("filter.not_specified").toLowerCase()
							)
								return 1;
							if (
								b.value.toString().toLowerCase() ==
								this.STranslate.instant("filter.not_specified").toLowerCase()
							)
								return -1;

							if (a.value.toString().toLowerCase() > b.value.toString().toLowerCase()) return 1;
							if (a.value.toString().toLowerCase() < b.value.toString().toLowerCase()) return -1;
							return 0;
						})
				} as IFilteredItemFormat);
			}
		});

		this.storedDatas["filters"]["customFields"] = customFieldsFilters;

		if (this.filters.customFields.length === 0 && this.storedDatas["filters"]["customFields"].length > 0) {
			this.filters.customFields = this.storedDatas["filters"]["customFields"];
		} else {
			if (this.storedDatas["filters"]["customFields"].length < this.filters.customFields.length) {
				this.filters.customFields.splice(
					this.storedDatas["filters"]["customFields"].length,
					this.filters.customFields.length - this.storedDatas["filters"]["customFields"].length
				);
			}

			this.storedDatas["filters"]["customFields"].forEach((customFilter, index) => {
				if (
					customFilter &&
					this.filters.customFields[index] &&
					!_.isEqual(customFilter, this.filters.customFields[index])
				) {
					this.filters.customFields[index] = _.cloneDeep(customFilter);
				} else if (customFilter && !this.filters.customFields[index]) {
					this.filters.customFields.push(customFilter);
				}
			});
		}
	}

	/**
	 * Change timezone type
	 * @param change
	 */
	async changeTimezoneType(change: any) {
		try {
			if (!this.updating && this.event && this.eventUser) {
				this.updating = true;
				this.eventUser.updatedSettings.timezoneType = change.detail.checked ? "local" : "event";
				await this.SEventUsers.updateEventUserUpdatedSettings(
					this.eventId,
					this.eventUser.moduleId,
					this.eventUser.uid,
					{
						timezoneType: this.eventUser.updatedSettings.timezoneType
					}
				);
				this.updating = false;
			}
		} catch (error) {
			this.updating = false;
		}
	}

	/**
	 * buildTracks
	 */
	buildTracks(tracks: ITrack[]) {
		if (
			(this.storedDatas["tracks"]["value"].length !== 0 &&
				!_.isEqual(tracks, this.storedDatas["tracks"]["value"])) ||
			this.storedDatas["tracks"]["value"].length === 0
		) {
			this.tracks = _.cloneDeep(tracks);
			this.storedDatas["tracks"]["value"] = _.cloneDeep(tracks);

			if (this.module?.options.showFilter && this.storedDatas["sessions"]["value"].length !== 0)
				this.buildTracksFilters(tracks, this.storedDatas["sessions"]["value"]);
		}
	}

	/**
	 * Build tracks filters
	 */
	buildTracksFilters(tracks: ITrack[], sessions: ISchedule[]) {
		this.tmpSessionsTracks = sessions;

		this.storedDatas["filters"]["tracks"] = tracks
			.filter((track) => this.tmpSessionsTracks.some((session) => session.tracks.includes(track.uid)))
			.sort((a, b) =>
				a.name[
					this.eventUser &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.language &&
					a.name[this.eventUser.updatedSettings.language]
						? this.eventUser.updatedSettings.language
						: this.currentLanguage
				] >
				b.name[
					this.eventUser &&
					this.eventUser.updatedSettings &&
					this.eventUser.updatedSettings.language &&
					b.name[this.eventUser.updatedSettings.language]
						? this.eventUser.updatedSettings.language
						: this.event.language
				]
					? 1
					: a.name[
							this.eventUser &&
							this.eventUser.updatedSettings &&
							this.eventUser.updatedSettings.language &&
							a.name[this.eventUser.updatedSettings.language]
								? this.eventUser.updatedSettings.language
								: this.event.language
					  ] <
					  b.name[
							this.eventUser &&
							this.eventUser.updatedSettings &&
							this.eventUser.updatedSettings.language &&
							b.name[this.eventUser.updatedSettings.language]
								? this.eventUser.updatedSettings.language
								: this.event.language
					  ]
					? -1
					: 0
			)
			.map((track) => {
				return {
					uid: track.uid,
					name: track.name,
					checked:
						this.filters && this.storedDatas["filters"]["tracks"].find((tra) => tra.uid === track.uid)
							? this.storedDatas["filters"]["tracks"].find((tra) => tra.uid === track.uid).checked
							: false,
					isReachable: true
				};
			});

		if (
			this.tmpSessionsTracks.some((session) => session.tracks.length === 0) &&
			!this.storedDatas["filters"]["tracks"].find(
				(track) =>
					(track.name[this.event.language] as string).toLowerCase() ===
					this.STranslate.instant("filter.not_specified").toLowerCase()
			)
		) {
			this.storedDatas["filters"]["tracks"].push({
				uid: "",
				name: {
					ArAR: this.event.language === "ArAR" ? this.STranslate.instant("filter.not_specified") : "",
					DeDE: this.event.language === "DeDE" ? this.STranslate.instant("filter.not_specified") : "",
					EnUS: this.event.language === "EnUS" ? this.STranslate.instant("filter.not_specified") : "",
					EsES: this.event.language === "EsES" ? this.STranslate.instant("filter.not_specified") : "",
					FrFR: this.event.language === "FrFR" ? this.STranslate.instant("filter.not_specified") : "",
					PtBR: this.event.language === "PtBR" ? this.STranslate.instant("filter.not_specified") : ""
				} as ILanguage,
				checked: false,
				isReachable: true
			});
		}

		if (this.filters.tracks.length === 0 && this.storedDatas["filters"]["tracks"].length > 0) {
			this.filters.tracks = this.storedDatas["filters"]["tracks"];
		} else {
			if (this.storedDatas["filters"]["tracks"].length < this.filters.tracks.length) {
				this.filters.tracks.splice(
					this.storedDatas["filters"]["tracks"].length,
					this.filters.tracks.length - this.storedDatas["filters"]["tracks"].length
				);
			}
			this.storedDatas["filters"]["tracks"].forEach((trackFilter, index) => {
				if (trackFilter && this.filters.tracks[index] && !_.isEqual(trackFilter, this.filters.tracks[index])) {
					this.filters.tracks[index] = _.cloneDeep(trackFilter);
				} else if (trackFilter && !this.filters.tracks[index]) {
					this.filters.tracks.push(trackFilter);
				}
			});
		}
	}

	/**
	 * Build grouops filters
	 */
	buildGroupsFilters(sessions: ISchedule[]) {
		this.tmpSessionsGroups = sessions;

		this.storedDatas["filters"]["groups"] = this.groups
			.filter((group) => this.tmpSessionsGroups.some((session) => session.groups.includes(group.uid)))
			.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
			.map((group) => {
				return {
					uid: group.uid,
					name: group.name,
					checked: this.storedDatas["filters"]["groups"].find((gr) => gr.uid === group.uid)
						? this.storedDatas["filters"]["groups"].find((gr) => gr.uid === group.uid).checked
						: false,
					isReachable: true
				};
			});

		if (
			this.tmpSessionsGroups.some((session) => session.groups.length === 0) &&
			!this.storedDatas["filters"]["groups"].find(
				(group) =>
					(group.name as string).toLowerCase() ===
					this.STranslate.instant("filter.not_specified").toLowerCase()
			)
		) {
			this.storedDatas["filters"]["groups"].push({
				uid: "",
				name: this.STranslate.instant("filter.not_specified"),
				checked: false,
				isReachable: true
			});
		}

		if (this.filters.groups.length === 0 && this.storedDatas["filters"]["groups"].length > 0) {
			this.filters.groups = this.storedDatas["filters"]["groups"];
		} else {
			if (this.storedDatas["filters"]["groups"].length < this.filters.groups.length) {
				this.filters.groups.splice(
					this.storedDatas["filters"]["groups"].length,
					this.filters.groups.length - this.storedDatas["filters"]["groups"].length
				);
			}
			this.storedDatas["filters"]["groups"].forEach((groupFilter, index) => {
				if (groupFilter && this.filters.groups[index] && !_.isEqual(groupFilter, this.filters.groups[index])) {
					this.filters.groups[index] = _.cloneDeep(groupFilter);
				} else if (groupFilter && !this.filters.groups[index]) {
					this.filters.groups.push(groupFilter);
				}
			});
		}
	}

	/**
	 * buildLocations
	 */
	buildLocations(locations: ILocation[]) {
		if (
			(this.storedDatas["locations"]["value"].length !== 0 &&
				!_.isEqual(locations, this.storedDatas["locations"]["value"])) ||
			this.storedDatas["locations"]["value"].length === 0
		) {
			this.locations = locations;
			this.locationsIds = this.locations.map((loc) => loc.uid);
			this.storedDatas["locations"]["value"] = locations;

			if (this.module?.options.showFilter && this.storedDatas["sessions"]["value"].length !== 0)
				this.buildLocationsFilters(locations, this.storedDatas["sessions"]["value"]);
		}
	}

	/**
	 * Get all locations
	 */
	buildLocationsFilters(locations: ILocation[], sessions: ISchedule[]) {
		this.tmpSessionsLocations = sessions;

		this.storedDatas["filters"]["locations"] = this.locations
			.filter((location) => this.tmpSessionsLocations.some((session) => session.locations.includes(location.uid)))
			.sort((a, b) => (a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
			.map((location) => {
				return {
					uid: location.uid,
					name: location.name,
					checked:
						this.filters && this.storedDatas["filters"]["locations"].find((loc) => loc.uid === location.uid)
							? this.storedDatas["filters"]["locations"].find((loc) => loc.uid === location.uid).checked
							: false,
					isReachable: true
				};
			});

		if (
			this.tmpSessionsLocations.some((session) => session.locations.length === 0) &&
			!this.storedDatas["filters"]["locations"].find(
				(location) =>
					(location.name as string).toLowerCase() ===
					this.STranslate.instant("filter.not_specified").toLowerCase()
			)
		)
			this.storedDatas["filters"]["locations"].push({
				uid: "",
				name: this.STranslate.instant("filter.not_specified"),
				checked: false,
				isReachable: true
			});

		if (this.filters.locations.length === 0 && this.storedDatas["filters"]["locations"].length > 0) {
			this.filters.locations = this.storedDatas["filters"]["locations"];
		} else {
			if (this.storedDatas["filters"]["locations"].length < this.filters.locations.length) {
				this.filters.locations.splice(
					this.storedDatas["filters"]["locations"].length,
					this.filters.locations.length - this.storedDatas["filters"]["locations"].length
				);
			}
			this.storedDatas["filters"]["locations"].forEach((locationFilter, index) => {
				if (
					locationFilter &&
					this.filters.locations[index] &&
					!_.isEqual(locationFilter, this.filters.locations[index])
				) {
					this.filters.locations[index] = _.cloneDeep(locationFilter);
				} else if (locationFilter && !this.filters.locations[index]) {
					this.filters.locations.push(locationFilter);
				}
			});
		}
	}

	/**
	 * getScheduleSessions
	 */
	getScheduleSessions() {
		if (this.sessionsSub && !this.sessionsSub.closed) {
			this.sessionsSub.unsubscribe();
		}
		this.sessionsSub = combineLatest(
			this.store.select(getCurrentEventUser),
			this.store.select(getSpecificModule(this.folder.moduleLinkedId))
		)
			.pipe(
				skipWhile(
					() =>
						!this.event ||
						!this.module ||
						!this.computedCustomFields ||
						!this.tracks ||
						!this.locations ||
						!this.eventUsersModules
				),
				take(1),
				switchMap((results) => {
					let eventUser = results[0];
					let module = results[1];
					if (eventUser && eventUser.favorites && eventUser.favorites.length > 0) {
						let chunkedFavorites = _.chunk(eventUser.favorites, 10);
						let obs: Observable<ISchedule[]>[] = [];
						chunkedFavorites.forEach((chunk) => {
							obs.push(
								from(
									this.SFirestore.getDocumentsCollectionGroup("schedules", [
										where("eventId", "==", this.eventId),
										where("uid", "in", chunk)
									])
								).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ISchedule)))
							);
						});
						return combineLatest(obs).pipe(
							take(1),
							switchMap((resultsSchedules) => {
								return of(
									_.flatten(resultsSchedules)
										.filter(
											(schedule) =>
												schedule &&
												schedule.moduleId === this.folder.moduleLinkedId &&
												(schedule.visibility || schedule.visibility === undefined)
										)
										.filter(
											(schedule) =>
												module &&
												module.options &&
												(!module.options.viewOnlyGroupsContent ||
													(module.options.viewOnlyGroupsContent &&
														eventUser &&
														schedule.groups.some((grpId) =>
															eventUser.groups.includes(grpId)
														)))
										)
										.sort((a, b) =>
											a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
										)
								);
							})
						);
					} else {
						return of([]);
					}
				})
			)
			.subscribe(async (sessions: ISchedule[]) => {
				this.allSessions = sessions;
				sessions = sessions.map((session) => {
					delete session.registeredUsers;
					return session;
				});

				this.buildFavorites();

				if (sessions.length <= 20) {
					this.sessionsPerPage = sessions.length;
					this.numberOfSessionsShowed = sessions.length;
				} else {
					this.sessionsPerPage = 15;
					this.numberOfSessionsShowed = 15;
				}

				if (
					(this.storedDatas["baseSessions"]["value"].length !== 0 && this.sessions.length === 0) ||
					(this.storedDatas["baseSessions"]["value"].length !== 0 &&
						!_.isEqual(sessions, this.storedDatas["baseSessions"]["value"])) ||
					this.storedDatas["baseSessions"]["value"].length === 0
				) {
					await this.buildScheduleSessions(sessions, this.tracks, this.locations);
					if (this.module && this.module.options.showFilter) {
						/** TRACKS FILTER DATAS - Part */
						this.buildTracksFilters(this.storedDatas["tracks"]["value"], sessions);
						/** End of - TRACKS FILTER DATAS - Part */

						/** LOCATIONS FILTER DATAS - Part */
						this.buildLocationsFilters(this.storedDatas["locations"]["value"], sessions);
						/** End of - LOCATIONS FILTER DATAS - Part */

						/** GROUPS FILTER DATAS - PART */
						this.buildGroupsFilters(this.sessions);
						/** End of - GROUPS FILTER DATAS - PART */

						/** CUSTOM FIELS FILTER DATAS - Part */
						if (this.module && this.module.options && this.module.options.showFilter)
							this.buildCustomFieldsFilters();
						/** End of - CUSTOM FIELS FILTER DATAS - Part */

						this.filtersCollapseState = this.filtersCollapseState.concat([
							{
								uid: "locations",
								collapsed: this.filterIsActivated("locations") ? false : true
							},
							{ uid: "tracks", collapsed: this.filterIsActivated("tracks") ? false : true },
							{ uid: "groups", collapsed: this.filterIsActivated("groups") ? false : true }
						]);
						this.storedDatas["filters"]["customFields"]?.forEach((filter) => {
							this.filtersCollapseState.push({
								uid: filter.uid,
								collapsed: this.filterIsActivated("customs-fields", filter.uid) ? false : true
							});
						});

						this.totalItemFiltered = this.sessions.length;
					}
				}

				this.loader = false;
				this.scheduleLoader = false;
			});
		// this.sessionsSub = this.store
		// 	.select(getFavoritesessionsOfEventUser(this.folder.moduleLinkedId))
		// 	.pipe(
		// 		skipWhile(
		// 			() =>
		// 				!this.event ||
		// 				!this.module ||
		// 				// !this.eventUser ||
		// 				!this.computedCustomFields ||
		// 				!this.tracks ||
		// 				!this.locations ||
		// 				!this.eventUsersModules
		// 		),
		// 		take(1)
		// 	)
		// 	.subscribe(async (sessions) => {
		// 		this.allSessions = sessions;
		// 		sessions = sessions.map((session) => {
		// 			delete session.registeredUsers;
		// 			return session;
		// 		});

		// 		this.buildFavorites();

		// 		if (sessions.length <= 20) {
		// 			this.sessionsPerPage = sessions.length;
		// 			this.numberOfSessionsShowed = sessions.length;
		// 		} else {
		// 			this.sessionsPerPage = 15;
		// 			this.numberOfSessionsShowed = 15;
		// 		}

		// 		if (
		// 			(this.storedDatas["baseSessions"]["value"].length !== 0 && this.sessions.length === 0) ||
		// 			(this.storedDatas["baseSessions"]["value"].length !== 0 &&
		// 				!_.isEqual(sessions, this.storedDatas["baseSessions"]["value"])) ||
		// 			this.storedDatas["baseSessions"]["value"].length === 0
		// 		) {
		// 			await this.buildScheduleSessions(sessions, this.tracks, this.locations);
		// 			if (this.module && this.module.options.showFilter) {
		// 				/** TRACKS FILTER DATAS - Part */
		// 				this.buildTracksFilters(this.storedDatas["tracks"]["value"], sessions);
		// 				/** End of - TRACKS FILTER DATAS - Part */

		// 				/** LOCATIONS FILTER DATAS - Part */
		// 				this.buildLocationsFilters(this.storedDatas["locations"]["value"], sessions);
		// 				/** End of - LOCATIONS FILTER DATAS - Part */

		// 				/** GROUPS FILTER DATAS - PART */
		// 				this.buildGroupsFilters(this.sessions);
		// 				/** End of - GROUPS FILTER DATAS - PART */

		// 				/** CUSTOM FIELS FILTER DATAS - Part */
		// 				if (this.module && this.module.options && this.module.options.showFilter)
		// 					this.buildCustomFieldsFilters();
		// 				/** End of - CUSTOM FIELS FILTER DATAS - Part */

		// 				this.filtersCollapseState = this.filtersCollapseState.concat([
		// 					{
		// 						uid: "locations",
		// 						collapsed: this.filterIsActivated("locations") ? false : true
		// 					},
		// 					{ uid: "tracks", collapsed: this.filterIsActivated("tracks") ? false : true },
		// 					{ uid: "groups", collapsed: this.filterIsActivated("groups") ? false : true }
		// 				]);
		// 				this.storedDatas["filters"]["customFields"]?.forEach((filter) => {
		// 					this.filtersCollapseState.push({
		// 						uid: filter.uid,
		// 						collapsed: this.filterIsActivated("customs-fields", filter.uid) ? false : true
		// 					});
		// 				});

		// 				this.totalItemFiltered = this.sessions.length;
		// 			}
		// 		}

		// 		this.loader = false;
		// 		this.scheduleLoader = false;
		// 	});
	}

	/**
	 * buildScheduleSessions
	 */
	async buildScheduleSessions(sessions: ISchedule[], tracks: ITrack[], locations: ILocation[]) {
		this.storedDatas["baseSessions"]["value"] = _.cloneDeep(sessions);
		this.storedDatas["sessions"]["value"] = _.cloneDeep(sessions);

		const eventUsersUidsToFind = this.getEventUsersUidsForSessions(sessions);
		const differentUids = _.difference(eventUsersUidsToFind, this.storedEventUsersUids);
		this.storedEventUsersUids = _.uniq(this.storedEventUsersUids.concat(eventUsersUidsToFind));

		// Get all event users datas needed
		const eventUsersfound = await firstValueFrom(
			this.SEventUsers.getSpecificEventUsersForEvent(this.eventId, null, differentUids).pipe(take(1))
		);

		this.storedEventUsersDatas = this.storedEventUsersDatas.concat(eventUsersfound);

		this.storedDatas["sessions"]["value"].forEach((session) => {
			// attach 'Tracks' to the session
			session["trackDatas"] = tracks
				.filter((track) => session.tracks.includes(track.uid))
				.sort((a, b) => {
					return a.orderInSession < b.orderInSession
						? 1
						: a.orderInSession > b.orderInSession
						? -1
						: a.name < b.name
						? 1
						: a.name < b.name
						? -1
						: 0;
				});
			// attach 'Locations' field to the session
			session["locationDatas"] = locations.filter((location) => session.locations.includes(location.uid));

			// attach 'Tags' to the session
			session["tags"] = this.getTagsForSession(session, this.storedEventUsersDatas);
		});

		// If no datas on stored sessions datas copy directly
		if (this.sessions.length === 0 && this.storedDatas["sessions"]["value"].length > 0) {
			this.sessions = _.cloneDeep(this.storedDatas["sessions"]["value"]);
		} else {
			// If already datas on it check for difference in length and delete surplus for having same length
			if (this.storedDatas["sessions"]["value"].length < this.sessions.length) {
				this.sessions.splice(
					this.storedDatas["sessions"]["value"].length,
					this.sessions.length - this.storedDatas["sessions"]["value"].length
				);
			}
			// After loop onto the sessions who arrive
			this.storedDatas["sessions"]["value"].forEach((session, index) => {
				if (session && this.sessions[index] && !_.isEqual(session, this.sessions[index])) {
					// Case if session exist in both arrays and those sessions are not equal, replace by the new
					this.sessions[index] = _.cloneDeep(session);
				} else if (session && !this.sessions[index]) {
					// eslint-disable-next-line max-len
					// Case if session exist but index of base session in array don't exist (case of adding new session) push it in
					this.sessions.push(session);
				}
			});
		}

		// Build dates
		this.dates = this.sessions
			.filter((session, index) => {
				const sessionsBefore = index > 0 ? this.sessions.slice(0, index) : [];
				return !sessionsBefore.some((sessionBefore) =>
					this.compareDates(sessionBefore.startDate, session.startDate)
				);
			})
			.map((session) => session.startDate)
			.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
		this.filterByDate();

		this.loader = false;
		return true;
	}

	/**
	 * buildScheduleSessionsOnCustomFieldsChanges
	 */
	async buildScheduleSessionsOnCustomFieldsChanges() {
		if (
			this.storedDatas["sessions"]["value"].length > 0 &&
			this.storedDatas["locations"]["value"].length > 0 &&
			this.storedDatas["tracks"]["value"].length > 0
		) {
			const sessions = this.storedDatas["sessions"]["value"];
			const locations = this.storedDatas["locations"]["value"];
			const tracks = this.storedDatas["tracks"]["value"];

			this.storedDatas["baseSessions"]["value"] = _.cloneDeep(sessions);
			this.storedDatas["sessions"]["value"] = _.cloneDeep(sessions);

			const eventUsersUidsToFind = this.getEventUsersUidsForSessions(sessions);

			const differentUids = _.difference(eventUsersUidsToFind, this.storedEventUsersUids);
			this.storedEventUsersUids = _.uniq(this.storedEventUsersUids.concat(eventUsersUidsToFind));

			// Get all event users datas needed
			const eventUsersfound = await firstValueFrom(
				this.SEventUsers.getSpecificEventUsersForEvent(this.eventId, null, differentUids).pipe(take(1))
			);

			this.storedEventUsersDatas = this.storedEventUsersDatas.concat(eventUsersfound);

			this.storedDatas["sessions"]["value"].forEach((session) => {
				// attach 'Tracks' to the session
				session["trackDatas"] = tracks.filter((track) => session.tracks.includes(track.uid));

				// attach 'Locations' field to the session
				session["locationDatas"] = locations.filter((location) => session.locations.includes(location.uid));

				// attach 'Tags' to the session
				session["tags"] = this.getTagsForSession(session, this.storedEventUsersDatas);
			});

			// If no datas on stored sessions datas copy directly
			if (this.sessions.length === 0 && this.storedDatas["sessions"]["value"].length > 0) {
				this.sessions = _.cloneDeep(this.storedDatas["sessions"]["value"]);
			} else {
				// If already datas on it check for difference in length and delete surplus for having same length
				if (this.storedDatas["sessions"]["value"].length < this.sessions.length) {
					this.sessions.splice(
						this.storedDatas["sessions"]["value"].length,
						this.sessions.length - this.storedDatas["sessions"]["value"].length
					);
				}
				// After loop onto the sessions who arrive
				this.storedDatas["sessions"]["value"].forEach((session, index) => {
					if (session && this.sessions[index] && !_.isEqual(session, this.sessions[index])) {
						// Case if session exist in both arrays and those sessions are not equal, replace by the new
						this.sessions[index] = _.cloneDeep(session);
					} else if (session && !this.sessions[index]) {
						// eslint-disable-next-line max-len
						// Case if session exist but index of base session in array don't exist (case of adding new session) push it in
						this.sessions.push(session);
					}
				});
			}

			// Build dates
			this.dates = this.sessions
				.filter((session, index) => {
					const sessionsBefore = index > 0 ? this.sessions.slice(0, index) : [];
					return !sessionsBefore.some((sessionBefore) =>
						this.compareDates(sessionBefore.startDate, session.startDate)
					);
				})
				.map((session) => session.startDate)
				.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
			this.filterByDate();
		} else {
			this.loader = false;
		}
	}

	/**
	 * Paginate dates
	 * @returns
	 */
	getPaginatedDates() {
		if (this.selectedDate === "all") {
			return this.sessionsFiltered
				.slice(0, this.numberOfSessionsShowed)
				.filter((session, index) => {
					const sessionsBefore = index > 0 ? this.sessionsFiltered.slice(0, index) : [];
					return sessionsBefore.some((sessionBefore) =>
						this.compareDates(sessionBefore.startDate, session.startDate)
					)
						? false
						: true;
				})
				.map((session) => session.startDate)
				.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0));
		} else {
			return [this.selectedDate];
		}
	}

	/**
	 * Get paginated sessions
	 * @returns
	 */
	getPaginatedSessions() {
		this.sessionsFiltered.forEach((ses) => this.icsSessionLoader.push({ sessionId: ses.uid, state: false }));
		return this.sessionsFiltered.slice(0, this.numberOfSessionsShowed);
	}

	/**
	 * Get paginated sessions
	 * @returns
	 */
	getPaginatedSessionsByTracks() {
		if (this.paginateByTrack === 1) {
			let checkIndexSlice = 0;
			let numberOfSessionsInSlice = 0;
			this.sessionsByTrackFiltered.forEach((groupTrack) => {
				if (checkIndexSlice < this.paginateByTrack || numberOfSessionsInSlice < 30) {
					numberOfSessionsInSlice = numberOfSessionsInSlice + groupTrack.sessions.length;
					checkIndexSlice++;
				}
			});

			this.paginateByTrack = checkIndexSlice;
			return this.sessionsByTrackFiltered.slice(0, this.paginateByTrack);
		} else {
			return this.sessionsByTrackFiltered.slice(0, this.paginateByTrack);
		}
	}

	/**
	 * Load more sessions
	 */
	moreSessions(evt: any, type: string) {
		if (evt) {
			if (type === "tracks") {
				if (this.paginateByTrack < this.sessionsByTrackFiltered.length) {
					this.paginateByTrack++;
					evt.target.complete();
				} else {
					evt.target.disabled = true;
				}
			} else {
				if (this.numberOfSessionsShowed < this.sessionsFiltered.length) {
					this.numberOfSessionsShowed = this.numberOfSessionsShowed + this.sessionsPerPage;
					this.buildScheduleSessionsOnCustomFieldsChanges();
					evt.target.complete();
				} else {
					evt.target.disabled = true;
				}
			}
		}
	}

	/**
	 * Filter sessions by date
	 */
	filterByDate(type?: string, item?: any, event?: any) {
		if (event) {
			if (type === "locations") {
				const locationFilter = this.storedDatas["filters"]["locations"].find((loc) => loc.uid === item.uid);
				if (locationFilter) locationFilter.checked = event.target.checked;
			} else if (type === "tracks") {
				const trackFilter = this.storedDatas["filters"]["tracks"].find((tra) => tra.uid === item.uid);
				if (trackFilter) trackFilter.checked = event.target.checked;
			} else if (type === "groups") {
				const groupFilter = this.storedDatas["filters"]["groups"].find((gr) => gr.uid === item.uid);
				if (groupFilter) groupFilter.checked = event.target.checked;
			}
		}

		this.sessionsFiltered =
			this.selectedDate === "all"
				? this.applyFiltersOnSessions()
				: this.applyFiltersOnSessions().filter(
						(session) => this.compareDates(session.startDate, this.selectedDate)
						// DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(this.selectedDate), "day") &&
						// DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(this.selectedDate), "year") &&
						// DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(this.selectedDate), "month")
				  );

		// Reset ion infinite scroll
		this.infiniteScroll && (this.infiniteScroll.disabled = false);

		this.module && this.module.options && this.module.options.showFilter && this.computeReachableDatas();

		this.sessionsByTrackFiltered = [];
		// By tracks
		this.applyFiltersOnSessions().forEach((session) => {
			if (session.tracks.length > 0) {
				session.tracks.forEach((trackId) => {
					const track = this.tracks.find((track) => track.uid === trackId);
					if (track) {
						const groupTrack = this.sessionsByTrackFiltered.find(
							(group) => group.track && group.track.uid === track.uid
						);
						if (!groupTrack) {
							this.sessionsByTrackFiltered.push({
								beginDate: "",
								endDate: "",
								mainLocation: null,
								sessions: [session],
								track: track ? track : null
							});
							this.groupTrackCollapseState.push({
								uid: track.uid,
								collapsed: this.module.options.trackItemsUnfoldDefault ? false : true
							});
						} else {
							groupTrack.sessions.push(session);
							this.groupTrackCollapseState.push({
								uid: groupTrack.track.uid,
								collapsed: this.module.options.trackItemsUnfoldDefault ? false : true
							});
						}
					}
				});
			} else {
				this.sessionsByTrackFiltered.push({
					beginDate: session.startDate,
					endDate: session.endDate ? session.endDate : null,
					mainLocation: null,
					sessions: [session],
					track: null
				});
			}
		});

		this.sessionsByTrackFiltered
			.map((groupTrack) => {
				groupTrack.sessions.sort((a, b) =>
					a.startDate > b.startDate ? 1 : a.startDate < b.startDate ? -1 : 0
				);
				groupTrack.beginDate = groupTrack.sessions.length > 0 ? groupTrack.sessions[0].startDate : "";
				groupTrack.endDate =
					groupTrack.sessions.length > 0 && groupTrack.sessions[groupTrack.sessions.length - 1].endDate
						? groupTrack.sessions[groupTrack.sessions.length - 1].endDate
						: "";
				const locations = [];
				groupTrack.sessions.forEach((session) => {
					if (session.locations.length > 0) {
						session.locations.forEach((locationId) => {
							const location = this.locations.find((loc) => loc.uid === locationId);
							if (location) {
								if (!locations.find((loc) => loc.uid === location.uid)) {
									locations.push(location);
								}
							}
						});
					}
				});

				if (locations.length === 1) {
					groupTrack.mainLocation = locations[0];
				}

				return groupTrack;
			})
			.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));

		// this.selectedDate === "all"
		// 	? this.applyFiltersOnSessions()
		// 	: this.applyFiltersOnSessions().filter(
		// 			(session) =>
		// 				DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(this.selectedDate), "day") &&
		// 				DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(this.selectedDate), "year") &&
		// 				DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(this.selectedDate), "month")
		// 	  );

		this.sessionsByTrackFiltered =
			this.selectedDate === "all"
				? this.sessionsByTrackFiltered.sort((a, b) =>
						a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0
				  )
				: this.sessionsByTrackFiltered
						.filter(
							(groupTrack) => this.compareDates(groupTrack.beginDate, this.selectedDate)
							// DateTime.fromISO(groupTrack.beginDate).hasSame(
							// 	DateTime.fromISO(this.selectedDate),
							// 	"day"
							// ) &&
							// DateTime.fromISO(groupTrack.beginDate).hasSame(
							// 	DateTime.fromISO(this.selectedDate),
							// 	"year"
							// ) &&
							// DateTime.fromISO(groupTrack.beginDate).hasSame(
							// 	DateTime.fromISO(this.selectedDate),
							// 	"month"
							// )
						)
						.sort((a, b) => (a.beginDate > b.beginDate ? 1 : a.beginDate < b.beginDate ? -1 : 0));

		if (this.sessionsFiltered.length === 0) this.showNoResultImage = true;

		if (this.module && this.module.options.activateTracksFiltering && this.module.options.enableHeaderGroupTag) {
			this.eventUsersFromTags = this.getEventUsersFromTags(this.sessionsByTrackFiltered);
		}
	}

	/**
	 * Apply filters on session
	 * @returns
	 */
	applyFiltersOnSessions() {
		return this.module && this.module.options && this.module.options.showFilter
			? this.sessions
					.filter((session) => {
						if (this.searchBarValue && this.searchBarValue !== "") {
							let check: boolean = false;
							for (const lang of Object.keys(session.name)) {
								if (
									session.name[lang] &&
									this.SUtility.removeAccents(session.name[lang].toLowerCase()).includes(
										this.searchBarValue
									)
								) {
									check = true;
								}
							}
							return check;
						} else {
							return true;
						}
					})
					.filter((session) => {
						// Filter by locations
						if (
							this.filters.locations.length === 0 ||
							!this.filters.locations.some((location) => location.checked)
						) {
							return true;
						} else {
							return this.filters.locations
								.filter((location) => location.checked)
								.some(
									(location) =>
										session.locations.includes(location.uid) ||
										((location.name as string).toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											session.locations.length === 0)
								)
								? true
								: false;
						}
					})
					.filter((session) => {
						// Filter by tracks
						if (this.filters.tracks.length === 0 || !this.filters.tracks.some((track) => track.checked)) {
							return true;
						} else {
							return this.filters.tracks
								.filter((track) => track.checked)
								.some(
									(track) =>
										session.tracks.includes(track.uid) ||
										((track.name[this.event.language] as string).toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											session.tracks.length === 0)
								)
								? true
								: false;
						}
					})
					.filter((session) => {
						// Filter by groups
						if (this.filters.groups.length === 0 || !this.filters.groups.some((group) => group.checked)) {
							return true;
						} else {
							return this.filters.groups
								.filter((group) => group.checked)
								.some(
									(group) =>
										session.groups.includes(group.uid) ||
										((group.name as string).toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											session.groups.length === 0)
								)
								? true
								: false;
						}
					})
					.filter((session) => {
						// Filter by customs fields
						if (
							this.filters.customFields.length === 0 ||
							!this.filters.customFields.some((customField) =>
								customField.values.some((value) => value.isSelected)
							)
						) {
							return true;
						} else {
							return this.filters.customFields
								.filter((customField) => customField.values.find((value) => value.isSelected))
								.every((customField) =>
									session.customFields.find((customData) =>
										customField.values.find((value) =>
											this.getCustomFieldType(customData.uid) === TypeCustomFields.TEXT ||
											this.getCustomFieldType(customData.uid) === TypeCustomFields.SELECT
												? value.isSelected &&
												  ((value.filterId === customData.uid &&
														customData.field.multiLanguageText[this.event.language]
															.toString()
															.toLowerCase() === value.value.toString().toLowerCase()) ||
														(value.isSelected &&
															value.filterId === customData.uid &&
															value.value.toString().toLowerCase() ===
																this.STranslate.instant(
																	"filter.not_specified"
																).toLowerCase() &&
															customData.field.multiLanguageText[this.event.language] ===
																""))
												: this.getCustomFieldType(customData.uid) === TypeCustomFields.URL ||
												  this.getCustomFieldType(customData.uid) === TypeCustomFields.EMAIL
												? value.isSelected &&
												  ((value.filterId === customData.uid &&
														value.value.toString().toLowerCase() ===
															customData.field.text?.toString().toLowerCase()) ||
														(value.isSelected &&
															value.filterId === customData.uid &&
															value.value.toString().toLowerCase() ===
																this.STranslate.instant(
																	"filter.not_specified"
																).toLowerCase() &&
															customData.field.text === ""))
												: this.getCustomFieldType(customData.uid) === TypeCustomFields.DATE
												? value.isSelected &&
												  ((value.filterId === customData.uid &&
														value.value ===
															customData.field.date?.fullDateISO.split("T")[0]) ||
														(value.isSelected &&
															value.filterId === customData.uid &&
															value.value.toString().toLowerCase() ===
																this.STranslate.instant(
																	"filter.not_specified"
																).toLowerCase() &&
															customData.field.date?.fullDateISO === ""))
												: this.getCustomFieldType(customData.uid) === TypeCustomFields.NUMERIC
												? value.isSelected &&
												  ((value.filterId === customData.uid &&
														value.value.toString().toLowerCase() ===
															customData.field.numeric?.toString().toLowerCase()) ||
														(value.filterId === customData.uid &&
															value.value.toString().toLowerCase() ===
																this.STranslate.instant(
																	"filter.not_specified"
																).toLowerCase() &&
															customData.field.numeric === -1))
												: this.getCustomFieldType(customData.uid) ===
												  TypeCustomFields.MULTI_SELECT
												? (customField.strictSelectionMode &&
														value.filterId === customData.uid &&
														customField.values
															.filter((v) => v.isSelected)
															.map((opt) => opt.value.toString().toLowerCase())
															.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
															.join(", ") ===
															customData.field.multiLanguageSelectArray
																.map((opt) =>
																	opt[this.event.language].toString().toLowerCase()
																)
																.sort((a, b) => (a > b ? 1 : a < b ? -1 : 0))
																.join(", ")) ||
												  (!customField.strictSelectionMode &&
														customField.uid === customData.uid &&
														customData.field.multiLanguageSelectArray
															.map((opt) =>
																opt[this.event.language].toString().toLowerCase()
															)
															.includes(value.value.toString().toLowerCase())) ||
												  (customField.uid === customData.uid &&
														value.value.toString().toLowerCase() ===
															this.STranslate.instant(
																"filter.not_specified"
															).toLowerCase() &&
														customData.field.multiLanguageSelectArray.map(
															(opt) => opt[this.event.language]
														).length === 0)
												: false
										)
									)
								);
						}
					})
			: this.sessions.filter((session) =>
					this.searchBarValue && this.searchBarValue !== ""
						? this.SUtility.removeAccents(session.name[this.event.language].toLowerCase()).includes(
								this.searchBarValue
						  )
						: true
			  );
	}

	/**
	 * computeReachableDatas
	 * @description Calculates reachables options (checkboxs) based on the selected items
	 *
	 */
	computeReachableDatas() {
		/********
		 * Steps :
		 *
		 * 1 => Get not selected filters / datas
		 *
		 * 2 => Check in sessions if there is matching possibility with selected datas
		 */
		if (this.sessions.length !== this.sessionsFiltered.length) {
			this.notSelectedLocationDatas = this.filters.locations.filter(() =>
				this.filters.locations.every((location) => !location.checked)
			);
			this.notSelectedTrackDatas = this.filters.tracks.filter(() =>
				this.filters.tracks.every((track) => !track.checked)
			);
			this.notSelectedCustomFieldFilters = this.filters.customFields.filter(
				(filter) => !filter.values.find((value) => value.isSelected)
			);

			/****** Locations *****/
			let locationMatches = 0;
			const locationDatas: string[] = [];
			this.notSelectedLocationDatas.forEach((filter) => {
				this.sessionsFiltered.forEach((session) => {
					// Checking sessions that include location present in filter
					const location = session.locations.includes(filter.uid) ? filter : null;
					if (location) {
						if (
							!locationDatas.find((data) => data.toLowerCase() === location.name.toString().toLowerCase())
						)
							locationDatas.push((location.name as string).toLowerCase());

						locationMatches++;
					}

					// Checking sessions with not specified location
					if (
						this.sessionsFiltered.some((session) => session.locations.length === 0) &&
						!locationDatas.find(
							(data) =>
								data.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
						)
					)
						locationDatas.push((this.STranslate.instant("filter.not_specified") as string).toLowerCase());
				});
			});

			// Setting reachable datas
			if (locationMatches > 0) {
				this.notSelectedLocationDatas.filter((fil) => {
					if (
						locationDatas.findIndex((data) => data.toLowerCase() === fil.name.toString().toLowerCase()) ===
						-1
					) {
						fil.isReachable = false;
						return true;
					} else {
						fil.isReachable = true;
						return false;
					}
				});
			}

			/****** Tracks *****/
			let trackMatches = 0;
			const trackDatas: string[] = [];
			this.notSelectedTrackDatas.forEach((filter) => {
				this.sessionsFiltered.forEach((session) => {
					// Checking sessions that include track present in filter
					const track = session.tracks.includes(filter.uid) ? filter : null;
					if (track) {
						if (
							!trackDatas.find(
								(data) => data.toLowerCase() === track.name[this.event.language].toLowerCase()
							)
						)
							trackDatas.push(track.name[this.event.language].toLowerCase());
						trackMatches++;
					}

					// Checking sessions with not specified track
					if (
						this.sessionsFiltered.some((session) => session.tracks.length === 0) &&
						!trackDatas.find(
							(data) =>
								data.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
						)
					)
						trackDatas.push((this.STranslate.instant("filter.not_specified") as string).toLowerCase());
				});
			});

			// Setting reachable datas
			if (trackMatches > 0) {
				this.notSelectedTrackDatas.filter((fil) => {
					if (
						trackDatas.findIndex(
							(data) => data.toLowerCase() === fil.name[this.event.language].toLowerCase()
						) === -1
					) {
						fil.isReachable = false;
						return true;
					} else {
						fil.isReachable = true;
						return false;
					}
				});
			}

			/***** Custom fields *****/
			this.notSelectedCustomFieldFilters.forEach((filter) => {
				let matches = 0;
				const datas: string[] = [];
				this.sessionsFiltered.forEach((session) => {
					const custom = session.customFields.find(
						(cus) =>
							cus.uid === filter.uid &&
							(((this.getCustomFieldType(cus.uid) === TypeCustomFields.TEXT ||
								this.getCustomFieldType(cus.uid) === TypeCustomFields.SELECT) &&
								filter.values.find(
									(value) =>
										value.value.toString().toLowerCase() ===
											cus.field.multiLanguageText[this.event.language].toString().toLowerCase() ||
										(value.value.toString().toLowerCase() ===
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											this.STranslate.instant("filter.not_specified").toLowerCase() &&
											cus.field.multiLanguageText[this.event.language] === "")
								)) ||
								((this.getCustomFieldType(cus.uid) === TypeCustomFields.URL ||
									this.getCustomFieldType(cus.uid) === TypeCustomFields.EMAIL) &&
									filter.values.find(
										(value) =>
											value.value.toString().toLowerCase() === cus.field.text.toLowerCase() ||
											(value.value.toString().toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase() &&
												cus.field.text === "")
									)) ||
								(this.getCustomFieldType(cus.uid) === TypeCustomFields.NUMERIC &&
									filter.values.find(
										(value) =>
											value.value.toString() === cus.field.numeric.toString() ||
											(value.value.toString().toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase() &&
												cus.field.numeric === -1)
									)) ||
								(this.getCustomFieldType(cus.uid) === TypeCustomFields.MULTI_SELECT &&
									filter.values.find(
										(value) =>
											cus.field.multiLanguageSelectArray
												.map((opt) => opt[this.event.language].toString().toLowerCase())
												.includes(value.value.toString().toLowerCase()) ||
											(value.value.toString().toLowerCase() ===
												this.STranslate.instant("filter.not_specified").toLowerCase() &&
												cus.field.multiLanguageSelectArray.map(
													(opt) => opt[this.event.language]
												).length === 0)
									)))
					);

					if (custom) {
						if (
							!datas.find(
								(data) =>
									((this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
										this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT) &&
										data.toString().toLowerCase() ===
											custom.field.multiLanguageText[this.event.language]
												.toString()
												.toLowerCase()) ||
									((this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
										this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL) &&
										data.toString().toLowerCase() === custom.field.text.toLowerCase()) ||
									(this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC &&
										data.toString() === custom.field.numeric.toString()) ||
									(this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
										custom.field.multiLanguageSelectArray
											?.map((opt: ILanguage) => {
												return opt[this.event.language].toString().toLowerCase();
											})
											.some((dat: string) => dat.toString() === data.toString().toLowerCase()))
							)
						)
							this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
							this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT
								? datas.push(custom.field.multiLanguageText[this.event.language])
								: this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
								  this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL
								? datas.push(custom.field.text)
								: this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC
								? datas.push(custom.field.numeric.toString())
								: custom.field.multiLanguageSelectArray.length > 0 &&
								  datas.push(
										custom.field.multiLanguageSelectArray
											?.map((opt: ILanguage) => {
												return opt[this.event.language].toString().toLowerCase();
											})
											.join("*")
								  );
						if (
							(((this.getCustomFieldType(custom.uid) === TypeCustomFields.TEXT ||
								this.getCustomFieldType(custom.uid) === TypeCustomFields.SELECT) &&
								custom.field.multiLanguageText[this.event.language] === "") ||
								((this.getCustomFieldType(custom.uid) === TypeCustomFields.URL ||
									this.getCustomFieldType(custom.uid) === TypeCustomFields.EMAIL) &&
									custom.field.text === "") ||
								(this.getCustomFieldType(custom.uid) === TypeCustomFields.NUMERIC &&
									custom.field.numeric === -1) ||
								(this.getCustomFieldType(custom.uid) === TypeCustomFields.MULTI_SELECT &&
									custom.field.multiLanguageSelectArray?.map((option) => option[this.event.language])
										.length === 0)) &&
							!datas.find(
								(data) =>
									data.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
							)
						)
							datas.push(this.STranslate.instant("filter.not_specified"));

						matches++;
					}
				});

				if (matches === this.sessionsFiltered.length) {
					// Flat arrays and remove duplicated items
					const splitedDatas = [
						...new Set(
							datas.map((dat) => dat.toString().split("*")).reduce((acc, val) => acc.concat(val), [])
						)
					];

					filter.values
						.map((fil) => fil.value !== "-1" && fil)
						.filter((fil) => {
							if (
								splitedDatas
									.filter((dat) => dat)
									.findIndex(
										(dat) => dat?.toString().toLowerCase() === fil.value?.toString().toLowerCase()
									) === -1
							) {
								fil.isReachable = false;
								return true;
							} else {
								filter.strictSelectionMode &&
								fil.value.toString().toLowerCase() ===
									this.STranslate.instant("filter.not_specified").toLowerCase()
									? (fil.isReachable = false)
									: (fil.isReachable = true);
								return false;
							}
						});
				}
			});
		} else {
			// Back to initial state (every checkbox are reachable)

			// Locations fields
			this.filters.locations.forEach((location) => (location.isReachable = true));

			// Tracks fields
			this.filters.tracks.forEach((track) => (track.isReachable = true));

			// Custom fields
			this.filters.customFields.forEach((item) => {
				item.values.forEach((data) => {
					item.strictSelectionMode &&
					data.value.toString().toLowerCase() ===
						this.STranslate.instant("filter.not_specified").toLowerCase()
						? (data.isReachable = false)
						: (data.isReachable = true);
				});
			});
		}
	}

	/**
	/**
	 * getCustomFieldType
	 * @description return type of custom field based on the uid
	 * @param uid
	 * @return number
	 */
	getCustomFieldType(uid: string): number {
		return this.computedCustomFields.find((computedCustomField) => computedCustomField.baseSettings.uid === uid)
			?.baseSettings.type;
	}

	/**
	 * filterIsActivated
	 * @description return boolean on whether the given filter has a selected data in it or not
	 * @param filter
	 * @returns filter
	 */
	filterIsActivated(filterName: string, customFieldId?: string) {
		switch (filterName) {
			case "locations":
				return this.filters.locations.some((location) => location.checked);

			case "tracks":
				return this.filters.tracks.some((track) => track.checked);

			case "groups":
				return this.filters.groups.some((group) => group.checked);

			case "customs-fields":
				return customFieldId
					? this.filters.customFields.some((cus) =>
							cus.values.some((value) => value.filterId === customFieldId && value.isSelected)
					  )
					: this.filters.customFields.some((cus) => cus.values.some((value) => value.isSelected));
		}
	}

	/**
	 * canShowFilterBloc
	 * @description return true if the filter is at least one custom field that can be filtered
	 * @returns boolean
	 */
	canShowFilterBloc() {
		return (
			(this.filters.locations.length > 0 && this.module.options.baseFields.location.filter) ||
			(this.filters.tracks.length > 0 && this.module.options.baseFields.track.filter) ||
			(this.filters.groups.length > 0 && this.module.options.baseFields.group.filter) ||
			this.filters.customFields.length > 0
		);
	}

	/**
	 * clearFilter
	 * @description remove checked option from the given filter
	 * @param filter
	 */
	clearFilter(filterName: string, customFieldId?: string) {
		switch (filterName) {
			case "locations":
				this.filters.locations.forEach((location) => (location.checked = false));
				break;

			case "tracks":
				this.filters.tracks.forEach((track) => (track.checked = false));
				break;

			case "groups":
				this.filters.groups.forEach((group) => (group.checked = false));
				break;

			case "customs-fields":
				return customFieldId
					? this.filters.customFields.forEach((cus) =>
							cus.values.forEach(
								(value) => value.filterId === customFieldId && (value.isSelected = false)
							)
					  )
					: this.filters.customFields.forEach((cus) =>
							cus.values.forEach((value) => (value.isSelected = false))
					  );
		}
	}

	/**
	 * clearAllFilter
	 * @description remove all checked options from all filters
	 */
	clearAllFilter() {
		this.clearFilter("locations");
		this.clearFilter("tracks");
		this.clearFilter("customs-fields");
	}

	/**
	 * changeSelectionMode
	 * @param filterItem
	 */
	changeSelectionMode(filterItem: IFilteredItemFormat) {
		this.clearFilter("customs-fields", filterItem.uid);

		// Disable the "not_specified" field
		const notSpecifierValue = filterItem.values.find(
			(v) => v.value.toLowerCase() === this.STranslate.instant("filter.not_specified").toLowerCase()
		);
		if (notSpecifierValue) {
			!filterItem.strictSelectionMode && notSpecifierValue
				? (notSpecifierValue.isReachable = false)
				: (notSpecifierValue.isReachable = true);
		}

		// Change mode
		filterItem.strictSelectionMode = !filterItem.strictSelectionMode;
	}

	/**
	 * Get part of date
	 * @param date
	 * @param unit
	 * @returns
	 */
	getPartOfDate(date: string, unit: string) {
		return this.SUtility.getPartOfDate(this.event, this.eventUser, date, unit);
	}

	/**
	 * Check session date and given date
	 * @param session
	 * @param date
	 * @returns
	 */
	checkSessionDate(session: ISchedule, date: string) {
		return this.compareDates(session.startDate, date) ? true : false;
		// return DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(date), "day") &&
		// 	DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(date), "year") &&
		// 	DateTime.fromISO(session.startDate).hasSame(DateTime.fromISO(date), "month")
		// 	? true
		// 	: false;
	}

	/**
	 * Get first track for session
	 * @param session
	 * @returns
	 */
	getFirstTrackForSession(session: ISchedule) {
		return session.tracks.length > 0 ? this.tracks.find((track) => track.uid === session.tracks[0]) : null;
	}

	/**
	 * Get specific track
	 * @param uid
	 */
	getSpecificTrack(uid: string) {
		return this.tracks.find((track) => track.uid === uid);
	}

	/**
	 * Get specific location
	 * @param uid
	 */
	getSpecificLocation(uid: string) {
		return this.locations.find((location) => location.uid === uid);
	}

	/**
	 * Slide previous
	 */
	// previousDatesSlide() {
	// 	this.datesSwiper.nativeElement.swiper.slidePrev();
	// }

	/**
	 * Slide next
	 */
	// nextDatesSlide() {
	// 	this.datesSwiper.nativeElement.swiper.slideNext();
	// }

	/**
	 * Get all custom fields tags for session
	 * @param session
	 */
	getTagsForSession(session: ISchedule, eventUsersFound: IEventUser[]) {
		if (session) {
			let sessionCustomFields: IFullCustomField[] = [];
			const computedCus = this.computedCustomFields
				.filter((custom) => {
					const sessionFieldData = session.customFields.find(
						(sessionFieldData) =>
							custom.baseSettings &&
							custom.moduleSettings &&
							sessionFieldData.uid === custom.baseSettings.uid &&
							custom.moduleSettings.moduleId === this.moduleId
					);

					return custom.baseSettings &&
						custom.moduleSettings &&
						custom.moduleSettings.canBeTag &&
						sessionFieldData &&
						this.SCustomFields.checkValueCustomField(
							custom.baseSettings.type,
							sessionFieldData,
							this.currentLanguage
						)
						? true
						: false;
				})
				.sort((a, b) => {
					if (a.baseSettings && a.moduleSettings && b.baseSettings && b.moduleSettings) {
						return this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) >
							this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? 1
							: this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) <
							  this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? -1
							: 0;
					}
				});

			sessionCustomFields = _.cloneDeep(computedCus);

			sessionCustomFields.forEach((sessionCustomField) => {
				const sessionFieldData = session.customFields.find(
					(sessionFieldData) => sessionFieldData.uid === sessionCustomField.baseSettings.uid
				);

				if (sessionCustomField.baseSettings.type !== TypeCustomFields.MODULE) {
					if (
						sessionCustomField.baseSettings.type === TypeCustomFields.URL ||
						sessionCustomField.baseSettings.type === TypeCustomFields.EMAIL
					) {
						sessionCustomField.fieldDatas.field.text = sessionFieldData.field.text;
					} else if (
						sessionCustomField.baseSettings.type === TypeCustomFields.TEXT ||
						sessionCustomField.baseSettings.type === TypeCustomFields.SELECT ||
						sessionCustomField.baseSettings.type === TypeCustomFields.HMTL
					) {
						sessionCustomField.fieldDatas.field.multiLanguageText =
							sessionFieldData.field.multiLanguageText;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.MULTI_SELECT) {
						sessionCustomField.fieldDatas.field.multiLanguageSelectArray =
							sessionFieldData.field.multiLanguageSelectArray;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.MULTI_TEXT) {
						sessionCustomField.fieldDatas.field.multiLanguageTextArray =
							sessionFieldData.field.multiLanguageTextArray;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.NUMERIC) {
						sessionCustomField.fieldDatas.field.numeric = sessionFieldData.field.numeric;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.DATE) {
						sessionCustomField.fieldDatas.field.date = sessionFieldData.field.date;
					} else if (sessionCustomField.baseSettings.type === TypeCustomFields.PHONE) {
						sessionCustomField.fieldDatas.field.phoneNumber = sessionFieldData.field.phoneNumber;
					}
				} else {
					if (
						sessionCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER
					) {
						const eventUsers = this.getEventUsersOfSession(session, sessionCustomField, eventUsersFound);
						eventUsers.forEach((eventUser) => {
							eventUser["tags"] = this.getTagsOfEventUser(eventUser);
						});
						sessionCustomField.fieldDatas.field.module.itemsDatas = eventUsers;
						sessionCustomField.fieldDatas.field.module.items = eventUsers.map((eventUser) => eventUser.uid);
						sessionCustomField.fieldDatas.field.module.itemsIdentifier = eventUsers.map(
							(eventUser) => eventUser.identifier
						);
					} else if (sessionCustomField.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) {
						sessionCustomField.fieldDatas.field.module.itemsDatas = this.getDocumentsOfSession(
							session,
							sessionCustomField
						);
						sessionCustomField.fieldDatas.field.module.items = this.getDocumentsOfSession(
							session,
							sessionCustomField
						)?.map((doc) => doc.uid);
						sessionCustomField.fieldDatas.field.module.itemsIdentifier = this.getDocumentsOfSession(
							session,
							sessionCustomField
						)?.map((doc) => doc.name[this.currentLanguage]);
					}
				}
			});

			return sessionCustomFields;
		}
	}

	/**
	 * getRepeatedTagsForGroupTrack
	 * @param groupTrack
	 * @returns
	 */
	getRepeatedTagsForGroupTrack(groupTrack: IScheduleByTrack): IFullCustomField[] {
		const repeatedTags: IFullCustomField[] = [];
		let sessions = groupTrack.sessions;
		const allSessionTags = sessions
			.map((session) => this.getTagsForSession(session, this.storedEventUsersDatas))
			.filter((tags) => tags.length > 0);

		for (const session of sessions) {
			const sessionTags = this.getTagsForSession(session, this.storedEventUsersDatas);
			const tempSessionTags = sessionTags.filter(
				(tag) => tag.moduleSettings.canBeTag && tag.fieldDatas.field.module.itemsDatas.length > 0
			);

			if (tempSessionTags.length > 0) {
				tempSessionTags.forEach((tag) => {
					// only keep tags that are present in all sessions
					if (repeatedTags.length === 0) {
						repeatedTags.push(...tempSessionTags);
					}

					if (
						allSessionTags.every((sessionTag) =>
							sessionTag.every((t) => t.fieldDatas.field.module.items.includes(tag.baseSettings.uid))
						)
					) {
						repeatedTags.splice(repeatedTags.indexOf(tag), 1);
					}
				});
			}
		}

		return repeatedTags;
	}

	/**
	 * getEventUsersFromTags
	 * @param tags
	 * @returns
	 */
	getEventUsersFromTags(groupTracks: IScheduleByTrack[]): { eventUsers: IEventUser[]; track: ITrack }[] {
		const datas: { eventUsers: IEventUser[]; track: ITrack }[] = [];
		for (const groupTrack of groupTracks) {
			const eventUsers: IEventUser[] = [];
			const tags = this.getRepeatedTagsForGroupTrack(groupTrack);
			const sessions = groupTrack.sessions;

			const allSessionTags = sessions
				.map((session) => this.getTagsForSession(session, this.storedEventUsersDatas))
				.filter((tags) => tags.length > 0);

			for (const tag of tags) {
				if (
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					(tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER)
				) {
					for (const item of tag.fieldDatas.field.module.itemsDatas) {
						if (
							allSessionTags.every((sessionTag) =>
								sessionTag.some((t) => t.fieldDatas.field.module.items.includes(item.uid))
							)
						) {
							item["tagName"] =
								tag.baseSettings.name[
									this.eventUser && this.eventUser.updatedSettings.language
										? this.eventUser.updatedSettings.language
										: this.event.language
								];

							eventUsers.push(item as IEventUser);
						}
					}
				}
			}

			datas.push({ eventUsers: eventUsers, track: groupTrack.track });
		}

		return datas;
	}

	/**
	 * getRepeatedTagsByGroupTraks
	 * @param groupTrack
	 * @returns
	 */
	getRepeatedTagsByGroupTraks(groupTrack: IScheduleByTrack): any {
		const eventUsers = this.eventUsersFromTags.find(
			(data) => data.track && groupTrack.track && data.track.uid === groupTrack.track.uid
		);

		if (eventUsers) {
			const groupedEventUsers = _.groupBy(eventUsers.eventUsers, "tagName");

			// remove all from groupTrack.sessions that are in groupedEventUsers
			groupTrack.sessions.forEach((session) => {
				if (session?.["tags"]) {
					session["tags"].forEach((tag: IFullCustomField) => {
						tag.fieldDatas.field.module.itemsDatas = (
							tag.fieldDatas.field.module.itemsDatas as IEventUser[]
						).filter(
							(item) =>
								!groupedEventUsers?.[tag.baseSettings.name[this.currentLanguage]]?.find(
									(i) => i.uid === item.uid
								)
						) as IEventUser[];
					});
				}
			});

			return Object.keys(groupedEventUsers).map((key) => {
				return groupedEventUsers[key];
			});
		} else {
			return [];
		}
	}

	getEventUsersUidsForSessions(sessions: ISchedule[]) {
		const eventUsersUidsToFind: string[] = [];
		for (let i = 0; i < sessions.length; i++) {
			const session = sessions[i];
			let sessionCustomFields: IFullCustomField[] = [];
			const computedCus = this.computedCustomFields
				.filter((custom) => {
					const sessionFieldData = session.customFields.find(
						(sessionFieldData) =>
							custom.baseSettings &&
							custom.moduleSettings &&
							sessionFieldData.uid === custom.baseSettings.uid &&
							custom.moduleSettings.moduleId === this.moduleId
					);

					return custom.baseSettings &&
						custom.moduleSettings &&
						custom.moduleSettings.canBeTag &&
						sessionFieldData &&
						this.SCustomFields.checkValueCustomField(
							custom.baseSettings.type,
							sessionFieldData,
							this.currentLanguage
						)
						? true
						: false;
				})
				.sort((a, b) => {
					if (a.baseSettings && a.moduleSettings && b.baseSettings && b.moduleSettings) {
						return this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) >
							this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? 1
							: this.getCustomFieldOrder(a.baseSettings.uid, this.moduleId) <
							  this.getCustomFieldOrder(b.baseSettings.uid, this.moduleId)
							? -1
							: 0;
					}
				});

			sessionCustomFields = _.cloneDeep(computedCus);
			for (let i = 0; i < sessionCustomFields.length; i++) {
				const sessionCustomField = sessionCustomFields[i];

				if (
					sessionCustomField.baseSettings.type === TypeCustomFields.MODULE &&
					(sessionCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER)
				) {
					const customFieldsSession = session.customFields
						.slice()
						.filter(
							(cus) =>
								sessionCustomField.baseSettings.uid === cus.uid &&
								sessionCustomField.baseSettings.type === TypeCustomFields.MODULE &&
								(sessionCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
									sessionCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER) &&
								cus.field.module.items.length > 0
						);

					for (const cus of customFieldsSession) {
						for (const itemId of cus.field.module.items) {
							if (!eventUsersUidsToFind.includes(itemId)) {
								eventUsersUidsToFind.push(itemId);
							}
						}
					}
				}
			}
		}

		return eventUsersUidsToFind;
	}

	/**
	 * Get tag value
	 * @param session
	 * @param tag
	 */
	getTagValue(session: ISchedule, tag: IFullCustomField) {
		const fieldData = session.customFields.find((fieldData) => fieldData.uid === tag.baseSettings.uid);

		if (tag.baseSettings.type !== TypeCustomFields.MODULE) {
			return this.SCustomFields.getValueForCustomField(
				tag.baseSettings.type,
				fieldData,
				this.event?.language,
				this.eventUser?.updatedSettings.language
			);
		}
	}

	/**
	 * getEventUsersOfSession
	 * @param session
	 * @param tag
	 */
	getEventUsersOfSession(session: ISchedule, tag: IFullCustomField, eventUsers: IEventUser[]) {
		const itemIds: string[] = [];
		const users: IEventUser[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter(
				(cus) =>
					tag.baseSettings.uid === cus.uid &&
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					(tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
						tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER) &&
					cus.field.module.items.length > 0
			);

		for (const cus of customFieldsSession) {
			for (const itemId of cus.field.module.items) {
				if (!itemIds.includes(itemId)) {
					itemIds.push(itemId);

					const user = eventUsers.find((user) => user.uid === itemId);
					user && users.push(user);
				}
			}
		}

		return users;
	}

	/**
	 * getDocumentsOfSession
	 * @param session
	 * @param tag
	 */
	getDocumentsOfSession(session: ISchedule, tag: IFullCustomField) {
		const itemIds: string[] = [];
		const documents: IDocument[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter(
				(cus) =>
					tag.baseSettings.uid === cus.uid &&
					tag.baseSettings.type === TypeCustomFields.MODULE &&
					cus.field.module.items.length > 0
			);

		for (const cus of customFieldsSession) {
			for (const itemId of cus.field.module.items) {
				if (!itemIds.includes(itemId)) {
					itemIds.push(itemId);

					if (tag.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) {
						const document = this.documents.find((document) => document.uid === itemId);
						document && documents.push(document);
					}
				}
			}
		}

		return documents;
	}

	/**
	 * getNumberOfAttendeeModuleOfSession
	 * @param session
	 * @param tag
	 */
	getNumberOfItemModuleOfSession(session: ISchedule) {
		const customFieldIds: string[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter((cus) =>
				this.computedCustomFields.find(
					(computedCustomField) =>
						computedCustomField.moduleSettings.moduleId === this.moduleId &&
						computedCustomField.baseSettings.uid === cus.uid &&
						computedCustomField.baseSettings.type === TypeCustomFields.MODULE &&
						(computedCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.DOCUMENT) &&
						cus.field?.module?.items.length > 0
				)
			);

		customFieldsSession.forEach((cus) => {
			const customField = this.computedCustomFields.find(
				(computedCustomField) =>
					computedCustomField.moduleSettings.moduleId === this.moduleId &&
					computedCustomField.baseSettings.uid === cus.uid
			);

			if (customField && !customFieldIds.includes(customField.baseSettings.uid)) {
				customFieldIds.push(customField.baseSettings.uid);
			}
		});

		return customFieldIds.length;
	}

	/**
	 * checkEventUsersModule
	 * @param tag
	 * @returns
	 */
	checkEventUsersModule(tag: IFullCustomField) {
		return (
			tag.baseSettings.type === TypeCustomFields.MODULE &&
			(tag.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
				tag.baseSettings.customFieldModuleType === TypeModule.SPEAKER)
		);
	}

	/**
	 * getTagsOfEventUser
	 * @description returns tags of eventuser
	 * @param eventUser
	 */
	getTagsOfEventUser(eventUser: IEventUser): ICustomFieldData[] {
		return (
			eventUser.customFields &&
			eventUser.customFields
				.filter((cus) => {
					return this.computedCustomFields.some(
						(computedCustomField) =>
							computedCustomField &&
							computedCustomField.baseSettings &&
							computedCustomField.moduleSettings &&
							computedCustomField.baseSettings.uid === cus.uid &&
							computedCustomField.moduleSettings.moduleId === eventUser.moduleId &&
							computedCustomField.moduleSettings.canBeTag &&
							((cus.field.multiLanguageText && cus.field.multiLanguageText[this.event.language] !== "") ||
								(cus.field.text && cus.field.text !== ""))
					);
				})
				.sort((a, b) =>
					this.getCustomFieldOrder(a.uid, eventUser.moduleId) >
					this.getCustomFieldOrder(b.uid, eventUser.moduleId)
						? 1
						: this.getCustomFieldOrder(a.uid, eventUser.moduleId) <
						  this.getCustomFieldOrder(b.uid, eventUser.moduleId)
						? -1
						: 0
				)
		);
	}

	/**
	 * getNumberOfEventUsersTypeOfSession
	 * @description returns differents type of eventusers modules
	 * @param session
	 */
	getNumberOfEventUsersTypeOfSession(session: ISchedule): number {
		const customFieldIds: string[] = [];

		const customFieldsSession = session.customFields
			.slice()
			.filter((cus) =>
				this.computedCustomFields.find(
					(computedCustomField) =>
						computedCustomField &&
						computedCustomField.baseSettings &&
						computedCustomField.moduleSettings &&
						computedCustomField.moduleSettings.moduleId === this.moduleId &&
						computedCustomField.baseSettings.uid === cus.uid &&
						computedCustomField.baseSettings.type === TypeCustomFields.MODULE &&
						(computedCustomField.baseSettings.customFieldModuleType === TypeModule.ATTENDEE ||
							computedCustomField.baseSettings.customFieldModuleType === TypeModule.SPEAKER) &&
						cus.field?.module?.items.length > 0
				)
			);

		customFieldsSession.forEach((cus) => {
			const customField = this.computedCustomFields.find(
				(computedCustomField) =>
					computedCustomField &&
					computedCustomField.baseSettings &&
					computedCustomField.moduleSettings &&
					computedCustomField.moduleSettings.moduleId === this.moduleId &&
					computedCustomField.baseSettings.uid === cus.uid
			);

			if (customField && !customFieldIds.includes(customField.baseSettings.uid)) {
				customFieldIds.push(customField.baseSettings.uid);
			}
		});

		return customFieldIds.length;
	}

	/**
	 * getCustomFieldOrder
	 */
	getCustomFieldOrder(customId: string, moduleId: string): number {
		return this.computedCustomFields.find(
			(cus) =>
				cus &&
				cus.baseSettings &&
				cus.moduleSettings &&
				cus.baseSettings.uid === customId &&
				cus.moduleSettings.moduleId === moduleId
		).moduleSettings.order;
	}

	/**
	 * checkDocumentsModule
	 * @param tag
	 * @returns boolean
	 */
	checkDocumentsModule(tag: IFullCustomField) {
		return (
			tag.baseSettings.type === TypeCustomFields.MODULE &&
			tag.baseSettings.customFieldModuleType === TypeModule.DOCUMENT
		);
	}

	/**
	 * Go to session
	 * @param session
	 */
	openSession(session: ISchedule) {
		if (!this.queryParams || this.queryParams["nav"] === undefined || this.queryParams["nav"] === "true") {
			this.navCtrl.navigateForward(
				`/event/${this.eventId}/schedule/${this.folder.moduleLinkedId}/session/${session.uid}`,
				{
					queryParams: this.queryParams
				}
			);
		}
	}

	/**
	 * openDownloadModal
	 * @param session
	 */
	async openDownloadModal(session: ISchedule, event, module?: IModule) {
		try {
			event.stopPropagation();
			const modal = await this.modalController.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					eventUser: this.eventUser,
					session: session,
					sessions: this.sessions,
					language:
						this.eventUser && this.eventUser.updatedSettings.language
							? this.eventUser.updatedSettings.language
							: this.event.language,
					mode: "session"
				},
				mode: "ios",
				canDismiss: true,
				cssClass: this.isMobile ? "confirm-ics-download-modal-mobile" : "confirm-ics-download-modal"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	async openDownloadScheduleModal() {
		try {
			event.stopPropagation();
			const modal = await this.modalController.create({
				component: ConfirmIcsDownloadComponent,
				componentProps: {
					event: this.event,
					module: this.module,
					language:
						this.eventUser && this.eventUser.updatedSettings.language
							? this.eventUser.updatedSettings.language
							: this.event.language,
					mode: "schedule"
				},
				mode: "ios",
				canDismiss: true,
				cssClass: this.isMobile
					? "confirm-ics-download-modal-mobile-schedule"
					: "confirm-ics-download-modal-schedule"
			});

			modal.present();
		} catch (error) {
			// snackbar error
		}
	}

	// Generate ics file part

	/**
	 * Generate ics file
	 * @param session
	 * @returns
	 */
	generateIcsFile(session: ISchedule) {
		this.sessionLoader = true;
		this.setIcsLoaderState(session.uid, true);
		// Implement a loader
		if (session) {
			const currentDate = new Date();
			const offsetHours = currentDate.getTimezoneOffset() / 60;
			this.SSchedules.generateIcsFile(
				this.eventId,
				this.moduleId,
				session.uid,
				this.event.language,
				offsetHours
			).subscribe((res) => {
				if (res && res["message"] === "success") {
					const sessionNameWithExtension = (session.name[this.event.language] as string) + ".ics";

					let sessionName = sessionNameWithExtension.toLowerCase();
					sessionName = sessionName.split(/[ ,]+/).join("_");
					sessionName = sessionName.split(/[ ']+/).join("");
					this.downloadFile(sessionName, res["result"], session.uid);
				}
			});
		}
	}

	/**
	 * Link session date to google calendar
	 * @param session
	 */
	linkEventToGoogleCalendar(session: ISchedule) {
		if (session) {
			const googleLinkPrefix = "https://calendar.google.com/calendar/render?action=TEMPLATE&";
			const currentDate = new Date();
			const offsetHours = currentDate.getTimezoneOffset() / 60;
			this.SSchedules.generateIcsFile(
				this.eventId,
				this.moduleId,
				session.uid,
				this.event.language,
				offsetHours
			).subscribe(async (res) => {
				if (res && res["message"] === "success") {
					const icsData: string = res["result"];
					// formating data for the link
					let splitedData = icsData.split("\n");
					splitedData = splitedData.map((s) => s.replace("\r", ""));
					const arrayTimestampStart = splitedData.filter((s) => s.startsWith("DTSTART"));
					const timestampStart = arrayTimestampStart[0].split(":")[1];
					const arrayTimestampEnd = splitedData.filter((s) => s.startsWith("DTEND"));
					const timestampEnd = arrayTimestampEnd[0].split(":")[1];

					const sessionNameEncoded = session.name[this.event.language]
						? encodeURI(session.name[this.event.language])
						: "";

					const sessionDescriptionEncoded =
						session.description && session.description[this.event.language]
							? encodeURI(session.description[this.event.language])
							: "";

					const datesEncoded = encodeURI(timestampStart + "/" + timestampEnd);

					const link =
						googleLinkPrefix +
						"dates=" +
						datesEncoded +
						"&text=" +
						sessionNameEncoded +
						"&details=" +
						sessionDescriptionEncoded;

					if (this.isMobile) {
						try {
							await Browser.open({
								url: link
							});
						} catch (error) {
							// ~ SchedulesComponent ~ ).subscribe ~ error:", error);
						}
					} else {
						window.open(link, "_blank");
					}
				}
			});
		}
	}

	/**
	 * Search bar
	 * @param ev
	 */
	searchBar(ev) {
		if (ev.target.value.length >= 1) {
			const value = this.SUtility.removeAccents(ev.target.value.toLowerCase());
			this.searchBarValue = value;
			// this.searchValue = value;
			// this.sessionsFiltered = [];
			this.filterByDate();
			const tmpSessionsFiltered = [...this.sessions].filter((session) => {
				let check: boolean = false;
				for (const lang of Object.keys(session.name)) {
					if (
						session.name[lang] &&
						this.SUtility.removeAccents(session.name[lang].toLowerCase()).includes(value)
					) {
						check = true;
					}
				}
				return check;
			});
			if (tmpSessionsFiltered.length === 0) {
				this.showNoResultImage = true;
			}
		} else {
			this.resetFilter();
		}
	}

	/**
	 * openFilterModal
	 */
	async openMobileFilterModal() {
		// this.loader = true;
		const filterModal = await this.modalController.create({
			component: FilterComponent,
			componentProps: {
				event: this.event,
				module: this.module,
				language: this.currentLanguage,
				sessions: this.sessions,
				sessionsByTrackFiltered: this.sessionsByTrackFiltered,
				sessionsFiltered: this.sessionsFiltered,
				customFields: this.computedCustomFields,
				schedulesFilters: this.filters,
				selectedFilters: this.selectedFilters,
				notSelectedCustomFieldFilters: this.notSelectedCustomFieldFilters,
				notSelectedLocationDatas: this.notSelectedLocationDatas,
				notSelectedTrackDatas: this.notSelectedTrackDatas,
				selectedDate: this.selectedDate,
				tracks: this.tracks,
				locations: this.locations,
				groups: this.groups,
				eventUsers: []
			},
			cssClass: "filter-modal-css",
			showBackdrop: true,
			canDismiss: true,
			// swipeToClose: true,
			presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
		});

		await filterModal.present();

		const { data } = await filterModal.onWillDismiss();

		if (data) {
			(this.filters = data.schedulesFilters),
				(this.sessionsByTrackFiltered = data.sessionsByTrackFiltered),
				(this.sessionsFiltered = data.sessionsFiltered),
				// (this.computedCustomFields = data.computedCustomFields),
				(this.selectedFilters = data.selectedFilters),
				(this.notSelectedCustomFieldFilters = data.notSelectedCustomFieldFilters),
				(this.notSelectedLocationDatas = data.notSelectedLocationDatas),
				(this.notSelectedTrackDatas = data.notSelectedTrackDatas);
		}
	}

	/**
	 * Reset filter
	 */
	resetFilter() {
		this.searchBarValue = "";
		this.sessionsFiltered = this.sessions.length === 0 ? [] : [...this.sessions];
		this.filterByDate();
		this.showNoResultImage = false;
	}

	/**
	 * Open timezone switch modal
	 */
	async openMobileTimezoneSwitchModal() {
		// this.loader = true;
		const timezoneSwitchModal = await this.modalController.create({
			component: TimezoneSwitchComponent,
			componentProps: {
				event: this.event
			},
			cssClass: "filter-modal-css",
			showBackdrop: true,
			presentingElement: await this.modalController.getTop() // Get the top-most ion-modal
		});

		await timezoneSwitchModal.present();
	}

	/**
	 * Download schedule sessions ics
	 */
	downloadSchedule(event?: any) {
		// Implement a loader
		event.stopPropagation();
		this.scheduleDownloadButtonLoader = true;
		this.SSchedules.generateSessionsIcsFileFromClient(this.module, this.sessions, this.event.language);
		this.scheduleDownloadButtonLoader = false;
	}

	/**
	 * Download a file
	 * @param filename
	 * @param text
	 */
	async downloadFile(filename: string, text, sessionId?: string) {
		try {
			if (!this.isMobile || this.platform.is("mobileweb")) {
				const element = document.createElement("a");
				element.setAttribute("href", "data:text/calendar;charset=utf-8," + encodeURIComponent(text));
				element.setAttribute("download", filename);
				element.setAttribute("target", "_blank");
				element.style.display = "none";
				element.click();
			} else {
				if (this.platform.is("ios")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: filename,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				} else if (this.platform.is("android")) {
					const fileWriteResult = await Filesystem.writeFile({
						path: `download/${filename}`,
						data: encodeURIComponent(text),
						directory: Directory.Cache,
						encoding: Encoding.UTF8,
						recursive: true
					});
					if (fileWriteResult && fileWriteResult.uri) this.SUtility.shareImage(fileWriteResult.uri);
				}
			}
			sessionId && this.setIcsLoaderState(sessionId, false);
			this.scheduleDownloadButtonLoader = false;
		} catch (error) {
			sessionId && this.setIcsLoaderState(sessionId, false);
			this.scheduleDownloadButtonLoader = false;
			this.SUtility.presentToast(
				this.STranslate.instant("toasts.errors.download_error"),
				2000,
				"bottom",
				"danger"
			);
		}
	}

	/**
	 * collapse
	 * @param index
	 */
	collapse(trackId: string) {
		this.groupTrackCollapseState.find((groupTrack) => groupTrack.uid === trackId).collapsed =
			!this.groupTrackCollapseState.find((groupTrack) => groupTrack.uid === trackId).collapsed;
		// this.groupTrackCollapseState[index] = !this.groupTrackCollapseState[index];
	}

	getTrackCollapseState(trackId: string): boolean {
		return this.groupTrackCollapseState.find((groupTrack) => groupTrack.uid === trackId)
			? this.groupTrackCollapseState.find((groupTrack) => groupTrack.uid === trackId).collapsed
			: false;
	}

	/**
	 * collapse
	 * @param index
	 */
	collapseFilter(filterId: string) {
		this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed = !this.filtersCollapseState.find(
			(filter) => filter.uid === filterId
		).collapsed;
		// this.groupTrackCollapseState[index] = !this.groupTrackCollapseState[index];
	}

	/**
	 * getFilterCollapseState
	 */
	getFilterCollapseState(filterId: string): boolean {
		return this.filtersCollapseState.find((filter) => filter.uid === filterId)
			? this.filtersCollapseState.find((filter) => filter.uid === filterId).collapsed
			: false;
	}

	/**
	 * getLocationsOfGroupTrack
	 * @param groupTrack
	 * @returns ILocation
	 */
	getLocationsOfGroupTrack(groupTrack: IScheduleByTrack): ILocation[] {
		const groupTrackLocationIds: string[] = [];
		groupTrack.sessions.forEach((session) =>
			session.locations.forEach(
				(sessionLocId) =>
					!groupTrackLocationIds.includes(sessionLocId) && groupTrackLocationIds.push(sessionLocId)
			)
		);
		// m

		return this.locations.filter((loc) => groupTrackLocationIds.includes(loc.uid));
	}

	getYPosition(element): number {
		let yPosition = 0;

		while (element) {
			yPosition += element.offsetTop - element.scrollTop + element.clientTop;
			element = element.offsetParent;
		}
		return yPosition;
	}

	/**
	 * getIcsSessionLoaderState
	 * @param sessionId
	 * @returns
	 */
	getIcsSessionLoaderState(sessionId: string): boolean {
		return this.icsSessionLoader.find((data) => data.sessionId === sessionId)?.state;
	}

	/**
	 * setIcsLoaderState
	 * @param sessionId
	 * @param state
	 */
	setIcsLoaderState(sessionId: string, state: boolean): void {
		const index = this.icsSessionLoader.findIndex((ses) => ses.sessionId === sessionId);
		index !== -1 && (this.icsSessionLoader[index].state = state);
	}

	async addToFavorite(sessionId: string, event) {
		try {
			this.eventUserFavoritesState[sessionId] = true;

			event.stopPropagation();
			if (this.eventUser.favorites) {
				!this.eventUser.favorites.includes(sessionId) && this.eventUser.favorites.push(sessionId);
			} else {
				this.eventUser = {
					...this.eventUser,
					favorites: [sessionId]
				};
			}
			this.buildFavorites();
			await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
				favorites: this.eventUser.favorites
			});
			this.snackBar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
				duration: 3000,
				panelClass: ["snackbar-success"]
			});
		} catch (error) {
			// Snackbar error
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	async removeFromFavorite(sessionId: string, event) {
		try {
			this.eventUserFavoritesState[sessionId] = false;

			event.stopPropagation();

			if (this.eventUser.favorites) {
				this.eventUser.favorites.includes(sessionId) &&
					(this.eventUser.favorites = this.eventUser.favorites.filter((fav) => fav !== sessionId));
			} else {
				this.eventUser = {
					...this.eventUser,
					favorites: []
				};
			}
			this.buildFavorites();
			await this.SEventUsers.updatePartOfEventUser(this.eventId, this.eventUser.moduleId, this.eventUser.uid, {
				favorites: this.eventUser.favorites
			});

			// this.getScheduleSessions();
			this.snackBar.open(this.STranslate.instant("snackbar.update_successfull"), null, {
				duration: 3000,
				panelClass: ["snackbar-success"]
			});
		} catch (error) {
			console.error("🚀 ~ removeFromFavorite ~ error:", error);
			// Snackbar error
			this.snackBar.open(this.STranslate.instant("snackbar.error_occured"), null, {
				duration: 3000,
				panelClass: ["snackbar-error"]
			});
		}
	}

	/**
	 * checkFavorite
	 * @param eventUserId
	 * @returns
	 */
	buildFavorites() {
		this.allSessions.forEach((session) => {
			if (this.eventUser.favorites?.includes(session.uid)) {
				this.eventUserFavoritesState = {
					...this.eventUserFavoritesState,
					[session.uid]: true
				};
			} else {
				this.eventUserFavoritesState = {
					...this.eventUserFavoritesState,
					[session.uid]: false
				};
			}
		});

		if (this.sessionsFiltered && this.sessionsFiltered.length > 0) {
			this.sessionsFiltered = this.sessionsFiltered.filter((session) => {
				return this.eventUser.favorites?.includes(session.uid);
			});
		}
	}
}
