import { createAction, props } from "@ngrx/store";
import { ICustomPage } from "../interfaces/custom-pages.interfaces";

export enum CustomPagesActionsTypes {
	GetAllCustomPages = "[CustomPages Service] Get all custom page",
	ResetCustomPagesState = "[CustomPages Service] Reset customPages state"
}

export const GetAllCustomPages = createAction(
	CustomPagesActionsTypes.GetAllCustomPages,
	props<{ payload: ICustomPage[]; eventId: string }>()
);

export const ResetCustomPagesState = createAction(CustomPagesActionsTypes.ResetCustomPagesState, props<any>());
