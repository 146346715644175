<!-- Blank (Required) Error field -->
<div
	*ngIf="
		SForm.getInputErrorType(
			$any(form.get(fieldType === 'singleLanguage' ? field.uid : field.uid + '_' + language))
		) === 'required'
	"
	class="form-bloc-error"
>
	<p class="error-label-text">
		{{ "edit-profil.errors.blank.simple_text" | translate }}
	</p>
</div>

<!-- Invalids format -->
<!-- (--minimum length error --) -->
<div
	*ngIf="
		SForm.getInputErrorType(
			$any(form.get(fieldType === 'singleLanguage' ? field.uid : field.uid + '_' + language))
		) === 'minlength'
	"
	class="form-bloc-error"
>
	<p class="error-label-text">
		{{ "edit-profil.errors.invalid.min_length" | translate }}&nbsp; ({{ SForm.minChar1 }})
		{{ "edit-profil.errors.characters" | translate }}&nbsp;
	</p>
</div>

<!-- (--maximum length error --) -->
<div
	*ngIf="
		SForm.getInputErrorType(
			$any(form.get(fieldType === 'singleLanguage' ? field.uid : field.uid + '_' + language))
		) === 'maxlength'
	"
	class="form-bloc-error"
>
	<p class="error-label-text">
		{{ "edit-profil.errors.invalid.multiple_text-too-long" | translate }} ({{ SForm.maxChar100 }})
	</p>
</div>

<!-- Patterns -->
<div
	*ngIf="
		SForm.getInputErrorType(
			$any(form.get(fieldType === 'singleLanguage' ? field.uid : field.uid + '_' + language))
		) === 'email'
	"
	class="form-bloc-error"
>
	<!-- EMAIL -->
	<p class="error-label-text">
		{{ "edit-profil.errors.invalid.email" | translate }}
	</p>
</div>

<!-- others -->
<div
	*ngIf="
		SForm.getInputErrorType(
			$any(form.get(fieldType === 'singleLanguage' ? field.uid : field.uid + '_' + language))
		) === 'pattern'
	"
	class="form-bloc-error"
>
	<!-- Url -->
	<p class="error-label-text">
		{{ "edit-profil.errors.invalid.generic" | translate }}
	</p>
</div>

<!-- Custom Validators -->

<!-- Multi-text -->
<div
	*ngIf="
		$any(field.value)?.baseSettings.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(field.uid))) as errorType
	"
	class="form-bloc-error"
>
	<p
		*ngIf="
			errorType === 'multiTextIsRequired' ||
			errorType === 'controlShouldNotContainValue' ||
			errorType === 'multiTextShouldContainValue'
		"
		class="error-label-text"
	>
		{{ form.get(field.uid)?.errors?.[errorType]?.['msg'] | translate }}
	</p>
</div>

<!-- Min/max dateValidators -->
<div
	*ngIf="
		$any(field.value)?.baseSettings.type === typeCustomFields.DATE &&
		SForm.getInputErrorType($any(form.get(field.uid))) as errorType
	"
	class="form-bloc-error"
>
	<p *ngIf="errorType === 'dateMaximum'" class="error-label-text">
		{{ form.get(field.uid)?.errors?.[errorType]?.['msg'] | translate:{maxDate: SLuxon.convertIsoDateToFormat($any(field.value)?.baseSettings?.dateRule?.maxDayDateIsEnabled ? dateTime.now().toISO() :  $any(field.value)?.baseSettings?.dateRule?.maxDate, language) } }}
	</p>

	<p *ngIf="errorType === 'dateMinimum'" class="error-label-text">
		{{ form.get(field.uid)?.errors?.[errorType]?.['msg'] | translate:{minDate: SLuxon.convertIsoDateToFormat($any(field.value)?.baseSettings?.dateRule?.minDayDateIsEnabled ? dateTime.now().toISO() :  $any(field.value)?.baseSettings?.dateRule?.minDate, language)} }}
	</p>
</div>

<!-- checkbox -->
<div
	*ngIf="
		$any(field.value)?.baseSettings.type === typeCustomFields.CHECKBOX &&
		SForm.getInputErrorType($any(form.get(field.uid))) as errorType
	"
	class="form-bloc-error"
>
	<p *ngIf="errorType === 'checkboxError'" class="error-label-text">
		{{ form.get(field.uid)?.errors?.[errorType]?.['msg'] | translate }}
	</p>
</div>
