import { createAction, props } from "@ngrx/store";
import { IExternalInteractivity } from "../interfaces";
import { IAskQuestions } from "../interfaces/ask-questions.interfaces";
import { IColorChart } from "../interfaces/color-charts.interfaces";
import { IQuiz, IQuizQuestionResult } from "../interfaces/quiz.interfaces";
import { ISurvey, ISurveyQuestionResult } from "../interfaces/surveys.interfaces";
import { IWordCloud } from "../interfaces/word-clouds.interfaces";

export enum InteractivityActionsTypes {
	GetAllAskQuestions = "[Interactivity Service] Get all ask questions",
	GetAllColorsCharts = "[Interactivity Service] Get all colors charts",
	GetAllExternalsInteractivity = "[Interactivity Service] Get all externals interactivity",
	GetAllQuizs = "[Interactivity Service] Get all quizs",
	GetAllQuizsResults = "[Interactivity Service] Get all quizs results",
	GetAllSurveys = "[Interactivity Service] Get all surveys",
	GetAllSurveysResults = "[Interactivity Service] Get all surveys results",
	GetAllWordClouds = "[Interactivity Service] Get all word clouds",
	ResetInteractivityState = "[Interactivity Service] Reset interactivity state"
}

export const GetAllAskQuestions = createAction(
	InteractivityActionsTypes.GetAllAskQuestions,
	props<{ payload: IAskQuestions[]; eventId: string }>()
);

export const GetAllColorsCharts = createAction(
	InteractivityActionsTypes.GetAllColorsCharts,
	props<{ payload: IColorChart[]; eventId: string }>()
);

export const GetAllExternalsInteractivity = createAction(
	InteractivityActionsTypes.GetAllExternalsInteractivity,
	props<{ payload: IExternalInteractivity[]; eventId: string }>()
);

export const GetAllQuizs = createAction(
	InteractivityActionsTypes.GetAllQuizs,
	props<{ payload: IQuiz[]; eventId: string }>()
);

export const GetAllQuizsResults = createAction(
	InteractivityActionsTypes.GetAllQuizsResults,
	props<{ payload: IQuizQuestionResult[] }>()
);

export const GetAllSurveys = createAction(
	InteractivityActionsTypes.GetAllSurveys,
	props<{ payload: ISurvey[]; eventId: string }>()
);

export const GetAllSurveysResults = createAction(
	InteractivityActionsTypes.GetAllSurveysResults,
	props<{ payload: ISurveyQuestionResult[] }>()
);

export const GetAllWordClouds = createAction(
	InteractivityActionsTypes.GetAllWordClouds,
	props<{ payload: IWordCloud[]; eventId: string }>()
);

export const ResetInteractivityState = createAction(InteractivityActionsTypes.ResetInteractivityState, props<any>());
