import { Injectable } from "@angular/core";
import { where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import { Subscription } from "rxjs";
import { take } from "rxjs/operators";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { GetWidgets } from "../actions/widgets.actions";
import { IWidget } from "../interfaces/widget.interfaces";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { checkSameEvent } from "../selectors/widgets.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class WidgetsService {
	widgetsSub: Subscription;

	constructor(private SFirestore: FirestoreService, private store: Store) {}

	unsubscribeAll() {
		if (this.widgetsSub && !this.widgetsSub.closed) {
			this.widgetsSub.unsubscribe();
		}
	}

	getAllWidgets(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.widgetsSub && !this.widgetsSub.closed) {
					return;
				} else if (!sameEvent && this.widgetsSub && !this.widgetsSub.closed) {
					this.widgetsSub.unsubscribe();
				}
				this.widgetsSub = this.SFirestore.collectionGroupValueChangesDocuments("widgets", [
					where("eventId", "==", eventId)
				]).subscribe((widgets: IWidget[]) => {
					this.store.dispatch(GetWidgets({ payload: widgets, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initWidgets"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(InitSpecificEventDatasPart({ part: "initWidgets", payload: true }));
							}
						});
				});
			});
	}
}
