<div class="filter_bloc">
	<div class="close-chip-bloc">
		<ion-chip class="filter-close-chip" (click)="onFilter()">
			<ion-label>{{ "texts.filter" | translate }}</ion-label>
		</ion-chip>
	</div>

	<!-- Reset filter button -->
	<div class="clear-chip-bloc">
		<ion-label class="filter-clear-chip" (click)="resetFilter()">
			{{ "alerts.clear-filter" | translate }}
		</ion-label>
	</div>


	<div class="delete-all-chip-bloc">
		<ion-chip *ngIf="selectedFilters.length > 0" color="danger" (click)="clearAllFilter()">
			<ion-label>{{ "filter.delete_all" | translate }}</ion-label>
			<ion-icon name="close-circle"></ion-icon>
		</ion-chip>
	</div>

	<!-- Schedules and event users filters -->
	<div class="filter-content"
		*ngIf="module.type === typeModule.SCHEDULE || module.type === typeModule.ATTENDEE || module.type === typeModule.SPEAKER"
		style="padding: 10px !important">
		<ion-chip *ngIf="
		filterIsActivated('groups') ||
		filterIsActivated('locations') ||
		filterIsActivated('tracks') ||
		filterIsActivated('customs-fields')
				" color="danger" (click)="clearAllFilter()">
			<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }}</ion-label>
			<ion-icon name="close-circle"></ion-icon>
		</ion-chip>
		<!-- Locations -->
		<div class="filter-content" style="padding: 10px !important"
			*ngIf="((module.options['baseFields'] && module.options['baseFields'].location && module.options['baseFields'].location.filter) || (module.options['requiredFields'] && module.options['requiredFields']['location'] && module.options['requiredFields']['location'].filter)) && filters.locations.length > 0">
			<div (click)="collapse('locations')"
				style="display: flex; justify-content: space-between; margin-top: 5px; margin-bottom: 5px; cursor: pointer;">
				<h5 class="filter-name" [style.color]="event.styling.titleColor">
					{{ module.options.locationName &&
					module.options.locationName[language] !== '' ?
					module.options.locationName[language] : "labels.locations" | translate }}:
				</h5>
				<ion-icon *ngIf="!getFilterCollapseState('locations')" src="assets/icon/ArrowUp_thin.svg"
					style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				<ion-icon *ngIf="getFilterCollapseState('locations')" src="assets/icon/ArrowDown_thin.svg"
					style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
			</div>

			<ion-list class="filter_bloc_values accordion-container"
				[class.collapsed]="getFilterCollapseState('locations')" [style.maxHeight]="'auto'">
				<ion-item lines="none" *ngFor="let locationItem of filters.locations">
					<ion-label class="filter-label">{{ locationItem.name }}</ion-label>
					<ion-checkbox color="dark" slot="end" [value]="locationItem.name" [(ngModel)]="locationItem.checked"
						[disabled]="!locationItem.isReachable">
					</ion-checkbox>
				</ion-item>
				<ion-chip *ngIf="filterIsActivated('locations')" color="danger" (click)="clearFilter('locations')">
					<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }} ({{
						module.options.locationName &&
						module.options.locationName[language] !== '' ?
						module.options.locationName[language] : "labels.locations"
						| translate }})
					</ion-label>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
			</ion-list>
		</div>

		<!-- Tracks -->
		<div class="filter-content" style="padding: 10px !important"
			*ngIf="((module.options['baseFields'] && module.options['baseFields'].track && module.options['baseFields'].track.filter) || (module.options['requiredFields'] && module.options['requiredFields']['track'] && module.options['requiredFields']['track'].filter)) && filters.tracks.length > 0">
			<div (click)="collapse('tracks')"
				style="display: flex; justify-content: space-between; margin-top: 5px; margin-bottom: 5px; cursor: pointer;">
				<h5 class="filter-name" [style.color]="event.styling.titleColor">{{
					module.options.trackName &&
					module.options.trackName[language] !== '' ?
					module.options.trackName[language] : "labels.tracks" | translate }}:</h5>
				<ion-icon *ngIf="!getFilterCollapseState('tracks')" src="assets/icon/ArrowUp_thin.svg"
					style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				<ion-icon *ngIf="getFilterCollapseState('tracks')" src="assets/icon/ArrowDown_thin.svg"
					style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
			</div>
			<ion-list class="accordion-container" [class.collapsed]="getFilterCollapseState('tracks')"
				[style.maxHeight]="'auto'">
				<ion-item lines="none" *ngFor="let trackItem of filters.tracks">
					<ion-label class="filter-label">{{ trackItem.name[language] }}</ion-label>
					<ion-checkbox color="dark" slot="end" [value]="trackItem.name[language]"
						[(ngModel)]="trackItem.checked" [disabled]="!trackItem.isReachable">
					</ion-checkbox>
				</ion-item>
				<ion-chip *ngIf="filterIsActivated('tracks')" color="danger" (click)="clearFilter('tracks')">
					<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }} ({{
						module.options.trackName &&
						module.options.trackName[language] !== '' ?
						module.options.trackName[language] : "labels.tracks" |
						translate }})</ion-label>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
			</ion-list>
		</div>

		<!-- Groups -->
		<div class="filter-content" style="padding: 10px !important"
			*ngIf="((module.options['baseFields'] && module.options['baseFields'].group && module.options['baseFields'].group.filter) || (module.options['requiredFields'] && module.options['requiredFields']['group'] && module.options['requiredFields']['group'].filter))  && filters.groups.length > 0">
			<div (click)="collapse('groups')"
				style="display: flex; justify-content: space-between; margin-top: 5px; margin-bottom: 5px; cursor: pointer;">
				<h5 class="filter-name" [style.color]="event.styling.titleColor">
					{{ "labels.groups" | translate }}:
				</h5>
				<ion-icon *ngIf="!getFilterCollapseState('groups')" src="assets/icon/ArrowUp_thin.svg"
					style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
				<ion-icon *ngIf="getFilterCollapseState('groups')" src="assets/icon/ArrowDown_thin.svg"
					style="position: relative; top: 6px; cursor: pointer">
				</ion-icon>
			</div>

			<ion-list class="accordion-container" [class.collapsed]="getFilterCollapseState('groups')"
				[style.maxHeight]="'auto'">
				<ion-item lines="none" *ngFor="let groupItem of filters.groups">
					<ion-label class="filter-label">{{ groupItem.name }}</ion-label>
					<ion-checkbox color="dark" slot="end" [value]="groupItem.name" [(ngModel)]="groupItem.checked"
						[disabled]="!groupItem.isReachable">
					</ion-checkbox>
				</ion-item>
				<ion-chip *ngIf="filterIsActivated('groups')" color="danger" (click)="clearFilter('groups')">
					<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }} ({{
						"labels.groups" |
						translate }})</ion-label>
					<ion-icon name="close-circle"></ion-icon>
				</ion-chip>
			</ion-list>
		</div>

		<!-- Customs fields -->
		<div class="filter-content" style="padding: 10px !important"
			*ngFor="let customFieldItem of filters.customFields">
			<div class="filter-custom-field-item" *ngIf="customFieldItem.values.length > 0">
				<div (click)="collapse(customFieldItem.uid)"
					style="display: flex; justify-content: space-between; margin-top: 5px; margin-bottom: 5px; cursor: pointer;">
					<h5 class="filter-name" [style.color]="event.styling.titleColor">{{ customFieldItem.name }}:</h5>
					<ion-icon *ngIf="!getFilterCollapseState(customFieldItem.uid)" src="assets/icon/ArrowUp_thin.svg"
						style="position: relative; top: 6px; cursor: pointer">
					</ion-icon>
					<ion-icon *ngIf="getFilterCollapseState(customFieldItem.uid)" src="assets/icon/ArrowDown_thin.svg"
						style="position: relative; top: 6px; cursor: pointer">
					</ion-icon>
				</div>

				<ion-list class="accordion-container" [class.collapsed]="getFilterCollapseState(customFieldItem.uid)"
					[style.maxHeight]="'auto'">
					<ion-item lines="none" *ngFor="let value of customFieldItem.values">
						<ion-label class="filter-label">{{ value.value }}</ion-label>
						<ion-checkbox color="dark" slot="end" [value]="value" [(ngModel)]="value.isSelected"
							[disabled]="!value.isReachable">
						</ion-checkbox>
					</ion-item>
					<ion-chip *ngIf="filterIsActivated('customs-fields', customFieldItem.uid)" color="danger"
						(click)="clearFilter('customs-fields', customFieldItem.uid)">
						<ion-label class="chip-delete-text">{{ "filter.delete_all" | translate }} ({{
							customFieldItem.name }})</ion-label>
						<ion-icon name="close-circle"></ion-icon>
					</ion-chip>
				</ion-list>
			</div>
		</div>
	</div>
</div>