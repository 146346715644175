<div class="container-infos" [style.height.px]="isMobile && 220 + containerInfosHeight">
	<div class="infos" *ngIf="currentEventUserProfile">
		<ion-avatar style="position: relative" slot="start" [style.background]="'rgb(234, 239, 242)'">
			<div class="is-online" *ngIf="
					event.settings.enableOnlineStatus &&
					currentEventUserProfile.updatedSettings &&
					currentEventUserProfile.updatedSettings.connected
				"></div>
			<ion-img *ngIf="currentEventUserProfile.photoUrl || (actualImage && actualImage.webPath !== '')" [src]="
					!actualImage && currentEventUserProfile.photoUrl && currentEventUserProfile.photoUrl !== ''
						? currentEventUserProfile.photoUrl
						: actualImage && actualImage.webPath !== ''
						? actualImage.webPath
						: ''
				" class="profile-picture" (click)="
					openImage(
						currentEventUserProfile.photoUrl !== ''
							? currentEventUserProfile.photoUrl
							: actualImage.webPath !== ''
							? actualImage.webPath
							: null
					)
				" [style.border-color]="isMobile ? '#fff' : event.styling.menuTextColorGradient">
			</ion-img>
			<div class="profile-picture" *ngIf="!currentEventUserProfile.photoUrl && !actualImage" [ngStyle]="{
					background: 'rgb(234, 239, 242)',
					'border-color': isMobile ? '#fff' : event.styling.menuTextColorGradient
				}">
				<ion-icon class="cus-picture" src="assets/icon/User2.svg"></ion-icon>
			</div>
			<ion-icon
				*ngIf="(currentEventUserProfile.photoUrl || (actualImage && actualImage.webPath !== '')) && editMode"
				style="position: absolute; font-size: 30px; top: 0; right: 0; cursor: pointer" color="danger"
				name="close" (click)="deleteProfilePicture()"></ion-icon>
			<button *ngIf="editMode" class="small-btn-cam" [style.background]="event.styling.btnBgColor"
				[style.color]="event.styling.btnTextColor" (click)="promptCameraModal()">
				<ion-icon src="assets/icon/Camera.svg"></ion-icon>
			</button>
		</ion-avatar>
		<ion-label [style.color]="isMobile ? menuColor : event.styling.menuTextColorGradient">
			<div class="bloc-tag">
				<div [ngClass]="!editMode ? 'name-view-mode' : 'name-edit-mode'"
					[style.color]="isMobile ? event.styling.titleColor : event.styling.menuTextColorGradient">
					{{ currentEventUserProfile.name }}

					<!-- Download contact -->
					@if (cardExchangeModule && cardExchangeModule.options.enableVcardDownloading &&
					(SCardExchange.checkContact(myEventUser,
					eventUserProfile.uid)) && cardExchangeMode) {
					<ion-icon slot="end" name="bookmark_remove" fill="{{ event.styling.contentTextColor }}"
						style="position: relative; top: 3px; cursor: pointer" src="assets/icon/download.svg" (click)="
							SCardExchange.downloadVcard(
								event,
								module,
								myEventUser,
								eventUserProfile,
								allCustomFields,
								null,
								$event
							)
						">
					</ion-icon>
					}

					<ng-container *ngIf="favoriteFolder && !cardExchangeMode">
						<!-- add bookmark icon to the right -->
						<ion-icon slot="end" name="bookmark" style="position: relative; top: 5px"
							[style.color]="isMobile ? event.styling.titleColor : event.styling.menuTextColorGradient"
							src="assets/icon/bookmarks.svg"
							*ngIf="favoriteModule && favoriteModule.options 
                            && (!favoriteModule.options.hideFavoriteMenuIcon 
                            || !favoriteModule.options.hideFavoriteHeaderIcon) && myEventUser && myEventUser.uid !== currentEventUserProfile.uid  && !favoriteState?.[currentEventUserProfile.uid]"
							(click)="addToFavorite(currentEventUserProfile?.uid, $event)"></ion-icon>

						<ion-icon slot="end" name="bookmark_remove" style="position: relative; top: 5px"
							[style.color]="isMobile ? event.styling.titleColor : event.styling.menuTextColorGradient"
							src="assets/icon/bookmark_remove.svg"
							*ngIf="favoriteModule && favoriteModule.options
                            && (!favoriteModule.options.hideFavoriteMenuIcon 
                            || !favoriteModule.options.hideFavoriteHeaderIcon) && myEventUser && myEventUser.uid !== currentEventUserProfile.uid  && favoriteState?.[currentEventUserProfile.uid] "
							(click)="removeFromFavorite(currentEventUserProfile.uid, $event)">
						</ion-icon>
					</ng-container>
				</div>

				<div *ngIf="!editMode && countCustomFieldTagged().length > 0" class="info-pro">
					<app-custom-fields-tags [event]="event" [module]="module"
						[modulesCustomsFields]="modulesCustomsFields" [eventUserProfile]="eventUserProfile"
						[customFields]="customFields" [isPrivateMode]="isPrivateMode"
						[currentLanguage]="currentLanguage"></app-custom-fields-tags>
				</div>

				<div *ngIf="!editMode" class="icons">
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Social/Facebook.svg" class="social-icons" *ngIf="
							((isPrivateMode && !module.options.requiredFields?.facebook?.hiding.profil) ||
								(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									module,
									modulesCustomsFields,
									eventUserProfile,
									'facebook',
									'baseFields'
								) &&
									!isPrivateMode &&
									!module.options.requiredFields?.facebook?.hiding.public)) &&
							currentEventUserProfile.socialMedias.facebook
						" (click)="openFacebook(currentEventUserProfile.socialMedias.facebook)">
					</ion-icon>
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Social/Twitter X.svg" class="social-icons" *ngIf="
							((isPrivateMode && !module.options.requiredFields?.twitter?.hiding.profil) ||
								(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									module,
									modulesCustomsFields,
									eventUserProfile,
									'twitter',
									'baseFields'
								) &&
									!isPrivateMode &&
									!module.options.requiredFields?.twitter?.hiding.public)) &&
							currentEventUserProfile.socialMedias.twitter
						" (click)="openX(currentEventUserProfile.socialMedias.twitter)">
					</ion-icon>
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Social/Linkedin.svg" class="social-icons" *ngIf="
							((isPrivateMode && !module.options.requiredFields?.linkedin?.hiding.profil) ||
								(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									module,
									modulesCustomsFields,
									eventUserProfile,
									'linkedin',
									'baseFields'
								) &&
									!isPrivateMode &&
									!module.options.requiredFields?.linkedin?.hiding.public)) &&
							currentEventUserProfile.socialMedias.linkedin
						" (click)="openLinkedIn(currentEventUserProfile.socialMedias.linkedin)">
					</ion-icon>
					<ion-icon class="social-icons" src="assets/icon/Social/Instagram.svg"
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						*ngIf="
							((isPrivateMode && !module.options.requiredFields?.instagram?.hiding.profil) ||
								(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									module,
									modulesCustomsFields,
									eventUserProfile,
									'instagram',
									'baseFields'
								) &&
									!isPrivateMode &&
									!module.options.requiredFields?.instagram?.hiding.public)) &&
							currentEventUserProfile.socialMedias.instagram
						" (click)="openInstagram(currentEventUserProfile.socialMedias.instagram)">
					</ion-icon>
					<ion-icon
						[style.color]="isMobile ? event.styling.contentTextColor : event.styling.menuTextColorGradient"
						src="assets/icon/Web2.svg" class="social-icons" *ngIf="
							((isPrivateMode && !module.options.requiredFields?.website?.hiding.profil) ||
								(!SCustomFields.isUserOrModuleFieldsVisibilityHidden(
									module,
									modulesCustomsFields,
									eventUserProfile,
									'website',
									'baseFields'
								) &&
									!isPrivateMode &&
									!module.options.requiredFields?.website?.hiding.public)) &&
							currentEventUserProfile.socialMedias.website
						" (click)="openLink(currentEventUserProfile.socialMedias.website)">
					</ion-icon>
				</div>

				<button *ngIf="editMode" class="btn-cam" [style.background]="event.styling.btnBgColor"
					[style.color]="event.styling.btnTextColor" (click)="promptCameraModal()">
					<ion-icon src="assets/icon/Camera.svg"></ion-icon>
					&nbsp;
					<span>{{ "texts.select_image" | translate }}</span>
				</button>
			</div>
		</ion-label>
	</div>
</div>