import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { environment } from "src/environments/environment";
import { IEvent } from "../interfaces";
import { IAuthState } from "../interfaces/auth.interfaces";
import { IContainersState } from "../interfaces/containers.interfaces";
import { IUser } from "../interfaces/user.interfaces";
import { getContainersState } from "./containers.selectors";
import { getCurrentEvent } from "./events.selectors";

export const getAuthState = createFeatureSelector<IAuthState>("auth");

export const checkSameUser = (userId: string) =>
	createSelector(getAuthState, (state: IAuthState) => state.currentUser.uid === userId);

export const getCurrentUser = createSelector(getAuthState, (state: IAuthState) => state.currentUser);

export const getCurrentUserId = createSelector(getAuthState, (state: IAuthState) =>
	state.currentUser ? _.cloneDeep(state.currentUser.uid) : null
);

export const getCurrentEventUser = createSelector(getAuthState, (state: IAuthState) =>
	_.cloneDeep(state.eventUser.eventUser)
);

export const getAuthenticated = createSelector(getAuthState, (state: IAuthState) => state.authenticated);

export const getCurrentUserLanguage = createSelector(getAuthState, (state: IAuthState) =>
	state.currentUser && state.currentUser.language ? state.currentUser.language : environment.platform.defaultLanguage
);

export const getContainersStateAndUser = createSelector(
	getContainersState,
	getCurrentUser,
	(containersState: IContainersState, user: IUser) => {
		return {
			containersState: containersState ? _.cloneDeep(containersState) : null,
			user: user ? _.cloneDeep(user) : null
		};
	}
);

export const checkSameEvent = (uid: string) =>
	createSelector(getAuthState, (state: IAuthState) => {
		return state.eventUser.eventId === uid ? true : false;
	});

export const getMyEventUser = createSelector(getAuthState, (state: IAuthState) =>
	_.cloneDeep(state.eventUser.eventUser)
);

export const getEventUserIfEventExist = createSelector(
	getAuthState,
	getCurrentEvent,
	(state: IAuthState, event: IEvent) => {
		return {
			eventExist: event ? true : false,
			eventUser: _.cloneDeep(state.eventUser.eventUser)
		};
	}
);
