export const getEmailDataFopAppointmentTaken = (
    title: string,
	language: string,
	attendeeName: string,
	scheduleDay: string,
	scheduleTime: string,
	subject: string,
    logoUrl: string,
    colorMenu: string, //Dans visuel de l'app , input qui s'appelle "Menu" si le dégradé est activé c'est "Menu1"
    colorTextTitle: string, //Dans visuel de l'app , input qui s'appelle "Texte des titres
    colorTextContent: string, //Dans visuel de l'app , input qui s'appelle "Texte du contenu"
    iconStatutUrl: string
) => {
	switch (language) {
		case "ArAR": {
			return {
				subject: "ملخص موعدك",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR">

                <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:700px) {
                        .desktop_hide table.icons-outer {
                            display: inline-table !important;
                        }
            
                        .desktop_hide table.icons-inner {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-5 .column-1 .block-5.heading_block h1,
                        .row-5 .column-1 .block-6.heading_block h1,
                        .row-5 .column-1 .block-7.heading_block h1 {
                            font-size: 18px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block td.pad {
                            padding: 10px 20px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block h1 {
                            font-size: 34px !important;
                        }
            
                        .row-5 .column-1 .block-9.paragraph_block td.pad,
                        .row-6 .column-1 .block-2.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #eeeeee; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #eeeeee;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:40px;line-height:40px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; background-color: ${colorMenu}; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:5px;padding-left:25px;padding-right:25px;padding-top:5px;width:100%;">
                                                                            <div class="alignment" align="left" style="line-height:10px">
                                                                                <div style="max-width: 68px;"><a href="${logoUrl}" target="_blank" style="outline:none" tabindex="-1"><img src="${logoUrl}" style="display: block; height: auto; border: 0; width: 100%;" width="68" alt="Company Logo" title="Company Logo" height="auto"></a></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; background-color: #ffffff; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; background-color: #ffffff; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="icons_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; text-align: center;">
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 15px; font-weight: 400; text-align: center;">
                                                                            <table class="icons-outer" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-table;">
                                                                                <tr>
                                                                                    <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;"><img class="icon" src="${iconStatutUrl}" height="auto" width="61" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextTitle}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 43px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 51.6px;"><span class="tinyMce-placeholder">${title}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">مع <strong>${attendeeName}</strong></span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-6" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder"> ${scheduleDay}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-7" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 19px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 22.8px;"><span class="tinyMce-placeholder"> ${scheduleTime}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-8" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-9" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;">
                                                                            <div style="color:${colorTextContent};direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:18px;font-weight:400;letter-spacing:0px;line-height:180%;text-align:center;mso-line-height-alt:32.4px;">
                                                                                <p style="margin: 0;">${subject}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-10" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:45px;padding-right:45px;">
                                                                            <div style="color:#cecece;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:10px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
                                                                                <p style="margin: 0; margin-bottom: 0px;">Si vous ne souhaitez plus recevoir des emails de la part d'InventApp</p>
                                                                                <p style="margin: 0;"><strong>Cliquez ici</strong></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-3" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>

                </html>`
			};
		}
		case "FrFR":
			return {
				subject: "Résumé de votre rendez-vous",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR">

                <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:700px) {
                        .desktop_hide table.icons-outer {
                            display: inline-table !important;
                        }
            
                        .desktop_hide table.icons-inner {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-5 .column-1 .block-5.heading_block h1,
                        .row-5 .column-1 .block-6.heading_block h1,
                        .row-5 .column-1 .block-7.heading_block h1 {
                            font-size: 18px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block td.pad {
                            padding: 10px 20px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block h1 {
                            font-size: 34px !important;
                        }
            
                        .row-5 .column-1 .block-9.paragraph_block td.pad,
                        .row-6 .column-1 .block-2.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #eeeeee; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #eeeeee;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:40px;line-height:40px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; background-color: ${colorMenu}; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:5px;padding-left:25px;padding-right:25px;padding-top:5px;width:100%;">
                                                                            <div class="alignment" align="left" style="line-height:10px">
                                                                                <div style="max-width: 68px;"><a href="${logoUrl}" target="_blank" style="outline:none" tabindex="-1"><img src="${logoUrl}" style="display: block; height: auto; border: 0; width: 100%;" width="68" alt="Company Logo" title="Company Logo" height="auto"></a></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; background-color: #ffffff; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; background-color: #ffffff; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="icons_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; text-align: center;">
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 15px; font-weight: 400; text-align: center;">
                                                                            <table class="icons-outer" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-table;">
                                                                                <tr>
                                                                                    <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;"><img class="icon" src="${iconStatutUrl}" height="auto" width="61" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextTitle}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 43px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 51.6px;"><span class="tinyMce-placeholder">${title}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">Avec <strong>${attendeeName}</strong></span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-6" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder"> ${scheduleDay}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-7" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 19px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 22.8px;"><span class="tinyMce-placeholder">à ${scheduleTime}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-8" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-9" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;">
                                                                            <div style="color:${colorTextContent};direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:18px;font-weight:400;letter-spacing:0px;line-height:180%;text-align:center;mso-line-height-alt:32.4px;">
                                                                                <p style="margin: 0;">${subject}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-10" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:45px;padding-right:45px;">
                                                                            <div style="color:#cecece;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:10px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
                                                                                <p style="margin: 0; margin-bottom: 0px;">Si vous ne souhaitez plus recevoir des emails de la part d'InventApp</p>
                                                                                <p style="margin: 0;"><strong>Cliquez ici</strong></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-3" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>

                </html>`
			};

		case "EnUS":
			return {
				subject: "Summary of your appointment",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR">

                <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:700px) {
                        .desktop_hide table.icons-outer {
                            display: inline-table !important;
                        }
            
                        .desktop_hide table.icons-inner {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-5 .column-1 .block-5.heading_block h1,
                        .row-5 .column-1 .block-6.heading_block h1,
                        .row-5 .column-1 .block-7.heading_block h1 {
                            font-size: 18px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block td.pad {
                            padding: 10px 20px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block h1 {
                            font-size: 34px !important;
                        }
            
                        .row-5 .column-1 .block-9.paragraph_block td.pad,
                        .row-6 .column-1 .block-2.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #eeeeee; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #eeeeee;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:40px;line-height:40px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; background-color: ${colorMenu}; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:5px;padding-left:25px;padding-right:25px;padding-top:5px;width:100%;">
                                                                            <div class="alignment" align="left" style="line-height:10px">
                                                                                <div style="max-width: 68px;"><a href="${logoUrl}" target="_blank" style="outline:none" tabindex="-1"><img src="${logoUrl}" style="display: block; height: auto; border: 0; width: 100%;" width="68" alt="Company Logo" title="Company Logo" height="auto"></a></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; background-color: #ffffff; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; background-color: #ffffff; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="icons_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; text-align: center;">
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 15px; font-weight: 400; text-align: center;">
                                                                            <table class="icons-outer" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-table;">
                                                                                <tr>
                                                                                    <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;"><img class="icon" src="${iconStatutUrl}" height="auto" width="61" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextTitle}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 43px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 51.6px;"><span class="tinyMce-placeholder">${title}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">With <strong>${attendeeName}</strong></span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-6" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">${scheduleDay}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-7" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 19px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 22.8px;"><span class="tinyMce-placeholder">${scheduleTime}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-8" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-9" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;">
                                                                            <div style="color:${colorTextContent};direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:18px;font-weight:400;letter-spacing:0px;line-height:180%;text-align:center;mso-line-height-alt:32.4px;">
                                                                                <p style="margin: 0;">${subject}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-10" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:45px;padding-right:45px;">
                                                                            <div style="color:#cecece;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:10px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
                                                                                <p style="margin: 0; margin-bottom: 0px;">Si vous ne souhaitez plus recevoir des emails de la part d'InventApp</p>
                                                                                <p style="margin: 0;"><strong>Cliquez ici</strong></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-3" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>

                </html>`
			};

		case "EsES":
			return {
				subject: "Resumen de su cita",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR">

                <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:700px) {
                        .desktop_hide table.icons-outer {
                            display: inline-table !important;
                        }
            
                        .desktop_hide table.icons-inner {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-5 .column-1 .block-5.heading_block h1,
                        .row-5 .column-1 .block-6.heading_block h1,
                        .row-5 .column-1 .block-7.heading_block h1 {
                            font-size: 18px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block td.pad {
                            padding: 10px 20px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block h1 {
                            font-size: 34px !important;
                        }
            
                        .row-5 .column-1 .block-9.paragraph_block td.pad,
                        .row-6 .column-1 .block-2.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #eeeeee; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #eeeeee;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:40px;line-height:40px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; background-color: ${colorMenu}; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:5px;padding-left:25px;padding-right:25px;padding-top:5px;width:100%;">
                                                                            <div class="alignment" align="left" style="line-height:10px">
                                                                                <div style="max-width: 68px;"><a href="${logoUrl}" target="_blank" style="outline:none" tabindex="-1"><img src="${logoUrl}" style="display: block; height: auto; border: 0; width: 100%;" width="68" alt="Company Logo" title="Company Logo" height="auto"></a></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; background-color: #ffffff; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; background-color: #ffffff; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="icons_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; text-align: center;">
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 15px; font-weight: 400; text-align: center;">
                                                                            <table class="icons-outer" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-table;">
                                                                                <tr>
                                                                                    <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;"><img class="icon" src="${iconStatutUrl}" height="auto" width="61" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextTitle}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 43px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 51.6px;"><span class="tinyMce-placeholder">${title}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">Con <strong>${attendeeName}</strong></span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-6" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">${scheduleDay}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-7" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 19px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 22.8px;"><span class="tinyMce-placeholder">${scheduleTime}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-8" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-9" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;">
                                                                            <div style="color:${colorTextContent};direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:18px;font-weight:400;letter-spacing:0px;line-height:180%;text-align:center;mso-line-height-alt:32.4px;">
                                                                                <p style="margin: 0;">${subject}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-10" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:45px;padding-right:45px;">
                                                                            <div style="color:#cecece;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:10px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
                                                                                <p style="margin: 0; margin-bottom: 0px;">Si vous ne souhaitez plus recevoir des emails de la part d'InventApp</p>
                                                                                <p style="margin: 0;"><strong>Cliquez ici</strong></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-3" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>

                </html>`
			};

		case "PtBR":
			return {
				subject: "Resumo do seu compromisso",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR">

                <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:700px) {
                        .desktop_hide table.icons-outer {
                            display: inline-table !important;
                        }
            
                        .desktop_hide table.icons-inner {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-5 .column-1 .block-5.heading_block h1,
                        .row-5 .column-1 .block-6.heading_block h1,
                        .row-5 .column-1 .block-7.heading_block h1 {
                            font-size: 18px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block td.pad {
                            padding: 10px 20px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block h1 {
                            font-size: 34px !important;
                        }
            
                        .row-5 .column-1 .block-9.paragraph_block td.pad,
                        .row-6 .column-1 .block-2.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #eeeeee; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #eeeeee;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:40px;line-height:40px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; background-color: ${colorMenu}; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:5px;padding-left:25px;padding-right:25px;padding-top:5px;width:100%;">
                                                                            <div class="alignment" align="left" style="line-height:10px">
                                                                                <div style="max-width: 68px;"><a href="${logoUrl}" target="_blank" style="outline:none" tabindex="-1"><img src="${logoUrl}" style="display: block; height: auto; border: 0; width: 100%;" width="68" alt="Company Logo" title="Company Logo" height="auto"></a></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; background-color: #ffffff; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; background-color: #ffffff; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="icons_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; text-align: center;">
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 15px; font-weight: 400; text-align: center;">
                                                                            <table class="icons-outer" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-table;">
                                                                                <tr>
                                                                                    <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;"><img class="icon" src="${iconStatutUrl}" height="auto" width="61" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextTitle}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 43px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 51.6px;"><span class="tinyMce-placeholder">${title}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">Com <strong>${attendeeName}</strong></span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-6" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">${scheduleDay}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-7" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 19px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 22.8px;"><span class="tinyMce-placeholder">${scheduleTime}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-8" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-9" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;">
                                                                            <div style="color:${colorTextContent};direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:18px;font-weight:400;letter-spacing:0px;line-height:180%;text-align:center;mso-line-height-alt:32.4px;">
                                                                                <p style="margin: 0;">${subject}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-10" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:45px;padding-right:45px;">
                                                                            <div style="color:#cecece;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:10px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
                                                                                <p style="margin: 0; margin-bottom: 0px;">Si vous ne souhaitez plus recevoir des emails de la part d'InventApp</p>
                                                                                <p style="margin: 0;"><strong>Cliquez ici</strong></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-3" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>

                </html>`
			};

		case "DeDE":
			return {
				subject: "Zusammenfassung Ihres Termins",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR">

                <head>
                <title></title>
                <meta http-equiv="Content-Type" content="text/html; charset=utf-8">
                <meta name="viewport" content="width=device-width, initial-scale=1.0"><!--[if mso]><xml><o:OfficeDocumentSettings><o:PixelsPerInch>96</o:PixelsPerInch><o:AllowPNG/></o:OfficeDocumentSettings></xml><![endif]--><!--[if !mso]><!--><!--<![endif]-->
                <style>
                    * {
                        box-sizing: border-box;
                    }
            
                    body {
                        margin: 0;
                        padding: 0;
                    }
            
                    a[x-apple-data-detectors] {
                        color: inherit !important;
                        text-decoration: inherit !important;
                    }
            
                    #MessageViewBody a {
                        color: inherit;
                        text-decoration: none;
                    }
            
                    p {
                        line-height: inherit
                    }
            
                    .desktop_hide,
                    .desktop_hide table {
                        mso-hide: all;
                        display: none;
                        max-height: 0px;
                        overflow: hidden;
                    }
            
                    .image_block img+div {
                        display: none;
                    }
            
                    @media (max-width:700px) {
                        .desktop_hide table.icons-outer {
                            display: inline-table !important;
                        }
            
                        .desktop_hide table.icons-inner {
                            display: inline-block !important;
                        }
            
                        .icons-inner {
                            text-align: center;
                        }
            
                        .icons-inner td {
                            margin: 0 auto;
                        }
            
                        .mobile_hide {
                            display: none;
                        }
            
                        .row-content {
                            width: 100% !important;
                        }
            
                        .stack .column {
                            width: 100%;
                            display: block;
                        }
            
                        .mobile_hide {
                            min-height: 0;
                            max-height: 0;
                            max-width: 0;
                            overflow: hidden;
                            font-size: 0px;
                        }
            
                        .desktop_hide,
                        .desktop_hide table {
                            display: table !important;
                            max-height: none !important;
                        }
            
                        .row-5 .column-1 .block-5.heading_block h1,
                        .row-5 .column-1 .block-6.heading_block h1,
                        .row-5 .column-1 .block-7.heading_block h1 {
                            font-size: 18px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block td.pad {
                            padding: 10px 20px !important;
                        }
            
                        .row-5 .column-1 .block-3.heading_block h1 {
                            font-size: 34px !important;
                        }
            
                        .row-5 .column-1 .block-9.paragraph_block td.pad,
                        .row-6 .column-1 .block-2.paragraph_block td.pad {
                            padding: 10px 25px !important;
                        }
                    }
                </style>
            </head>
            
            <body style="background-color: #eeeeee; margin: 0; padding: 0; -webkit-text-size-adjust: none; text-size-adjust: none;">
                <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: #eeeeee;">
                    <tbody>
                        <tr>
                            <td>
                                <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:40px;line-height:40px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-size: auto; background-color: ${colorMenu}; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="image_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:5px;padding-left:25px;padding-right:25px;padding-top:5px;width:100%;">
                                                                            <div class="alignment" align="left" style="line-height:10px">
                                                                                <div style="max-width: 68px;"><a href="${logoUrl}" target="_blank" style="outline:none" tabindex="-1"><img src="${logoUrl}" style="display: block; height: auto; border: 0; width: 100%;" width="68" alt="Company Logo" title="Company Logo" height="auto"></a></div>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                            <td class="column column-2" width="50%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: middle; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <table class="empty_block block-1" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad">
                                                                            <div></div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:10px;line-height:10px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; border-radius: 0; color: #000000; background-color: #ffffff; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; background-color: #ffffff; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="icons_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; text-align: center;">
                                                                    <tr>
                                                                        <td class="pad" style="vertical-align: middle; color: #000000; font-family: inherit; font-size: 15px; font-weight: 400; text-align: center;">
                                                                            <table class="icons-outer" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; display: inline-table;">
                                                                                <tr>
                                                                                    <td style="vertical-align: middle; text-align: center; padding-top: 5px; padding-bottom: 5px; padding-left: 5px; padding-right: 5px;"><img class="icon" src="${iconStatutUrl}" height="auto" width="61" align="center" style="display: block; height: auto; margin: 0 auto; border: 0;"></td>
                                                                                </tr>
                                                                            </table>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-3" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextTitle}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 43px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 51.6px;"><span class="tinyMce-placeholder">${title}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-4" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="heading_block block-5" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">Mit <strong>${attendeeName}</strong></span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-6" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 18px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 21.599999999999998px;"><span class="tinyMce-placeholder">${scheduleDay}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <table class="heading_block block-7" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;text-align:center;width:100%;">
                                                                            <h1 style="margin: 0; color: ${colorTextContent}; direction: ltr; font-family: Arial, Helvetica, sans-serif; font-size: 19px; font-weight: 400; letter-spacing: normal; line-height: 120%; text-align: center; margin-top: 0; margin-bottom: 0; mso-line-height-alt: 22.8px;"><span class="tinyMce-placeholder">${scheduleTime}</span></h1>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-8" style="height:20px;line-height:20px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-9" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:60px;padding-right:60px;padding-top:10px;">
                                                                            <div style="color:${colorTextContent};direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:18px;font-weight:400;letter-spacing:0px;line-height:180%;text-align:center;mso-line-height-alt:32.4px;">
                                                                                <p style="margin: 0;">${subject}</p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-10" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt;">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <table class="row-content stack" align="center" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; background-color: ${colorMenu}; border-radius: 0; color: #000000; width: 680px; margin: 0 auto;" width="680">
                                                    <tbody>
                                                        <tr>
                                                            <td class="column column-1" width="100%" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; font-weight: 400; text-align: left; padding-bottom: 5px; padding-top: 5px; vertical-align: top; border-top: 0px; border-right: 0px; border-bottom: 0px; border-left: 0px;">
                                                                <div class="spacer_block block-1" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                                <table class="paragraph_block block-2" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation" style="mso-table-lspace: 0pt; mso-table-rspace: 0pt; word-break: break-word;">
                                                                    <tr>
                                                                        <td class="pad" style="padding-bottom:10px;padding-left:45px;padding-right:45px;">
                                                                            <div style="color:#cecece;direction:ltr;font-family:Arial, Helvetica, sans-serif;font-size:10px;font-weight:400;letter-spacing:0px;line-height:150%;text-align:center;mso-line-height-alt:15px;">
                                                                                <p style="margin: 0; margin-bottom: 0px;">Si vous ne souhaitez plus recevoir des emails de la part d'InventApp</p>
                                                                                <p style="margin: 0;"><strong>Cliquez ici</strong></p>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                </table>
                                                                <div class="spacer_block block-3" style="height:25px;line-height:25px;font-size:1px;">&#8202;</div>
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr>
                    </tbody>
                </table><!-- End -->
            </body>

                </html>`
			};

		default:
			return {
				subject: "Résumé de votre rendez-vous",
				html: `<!DOCTYPE html>
                <html xmlns:v="urn:schemas-microsoft-com:vml" xmlns:o="urn:schemas-microsoft-com:office:office" lang="fr-FR"
                    style="box-sizing: border-box;">
                
                <head style="box-sizing: border-box;">
                    <title style="box-sizing: border-box;"></title>
                    <meta http-equiv="Content-Type" content="text/html; charset=utf-8" style="box-sizing: border-box;">
                    <meta name="viewport" content="width=device-width, initial-scale=1.0" style="box-sizing: border-box;">

                </head>
                
                <body
                    style="background-color: #eeeeee;margin: 0;padding: 0;-webkit-text-size-adjust: none;text-size-adjust: none;box-sizing: border-box;">
                    <table class="nl-container" width="100%" border="0" cellpadding="0" cellspacing="0" role="presentation"
                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;background-color: #eeeeee;box-sizing: border-box;">
                        <tbody style="box-sizing: border-box;">
                            <tr style="box-sizing: border-box;">
                                <td style="box-sizing: border-box;">
                                    <table class="row row-1" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation" style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                        <tbody style="box-sizing: border-box;">
                                            <tr style="box-sizing: border-box;">
                                                <td style="box-sizing: border-box;">
                                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;border-radius: 0;color: #000000;width: 680px;margin: 0 auto;box-sizing: border-box;"
                                                        width="680">
                                                        <tbody style="box-sizing: border-box;">
                                                            <tr style="box-sizing: border-box;">
                                                                <td class="column column-1" width="100%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;padding-bottom: 5px;padding-top: 5px;vertical-align: top;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <div class="spacer_block block-1"
                                                                        style="height: 40px;line-height: 40px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="row row-2" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation" style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                        <tbody style="box-sizing: border-box;">
                                            <tr style="box-sizing: border-box;">
                                                <td style="box-sizing: border-box;">
                                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;background-color: ${colorMenu};border-radius: 0;color: #000000;width: 680px;margin: 0 auto;box-sizing: border-box;"
                                                        width="680">
                                                        <tbody style="box-sizing: border-box;">
                                                            <tr style="box-sizing: border-box;">
                                                                <td class="column column-1" width="100%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;padding-bottom: 5px;padding-top: 5px;vertical-align: top;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <div class="spacer_block block-1"
                                                                        style="height: 10px;line-height: 10px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="row row-3" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation"
                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;background-size: auto;box-sizing: border-box;">
                                        <tbody style="box-sizing: border-box;">
                                            <tr style="box-sizing: border-box;">
                                                <td style="box-sizing: border-box;">
                                                    <table class="row-content" align="center" border="0" cellpadding="0" cellspacing="0"
                                                        role="presentation"
                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;background-size: auto;background-color: ${colorMenu};color: #000000;width: 680px;margin: 0 auto;box-sizing: border-box;"
                                                        width="680">
                                                        <tbody style="box-sizing: border-box;">
                                                            <tr style="box-sizing: border-box;">
                                                                <td class="column column-1" width="50%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;padding-bottom: 5px;padding-top: 5px;vertical-align: middle;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <table class="image_block block-1" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-bottom: 5px;padding-left: 25px;padding-right: 25px;padding-top: 5px;width: 100%;box-sizing: border-box;">
                                                                                <div class="alignment" align="left"
                                                                                    style="line-height: 10px;box-sizing: border-box;">
                                                                                    <div
                                                                                        style="max-width: 68px;box-sizing: border-box;">
                                                                                        <a href="www.example.com" target="_blank"
                                                                                            style="outline: none;box-sizing: border-box;"
                                                                                            tabindex="-1"><img
                                                                                                src="https://631fff4a1d.imgdist.com/pub/bfra/fkl6ey56/qc8/bkt/v0i/logo%20carre%CC%81.png"
                                                                                                style="display: block;height: auto;border: 0;width: 100%;box-sizing: border-box;"
                                                                                                width="68" alt="Company Logo"
                                                                                                title="Company Logo"></a>
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                                <td class="column column-2" width="50%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;padding-bottom: 5px;padding-top: 5px;vertical-align: middle;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <table class="empty_block block-1" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad" style="box-sizing: border-box;">
                                                                                <div style="box-sizing: border-box;"></div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="row row-4" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation" style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                        <tbody style="box-sizing: border-box;">
                                            <tr style="box-sizing: border-box;">
                                                <td style="box-sizing: border-box;">
                                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;background-color: ${colorMenu};border-radius: 0;color: #000000;width: 680px;margin: 0 auto;box-sizing: border-box;"
                                                        width="680">
                                                        <tbody style="box-sizing: border-box;">
                                                            <tr style="box-sizing: border-box;">
                                                                <td class="column column-1" width="100%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;padding-bottom: 5px;padding-top: 5px;vertical-align: top;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <div class="spacer_block block-1"
                                                                        style="height: 10px;line-height: 10px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="row row-5" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation" style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                        <tbody style="box-sizing: border-box;">
                                            <tr style="box-sizing: border-box;">
                                                <td style="box-sizing: border-box;">
                                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;border-radius: 0;color: #000000;background-color: #ffffff;width: 680px;margin: 0 auto;box-sizing: border-box;"
                                                        width="680">
                                                        <tbody style="box-sizing: border-box;">
                                                            <tr style="box-sizing: border-box;">
                                                                <td class="column column-1" width="100%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;background-color: #ffffff;padding-bottom: 5px;padding-top: 5px;vertical-align: top;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <div class="spacer_block block-1"
                                                                        style="height: 25px;line-height: 25px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                    <table class="icons_block block-2" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="vertical-align: middle;color: #000000;font-family: inherit;font-size: 15px;font-weight: 400;text-align: center;box-sizing: border-box;">
                                                                                <table class="alignment" cellpadding="0" cellspacing="0"
                                                                                    role="presentation" align="center"
                                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                                    <tr style="box-sizing: border-box;">
                                                                                        <td
                                                                                            style="vertical-align: middle;text-align: center;padding-top: 5px;padding-bottom: 5px;padding-left: 5px;padding-right: 5px;box-sizing: border-box;">
                                                                                            <img class="icon"
                                                                                                src="https://firebasestorage.googleapis.com/v0/b/invent-app-prd.appspot.com/o/appointment-mail-icon.png?alt=media&token=93b03c62-5b94-4012-a4a6-ae808a9d6e6e"
                                                                                                height="64" width="61" align="center"
                                                                                                style="display: block;height: auto;margin: 0 auto;border: 0;box-sizing: border-box;">
                                                                                        </td>
                                                                                    </tr>
                                                                                </table>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table class="heading_block block-3" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-bottom: 10px;padding-left: 60px;padding-right: 60px;padding-top: 10px;text-align: center;width: 100%;box-sizing: border-box;">
                                                                                <h1
                                                                                    style="margin: 0;color: ${colorTextTitle};direction: ltr;font-family: Arial, Helvetica, sans-serif;font-size: 43px;font-weight: 400;letter-spacing: normal;line-height: 120%;text-align: center;margin-top: 0;margin-bottom: 0;mso-line-height-alt: 51.6px;box-sizing: border-box;">
                                                                                    <span class="tinyMce-placeholder"
                                                                                        style="box-sizing: border-box;">${title}</span>
                                                                                </h1>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <div class="spacer_block block-4"
                                                                        style="height: 20px;line-height: 20px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                    <table class="heading_block block-5" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-left: 60px;padding-right: 60px;padding-top: 10px;text-align: center;width: 100%;box-sizing: border-box;">
                                                                                <h1
                                                                                    style="margin: 0;color: ${colorTextContent};direction: ltr;font-family: Arial, Helvetica, sans-serif;font-size: 18px;font-weight: 400;letter-spacing: normal;line-height: 120%;text-align: center;margin-top: 0;margin-bottom: 0;mso-line-height-alt: 21.599999999999998px;box-sizing: border-box;">
                                                                                    <span class="tinyMce-placeholder"
                                                                                        style="box-sizing: border-box;">Avec <strong
                                                                                            style="box-sizing: border-box;">${attendeeName}</strong></span>
                                                                                </h1>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table class="heading_block block-6" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-left: 60px;padding-right: 60px;padding-top: 10px;text-align: center;width: 100%;box-sizing: border-box;">
                                                                                <h1
                                                                                    style="margin: 0;color: ${colorTextContent};direction: ltr;font-family: Arial, Helvetica, sans-serif;font-size: 18px;font-weight: 400;letter-spacing: normal;line-height: 120%;text-align: center;margin-top: 0;margin-bottom: 0;mso-line-height-alt: 21.599999999999998px;box-sizing: border-box;">
                                                                                    <span class="tinyMce-placeholder"
                                                                                        style="box-sizing: border-box;">${scheduleDay}</span>
                                                                                </h1>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <table class="heading_block block-7" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-bottom: 10px;padding-left: 60px;padding-right: 60px;padding-top: 10px;text-align: center;width: 100%;box-sizing: border-box;">
                                                                                <h1
                                                                                    style="margin: 0;color: ${colorTextContent};direction: ltr;font-family: Arial, Helvetica, sans-serif;font-size: 19px;font-weight: 400;letter-spacing: normal;line-height: 120%;text-align: center;margin-top: 0;margin-bottom: 0;mso-line-height-alt: 22.8px;box-sizing: border-box;">
                                                                                    <span class="tinyMce-placeholder"
                                                                                        style="box-sizing: border-box;">${scheduleTime}</span>
                                                                                </h1>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <div class="spacer_block block-8"
                                                                        style="height: 20px;line-height: 20px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                    <table class="paragraph_block block-9" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;word-break: break-word;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-bottom: 10px;padding-left: 60px;padding-right: 60px;padding-top: 10px;box-sizing: border-box;">
                                                                                <div
                                                                                    style="color: ${colorTextContent};direction: ltr;font-family: Arial, Helvetica, sans-serif;font-size: 18px;font-weight: 400;letter-spacing: 0px;line-height: 180%;text-align: center;mso-line-height-alt: 32.4px;box-sizing: border-box;">
                                                                                    <p
                                                                                        style="margin: 0;box-sizing: border-box;line-height: inherit;">
                                                                                        ${subject}</p>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <div class="spacer_block block-10"
                                                                        style="height: 25px;line-height: 25px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <table class="row row-6" align="center" width="100%" border="0" cellpadding="0" cellspacing="0"
                                        role="presentation" style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;box-sizing: border-box;">
                                        <tbody style="box-sizing: border-box;">
                                            <tr style="box-sizing: border-box;">
                                                <td style="box-sizing: border-box;">
                                                    <table class="row-content stack" align="center" border="0" cellpadding="0"
                                                        cellspacing="0" role="presentation"
                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;background-color: ${colorMenu};border-radius: 0;color: #000000;width: 680px;margin: 0 auto;box-sizing: border-box;"
                                                        width="680">
                                                        <tbody style="box-sizing: border-box;">
                                                            <tr style="box-sizing: border-box;">
                                                                <td class="column column-1" width="100%"
                                                                    style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;font-weight: 400;text-align: left;padding-bottom: 5px;padding-top: 5px;vertical-align: top;border-top: 0px;border-right: 0px;border-bottom: 0px;border-left: 0px;box-sizing: border-box;">
                                                                    <div class="spacer_block block-1"
                                                                        style="height: 25px;line-height: 25px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                    <table class="paragraph_block block-2" width="100%" border="0"
                                                                        cellpadding="0" cellspacing="0" role="presentation"
                                                                        style="mso-table-lspace: 0pt;mso-table-rspace: 0pt;word-break: break-word;box-sizing: border-box;">
                                                                        <tr style="box-sizing: border-box;">
                                                                            <td class="pad"
                                                                                style="padding-bottom: 10px;padding-left: 45px;padding-right: 45px;box-sizing: border-box;">
                                                                                <div
                                                                                    style="color: #cecece;direction: ltr;font-family: Arial, Helvetica, sans-serif;font-size: 10px;font-weight: 400;letter-spacing: 0px;line-height: 150%;text-align: center;mso-line-height-alt: 15px;box-sizing: border-box;">
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    </table>
                                                                    <div class="spacer_block block-3"
                                                                        style="height: 25px;line-height: 25px;font-size: 1px;box-sizing: border-box;">
                                                                        &#8202;</div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </body>
                
                </html>`
			};
	}
};
