@if (designType === "card") {
<div
	observeVisibility
	[debounceTime]="0"
	[threshold]="0"
	[rootElement]="rootElement"
	[root]="'#onVisibleScrollContainer'"
	[rootMargin]="'0px 0px 0px 0px'"
	(visible)="onVisibleEventUser($event)"
>
	<ion-card
		[style.--background]="event.styling.cardBgColor ? event.styling.cardBgColor : '#ffffff'"
		[style.min-width]="isMobile ? '45vw' : '250px'"
		[style.width]="isMobile ? '45vw' : '250px'"
		style="height: 250px"
	>
		<div
			style="
				width: 100%;
				display: flex;
				flex-direction: row;
				align-items: center;
				justify-content: center;
				padding: 10px;
			"
		>
			<ion-avatar slot="start" [ngStyle]="{ padding: !eventUser.photoUrl ? '20px 0' : '' }">
				@if (targetModule && targetModule.type === typesModules.SPEAKER && targetModule.options &&
				targetModule.options.showNewHintChip && checkIsNew()) {
				<ion-icon
					style="position: absolute; top: -5px; font-size: 25px"
					[style.color]="'#efc700'"
					name="sparkles"
				></ion-icon>
				} @if (event.settings.enableOnlineStatus && data && isConnected) {
				<div class="is-online"></div>
				} @if (data.photoUrl) {
				<img class="picture" [src]="data.photoUrl" />
				} @else {
				<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
				}
			</ion-avatar>
		</div>
		<ion-card-header>
			<ion-card-title class="line-clamp-2" style="text-align: center" [style.color]="event.styling.titleColor"
				>{{ data?.name }}
			</ion-card-title>
		</ion-card-header>

		<ion-card-content>
			@for (customField of getEventUserTags(data); track customField.uid; let count = $count) {
			<div class="margin-padding-0">
				<h5
					class="ellipsis-100 custom-fields-event-users-module-item-tags"
					style="text-align: center"
					[style.color]="event.styling.contentTextColor"
					[style.fontSize]="count === 3 ? 'O.8rem' : '0.9rem'"
				>
					{{
					customField.field.text?.trim() ||
					customField.field.multiLanguageText?.[currentLanguage]?.trim()
					||
					customField.field.numeric?.toString()
					}}
				</h5>
			</div>
			}
		</ion-card-content>
	</ion-card>
</div>
} @else {
<div
	observeVisibility
	[debounceTime]="0"
	[threshold]="0"
	[rootElement]="rootElement"
	[root]="'#onVisibleScrollContainer'"
	[rootMargin]="'0px 0px 0px 0px'"
	(visible)="onVisibleEventUser($event)"
>
	@if (groupedLetters && groupedLetters[data.uid]) {
	<div class="header-divider" [hidden]="!targetModule.options.showLetters" style="height: 50px">
		<div class="letter" [style.color]="event.styling.titleColor">
			{{ groupedLetters[data.uid] }}
		</div>
		<div class="bar" [style.background-color]="event.styling.titleColor"></div>
	</div>
	} @if (data) {
	<ion-item
		[style.--background]="event.styling.cardBgColor ? event.styling.cardBgColor : '#ffffff'"
		lines="none"
		class="item-list"
	>
		<ion-avatar slot="start" [ngStyle]="{ padding: !data.photoUrl ? '20px 0' : '' }">
			@if (targetModule && targetModule.type === typesModules.SPEAKER && targetModule.options &&
			targetModule.options.showNewHintChip && checkIsNew()) {
			<ion-icon
				style="position: absolute; top: -5px; font-size: 25px"
				[style.color]="'#efc700'"
				name="sparkles"
			></ion-icon>
			} @if (event.settings.enableOnlineStatus && data && isConnected) {
			<div class="is-online"></div>
			} @if (data.photoUrl) {
			<img class="picture" [src]="data.photoUrl" />
			} @else {
			<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
			}
		</ion-avatar>

		<ion-label style="height: auto">
			<h2 class="line-clamp-2" [style.color]="event.styling.titleColor">
				{{ data?.name }}
			</h2>
			@for (customField of getEventUserTags(data); track customField.uid; let count = $count) {
			<div class="margin-padding-0">
				<h5
					class="ellipsis-100 custom-fields-event-users-module-item-tags"
					[style.color]="event.styling.contentTextColor"
					[style.fontSize]="count === 3 ? 'O.8rem' : '0.9rem'"
				>
					{{
					customField.field.text?.trim() ||
					customField.field.multiLanguageText?.[currentLanguage]?.trim() ||
					customField.field.numeric?.toString()
					}}
				</h5>
			</div>
			}
		</ion-label>

		@if (data && eventUser && cardExchangeModule && !checkIsContact() && eventUser.uid !== data.uid) {
		<ion-icon
			slot="end"
			name="add-contact"
			fill="{{ event.styling.contentTextColor }}"
			[style.color]="event.styling.contentTextColor"
			src="assets/icon/add-user.svg"
			(click)="addToContacts(data, $event)"
		></ion-icon>
		} @if (data && eventUser && favoriteModule && favoriteFolder && favoriteModule.options && eventUser.uid !==
		data.uid && (!favoriteModule.options.hideFavoriteMenuIcon || !favoriteModule.options.hideFavoriteHeaderIcon)) {
		<ion-icon
			slot="end"
			fill="{{ event.styling.contentTextColor }}"
			[style.color]="event.styling.contentTextColor"
			[src]="checkIsFavorite() ? 'assets/icon/bookmark_remove.svg' : 'assets/icon/bookmarks.svg'"
			(click)="$event.stopPropagation(); changeFavorites(data.uid, !checkIsFavorite())"
		></ion-icon>
		} @if (data && eventUser && mainModule.type === typesModules.CHECKIN) { @if (isChecked && checked) {
		<ion-label
			slot="end"
			style="display: flex; flex-direction: column; align-items: center; justify-content: center"
			[style.color]="event.styling.titleColor"
		>
			<span>{{ SUtility.formatDate(event, eventUser, checked.creationDate, "short") }}</span>
			<span>{{ SUtility.formatDate(event, eventUser, checked.creationDate, "time") }}</span>
		</ion-label>
		}
		<ion-icon
			[style.color]="event.styling.titleColor"
			slot="end"
			[src]="isChecked ? 'assets/icon/Check2.svg' : 'assets/icon/Check.svg'"
		>
		</ion-icon>

		@if (multiCheck && isChecked) {
		<div
			slot="end"
			style="height: 100%; display: flex; flex-direction: column; align-items: center; justify-content: center"
		>
			<span
				(click)="modifyMultiCheck.emit('add'); $event.stopPropagation()"
				class="material-icons"
				style="color: green"
			>
				add
			</span>
			<span style="margin: 10px 0px">
				{{
					checked && checked.multiCheckRecordsCount && checked.multiCheckRecordsCount > 0
						? checked.multiCheckRecordsCount
						: 0
				}}
			</span>
			<span
				(click)="modifyMultiCheck.emit('remove'); $event.stopPropagation()"
				class="material-icons"
				style="color: red"
			>
				remove
			</span>
		</div>
		} }
	</ion-item>
	}
</div>
}
