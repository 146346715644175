import { createReducer, on } from "@ngrx/store";
import { IAnalyticsState } from "../interfaces/analytics.interfaces";
import * as AnalyticsActions from "../actions/analytics.actions";

export const initialState: IAnalyticsState = {
	general: null,
	attendees: null,
	chats: null,
	documents: null,
	feeds: null,
	galleries: null,
	schedules: null,
	speakers: null,
	tracking: null,
	users: null,
	visios: null
};

export const analyticsReducers = createReducer(
	initialState,

	/******************************************/
	/*********   General analytics   **********/
	/******************************************/
	on(AnalyticsActions.UpdateGeneralAnalytics, (state, action) => ({
		...state,
		general: action.payload
	})),
	on(AnalyticsActions.UpdateTotalAccess, (state, action) => ({
		...state,
		general: {
			...state.general,
			totalAccess: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalProfilEdition, (state, action) => ({
		...state,
		general: {
			...state.general,
			totalProfilEdition: action.payload
		}
	})),
	on(AnalyticsActions.UpdateBestConsultedModules, (state, action) => ({
		...state,
		general: {
			...state.general,
			bestConsultedModules: action.payload
		}
	})),

	/******************************************/
	/*********  Attendees analytics  **********/
	/******************************************/
	on(AnalyticsActions.UpdateAttendeesAnalytics, (state, action) => ({
		...state,
		attendees: action.payload
	})),
	on(AnalyticsActions.UpdateTotalAttendeesModulesAnalytics, (state, action) => ({
		...state,
		attendees: {
			...state.attendees,
			totalAttendeesModules: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalAttendeesAnalytics, (state, action) => ({
		...state,
		attendees: {
			...state.attendees,
			totalAttendees: action.payload
		}
	})),

	/******************************************/
	/***********  Chats analytics  ************/
	/******************************************/
	on(AnalyticsActions.UpdateChatsAnalytics, (state, action) => ({
		...state,
		chats: action.payload
	})),
	on(AnalyticsActions.UpdateTotalChats, (state, action) => ({
		...state,
		chats: {
			...state.chats,
			totalChats: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalChatsUsers, (state, action) => ({
		...state,
		chats: {
			...state.chats,
			totalChatsUsers: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalMessagesChats, (state, action) => ({
		...state,
		chats: {
			...state.chats,
			totalMessagesOnChats: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalDiscussionGroups, (state, action) => ({
		...state,
		chats: {
			...state.chats,
			totalDiscussionGroups: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalDiscussionGroupsUsers, (state, action) => ({
		...state,
		chats: {
			...state.chats,
			totalDiscussionGroupsUsers: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalMessagesDiscussionGroups, (state, action) => ({
		...state,
		chats: {
			...state.chats,
			totalMessagesOnDiscussionGroups: action.payload
		}
	})),

	/******************************************/
	/*********  Documents analytics  **********/
	/******************************************/
	on(AnalyticsActions.UpdateDocumentsAnalytics, (state, action) => ({
		...state,
		documents: action.payload
	})),
	on(AnalyticsActions.UpdateBestDocuments, (state, action) => ({
		...state,
		documents: {
			...state.documents,
			bestDocuments: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalDocuments, (state, action) => ({
		...state,
		documents: {
			...state.documents,
			totalDocuments: action.payload
		}
	})),

	/******************************************/
	/*********    Feeds analytics    **********/
	/******************************************/
	on(AnalyticsActions.UpdateFeedsAnalytics, (state, action) => ({
		...state,
		feeds: action.payload
	})),
	on(AnalyticsActions.UpdateTotalFeedsPosts, (state, action) => ({
		...state,
		feeds: {
			...state.feeds,
			totalFeedsPosts: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalFeedsLikes, (state, action) => ({
		...state,
		feeds: {
			...state.feeds,
			totalFeedsLikes: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalFeedsComments, (state, action) => ({
		...state,
		feeds: {
			...state.feeds,
			totalFeedsComments: action.payload
		}
	})),
	on(AnalyticsActions.UpdateBestLikedFeeds, (state, action) => ({
		...state,
		feeds: {
			...state.feeds,
			bestLikedFeeds: action.payload
		}
	})),
	on(AnalyticsActions.UpdateBestCommentedFeeds, (state, action) => ({
		...state,
		feeds: {
			...state.feeds,
			bestCommentedFeeds: action.payload
		}
	})),

	/******************************************/
	/*********  Galleries analytics  **********/
	/******************************************/
	on(AnalyticsActions.UpdateGalleriesAnalytics, (state, action) => ({
		...state,
		galleries: action.payload
	})),
	on(AnalyticsActions.UpdateBestImages, (state, action) => ({
		...state,
		galleries: {
			...state.galleries,
			bestImages: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalImages, (state, action) => ({
		...state,
		galleries: {
			...state.galleries,
			totalImages: action.payload
		}
	})),

	/******************************************/
	/*********  Schedules analytics  **********/
	/******************************************/
	on(AnalyticsActions.UpdateSchedulesAnalytics, (state, action) => ({
		...state,
		schedules: action.payload
	})),
	on(AnalyticsActions.UpdateTotalSchedulesModulesAnalytics, (state, action) => ({
		...state,
		schedules: {
			...state.schedules,
			totalSchedulesModules: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalSchedulesSessionsAnalytics, (state, action) => ({
		...state,
		schedules: {
			...state.schedules,
			totalSessionsSchedules: action.payload
		}
	})),

	/******************************************/
	/**********  Speakers analytics  **********/
	/******************************************/
	on(AnalyticsActions.UpdateSpeakersAnalytics, (state, action) => ({
		...state,
		speakers: action.payload
	})),
	on(AnalyticsActions.UpdateTotalSpeakersModulesAnalytics, (state, action) => ({
		...state,
		speakers: {
			...state.speakers,
			totalSpeakersModules: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalSpeakersAnalytics, (state, action) => ({
		...state,
		speakers: {
			...state.speakers,
			totalSpeakers: action.payload
		}
	})),

	/******************************************/
	/*********  Trackings analytics  **********/
	/******************************************/
	on(AnalyticsActions.UpdateTrackingAnalytics, (state, action) => ({
		...state,
		tracking: action.payload
	})),
	on(AnalyticsActions.UpdateUserRoadTrackingAnalytics, (state, action) => ({
		...state,
		tracking: {
			...state.tracking,
			userRoadTracking: action.payload
		}
	})),

	/******************************************/
	/*********    Users analytics    **********/
	/******************************************/
	on(AnalyticsActions.UpdateUsersAnalytics, (state, action) => ({
		...state,
		users: action.payload
	})),
	on(AnalyticsActions.UpdateTotalUniqueUsers, (state, action) => ({
		...state,
		users: {
			...state.users,
			totalUniqueUsers: action.payload
		}
	})),
	on(AnalyticsActions.UpdateCurrentConnectedAttendees, (state, action) => ({
		...state,
		users: {
			...state.users,
			currentConnectedAttendees: action.payload
		}
	})),
	on(AnalyticsActions.UpdateCurrentConnectedSpeakers, (state, action) => ({
		...state,
		users: {
			...state.users,
			currentConnectedSpeakers: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalUsersByModule, (state, action) => ({
		...state,
		users: {
			...state.users,
			totalUsersByModules: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalAttendees, (state, action) => ({
		...state,
		users: {
			...state.users,
			totalAttendees: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalSpeakers, (state, action) => ({
		...state,
		users: {
			...state.users,
			totalSpeakers: action.payload
		}
	})),

	/******************************************/
	/*********   Visios analytics    **********/
	/******************************************/
	on(AnalyticsActions.UpdateVisiosAnalytics, (state, action) => ({
		...state,
		visios: action.payload
	})),
	on(AnalyticsActions.UpdateTotalPIM, (state, action) => ({
		...state,
		visios: {
			...state.visios,
			totalPIM: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalRoomsForEvent, (state, action) => ({
		...state,
		visios: {
			...state.visios,
			totalRoomsForEvent: action.payload
		}
	})),
	on(AnalyticsActions.UpdateTotalRoomsBetween2, (state, action) => ({
		...state,
		visios: {
			...state.visios,
			totalRoomsBetween2: action.payload
		}
	}))
);
