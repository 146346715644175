@if (!scanningWeb) {
<div class="background"
    style="width: 100%; background: transparent; display: flex; flex-direction: row; align-items: flex-start; justify-content: center;">
    <!-- Filters -->
    @if (checkin() && checkin().showFilter && canShowFilterBloc() && !isMobile && !filtersLoader) {
    <app-filters-for-list style="height: 100dvh" [event]="event" [modules]="eventUsersModules()" [eventUser]="eventUser"
        [filters]="filters()" (filtersStateChanged)="buildFilterQuery()"></app-filters-for-list>
    }

    <div class="datas-container">
        @if (event && checkin()) {
        <ng-container>
            <div class="checkin-header-container" [style.border-top-left-radius]="isMobile ? '0' : '15px'"
                [style.border-top-right-radius]="isMobile ? '0' : '15px'" [style.border-bottom-left-radius]="'15px'"
                [style.border-bottom-right-radius]="'15px'" [style.background]="event.styling.cardBgColor">
                <div class="counter-container" #totalContainer>
                    <div class="present cursor-pointer"
                        [style.background-color]="filterType() === 'present' ? '#c4c4c4' : 'transparent'"
                        [style.color]="'green'" (click)="filterType.set('present')">
                        <h2>{{ totalPresent }}</h2>
                        <span>{{"checkin.showed" | translate}}</span>
                    </div>
                    <div class="divider"></div>
                    <div class="absent cursor-pointer"
                        [style.background-color]="filterType() === 'away' ? '#c4c4c4' : 'transparent'"
                        [style.color]="'#d82029'" (click)="filterType.set('away')">
                        <h2>{{ totalAway }}</h2>
                        <span>{{ "checkin.away" | translate }}</span>
                    </div>
                    <div class="divider"></div>
                    <div class="present cursor-pointer"
                        [style.background-color]="filterType() === 'all' ? '#c4c4c4' : 'transparent'"
                        [style.color]="'black'" (click)="filterType.set('all')">
                        <h2>{{ total }}</h2>
                        <span>{{ "checkin.total" | translate }}</span>
                    </div>
                </div>
                <ion-searchbar [style.--background]="'rgb(234, 239, 242)'"
                    placeholder="{{'texts.search_plc' | translate}}" cancelButtonText="{{'buttons.cancel' | translate}}"
                    [debounce]="1000" (ionClear)="resetFilter()" [(ngModel)]="searchValue"
                    (ngModelChange)="searchValueUpdated.next($event)">
                </ion-searchbar>
            </div>
            @if (!loader && datasFiltered().length > 0) {
            <div #contentContainer [style.height]="'calc(100% - ' + totalContainer.clientHeight + 'px - 70px)'">
                <cdk-virtual-scroll-viewport #onVisibleScrollContainer id="onVisibleScrollContainer"
                    (scrolledIndexChange)="scrolledIndexChanged($event)" style="height: 100%; width: 100%"
                    [itemSize]="itemSize" [minBufferPx]="height" [maxBufferPx]="height">
                    <div id="main-list" style="height: 100%;"
                        [style.padding]="checkin().showFilter && canShowFilterBloc() && !isMobile ? '0 10px 0 0' : '0 10px 80px 10px'">
                        <div *cdkVirtualFor="let data of datasFiltered()">
                            <app-event-user-item [rootElement]="onVisibleScrollContainer.elementRef.nativeElement"
                                [event]="event" [mainModule]="module"
                                [targetModule]="getSpecificEventUsersModule(data.moduleId)"
                                [customFields]="getCustomFieldsOfModule(data.moduleId)" [data]="data"
                                [eventUser]="eventUser" [currentLanguage]="currentLanguage"
                                [multiCheck]="checkin().multiCheck" [isChecked]="checkIsCheckedEventUser(data)"
                                [checked]="getCheckedForEventUser(data.uid)"
                                (modifyMultiCheck)="modifyMultiCheck(data.uid, $event);"
                                (click)="confirmChangeStatus(data)"></app-event-user-item>
                        </div>
                    </div>
                </cdk-virtual-scroll-viewport>
            </div>
            }
        </ng-container>
        }


        @if (loader) {
        <div class="loader-block">
            <ion-item-divider>
                <ion-skeleton-text animated style="width: 10%"></ion-skeleton-text>
            </ion-item-divider>
            @for (item of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]; track item) {
            <ion-item lines="none">
                <ion-avatar slot="start">
                    <img src="../../../assets/images/grey.jpg">
                </ion-avatar>
                <ion-label>
                    <h3>
                        <ion-skeleton-text animated style="width: 80%"></ion-skeleton-text>
                    </h3>
                    <h3>
                        <ion-skeleton-text animated style="width: 45%"></ion-skeleton-text>
                    </h3>
                    <h3>
                        <ion-skeleton-text animated style="width: 30%"></ion-skeleton-text>
                    </h3>
                </ion-label>
            </ion-item>
            }
        </div>
        }
    </div>
</div>
}

@if (event && isMobile && checkin() && checkin().showFilter && canShowFilterBloc()) {
<!-- Filter button -->
<div class="filter-button-container">
    <ion-button [id]="filtersUuid" expand="block" shape="round" class="filter-button"
        [style.--background]="event.styling.btnBgColor" [style.color]="event.styling.btnTextColor"
        style="font-weight: 600">
        <ion-icon slot="start" name="filter-outline"></ion-icon>
        {{ "texts.filter" | translate }}
    </ion-button>
    @if (getTotalFiltersChecked() > 0) {
    <div class="filter-button-badge">
        {{ getTotalFiltersChecked() }}
    </div>
    }
</div>
}

<!-- Mobile filters modal -->
@if (event && isMobile && checkin() && checkin().showFilter && canShowFilterBloc()) {
<ion-modal [trigger]="filtersUuid" [style.--height]="'calc(100% - 57px)'" [handle]="false" mode="ios"
    [initialBreakpoint]="1" [breakpoints]="[0, 1]">
    <ng-template>
        <app-filters-for-list style="height: 100%" [event]="event" [modules]="eventUsersModules()"
            [eventUser]="eventUser" [filters]="filters()" [type]="'mobile'"
            (filtersStateChanged)="buildFilterQuery()"></app-filters-for-list>
    </ng-template>
</ion-modal>
}

@if (scanningWeb) {
<div style="position: relative">
    <ion-icon (click)="scanning = false; scanningWeb = false; enableScanWeb = false"
        style="position: absolute; top: 20px; right: 20px; font-size: 30px; cursor: pointer; z-index: 99999999"
        color="danger" name="close">
    </ion-icon>
    <zxing-scanner [enable]="enableScanWeb" (camerasFound)="camerasFoundHandler()"
        (camerasNotFound)="camerasNotFoundHandler()" (scanSuccess)="scanSuccessHandler($event)"
        (scanError)="scanErrorHandler()">
    </zxing-scanner>
</div>
}