<!-- Untouched field icon -->
<ion-icon
	*ngIf="
		form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		) &&
		!form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		)?.touched &&
		(computedCustomField.baseSettings.type === typeCustomFields.PHONE ||
			!form.get(
				mode === 'multiLanguage'
					? computedCustomField.baseSettings.uid + '_' + language
					: mode === 'singleLanguage'
					? computedCustomField.baseSettings.uid
					: computedCustomField.baseSettings.name[language] +
					  '_' +
					  computedCustomField.baseSettings.uid +
					  '_' +
					  language
			)?.dirty)
	"
	src="assets/icon/Check.svg"
	class="check-icon"
	slot="end"
>
</ion-icon>
<!-- Success icon -->
<ion-icon
	*ngIf="
		form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		) &&
		!form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		)?.errors &&
		(form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		)?.touched ||
			(computedCustomField.baseSettings.type !== typeCustomFields.PHONE &&
				form.get(
					mode === 'multiLanguage'
						? computedCustomField.baseSettings.uid + '_' + language
						: mode === 'singleLanguage'
						? computedCustomField.baseSettings.uid
						: computedCustomField.baseSettings.name[language] +
						  '_' +
						  computedCustomField.baseSettings.uid +
						  '_' +
						  language
				)?.dirty))
	"
	src="assets/icon/Check.svg"
	class="check-icon"
	slot="end"
	color="primary"
>
</ion-icon>
<!-- Errors icon -->
<ion-icon
	*ngIf="
		computedCustomField.baseSettings.type !== typeCustomFields.SELECT &&
		computedCustomField.baseSettings.type !== typeCustomFields.MULTI_SELECT &&
		form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		) &&
		computedCustomField.baseSettings.type !== typeCustomFields.SELECT &&
		computedCustomField.baseSettings.type !== typeCustomFields.MULTI_SELECT &&
		form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		)?.errors &&
		(form.get(
			mode === 'multiLanguage'
				? computedCustomField.baseSettings.uid + '_' + language
				: computedCustomField.baseSettings.uid
		)?.touched ||
			(computedCustomField.baseSettings.type !== typeCustomFields.PHONE &&
				form.get(
					mode === 'multiLanguage'
						? computedCustomField.baseSettings.uid + '_' + language
						: mode === 'singleLanguage'
						? computedCustomField.baseSettings.uid
						: computedCustomField.baseSettings.name[language] +
						  '_' +
						  computedCustomField.baseSettings.uid +
						  '_' +
						  language
				)?.dirty))
	"
	src="assets/icon/Close.svg"
	class="check-icon"
	slot="end"
	color="danger"
>
</ion-icon>
