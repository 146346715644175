import { createReducer, on } from "@ngrx/store";
import * as SchedulesActions from "../actions/schedules.actions";
import { ISchedulesState } from "../interfaces/schedules.interfaces";

export const initialState: ISchedulesState = {
	eventId: "",
	schedules: []
};

export const schedulesFeatureKey = "schedules";

export const schedulesReducers = createReducer(
	initialState,
	on(SchedulesActions.GetAllSchedules, (state, action) => ({
		...state,
		eventId: action.eventId,
		schedules: action.payload
	})),
	on(SchedulesActions.ResetSchedulesState, () => ({
		...initialState
	}))
);
