import { Component, ElementRef, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NavController } from "@ionic/angular";
import { Store } from "@ngrx/store";
import { TranslateService } from "@ngx-translate/core";
import * as _ from "lodash-es";
import { DateTime } from "luxon";
import { Subscription, combineLatest } from "rxjs";
import { skipWhile, take } from "rxjs/operators";
import { Loading } from "src/app/shared/actions/utility.actions";
import { TypeLogin } from "src/app/shared/enums/type-login";
import { TypeUser } from "src/app/shared/enums/type-user";
import { IContainer, IEvent, IUser } from "src/app/shared/interfaces";
import { getCurrentUser } from "src/app/shared/selectors/auth.selectors";
import { getContainersState } from "src/app/shared/selectors/containers.selectors";
import { getAllEvents } from "src/app/shared/selectors/events.selectors";
import { getInitEvents, getInitUser } from "src/app/shared/selectors/utility.selectors";
import { AuthService } from "src/app/shared/services";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-events-list",
	templateUrl: "./events-list.page.html",
	styleUrls: ["./events-list.page.scss"]
})
export class EventsListPage implements OnInit, OnDestroy {
	subscriptions: Subscription[] = [];

	loader: boolean = true;

	paramType: string = "my-events";
	listType: boolean = false; // False = my-events list and true = publics events list

	searchOpen: boolean = false;
	terms: string = "";

	myEvents: IEvent[] = [];
	publicsEvents: IEvent[] = [];
	events: IEvent[] = [];
	user: IUser;
	container: IContainer;

	eventsSub: Subscription;
	currentLanguage: string = environment.platform.defaultLanguage;

	constructor(
		private SAuth: AuthService,
		private store: Store,
		private route: ActivatedRoute,
		private navCtrl: NavController,
		private STranslate: TranslateService,
		private elementRef: ElementRef
	) {}

	ngOnInit() {
		this.route.params.pipe(take(1)).subscribe((params) => {
			this.paramType = params.type;
			this.listType = params.type === "all" || params.type === "my-events" ? false : true;
		});

		this.currentLanguage = this.STranslate.currentLang;
		this.subscriptions.push(
			this.STranslate.onLangChange.subscribe((lang) => {
				this.currentLanguage = lang.lang;
			})
		);

		this.subscriptions.push(
			this.store.select(getContainersState).subscribe((containersState) => {
				this.container =
					!environment.platform.containerId || environment.platform.containerId === "defaultContainer"
						? containersState.defaultContainer
						: containersState.currentContainer;
			})
		);

		combineLatest([
			this.store.select(getInitUser).pipe(skipWhile((init) => !init)),
			this.store.select(getInitEvents).pipe(skipWhile((init) => !init))
		])
			.pipe(take(1))
			.subscribe(() => {
				this.initEventsAndUser();
			});
	}

	ngOnDestroy() {
		this.subscriptions.forEach((sub) => sub.unsubscribe());
		if (this.eventsSub && !this.eventsSub.closed) {
			this.eventsSub.unsubscribe();
		}
	}

	/**
	 * Init events and user
	 */
	initEventsAndUser() {
		this.subscriptions.push(
			this.store.select(getCurrentUser).subscribe((user) => {
				if (!_.isEqual(user, this.user)) {
					this.user = user;

					if (this.eventsSub && !this.eventsSub.closed) {
						this.eventsSub.unsubscribe();
					}

					this.eventsSub = this.store.select(getAllEvents).subscribe((events) => {
						this.events = events?.sort((a, b) => {
							return DateTime.fromISO(a.startDate).toMillis() - DateTime.fromISO(b.startDate).toMillis();
						});

						this.loader = false;
						this.store.dispatch(Loading({ payload: false }));
					});

					// this.eventsSub =
					// 	this.user && this.user.type <= TypeUser.GOD
					// 		? this.store.select(getAllEvents).subscribe((events) => {
					// 				this.events = events?.sort((a, b) => {
					// 					return (
					// 						DateTime.fromISO(a.startDate).toMillis() -
					// 						DateTime.fromISO(b.startDate).toMillis()
					// 					);
					// 				});

					// 				this.loader = false;
					// 				this.store.dispatch(Loading({ payload: false }));
					// 		  })
					// 		: combineLatest([
					// 				this.store.select(getMyEvents),
					// 				this.store.select(getPublicsEvents)
					// 		  ]).subscribe((results) => {
					// 				// Init my events
					// 				if (!_.isEqual(this.myEvents, results[0])) {
					// 					this.myEvents = results[0];
					// 				}

					// 				if (!this.listType) {
					// 					this.events = this.myEvents?.sort((a, b) => {
					// 						return (
					// 							DateTime.fromISO(a.startDate).toMillis() -
					// 							DateTime.fromISO(b.startDate).toMillis()
					// 						);
					// 					});
					// 				}

					// 				// Init publics events
					// 				// if (!_.isEqual(this.publicsEvents, results[1])) {
					// 				// 	this.publicsEvents = results[1];
					// 				// }

					// 				if (this.listType) {
					// 					this.events = this.publicsEvents?.sort((a, b) => {
					// 						return (
					// 							DateTime.fromISO(a.startDate).toMillis() -
					// 							DateTime.fromISO(b.startDate).toMillis()
					// 						);
					// 					});
					// 				}

					// 				if ([...this.myEvents, ...this.publicsEvents].length === 1) {
					// 					this.openEvent([...this.myEvents, ...this.publicsEvents][0]);
					// 				}
					// 				this.loader = false;
					// 				this.store.dispatch(Loading({ payload: false }));
					// 		  });
				}
			})
		);
	}

	/**
	 * Show or not list switch
	 * @returns
	 */
	checkContainerSettings() {
		if (
			(this.container.loginSettings.type === TypeLogin.WITH_EMAIL_CONFIRM &&
				this.container.loginSettings.showAccessPublicEventsBtn) ||
			(this.container.loginSettings.type === TypeLogin.WITHOUT_EMAIL_CONFIRM &&
				this.container.loginSettings.showAccessPublicEventsBtn &&
				this.user &&
				this.user.type >= TypeUser.SPEAKER &&
				this.publicsEvents.length > 0)
		) {
			return true;
		} else {
			return false;
		}
	}

	/**
	 * Go to specific event
	 * @param event
	 */
	openEvent(event: IEvent) {
		// this.SEvents.logo = event.identity && event.identity.logo ? event.identity.logo : "";
		// this.SEvents.loaderBackgroundColor =
		// 	event.styling && event.styling.menuTextColor ? event.styling.menuTextColor : "#ffffff";
		// this.SEvents.loaderColor = event.styling && event.styling.menuColor ? event.styling.menuColor : "#4f8db2";
		this.navCtrl.navigateRoot(event.homePage ? event.homePage : "/event/" + event.uid);
		this.elementRef.nativeElement.remove();
	}

	/**
	 * Change list of events
	 * @param event
	 */
	changeList(event) {
		this.events = !event.detail.checked ? this.myEvents : this.publicsEvents;
	}

	/**
	 * Get date
	 * @param date
	 * @returns
	 */
	getDate(date: string) {
		return DateTime.fromISO(date)
			.setLocale(this.currentLanguage.slice(0, 2).toLowerCase() + "-" + this.currentLanguage.slice(2, 4))
			.toFormat("dd MMMM yyyy");
	}

	/**
	 * Log out user
	 */
	logOut() {
		this.SAuth.logoutUser();
	}
}
