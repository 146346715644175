export enum FormItemType {
	INPUT = 1,
	TEXT = 2,
	MODULE = 3,
	IMAGE = 4,
	VIDEO = 5
}

export enum RegisterSettingsFormType {
	GENERAL = 1,
	CONTENT = 2
}

export enum FormType {
	BASE = 1,
	ACCOMPANYING = 2
}

export enum StepName {
	GENERAL_INFO = 1,
	SETTINGS = 2,
	FORM_BUILDER = 3,
	ACCOMPANYING_FORM_BUILDER = 4
}
