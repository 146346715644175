<div class="container-input">
  <div class="ion-intl-tel-input-code">
    <ion-select [(ngModel)]="selectedCountryDialCode" (ionChange)="onCodeChange($event)">
      <ion-select-option *ngFor="let country of countries" [value]="country.dialCode">
        <div style="position: relative; left: 20px !important;">
          &nbsp; <span style="display: block !important;"
            *ngIf="separateDialCode">{{dialCodePrefix}}{{country.dialCode}}</span>
          &nbsp; <span style="display: block !important;">{{country.name}}</span>
        </div>
        <!-- <div class=" code-show" *ngIf="selectedCountryDialCode && country.dialCode === selectedCountryDialCode">
            &nbsp; <span class="fi fi-{{country.flagClass}}">{{dialCodePrefix}}{{country.dialCode}}
            </span>
        </div> -->
      </ion-select-option>
    </ion-select>
  </div>

  <div class="ion-intl-tel-input-number">
    <ion-input #numberInput [(ngModel)]="phoneNumber" autocomplete="off" [disabled]="disabled"
      [attr.maxLength]="maxLength" type="tel" (ionBlur)="onIonNumberBlur()" (ionChange)="onIonNumberChange($event)"
      (ionFocus)="onIonNumberFocus()" (ionInput)="onIonNumberInput($event)" (keydown)="onNumberKeyDown($event)"
      (ngModelChange)="onNumberChange()"
      placeholder="{{country | countryPlaceholder: inputPlaceholder:separateDialCode:fallbackPlaceholder}}">
    </ion-input>
    <!-- <ion-input #numberInput [formControl]="formControlSelected" [(ngModel)]="phoneNumber" autocomplete="off" [disabled]="disabled"
      [attr.maxLength]="maxLength" type="tel" (ionBlur)="onIonNumberBlur()" (ionChange)="onIonNumberChange($event)"
      (ionFocus)="onIonNumberFocus()" (ionInput)="onIonNumberInput($event)" (keydown)="onNumberKeyDown($event)"
      (ngModelChange)="onNumberChange()"
      placeholder="{{country | countryPlaceholder: inputPlaceholder:separateDialCode:fallbackPlaceholder}}">
    </ion-input> -->
  </div>
</div>