<div
	*ngIf="event && module && askQuestions && eventUser"
	class="questions-main-container full-h"
	[class.full-w]="componentMode"
	[style.overflow-y]="isMobile && componentMode ? 'visible' : 'scroll'"
>
	<ion-row *ngIf="askQuestions && componentMode && showBackBtn">
		<ion-icon class="icons-25" name="arrow-back" [style.color]="event.styling.titleColor" (click)="goBackToList()">
		</ion-icon>
	</ion-row>
	<ng-container></ng-container>
	<!-- List of questions -->
	<div
		#mainContainer
		[style.height]="'calc(100% - 150px)'"
		[style.min-height]="questions.length <= 4 ? 72 * questions.length + 'px' : '288px'"
	>
		<!-- <div > -->
		<cdk-virtual-scroll-viewport
			[itemSize]="72"
			[minBufferPx]="isMobile ? 288 : 576"
			[maxBufferPx]="isMobile ? 576 : 1152"
		>
			<ng-container style="padding: 0 15px" *cdkVirtualFor="let question of questions; let i = index">
				<ion-item
					class="justify-text question-item"
					[ngClass]="{
						'disabled-style': question.validateByModerator === false && question.userId === eventUser.uid
					}"
					[style.--padding-start]="'0px'"
					*ngIf="
						question?.visibility ||
						(question.validateByModerator === false && question.userId === eventUser.uid)
					"
				>
					<ion-avatar
						*ngIf="question.userData && !askQuestions.anonymous && !question.markedAnonymousByUser"
						slot="start"
						[id]="'avatar' + i"
					>
						<img *ngIf="question.userData.photoUrl" class="user-img" [src]="question.userData.photoUrl" />
						<div class="profile-picture" *ngIf="!question.userData.photoUrl && question.userData.name">
							<ion-img class="cus-picture" src="assets/icon/User2.svg"></ion-img>
						</div>
					</ion-avatar>
					<ion-label
						[id]="'label' + i"
						[disabled]="false"
						[style.margin-left]="
							question.markedAnonymousByUser || !question.userData || !question.userData.photoUrl
								? '16px'
								: '0px'
						"
					>
						<h2
							[style.color]="event.styling.titleColor"
							*ngIf="
								question.userData &&
								question.userData.name &&
								!askQuestions.anonymous &&
								!question.markedAnonymousByUser
							"
						>
							{{ question.userData.name }}
						</h2>
						<h3
							[style.color]="event.styling.contentTextColor"
							class="question-body"
							#item
							[innerHTML]="question?.question | linky"
						></h3>
						<a
							[style.color]="event.styling.titleColor"
							[attr.disabled]="null"
							aria-disabled="false"
							disabled="false"
							style="cursor: pointer"
							*ngIf="checkOverflow(item)"
							(click)="showMoreLess('btn_' + i, item)"
							[id]="'btn_' + i"
							>{{ "buttons.show_more" | translate }}</a
						>
						<a
							[style.color]="event.styling.titleColor"
							style="cursor: pointer; display: none"
							(click)="showMoreLess('newB' + i, item)"
							[id]="'newB' + i"
							>{{ "buttons.show_more" | translate }}</a
						>
					</ion-label>

					<ion-text slot="end">
						<span class="num-and-icon">
							<span
								[style.color]="
									checkLikedQuestion(question)
										? event.styling.titleColor
										: event.styling.titleColor + 'D9'
								"
								[style.font-weight]="checkLikedQuestion(question) ? 600 : 300"
								class="num-votes"
								>{{ question?.totalVotes }} &nbsp;</span
							>
						</span>
					</ion-text>

					<ion-icon
						slot="end"
						[style.color]="event.styling.btnBgColor"
						[disabled]="question.validateByModerator === false && question.userId === eventUser.uid"
						[attr.disabled]="
							question.validateByModerator === false && question.userId === eventUser.uid ? true : null
						"
						[src]="checkLikedQuestion(question) ? 'assets/icon/Like2.svg' : 'assets/icon/Like.svg'"
						(click)="
							question.validateByModerator === false && question.userId === eventUser.uid
								? showModeratorMsg()
								: questionAddOrRemoveVote(question)
						"
					>
					</ion-icon>

					<ion-icon slot="end" *ngIf="question.answered" src="assets/icon/Check2.svg" color="success">
					</ion-icon>
				</ion-item>
			</ng-container>
		</cdk-virtual-scroll-viewport>
		<!-- </div> -->
	</div>

	<!-- End of list of questions -->

	<!-- Footer for submitting a new question -->
	<div class="f-col-c-c ask-question-footer" [class.footer-desktop]="!isMobile" [style.position]="'relative'">
		<ion-textarea
			autocapitalize="sentences"
			placeholder="{{ 'asks-questions.ask-questions-plc' | translate }}"
			[value]="textQuestion"
			[(ngModel)]="textQuestion"
		>
		</ion-textarea>
		<ion-item *ngIf="askQuestions.askAnonymously && !askQuestions.anonymous" class="mark-question-anonymous">
			<ion-checkbox slot="start" [(ngModel)]="markQuestionAnonymous"></ion-checkbox>
			<ion-label>{{ "asks-questions.ask-question-anonymously" | translate }}</ion-label>
		</ion-item>
		<button
			shape="round"
			class="btn-validate btn-validate-primary"
			(click)="createQuestion()"
			[disabled]="!textQuestion || submitting"
			[style.marginTop]="askQuestions.askAnonymously && !askQuestions.anonymous ? '0px' : '5px'"
		>
			{{ "buttons.validate" | translate }}
		</button>
	</div>
	<!-- End of Footer -->
</div>

<app-login-register-buttons
	*ngIf="event && event.settings.visibility && !eventUser"
	[event]="event"
></app-login-register-buttons>
