<ion-grid class="grid-interactivity" *ngIf="event && module">
    <!-- Segments tab -->

    <ion-row #segmentRow>
        <ion-segment [style.width]="detailSegmentsIsAlone() && '100%'" [ngModel]="segmentType" scrollable mode="md"
            (ionChange)="segmentTypeChanged($event)">
            <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                mode="md" *ngIf="isMobile && canShowDetailSegment()" value="details">
                <ion-label>{{ "schedule_detail.interactivity.details" | translate }}</ion-label>
            </ion-segment-button>

            <ng-container *ngFor="let module of sortedModules">
                <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                    [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                    mode="md" *ngIf="module && module.type === typesModules.FEEDBACKS && feedbacks.length > 0"
                    value="feedbacks">
                    <ion-label>
                        {{ (feedbacksModule.name[currentLanguage]) ? feedbacksModule.name[currentLanguage] :
                        ("schedule_detail.interactivity.feedback-session" | translate) }}
                    </ion-label>
                </ion-segment-button>

                <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                    [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                    mode="md" *ngIf="module && module.type === typesModules.ASK_QUESTION && asksQuestions.length > 0"
                    value="questions">
                    <ion-label>
                        {{ (asksQuestionsModule.name[currentLanguage]) ? asksQuestionsModule.name[currentLanguage] :
                        ("schedule_detail.interactivity.ask-questions" | translate) }}
                    </ion-label>
                </ion-segment-button>

                <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                    [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                    mode="md" *ngIf="module && module.type === typesModules.SURVEY && surveys.length > 0"
                    value="surveys">
                    <ion-label>
                        {{ (surveysModule.name[currentLanguage]) ? surveysModule.name[currentLanguage] :
                        ("schedule_detail.interactivity.survey" | translate) }}
                    </ion-label>
                </ion-segment-button>

                <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                    [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                    mode="md" *ngIf="module && module.type === typesModules.QUIZ && quizs.length > 0" value="quizs">
                    <ion-label>
                        {{ (quizsModule.name[currentLanguage]) ? quizsModule.name[currentLanguage] :
                        ("schedule_detail.interactivity.quiz" | translate) }}
                    </ion-label>
                </ion-segment-button>

                <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                    [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                    mode="md"
                    *ngIf="module && module.type === typesModules.EXTERNAL_INTERACTIVITY && externalsInteractivity.length > 0"
                    value="externalsInteractivity">
                    <ion-label>
                        {{ (externalsInteractivityModule.name[currentLanguage]) ?
                        externalsInteractivityModule.name[currentLanguage] :
                        ("schedule_detail.interactivity.externals_interactivity" | translate) }}
                    </ion-label>
                </ion-segment-button>

                <ion-segment-button [style.--color-checked]="event.styling.menuColor"
                    [style.--color-focused]="event.styling.menuColor" [style.--color-hover]="event.styling.menuColor"
                    mode="md" *ngIf="module && module.type === typesModules.CHAT && discussionsGroups.length > 0"
                    value="chats">
                    <ion-label>
                        {{ module.options.interactivityChatGroupName ?
                        module.options.interactivityChatGroupName[currentLanguage] :
                        ("schedule_detail.interactivity.group-discussion" | translate) }}
                    </ion-label>
                </ion-segment-button>
            </ng-container>
        </ion-segment>
    </ion-row>

    <!-- End of segments tabs -->

    <!-- Interactivity components -->

    <ion-row class="hide-scrollbar" [class.row-interactivity]="!isMobile" [style.height]="'calc(100% - 50px)'">

        <!-- Details component -->
        <div class="details-tabs" *ngIf="type === 'details'  && canShowDetailSegment() && init">

            <!-- Session's group(s) -->
            <div class="group-field"
                *ngIf="!module.options.baseFields.group?.hiding.general && session.groups.length > 0">
                <div class="item-name" [style.color]="event.styling.titleColor">
                    <i class="fa fa-users icon-people" aria-hidden="true"></i>
                    <h1 *ngIf="session.groups.length === 1" class="group-title">{{
                        "labels.group" | translate
                        }}</h1>
                    <h1 *ngIf="session.groups.length > 1" class="group-title">{{
                        "labels.groups" | translate }}
                    </h1>
                </div>
                <div class="group-value">
                    <small class="group-name" *ngFor="let group of getCorrespondingGroups(session.groups)"
                        [style.color]="group ? group.textColor : ''" [style.background]="group
                            ? group.backgroundColor
                            : ''">
                        {{
                        group.name
                        }}
                    </small>
                </div>
            </div>

            <!-- Customs fields -->
            <app-custom-fields-view #customFieldsViewComponent [event]="event" [module]="module"
                [eventUserProfile]="null" [scheduleId]="sessionId" [moduleType]="typesModules.SCHEDULE"
                [currentLanguage]="STranslate.currentLang">
            </app-custom-fields-view>
        </div>

        <!-- Feedbacks component -->
        <app-feedbacks *ngIf="canShowSegment('feedbacks', 'feedbacks', feedbacks) && feedbacksModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="feedbacksModule.uid" [sessionId]="sessionId"
            (changeView)="changingView($event)">
        </app-feedbacks>
        <app-feedback *ngIf="canShowSegment('feedbacks', 'feedback', feedbacks) && feedbacksModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="feedbacksModule.uid" [feedbackId]="itemViewUid"
            [sessionId]="sessionId" (changeView)="changingView($event)" [showBackBtn]="showBackBtnFeedback">
        </app-feedback>

        <!-- Ask questions component -->
        <app-asks-questions
            *ngIf="canShowSegment('questions', 'questions', asksQuestions) && asksQuestionsModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="asksQuestionsModule.uid" [sessionId]="sessionId"
            (changeView)="changingView($event)">
        </app-asks-questions>
        <app-ask-questions *ngIf="canShowSegment('questions', 'question', asksQuestions) && asksQuestionsModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="asksQuestionsModule?.uid"
            [askQuestionsId]="itemViewUid" [showBackBtn]="showBackBtnAskQuestion" (changeView)="changingView($event)">
        </app-ask-questions>

        <!-- Surveys component -->
        <app-surveys *ngIf="canShowSegment('surveys', 'surveys', surveys) && surveysModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="surveysModule.uid" [sessionId]="sessionId"
            (changeView)="changingView($event)">
        </app-surveys>
        <app-survey *ngIf="canShowSegment('surveys', 'survey', surveys) && surveysModule && init" [componentMode]="true"
            [eventId]="eventId" [moduleId]="surveysModule.uid" [surveyId]="itemViewUid"
            (changeView)="changingView($event)" [showBackBtn]="showBackBtnSurvey">
        </app-survey>

        <!-- Quizs component -->
        <app-quizs *ngIf="canShowSegment('quizs', 'quizs', quizs) && quizsModule && init" [componentMode]="true"
            [eventId]="eventId" [moduleId]="quizsModule.uid" [sessionId]="sessionId"
            (changeView)="changingView($event)"></app-quizs>
        <app-quiz *ngIf="canShowSegment('quizs', 'quiz', quizs) && quizsModule && init" [componentMode]="true"
            [eventId]="eventId" [moduleId]="quizsModule.uid" [quizId]="itemViewUid" (changeView)="changingView($event)"
            [showBackBtn]="showBackBtnQuiz">
        </app-quiz>

        <!-- External Interactivity component -->
        <app-externals-interactivity
            *ngIf="canShowSegment('externalsInteractivity', 'externalsInteractivity', externalsInteractivity) && externalsInteractivityModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="externalsInteractivityModule.uid"
            [sessionId]="sessionId" (changeView)="changingView($event)"></app-externals-interactivity>
        <app-external-interactivity
            *ngIf="canShowSegment('externalsInteractivity', 'externalInteractivity', externalsInteractivity) && externalsInteractivityModule && init"
            [componentMode]="true" [eventId]="eventId" [moduleId]="externalsInteractivityModule.uid"
            [externalInteractivityId]="itemViewUid" (changeView)="changingView($event)"
            [showBackBtn]="showBackBtnExternalInteractivity">
        </app-external-interactivity>

        <!-- Discussions groups component -->
        <app-chats *ngIf="canShowSegment('chats', 'chats', discussionsGroups) && discussionsGroupsModule && init"
            [componentMode]="true" [type]="'groups'" [eventId]="eventId" [moduleId]="discussionsGroupsModule.uid"
            [sessionId]="sessionId" (changeView)="changingView($event)">
        </app-chats>
        <app-chat-details *ngIf="canShowSegment('chats', 'chat', discussionsGroups) && discussionsGroupsModule && init"
            [componentMode]="true" [canGoBack]="discussionsGroups.length > 1 ? true : false" [eventId]="eventId"
            [moduleId]="discussionsGroupsModule.uid" [chatId]="itemViewUid" (changeView)="changingView($event)">
        </app-chat-details>
    </ion-row>

    <!-- End of interactivity components -->
</ion-grid>

<!-- <app-login-register-buttons *ngIf="event && !eventUser" [event]="event"></app-login-register-buttons> -->