import { createFeatureSelector, createSelector } from "@ngrx/store";
import { IInteractivityState } from "../interfaces/states.interfaces";
import { ISurvey, ISurveyQuestionResult } from "../interfaces/surveys.interfaces";
import * as _ from "lodash-es";
import { IColorChart } from "../interfaces/color-charts.interfaces";
import { IQuiz, IQuizQuestionResult } from "../interfaces/quiz.interfaces";
import { IWordCloud } from "../interfaces/word-clouds.interfaces";
import { IAskQuestions } from "../interfaces/ask-questions.interfaces";
import { getCurrentEventUser } from "./auth.selectors";
import { IEventUser, IExternalInteractivity, IFeedback, ISchedule } from "../interfaces";
import { DateTime } from "luxon";
import { getAccessiblesFeedbacksByType } from "./generics-modules-data.selectors";
import { getSessionDiscussionsGroups } from "./chats.selectors";
import { IChat } from "../interfaces/chats.interfaces";

export const getInteractivityState = createFeatureSelector<IInteractivityState>("interactivity");

export const checkSameEvent = (data: { key: string; uid: string }) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		return state[data.key] && state[data.key].eventId === data.uid ? true : false;
	});

/**
 * Ask questions part
 */
export const getAskQuestions = createSelector(getInteractivityState, (state: IInteractivityState) =>
	_.cloneDeep(state.asksQuestions.asksQuestions)
);

export const getAskQuestionsOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const askQuestions = _.cloneDeep(state.asksQuestions.asksQuestions);
		return askQuestions.filter((askQuestion) => askQuestion.moduleId === moduleId);
	});

export const getVisibleAskQuestionsOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const askQuestions = _.cloneDeep(state.asksQuestions.asksQuestions);
		return askQuestions.filter((askQuestion) => askQuestion.moduleId === moduleId && askQuestion.visibility);
	});

export const getSpecificAskQuestions = (uid: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const askQuestion: IAskQuestions = state.asksQuestions.asksQuestions.find(
			(askQuestion) => askQuestion.uid === uid
		);
		return askQuestion ? _.cloneDeep(askQuestion) : null;
	});

export const getAccessiblesAsksQuestionsByType = (type: number, session: ISchedule) =>
	createSelector(getInteractivityState, getCurrentEventUser, (state: IInteractivityState, eventUser: IEventUser) => {
		const asksQuestions = _.cloneDeep(state.asksQuestions.asksQuestions)
			.filter(
				(askQuestions) =>
					askQuestions.type === type &&
					(!askQuestions.groupsLink.linked ||
						(askQuestions.groupsLink.linked &&
							eventUser &&
							askQuestions.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId))))
			)
			.filter((askQuestions) => askQuestions.visibility);
		if (type === 0) {
			return asksQuestions;
		} else {
			return asksQuestions.filter(
				(askQuestion) =>
					askQuestion.schedulesLink.linked &&
					(askQuestion.schedulesLink.linkType === 0 ||
						(askQuestion.schedulesLink.linkType === 1 &&
							session &&
							askQuestion.schedulesLink.linkedModules.includes(session.moduleId)) ||
						(askQuestion.schedulesLink.linkType === 2 &&
							session &&
							askQuestion.schedulesLink.linkedSchedules.includes(session.uid)))
			);
		}
	});

/**
 * Colors charts part
 */
export const getColorsCharts = createSelector(getInteractivityState, (state: IInteractivityState) =>
	_.cloneDeep(state.colorsCharts.colorsCharts)
);

export const getSpecificColorsChart = (uid: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const colorChart: IColorChart = state.colorsCharts.colorsCharts.find((colorChart) => colorChart.uid == uid);
		return colorChart ? _.cloneDeep(colorChart) : null;
	});

/**
 * External interactivity part
 */
export const getExternalsInteractivity = createSelector(getInteractivityState, (state: IInteractivityState) =>
	_.cloneDeep(state.externalsInteractivity.externalsInteractivity)
);

export const getExternalsInteractivityOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const externalsInteractivity = _.cloneDeep(state.externalsInteractivity.externalsInteractivity);
		return externalsInteractivity
			.filter((externalInteractivity) => externalInteractivity.moduleId === moduleId)
			.sort((a, b) =>
				a.order - b.order !== 0
					? a.order - b.order
					: DateTime.fromISO(b.creationDate).valueOf() - DateTime.fromISO(a.creationDate).valueOf()
			);
	});

export const getSpecificExternalInteractivity = (uid: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const externalInteractivity: IExternalInteractivity = state.externalsInteractivity.externalsInteractivity.find(
			(externalInteractivity) => externalInteractivity.uid === uid
		);
		return externalInteractivity ? _.cloneDeep(externalInteractivity) : null;
	});

export const getAccessiblesExternalsInteractivityByType = (type: number, session: ISchedule) =>
	createSelector(getInteractivityState, getCurrentEventUser, (state: IInteractivityState, eventUser: IEventUser) => {
		const externalsInteractivity = _.cloneDeep(state.externalsInteractivity.externalsInteractivity)
			.filter(
				(externalInteractivity) =>
					!externalInteractivity.groupsLink.linked ||
					(externalInteractivity.groupsLink.linked &&
						eventUser &&
						externalInteractivity.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
			)
			.filter((externalInteractivity) => externalInteractivity.visibility);
		if (type === 0) {
			return externalsInteractivity;
		} else {
			return externalsInteractivity.filter(
				(externalInteractivity) =>
					externalInteractivity.schedulesLink.linked &&
					(externalInteractivity.schedulesLink.linkType === 0 ||
						(externalInteractivity.schedulesLink.linkType === 1 &&
							session &&
							externalInteractivity.schedulesLink.linkedModules.includes(session.moduleId)) ||
						(externalInteractivity.schedulesLink.linkType === 2 &&
							session &&
							externalInteractivity.schedulesLink.linkedSchedules.includes(session.uid)))
			);
		}
	});

/**
 * Quizs part
 */

export const getResultsOfQuizs = createSelector(getInteractivityState, (state: IInteractivityState) => {
	return _.cloneDeep(state.quizs.quizsResults);
});

export const getResultOfQuiz = (quizId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const results = state.quizs.quizsResults.filter((result) => result.quizId === quizId);
		return results ? _.cloneDeep(results) : null;
	});

export const getQuizs = createSelector(getInteractivityState, (state: IInteractivityState) =>
	_.cloneDeep(state.quizs.quizs)
);

export const getQuizOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const quizs = _.cloneDeep(state.quizs.quizs);
		return quizs.filter((quiz) => quiz.moduleId === moduleId);
	});

export const getVisibleQuizOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const quizs = _.cloneDeep(state.quizs.quizs);
		return quizs.filter((quiz) => quiz.moduleId === moduleId && quiz.visibility);
	});

export const getSpecificQuiz = (uid: string) =>
	createSelector(
		getInteractivityState,
		getResultOfQuiz(uid),
		(state: IInteractivityState, quizResults: IQuizQuestionResult[]) => {
			const quiz: IQuiz = _.cloneDeep(state.quizs.quizs).find((quiz) => quiz.uid === uid);
			if (quiz) {
				quiz.eventUserResults = _.cloneDeep(quizResults);
			}
			return quiz ? _.cloneDeep(quiz) : null;
		}
	);

export const getAccessiblesQuizsByType = (type: number, session: ISchedule) =>
	createSelector(
		getInteractivityState,
		getCurrentEventUser,
		getResultsOfQuizs,
		(state: IInteractivityState, eventUser: IEventUser, quizsResults: IQuizQuestionResult[]) => {
			const quizs = _.cloneDeep(state.quizs.quizs)
				.filter(
					(quiz) =>
						!quiz.groupsLink.linked ||
						(quiz.groupsLink.linked &&
							eventUser &&
							quiz.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
				)
				.filter((quiz) => quiz.visibility)
				.filter((quiz) => quiz.questions.filter((question) => question.visibility).length > 0);
			if (type === 0) {
				return quizs.map((quiz) => {
					quiz.eventUserResults = _.cloneDeep(quizsResults.filter((result) => result.quizId === quiz.uid));
					return quiz;
				});
			} else {
				return quizs
					.filter(
						(quiz) =>
							quiz.schedulesLink.linked &&
							(quiz.schedulesLink.linkType === 0 ||
								(quiz.schedulesLink.linkType === 1 &&
									session &&
									quiz.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(quiz.schedulesLink.linkType === 2 &&
									session &&
									quiz.schedulesLink.linkedSchedules.includes(session.uid)))
					)
					.map((quiz) => {
						quiz.eventUserResults = _.cloneDeep(
							quizsResults.filter((result) => result.quizId === quiz.uid)
						);
						return quiz;
					});
			}
		}
	);

/**
 * Surveys part
 */

export const getResultsOfSurveys = createSelector(getInteractivityState, (state: IInteractivityState) => {
	return _.cloneDeep(state.surveys.surveysResults);
});

export const getResultOfSurvey = (surveyId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const results = state.surveys.surveysResults.filter((result) => result.surveyId === surveyId);
		return results ? _.cloneDeep(results) : null;
	});

export const getSurveys = createSelector(
	getInteractivityState,
	getResultsOfSurveys,
	(state: IInteractivityState, surveysResults: ISurveyQuestionResult[]) => {
		const surveys = _.cloneDeep(state.surveys.surveys);
		return surveys.map((survey) => {
			survey.eventUserResults = _.cloneDeep(surveysResults.filter((result) => result.surveyId === survey.uid));
			return survey;
		});
	}
);

export const getSurveyOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const surveys = _.cloneDeep(state.surveys.surveys);
		return surveys.filter((survey) => survey.moduleId === moduleId);
	});

export const getVisibleSurveyOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const surveys = _.cloneDeep(state.surveys.surveys);
		return surveys.filter((survey) => survey.moduleId === moduleId && survey.visibility);
	});

export const getSpecificSurvey = (uid: string) =>
	createSelector(
		getInteractivityState,
		getResultOfSurvey(uid),
		(state: IInteractivityState, surveyResults: ISurveyQuestionResult[]) => {
			const survey: ISurvey = _.cloneDeep(state.surveys.surveys).find((survey) => survey.uid === uid);
			if (survey) {
				survey.eventUserResults = _.cloneDeep(surveyResults);
			}
			return survey ? _.cloneDeep(survey) : null;
		}
	);

export const getAccessiblesSurveysByType = (type: number, session: ISchedule) =>
	createSelector(
		getInteractivityState,
		getCurrentEventUser,
		getResultsOfSurveys,
		(state: IInteractivityState, eventUser: IEventUser, surveysResults: ISurveyQuestionResult[]) => {
			const surveys = _.cloneDeep(state.surveys.surveys)
				.filter(
					(survey) =>
						!survey.groupsLink.linked ||
						(survey.groupsLink.linked &&
							eventUser &&
							survey.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
				)
				.filter((survey) => survey.visibility)
				.filter((survey) => survey.questions.filter((question) => question.visibility).length > 0);
			if (type === 0) {
				return surveys.map((survey) => {
					survey.eventUserResults = _.cloneDeep(
						surveysResults.filter((result) => result.surveyId === survey.uid)
					);
					return survey;
				});
			} else {
				return surveys
					.filter(
						(survey) =>
							survey.schedulesLink.linked &&
							(survey.schedulesLink.linkType === 0 ||
								(survey.schedulesLink.linkType === 1 &&
									session &&
									survey.schedulesLink.linkedModules.includes(session.moduleId)) ||
								(survey.schedulesLink.linkType === 2 &&
									session &&
									survey.schedulesLink.linkedSchedules.includes(session.uid)))
					)
					.map((survey) => {
						survey.eventUserResults = _.cloneDeep(
							surveysResults.filter((result) => result.surveyId === survey.uid)
						);
						return survey;
					});
			}
		}
	);

/**
 * Word clouds part
 */
export const getWordClouds = createSelector(getInteractivityState, (state: IInteractivityState) =>
	_.cloneDeep(state.wordClouds.wordClouds)
);

export const getWordCloudsOfModule = (moduleId: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const wordClouds = _.cloneDeep(state.wordClouds.wordClouds);
		return wordClouds.filter((wordCloud) => wordCloud.moduleId === moduleId);
	});

export const getSpecificWordCloud = (uid: string) =>
	createSelector(getInteractivityState, (state: IInteractivityState) => {
		const wordCloud: IWordCloud = state.wordClouds.wordClouds.find((wordCloud) => wordCloud.uid === uid);
		return wordCloud ? _.cloneDeep(wordCloud) : null;
	});

export const getAccessiblesWordsClouds = createSelector(
	getInteractivityState,
	getCurrentEventUser,
	(state: IInteractivityState, eventUser: IEventUser) => {
		const wordClouds = _.cloneDeep(state.wordClouds.wordClouds);
		return wordClouds.filter(
			(wordCloud) =>
				!wordCloud.groupsLink.linked ||
				(wordCloud.groupsLink.linked &&
					eventUser &&
					wordCloud.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId)))
		);
	}
);

/**
 * Get all accessible
 */

export const getAllAccessiblesInteractivity = (session: ISchedule) =>
	createSelector(
		getAccessiblesAsksQuestionsByType(1, session),
		getAccessiblesFeedbacksByType(1, session),
		getAccessiblesQuizsByType(1, session),
		getAccessiblesExternalsInteractivityByType(1, session),
		getAccessiblesSurveysByType(1, session),
		getSessionDiscussionsGroups(session),

		(
			asksQuestionsState: IAskQuestions[],
			feedbacksState: IFeedback[],
			quizsState: IQuiz[],
			externalsInteractivityState: IExternalInteractivity[],
			surveysState: ISurvey[],
			chatsState: IChat[]
		) => {
			return {
				asksQuestionsState: asksQuestionsState,
				feedbacksState: feedbacksState,
				quizsState: quizsState,
				externalsInteractivityState: externalsInteractivityState,
				surveysState: surveysState,
				chatsState: chatsState
			};
		}
	);
