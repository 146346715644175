<!-- Untouched field icon -->
<ion-icon
	*ngIf="
		form &&
		form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language) &&
		!form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language).touched
	"
	src="assets/icon/Check.svg"
	class="check-icon"
	slot="end"
>
</ion-icon>
<!-- Success icon -->
<ion-icon
	*ngIf="
		form &&
		form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language) &&
		!form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language).errors &&
		form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language).touched
	"
	src="assets/icon/Check.svg"
	class="check-icon"
	slot="end"
	color="primary"
>
</ion-icon>
<!-- Errors icon -->
<ion-icon
	*ngIf="
		form &&
		form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language) &&
		form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language).errors &&
		form.get(fieldType === 'singleLanguage' ? fieldId : fieldId + '_' + language).touched
	"
	src="assets/icon/Close.svg"
	class="check-icon"
	slot="end"
	color="danger"
>
</ion-icon>
