import { createReducer, on } from "@ngrx/store";
import * as RegisterFomrsActionsTypes from "../actions/register-form.actions";
import { IRegisterFormState } from "../interfaces/register-form.interfaces";
export const initialState: IRegisterFormState = {
	eventId: "",
	moduleId: "",
	registerForms: [],
	currentRegisterForm: null
};

export const registerFormFeatureKey = "registerForms";

export const registerFormReducers = createReducer(
	initialState,
	on(RegisterFomrsActionsTypes.GetRegisterForms, (state, action) => ({
		...state,
		registerForms: action.payload,
		currentRegisterForm: null,
		eventId: action.eventId
	})),
	on(RegisterFomrsActionsTypes.GetCurrentRegisterForm, (state, action) => ({
		...state,
		moduleId: action.moduleId,
		currentRegisterForm: state.registerForms.find((registerForm) => registerForm.moduleId === action.moduleId)
	})),
	on(RegisterFomrsActionsTypes.ResetRegisterFormsState, () => ({
		...initialState
	}))
);
