import { createFeatureSelector, createSelector } from "@ngrx/store";
import * as _ from "lodash-es";
import { IEventUser } from "../interfaces";
import { IRankingsState } from "../interfaces/ranking.interfaces";
import { getCurrentEventUser } from "./auth.selectors";

export const getRankingsState = createFeatureSelector<IRankingsState>("rankings");

export const checkSameEvent = (uid: string) =>
	createSelector(getRankingsState, (state: IRankingsState) => {
		return state.eventId === uid ? true : false;
	});

export const getAllRankings = createSelector(getRankingsState, (state: IRankingsState) => _.cloneDeep(state.rankings));

export const getRankingsOfModule = (moduleId: string) =>
	createSelector(getRankingsState, getCurrentEventUser, (state: IRankingsState, eventUser: IEventUser) => {
		return _.cloneDeep(state.rankings).filter(
			(ranking) =>
				ranking.moduleId === moduleId &&
				(!ranking.groupsLink.linked ||
					(ranking.groupsLink.linked &&
						eventUser &&
						ranking.groupsLink.groups.some((groupId) => eventUser.groups.includes(groupId))))
		);
	});

export const getSpecificRanking = (uid: string) =>
	createSelector(getRankingsState, (state: IRankingsState) =>
		_.cloneDeep(state.rankings).find((ranking) => ranking.uid === uid)
	);
