import { Injectable } from "@angular/core";
import { IReport } from "../interfaces/reports.interfaces";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class ReportsService {
	constructor(private SFirestore: FirestoreService) {}
	/**
	 * Create a report
	 * @param eventId
	 * @param report
	 * @returns
	 */
	createReport(eventId: string, report: IReport) {
		report.uid = report.uid ? report.uid : this.SFirestore.createId(`events/${eventId}/reports`);
		return this.SFirestore.setDocument(`events/${eventId}/reports/${report.uid}`, report);
	}
}
