import { Injectable } from "@angular/core";
import { Firestore, QueryConstraint, doc, orderBy, where } from "@angular/fire/firestore";
import { Store } from "@ngrx/store";
import * as _ from "lodash-es";
import { Observable, Subject, Subscription, combineLatest, forkJoin, from, of } from "rxjs";
import { debounceTime, map, skipWhile, switchMap, take } from "rxjs/operators";
import { GetAllCheckins } from "../actions/checkins.actions";
import { InitSpecificEventDatasPart } from "../actions/utility.actions";
import { IEventUser } from "../interfaces";
import { ICheckin, ICheckinChecked } from "../interfaces/checkin.interfaces";
import { checkSameEvent } from "../selectors/checkins.selectors";
import { getInitSpecificEventDatasPart } from "../selectors/utility.selectors";
import { FirestoreService } from "./firestore.service";

@Injectable({
	providedIn: "root"
})
export class CheckinsService {
	checkinsSub: Subscription;
	eventUsersSub: Subscription;

	scanQrSubject: Subject<boolean> = new Subject();

	constructor(private SFirestore: FirestoreService, private store: Store, private firestore: Firestore) {}

	/**
	 * Get all checkins for event
	 * @param eventId
	 * @returns
	 */
	getCheckinsOfEvent(eventId: string) {
		this.store
			.select(checkSameEvent(eventId))
			.pipe(take(1))
			.subscribe((sameEvent) => {
				if (sameEvent && this.checkinsSub && !this.checkinsSub.closed) {
					return;
				} else if (!sameEvent && this.checkinsSub && !this.checkinsSub.closed) {
					this.checkinsSub.unsubscribe();
				}

				this.checkinsSub = this.SFirestore.collectionGroupValueChangesDocuments("checkins", [
					where("eventId", "==", eventId),
					where("visibility", "==", true)
				]).subscribe((checkins: ICheckin[]) => {
					this.store.dispatch(GetAllCheckins({ payload: checkins, eventId: eventId }));

					this.store
						.select(getInitSpecificEventDatasPart("initCheckins"))
						.pipe(take(1))
						.subscribe((init) => {
							if (!init) {
								this.store.dispatch(
									InitSpecificEventDatasPart({ part: "initCheckins", payload: true })
								);
							}
						});
				});
			});
	}

	unsubscribeAll() {
		if (this.checkinsSub) {
			this.checkinsSub.unsubscribe();
		}
	}

	/**
	 * Get checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @returns
	 */
	getCheckinCheckeds(eventId: string, moduleId: string, checkinId: string) {
		return this.SFirestore.valueChangesDocuments(
			`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked`,
			[]
		);
	}

	/**
	 * Get checkin checked for users
	 * @param eventId
	 * @param checkinId
	 * @param usersIds
	 * @returns
	 */
	getCheckinCheckedForUsers(eventId: string, checkinId: string, usersIds: string[]) {
		if (usersIds.length === 0) {
			return of([]);
		}
		const allIds = _.chunk(usersIds, 10);
		const obsArray: Observable<ICheckinChecked[]>[] = [];
		const queryConstraints: QueryConstraint[] = [
			where("eventId", "==", eventId),
			where("checkinId", "==", checkinId)
		];

		allIds.forEach((ids) => {
			obsArray.push(
				from(
					this.SFirestore.getDocumentsCollectionGroup(
						`checkin-checked`,
						queryConstraints.concat([where("userId", "in", ids)])
					)
				).pipe(map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked)))
			);
		});
		return forkJoin(obsArray).pipe(
			switchMap((results) => {
				const checkinCheckeds: ICheckinChecked[] = _.uniqBy(_.flatten(results), "userId");
				return of(checkinCheckeds);
			})
		);
	}

	/**
	 * Get checkin checked for users
	 * @param eventId
	 * @param checkinId
	 * @param usersIds
	 * @returns
	 */
	getCheckinCheckedForUser(eventId: string, checkinId: string, userId: string) {
		const queryConstraints: QueryConstraint[] = [
			where("eventId", "==", eventId),
			where("checkinId", "==", checkinId),
			where("userId", "==", userId)
		];

		return from(this.SFirestore.getDocumentsCollectionGroup(`checkin-checked`, queryConstraints)).pipe(
			map((snapshot) => snapshot.docs.map((doc) => doc.data() as ICheckinChecked))
		);
	}

	/**
	 * Get checkin checkeds for event user
	 * @param eventId
	 * @param eventUserId
	 * @returns
	 */
	getCheckedsForEventUser(eventId: string, eventUserId: string) {
		return this.SFirestore.collectionGroupValueChangesDocuments("checkin-checked", [
			where("eventId", "==", eventId),
			where("userId", "==", eventUserId),
			// where("checkinStatus", "==", true),
			orderBy("creationDate", "desc")
		]);
	}

	/**
	 * Get checkin
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @returns
	 */
	getCheckin(eventId: string, moduleId: string, checkinId: string) {
		return this.SFirestore.valueChangesDocument(`events/${eventId}/modules/${moduleId}/checkins/${checkinId}`);
	}

	/**
	 * Create checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @param checked
	 * @returns
	 */
	createCheckinChecked(eventId: string, moduleId: string, checkinId: string, checked: ICheckinChecked) {
		checked.uid = checked.uid
			? checked.uid
			: this.SFirestore.createId(`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked`);
		return this.SFirestore.setDocument(
			`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked/${checked.uid}`,
			checked
		);
	}

	/**
	 * Update checkin checked
	 * @param eventId
	 * @param moduleId
	 * @param checkinId
	 * @param checked
	 * @returns
	 */
	updateCheckinChecked(eventId: string, moduleId: string, checkinId: string, checked: ICheckinChecked) {
		return this.SFirestore.updateDocument(
			`events/${eventId}/modules/${moduleId}/checkins/${checkinId}/checkin-checked/${checked.uid}`,
			_.omit(checked, ["eventId", "moduleId", "checkinId", "uid", "userId", "validationType"])
		);
	}

	/**
	 * repairCheckins
	 * @description updated valued in checkinsNotChecked and checkinsChecked arrays
	 */
	repairCheckins(eventId: string) {
		// get all eventUsers of the event
		combineLatest([this.getAllUsersForEvent(eventId), this.getCheckins(eventId), this.getCheckinsChecked(eventId)])
			.pipe(
				debounceTime(3000),
				skipWhile(
					(results) => results[0] && results[1] && (results[0].length === 0 || results[1].length === 0)
				),
				take(1)
			)
			.subscribe(async (results) => {
				const eventUsers = results[0] as IEventUser[];
				const checkins = results[1] as ICheckin[];
				const checkinsChecked = results[2] as ICheckinChecked[];
				const chunkEventUsers = _.chunk(eventUsers, 400);
				for (const eventUsers of chunkEventUsers) {
					const batch = this.SFirestore.getBatch();

					eventUsers.forEach((eventUser) => {
						// Init checkins
						checkins.forEach((checkin) => {
							// add checkinId to checkinsChecked array if it is not already in
							if (eventUser.checkins) {
								if (!eventUser.checkins.checkinsNotChecked.includes(checkin.uid)) {
									eventUser.checkins.checkinsNotChecked.push(checkin.uid);
								}
							} else {
								eventUser.checkins = {
									checkinsChecked: [],
									checkinsNotChecked: [checkin.uid]
								};
							}
						});

						eventUser.checkins.checkinsChecked = [];

						// remove all data from "eventUser.checkins.checkinsNotChecked" that art not in "chekins"
						eventUser.checkins.checkinsNotChecked = eventUser.checkins.checkinsNotChecked.filter(
							(checkinId) => {
								return checkins.some((checkin) => checkin.uid === checkinId);
							}
						);
						eventUser.checkins.checkinsChecked = _.uniq(eventUser.checkins.checkinsChecked);
						eventUser.checkins.checkinsNotChecked = _.uniq(eventUser.checkins.checkinsNotChecked);

						// init checkinsChecked
						checkinsChecked.forEach((checkinChecked) => {
							// add checkinId to checkinsChecked array if it is not already in
							if (eventUser.uid === checkinChecked.userId) {
								if (
									!eventUser.checkins.checkinsChecked.includes(checkinChecked.checkinId) &&
									checkinChecked.checkinStatus === true &&
									eventUser.checkins.checkinsNotChecked.includes(checkinChecked.checkinId)
								) {
									eventUser.checkins.checkinsChecked.push(checkinChecked.checkinId);
									// remove checkinId from checkinsNotChecked array
									eventUser.checkins.checkinsNotChecked =
										eventUser.checkins.checkinsNotChecked.filter(
											(checkinId) => checkinId !== checkinChecked.checkinId
										);
								} else {
									// remove checkinId from checkinsChecked array
									eventUser.checkins.checkinsChecked = eventUser.checkins.checkinsChecked.filter(
										(checkinId) => checkinId !== checkinChecked.checkinId
									);
									// add checkinId to checkinsNotChecked array if it is not already in
									if (
										!eventUser.checkins.checkinsNotChecked.includes(checkinChecked.checkinId) &&
										checkinChecked.checkinStatus === false
									) {
										eventUser.checkins.checkinsNotChecked.push(checkinChecked.checkinId);
									}
								}
							}
						});

						eventUser.checkins.checkinsChecked = _.uniq(eventUser.checkins.checkinsChecked);
						eventUser.checkins.checkinsNotChecked = _.uniq(eventUser.checkins.checkinsNotChecked);

						// update eventUser
						batch.update(
							doc(
								this.firestore,
								`events/${eventId}/modules/${eventUser.moduleId}/event-users/${eventUser.uid}`
							),
							{
								checkins: eventUser.checkins
							}
						);
					});

					try {
						await batch.commit();
					} catch (error) {
						//
					}
				}
			});
	}

	/**
	 * getAllUsersForEvent
	 * @param eventId
	 * @returns
	 */
	getAllUsersForEvent(eventId: string): Observable<any[]> {
		if (eventId && eventId != "") {
			return this.SFirestore.collectionGroupValueChangesDocuments("event-users", [
				where("eventId", "==", eventId)
			]);
		}
	}

	/**
	 * getCheckins
	 * @param eventId
	 * @returns
	 */
	getCheckins(eventId: string): Observable<any[]> {
		if (eventId && eventId != "") {
			return this.SFirestore.collectionGroupValueChangesDocuments("checkins", [where("eventId", "==", eventId)]);
		}
	}

	/**
	 * getCheckinsChecked
	 * @param eventId
	 * @returns
	 */
	getCheckinsChecked(eventId: string): Observable<any[]> {
		if (eventId && eventId != "") {
			return this.SFirestore.collectionGroupValueChangesDocuments("checkin-checked", [
				where("eventId", "==", eventId)
			]);
		}
	}
}
